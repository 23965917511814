<template>
  <el-dialog v-model="visible" width="80%" destroy-on-close :show-close="false">
    <template #header="{ close, titleId, titleClass }">
      <div class="my-header">
        <div class="size-16">
          {{ $t("changePriceLists.changePriceList.selectCell") }}
        </div>
        <div style="cursor: pointer" @click="close">
          <el-icon :size="20"><Close /></el-icon>
        </div>
      </div>
    </template>
    <div class="tcm-table">
      <el-row :gutter="10">
        <el-col :span="24">
          <table style="margin-bottom: 10px">
            <tr>
              <td style="width: 260px">
                {{ $t("changePriceLists.changePriceList.currency") }}
                <el-select
                  clearable
                  :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                  v-model="searchForm.currencyId"
                  placeholder=""
                  style="width: 200px"
                  :disabled="operateType == 'detail'"
                >
                  <el-option
                    v-for="item in CurrencyOptions"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </td>
              <td style="width: 260px">
                {{ $t("changePriceLists.changePriceList.region") }}
                <el-select
                  clearable
                  v-model="searchForm.RegionId"
                  :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                  style="width: 200px"
                >
                  <el-option
                    v-for="item in regionOptions"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </td>
              <td style="width: 260px">
                <el-input
                  v-model="searchForm.Keyword"
                  :placeholder="$t('unifiedPrompts.unifiedPrompt.keyword')"
                />
              </td>
              <td style="width: 260px">
                <el-button
                  style="
                    background-color: var(--themeColor);
                    color: var(--menuTextActive);
                    margin-right: 16px;
                  "
                  @click="search"
                  >{{
                    $t("changePriceLists.changePriceList.search")
                  }}</el-button
                >
              </td>
            </tr>
          </table>
        </el-col>
        <el-col :span="24">
          <div class="regional_table">
            <el-table
              ref="rawTable"
              :data="tableData"
              border
              stripe
              style="width: 100%"
              :row-key="
                (row) => {
                  return row.id;
                }
              "
              @selection-change="handleSelectionChange"
              :empty-text="$t('tcmlists.tcmlist.noData')"
            >
              <!-- @select-all="selectAll" -->
              <el-table-column
                type="selection"
                reserve-selection
                width="55"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="number"
                :label="$t('templateLists.templateList.cellNo')"
                width="180"
                show-overflow-tooltip
                align="center"
              />
              <el-table-column
                prop="name"
                :label="$t('templateLists.templateList.cellName')"
                width="160"
                show-overflow-tooltip
                align="center"
              />
              <el-table-column
                prop="description"
                :label="$t('templateLists.templateList.itemDescription')"
                width="160"
                show-overflow-tooltip
                align="center"
              />
              <el-table-column
                prop="regionName"
                :label="$t('templateLists.templateList.region')"
                width="90"
                show-overflow-tooltip
                align="center"
              />
              <el-table-column
                prop="currencyName"
                :label="$t('changePriceLists.changePriceList.currency')"
                width="90"
                show-overflow-tooltip
                align="center"
              />
              <el-table-column
                prop="version"
                :label="$t('templateLists.templateList.version')"
                width="90"
                show-overflow-tooltip
                align="center"
              />
              <el-table-column
                prop="comment"
                :label="$t('templateLists.templateList.comments')"
                width="200"
                show-overflow-tooltip
                align="center"
              />
              <el-table-column
                prop="creator"
                :label="$t('templateLists.templateList.creator')"
                width="120"
                show-overflow-tooltip
                align="center"
              />
              <el-table-column
                prop="date"
                :label="$t('templateLists.templateList.date')"
                width="120"
                show-overflow-tooltip
                align="center"
              />
              <el-table-column
                prop="enable"
                :label="$t('templateLists.templateList.enable')"
                width="120"
                show-overflow-tooltip
                align="center"
              >
                <template #default="scope">
                  <el-icon
                    :size="16"
                    color="#40D4A8"
                    v-show="scope.row.isEnable == '1'"
                  >
                    <SuccessFilled />
                  </el-icon>
                  <el-icon
                    :size="16"
                    color="#F56C6C"
                    v-show="scope.row.isEnable == '2'"
                  >
                    <CircleCloseFilled />
                  </el-icon>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="pagination">
            <p></p>
            <el-pagination
              layout="prev, pager, next"
              background
              :total="total"
              :current-page="searchForm.page"
              @current-change="handleCurrentChange"
            />
          </div>
        </el-col>
      </el-row>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close">{{
          $t("userlists.userlist.cancel")
        }}</el-button>
        <el-button
          style="
            background-color: var(--themeColor);
            color: var(--menuTextActive);
            margin-right: 16px;
          "
          @click="toConfirm"
          >{{ $t("userlists.userlist.confirm") }}</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {
  GetTemplatePurchases,
  getRegions,
} from "../../assets/js/requestApi.js";
import commonUtil from "../../assets/js/commonUtil.js";
export default {
  props: ["CommodityId", "operateType", "CurrencyOptions"],
  emits: ["getChoosePurId"],
  data() {
    return {
      visible: false,
      searchForm: {
        date: "",
        Enable: 1,
        StartDate: "",
        EndDate: "",
        status: "",
        Keyword: "",
        CommodityId: "",
        currencyId: "",
        VersionType: 1,
        RegionId: "",
        CommodityId: "",
        page: 1,
        limit: 10,
      },
      regionOptions: [],
      // 总页数
      total: 0,
      // 表格数据
      tableData: [],
      // 已勾选数据
      chooseData: [],
    };
  },
  mounted() {
    // this.getRegion();
    this.regionOptions = JSON.parse(sessionStorage.getItem("Region"));
  },
  methods: {
    close() {
      this.visible = false;
      this.chooseData = [];
    },
    toConfirm() {
      if (this.chooseData.length == 0) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.pleaseChooseCell"),
          "",
          "warning"
        );
        return;
      }
      if (this.operateType == "choosePrice" && this.chooseData.length > 1) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.onlyOneToChoose"),
          "",
          "warning"
        );
        return;
      }
      let ids = [];
      this.chooseData.forEach(function (val, index) {
        ids.push(val.id);
      });
      this.$emit("getChoosePurId", ids);
      // this.$emit('getChoosePurId', this.chooseData.id)
      this.chooseData = [];
    },
    // 获取表格数据
    getlist() {
      this.searchForm = {
        date: "",
        Enable: 1,
        StartDate: "",
        EndDate: "",
        status: "",
        Keyword: "",
        CommodityId: this.CommodityId,
        currencyId: "",
        VersionType: 1,
        RegionId: "",
        page: 1,
        limit: 10,
      };
      this.search();
    },
    // 查询
    search() {
      GetTemplatePurchases(this.searchForm, (response) => {
        if (response && response.code == 200) {
          this.tableData = response.data;
          this.total = response.count;
        } else {
          commonUtil.showTipInfo(this.$t("userlists.userlist.inqueryTimeout"));
        }
      });
    },
    // 分页
    handleCurrentChange(val) {
      this.searchForm.page = val;
      this.search();
    },
    // 表格单选选
    handleSelectionChange(val) {
      // this.chooseData = val[0]
      // if (val.length > 1) {
      // 	this.$refs.rawTable.clearSelection();
      // 	this.$refs.rawTable.toggleRowSelection(val.pop());
      // }
      this.chooseData = val;
    },
    // 表格全选
    selectAll() {
      this.$refs.rawTable.clearSelection();
      this.chooseData = [];
    },
    // 获取区域列表选项
    getRegion() {
      getRegions(
        {
          Keyword: "",
          isEnable: 1,
          page: 1,
          limit: 9999,
          key: "",
        },
        (response) => {
          if (response && response.code == 200) {
            this.regionOptions = response.data;
          }
        }
      );
    },
  },
};
</script>

<style scoped lang="scss">
:deep(.el-dialog__header) {
  margin-right: 0;
}
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.size-16 {
  font-size: 16px;
}
.page-cla {
  padding: 20px;
  display: flex;
  justify-content: flex-end;
}
.align-right {
  text-align: right;
}
.tcm-table {
  height: calc(100% - 90px);
  padding: 0 20px;
  box-sizing: border-box;
}
.tree-cla {
  padding: 10px;
  box-sizing: border-box;
  height: 100%;
  border: 1px solid #ebeef5;
}
.el-row {
  height: 100%;
}
.flex-around {
  display: flex;
  justify-content: space-around;
}
</style>