<template>
  <div class="main">
    <div class="main-top_handle">
      <div class="title">{{ $t("homePage.home.Application") }}</div>
      <div class="main-handle flex">
        <!-- 状态 -->
        <div class="main-handle-item">
          {{ $t("tcmlists.tcmlist.workStatus") }}
          <el-select
            size="small"
            clearable
            v-model="searchForm.status"
            :placeholder="$t('tcmlists.tcmlist.workStatus')"
          >
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="$t(item.label)"
              :value="item.value"
            />
          </el-select>
        </div>
        <div class="main-handle-item">
          {{ $t("tcmlists.tcmlist.commodity") }}
          <!-- CommodityId -->
          <el-tree-select
            size="small"
            clearable
            :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
            v-model="searchForm.CommodityId"
            :data="CommodityOptions"
            :props="defaultProps"
            check-strictly
            :render-after-expand="false"
            :default-expand-all="true"
          />
        </div>
        <div class="main-handle-item">
          {{ $t("changePriceLists.changePriceList.programName") }}
          <input
            type="text"
            v-model="searchForm.programName"
            :placeholder="$t('changePriceLists.changePriceList.programName')"
          />
        </div>
        <div class="main-handle-item">
          {{ $t("changePriceLists.changePriceList.partNumber") }}
          <input
            type="text"
            v-model="searchForm.partNumber"
            :placeholder="$t('changePriceLists.changePriceList.partNumber')"
          />
        </div>
        <div class="main-handle-item">
          {{ $t("unifiedPrompts.unifiedPrompt.keyword") }}
          <input
            type="text"
            v-model="searchForm.Keyword"
            :placeholder="$t('unifiedPrompts.unifiedPrompt.keyword')"
          />
        </div>
        <!-- 日期选择 -->
        <div class="main-handle-item">
          <p style="height: 13px"></p>
          <div class="select-box">
            <div class="select-btn">
              <el-icon class="Calendar"><Calendar /></el-icon>
              <span :style="{ color: searchForm.date ? '#606266' : '' }">{{
                searchForm.date
                  ? searchForm.date[0] + " - " + searchForm.date[1]
                  : $t("tcmdatalists.tcmdatalist.pleaseSlect")
              }}</span>
              <el-icon v-show="!searchForm.date">
                <ArrowDownBold />
              </el-icon>
              <el-icon :size="18" v-show="searchForm.date">
                <Close />
              </el-icon>
            </div>
            <el-config-provider
              :locale="$t('homePage.home.locale') == 'locale' ? locale : ''"
            >
              <el-date-picker
                class="date-picker"
                style="
                  width: 200px;
                  height: 30px;
                  position: absolute;
                  top: 0;
                  left: 0;
                  opacity: 0;
                "
                v-model="searchForm.date"
                type="daterange"
                range-separator="To"
                :start-placeholder="
                  $t('unifiedPrompts.unifiedPrompt.startDate')
                "
                :end-placeholder="$t('unifiedPrompts.unifiedPrompt.endDate')"
                size="mini"
                value-format="YYYY-MM-DD"
                @change="changeDate"
              />
            </el-config-provider>
          </div>
        </div>
        <div class="search-btn" @click="search" style="margin-top: 13px">
          {{ $t("tcmlists.tcmlist.search") }}
        </div>
      </div>
    </div>
    <div class="handle-btn flex">
      <div class="handle-btn-item" v-if="BtnLimits.AddBtn" @click="addEntry">
        {{ $t("unifiedPrompts.unifiedPrompt.new") }}
      </div>
      <div class="handle-btn-item" v-if="BtnLimits.Copy" @click="copyData()">
        {{ $t("unifiedPrompts.unifiedPrompt.copy") }}
      </div>
      <el-upload
        ref="upload"
        class="upload-demo custom-el-upload-list-none"
        action="/"
        :on-change="importData"
        :auto-upload="false"
      >
        <template #trigger>
          <div class="handle-btn-item" v-if="BtnLimits.Import">
            {{ $t("ccls.ccl.import") }}
          </div>
        </template>
      </el-upload>
      <div class="handle-btn-item" @click="downTemplate">
        {{ $t("unifiedPrompts.unifiedPrompt.downTemplate") }}
      </div>
    </div>
    <div class="regional_table">
      <el-table
        stripe
        border
        :data="tableData"
        :height="TableHeight"
        @row-click="RowClick"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column
          prop="partNumber"
          :label="$t('tcmlists.tcmlist.partNumber')"
          width="120"
          align="center"
        />
        <el-table-column
          prop="programName"
          :label="$t('changePriceLists.changePriceList.programName')"
          align="center"
        />

        <el-table-column
          prop="commodityName"
          :label="$t('tcmlists.tcmlist.commodity')"
          width="110"
          align="center"
        />

        <el-table-column
          prop="regionName"
          :label="$t('tcmdatalists.tcmdatalist.regionName')"
          align="center"
        />
        <el-table-column
          prop="tcmOwner"
          :label="$t('tcmlists.tcmlist.tcmRequester')"
          width="120"
          align="center"
        />

        <el-table-column
          prop="partDescription"
          :label="$t('tcmlists.tcmlist.partDescription')"
          align="center"
        />
        <el-table-column
          prop="quoteType"
          :label="$t('tcmlists.tcmlist.quotationType')"
          width="120"
          align="center"
        />
        <el-table-column
          prop="tcmRequest"
          :label="$t('tcmlists.tcmlist.tcmOwner')"
          width="120"
          align="center"
        />

        <!-- <el-table-column
          prop="date"
          :label="$t('tcmlists.tcmlist.startDate')"
          width="125"
          sortable
          align="center"
        /> -->
        <el-table-column
          prop="status"
          :label="$t('tcmlists.tcmlist.workStatus')"
          sortable
          align="center"
        >
          <template #default="scope">
            <div v-show="scope.row.status == '0'" class="color-F59A23">
              {{ $t("tcmdatalists.tcmdatalist.notStart") }}
            </div>
            <div v-show="scope.row.status == '1'" class="color-D9001B">
              {{ $t("tcmdatalists.tcmdatalist.inProcess") }}
            </div>
            <div v-show="scope.row.status == '2'" class="color-0000FF">
              {{ $t("tcmdatalists.tcmdatalist.toApproved") }}
            </div>
            <div v-show="scope.row.status == '4'">
              {{ $t("tcmdatalists.tcmdatalist.underApproved") }}
            </div>
            <div v-show="scope.row.status == '5'" class="color-D9001B">
              {{ $t("tcmdatalists.tcmdatalist.rejected") }}
            </div>
            <div v-show="scope.row.status == '6'" class="color-67C23A">
              {{ $t("tcmdatalists.tcmdatalist.approved") }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="urgency"
          :label="$t('tcmlists.tcmlist.priority')"
          sortable
          :sort-method="
            (aRow, bRow) => {
              let a = aRow.urgency;
              let b = bRow.urgency;
              let i = a == 'Low' ? 1 : a == 'Middle' ? 2 : a == 'High' ? 3 : 0;
              let j = b == 'Low' ? 1 : b == 'Middle' ? 2 : b == 'High' ? 3 : 0;
              return i - j;
            }
          "
          width="110"
          align="center"
        >
          <template #default="scope">
            <div v-show="scope.row.urgency == 'High'" style="color: #e91919">
              {{ $t("tcmdatalists.tcmdatalist.high") }}
            </div>
            <div v-show="scope.row.urgency == 'Middle'">
              {{ $t("tcmdatalists.tcmdatalist.middle") }}
            </div>
            <div v-show="scope.row.urgency == 'Low'">
              {{ $t("tcmdatalists.tcmdatalist.low") }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="date"
          :label="$t('tcmdatalists.tcmdatalist.startDate')"
          sortable
          width="110"
          align="center"
        />
        <el-table-column
          prop="doDate"
          :label="$t('changePriceLists.changePriceList.dueDate')"
          sortable
          width="120"
          align="center"
        />
        <el-table-column
          width="80"
          fixed="right"
          class-name="lastTd"
          align="center"
          :label="$t('homePage.home.Action')"
        >
          <template #default="scope">
            <el-popover
              style="padding: 5px !important"
              placement="bottom"
              trigger="hover"
            >
              <template #reference>
                <div style="text-align: center">
                  <el-icon :size="15"><MoreFilled /></el-icon>
                </div>
              </template>
              <div class="action-box">
                <div
                  class="action-btn"
                  v-if="BtnLimits.Copy"
                  @click="copyData(scope.row.id)"
                >
                  <el-icon style="color: #2d8c1d"><CopyDocument /></el-icon>
                  <span>{{ $t("unifiedPrompts.unifiedPrompt.copy") }}</span>
                </div>
                <div
                  class="action-btn"
                  v-if="scope.row.status == '0' && BtnLimits.Delete"
                  @click="deleteApp(scope.row.id)"
                >
                  <el-icon style="color: #b2bde7"><Delete /></el-icon>
                  <span>{{ $t("unifiedPrompts.unifiedPrompt.delete") }}</span>
                </div>
                <div
                  class="action-btn"
                  v-if="scope.row.status == '0' && BtnLimits.Edit == true"
                  @click="edit(scope.row.id)"
                >
                  <el-icon style="color: #ff6d4d"><Edit /></el-icon>
                  <span>{{ $t("unifiedPrompts.unifiedPrompt.edit") }}</span>
                </div>
                <div
                  class="action-btn"
                  v-if="scope.row.status == '6' && BtnLimits.Reject == true"
                  @click="toReject(scope.row.id, scope.row.status)"
                >
                  <el-icon style="color: #f24242"><CloseBold /></el-icon>
                  <span>{{ $t("tcmdatalists.tcmdatalist.rejected") }}</span>
                </div>
                <div
                  class="action-btn"
                  v-if="scope.row.status == '1' && BtnLimits.Rework"
                  @click="toCallback(scope.row.id, scope.row.status)"
                >
                  <el-icon style="color: #2f4cdd"><Callback /></el-icon>
                  <span>{{ $t("homePage.home.Callback") }}</span>
                </div>
              </div>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <div>
        {{ $t("homePage.home.Showing") }} {{ pageSize }}
        {{ $t("homePage.home.From") }} {{ total }}
        {{ $t("homePage.home.Data") }}
        <el-popover placement="top" :width="70" trigger="click">
          <template #reference>
            <el-icon
              style="margin-left: 5px; font-size: 17px; vertical-align: middle"
              ><Operation
            /></el-icon>
          </template>
          <el-input
            v-model="searchForm.limit"
            @input="(val) => (searchForm.limit = val.replace(/[^\d]/g, ''))"
            :min="1"
            style="width: 130px"
            @change="handleSizeChange"
          >
            <template #suffix> {{ $t("ccls.ccl.barPage") }}</template>
          </el-input>
        </el-popover>
      </div>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="pageSize"
        :current-page="searchForm.page"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  getApplicationList,
  deleteApplyDetail,
  ImportTemplateMaterialExcel,
  GetOrgs,
  ExportDataTableExcel,
  GetDataIsDuplicate,
  ReworkApply,
  DownloadTemplateExcel,
  UpdateApplyStatus,
} from "../../assets/js/requestApi.js";
import commonUtil from "../../assets/js/commonUtil.js";
import { ElMessageBox } from "element-plus";
import zhCn from "element-plus/dist/locale/zh-cn.mjs";
export default {
  name: "Tcmlist",
  activated() {
    // 以及每次从缓存中被重新插入的时候调用
    this.search();
    this.getUnits();
  },
  deactivated() {
    clearTimeout(this.timer);
    // 在从 DOM 上移除、进入缓存
    // 以及组件卸载时调用
  },
  data() {
    return {
      locale: zhCn,
      TableHeight: window.innerHeight - 235,
      searchForm: {
        CommodityId: "",
        currencyId: "",
        date: "",
        status: "",
        StartDate: "",
        EndDate: "",
        Keyword: "",
        priceType: 0,
        isDraft: 0,
        page: 1,
        limit: 10,
        key: "",
        PageType: 0,
        versionType: 1,
        partNumber: "",
        programName: "",
      },
      // commodity选项
      CommodityOptions: [],
      defaultProps: {
        children: "children",
        label: "Name",
      },
      // status选项
      statusOptions: [
        {
          value: "",
          label: "tcmdatalists.tcmdatalist.All",
        },
        {
          value: 0,
          label: "tcmdatalists.tcmdatalist.notStart",
        },
        {
          value: 1,
          label: "tcmdatalists.tcmdatalist.inProcess",
        },
        {
          value: 2,
          label: "tcmdatalists.tcmdatalist.toApproved",
        },
        {
          value: 4,
          label: "tcmdatalists.tcmdatalist.underApproved",
        },
        {
          value: 5,
          label: "tcmdatalists.tcmdatalist.rejected",
        },
        {
          value: 6,
          label: "tcmdatalists.tcmdatalist.approved",
        },
      ],
      tableData: [],
      // 已选择数据
      chooseData: [],
      // 已选择对比数据
      drawer: false,
      // 选择的对比数据列表
      compareList: [],
      // 总页数
      total: 0,
      pageSize: 10,
      power: [],
      btnAdd: "",
      btnDel: "",
      btnEdit: "",
      timer: "",
      // 数据重复是否继续
      isContinue: false,
      BtnLimits: {},
    };
  },
  mounted() {
    this.TableHeight = window.innerHeight - 235;
    window.onresize = () => {
      return (() => {
        this.TableHeight = window.innerHeight - 235;
      })();
    };
    this.autoHeight();
    this.getList();
    // this.getCommdity();
    // 获取getCommdity
    this.CommodityOptions = JSON.parse(sessionStorage.getItem("Commodity"));
  },

  methods: {
    RowClick(row) {
      if (this.BtnLimits.Detail) {
        this.showDetail(row.id);
      }
    },
    inputDD(val) {
      this.searchForm.limit = val.replace(/[^\d]/g, "");
    },
    autoHeight() {
      // 初始化的时候，设置高度
      this.setHeight();
      // 改变浏览器窗口大小的时候，设置高度
      window.addEventListener("resize", () => {
        this.setHeight();
      });
    },
    setHeight() {
      this.$nextTick(() => {
        this.TableHeight = window.innerHeight - 235; // 这里的X就是顶部栏的高度
      });
    },
    getUnits() {
      this.timer = setInterval(this.gettime, 1000);
    },
    gettime() {
      this.BtnLimits = JSON.parse(sessionStorage.getItem("Btn"));
    },

    getList() {
      this.searchForm = {
        CommodityId: "",
        currencyId: "",
        date: "",
        status: "",
        StartDate: "",
        EndDate: "",
        Keyword: "",
        priceType: 0,
        isDraft: 0,
        page: 1,
        limit: 10,
        key: "",
        PageType: 0,
        versionType: 1,
        partNumber: "",
        programName: "",
      };
      if (this.$route.query.Time) {
        this.searchForm.StartDate = this.$route.query.Time;
        this.searchForm.date = [this.$route.query.Time, this.$route.query.Time];
        this.searchForm.StartDate = this.$route.query.Time;
        this.searchForm.EndDate = this.$route.query.Time;
      }
      if (this.$route.query.pageType) {
        this.searchForm.PageType = this.$route.query.pageType;
      }
      this.search();
    },
    search() {
      getApplicationList(this.searchForm, (response) => {
        if (response && response.code == 200) {
          this.tableData = response.data;
          this.total = response.count;
        }
      });
    },
    // 分页
    handleCurrentChange(val) {
      this.searchForm.page = val;
      this.search();
    },
    // 分页数量修改
    handleSizeChange() {
      this.pageSize = parseInt(this.searchForm.limit);
      if (this.searchForm.limit === "") {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.setPageNum"),
          "",
          "warning"
        );
        return;
      }
      if (this.searchForm.limit == 0) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.pageThanZero"),
          "",
          "warning"
        );
        return;
      }
      this.searchForm.page = 1;
      this.search();
    },
    // 获取商品分类数据
    // getCommdity() {
    //   let _this = this;
    //   GetOrgs(
    //     {
    //       type: 0,
    //       versionType: 0,
    //       TypeCost: 0,
    //       isAll: 0,
    //     },
    //     (response) => {
    //       if (response && response.Code == 200) {
    //         this.CommodityOptions = commonUtil.transferList(response.Result);
    //       }
    //     }
    //   );
    // },
    // 切换时间
    changeDate(val) {
      if (val != null) {
        this.searchForm.StartDate = val[0];
        this.searchForm.EndDate = val[1];
      } else {
        this.searchForm.StartDate = "";
        this.searchForm.EndDate = "";
      }
    },
    // 新增
    addEntry() {
      this.$router.push({
        path: "/tcmAdd",
        name: "TcmAdd",
        query: {
          type: "add",
        },
      });
    },
    // 详情
    showDetail(id) {
      this.$router.push({
        path: "/tcmResult",
        name: "tcmResult",
        query: {
          id: id,
          crumbs: ["homePage.home.Application", "ccls.ccl.detail"],
          //
        },
      });
    },
    // 复制
    copyData(id) {
      if (!id) {
        if (this.chooseData.length == 0) {
          commonUtil.showTipInfo(
            this.$t("userlists.userlist.selecWant"),
            "",
            "warning"
          );
          return;
        }
        if (this.chooseData.length > 1) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.onlyOne"),
            "",
            "warning"
          );
          return;
        }
        id = this.chooseData[0].id;
      }
      this.$router.push({
        path: "/tcmAdd",
        name: "TcmAdd",
        query: {
          type: "editCopy",
          id: id,
        },
      });
    },
    // 编辑
    edit(id) {
      this.$router.push({
        path: "/tcmAdd",
        name: "TcmAdd",
        query: {
          type: "edit",
          id: id,
        },
      });
    },
    // 继续编辑
    editProcess(id) {
      this.$router.push({
        path: "/tcmAdd",
        name: "TcmAdd",
        query: {
          type: "edit",
          id: id,
        },
      });
    },
    // 表格多选
    handleSelectionChange(val) {
      this.chooseData = val;
    },
    // 删除
    deleteApp(id) {
      let ids = [];
      if (!id) {
        if (this.chooseData.length == 0) {
          commonUtil.showTipInfo(
            this.$t("userlists.userlist.selecWant"),
            "",
            "warning"
          );
          return;
        }
        if (this.chooseData.length > 1) {
          commonUtil.showTipInfo(
            this.$t("userlists.userlist.deleteOne"),
            "",
            "warning"
          );
          return;
        }

        if (this.chooseData[0].status !== 0) {
          commonUtil.showTipInfo(
            this.$t("userlists.userlist.deleteNoStart"),
            "",
            "warning"
          );
          return;
        }
        this.chooseData.forEach(function (val, index) {
          ids.push(val.id);
        });
      } else {
        ids.push(id);
      }
      deleteApplyDetail(ids, (response) => {
        if (response && response.code == 200) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.deleteSuccessfully"),
            "",
            "success"
          );
          this.search();
        }
      });
    },
    // 退回 废弃
    toRevoke() {
      if (this.chooseData.length === 0) {
        commonUtil.showTipInfo(
          this.$t("userlists.userlist.selecWant"),
          "",
          "warning"
        );
        return;
      }
      if (this.chooseData.length > 1) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.onlyOne"),
          "",
          "warning"
        );
        return;
      }
      if (this.chooseData[0].status != 6) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.approvedTobe"),
          "",
          "warning"
        );
        return;
      }
      if (this.chooseData[0].status === 6) {
        this.revokeData(this.chooseData[0].id);
      }
    },
    // 拒绝并输入备注
    rejectData(id) {
      let _this = this;
      ElMessageBox.prompt(this.$t("ccls.ccl.pleaseRework"), "", {
        cancelButtonText: this.$t("ccls.ccl.cancel"),
        confirmButtonText: this.$t("ccls.ccl.ok"),
        inputValidator: (value) => {
          if (!value) {
            return _this.$t("unifiedPrompts.unifiedPrompt.remarkNotEmpty");
          }
        },
      })
        .then((info) => {
          if (info.action == "confirm") {
            if (info.value === "" || info.value === null) {
              commonUtil.showTipInfo(
                this.$t("unifiedPrompts.unifiedPrompt.remarkNotEmpty"),
                "",
                "warning"
              );
            } else {
              UpdateApplyStatus(
                {
                  nodeId: 1,
                  flowstatus: -1,
                  status: 2,
                  id: id,
                  comment: info.value,
                },
                (response) => {
                  if (response && response.code == 200) {
                    commonUtil.showTipInfo(
                      this.$t(
                        "unifiedPrompts.unifiedPrompt.operateSuccessfully"
                      ),
                      "",
                      "success"
                    );
                    this.search();
                  }
                }
              );
              done();
            }
          } else {
            done();
          }
        })
        .catch(() => {
          // catch error
        });
    },

    //拒绝
    toReject(id, status) {
      if (!id) {
        if (this.chooseData.length === 0) {
          commonUtil.showTipInfo(
            this.$t("userlists.userlist.selecWant"),
            "",
            "warning"
          );
          return;
        }
        if (this.chooseData.length > 1) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.onlyOne"),
            "",
            "warning"
          );
          return;
        }
        if (this.chooseData[0].status != 6) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.approvedTobe"),
            "",
            "warning"
          );
          return;
        }
        status = this.chooseData[0].status;
        id = this.chooseData[0].id;
      }

      if (status === 6) {
        this.rejectData(id);
      }
    },

    toCallback(id, status) {
      if (!id) {
        if (this.chooseData.length === 0) {
          commonUtil.showTipInfo(
            this.$t("userlists.userlist.selecWant"),
            "",
            "warning"
          );
          return;
        }
        if (this.chooseData.length > 1) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.onlyOne"),
            "",
            "warning"
          );
          return;
        }
        if (this.chooseData[0].status != 6) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.approvedTobe"),
            "",
            "warning"
          );
          return;
        }
        status = this.chooseData[0].status;
        id = this.chooseData[0].id;
      }

      let _this = this;
      ElMessageBox.prompt("Please enter a comment", "", {
        cancelButtonText: this.$t("ccls.ccl.cancel"),
        confirmButtonText: this.$t("ccls.ccl.ok"),
        inputValidator: (value) => {
          if (!value) {
            return _this.$t("Please enter a comment");
          }
        },
      })
        .then((info) => {
          if (info.action == "confirm") {
            if (info.value === "" || info.value === null) {
              commonUtil.showTipInfo(
                this.$t("Please enter a comment"),
                "",
                "warning"
              );
            } else {
              UpdateApplyStatus(
                {
                  nodeId: 1,
                  flowstatus: 2,
                  status: 0,
                  id: id,
                  comment: info.value,
                },
                (response) => {
                  if (response && response.code == 200) {
                    commonUtil.showTipInfo(
                      this.$t(
                        "unifiedPrompts.unifiedPrompt.operateSuccessfully"
                      ),
                      "",
                      "success"
                    );
                    this.search();
                  }
                }
              );
              done();
            }
          } else {
            done();
          }
        })
        .catch(() => {
          // catch error
        });
    },

    // 关闭选择对比数据弹窗
    handleClose(done) {
      done();
      // this.$confirm('确认关闭？')
      // .then(_ => {
      //     done();
      // })
      // .catch(_ => {});
    },
    cancelDrawer() {
      this.drawer = false;
      clearTimeout(this.timer);
    },
    // 去对比
    toCompare(row) {
      if (this.compareList.indexOf(row) > -1) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.compareNotRepeat"),
          "",
          "warning"
        );
      } else if (this.compareList.length == 5) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.compareFive"),
          "",
          "warning"
        );
      } else if (this.compareList.indexOf(row) < 0) {
        this.compareList.push(row);
      }
      this.startCompare();
      // this.drawer = true
    },
    // 取消对比
    cancleCompare(item) {
      this.compareList.splice(this.compareList.indexOf(item), 1);
    },
    // 开始对比
    startCompare() {
      if (this.compareList.length == 0) {
        commonUtil.showTipInfo(
          this.$t("userlists.userlist.selecWant"),
          "",
          "warning"
        );
        return;
      }
      let ids = [];
      this.compareList.forEach(function (val, key) {
        ids.push(val.id);
      });
      this.$router.push({
        path: "/tcmCompare",
        name: "TcmCompare",
        query: {
          ids: JSON.stringify(ids),
        },
      });
    },
    // 导入
    importData(files) {
      let format = new FormData();
      format.append("file", files.raw);
      format.append("Type", 4);
      let excelFormat = new FormData();
      excelFormat.append("file", files.raw);
      excelFormat.append("Type", 4);
      GetDataIsDuplicate(format, (response) => {
        if (response && response.code == 200) {
          // if (response.data) {
          if (response.data == "") {
            excelFormat.append("DataIsDuplicate", "");
            this.importExcel(excelFormat);
          } else {
            // 有重复数据
            ElMessageBox.confirm(
              response.data +
                this.$t("unifiedPrompts.unifiedPrompt.dupImportQue")
            )
              .then(() => {
                excelFormat.append("DataIsDuplicate", "");
                this.isContinue = true;
                this.importExcel(excelFormat);
                done();
              })
              .catch(() => {
                if (!this.isContinue) {
                  excelFormat.append("DataIsDuplicate", response.data);
                  this.importExcel(excelFormat);
                }
                this.isContinue = false;
                // catch error
              });
          }
          // } else {
          // 	commonUtil.showTipInfo(response.message,"", "error");
          // 	this.getList()
          // 	this.$refs.upload.clearFiles()
          // }
        }
      });
    },
    // 导入
    importExcel(format) {
      ImportTemplateMaterialExcel(
        format,
        (response) => {
          if (response && response.code == 200) {
            this.getList();
            this.$refs.upload.clearFiles();
            commonUtil.showTipInfo(
              this.$t("unifiedPrompts.unifiedPrompt.importSuccessfully"),
              "",
              "success"
            );
          }
        },
        (errRes) => {
          if (errRes && errRes.code == 500) {
            ExportDataTableExcel('"' + errRes.data + '"', (res) => {});
          } else {
            commonUtil.showTipInfo(
              this.$t("unifiedPrompts.unifiedPrompt.importFail"),
              "",
              "error"
            );
          }
          this.getList();
          this.$refs.upload.clearFiles();
        }
      );
    },
    // 下载模板
    downTemplate() {
      DownloadTemplateExcel(1, (response) => {});
    },
  },
};
</script>
<style scoped lang="scss">
.main {
  width: 100%;
  // height: calc(100vh - 100px);
  height: 100%;
  background: url("../../assets/img/car-table-bg.png") no-repeat center;
  background-size: contain;
}
.main-top_handle {
  display: flex;
  justify-content: space-between;
  height: 40px;
}
.main-handle {
  width: 80%;
  align-items: center;
}
.main-handle-item {
  margin-right: 10px;
  flex: 1;
  .el-select /deep/ .el-input__wrapper {
    height: 30px;
  }
}
.main-handle input,
.main-handle .el-select .el-input {
  width: 100%;
  border-radius: 10px;
  font-size: 14px;
}
.main-handle input::placeholder {
  color: #3e4954;
  text-align: center;
  font-size: 14px;
}
.handle-btn {
  align-items: center;
  height: 40px;
  .handle-btn-item {
    padding: 0 20px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    background: #b2bde7;
    color: #fff;
    border-radius: 25px;
    cursor: pointer;
    margin-left: 10px;
  }
}
// 日期选择器
.select-box {
  position: relative;
  .select-btn {
    width: 200px;
    height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 10px;
    background: #fff;
    cursor: pointer;
    .Calendar,
    .Switch {
      font-size: 18px;
      color: #2f4cdd;
    }
  }
}
.search-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 30px;
  background: #445581;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
/deep/ .el-table {
  .el-table__body {
    height: 100%;
  }
}
.el-table {
  height: 400px;
}
</style>

<!-- <style scoped lang="scss">
	.ElDrawer {
		:deep(.el-drawer__title){
			font-size: 2rem;
		}
	
	}
	.tcm-search{
		width: calc(100% - var( --asidewidth));
		background-color: var(--searchBg);
		padding: 20px;
		box-sizing: border-box;
		position: fixed;
		z-index:888;
	}
	.tcm-hold{
		height: 155px;
	}
	.tcm-search td{
		/* width: 20%; */
		padding: 0 10px;
	}

	.cell {
		.divLink{
			color:blue;
			cursor: pointer;
			text-decoration: underline;
		}
		.divLink:hover{
			text-decoration: none;
		}
		.opreateList{
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}
	}
	.operate-btn{
		border-radius: 5px;
		background-color: var(--themeColor);
		color: #fff;
		text-align: center;
		padding: 7px 10px;
		display: inline-block;
		min-width: 80px;
		cursor: pointer;
	}
	.color-ff0000{
		color: #ff0000;
	}
	.color-F59A23{
		color: #F59A23;
	}
	.color-0000FF{
		color: #0000FF;
	}
	.color-D9001B{
		color: #D9001B;
	}
	.color-67C23A{
		color:#67C23A ;
	}
	.mlg-10{
		margin-left: 10px
	}
	.mrg-10{
		margin-right: 10px;
	}
	.page-cla{
		padding: 20px;
		display: flex;
		justify-content: flex-end;
	}
	.box-card{
		margin-bottom: 10px;
	}
	.drawer-content{
		display: flex;
		flex-direction: column;
		height: 100%;
	}
	.drawer-footer{
		display: flex;
		justify-content: flex-end;
	}
</style> -->