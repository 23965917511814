<template>
  <div class="main-box">
    <div class="crumbs-box">
      <div class="crumbs-box-item parent" @click="goBack">
        {{ $t("homePage.home.Back") }}
      </div>
      <div class="line"></div>
      <div class="crumbs-box-item" v-for="(item, index) in crumbs">
        {{ $t(item) }}
        <el-icon v-show="index != crumbs.length - 1"><ArrowRight /></el-icon>  
      </div>
    </div>
    <div class="main">
      <div class="setting-item">
        <div class="flex">
          <span class="userName">{{ userName }}：</span>
          <el-radio
            v-model="approver.isCheck"
            :label="true"
            @click.prevent="approverChange"
            border
            >{{
              powers == "en" ? approver.name : approver.chineseName
            }}</el-radio
          >
          <el-radio
            v-model="operator.isCheck"
            :label="true"
            @click.prevent="operatorChange"
            border
            >{{
              powers == "en" ? operator.name : operator.chineseName
            }}</el-radio
          >
          <div>
            <span>{{ $t("other.other.PersonnelTemplate") }}：</span>
            <el-select
              v-model="AcUser"
              :placeholder="$t('other.other.SelectorTemplate')"
              size="size"
              filterable
              style="width: 240px"
              @change="UserTemChange"
            >
              <el-option
                v-for="item in userList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
                <span style="float: left">{{ item.name }}</span>
                <span
                  style="
                    float: right;
                    color: var(--themeColor);
                    font-size: 13px;
                  "
                >
                  {{ $t("changePriceLists.changePriceList.copy") }}
                </span>
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="flex">
          <div class="submit-btn" @click="SaveData">
            {{ $t("changePriceLists.changePriceList.Save") }}
          </div>
          <div class="submit-btn" @click="goBack">
            {{ $t("userlists.userlist.cancel") }}
          </div>
        </div>
      </div>
      <div class="checkbox-title">
        {{ $t("userlists.userlist.FunctionalAuthority") }}:
      </div>

      <el-tree
        ref="treeRef"
        style="max-width: 95%; margin-left: 30px"
        :data="menus"
        show-checkbox
        :props="props"
        node-key="id"
        default-expand-all
        @check="CurrentTreeChange"
        :expand-on-click-node="false"
      >
        <template #default="{ node, data }">
          <div class="custom-tree-node">
            <span>{{ powers == "en" ? node.label : data.chineseName }}</span>
            <div v-show="data.elements" style="padding-left: 20px">
              <template v-for="item in data.elements">
                <el-radio
                  v-model="item.isCheck"
                  @click.prevent="ElementsClick(item, data)"
                  :label="true"
                  size="small"
                  >{{
                    powers == "en" ? item.nameEn : item.chineseName
                  }}</el-radio
                >
                <!-- powers == "en" ? item.name :  -->
              </template>
            </div>
          </div>
        </template>
      </el-tree>
    </div>
  </div>
</template>

<script>
import {
  GetUserSettingMenu,
  SaveUserSettingMenu,
  userList,
} from "@/assets/js/requestApi.js";
import commonUtil from "../../assets/js/commonUtil.js";
export default {
  name: "funSetting",
  components: {},
  watch: {
    "$i18n.locale"(val) {
      this.powers = val;
      // this.ChangeLan();
    },
  },
  data() {
    return {
      crumbs: ["homePage.home.UserManagement", "userlists.userlist.Setting"],
      powers: null,
      userId: "",
      operator: {},
      approver: {},
      menus: [],
      props: {
        label: "name",
        children: "subPages",
        class: this.customNodeClass,
      },
      checkBtn: [],
      showCheck: [],
      userList: [],
      AcUser: "",
      userName: "",
    };
  },

  mounted() {
    this.userId = this.$route.query.id;
    this.userName = this.$route.query.name;

    this.powers = JSON.parse(localStorage.getItem("translate"));
    // this.ChangeLan();
    this.getSetting(this.userId);
    this.GetUserData();
  },
  methods: {
    UserTemChange(val) {
      this.getSetting(val, "change");
    },
    ElementsClick(item, data) {
      item.isCheck = !item.isCheck;
      this.$refs.treeRef.setChecked(data.id, true);
    },
    CurrentTreeChange(data, val) {
      let index = val.checkedKeys.findIndex((item) => item == data.id);
      data.elements.forEach((item) => {
        item.isCheck = index >= 0 ? true : false;
      });
    },
    // 是否按钮全选
    ChekALLBtn(data) {
      data.isChekALLBtn = !data.isChekALLBtn;
      data.elements.forEach((item) => {
        item.isCheck = !item.isCheck;
      });
    },
    customNodeClass(data) {
      if (data.index == 1) {
        return "is-title";
      }
    },
    approverChange() {
      this.approver.isCheck = !this.approver.isCheck;
      // if (this.approver.isCheck) this.operator.isCheck = false;
    },
    operatorChange() {
      this.operator.isCheck = !this.operator.isCheck;
      // if (this.operator.isCheck) this.approver.isCheck = false;
    },
    getSetting(id, flag) {
      GetUserSettingMenu(
        {
          UserId: id,
        },
        (response) => {
          if (response && response.code == 200) {
            this.approver = response.result.operationRole.approver;
            this.operator = response.result.operationRole.operator;
            this.menus = response.result.menus;
            this.menus.forEach((item, index) => {
              item.subPages = item.pages;
              item.index = 1;
            });
            this.GetOnshowCheck(this.menus);
            this.$nextTick(() => {
              this.$refs.treeRef.setCheckedKeys(this.showCheck);
            });
            if (flag) {
              commonUtil.showTipInfo(
                this.$t("unifiedPrompts.unifiedPrompt.operateSuccessfully"),
                "",
                "success"
              );
            }
          }
        }
      );
    },
    GetOnshowCheck(data) {
      if (data) {
        data.forEach((item) => {
          if (item.isCheck == true) {
            if (item.subPages) {
              let index = item.subPages.findIndex((itm) => {
                return itm.isCheck == false;
              });
              if (index < 0) this.showCheck.push(item.id);
            } else {
              this.showCheck.push(item.id);
            }
            // this.showCheck.push(item.id);
            this.GetOnshowCheck(item.subPages);
          } else {
            this.GetOnshowCheck(item.subPages);
          }
        });
      }
    },
    GetUserData() {
      let params = {
        page: 1, //当前页
        limit: 999, //每页个数
        id: "",
      };
      userList(params, (response) => {
        if (response && response.code == 200) {
          this.userList = response.data.reverse();
        }
      });
    },
    // 返回
    goBack() {
      this.$router.back(-1);
    },
    // treeRef
    SaveData() {
      this.checkBtn = [];
      let checkMenus = this.$refs.treeRef.getCheckedKeys();
      let halfCheckedKeys = this.$refs.treeRef.getHalfCheckedKeys();
      checkMenus.unshift.apply(checkMenus, halfCheckedKeys);
      this.getCheckBtnData(this.menus);
      let params = {
        isApprover: this.approver.isCheck,
        isOperator: this.operator.isCheck,
        userId: this.userId,
        checkElementIds: this.checkBtn,
        checkModuleIds: checkMenus,
      };
      SaveUserSettingMenu(params, (response) => {
        if (response && response.code == 200) {
          if (response && response.code == 200) {
            commonUtil.showTipInfo(
              this.$t("unifiedPrompts.unifiedPrompt.operateSuccessfully"),
              "",
              "success"
            );
            this.$router.go(-1);
          }
        }
      });
    },
    getCheckBtnData(data) {
      if (data) {
        data.forEach((item) => {
          if (item.elements) {
            item.elements.forEach((itm, idx) => {
              if (itm.isCheck == true) this.checkBtn.push(itm.id);
            });
            this.getCheckBtnData(item.subPages);
          } else {
            this.getCheckBtnData(item.subPages);
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.main-box {
  height: 100%;
}
.main {
  padding: 40px;
  padding-top: 80px;
  width: 100%;
  min-height: 100%;
  background: url("../../assets/img/car-table-bg.png") no-repeat right;
  background-position: right 4% bottom 0;
  background-size: 650px 600px;
  border-radius: 10px;
  background-color: #fff;
}
.crumbs-box {
  position: fixed;
  top: 70px;
  left: 220px;
  padding-left: 20px;
  width: 100%;
  display: flex;
  background-color: #f2f2f2;
  z-index: 999;
  // border-bottom: 1px solid #e4e7ed;
  align-items: center;
  height: 30px;
  font-size: 16px;
  color: #3e4954;
  .crumbs-box-item {
    display: flex;
    align-items: center;
  }
  .el-icon {
    margin: 0 10px;
  }
  .parent {
    color: #2f4cdd;
    cursor: pointer;
  }
  .line {
    margin: 0 15px;
    width: 2px;
    height: 15px;
    background: #3e4954;
  }
}
.setting-item {
  position: fixed;
  top: 100px;
  width: calc(100% - 315px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 170px;
  height: 60px;
  border-bottom: 1px solid #dcdfe6;
  background: #fff !important;
  z-index: 999;
}
.checkbox-title {
  margin: 20px 0;
  font-size: 17px;
  font-weight: 600;
}
.checkbox-item {
  padding-left: 170px;
}

.handle-box {
  padding-bottom: 10px;
  display: flex;
  justify-content: flex-end;
}
.submit-btn {
  margin-right: 10px;
  padding: 0 30px;
  height: 30px;
  line-height: 30px;
  background: #b2bde7;
  color: #fff;
  text-align: center;
  border-radius: 30px;
  cursor: pointer;
  font-weight: 600;
}
// :deep .is-title .el-tree-node__content {
//   font-size: 18px !important;
//   font-weight: 700;
//   color: red;
// }
:deep .el-tree > .el-tree-node > .el-tree-node__content {
  font-weight: 900;
  font-size: 18px;
  height: 40;
  color: #333333;
}
:deep .el-tree {
  background: transparent;
}

:deep .el-tree-node__expand-icon {
  display: none !important;
}
:deep .el-tree .el-tree-node__content {
  height: 35px;
}
.userName {
  font-size: 18px;
  margin-right: 20px;
}
.custom-tree-node {
  // display: block;
}
:deep .el-tree .el-tree-node__content {
  // height: 60px;
}
.el-radio {
  margin-right: 15px;
  font-size: 14px !important;
  color: #a8abb2 !important;
}
</style>
