// 请求接口APIgatewayUrl
import request from './request.js'
import commonUtil from './commonUtil.js'
import errorCodeEnum from '../enum/errorCodeEnum.js'
// import da from "element-plus/src/locale/lang/da";
import configUtil from '../../../public/config'
import route from '../../router/index'
const baseUrl = configUtil.gatewayUrl
const addressUrl = configUtil.addressGatewayUrl
const logicUrl = configUtil.logicGatewayUrl
let _this = this

/**
 * 获取请求对象
 * @param {*} requestData 请求对象
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 * @param {*} defaultErrorMsg 请求接口默认错误提示
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 */
function sendRequest(requestData, timeout, timeStampFlag, defaultErrorMsg, successCallBack, failCallBack) {
    commonUtil.showLoading(true);
    // requestData.data.businessAppCode = configUtil.businessAppCode;
    if (commonUtil.isEmpty(requestData.headers)) {
        requestData.headers = {};
    }
    requestData.headers["X-Requested-With"] = "XMLHttpRequest";
    requestData.headers["X-Token"] = sessionStorage.getItem('token');
    // requestData.headers["X-Token"] = '34f4dd32';
    // requestData.headers["X-Token"] = '67cbe41e';
    if (requestData.url.indexOf('/api/Calculator_Tables/InputPictures') != -1 || requestData.url.indexOf('/api/ExcelHelper/ImportTemplateMaterialExcel') != -1 || requestData.url.indexOf('/api/ExcelHelper/GetDataIsDuplicate') != -1 || requestData.url.indexOf('/api/EBom_YESPricings/ImportYes') != -1 || requestData.url.indexOf('/api/EBom_NOPricings/ImportNo') != -1) {
        requestData.headers['Content-Type'] = 'multipart/form-data'
    }
    if (requestData.url.indexOf('api/ExcelHelper/ExportTCMDataBaseExcel') > -1
        || requestData.url.indexOf('api/ExcelHelper/ExportTCMDataBaseExcelNew') > -1
        || requestData.url.indexOf('api/CostApplys/DownloadTcmDataExcel') > -1
        || requestData.url.indexOf('api/ExcelHelper/ExportDataTableExcel') > -1
        || requestData.url.indexOf('api/EBom_YESPricings/ExportYes') > -1
        || requestData.url.indexOf('api/EBom_NOPricings/ExportNo') > -1
        || requestData.url.indexOf('api/TemplateMaterials/ExportTemplateMaterial') > -1
        || requestData.url.indexOf('api/TemplatePurchases/ExportTemplatePurchase') > -1
        || requestData.url.indexOf('api/TemplateProcesss/ExportTemplateProcess') > -1
        || requestData.url.indexOf('api/CostApplys/DownloadTemplateExcel') > -1) {
        requestData.responseType = 'arraybuffer'
    }
    if (!isNaN(timeout) && timeout > 0) {
        requestData.timeout = timeout;
    }
    if (timeStampFlag == true) {
        if (requestData.url.indexOf("?") == -1) {
            requestData.url += "?timeStamp=" + new Date().getTime();
        } else {
            requestData.url += "&timeStamp=" + new Date().getTime();
        }
    }
    //是否记录日志参数统一拦截处理
    if (requestData.data && requestData.data.noLogFlag) {
        if (requestData.url.indexOf("?") == -1) {
            requestData.url += "?noLogFlag=1";
        } else {
            requestData.url += "&noLogFlag=1";
        }
    }
    //get请求去除请求体
    if (requestData.method.toUpperCase() == "GET") {
        requestData.data = {};
    } else if (requestData.method.toUpperCase() == "POST") {
        //post 去除请求体中的noLogFlag参数
        delete requestData.data.noLogFlag;
    }
    if (requestData.url.indexOf("#") != -1) {
        requestData.url = requestData.url.replace(/#/g, '%23');
    }
    //重复请求校验
    var index = commonUtil.loadingRequestArray.indexOf(requestData.url);
    if (index > -1) {
        // return;
    } else {
        commonUtil.loadingRequestArray.push(requestData.url);
    }
    var response = request(requestData);
    var errorMsg = "";
    var fileName = requestData.data.fileName;
    delete requestData.data.fileName;
    response.then(responseData => {
        if (requestData.responseType == 'blob') {
            try {
                commonUtil.downloadFileByBlob(responseData, fileName);
            } catch (error) {
                errorMsg = defaultErrorMsg;
            }
        } else {
            // if (requestData.url.indexOf('api/ExcelHelper/ExportTCMDataBaseExcel') > -1) {
            //     try {
            //         commonUtil.downloadFileByArryBuffer(responseData, 'TCMDataExcel', 'xlsm')
            //     } catch (error) {
            //         errorMsg = defaultErrorMsg;
            //     }
            //     commonUtil.showLoading(false);
            // } else 
            if (requestData.url.indexOf('api/ExcelHelper/ExportDataTableExcel') > -1) {
                try {
                    commonUtil.downloadFileByArryBuffer(responseData, '导入Excel错误数据')
                } catch (error) {
                    errorMsg = defaultErrorMsg;
                }
                commonUtil.showLoading(false);
            } else if (requestData.url.indexOf('api/ExcelHelper/ExportTCMDataBaseExcelNew') > -1) {
                try {
                    let tcmExportFileName = sessionStorage.getItem('tcmExportFileName');
                    commonUtil.downloadFileByArryBuffer(responseData, tcmExportFileName, 'xlsm')
                } catch (error) {
                    errorMsg = defaultErrorMsg;
                }
                commonUtil.showLoading(false);
            } else if (requestData.url.indexOf('api/CostApplys/DownloadTcmDataExcel') > -1) {
                try {
                    let tcmExportFileName = sessionStorage.getItem('tcmExportFileName');
                    commonUtil.downloadFileByArryBuffer(responseData, tcmExportFileName, 'xlsx')
                } catch (error) {
                    errorMsg = defaultErrorMsg;
                }
                commonUtil.showLoading(false);
            } else if (requestData.url.indexOf('api/EBom_YESPricings/ExportYes') > -1 || requestData.url.indexOf('api/EBom_NOPricings/ExportNo') > -1
                || requestData.url.indexOf('api/TemplateMaterials/ExportTemplateMaterial') > -1 || requestData.url.indexOf('api/TemplatePurchases/ExportTemplatePurchase') > -1
                || requestData.url.indexOf('api/TemplateProcesss/ExportTemplateProcess') > -1
            ) {
                try {
                    let ExportYesName = sessionStorage.getItem('tcmExportFileName');
                    commonUtil.downloadFileByArryBuffer(responseData, ExportYesName, 'xlsx')
                } catch (error) {
                    errorMsg = defaultErrorMsg;
                }
                commonUtil.showLoading(false);
            } else if (requestData.url.indexOf('api/CostApplys/DownloadTemplateExcel') > -1) {
                try {
                    let templateName = 'Application Template'
                    if (requestData.data == 8) templateName = 'CBD Template'
                    if (requestData.data == 2) templateName = 'Processing Template'
                    if (requestData.data == 3) templateName = 'Components Template'
                    if (requestData.data == 4) templateName = 'Material Template'
                    if (requestData.data == 7) templateName = 'eBom Template'
                    if (requestData.data == 6) templateName = 'No Price Template'
                    if (requestData.data == 5) templateName = 'Yes Price Template'
                    // let TcmTemplateExcel = sessionStorage.getItem('tcmExportFileName');
                    commonUtil.downloadFileByArryBuffer(responseData, templateName, 'xlsx')
                } catch (error) {
                    errorMsg = defaultErrorMsg;
                }
                commonUtil.showLoading(false);
            } else {
                // eslint-disable-next-line no-prototype-builtins
                if (
                    responseData.successful || (responseData.hasOwnProperty("code") && responseData.code == errorCodeEnum.OK) || (responseData.hasOwnProperty("Code") && responseData.Code == errorCodeEnum.OK)
                    || responseData.successful || (responseData.hasOwnProperty("code") && responseData.code == errorCodeEnum.NexteerPart_ebomList) || (responseData.hasOwnProperty("Code") && responseData.Code == errorCodeEnum.NexteerPart_ebomList)
                    || responseData.successful || (responseData.hasOwnProperty("code") && responseData.code == errorCodeEnum.NexteerPart_YesPrice) || (responseData.hasOwnProperty("Code") && responseData.Code == errorCodeEnum.NexteerPart_YesPrice)
                    || responseData.successful || (responseData.hasOwnProperty("code") && responseData.code == errorCodeEnum.NexteerPart_NoPrice) || (responseData.hasOwnProperty("Code") && responseData.Code == errorCodeEnum.NexteerPart_NoPrice)
                    || responseData.successful || (responseData.hasOwnProperty("code") && responseData.code == errorCodeEnum.NexteerPart_ebom) || (responseData.hasOwnProperty("Code") && responseData.Code == errorCodeEnum.NexteerPart_ebom)
                    || responseData.successful || (responseData.hasOwnProperty("code") && responseData.code == errorCodeEnum.seq_ct) || (responseData.hasOwnProperty("Code") && responseData.Code == errorCodeEnum.seq_ct)
                    || responseData.successful || (responseData.hasOwnProperty("code") && responseData.code == errorCodeEnum.machineType_ct) || (responseData.hasOwnProperty("Code") && responseData.Code == errorCodeEnum.machineType_ct)
                    || responseData.successful || (responseData.hasOwnProperty("code") && responseData.code == errorCodeEnum.hasNoData_ct) || (responseData.hasOwnProperty("Code") && responseData.Code == errorCodeEnum.hasNoData_ct)
                    || responseData.successful || (responseData.hasOwnProperty("code") && responseData.code == errorCodeEnum.Invalid_Account) || (responseData.hasOwnProperty("Code") && responseData.Code == errorCodeEnum.Invalid_Account)
                    || responseData.successful || (responseData.hasOwnProperty("code") && responseData.code == errorCodeEnum.toolName_ct) || (responseData.hasOwnProperty("Code") && responseData.Code == errorCodeEnum.toolName_ct)
                    || responseData.code == 300
                ) {
                    if (typeof (successCallBack) == "function") {
                        successCallBack(responseData);
                    }
                } else {
                    if (commonUtil.isEmpty(responseData.code) || (responseData.Code && commonUtil.isEmpty(responseData.Code))) {
                        errorMsg = defaultErrorMsg + "," + responseData.message && responseData.message !== undefined ? responseData.message : '' + responseData.msg && responseData.msg !== undefined ? responseData.msg : '';
                        commonUtil.showTipInfo(errorMsg, "", "error");
                    } else {
                        errorMsg = defaultErrorMsg + "," + responseData.message && responseData.message !== undefined ? responseData.message : '' + responseData.msg && responseData.msg !== undefined ? responseData.msg : '';
                        commonUtil.showTipInfo(errorMsg, "", "error");
                    }
                    if (typeof (failCallBack) == "function") {
                        failCallBack(responseData);
                    }
                }
            }

        }
        index = commonUtil.loadingRequestArray.indexOf(requestData.url);
        commonUtil.loadingRequestArray.splice(index, 1);
        commonUtil.showLoading(false);
    }, error => {
        if (String(error).indexOf("timeout") > 0) {
            commonUtil.showTipInfo(defaultErrorMsg + "," + "请求超时", "", "error");
        } else {

            if (error.response.status == '401') {
                sessionStorage.setItem('token', '')
                route.replace({ path: "/login" });

            } else {
                commonUtil.showTipInfo(error, "", "error");
            }
            // if (error.response.status == '300') {
            //     failCallBack(responseData);
            // } 
        }
        commonUtil.loadingRequestArray.splice(index, 1);
        commonUtil.showLoading(false);
    });
}

/**
 * 根据url发送请求
 * @param {*} url 接口URL地址
 * @param {*} data 接口请求对象
 * @param {*} requestType 接口请求类型
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function sendRequestByUrl(url, data, requestType, successCallBack, failCallBack, timeout, timeStampFlag) {
    commonUtil.showLoading(true);
    var requestData = {
        url: url,
        method: requestType || "get",
        data: data
    };
    // requestData.data.businessAppCode = configUtil.businessAppCode;
    if (commonUtil.isEmpty(requestData.headers)) {
        requestData.headers = {};
    }
    requestData.headers["X-Requested-With"] = "XMLHttpRequest";
    // requestData.headers._at = configUtil.userToken;
    //防止IE 预检请求报错
    requestData.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    if (!isNaN(timeout) && timeout > 0) {
        requestData.timeout = timeout;
    }
    if (timeStampFlag == true) {
        if (requestData.url.indexOf("?") == -1) {
            requestData.url += "?timeStamp=" + new Date().getTime();
        } else {
            requestData.url += "&timeStamp=" + new Date().getTime();
        }
    }
    if (requestData.url.indexOf("#") != -1) {
        requestData.url = requestData.url.replace(/#/g, '%23');
    }
    //重复请求校验
    var index = commonUtil.loadingRequestArray.indexOf(requestData.url);
    if (index > -1) {
        return;
    } else {
        commonUtil.loadingRequestArray.push(requestData.url);
    }
    var response = request(requestData);
    response.then(responseData => {
        //接口返回重定向地址重新发送请求
        if (responseData.ResultCode == errorCodeEnum.REDIRECT_CODE) {
            index = commonUtil.loadingRequestArray.indexOf(requestData.url);
            commonUtil.loadingRequestArray.splice(index, 1);
            sendRequestByUrl(responseData.Location, {}, "get", successCallBack, failCallBack);
        } else {
            // eslint-disable-next-line no-prototype-builtins
            if (responseData.successful || (responseData.hasOwnProperty("resultCode") && responseData.resultCode == errorCodeEnum.OK ||
                // eslint-disable-next-line no-prototype-builtins
                (responseData.hasOwnProperty("ResultCode") && responseData.ResultCode == errorCodeEnum.OK))) {
                if (typeof (successCallBack) == "function") {
                    successCallBack(responseData);
                }
            } else {
                if (typeof (failCallBack) == "function") {
                    failCallBack(responseData);
                }
            }
            index = commonUtil.loadingRequestArray.indexOf(requestData.url);
            commonUtil.loadingRequestArray.splice(index, 1);
            commonUtil.showLoading(false);
        }
    }, error => {
        if (String(error).indexOf("timeout") > 0) {
            commonUtil.showTipInfo("请求超时", "", "error", 3);
        } else {
            commonUtil.showTipInfo("服务端无响应", "", "error", 3);
        }
        commonUtil.loadingRequestArray.splice(index, 1);
        commonUtil.showLoading(false);
    });
}
/**
 * 工艺计算器-查询
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function getCalculatorList(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Calculator_Tables/Load?CommodityId=" + data.CommodityId + '&Enable=' + data.Enable + '&Keyword=' + data.Keyword + '&page=' + data.page + '&limit=' + data.limit + '&key=' + data.key,
        method: 'get',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "查询失败", successCallBack, failCallBack);
}
/**
 * 工艺计算器-树查询
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function GetOrgs(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CommodityTree/GetOrgs?type=" + data.type + '&versionType=' + data.versionType + '&TypeCost=' + data.TypeCost + '&isAll=' + data.isAll,
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "查询失败", successCallBack, failCallBack);
}
/**
 * 工艺计算器-新增
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function AddOrUpdate(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Calculator_Tables/AddOrUpdate",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "新增失败", successCallBack, failCallBack);
}
/**
 * 工艺计算器-计算结果
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function CalculationFormulaResult(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Calculator_Tables/CalculationFormulaResult?Formula=" + data.Formula,
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "计算失败", successCallBack, failCallBack);
}
/**
 * 工艺计算器-根据id获取详情
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function GetCalculatorDetail(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Calculator_Tables/GetCalculatorDetail",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "查询失败", successCallBack, failCallBack);
}
/**
 * 工艺计算器-删除
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function DeleteCalculatorDetail(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Calculator_Tables/Delete_IsDeleted",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "删除失败", successCallBack, failCallBack);
}
/**
 * 工艺计算器-批量启用
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function EnableByIds(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Calculator_Tables/EnableByIds",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "启用失败", successCallBack, failCallBack);
}
/**
 * 工艺计算器-批量禁用
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function DisableByIds(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Calculator_Tables/DisableByIds",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "禁用失败", successCallBack, failCallBack);
}
/**
 * 工艺计算器-上传文件
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function uploadFile(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Calculator_Tables/InputPictures",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "上传失败", successCallBack, failCallBack);
}
/**
 * 工艺计算器-计算结果
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function InputParameterAnalysis(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Calculator_Tables/InputParameterAnalysis",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "计算失败", successCallBack, failCallBack);
}
/**
 * 登录
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function login(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Check/Login",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "登录失败", successCallBack, failCallBack);
}
/**
 * 根据输入的账号获取用户信息(是否为供应商)
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function JudgeAccountIsSupplier(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Users/JudgeAccountIsSupplier",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * 忘记密码
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function ForgetPassword(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Users/ForgetPassword",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "登录失败", successCallBack, failCallBack);
}
/**
 * 退出登录
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function logout(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Check/Logout",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "退出登录失败", successCallBack, failCallBack);
}
/**
 * 主页待办
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function needtobedealtwith(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CostApplys/GetAgency",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "获取失败", successCallBack, failCallBack);
}
/**
 * 获取菜单
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function GetModulesTree(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Check/GetModulesTree",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "获取失败", successCallBack, failCallBack);
}
/**
 * 申请单--查询
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function getApplicationList(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CostApplys/Load",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "获取失败", successCallBack, failCallBack);
}
/**
 * tcm数据库-启用禁用
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function tcmDataDisableByIds(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CostApplys/DisableByIds",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "获取失败", successCallBack, failCallBack);
}
/**
 * 查询tcm数据库临时表
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function getApplicationListCursor(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CostApplys/Load_Cursor",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "获取失败", successCallBack, failCallBack);
}
/**
 * 申请单--报价类型
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function getQuoteTypes(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/QuoteTypes/Load?Keyword=" + data.Keyword + '&isEnable=' + data.isEnable + '&page=' + data.page + '&isEnable=' + data.isEnable + '&limit=' + data.limit + '&key=' + data.key,
        method: 'get',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "获取失败", successCallBack, failCallBack);
}
/**
 * 申请单--区域列表
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function getRegions(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Region/Load",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "获取失败", successCallBack, failCallBack);

}
/**
 * 申请单--等级
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function getCategorys(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Categorys/Load?Keyword=" + data.Keyword + '&page=' + data.page + '&limit=' + data.limit + '&key=' + data.key + '&TypeID=' + data.TypeID,
        method: 'get',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "获取失败", successCallBack, failCallBack);
}
/**
 * 加载首页首页有duedata申请单的日期列表
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function getCalendar(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CostApplys/LoadDueDateForHome",
        method: 'get',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "获取失败", successCallBack, failCallBack);
}
/**
 *  首页清单列表
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function LoadForHome(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CostApplys/LoadForHome",
        method: 'get',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "获取失败", successCallBack, failCallBack);
}
/**
 * 申请单--新增
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function AddOrUpdateForAppllicant(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CostApplys/AddOrUpdateForAppllicant",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "新增失败", successCallBack, failCallBack);
}
/**
 * 申请单--获取详情
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function GetApplyDetail(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CostApplys/GetApplyDetail",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "查询失败", successCallBack, failCallBack);
}
/**
 * 申请单--删除
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function deleteApplyDetail(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CostApplys/Delete",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "删除失败", successCallBack, failCallBack);
}
/**
 * 申请单--查询税率表区域选项
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function GetSelCurrency(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/ExchangeRatess/GetSelCurrency",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "查询失败", successCallBack, failCallBack);
}

/**
 * 申请单--查询税率
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function GetRate(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CostApplys/GetRate?currency=" + data.currency + '&reportCurrency=' + data.reportCurrency,
        method: 'get',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "查询失败", successCallBack, failCallBack);
}
/**
 * 物料管理--列表查询
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function GetTemplateMaterials(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/TemplateMaterials/Load?Keyword=" + data.Keyword + '&RegionId=' + data.RegionId + '&CommodityId=' + data.CommodityId + '&currencyId=' + data.currencyId + '&Enable=' + data.Enable + '&StartDate=' + data.StartDate + '&EndDate=' + data.EndDate + '&VersionType=' + data.VersionType + '&page=' + data.page + '&limit=' + data.limit,
        method: 'get',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "查询失败", successCallBack, failCallBack);


}
/**
 * 物料管理--新增、编辑
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function AddOrUpdateTemplateMaterials(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/TemplateMaterials/AddOrUpdate",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * 物料管理--获取详情
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function getTemplateMaterialsDetail(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/TemplateMaterials/Get?id=" + data.id,
        method: 'get',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "查询失败", successCallBack, failCallBack);
}
/**
 * 物料管理--删除
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function deleteTemplateMaterials(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/TemplateMaterials/Delete",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "删除失败", successCallBack, failCallBack);
}
/**
 * 物料管理--批量启用
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function startTemplateMaterials(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/TemplateMaterials/EnableByIds",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * 物料管理--批量禁用
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function stopTemplateMaterials(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/TemplateMaterials/DisableByIds",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * 模板导入
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function ImportTemplateMaterialExcel(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/ExcelHelper/ImportTemplateMaterialExcel",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "导入失败", successCallBack, failCallBack);
}
/**
 * 工艺管理--列表查询
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function GetTemplateProcesss(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/TemplateProcesss/Load?Keyword=" + data.Keyword + '&RegionId=' + data.RegionId + '&CommodityId=' + data.CommodityId + '&currencyId=' + data.currencyId + '&Enable=' + data.Enable + '&StartDate=' + data.StartDate + '&EndDate=' + data.EndDate + '&VersionType=' + data.VersionType + '&page=' + data.page + '&limit=' + data.limit,
        method: 'get',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "查询失败", successCallBack, failCallBack);
}
/**
 * 工艺管理--新增
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function AddOrUpdateTemplateProcesss(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/TemplateProcesss/AddOrUpdate",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * 工艺管理--获取详情
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function getTemplateProcesssDetail(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/TemplateProcesss/Get?id=" + data.id,
        method: 'get',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "查询失败", successCallBack, failCallBack);
}
/**
 * 工艺管理--删除
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function deleteTemplateProcesss(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/TemplateProcesss/Delete",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "删除失败", successCallBack, failCallBack);
}
/**
 * 工艺管理--批量启用
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function startTemplateProcesss(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/TemplateProcesss/EnableByIds",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * 工艺管理--批量禁用
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function stopTemplateProcesss(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/TemplateProcesss/DisableByIds",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * 采购管理--列表查询
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function GetTemplatePurchases(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/TemplatePurchases/Load?Keyword=" + data.Keyword + '&RegionId=' + data.RegionId + '&CommodityId=' + data.CommodityId + '&currencyId=' + data.currencyId + '&Enable=' + data.Enable + '&StartDate=' + data.StartDate + '&EndDate=' + data.EndDate + '&VersionType=' + data.VersionType + '&page=' + data.page + '&limit=' + data.limit,
        method: 'get',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "查询失败", successCallBack, failCallBack);


}
/**
 * 采购管理--删除
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function deleteTemplatePurchases(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/TemplatePurchases/Delete",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "删除失败", successCallBack, failCallBack);
}
/**
 * 采购管理--批量启用
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function startTemplatePurchases(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/TemplatePurchases/EnableByIds",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * 采购管理--批量禁用
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function stopTemplatePurchases(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/TemplatePurchases/DisableByIds",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * 采购管理--新增
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function AddOrUpdateTemplatePurchases(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/TemplatePurchases/AddOrUpdate",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * 采购管理--获取详情
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function getTemplatePurchasesDetail(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/TemplatePurchases/Get?id=" + data.id,
        method: 'get',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "查询失败", successCallBack, failCallBack);
}
/**
 * 模板管理--列表查询
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function GetTemplateApplys(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/TemplateApplys/Load?Keyword=" + data.Keyword + '&regionId=' + data.regionId + '&CommodityId=' + data.CommodityId + '&currencyId=' + data.currencyId + '&Enable=' + data.Enable + '&StartDate=' + data.StartDate + '&EndDate=' + data.EndDate + '&isDraft=' + data.isDraft + '&VersionType=' + data.VersionType + '&page=' + data.page + '&limit=' + data.limit,
        method: 'get',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "查询失败", successCallBack, failCallBack);


}
/**
 * 模板管理--删除
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function deleteTemplateApplys(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/TemplateApplys/Delete",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "删除失败", successCallBack, failCallBack);
}
/**
 * 模板管理--批量启用
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function startTemplateApplys(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/TemplateApplys/EnableByIds",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * 模板管理--批量禁用
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function stopTemplateApplys(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/TemplateApplys/DisableByIds",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * 模板管理--新增、编辑
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function AddOrUpdateTemplateApplys(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/TemplateApplys/AddOrUpdate",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * 模板管理--获取详情
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function GetTemplateOrDraftDetail(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/TemplateApplys/GetTemplateOrDraftDetail?id=" + data.id,
        method: 'get',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * 角色管理——获取登陆用户可访问角色
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function roleList(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Roles/LoadAll",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "获取失败", successCallBack, failCallBack);
}

/**
 * 角色管理——添加角色
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function addRole(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Roles/Add",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "添加失败", successCallBack, failCallBack);
}
/**
 * 角色管理——加载用户的角色
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function loadRole(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Roles/LoadForUser",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "加载失败", successCallBack, failCallBack);
}
/**
 * 角色管理——删除用户的角色
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function delRole(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Roles/Delete",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "删除失败", successCallBack, failCallBack);
}
/**
 * 角色管理——为角色分配模块
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function assignRole(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/AccessObjs/AssignRoleUsers",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "获取失败", successCallBack, failCallBack);
}
/**
 * 角色管理——修改
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function editRole(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Roles/Update",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "修改失败", successCallBack, failCallBack);
}
/**
 * 角色管理——分配角色渲染
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function treeRole(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Roles/Update",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "修改失败", successCallBack, failCallBack);
}
/**
 * 角色管理——分配模块渲染
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function moudleRole(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Check/GetModules",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "修改失败", successCallBack, failCallBack);
}
/**
 * 角色管理——回显分配角色渲染
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function showTreeRole(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Users/LoadByRole",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "加载失败", successCallBack, failCallBack);
}
/**
 * 角色管理——查看已分配模块
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function lookmoudleRole(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Modules/LoadForRole",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "修改失败", successCallBack, failCallBack);
}
/**
 * 角色管理——分配模块
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function assignMoudle(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/AccessObjs/Assign",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "加载失败", successCallBack, failCallBack);
}
/**
 * 角色管理——取消分配模块
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function cancelMoudle(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/AccessObjs/UnAssign",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "取消失败", successCallBack, failCallBack);
}
/**
 * 角色管理——分配模块-菜单渲染
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function assignMoudleList(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Modules/LoadMenus",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "修改失败", successCallBack, failCallBack);
}
/**
 * 用户管理——新增用户
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function addUser(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Users/AddOrUpdate",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "添加失败", successCallBack, failCallBack);
}
/**
 * 用户管理——删除
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function delUser(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Users/Delete",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "加载失败", successCallBack, failCallBack);
}
/**
 * 用户管理——编辑用户
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function editUser(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Users/AddOrUpdate",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "添加失败", successCallBack, failCallBack);
}
/**
 * 用户管理——用户列表
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function userList(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Users/LoadAll?comId=" + data.id,
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "加载失败", successCallBack, failCallBack);
}
/**
 * 用户管理——用户编辑部门回显
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function echoList(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Users/SelUserOrgs",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "加载失败", successCallBack, failCallBack);
}
/**
 * 用户管理——用户编辑商品分类回显
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function echoCommodity(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Users/SelUserCommoditys",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "加载失败", successCallBack, failCallBack);
}
/**
 * 用户管理——用户编辑地区回显
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function echoSelUserRegions(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Users/SelUserRegions",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "加载失败", successCallBack, failCallBack);
}
/**
 * 部门管理——树渲染
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function treeDepartment(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Orgs/Load?orgid=" + data.id,
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "加载失败", successCallBack, failCallBack);
}

/**
 * 部门管理——列表
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function DepartmentList(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Check/GetSubOrgs?page=" + data.page + "&limit=" + data.limit + "&orgId=" + data.id,
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "加载失败", successCallBack, failCallBack);
}

/**
 * 部门管理——新增
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function addDepartment(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Orgs/Add",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "添加失败", successCallBack, failCallBack);
}
/**
 * 部门管理——删除
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function delDepartment(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Orgs/Delete",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "删除失败", successCallBack, failCallBack);
}
/**
 * 部门管理——编辑
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function editDepartment(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Orgs/Update",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "删除失败", successCallBack, failCallBack);
}

/**
 * 用户组——获取用户组列表
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function userGroupList(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/UserGroupManagements/Load",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "获取失败", successCallBack, failCallBack);
}
/**
 * 用户组——添加
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function addGroupList(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/UserGroupManagements/AddOrUpdate",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "加载失败", successCallBack, failCallBack);
}
/**
 * 用户组——删除
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function delGroupList(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/UserGroupManagements/Delete_IsDeleted",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "删除失败", successCallBack, failCallBack);
}
/**
 * 用户组——编辑
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function editGroup(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/UserGroupManagements/AddOrUpdate",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "编辑失败", successCallBack, failCallBack);
}
/**
 * 操作-更改申请状态
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function updateStatusCostApplys(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CostApplys/UpdateStatus",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * 操作-更改申请状态(改为未开始，并清空数据)
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function ReworkApply(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CostApplys/ReworkApply",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * 操作-保存
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function UpdateCostApplyForOperateReq(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CostApplys/UpdateCostApplyForOperateReq",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * 区域管理-查询
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function loadRegions(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Region/Load",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "查询失败", successCallBack, failCallBack);
}
/**
 * 区域管理-新增、编辑
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function AddOrUpdateRegions(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Region/AddOrUpdate",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * 区域管理-删除
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function deleteOneRegions(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Region/Delete_IsDeleted",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * 区域管理-批量删除
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function deleteRegions(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Region/Delete",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * 区域管理-批量启用
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function EnableByIdsRegions(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Region/EnableByIds",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * 区域管理-批量禁用
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function DisableByIdsRegions(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Region/DisableByIds",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * 报价分类管理-查询
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function loadQuoteTypes(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/QuoteTypes/Load?Keyword=" + data.Keyword + '&page=' + data.page + '&limit=' + data.limit,
        method: 'get',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "查询失败", successCallBack, failCallBack);
}
/**
 * 报价分类管理-新增、编辑
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function AddOrUpdateQuoteTypes(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/QuoteTypes/AddOrUpdate",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * 报价分类管理-删除
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function deleteOneQuoteTypes(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/QuoteTypes/Delete_IsDeleted",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * 报价分类管理-批量删除
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function deleteQuoteTypes(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/QuoteTypes/Delete",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * 报价分类管理-批量启用
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function EnableByIdsQuoteTypes(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/QuoteTypes/EnableByIds",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * 报价分类管理-批量禁用
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function DisableByIdsQuoteTypes(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/QuoteTypes/DisableByIds",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * 税率管理-查询
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function loadExchangeRatess(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/ExchangeRatess/Load?Keyword=" + data.Keyword + '&VersionType=' + data.VersionType + '&page=' + data.page + '&limit=' + data.limit,
        method: 'get',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "查询失败", successCallBack, failCallBack);
}
/**
 * 汇率管理-新增、编辑
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function AddOrUpdateExchangeRatess(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/ExchangeRatess/AddOrUpdate",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * 汇率管理-删除
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function deleteOneExchangeRatess(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/ExchangeRatess/Delete_IsDeleted",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * 汇率管理-批量删除
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function deleteExchangeRatess(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/ExchangeRatess/Delete",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * 汇率管理-批量启用
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function EnableByIdsExchangeRatess(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/ExchangeRatess/EnableByIds",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * 汇率管理-批量禁用
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function DisableByIdsExchangeRatess(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/ExchangeRatess/DisableByIds",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * 商品列表--列表
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function CommodityLsit(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CommodityLists/GetOrgsList?CommId=" + data.id + "&page=" + data.page + "&limit=" + data.limit + "&Keyword=" + data.Keyword,
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "加载失败", successCallBack, failCallBack);
}
/**
 * 商品列表--添加
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function addCommodity(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CommodityLists/Add",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "添加失败", successCallBack, failCallBack);
}
/**
 * 商品列表--删除
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function delCommodity(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CommodityLists/Delete",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "添加失败", successCallBack, failCallBack);
}
/**
 * 商品列表--编辑
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function editCommodity(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CommodityLists/Update",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "编辑失败", successCallBack, failCallBack);
}
/**
 * 菜单树状
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function moduleTree(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Check/GetModules",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "加载失败", successCallBack, failCallBack);
}
/**
 * 菜单列表
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function moduleList(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Modules/Load",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "加载失败", successCallBack, failCallBack);
}
/**
 * 菜单添加
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function addmodule(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Modules/Add",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "添加失败", successCallBack, failCallBack);
}
/**
 * 菜单编辑
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function editmodule(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Modules/Update",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "编辑失败", successCallBack, failCallBack);
}
/**
 * 菜单删除
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function delsmodule(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Modules/Delete",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "删除失败", successCallBack, failCallBack);
}
/**
 * 获取菜单已有按钮
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function butttonList(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Modules/LoadMenus",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "获取失败", successCallBack, failCallBack);
}
/**
 * 新增按钮
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function addButton(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Modules/AddMenu",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "新增失败", successCallBack, failCallBack);
}
/**
 * 编辑按钮
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function editButton(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Modules/UpdateMenu",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "编辑失败", successCallBack, failCallBack);
}
/**
 * 删除按钮
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function delButton(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Modules/DeleteMenu",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "删除失败", successCallBack, failCallBack);
}
/**
 * 回显按钮
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function echoButton(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Modules/LoadMenusForRole",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "获取失败", successCallBack, failCallBack);
}
/**
 * 修改密码
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function changepassword(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Users/ChangePassword",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "删除失败", successCallBack, failCallBack);
}
/**
 * 货币管理-查询
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function loadCurrency(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Currency/Load?Keyword=" + data.Keyword + '&IsOrderNumber=' + data.IsOrderNumber + '&page=' + data.page + '&limit=' + data.limit,
        method: 'get',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "查询失败", successCallBack, failCallBack);
}
/**
 * 货币管理-新增、编辑
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function AddOrUpdateCurrency(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Currency/AddOrUpdate",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * 货币管理-删除
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function deleteOneCurrency(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Currency/Delete_IsDeleted",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * 货币管理-批量删除
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function deleteCurrency(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Currency/Delete",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * 货币管理-批量启用
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function EnableByIdsCurrency(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Currency/EnableByIds",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * 货币管理-批量禁用
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function DisableByIdsCurrency(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Currency/DisableByIds",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * 历史邮箱管理-查询
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function loadMailHistory(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/MailHistory/Load?Keyword=" + data.Keyword + '&page=' + data.page + '&limit=' + data.limit,
        method: 'get',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "查询失败", successCallBack, failCallBack);
}
/**
 * 历史邮箱管理-新增
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function AddOrUpdateMailHistory(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/MailHistory/AddOrUpdate",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "新增失败", successCallBack, failCallBack);
}
/**
 * 历史邮箱管理-删除
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function DeleteMailHistory(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/MailHistory/Delete",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "删除失败", successCallBack, failCallBack);
}
/**
 * 板材管理-查询
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function loadCCLSizeDetails(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CCLSizeDetails/Load?page=" + data.page + '&limit=' + data.limit,
        method: 'get',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "查询失败", successCallBack, failCallBack);
}
/**
 * 板材管理-新增编辑
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function addCCLSizeDetails(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CCLSizeDetails/AddOrUpdate",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * 板材管理-删除
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function DeleteCCLSizeDetails(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CCLSizeDetails/Delete",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * 板材管理-批量启用
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function CCLSizeDetailsEnableByIds(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CCLSizeDetails/EnableByIds",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * 板材管理-批量禁用
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function CCLSizeDetailsDisableByIds(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CCLSizeDetails/DisableByIds",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * DirectMaterial-查询
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function loadDirectMaterial(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Direct_Materials/Load?page=" + data.page + '&limit=' + data.limit,
        method: 'get',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "查询失败", successCallBack, failCallBack);
}
/**
 * DirectMaterial-查询(修改)
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function GetDirectMaterialList(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Direct_Materials/GetDirectMaterialList",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "查询失败", successCallBack, failCallBack);
}

/**
 * DirectMaterial-添加
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function DirectMaterialAddOrUpdate(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "/api/Direct_Materials/AddOrUpdate",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * DirectMaterial-添加(修改)
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function AddDirectMaterial(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Direct_Materials/Add",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * DirectMaterial-批量启用
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function DirectMaterialEnableByIds(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Direct_Materials/EnableByIds",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * DirectMaterial-批量禁用
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function DirectMaterialDisableByIds(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Direct_Materials/DisableByIds",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * DirectMaterial-修改
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function UpdateDirectMaterial(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Direct_Materials/Update",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * ProcessIndirect-查询
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function loadProcessIndirect(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/ProcessIndirects/Load?page=" + data.page + '&limit=' + data.limit,
        method: 'get',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "查询失败", successCallBack, failCallBack);
}
/**
 * ProcessIndirect-修改
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function UpdateProcessIndirect(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/ProcessIndirects/AddOrUpdate",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * ProcessIndirect-批量启用
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function ProcessIndirectEnableByIds(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/ProcessIndirects/EnableByIds",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * ProcessIndirect-批量禁用
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function ProcessIndirectDisableByIds(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/ProcessIndirects/DisableByIds",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * ProcessIndirect-删除
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function ProcessIndirectDelete(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/ProcessIndirects/Delete",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * CCLSizes-查询
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function loadCCLSizes(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CCLSizes/Load",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "查询失败", successCallBack, failCallBack);
}
/**
 * CCLSizes-计算
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function GetTotalCost(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CCLSizes/GetTotalCost",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * CCLSizes-获取计算过程数据
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function GetHistoryListCCL(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CCLSizes/GetHistoryList",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * CCLSizes-根据cclsize获取type和供应商选项
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function GetTypeListBySize(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Direct_Materials/GetTypeListBySize",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * CCLSizes-查询详情
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function GetCCLSizes(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CCLSizes/Get?id=" + data.id,
        method: 'get',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * CCLSizes-新增
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function AddOrUpdateCCLSizes(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CCLSizes/AddOrUpdate",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * CCLSizes-批量启用
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function CCLSizesEnableByIds(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CCLSizes/EnableByIds",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * CCLSizes-批量禁用
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function CCLSizesDisableByIds(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CCLSizes/DisableByIds",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * CCLSizes-删除
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function CCLSizesDelete(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CCLSizes/Delete_IsDeleted",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}

/**
 * 定制计算器Grade维护页面-查询
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function loadGradeFerrouss(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/GradeFerrouss/Load?page=" + data.page + '&limit=' + data.limit + '&keyword=' + data.keyword,
        method: 'get',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "查询失败", successCallBack, failCallBack);
}
/**
 * 定制计算器Grade维护页面-新增
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function AddOrUpdateGradeFerrouss(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/GradeFerrouss/AddOrUpdate",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * 定制计算器Grade维护页面-批量启用
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function GradeFerroussEnableByIds(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/GradeFerrouss/EnableByIds",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * 定制计算器Grade维护页面-批量禁用
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function GradeFerroussDisableByIds(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/GradeFerrouss/DisableByIds",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * 定制计算器Grade维护页面-批量删除
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function GradeFerroussDelete(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/GradeFerrouss/Delete",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}

/**
 * 定制计算器Tonnage维护页面-查询
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function loadTonnages(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Tonnages/Load?page=" + data.page + '&limit=' + data.limit + '&keyword=' + data.keyword,
        method: 'get',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "查询失败", successCallBack, failCallBack);
}
/**
 * 定制计算器Tonnage维护页面-新增
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function AddOrUpdateTonnages(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Tonnages/AddOrUpdate",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * 定制计算器Tonnage维护页面-批量启用
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function TonnagesEnableByIds(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Tonnages/EnableByIds",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * 定制计算器Tonnage维护页面-批量禁用
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function TonnagesDisableByIds(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Tonnages/DisableByIds",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * 定制计算器Tonnage维护页面-批量删除
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function TonnagesDelete(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Tonnages/Delete",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}

/**
 * 定制计算器operation维护页面-查询
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function loadOperationDetailss(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Operation_Detailss/Load?page=" + data.page + '&limit=' + data.limit + '&keyword=' + data.keyword,
        method: 'get',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "查询失败", successCallBack, failCallBack);
}
/**
 * 定制计算器operation维护页面-新增
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function AddOrUpdateOperationDetailss(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Operation_Detailss/AddOrUpdate",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * 定制计算器operation维护页面-批量删除
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function DeleteOperationDetailss(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Operation_Detailss/Delete",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}

/**
 * 定制计算器operation维护页面-批量启用
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function OperationDetailssEnableByIds(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Operation_Detailss/EnableByIds",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * 定制计算器operation维护页面-批量禁用
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function OperationDetailssDisableByIds(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Operation_Detailss/DisableByIds",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}

/**
 * 定制计算器Calculated_Details维护页面-查询
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function loadCalculatedDetails(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Calculated_Details/Load?page=" + data.page + '&limit=' + data.limit + '&keyword=' + data.keyword,
        method: 'get',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "查询失败", successCallBack, failCallBack);
}
/**
 * 定制计算器Calculated_Details维护页面-新增
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function AddOrUpdateCalculatedDetails(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Calculated_Details/AddOrUpdate",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * 定制计算器Calculated_Details维护页面-批量启用
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function CalculatedDetailsEnableByIds(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Calculated_Details/EnableByIds",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * 定制计算器Calculated_Details维护页面-批量禁用
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function CalculatedDetailsDisableByIds(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Calculated_Details/DisableByIds",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}

/**
 * 操作-改价
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function UpdateForPrice(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CostApplys/UpdateForPrice",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * tcm-导入
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function ImportTCMDataBaseExcel(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/ExcelHelper/ImportTCMDataBaseExcel",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "导入失败", successCallBack, failCallBack);
}
/**
 * tcm-导出
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function ExportTCMDataBaseExcel(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/ExcelHelper/ExportTCMDataBaseExcel",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "导出失败", successCallBack, failCallBack);
}
/**
 * tcm-导出(改版)
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function ExportTCMDataBaseExcelNew(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/ExcelHelper/ExportTCMDataBaseExcelNew",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "导出失败", successCallBack, failCallBack);
}
/**
 * 获取登录用户资料
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function GetUserProfile(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Check/GetUserProfile",
        method: 'get',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "获取失败", successCallBack, failCallBack);
}
/**
 * 获取发起页面的菜单权限
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function LoadMenus(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Modules/LoadMenus",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "获取失败", successCallBack, failCallBack);
}
/**
 * 获取用户角色
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function GetUserRoles(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Check/GetRoles",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "获取失败", successCallBack, failCallBack);
}
/**
 * pcb定制计算器-新增计算
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function GetTonnageCost(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Process_Stampings/GetTonnageCost",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "计算失败", successCallBack, failCallBack);
}
/**
 * pcb定制计算器-获取总和最大值
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function GetRecommendedTonnageCost(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Process_Stampings/GetRecommendedTonnageCost",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "计算失败", successCallBack, failCallBack);
}
/**
 * 导出有问题的数据
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function ExportDataTableExcel(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/ExcelHelper/ExportDataTableExcel",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "导出失败", successCallBack, failCallBack);
}
/**
 * 查询选择发送邮件的用户
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function GetUserEmail(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CostApplys/GetUserEmail",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "查询失败", successCallBack, failCallBack);
}
/**
 * 查询选择发送过邮件的用户
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function LoadMailLine(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/MailHistory/Load_MailLine",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "查询失败", successCallBack, failCallBack);
}
/**
 * 判断导入数据是否有重复
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function GetDataIsDuplicate(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/ExcelHelper/GetDataIsDuplicate",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "导入失败", successCallBack, failCallBack);
}
/**
 * 获取多组税率
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function getMultiRate(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/ExchangeRatess/GetRate",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "获取失败", successCallBack, failCallBack);
}

/**
 * 选择已完成的CBD数据存放到临时表中
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function AddCompletedCBD(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CostApplys/AddCompletedCBD",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "新增失败", successCallBack, failCallBack);
}
/**
 * 改价列表查询
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function LoadForPrice(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CostApplys/LoadForPrice?Keyword=" + data.Keyword + '&isDraft=' + data.isDraft + '&CommodityId=' + data.CommodityId + '&currencyId=' + data.currencyId + '&Status=' + data.status + '&StartDate=' + data.StartDate + '&EndDate=' + data.EndDate + '&priceType=' + data.priceType + '&page=' + data.page + '&limit=' + data.limit + '&key=' + data.key + '&PageType=' + data.PageType,
        method: 'get',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "获取失败", successCallBack, failCallBack);
}

/**
 * 改价列表导入已完成的CBD
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function AddOrUpdatePriceRelation(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CostApplys/AddOrUpdatePriceRelation",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "新增失败", successCallBack, failCallBack);
}

/**
 * PCBA-yes price查询
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function EBom_YESPricingsLoad(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/EBom_YESPricings/Load",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "获取失败", successCallBack, failCallBack);
}
/**
 * PCBA-yes price获取详情
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function EBom_YESPricingsGetDetail(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/EBom_YESPricings/GetDetail",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "获取失败", successCallBack, failCallBack);
}
/**
 * PCBA-yes price新增父表信息
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function AddOrUpdateEBom_YESPricings(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/EBom_YESPricings/AddOrUpdate",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * PCBA-yes price新增子表信息
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function AddOrUpdateDetailEBom_YESPricings(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/EBom_YESPricings/AddOrUpdateDetail",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * PCBA-yes price删除子表信息
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function DetailDeleteEBom_YESPricings(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/EBom_YESPricings/DetailDelete",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * PCBA-yes price删除父表信息
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function DeleteEBom_YESPricings(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/EBom_YESPricings/Delete",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * PCBA-yes price导入
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function ImportYes(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/EBom_YESPricings/ImportYes",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * PCBA-eBom查询
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function EBomsLoad(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/EBoms/Load",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "获取失败", successCallBack, failCallBack);
}
/**
 * PCBA-eBom新增父表信息
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function AddOrUpdateEBoms(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/EBoms/AddOrUpdate",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * PCBA-eBom主表删除
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function EBomsDelete(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/EBoms/Delete",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * PCBA-eBom主表删除
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function ImportEbom(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/EBoms/ImportEbom",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * PCBA-eBom根据id获取计算结果
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function GetEbomComputeResult(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/EBoms/GetEbomComputeResult",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "获取失败", successCallBack, failCallBack);
}
/**
 * PCBA-eBom获取详情列表
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function EBomsLoadDetail(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/EBoms/LoadDetail",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "获取失败", successCallBack, failCallBack);
}
/**
 * PCBA-eBom获取directBuy
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function GetDirectBuyList(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/EBoms/GetDirectBuyList",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "获取失败", successCallBack, failCallBack);
}
/**
 * PCBA-eBom根据参数计算结果
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function GetEbomComputeResultByParameter(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/EBoms/GetEbomComputeResultByParameter",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * PCBA-eBom根据选择项带出unitprice结果
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function GetEbomUnitPrice(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/EBoms/GetEbomUnitPrice",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * PCBA-eBom新增详情列表
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function EBomsAddOrUpdateDetail(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/EBoms/AddOrUpdateDetail",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * PCBA-eBom详情列表删除
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function EBomsDeleteDetail(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/EBoms/DeleteDetail",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * PCBA-no price查询
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function EBom_NOPricingsLoad(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/EBom_NOPricings/Load",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "获取失败", successCallBack, failCallBack);
}
/**
 * PCBA-no price新增
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function EBom_NOPricingsAddOrUpdate(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/EBom_NOPricings/AddOrUpdate",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * PCBA-no price删除
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function EBom_NOPricingsDelete(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/EBom_NOPricings/Delete",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * PCBA-no price导入
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function ImportNo(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/EBom_NOPricings/ImportNo",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}

/**
 * PCBA-获取详情列表下所有的币种
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function GetEbomCurrencyList(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/EBoms/GetEbomCurrencyList",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * PCBA-修改exchangerate1和exchangerate2提交
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function BatchChangeRate(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/EBoms/BatchChangeRate",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * PCBA-获取对应税率
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function EBomsGetRate(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/EBoms/GetRate",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * PCBA-更新unitPrice
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function ChangeUnitPrice(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/EBoms/ChangeUnitPrice",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * 抄送-查询列表
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function LoadForCC(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CostApplys/LoadForCC",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * plastic-tonnage计算
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function GetTonnageComputeResultByParameter(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Plastic_CycleTimes/GetTonnageComputeResultByParameter",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * plastic-part weight计算
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function GetPartComputeResultByParameter(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Plastic_CycleTimes/GetPartComputeResultByParameter",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * plastic-cycleTime新增
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function AddCycleTime(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Plastic_CycleTimes/AddCycleTime",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * plastic-cycleTime查询
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function GetCycleTimeList(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Plastic_CycleTimes/GetCycleTimeList",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * plastic-cycleTime计算
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function GetCycleTimeResult(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Plastic_CycleTimes/GetCycleTimeResult",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * plastic-cycleTime历史数据新增
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function CycleTimeAddHistory(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Plastic_CycleTimes/AddHistory",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * plastic-cycleTime查询历史数据
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function GetCycleTimeHistoryList(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Plastic_CycleTimes/GetCycleTimeHistoryList",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * plastic-bracket weight计算
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function GetBracketResult(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Process_Stampings/GetBracketResult",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}

/**
 * stamping-加载主表
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function LoadMainStamping(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Process_Stampings/LoadMain",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * stamping-新增主表
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function AddOrUpdateMainStamping(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Process_Stampings/AddOrUpdateMain",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * stamping-新增主表
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function DeleteMainByIdsStamping(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Process_Stampings/DeleteMainByIds",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * stamping-加载子表数据
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function LoadStamping(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Process_Stampings/Load",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * stamping-计算calculated值
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function GetCalculatedResult(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Process_Stampings/GetCalculatedResult",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * stamping-获取中间量值 Total Length of Cut / bend / Form
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function GetTotalLengthResult(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Process_Stampings/GetTotalLengthResult",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * stamping-获取Calculated Tonnage值
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function GetCalculatedTonnageResult(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Process_Stampings/GetCalculatedTonnageResult",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * stamping-新增
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function AddOrUpdateStamping(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Process_Stampings/AddOrUpdate",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * stamping-新增(主表一起新增)
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function AddOrUpdateStampingMain(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Process_Stampings/AddByCostApplyId",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * stamping-新增历史数据
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function StampingAddHistory(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Process_Stampings/AddHistory",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * stamping-新增
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function DeleteStamping(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Process_Stampings/Delete",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * stamping-获取主表计算总值
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function GetComputeMainResultRes(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Process_Stampings/GetComputeMainResultRes",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * stamping-修改主表类型（max, min, avg）
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function UpdateTypeStamping(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Process_Stampings/UpdateType",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * stamping-获取主表详情
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function GetStampingDetail(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Process_Stampings/GetMain",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * pcba-yesPrice 导出
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function ExportYes(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/EBom_YESPricings/ExportYes",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "导出失败", successCallBack, failCallBack);
}
/**
 * pcba-noPrice 导出
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function ExportNo(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/EBom_NOPricings/ExportNo",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "导出失败", successCallBack, failCallBack);
}
/**
 * ct-加载tool列表
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function LoadTool(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CustomTools/LoadTool",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "查询失败", successCallBack, failCallBack);
}
/**
 * ct-tool新增
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function AddOrUpdateTool(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CustomTools/AddOrUpdateTool",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * ct-tool删除
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function DeleteTool(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CustomTools/DeleteTool",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * ct-加载equiment列表
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function LoadEquiment(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CustomTools/LoadEquiment",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "查询失败", successCallBack, failCallBack);
}


/**
 * ct-equiment新增
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function AddOrUpdateEquiment(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CustomTools/AddOrUpdateEquiment",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * ct-equiment删除
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function DeleteEquiment(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CustomTools/DeleteEquiment",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * ct-主表 列表查询
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function CTCalculator_MainsLoad(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CTCalculator_Mains/Load",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "查询失败", successCallBack, failCallBack);
}
/**
 * ct-主表 新增
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function CTCalculator_MainsAddOrUpdateBaseInfo(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CTCalculator_Mains/AddOrUpdateBaseInfo",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * ct-主表 新增
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function CTCalculator_MainsDelete(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CTCalculator_Mains/Delete",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * ct-主表 查询详情(列表)
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function CTCalculator_DetailsGet(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CTCalculator_Details/Load",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "查询失败", successCallBack, failCallBack);
}
/**
 * ct-主表 查询详情（带附件列表）
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function CTCalculator_MainsGetCTMainDetail(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CTCalculator_Mains/GetCTMainDetail",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "查询失败", successCallBack, failCallBack);
}
/**
 * ct-获取计算结果但不存存
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function ReCalculateResult(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CTCalculator_Details/GetReCalc",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * ct-获取计算结果
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function SaveCalculateResult(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CTCalculator_Mains/SaveCalculateResult",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * ct-新增明细
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function AddOrUpdateCTDetails(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CTCalculator_Details/AddOrUpdate",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * ct-删除明细
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function DeleteCTDetails(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CTCalculator_Details/Delete",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * ct-选择历史ct
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function AddBySelectMain(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CTCalculator_Details/AddBySelectMain",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}


/**
 * ct-加载equiment详情
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function GetCtEquiment(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CustomTools/GetEquiment",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "查询失败", successCallBack, failCallBack);
}
/**
 * ct-加载equiment删除详情
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function GDeleteByMainIdsCtEquiment(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CTCalculator_Details/DeleteByMainIds",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "查询失败", successCallBack, failCallBack);
}

/**
 * raw-导出
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function ExportTemplateMaterial(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/TemplateMaterials/ExportTemplateMaterial",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}

/**
 * pur-导出
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function ExportTemplatePurchase(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/TemplatePurchases/ExportTemplatePurchase",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * pro-导出
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function ExportTemplateProcess(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/TemplateProcesss/ExportTemplateProcess",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * 下载模板
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function DownloadTemplateExcel(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CostApplys/DownloadTemplateExcel",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
/**
 * 获取审批历史记录
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function getHistory(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CostApplys/LoadFlow?id=" + data.id,
        method: 'get',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}

/**
 * 更改审批状态
 * @param {*} data 接口请求对象
 * @param {*} successCallBack 请求成功回调
 * @param {*} failCallBack 请求失败回调
 * @param {*} timeout 接口请求超时时间
 * @param {*} timeStampFlag 请求接口是否添加时间戳
 */
export function UpdateApplyStatus(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    data.nodeId = data.nodeId || "";
    data.id = data.id || "";
    data.userId = data.userId || "";
    data.comment = data.comment || "";
    var requestData = {
        url: baseUrl + "api/CostApplys/UpdateFlowStatus?nodeId=" + data.nodeId + "&flowstatus=" + data.flowstatus + "&status=" + data.status + "&id=" + data.id + "&userId=" + data.userId + "&comment=" + data.comment,
        method: 'get',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);

}

export function UpdateApplyForStart(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CostApplys/AddOrUpdateForStart",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}

export function UpdateCostApplyForChangePriceReq(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CostApplys/UpdateCostApplyForChangePriceReq",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}


// 批量修改Commodity
export function ModifyMaterialsCommodityByIds(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/TemplateMaterials/CommodityByIds?commodityId=" + data.commodityId,
        method: 'post',
        data: data.ids
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
// 批量修改Commodity
export function ModifyProcesssCommodityByIds(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/TemplateProcesss/CommodityByIds?commodityId=" + data.commodityId,
        method: 'post',
        data: data.ids
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
// 批量修改Commodity
export function ModifyPurchasesCommodityByIds(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/TemplatePurchases/CommodityByIds?commodityId=" + data.commodityId,
        method: 'post',
        data: data.ids
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}

export function GetApplicationReqs(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CostApplys/GetApplicationReqs",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}


export function GetCostPartLoad(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CostPart/Load",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}


export function CheckOperateReqs(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CostApplys/CheckOperateReqs",
        method: 'post',
        data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
// 保存修改tcmdata
export function TCMDataEdit(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CostApplys/UpdateForTCMDataEdit",
        method: 'post',
        data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}

// 获取sum 表格数据
export function GetSummaryLine(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CostApplys/GetSummaryLine?id=" + data.id,
        method: 'post',
        data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}

// 忘记密码
export function ChangeLoginPassword(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Users/ChangeLoginPassword",
        method: 'post',
        data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}


// 获取ExportSetting 表格数据
export function GetExportSettingLoad(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/ExportSetting/Load",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}
// 首页联系人
// api/CostApplys/LoadResquest

export function GetLoadResquest(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CostApplys/LoadResquest",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}


// 设置导出tcmdata 清单

export function ExportSettingAddOrUpdate(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/ExportSetting/AddOrUpdate",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}



// 导出tcmdata 清单
export function DownloadTcmDataExcel(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CostApplys/DownloadTcmDataExcel",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}



// 撤回导入cbd更改状态
export function CallBackCostApply(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/CostApplys/CallBackCostApply",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}


// 查询用户的角色
export function SelUserRoles(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Users/SelUserRoles",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}


// USER Setting加载接口

export function GetUserSettingMenu(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Modules/GetUserSettingMenu",
        method: 'get',
        params: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}

// USER Setting保存接口

export function SaveUserSettingMenu(data, successCallBack, failCallBack, timeout, timeStampFlag) {
    var requestData = {
        url: baseUrl + "api/Modules/SaveUserSetting",
        method: 'post',
        data: data
    };
    sendRequest(requestData, timeout, timeStampFlag, "操作失败", successCallBack, failCallBack);
}