<template>
  <el-dialog v-model="visible" width="70%" :show-close="false" @close="close">
    <template #header="{ close, titleId, titleClass }">
      <div class="my-header">
        <div class="size-16">{{ $t("ccls.ccl.ExportForm") }}</div>
        <div style="cursor: pointer" @click="close">
          <el-icon :size="20"><Close /></el-icon>
        </div>
      </div>
    </template>
    <div class="handle-btn">
      <div class="handle-btn-item" @click="confirm">
        {{ $t("userlists.userlist.confirm") }}
      </div>
    </div>
    <div class="checkbox-item" v-for="itm in DataList">
      <el-checkbox
        v-model="itm.CheckAll"
        :indeterminate="itm.isIndeterminate"
        @change="handleCheckAllChange($event, itm)"
      >
        <span style="font-weight: 600; font-size: 16px">
          {{ powers == "en" ? itm.Ename : itm.name }}
        </span>
      </el-checkbox>
      <el-checkbox-group
        v-model="itm.CheckList"
        @change="handleCheckedCitiesChange($event, itm)"
      >
        <el-checkbox
          v-for="item in itm.Data"
          :key="item.id"
          :label="item.id"
          :value="item.isCheck"
        >
          {{ powers == "en" ? item.name : item.chineseName }}
        </el-checkbox>
      </el-checkbox-group>
    </div>
  </el-dialog>
</template>
  
  <script>
import {
  GetExportSettingLoad,
  ExportSettingAddOrUpdate,
} from "../../assets/js/requestApi.js";
import commonUtil from "../../assets/js/commonUtil.js";
export default {
  props: [],
  emits: [],
  data() {
    return {
      powers: null,
      SettingList: {},
      visible: false,
      DataList: {
        gen: {
          name: "基本信息",
          Ename: "General lnformation",
          CheckAll: false,
          isIndeterminate: false,
          CheckList: [],
        },
        raw: {
          name: "原材料",
          Ename: "Raw Material",
          CheckAll: false,
          isIndeterminate: false,
          CheckList: [],
        },
        pur: {
          name: "采购件",
          Ename: "Purchase Component",
          CheckAll: false,
          isIndeterminate: false,
          CheckList: [],
        },
        pro: {
          name: "工序",
          Ename: "Processing",
          CheckAll: false,
          isIndeterminate: false,
          CheckList: [],
        },
        pl: {
          name: "包装运输成本",
          Ename: "P8L",
          CheckAll: false,
          isIndeterminate: false,
          CheckList: [],
        },
        oh: {
          name: "管理费用与利润",
          Ename: "OH",
          CheckAll: false,
          isIndeterminate: false,
          CheckList: [],
        },
        sum: {
          name: "汇总",
          Ename: "Cost Summary",
          CheckAll: false,
          isIndeterminate: false,
          CheckList: [],
        },
      },
    };
  },
  mounted() {},
  methods: {
    GetSettingList() {
      GetExportSettingLoad("", (res) => {
        if (res && res.code == 200) {
          this.DataList.sum.Data = res.data.cost_Summarys;
          this.DataList.gen.Data = res.data.general_Informations;
          this.DataList.oh.Data = res.data.oHs;
          this.DataList.pl.Data = res.data.p_Ls;
          this.DataList.pro.Data = res.data.processings;
          this.DataList.pur.Data = res.data.purchase_Components;
          this.DataList.raw.Data = res.data.raw_Materials;
          for (let key in this.DataList) {
            let temp = this.DataList[key];
            temp.CheckList = [];
            temp.isIndeterminate = false;
            let checkedCount = 0;
            temp.Data.forEach((item, index) => {
              if (item.isCheck) {
                checkedCount = checkedCount + 1;
                temp.CheckList.push(item.id);
              }
            });
            temp.CheckAll = temp.Data.length === checkedCount ? true : false;
            temp.isIndeterminate =
              checkedCount > 0 && checkedCount < temp.Data.length;
          }
        }
      });
    },
    handleCheckAllChange(val, item) {
      if (val) {
        let arr = [];
        item.Data.forEach((element) => {
          arr.push(element.id);
        });
        item.CheckList = arr;
      } else {
        item.CheckList = [];
      }
      item.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value, item) {
      const checkedCount = value.length;
      item.CheckAll = checkedCount === item.Data.length;
      item.isIndeterminate =
        checkedCount > 0 && checkedCount < item.Data.length;
    },
    confirm() {
      let parasm = [];
      for (let key in this.DataList) {
        let temp = this.DataList[key];
        temp.Data.forEach((item, index) => {
          let parasmItem = {
            id: item.id,
            isCheck: 0,
          };
          temp.CheckList.forEach((itm) => {
            if (item.id == itm) parasmItem.isCheck = 1;
          });
          parasm.push(parasmItem);
        });
      }
      ExportSettingAddOrUpdate(parasm, (res) => {
        if (res && res.code == 200) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.operateSuccessfully"),
            "",
            "success"
          );
          this.visible = false;
        }
      });
    },

    close() {
      this.visible = false;
      this.$parent.GetSettingList();
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.size-16 {
  font-size: 16px;
}
.checkbox-item {
  margin-bottom: 20px;
}
.handle-btn {
  padding-right: 70px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 25px;
  .handle-btn-item {
    padding: 0 20px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    background: #b2bde7;
    color: #fff;
    border-radius: 25px;
    cursor: pointer;
    margin-left: 10px;
  }
}
</style>