<template>
  <div class="main">
    <div class="main-top_handle">
      <div class="title">
        {{ $t("homePage.home.ProcessCalculator") }} -
        {{ $t("homePage.home.ProcessStamping") }} -
        {{ $t("homePage.home.TonnageCalculator") }}
      </div>
      <div class="main-handle flex">
        <div class="main-handle-item">
          {{ $t("homePage.home.no") }}
          <input type="text" v-model="searchForm.number" />
        </div>
        <div class="main-handle-item">
          {{ $t("ccls.ccl.desc") }}
          <input type="text" v-model="searchForm.description" />
        </div>
        <div class="search-btn" style="margin-top: 15px" @click="search">
          {{ $t("tcmlists.tcmlist.search") }}
        </div>
      </div>
    </div>

    <div class="handle-btn flex">
      <div
        v-if="BtnLimits.Add"
        class="handle-btn-item"
        style="margin-right: 10px"
        @click="addData"
      >
        {{ $t("unifiedPrompts.unifiedPrompt.new") }}
      </div>
      <div
        v-if="BtnLimits.Edit"
        class="handle-btn-item"
        style="margin-right: 10px"
        @click="editData"
      >
        {{ $t("unifiedPrompts.unifiedPrompt.edit") }}
      </div>
      <div
        v-if="BtnLimits.Delete"
        class="handle-btn-item"
        style="margin-right: 10px"
        @click="deleteData"
      >
        {{ $t("unifiedPrompts.unifiedPrompt.delete") }}
      </div>
    </div>
    <div class="regional_table">
      <el-table
        border
        stripe
        :empty-text="$t('tcmlists.tcmlist.noData')"
        :data="tableData"
        :height="TableHeight"
        @row-click="RowClick"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="number"
          :label="$t('homePage.home.no')"
          align="center"
        />
        <el-table-column
          prop="description"
          :label="$t('ccls.ccl.desc')"
          align="center"
        />
        <el-table-column
          prop="regionName"
          :label="$t('tcmdatalists.tcmdatalist.regionName')"
          align="center"
        />
        <el-table-column
          prop="tonnage"
          :label="$t('homePage.home.TotalTonnage')"
          align="center"
        >
          <template #default="scope"> {{ scope.row.tonnage }} T </template>
        </el-table-column>
        <el-table-column
          prop="recommendedTonnage"
          :label="$t('homePage.home.RecommendedTonnage')"
          align="center"
        >
          <template #default="scope">
            {{ scope.row.recommendedTonnage }}
            {{
              scope.row.recommendedTonnage === "" ||
              scope.row.recommendedTonnage === null
                ? ""
                : "T"
            }}
          </template>
        </el-table-column>
        <el-table-column
          prop="date"
          :label="$t('changePriceLists.changePriceList.date')"
          align="center"
        />
        <el-table-column
          v-if="BtnLimits.ComputeDetail"
          width="80"
          fixed="right"
          class-name="lastTd"
          :label="$t('templateLists.templateList.operate')"
          align="center"
        >
          <template #default="scope">
            <el-popover placement="bottom" trigger="hover">
              <template #reference>
                <div style="text-align: center">
                  <el-icon><MoreFilled /></el-icon>
                </div>
              </template>
              <div class="action-box">
                <div
                  class="action-btn"
                  style="font-size: 12px"
                  @click="editDetail(scope.row)"
                >
                  <el-icon style="margin: 0 10px"><View /></el-icon>
                  <span>{{ $t("ccls.ccl.detail") }}</span>
                </div>
              </div>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <div>
        {{ $t("homePage.home.Showing") }} {{ pageSize }}
        {{ $t("homePage.home.From") }} {{ total }}
        {{ $t("homePage.home.Data") }}
        <el-popover placement="top" :width="70" trigger="click">
          <template #reference>
            <el-icon
              style="margin-left: 5px; font-size: 17px; vertical-align: middle"
              ><Operation
            /></el-icon>
          </template>
          <el-input
            v-model="searchForm.limit"
            @input="(val) => (searchForm.limit = val.replace(/[^\d]/g, ''))"
            :min="1"
            style="width: 130px"
            @change="handleSizeChange"
          >
            <template #suffix> {{ $t("ccls.ccl.barPage") }}</template>
          </el-input>
        </el-popover>
      </div>
      <el-pagination
        layout="prev, pager, next"
        background
        :total="total"
        :page-size="pageSize"
        :current-page="searchForm.page"
        @current-change="handleCurrentChange"
      />
    </div>
    <AddMain ref="addDia" @confirmInfo="confirmInfo"></AddMain>
  </div>
</template>

<script>
import {
  LoadMainStamping,
  AddOrUpdateMainStamping,
  DeleteMainByIdsStamping,
} from "../../assets/js/requestApi.js";
import commonUtil from "../../assets/js/commonUtil.js";
import AddMain from "./addMain.vue";
export default {
  components: {
    AddMain,
  },
  name: "allTonnageList",
  activated() {
    // 在首次挂载、
    // 以及每次从缓存中被重新插入的时候调用
    this.search();
    this.getUnits();
  },
  deactivated() {
    clearTimeout(this.timer);
    // 在从 DOM 上移除、进入缓存
    // 以及组件卸载时调用
  },
  data() {
    return {
      TableHeight: 0,
      searchForm: {
        number: "",
        description: "",
        page: 1,
        limit: 10,
        key: "",
      },
      tableData: [],
      // 已选择数据
      chooseData: [],
      // 已选择对比数据
      drawer: false,
      // 选择的对比数据列表
      compareList: [],
      // 总页数
      total: 0,
      pageSize: 10,
      timer: "",
      // 数据重复是否继续导入
      isContinue: false,
      BtnLimits: {},
    };
  },
  mounted() {
    this.autoHeight();
    this.getList();
  },
  methods: {
    RowClick(row) {
      if (this.BtnLimits.Edit) {
        this.editDetail(row);
      }
    },
    autoHeight() {
      // 初始化的时候，设置高度
      this.setHeight();
      // 改变浏览器窗口大小的时候，设置高度
      window.addEventListener("resize", () => {
        this.setHeight();
      });
    },
    setHeight() {
      this.$nextTick(() => {
        this.TableHeight = window.innerHeight - 235; // 这里的X就是顶部栏的高度
      });
    },
    getUnits() {
      this.timer = setInterval(this.gettime, 1000);
    },
    gettime() {
      this.BtnLimits = JSON.parse(sessionStorage.getItem("Btn"));
    },
    getList() {
      this.searchForm = {
        number: "",
        description: "",
        page: 1,
        limit: 10,
        key: "",
      };
      this.search();
    },
    search() {
      LoadMainStamping(this.searchForm, (response) => {
        if (response && response.code == 200) {
          this.tableData = response.data;
          this.total = response.count;
        }
      });
    },
    // 分页
    handleCurrentChange(val) {
      this.searchForm.page = val;
      this.search();
    },
    handleSizeChange() {
      this.pageSize = parseInt(this.searchForm.limit);
      if (this.searchForm.limit === "") {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.setPageNum"),
          "",
          "warning"
        );
        return;
      }
      if (this.searchForm.limit == 0) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.pageThanZero"),
          "",
          "warning"
        );
        return;
      }
      this.searchForm.page = 1;
      this.search();
    },
    // 新增
    addData() {
      this.$refs.addDia.visible = true;
    },
    confirmInfo(form) {
      AddOrUpdateMainStamping(form, (response) => {
        if (response && response.code == 200) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.operateSuccessfully"),
            "",
            "success"
          );
          this.$refs.addDia.close();
          this.search();
        }
      });
    },
    // 编辑数据
    editData() {
      if (this.chooseData.length == 0) {
        commonUtil.showTipInfo(
          this.$t("userlists.userlist.selecWant"),
          "",
          "warning"
        );
        return;
      }
      if (this.chooseData.length > 1) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.onlyOne"),
          "",
          "warning"
        );
        return;
      }
      this.$refs.addDia.form = JSON.parse(JSON.stringify(this.chooseData[0]));
      this.$refs.addDia.visible = true;
    },
    // 编辑详情数据
    editDetail(row) {
      this.$router.push({
        path: "/TonnageCalculatorlist",
        name: "TonnageCalculatorlist",
        query: {
          type: "edit",
          id: row.id,
          // calculatedType: row.type,
          // totalTonnage: row.tonnage,
          // recommendTonnage: row.recommendedTonnage
        },
      });
    },
    // 删除
    deleteData() {
      if (this.chooseData.length == 0) {
        commonUtil.showTipInfo(
          this.$t("userlists.userlist.selecWant"),
          "",
          "warning"
        );
        return;
      }
      let ids = [];
      this.chooseData.forEach(function (val, index) {
        ids.push(val.id);
      });
      DeleteMainByIdsStamping(ids, (response) => {
        if (response && response.code == 200) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.deleteSuccessfully"),
            "",
            "success"
          );
          this.search();
        }
      });
    },
    // 表格多选
    handleSelectionChange(val) {
      this.chooseData = val;
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  width: 100%;
  height: 100%;
  background: url("../../assets/img/car-table-bg.png") no-repeat center;
  background-size: contain;
}

.main-top_handle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
}
.tree-cla {
  background: #fff;
  .tree-cla-search {
    padding: 10px;
  }
  input {
    display: block;
    width: calc(100% - 10px);
    height: 40px;
    border-radius: 10px;
    background: #f5f7ff;
  }
}
.main-handle {
  align-items: center;
}
.main-handle-item {
  margin-right: 10px;
}
.main-handle input,
.main-handle .el-select .el-input {
  width: 100%;
  border-radius: 10px;
  font-size: 14px;
}
.main-handle input::placeholder {
  color: #3e4954;
  text-align: center;
  font-size: 14px;
}

.handle-btn {
  align-items: center;
  height: 40px;
  .handle-btn-item {
    padding: 0 20px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    background: #b2bde7;
    color: #fff;
    border-radius: 25px;
    cursor: pointer;
    margin-left: 10px;
  }
}
// 日期选择器
.select-box {
  position: relative;
  .select-btn {
    width: 200px;
    height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 10px;
    background: #fff;
    cursor: pointer;
    .Calendar,
    .Switch {
      font-size: 18px;
      color: #2f4cdd;
    }
  }
}
.search-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 30px;
  background: #445581;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
</style>