<template>
  <div>
    <div class="add-top">
      <el-row>
        <el-col :span="12">
          <el-row class="mgt-15">
            <el-col :span="24">
              <div class="dis-flex">
                <div class="line-32 add-top-text"></div>
                <div style="width: 100px" class="line-32">
                  {{ $t("other.other.Range") }}
                </div>
                <div style="width: 100px" class="line-32">
                  {{ $t("other.other.Typical") }}
                </div>
                <div style="width: 214px" class="line-32"></div>
              </div>
            </el-col>
          </el-row>
          <el-row class="mgt-15">
            <el-col :span="24">
              <div class="dis-flex">
                <div class="line-32 add-top-text">
                  {{ $t("other.other.MoldClose") }}
                </div>
                <div style="width: 100px" class="line-32">1-5%</div>
                <div style="width: 100px" class="line-32">8%</div>
                <el-input
                  v-model="form.moldClose"
                  disabled
                  placeholder=""
                  style="width: 214px"
                />
              </div>
            </el-col>
          </el-row>
          <el-row class="mgt-15">
            <el-col :span="24">
              <div class="dis-flex">
                <div class="line-32 add-top-text">
                  {{ $t("other.other.MoldOpen") }}
                </div>
                <div style="width: 100px" class="line-32">1-5%</div>
                <div style="width: 100px" class="line-32">8%</div>
                <el-input
                  v-model="form.moldOpen"
                  disabled
                  placeholder=""
                  style="width: 214px"
                />
              </div>
            </el-col>
          </el-row>
          <el-row class="mgt-15">
            <el-col :span="24">
              <div class="dis-flex">
                <div class="line-32 add-top-text">
                  {{ $t("other.other.Ejection") }}
                </div>
                <div style="width: 100px" class="line-32"></div>
                <div style="width: 100px" class="line-32">15%</div>
                <el-input
                  v-model="form.ejection"
                  disabled
                  placeholder=""
                  style="width: 214px"
                />
              </div>
            </el-col>
          </el-row>
          <el-row class="mgt-15">
            <el-col :span="24">
              <div class="dis-flex">
                <div class="line-32 add-top-text">
                  {{ $t("other.other.Injection") }}
                </div>
                <div style="width: 100px" class="line-32"></div>
                <div style="width: 100px" class="line-32">5%</div>
                <el-input
                  v-model="form.injection"
                  disabled
                  placeholder=""
                  style="width: 214px"
                />
              </div>
            </el-col>
          </el-row>
          <el-row class="mgt-15">
            <el-col :span="24">
              <div class="dis-flex">
                <div class="line-32 add-top-text">
                  {{ $t("other.other.HoldingTime") }}
                </div>
                <div style="width: 100px" class="line-32">5-25%</div>
                <div style="width: 100px" class="line-32">4%</div>
                <el-input
                  v-model="form.holdingTime"
                  disabled
                  placeholder=""
                  style="width: 214px"
                />
              </div>
            </el-col>
          </el-row>
          <el-row class="mgt-15">
            <el-col :span="24">
              <div class="dis-flex">
                <div class="line-32 add-top-text">
                  {{ $t("other.other.CoolingTime") }}
                </div>
                <div style="width: 100px" class="line-32">50-85%</div>
                <div style="width: 100px" class="line-32">60%</div>
                <el-input
                  v-model="form.coolingTime"
                  disabled
                  placeholder=""
                  style="width: 214px"
                />
              </div>
            </el-col>
          </el-row>
          <el-row class="mgt-15">
            <el-col :span="24">
              <div class="dis-flex">
                <div class="line-32 add-top-text">
                  {{ $t("other.other.TotalCT") }}
                </div>
                <el-input
                  v-model="form.total"
                  disabled
                  placeholder=""
                  style="width: 214px"
                />
                <div style="width: 100px" class="line-32">S</div>
                <div style="width: 100px" class="line-32"></div>
              </div>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="1"></el-col>
        <el-col :span="8" style="background-color: #f5f5f5">
          <el-row style="padding-top: 15px">
            <el-col :span="24">
              <div class="dis-flex">
                <div class="line-32 add-top-text">
                  {{ $t("other.other.WallThickness") }}
                </div>
                <el-select
                  clearable
                  :disabled="type == 'detail'"
                  :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                  filterable
                  v-model="form.wall"
                  style="width: 214px"
                >
                  <el-option
                    v-for="item in wallOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  />
                </el-select>
              </div>
            </el-col>
          </el-row>
          <el-row class="mgt-15">
            <el-col :span="24">
              <div class="dis-flex">
                <div class="line-32 add-top-text">
                  {{ $t("other.other.Material") }}
                </div>
                <el-select
                  clearable
                  :disabled="type == 'detail'"
                  :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                  filterable
                  v-model="form.material"
                  style="width: 214px"
                >
                  <el-option
                    v-for="item in materialOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  />
                </el-select>
              </div>
            </el-col>
          </el-row>
          <el-row class="mgt-15">
            <el-col :span="24">
              <div class="height-32"></div>
            </el-col>
          </el-row>
          <el-row class="mgt-15">
            <el-col :span="24">
              <div class="height-32"></div>
            </el-col>
          </el-row>
          <el-row class="mgt-15">
            <el-col :span="24" class="align-right"></el-col>
          </el-row>
          <el-row class="mgt-15">
            <el-col :span="24" class="align-right" v-show="type != 'detail'">
              <div
                class="operate-btn"
                style="margin-right: 50px"
                @click="compute"
              >
                {{ $t("other.other.Submit") }}
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <div class="padding-20">
      <!-- <div>
				<div class="operate-btn mgr-10" @click="addRow">Add Row</div>
				<div class="operate-btn mgr-10" @click="addColum">Add Column</div>
				<div class="operate-btn mgr-10" @click="submitTable">Submit</div>
			</div> -->
      <div class="table-cla">
        <div
          style="
            max-width: calc(100% - 30px);
            margin-right: 10px;
            overflow-x: auto;
          "
        >
          <table class="add-table">
            <tr>
              <!-- <td>
								<div style="width: 60px;">Operate</div>
							</td> -->
              <td>
                <div style="width: 100px">{{ $t("other.other.Material") }}</div>
              </td>
              <td v-for="(it, ind) in cycleLst">
                <div style="width: 100px">{{ it.colName }}</div>

                <!-- <el-input v-model="it.colName"  placeholder="" style="width: 100px;"/> -->
              </td>
            </tr>
            <tr v-for="(item, index) in tableData">
              <!-- <td>
								<div class="btn-text" @click="deleteRow(index)">Delete</div>
							</td> -->
              <td>
                <div style="width: 100px">{{ item.materialName }}</div>
                <!-- <el-input v-model="item.materialName" placeholder="" style="width: 100px;" /> -->
              </td>
              <td v-for="(it, ind) in item.cycleLst">
                <div
                  style="width: 100px"
                  :class="
                    form.wall == cycleLst[ind].colName &&
                    form.material == item.materialName
                      ? 'color-red'
                      : ''
                  "
                >
                  {{ it.value }}
                </div>
                <!-- <el-input v-model="it.value" :class="form.wall == cycleLst[ind].colName && form.material == item.materialName ? 'custom-el-input-red' : ''"  @input="(v) => (it.value = v.replace(/[^\d.]/g, ''))" placeholder="" style="width: 100px;"/> -->
              </td>
            </tr>
            <!-- <tr v-show="cycleLst.length > 0">
							<td>
								<div style="width: 60px;"></div>
							</td>
							<td>
								<div style="width: 100px;"></div>
							</td>
							<td v-for="(it, ind) in cycleLst">
								<div class="btn-text" @click="deleteColum(ind)">Delete</div>
							</td>
						</tr> -->
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  AddCycleTime,
  GetCycleTimeList,
  GetCycleTimeResult,
  GetCycleTimeHistoryList,
} from "../../assets/js/requestApi.js";
import commonUtil from "../../assets/js/commonUtil.js";
import "../../assets/css/custom-el-input-red.css";
export default {
  props: ["type"],
  data() {
    return {
      form: {
        moldClose: "",
        moldOpen: "",
        ejection: "",
        injection: "",
        holdingTime: "",
        coolingTime: "",
        total: "",
        wall: "",
        material: "",
      },
      wallOptions: [],
      materialOptions: [],
      tableData: [],
      cycleLst: [],
    };
  },
  mounted() {
    // this.getInit()
  },
  methods: {
    getInit() {
      let _this = this;
      GetCycleTimeList("", (response) => {
        if (response && response.code == 200) {
          this.tableData = response.result;
          if (this.tableData.length > 0) {
            this.cycleLst = JSON.parse(
              JSON.stringify(this.tableData[0].cycleLst)
            );
          }
          this.materialOptions = [];
          this.wallOptions = [];
          this.tableData.forEach(function (val, index) {
            _this.materialOptions.push(val.materialName);
          });
          this.cycleLst.forEach(function (val, index) {
            _this.wallOptions.push(val.colName);
          });
          this.form.wall = "";
          this.form.material = "";
        }
      });
    },
    getHistoryList() {
      let _this = this;
      GetCycleTimeHistoryList({ mainId: this.form.id }, (response) => {
        if (response && response.code == 200) {
          this.tableData = response.result;
          if (this.tableData.length > 0) {
            this.cycleLst = JSON.parse(
              JSON.stringify(this.tableData[0].cycleLst)
            );
          }
          this.materialOptions = [];
          this.wallOptions = [];
          this.tableData.forEach(function (val, index) {
            _this.materialOptions.push(val.materialName);
          });
          this.cycleLst.forEach(function (val, index) {
            _this.wallOptions.push(val.colName);
          });
        }
      });
    },
    // 计算
    compute() {
      let _this = this;
      let value = "";
      if (_this.form.wall === "") {
        commonUtil.showTipInfo("Please choose Wall Thickness! ", "", "warning");
        return;
      }
      if (_this.form.material === "") {
        commonUtil.showTipInfo("Please choose Material! ", "", "warning");
        return;
      }

      this.tableData.forEach(function (val, index) {
        if (val.materialName == _this.form.material) {
          val.cycleLst.forEach(function (cycleVal, cycleIndex) {
            if (_this.form.wall == _this.cycleLst[cycleIndex].colName) {
              value = cycleVal.value;
            }
          });
        }
      });
      GetCycleTimeResult(
        {
          value: value,
        },
        (response) => {
          if (response && response.code == 200) {
            this.form.moldClose = response.result.moldClose.toFixed(2);
            this.form.moldOpen = response.result.moldOpen.toFixed(2);
            this.form.ejection = response.result.ejection.toFixed(2);
            this.form.injection = response.result.injection.toFixed(2);
            this.form.holdingTime = response.result.holdingTime.toFixed(2);
            this.form.coolingTime = response.result.coolingTime.toFixed(2);
            this.form.total = response.result.total.toFixed(2);
          }
        }
      );
    },
    // 提交维护数据表格
    submitTable() {
      let data = [];
      let _this = this;
      let rowList = [];
      let colmunList = [];
      this.tableData.forEach(function (val, index) {
        val.cycleLst.forEach(function (cycleVal, cycleIndex) {
          data.push({
            rowName: val.materialName,
            rowIndex: index,
            colName: _this.cycleLst[cycleIndex].colName,
            value: cycleVal.value,
          });
        });
        rowList.push(val.materialName);
      });
      rowList.push("Material");
      let rowNameIsNull = false;
      let colmunNameIsNull = false;
      let valueNameIsNull = false;
      data.forEach(function (val, index) {
        if (val.rowName === "") {
          rowNameIsNull = true;
        }
        if (val.colName === "") {
          colmunNameIsNull = true;
        }
        if (val.value === "") {
          valueNameIsNull = true;
        }
      });
      this.cycleLst.forEach(function (val, index) {
        colmunList.push(val.colName);
      });
      if (rowNameIsNull) {
        commonUtil.showTipInfo(
          "Please complete the information! ",
          "",
          "warning"
        );
        return;
      }
      if (colmunNameIsNull) {
        commonUtil.showTipInfo(
          "Please complete the information! ",
          "",
          "warning"
        );
        return;
      }
      if (valueNameIsNull) {
        commonUtil.showTipInfo(
          "Please complete the information! ",
          "",
          "warning"
        );
        return;
      }
      if (Array.from(new Set(rowList)).length != rowList.length) {
        commonUtil.showTipInfo(
          "Material name cannot be repeated! ",
          "",
          "warning"
        );
        return;
      }
      if (Array.from(new Set(colmunList)).length != colmunList.length) {
        commonUtil.showTipInfo(
          "The list name cannot be repeated! ",
          "",
          "warning"
        );
        return;
      }
      AddCycleTime(data, (response) => {
        if (response && response.code == 200) {
          this.getInit();
        }
      });
    },
    // 添加列
    addColum() {
      this.cycleLst.push({
        colName: "",
      });
      this.tableData.forEach(function (val, index) {
        val.cycleLst.push({
          colName: "",
          value: "",
        });
      });
    },
    // 添加行
    addRow() {
      let rowData = {
        materialName: "",
        cycleLst: [],
      };
      rowData.materialName = "";
      this.cycleLst.forEach(function (val, index) {
        rowData.cycleLst.push({
          value: "",
          colName: "",
        });
      });
      this.tableData.push(rowData);
    },
    // 删除行
    deleteRow(index) {
      this.tableData.splice(index, 1);
    },
    // 删除列
    deleteColum(index) {
      this.tableData.forEach(function (val, ind) {
        val.cycleLst.splice(index, 1);
      });
      this.cycleLst.splice(index, 1);
    },
  },
};
</script>

<style scoped>
.add-top {
  padding: 20px;
  box-sizing: border-box;
  border-bottom: 1px solid #8c8c8c;
}
.dis-flex {
  display: flex;
  justify-content: space-around;
}
.line-32 {
  line-height: 32px;
}
.height-32 {
  height: 32px;
}
.padding-20 {
  padding: 20px;
  box-sizing: border-box;
}
.mgr-20 {
  margin-right: 20px;
}
.mgr-10 {
  margin-right: 10px;
}
.mgt-15 {
  margin-top: 12px;
}
.align-right {
  text-align: right;
}
.add-top-text {
  font-size: 14px;
  width: 150px;
}
.operate-btn {
  border-radius: 5px;
  background-color: var(--themeColor);
  color: #fff;
  text-align: center;
  padding: 7px 10px;
  display: inline-block;
  min-width: 80px;
  cursor: pointer;
}
.size-16 {
  font-size: 16px;
}
.text-sub {
  font-size: 14px;
  margin-left: 20px;
  margin-top: 10px;
}
.table-cla {
  position: relative;
  display: flex;
  margin-top: 20px;
}
.add-table {
  border-collapse: collapse;
}
.add-table td {
  border: 1px solid #dcdfe6;
  padding: 5px 10px;
  text-align: center;
}
.btn-text {
  color: var(--themeColor);
  cursor: pointer;
  font-size: 12px;
}
.color-red {
  color: red;
}
</style>