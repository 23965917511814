<template>
  <div>
    <div class="add-top">
      <table>
        <tr>
          <td></td>
          <td class="add-top-text">{{ $t("other.other.Length") }}</td>
          <td class="add-top-text">{{ $t("other.other.Width") }}</td>
          <td class="add-top-text">{{ $t("homePage.home.Thickness") }}</td>
        </tr>
        <tr>
          <td class="add-top-text">{{ $t("other.other.PartActualSize") }}</td>
          <td>
            <el-input
              v-model="form.partActualLength"
              :disabled="type == 'detail'"
              @input="(v) => (form.partActualLength = v.replace(/[^\d.]/g, ''))"
              placeholder=""
              class="mgr-20"
              style="width: 214px"
            />
          </td>
          <td>
            <el-input
              v-model="form.partActualWidth"
              :disabled="type == 'detail'"
              @input="(v) => (form.partActualWidth = v.replace(/[^\d.]/g, ''))"
              placeholder=""
              class="mgr-20"
              style="width: 214px"
            />
          </td>
          <td>
            <el-input
              v-model="form.partActualThick"
              :disabled="type == 'detail'"
              @input="(v) => (form.partActualThick = v.replace(/[^\d.]/g, ''))"
              placeholder=""
              class="mgr-20"
              style="width: 214px"
            />
          </td>
        </tr>
        <tr>
          <td class="add-top-text">{{ $t("other.other.PartBlankSize") }}</td>
          <td>
            <el-input
              v-model="form.partBlankLength"
              disabled
              placeholder=""
              class="mgr-20"
              style="width: 214px"
            />
          </td>
          <td>
            <el-input
              v-model="form.partBlankWidth"
              disabled
              placeholder=""
              class="mgr-20"
              style="width: 214px"
            />
          </td>
          <td>
            <el-input
              v-model="form.partBlankThick"
              disabled
              placeholder=""
              class="mgr-20"
              style="width: 214px"
            />
          </td>
        </tr>
        <tr>
          <td class="add-top-text">
            {{ $t("other.other.RoundoffBlankSize") }}
          </td>
          <td>
            <el-input
              v-model="form.roundBlankLength"
              disabled
              placeholder=""
              class="mgr-20"
              style="width: 214px"
            />
          </td>
          <td>
            <el-input
              v-model="form.roundBlankWidth"
              disabled
              placeholder=""
              class="mgr-20"
              style="width: 214px"
            />
          </td>
          <td>
            <el-input
              v-model="form.roundBlankThick"
              disabled
              placeholder=""
              class="mgr-20"
              style="width: 214px"
            />
          </td>
        </tr>
        <tr>
          <td class="add-top-text">{{ $t("other.other.Density") }}</td>
          <td>
            <el-input
              v-model="form.density"
              :disabled="type == 'detail'"
              @input="(v) => (form.density = v.replace(/[^\d.]/g, ''))"
              placeholder=""
              class="mgr-20"
              style="width: 214px"
            />
          </td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td class="add-top-text">{{ $t("other.other.PartNetWeight") }}</td>
          <td>
            <el-input
              v-model="form.netWeight"
              :disabled="type == 'detail'"
              @input="(v) => (form.netWeight = v.replace(/[^\d.]/g, ''))"
              placeholder=""
              class="mgr-20"
              style="width: 214px"
            />
          </td>
          <td>kg</td>
          <td></td>
        </tr>
        <tr>
          <td class="add-top-text">{{ $t("other.other.PartGrossWeight") }}</td>
          <td>
            <el-input
              v-model="form.grossWeight"
              disabled
              placeholder=""
              class="mgr-20"
              style="width: 214px"
            />
          </td>
          <td>kg</td>
          <td></td>
        </tr>
        <tr>
          <td class="add-top-text">{{ $t("other.other.Yeild") }}%</td>
          <td>
            <el-input
              v-model="form.yeild"
              disabled
              placeholder=""
              class="mgr-20"
              style="width: 214px"
            />
          </td>
          <td>%</td>
          <td></td>
        </tr>
        <tr>
          <td colspan="4" class="align-center" style="padding-top: 20px">
            <div class="operate-btn" v-show="type != 'detail'" @click="compute">
              {{ $t("other.other.Compute") }}
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { GetBracketResult } from "../../assets/js/requestApi.js";
import commonUtil from "../../assets/js/commonUtil.js";
export default {
  props: ["type"],
  data() {
    return {
      form: {
        partActualLength: "",
        partActualWidth: "",
        partActualThick: "",
        netWeight: "",
        density: "",
        grossWeight: "",
        partBlankLength: "",
        partBlankThick: "",
        partBlankWidth: "",
        roundBlankLength: "",
        roundBlankThick: "",
        roundBlankWidth: "",
        yeild: "",
      },
    };
  },
  methods: {
    // 计算
    compute() {
      if (this.form.partActualLength === "") {
        commonUtil.showTipInfo(
          "Part Actual Size Length can not be empty! ",
          "",
          "warning"
        );
        return;
      }
      if (this.form.partActualWidth === "") {
        commonUtil.showTipInfo(
          "Part Actual Size Width can not be empty! ",
          "",
          "warning"
        );
        return;
      }
      if (this.form.partActualThick === "") {
        commonUtil.showTipInfo(
          "Part Actual Size Thickness can not be empty! ",
          "",
          "warning"
        );
        return;
      }
      if (this.form.density === "") {
        commonUtil.showTipInfo("Density can not be empty! ", "", "warning");
        return;
      }
      if (this.form.partActualThick === "0") {
        commonUtil.showTipInfo(
          "Part Actual Size Thickness can not be zero! ",
          "",
          "warning"
        );
        return;
      }
      if (this.form.density === "0") {
        commonUtil.showTipInfo("Density can not be zero! ", "", "warning");
        return;
      }
      let form = {
        partActualLength:
          this.form.partActualLength === ""
            ? 0
            : parseFloat(this.form.partActualLength),
        partActualWidth:
          this.form.partActualWidth === ""
            ? 0
            : parseFloat(this.form.partActualWidth),
        partActualThick:
          this.form.partActualThick === ""
            ? 0
            : parseFloat(this.form.partActualThick),
        netWeight:
          this.form.netWeight === "" ? 0 : parseFloat(this.form.netWeight),
        density: this.form.density === "" ? 0 : parseFloat(this.form.density),
      };
      GetBracketResult(form, (response) => {
        if (response && response.code == 200) {
          this.form.grossWeight = response.result.grossWeight.toFixed(2);
          this.form.partBlankLength = response.result.partBlankLength;
          this.form.partBlankThick = response.result.partBlankThick;
          this.form.partBlankWidth = response.result.partBlankWidth;
          this.form.roundBlankLength = response.result.roundBlankLength;
          this.form.roundBlankThick = response.result.roundBlankThick;
          this.form.roundBlankWidth = response.result.roundBlankWidth;
          this.form.yeild = response.result.yeild.toFixed(2);
          // this.form.clampingForce = response.result.clampingForce
          // this.form.clampingForce2 = response.result.clampingForce2
          // this.form.machine = response.result.machine
        }
      });
    },
  },
};
</script>

<style scoped>
.add-top {
  padding: 20px;
  box-sizing: border-box;
}
.add-top table td {
  padding: 5px 10px;
  box-sizing: border-box;
}
.dis-flex {
  display: flex;
  justify-content: space-around;
}
.line-32 {
  line-height: 32px;
}
.height-32 {
  height: 32px;
}
.mgr-20 {
  margin-right: 20px;
}
.mgr-10 {
  margin-right: 10px;
}
.mgt-15 {
  margin-top: 12px;
}
.align-right {
  text-align: right;
}
.add-top-text {
  font-size: 14px;
}
.operate-btn {
  border-radius: 5px;
  background-color: var(--themeColor);
  color: #fff;
  text-align: center;
  padding: 7px 10px;
  display: inline-block;
  min-width: 80px;
  cursor: pointer;
}
.size-16 {
  font-size: 16px;
}
.text-sub {
  font-size: 14px;
  margin-left: 20px;
  margin-top: 10px;
}
.align-center {
  text-align: center;
}
</style>