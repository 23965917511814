<template>
  <div class="main">
    <div class="main-top_handle">
      <div class="title">{{ $t("homePage.home.Operation") }}</div>
      <div class="main-handle flex">
        <!-- 状态 -->
        <div class="main-handle-item">
          {{ $t("tcmlists.tcmlist.workStatus") }}
          <el-select
            size="small"
            clearable
            v-model="searchForm.status"
            :placeholder="$t('tcmlists.tcmlist.workStatus')"
          >
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="$t(item.label)"
              :value="item.value"
            />
          </el-select>
        </div>
        <div class="main-handle-item">
          {{ $t("tcmdatalists.tcmdatalist.commodity") }}
          <!-- CommodityId -->
          <el-tree-select
            size="small"
            clearable
            :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
            v-model="searchForm.CommodityId"
            :data="CommodityOptions"
            :props="defaultProps"
            check-strictly
            :render-after-expand="false"
            :default-expand-all="true"
          />
        </div>
        <div class="main-handle-item">
          {{ $t("changePriceLists.changePriceList.programName") }}
          <input
            type="text"
            v-model="searchForm.programName"
            :placeholder="$t('changePriceLists.changePriceList.programName')"
          />
        </div>
        <div class="main-handle-item">
          {{ $t("changePriceLists.changePriceList.partNumber") }}
          <input
            type="text"
            v-model="searchForm.partNumber"
            :placeholder="$t('changePriceLists.changePriceList.partNumber')"
          />
        </div>
        <div class="main-handle-item">
          {{ $t("unifiedPrompts.unifiedPrompt.keyword") }}
          <input
            type="text"
            v-model="searchForm.Keyword"
            :placeholder="$t('unifiedPrompts.unifiedPrompt.keyword')"
          />
        </div>
        <!-- 日期选择 -->
        <div class="main-handle-item" style="margin-top: 13px">
          <div class="select-box">
            <div class="select-btn">
              <el-icon class="Calendar"><Calendar /></el-icon>
              <span :style="{ color: searchForm.date ? '#606266' : '' }">{{
                searchForm.date
                  ? searchForm.date[0] + " - " + searchForm.date[1]
                  : $t("tcmdatalists.tcmdatalist.pleaseSlect")
              }}</span>
              <el-icon v-show="!searchForm.date">
                <ArrowDownBold />
              </el-icon>
              <el-icon :size="18" v-show="searchForm.date">
                <Close />
              </el-icon>
            </div>
            <el-config-provider
              :locale="$t('homePage.home.locale') == 'locale' ? locale : ''"
            >
              <el-date-picker
                class="date-picker"
                style="
                  width: 200px;
                  height: 30px;
                  position: absolute;
                  top: 0;
                  left: 0;
                  opacity: 0;
                "
                v-model="searchForm.date"
                type="daterange"
                range-separator="To"
                :start-placeholder="
                  $t('unifiedPrompts.unifiedPrompt.startDate')
                "
                :end-placeholder="$t('unifiedPrompts.unifiedPrompt.endDate')"
                size="mini"
                value-format="YYYY-MM-DD"
                @change="changeDate"
              />
            </el-config-provider>
          </div>
        </div>
        <div class="search-btn" style="margin-top: 13px" @click="search">
          {{ $t("tcmlists.tcmlist.search") }}
        </div>
      </div>
    </div>
    <div class="handle-btn flex">
      <div class="handle-btn-item" @click="toChange">
        {{ $t("other.other.VemChange") }}
      </div>
    </div>
    <div class="regional_table">
      <el-table
        border
        stripe
        :data="tableData"
        :height="TableHeight"
        @row-click="RowClick"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column
          prop="partNumber"
          :label="$t('tcmdatalists.tcmdatalist.partNumber')"
          width="120"
          align="center"
        />
        <el-table-column
          prop="programName"
          :label="$t('changePriceLists.changePriceList.programName')"
          align="center"
        />

        <el-table-column
          prop="commodityName"
          :label="$t('tcmdatalists.tcmdatalist.commodity')"
          width="110"
          align="center"
        />

        <el-table-column
          prop="regionName"
          :label="$t('tcmdatalists.tcmdatalist.regionName')"
          align="center"
        />
        <!-- <el-table-column
          prop="documentNo"
          :label="$t('tcmdatalists.tcmdatalist.documentNO')"
          align="center"
        >
          <template #default="scope">
            <el-tooltip content="View" placement="top" effect="light">
              <div class="divLink">
                {{ scope.row.documentNo }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column> -->
        <el-table-column
          prop="tcmOwner"
          :label="$t('tcmdatalists.tcmdatalist.tcmRequester')"
          width="120"
          align="center"
        />
        <el-table-column
          prop="partDescription"
          :label="$t('tcmdatalists.tcmdatalist.partDescription')"
          align="center"
        />
        <el-table-column
          prop="quoteType"
          :label="$t('tcmdatalists.tcmdatalist.quotationType')"
          width="120"
          align="center"
        />

        <el-table-column
          prop="tcmRequest"
          :label="$t('tcmdatalists.tcmdatalist.tcmOwner')"
          width="120"
          align="center"
        />

        <el-table-column
          prop="status"
          :label="$t('tcmlists.tcmlist.workStatus')"
          align="center"
        >
          <template #default="scope">
            <div v-show="scope.row.status == '0'" class="color-F59A23">
              {{ $t("tcmdatalists.tcmdatalist.notStart") }}
            </div>
            <div v-show="scope.row.status == '1'" class="color-D9001B">
              {{ $t("tcmdatalists.tcmdatalist.inProcess") }}
            </div>
            <div v-show="scope.row.status == '2'" class="color-0000FF">
              {{ $t("tcmdatalists.tcmdatalist.toApproved") }}
            </div>
            <div v-show="scope.row.status == '4'">
              {{ $t("tcmdatalists.tcmdatalist.underApproved") }}
            </div>
            <div v-show="scope.row.status == '5'" class="color-D9001B">
              {{ $t("tcmdatalists.tcmdatalist.rejected") }}
            </div>
            <div v-show="scope.row.status == '6'" class="color-67C23A">
              {{ $t("tcmdatalists.tcmdatalist.approved") }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="urgency"
          :label="$t('tcmdatalists.tcmdatalist.priority')"
          width="80"
          align="center"
        >
          <template #default="scope">
            <div v-show="scope.row.urgency == 'High'" class="color-ff0000">
              {{ $t("tcmdatalists.tcmdatalist.high") }}
            </div>
            <div v-show="scope.row.urgency == 'Middle'" class="color-F59A23">
              {{ $t("tcmdatalists.tcmdatalist.middle") }}
            </div>
            <div v-show="scope.row.urgency == 'Low'">
              {{ $t("tcmdatalists.tcmdatalist.low") }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="date"
          :label="$t('tcmdatalists.tcmdatalist.startDate')"
          sortable
          width="110"
          align="center"
        />
        <el-table-column
          prop="doDate"
          :label="$t('changePriceLists.changePriceList.dueDate')"
          sortable
          width="110"
          align="center"
        />
        <el-table-column
          width="80"
          align="center"
          fixed="right"
          class-name="lastTd"
          prop="action"
          :label="$t('homePage.home.Action')"
        >
          <template #default="scope">
            <el-popover placement="bottom" trigger="hover">
              <template #reference>
                <div style="text-align: center">
                  <el-icon><MoreFilled /></el-icon>
                </div>
              </template>
              <div class="action-box">
                <div
                  class="action-btn"
                  v-if="BtnLimits.Detail"
                  @click="showDetail(scope.row.id)"
                >
                  <el-icon style="color: #2f4cdd"><View /></el-icon>
                  <span>{{ $t("ccls.ccl.detail") }}</span>
                </div>
                <div
                  class="action-btn"
                  v-if="
                    (scope.row.status == '0' || scope.row.status == '1') &&
                    BtnLimits.Operate
                  "
                  @click="
                    toOperate(
                      scope.row.id,
                      scope.row.status,
                      scope.row.tcmRequestId
                    )
                  "
                >
                  <el-icon style="color: #ff6d4d"><Edit /></el-icon>
                  <span>{{ $t("unifiedPrompts.unifiedPrompt.edit") }}</span>
                </div>
                <div
                  class="action-btn"
                  v-if="
                    (scope.row.status == '2' || scope.row.status == '4') &&
                    BtnLimits.Approve
                  "
                  @click="
                    toApprove(
                      scope.row.id,
                      scope.row.status,
                      scope.row.tcmRequestId
                    )
                  "
                >
                  <el-icon style="color: #ff6d4d"><Edit /></el-icon>
                  <span>{{ $t("unifiedPrompts.unifiedPrompt.edit") }}</span>
                </div>
                <div
                  class="action-btn"
                  v-if="scope.row.status == '1' && BtnLimits.Reject"
                  @click="toReject(scope.row.id, scope.row.status)"
                >
                  <el-icon style="color: #f24242"><CloseBold /></el-icon>
                  <span>{{ $t("unifiedPrompts.unifiedPrompt.reject") }}</span>
                </div>
                <div
                  class="action-btn"
                  v-if="
                    (scope.row.status == '2' || scope.row.status == '5') &&
                    BtnLimits.Rework
                  "
                  @click="toRevoke(scope.row.id, scope.row.status)"
                >
                  <el-icon style="color: #2f4cdd"><Callback /></el-icon>
                  <span>{{ $t("homePage.home.Callback") }}</span>
                </div>
              </div>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <div>
        {{ $t("homePage.home.Showing") }} {{ pageSize }}
        {{ $t("homePage.home.From") }} {{ total }}
        {{ $t("homePage.home.Data") }}
        <el-popover placement="top" :width="70" trigger="click">
          <template #reference>
            <el-icon
              style="margin-left: 5px; font-size: 17px; vertical-align: middle"
              ><Operation
            /></el-icon>
          </template>
          <el-input
            v-model="searchForm.limit"
            @input="(val) => (searchForm.limit = val.replace(/[^\d]/g, ''))"
            :min="1"
            style="width: 130px"
            @change="handleSizeChange"
          >
            <template #suffix> {{ $t("ccls.ccl.barPage") }}</template>
          </el-input>
        </el-popover>
      </div>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="pageSize"
        :current-page="searchForm.page"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
  <VemChangeDia
    ref="vemChangeDia"
    @ChangeUserConfirm="vemChangeUserData"
  ></VemChangeDia>
</template>
<script>
import {
  GetUserRoles,
  getApplicationList,
  deleteApplyDetail,
  updateStatusCostApplys,
  GetOrgs,
  ReworkApply,
  UpdateApplyStatus,
  GetUserSettingMenu,
} from "../../assets/js/requestApi.js";
import commonUtil from "../../assets/js/commonUtil.js";
import { ElMessageBox } from "element-plus";
import VemChangeDia from "../../components/comments/VemChangeDia.vue";
import zhCn from "element-plus/dist/locale/zh-cn.mjs";

export default {
  name: "OperateList",
  components: {
    VemChangeDia,
  },
  activated() {
    // 在首次挂载、
    // 以及每次从缓存中被重新插入的时候调用
    this.search();
    this.getUnits();
  },
  deactivated() {
    clearTimeout(this.timer);
    // 在从 DOM 上移除、进入缓存
    // 以及组件卸载时调用
  },
  data() {
    return {
      locale: zhCn,
      TableHeight: 0,
      searchForm: {
        timers: "",
        CommodityId: "",
        currencyId: "",
        date: "",
        StartDate: "",
        EndDate: "",
        status: "",
        Keyword: "",
        priceType: 0,
        isDraft: 0,
        page: 1,
        limit: 10,
        key: "",
        PageType: 3,
        versionType: 1,
        partNumber: "",
        programName: "",
      },
      // commodity选项
      // commodity选项
      CommodityOptions: [],
      defaultProps: {
        children: "children",
        label: "Name",
      },
      // status选项
      statusOptions: [
        {
          value: "",
          label: "tcmdatalists.tcmdatalist.All",
        },
        {
          value: 0,
          label: "tcmdatalists.tcmdatalist.notStart",
        },
        {
          value: 1,
          label: "tcmdatalists.tcmdatalist.inProcess",
        },
        {
          value: 2,
          label: "tcmdatalists.tcmdatalist.toApproved",
        },
        {
          value: 4,
          label: "tcmdatalists.tcmdatalist.underApproved",
        },
        {
          value: 5,
          label: "tcmdatalists.tcmdatalist.rejected",
        },
        {
          value: 6,
          label: "tcmdatalists.tcmdatalist.approved",
        },
      ],

      tableData: [],
      // 已选择数据
      chooseData: [],
      // 已选择对比数据
      drawer: false,
      // 选择的对比数据列表
      compareList: [],
      // 总页数
      total: 0,
      pageSize: 10,
      power: [],
      timer: "",
      // 权限列表
      roleList: [],
      // 操作按钮权限
      operateBtnDisabled: false,
      // 撤回按钮权限
      revokeBtnDisabled: false,
      // 审批按钮权限
      approveBtnDisabled: false,
      // 当前用户信息
      userInfo: {},
      approverFlag: false,
      operatorFlag: false,
    };
  },

  mounted() {
    this.autoHeight();
    this.getRole();
    this.getList();
    // this.getCommdity();
    this.CommodityOptions = JSON.parse(sessionStorage.getItem("Commodity"));
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  },
  methods: {
    RowClick(row) {
      // this.showDetail(row.id);
      if ((row.status == "0" || row.status == "1") && this.BtnLimits.Operate) {
        this.toOperate(row.id, row.status, row.tcmRequestId);
      }
      if ((row.status == "2" || row.status == "4") && this.BtnLimits.Approve) {
        this.toApprove(row.id, row.status, row.tcmRequestId);
      }
    },
    autoHeight() {
      // 初始化的时候，设置高度
      this.setHeight();
      // 改变浏览器窗口大小的时候，设置高度
      window.addEventListener("resize", () => {
        this.setHeight();
      });
    },
    setHeight() {
      this.$nextTick(() => {
        this.TableHeight = window.innerHeight - 235; // 这里的X就是顶部栏的高度
      });
    },
    // 获取当前用户角色
    getRole() {
      GetUserSettingMenu({}, (response) => {
        if (response && response.code == 200) {
          const { approver, operator } = response.result.operationRole;
          this.approverFlag = approver.isCheck;
          this.operatorFlag = operator.isCheck;
        }
      });

      // let _this = this;
      // _this.roleList = [];
      // GetUserRoles({}, (response) => {
      //   if (response && response.code == 200) {
      //     response.result.forEach(function (val, index) {
      //       _this.roleList.push(val.name);
      //     });
      //     if (_this.roleList.indexOf("operator") < 0) {
      //       this.operateBtnDisabled = true;
      //       this.revokeBtnDisabled = true;
      //     }
      //     if (_this.roleList.indexOf("approved") < 0) {
      //       this.approveBtnDisabled = true;
      //     }
      //   }
      // });
    },
    getUnits() {
      this.timer = setTimeout(this.gettime, 1000);
    },
    gettime() {
      this.BtnLimits = JSON.parse(sessionStorage.getItem("Btn"));
    },

    getList() {
      this.searchForm = {
        CommodityId: "",
        currencyId: "",
        date: "",
        StartDate: "",
        EndDate: "",
        status: "",
        Keyword: "",
        priceType: 0,
        isDraft: 0,
        page: 1,
        limit: 10,
        key: "",
        PageType: 3,
        versionType: 1,
        partNumber: "",
        programName: "",
      };
      if (this.$route.query.Time) {
        this.searchForm.StartDate = this.$route.query.Time;
        this.searchForm.date = [this.$route.query.Time, this.$route.query.Time];
        this.searchForm.StartDate = this.$route.query.Time;
        this.searchForm.EndDate = this.$route.query.Time;
      }
      if (this.$route.query.pageType) {
        this.searchForm.PageType = this.$route.query.pageType;
      }
      // if (this.$route.query.status) {
      // 	this.searchForm.status = parseFloat(this.$route.query.status)
      // }
      console.log(this.searchForm);
      this.search();
    },
    search() {
      getApplicationList(this.searchForm, (response) => {
        if (response && response.code == 200) {
          this.tableData = response.data;
          console.log(this.tableData);
          this.total = response.count;
        }
      });
    },
    // 分页
    handleCurrentChange(val) {
      this.searchForm.page = val;
      this.search();
    },
    // 分页数量修改
    handleSizeChange() {
      this.pageSize = parseInt(this.searchForm.limit);
      if (this.searchForm.limit === "") {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.setPageNum"),
          "",
          "warning"
        );
        return;
      }
      if (this.searchForm.limit == 0) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.pageThanZero"),
          "",
          "warning"
        );
        return;
      }
      this.searchForm.page = 1;
      this.search();
    },
    // 获取商品分类数据
    getCommdity() {
      let _this = this;
      GetOrgs(
        {
          type: 0,
          versionType: 0,
          TypeCost: 0,
          isAll: 0,
        },
        (response) => {
          if (response && response.Code == 200) {
            this.CommodityOptions = commonUtil.transferList(response.Result);
            console.log(this.CommodityOptions);
          }
        }
      );
    },
    // 切换时间
    changeDate(val) {
      console.log(val);
      if (val != null) {
        this.searchForm.StartDate = val[0];
        this.searchForm.EndDate = val[1];
      } else {
        this.searchForm.StartDate = "";
        this.searchForm.EndDate = "";
      }
    },
    // 新增
    addEntry() {
      this.$router.push({
        path: "/tcmAdd",
        name: "TcmAdd",
        query: {
          type: "add",
        },
      });
    },
    // 表格多选
    handleSelectionChange(val) {
      console.log(val);
      this.chooseData = val;
    },
    // 删除
    deleteApp() {
      console.log(this.chooseData);
      if (this.chooseData.length == 0) {
        commonUtil.showTipInfo(
          this.$t("userlists.userlist.selecWant"),
          "",
          "warning"
        );
        return;
      }
      let ids = [];
      this.chooseData.forEach(function (val, index) {
        ids.push(val.id);
      });
      deleteApplyDetail(ids, (response) => {
        if (response && response.code == 200) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.deleteSuccessfully"),
            "",
            "success"
          );
          this.search();
        }
      });
    },
    // 详情
    showDetail(id) {
      this.$router.push({
        path: "/tcmResult",
        name: "tcmResult",
        query: {
          id: id,
          crumbs: ["homePage.home.Operation", "ccls.ccl.detail"],
        },
      });
    },
    revokeOperate(id) {
      ElMessageBox.confirm(this.$t("ccls.ccl.pleaseOperation"), {
        cancelButtonText: this.$t("ccls.ccl.cancel"),
        confirmButtonText: this.$t("ccls.ccl.ok"),
      })
        .then(() => {
          UpdateApplyStatus(
            {
              nodeId: 2,
              flowstatus: 2,
              status: 1,
              id: id,
            },
            (response) => {
              console.log(response);
              if (response && response.code == 200) {
                this.editProcess(id);
                // commonUtil.showTipInfo(response.message, '', 'success')
              }
            }
          );
          done();
        })
        .catch(() => {
          // catch error
        });
    },
    rejectData(id) {
      let _this = this;
      ElMessageBox.prompt(this.$t("ccls.ccl.pleaseReject"), "", {
        cancelButtonText: this.$t("ccls.ccl.cancel"),
        confirmButtonText: this.$t("ccls.ccl.ok"),
        inputValidator: (value) => {
          if (!value) {
            return _this.$t("unifiedPrompts.unifiedPrompt.remarkNotEmpty");
          }
        },
      })
        .then((info) => {
          if (info.action == "confirm") {
            if (info.value === "" || info.value === null) {
              commonUtil.showTipInfo(
                this.$t("unifiedPrompts.unifiedPrompt.remarkNotEmpty"),
                "",
                "warning"
              );
            } else {
              UpdateApplyStatus(
                {
                  nodeId: 2,
                  flowstatus: -1,
                  status: 0,
                  id: id,
                  comment: info.value,
                },
                (response) => {
                  if (response && response.code == 200) {
                    commonUtil.showTipInfo(
                      this.$t(
                        "unifiedPrompts.unifiedPrompt.operateSuccessfully"
                      ),
                      "",
                      "success"
                    );
                    this.search();
                  }
                }
              );
              done();
            }
          } else {
            done();
          }
        })
        .catch(() => {
          // catch error
        });
    },
    // 编辑
    edit(id) {
      this.$router.push({
        path: "/addOperate",
        name: "AddOperate",
        query: {
          type: "detail",
          id: id,
          crumbs: [
            "homePage.home.Operation",
            "unifiedPrompts.unifiedPrompt.edit",
          ],
        },
      });
    },
    // 继续编辑
    editProcess(id) {
      this.$router.push({
        path: "/addOperate",
        name: "AddOperate",
        query: {
          type: "edit",
          id: id,
          crumbs: [
            "homePage.home.Operation",
            "unifiedPrompts.unifiedPrompt.edit",
          ],
        },
      });
    },
    // 审批
    approve(id, status) {
      this.$router.push({
        path: "/apprcverSUM",
        name: "apprcverSUM",
        query: {
          type: "edit",
          id: id,
          status: status,
        },
      });
    },
    // 操作按钮点击事件
    toOperate(id, status, tcmRequestId) {
      if (!id) {
        if (this.chooseData.length === 0) {
          commonUtil.showTipInfo(
            this.$t("userlists.userlist.selecWant"),
            "",
            "warning"
          );
          return;
        }
        if (this.chooseData.length > 1) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.onlyOne"),
            "",
            "warning"
          );
          return;
        }
        console.log(this.chooseData);
        status = this.chooseData[0].status;
        tcmRequestId = this.chooseData[0].tcmRequestId;
        id = this.chooseData[0].id;
      }
      if (!this.operatorFlag) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.noPermission"),
          "",
          "warning"
        );
        return;
      }

      if (status > 1) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.underApprove"),
          "",
          "warning"
        );
        return;
      }
      if (status === 6) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.isApprovedNotToOperate"),
          "",
          "warning"
        );
        return;
      }
      if (status === 1) {
        if (
          tcmRequestId != null &&
          tcmRequestId != this.userInfo.id &&
          tcmRequestId != "00000000-0000-0000-0000-000000000000"
        ) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.operatedByother"),
            "",
            "warning"
          );
          return;
        }
      }
      if (status === 0) {
        this.edit(id);
      } else if (status === 1) {
        this.editProcess(id);
      }
    },
    // 撤回按钮点击事件
    toRevoke(id, status) {
      if (!id) {
        if (this.chooseData.length === 0) {
          commonUtil.showTipInfo(
            this.$t("userlists.userlist.selecWant"),
            "",
            "warning"
          );
          return;
        }
        if (this.chooseData.length > 1) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.onlyOne"),
            "",
            "warning"
          );
          return;
        }
        status = this.chooseData[0].status;
        id = this.chooseData[0].id;
      }
      if (!this.operatorFlag) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.noRevokePermission"),
          "",
          "warning"
        );
        return;
      }
      if (status < 2) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.noStartToRevoke"),
          "",
          "warning"
        );
        return;
      }
      if (status > 2 && status < 5) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.isApprovedNotToRevoke"),
          "",
          "warning"
        );
        return;
      }
      if (status === 6) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.isApprovedNotToOperate"),
          "",
          "warning"
        );
        return;
      }
      if (status === 2 || status === 5) {
        this.revokeOperate(id);
      }
    },
    // 审批按钮点击事件
    toApprove(id, status, tcmRequestId) {
      if (!id) {
        if (this.chooseData.length === 0) {
          commonUtil.showTipInfo(
            this.$t("userlists.userlist.selecWant"),
            "",
            "warning"
          );
          return;
        }

        if (this.chooseData.length > 1) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.onlyOne"),
            "",
            "warning"
          );
          return;
        }

        id = this.chooseData[0].id;
        status = this.chooseData[0].status;
        tcmRequestId = this.chooseData[0].tcmRequestId;
      }
      if (!this.approverFlag) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.noApprovePermission"),
          "",
          "warning"
        );
        return;
      }
      if (status < 2) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.noStartToApprove"),
          "",
          "warning"
        );
        return;
      }
      if (status === 6) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.isApprovedNotToOperate"),
          "",
          "warning"
        );
        return;
      }
      if (status != 2) {
        if (
          tcmRequestId != null &&
          tcmRequestId != this.userInfo.id &&
          tcmRequestId != "00000000-0000-0000-0000-000000000000"
        ) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.operatedByother"),
            "",
            "warning"
          );
          return;
        }
      }

      if (status === 2 || status === 4) {
        this.approve(id, status);
      }
    },
    // 退回按钮点击事件
    toReject(id, status) {
      if (!id) {
        if (this.chooseData.length === 0) {
          commonUtil.showTipInfo(
            this.$t("userlists.userlist.selecWant"),
            "",
            "warning"
          );
          return;
        }
        if (this.chooseData.length > 1) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.onlyOne"),
            "",
            "warning"
          );
          return;
        }
        status = this.chooseData[0].status;
        id = this.chooseData[0].id;
      }

      if (status != 1) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.inProcessTonotStart"),
          "",
          "warning"
        );
        return;
      }
      if (status === 1) {
        this.rejectData(id, status);
      }
    },
    toChange() {
      if (this.chooseData.length === 0) {
        commonUtil.showTipInfo(
          this.$t("userlists.userlist.selecWant"),
          "",
          "warning"
        );
        return;
      }
      if (this.chooseData.length > 1) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.onlyOne"),
          "",
          "warning"
        );
        return;
      }
      let commodityId = this.chooseData[0].commodityId;
      this.$refs.vemChangeDia.visible = true;
      this.$refs.vemChangeDia.commodityId = commodityId;
    },
    vemChangeUserData(data) {
      let id = this.chooseData[0].id;
      let node = this.chooseData[0].flowNode;
      let flowstatus = this.chooseData[0].flowState;
      let status = this.chooseData[0].status;
      var that = this;
      UpdateApplyStatus(
        {
          nodeId: node,
          userId: data.userId,
          id: id,
          flowstatus: flowstatus,
          status: status,
          comment: data.comment,
        },
        (response) => {
          console.log(response);
          if (response && response.code == 200) {
            that.$refs.vemChangeDia.visible = false;
            commonUtil.showTipInfo(
              this.$t("other.other.ChangeSuccess"),
              "",
              "success"
            );
            that.search();
          }
        }
      );
    },
  },
};
</script>


<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  background: url("../../assets/img/car-table-bg.png") no-repeat center;
  background-size: contain;
}
.main-top_handle {
  display: flex;
  justify-content: space-between;
  height: 40px;
}
.handle-btn {
  align-items: center;
  height: 40px;
  .handle-btn-item {
    padding: 0 20px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    background: #b2bde7;
    color: #fff;
    border-radius: 25px;
    cursor: pointer;
    margin-left: 10px;
  }
}

.main-handle {
  width: 80%;
  align-items: center;
}
.main-handle-item {
  margin-right: 10px;
  flex: 1;
}
.main-handle input,
.main-handle .el-select .el-input {
  width: 100%;
  border-radius: 10px;
  font-size: 14px;
}
.main-handle input::placeholder {
  color: #3e4954;
  text-align: center;
  font-size: 14px;
}
// 日期选择器
.select-box {
  position: relative;
  .select-btn {
    width: 200px;
    height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 10px;
    background: #fff;
    cursor: pointer;
    .Calendar,
    .Switch {
      font-size: 18px;
      color: #2f4cdd;
    }
  }
}
.search-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 30px;
  background: #445581;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
</style>
<!-- <style scoped lang="scss">
	.ElDrawer :deep(.el-drawer__title){
		font-size: 2rem;
	}

	.tcm-search{
		width: calc(100% - var( --asidewidth));
		background-color: var(--searchBg);
		padding: 20px;
		box-sizing: border-box;
		position:fixed;
		z-index:888;
	}
	.cell {
		.divLink{
			color:blue;
			cursor: pointer;
			text-decoration: underline;
		}
		.divLink:hover{
			text-decoration: none;
		}
		.opreateList{
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}
	}
	
	.tcm-hold{
		height:150px;
	}
	.tcm-search td{
		/* width: 20%; */
		padding: 0 10px;
	}
	.operate-btn{
		border-radius: 5px;
		background-color: var(--themeColor);
		color: #fff;
		text-align: center;
		padding: 7px 10px;
		display: inline-block;
		min-width: 80px;
		cursor: pointer;
	}
	.color-ff0000{
		color: #ff0000;
	}
	.color-F59A23{
		color: #F59A23;
	}
	.color-0000FF{
		color: #0000FF;
	}
	.color-D9001B{
		color: #D9001B;
	}
	.color-67C23A{
		color:#67C23A ;
	}
	.mlg-10{
		margin-left: 10px
	}
	.mrg-10{
		margin-right: 10px;
	}
	.page-cla{
		padding: 20px;
		display: flex;
		justify-content: flex-end;
	}
	.box-card{
		margin-bottom: 10px;
	}
	.drawer-content{
		display: flex;
		flex-direction: column;
		height: 100%;
	}
	.drawer-footer{
		display: flex;
		justify-content: flex-end;
	}
</style> -->