const info = {
    // 中文  templateLists.templateList.
    ZH: {
        templateNo: '模板编号',
        templateName: '模板名称',
        version: '版本',
        comments: '备注',
        creator: '创建者',
        date: '日期',
        enable: '激活状态',
        operate: '操作',
        revisions: '版本',
        region: '地区',
        cellNo: '单元编号',
        cellName: '单元名称',
        materialDescription: '物料名称',
        batchEnable: '分批激活',
        batchDisabling: '分批取消',
        operationDescription: '操作描述',
        purchasedCell: '新建采购件单元',
        itemDescription: '项目、材料或工艺描述',
        newInterface: '新建模板界面',
        time: '时间',
        commodityRequired: '请选择商品分类！',
        currencyRequired: '请选择币种！',
        firstPlace: '已在第一位，不可往左移！',
        endPlace: '已在最后一位，不可往右移！',
        ChangeCommodity: '更改商品分类',
        tcmaddTips: '您填写的组件号已存在于系统中!如果您想引用以前的版本，请从列表选择。',
        IsAll: '所有版本',
        SelectErrMsg: '选择失败，此条数据缺少货币',

    },
    // 英文
    EN: {
        templateNo: 'Template No',
        templateName: 'Template Name',
        version: 'Version',
        comments: 'Comments',
        creator: 'Creator',
        date: 'Date',
        enable: 'Status',
        operate: 'Operate',
        revisions: 'Revisions',
        region: 'Region',
        cellNo: 'Cell No',
        cellName: 'Cell Name',
        materialDescription: 'Material Description',
        batchEnable: 'Batch Enable',
        batchDisabling: 'Batch Disabling',
        operationDescription: 'Operation Description',
        purchasedCell: 'New Purchased Components Cell',
        itemDescription: 'Item, Material or Process Description',
        newInterface: 'New Template Interface',
        time: 'Time',
        commodityRequired: 'Please select commodity!',
        currencyRequired: 'Please select currency!',
        firstPlace: 'Already in the first place, do not move left!',
        endPlace: 'You are in the last position. Do not move to the right!',
        ChangeCommodity: 'Change Commodity',
        tcmaddTips: 'The part number you flled in already exists in the system! If you want to refer to a previous version, please select from the list below.',
        IsAll: 'All Version',
        SelectErrMsg: 'Selection failed, this data lacks currency',

    }
}
export default info