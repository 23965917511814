const info = {
    // 中文
    ZH: {
        processCalculatorManage: '工艺计算器管理',
        close: '关闭',
        Tools: '工具',
        Downloadtemplate: '下载模板',
        AllCheck: '全选',
        VemChange: '更换操作者',
        SetPermissions: '权限',
        exchangeRateSet: '税率管理',
        CurrencyManagement: '货币管理',
        CommodityManagement: '商品分类管理',
        pleaseName: '请输入名称!',
        pleaseEngName: '请输入英文名称!',
        RoleManagement: '角色管理',
        EnterDepartmentname: '请输入部门名称',
        DepartmentManagement: '部门管理',
        EnterMachiningType: '请输入加工类型',
        AddGrade: '添加品级',
        Process: '工序',
        EnterProcess: '请输入工序',
        StrengthDetails: '强度详情',
        EnterStrength: '请输入强度详情',
        Comment: '备注',
        Company: '公司',
        EnterMaterial: '请输入物料类型',
        ChooseMaterial: '请选择物料',
        EnterCompany: '请输入公司',
        EnterNum: '请输入数',
        ChooseCurrency: '请选择币种',
        EnterManufacture: '请输入制造商名称',
        EnterDescription: '请输入描述',
        SelectRagion: '请选择地区',
        SelectorTemplate: '选择人员',
        PersonnelTemplate: '人员模板',
        ConfrimPwdErr: ' 两次密码输入不相等，请重新输入',
        PwsRulesErr: '必须包含至少 12 个字符,必须包含大写字母、小写字母、数字、特殊符号',
        Tonnage: '吨位',
        PartWeight: '零件重量',
        CycleTime: '生产周期',
        CycleTime_cavity: '生产周期(cavity)',
        BracketWeight: '支架重量',
        Length: '长度',
        Width: '宽度',
        Height: '高度',
        WallThickness: '壁厚',
        ProjectedArea: '投影面积',
        Material: '材料',
        NoofCavities: '腔体数量',
        ClampingPressure: '合模压力',
        ClampingForce: '理论锁模压力',
        ClampingForceTotal: '最终锁模压力',
        SafetyFactor: '安全系数',
        SelectedMachine: '选择机器',
        Compute: '计算',
        Tons: '吨',
        TonsSq: '吨/平方英寸',
        Numbers: '数',
        AddRow: '添加行',
        AddColumn: '添加列',
        Submit: '提交',
        Operate: '操作',
        Plastic: '塑料',
        CircleTime: '循环时间',
        Density: '密度',
        NetWeight: '净重',
        RunnerLength: '转轮长度',
        RunnerVolume: '转轮体积',
        RunnerWeight: '转轮重量',
        GrossWeight: '总重量',
        RunnerDiameter: '转轮直径',
        Range: '范围',
        Typical: '平常',
        MoldClose: '模具关闭',
        MoldOpen: '模具打开',
        Ejection: '脱模',
        Injection: '注入',
        HoldingTime: '占用时间',
        CoolingTime: '冷区时间',
        TotalCT: '总CT',
        PartActualSize: '零件实际尺寸',
        PartBlankSize: '零件坯料尺寸',
        RoundoffBlankSize: '圆整坯料尺寸',
        PartNetWeight: '零件净重',
        PartGrossWeight: '零件毛重',
        Yeild: '产量',
        History: '历史',
        Calculate: '计算',
        CTCalculator: 'CT 计算器',
        CTCalculatorList: 'CT 计算器列表',
        MaterialType: '材料类型',
        Ferrous: '含铁',
        Nonferrous: '不含铁',
        CCLSizeDetail: 'CCL尺寸详情',
        ProcessIndirect: '间接工序',
        DirectMaterial: '直接材料',
        CustomEquimentList: '定制设备清单',
        Spanish: '西班牙语',
        Polish: '波兰语',
        Chinese: '中文',
        English: '英文',
        NewButton: '新增按钮',
        EditButton: '修改按钮',
        ButtonName: '按钮名称',
        ButtonFlag: '按钮标识',
        ExistingButton: '已有按钮',
        All: '全部',
        Enable: '激活',
        Disable: '禁用',
        ChangeSuccess: '更改成功',
    },
    // 英文
    EN: {
        processCalculatorManage: 'Process Calculator',
        close: 'Close',
        Tools: 'Tools',
        Downloadtemplate: 'Download Template',
        AllCheck: 'Select all',
        VemChange: 'Vem Change',
        SetPermissions: 'Permissions',
        exchangeRateSet: 'Exchange Rate',
        CurrencyManagement: 'Currency Management',
        CommodityManagement: 'Commodity Management',
        pleaseName: 'Please enter name!',
        pleaseEngName: 'Please enter an English name!',
        RoleManagement: 'Role Management',
        EnterDepartmentname: 'Please enter a department name',
        DepartmentManagement: 'Department',
        EnterMachiningType: 'Please enter machining type',
        AddGrade: 'Add Grade',
        Process: 'Process',
        EnterProcess: 'Please enter process',
        StrengthDetails: 'Strength Details',
        EnterStrength: 'Please enter strength details',
        Comment: 'Comment',
        Company: '公司',
        EnterMaterial: 'Please enter material type',
        ChooseMaterial: 'Please choose material',
        EnterCompany: 'Please enter company',
        EnterNum: 'Please enter number',
        ChooseCurrency: 'Please choose currency',
        EnterManufacture: 'Please enter Manufacture Name',
        EnterDescription: 'Please enter description',
        SelectRagion: 'Please select ragion',
        SelectorTemplate: 'Selector',
        PersonnelTemplate: 'Personnel Template',
        ConfrimPwdErr: 'The two passwords are different. Please re-enter them',
        PwsRulesErr: 'The password must contain at least 12 characters, including uppercase letters, lowercase letters, digits, and special symbols',
        Tonnage: 'Tonnage',
        PartWeight: 'Part Weight',
        CycleTime: 'Cycle Time',
        CycleTime_cavity: 'Cycle Time(cavity)',
        BracketWeight: 'Bracket Weight',
        Length: 'Length',
        Width: 'Width',
        Height: 'Height',
        WallThickness: 'Wall Thickness',
        ProjectedArea: 'Projected Area',
        Material: 'Material',
        NoofCavities: 'No. of Cavities',
        ClampingPressure: 'Clamping Pressure',
        ClampingForce: 'Clamping Force(Theoretical)',
        ClampingForceTotal: 'Clamping Force(Total)',
        SafetyFactor: 'Safety Factor',
        SelectedMachine: 'Selected Machine',
        Compute: 'Compute',
        Tons: 'Tons',
        TonsSq: 'Tons/Sq. In.',
        Numbers: 'Numbers',
        AddRow: 'Add Row',
        AddColumn: 'Add Column',
        Submit: 'Submit',
        Operate: 'Operate',
        Plastic: 'Plastic',
        CircleTime: 'Circle Time',
        Density: 'Density',
        NetWeight: 'Net Weight',
        RunnerLength: 'Runner Length',
        RunnerVolume: 'Runner Volume',
        RunnerWeight: 'Runner Weight',
        GrossWeight: 'Gross Weight',
        RunnerDiameter: 'Runner Diameter',
        Range: 'Range',
        Typical: 'Typical',
        MoldClose: 'Mold Close',
        MoldOpen: 'Mold Open',
        Ejection: 'Ejection',
        Injection: 'Injection',
        HoldingTime: 'Holding Time',
        CoolingTime: 'Cooling Time',
        TotalCT: 'Total CT',
        PartActualSize: 'Part Actual Size',
        PartBlankSize: 'Part Blank Size',
        RoundoffBlankSize: 'Round off Blank Size',
        PartNetWeight: 'Part Net Weight',
        PartGrossWeight: 'Part Gross Weight',
        Yeild: 'Yeild',
        History: 'History',
        Calculate: 'Calculate',
        CTCalculator: 'CT Calculator',
        CTCalculatorList: 'CT Calculator List',
        MaterialType: 'Material Type',
        Ferrous: 'Ferrous',
        Nonferrous: 'Nonferrous',
        CCLSizeDetail: 'CCL Size Detail',
        ProcessIndirect: 'Process Indirect',
        DirectMaterial: 'Direct Material',
        CustomEquimentList: 'Custom Equiment List',
        Spanish: 'Spanish',
        Polish: 'Polish',
        Chinese: 'Chinese',
        English: 'English',
        NewButton: 'New Button',
        EditButton: 'Edit Button',
        ButtonName: 'Button Name',
        ButtonFlag: 'Button ID',
        ExistingButton: 'Existing Button',
        All: 'All',
        Enable: 'Enable',
        Disable: 'Disable',
        ChangeSuccess: 'Change Success',
    }
}
export default info