<template>
  <div class="main">
    <div class="crumbs-box">
      <div class="crumbs-box-item parent" @click="Goback">
        {{ $t("homePage.home.Back") }}
      </div>
      <div class="line"></div>
      <div class="crumbs-box-item" v-for="(item, index) in crumbs">
        {{ item }}
        <el-icon v-show="index != crumbs.length - 1"><ArrowRight /></el-icon>
      </div>
    </div>

    <div class="main-top_handle">
      <!-- <div class="title">CT Calculator - Custom Equiment List - Tool List</div> -->

      <div class="main-handle flex">
        <div class="handle-btn flex">
          <div class="handle-btn-item" @click="addData">
            {{ $t("pcbas.pcba.new") }}
          </div>
          <div class="handle-btn-item" @click="editData">
            {{ $t("pcbas.pcba.edit") }}
          </div>
          <div
            class="handle-btn-item"
            style="margin-right: 10px"
            @click="deleteData"
          >
            {{ $t("pcbas.pcba.delete") }}
          </div>
        </div>
        <div
          class="main-handle-item"
          style="white-space: nowrap; font-size: 15px"
        >
          {{ $t("ccls.ccl.Machiningtype") }}:
          <el-tag type="warning" style="margin-left: 10px">{{
            machiningTypeName
          }}</el-tag>
        </div>
        <div
          class="main-handle-item"
          style="white-space: nowrap; font-size: 15px"
        >
          {{ $t("other.other.Material") }}:
          <el-tag type="warning" style="margin-left: 10px">{{
            materialName
          }}</el-tag>
        </div>

        <div class="main-handle-item">
          <input
            type="text"
            style="width: 180px"
            v-model="searchForm.key"
            :placeholder="$t('ccls.ccl.Toolname')"
          />
        </div>
        <div class="search-btn" @click="search">
          {{ $t("tcmlists.tcmlist.search") }}
        </div>
      </div>
    </div>

    <div class="regional_table">
      <el-table
        border
        stripe
        :empty-text="$t('tcmlists.tcmlist.noData')"
        ref="roleTable"
        :data="tableData"
        :height="TableHeight"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        @row-click="RowClick"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column
          prop="toolName"
          :label="$t('ccls.ccl.Toolname')"
          align="center"
        />
        <el-table-column
          prop="toolType"
          :label="$t('ccls.ccl.Tooltype')"
          align="center"
        />
        <el-table-column
          prop="alSpeed"
          :label="$t('ccls.ccl.Speed')"
          align="center"
        >
          <template #default="scope">
            <div class="color-F59A23">{{ scope.row.alSpeed }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="alFeed"
          :label="$t('ccls.ccl.Feed')"
          align="center"
        >
          <template #default="scope">
            <div class="color-F59A23">{{ scope.row.alFeed }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="remark"
          :label="$t('pcbas.pcba.remark')"
          align="center"
        />
      </el-table>
    </div>
    <div class="pagination">
      <div>
        {{ $t("homePage.home.Showing") }} {{ pageSize }}
        {{ $t("homePage.home.From") }} {{ total }}
        {{ $t("homePage.home.Data") }}
        <el-popover placement="top" :width="70" trigger="click">
          <template #reference>
            <el-icon
              style="margin-left: 5px; font-size: 17px; vertical-align: middle"
              ><Operation
            /></el-icon>
          </template>
          <el-input
            v-model="searchForm.limit"
            @input="(val) => (searchForm.limit = val.replace(/[^\d]/g, ''))"
            :min="1"
            style="width: 130px"
            @change="handleSizeChange"
          >
            <template #suffix> {{ $t("ccls.ccl.barPage") }}</template>
          </el-input>
        </el-popover>
      </div>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="pageSize"
        :current-page="searchForm.page"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <AddTool ref="addDia" @confirmInfo="confirmInfo"></AddTool>
  </div>
</template>

<script>
import {
  LoadTool,
  AddOrUpdateTool,
  DeleteTool,
  GetCtEquiment,
} from "../../../assets/js/requestApi.js";
import commonUtil from "../../../assets/js/commonUtil.js";
import AddTool from "./addTool.vue";
export default {
  components: {
    AddTool,
  },
  data() {
    return {
      TableHeight: 0,
      searchForm: {
        machineType: "",
        description: "",
        page: 1,
        limit: 10,
        key: "",
      },
      tableData: [],
      // 已选择数据
      chooseData: [],
      // 已选择对比数据
      drawer: false,
      // 选择的对比数据列表
      compareList: [],
      // 总页数
      total: 0,
      pageSize: 10,
      power: [],
      btnAdd: "",
      btnDel: "",
      btnEdit: "",
      timer: "",
      timers: "",
      // 数据重复是否继续导入
      isContinue: false,
      machiningTypeName: "",
      materialName: "",
      crumbs: [
        this.$t("other.other.CTCalculator"),
        this.$t("other.other.CustomEquimentList"),
        this.$t("ccls.ccl.ToolList"),
      ],
    };
  },
  watch: {
    "$i18n.locale"() {
      this.crumbs = [
        this.$t("other.other.CTCalculator"),
        this.$t("other.other.CustomEquimentList"),
        this.$t("ccls.ccl.ToolList"),
      ];
    },
  },
  mounted() {
    this.autoHeight();
    if (this.$route.query.id) {
      this.searchForm.machineType = this.$route.query.id;
      this.getList(this.$route.query.id);
      this.getEquipDetail(this.$route.query.id);
    }
    this.timer = setTimeout(this.gettime, 1000);
  },
  methods: {
    RowClick(row) {
      this.$refs.addDia.form = JSON.parse(JSON.stringify(row));
      this.$refs.addDia.form.machiningTypeName = this.machiningTypeName;
      this.$refs.addDia.visible = true;
    },
    autoHeight() {
      // 初始化的时候，设置高度
      this.setHeight();
      // 改变浏览器窗口大小的时候，设置高度
      window.addEventListener("resize", () => {
        this.setHeight();
      });
    },
    setHeight() {
      this.$nextTick(() => {
        this.TableHeight = window.innerHeight - 215; // 这里的X就是顶部栏的高度
      });
    },
    gettime() {
      this.power = JSON.parse(localStorage.getItem("power"));
      this.power.forEach((e) => {
        if (e.name == "添加") {
          this.btnAdd = 1;
        }
        if (e.name == "删除") {
          this.btnDel = 1;
        }
        if (e.name == "编辑") {
          this.btnEdit = 1;
        }
      });
      clearTimeout(this.timer);
    },
    getEquipDetail(id) {
      GetCtEquiment(
        {
          id: id,
        },
        (response) => {
          if (response && response.code == 200) {
            this.machiningTypeName = response.result.machiningType;
            this.materialName = response.result.materialName;
          }
        }
      );
    },
    getList(id) {
      this.searchForm = {
        machineType: id,
        description: "",
        page: 1,
        limit: 10,
        key: "",
      };
      this.search();
    },
    search() {
      LoadTool(this.searchForm, (response) => {
        if (response && response.code == 200) {
          this.tableData = response.data;
          this.total = response.count;
        }
      });
    },
    // 分页
    handleCurrentChange(val) {
      this.searchForm.page = val;
      this.search();
    },
    handleSizeChange() {
      this.pageSize = parseInt(this.searchForm.limit);
      if (this.searchForm.limit === "") {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.setPageNum"),
          "",
          "warning"
        );
        return;
      }
      if (this.searchForm.limit == 0) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.pageThanZero"),
          "",
          "warning"
        );
        return;
      }
      this.searchForm.page = 1;
      this.search();
    },
    // 新增
    addData() {
      this.$refs.addDia.form.machiningTypeName = this.machiningTypeName;
      this.$refs.addDia.form.machiningType = this.searchForm.machineType;
      this.$refs.addDia.visible = true;
    },
    confirmInfo(form) {
      AddOrUpdateTool(form, (response) => {
        if (response && response.code == 200) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.operateSuccessfully"),
            "",
            "success"
          );
          this.$refs.addDia.close();
          this.getList(this.searchForm.machineType);
        } else {
          commonUtil.showTipInfo(
            "Tool name is repeated. Please try again",
            "",
            "warning"
          );
        }
      });
    },
    // 编辑数据
    editData() {
      if (this.chooseData.length == 0) {
        commonUtil.showTipInfo(
          this.$t("userlists.userlist.selecWant"),
          "",
          "warning"
        );
        return;
      }
      if (this.chooseData.length > 1) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.onlyOne"),
          "",
          "warning"
        );
        return;
      }
      this.$refs.addDia.form = JSON.parse(JSON.stringify(this.chooseData[0]));
      this.$refs.addDia.form.machiningTypeName = this.machiningTypeName;
      this.$refs.addDia.visible = true;
    },
    // 编辑详情数据
    editDetail(row) {
      this.$router.push({
        path: "/ctDetailList",
        name: "ctDetailList",
        query: {
          type: "edit",
          id: row.id,
        },
      });
    },
    // 删除
    deleteData() {
      if (this.chooseData.length == 0) {
        commonUtil.showTipInfo(
          this.$t("userlists.userlist.selecWant"),
          "",
          "warning"
        );
        return;
      }
      let ids = [];
      this.chooseData.forEach(function (val, index) {
        ids.push(val.id);
      });
      DeleteTool(ids, (response) => {
        if (response && response.code == 200) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.deleteSuccessfully"),
            "",
            "success"
          );
          this.search();
        }
      });
    },
    // 表格多选
    handleSelectionChange(val) {
      this.chooseData = val;
    },
    Goback() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  padding-top: 20px;
  width: 100%;
  height: 100%;
  background: url("../../../assets/img/car-table-bg.png") no-repeat center;
  background-size: contain;
}
.main-top_handle {
  display: flex;
  justify-content: space-between;
  height: 40px;
}
.handle-btn {
  align-items: center;
  height: 40px;
  .handle-btn-item {
    padding: 0 20px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    background: #b2bde7;
    color: #fff;
    border-radius: 25px;
    cursor: pointer;
    margin-left: 10px;
  }
}

.main-handle {
  align-items: center;
}
.main-handle-item {
  margin-right: 10px;
  flex: 1;
  display: flex;
  align-items: center;
}
.main-handle input,
.main-handle .el-select .el-input {
  width: 100%;
  border-radius: 10px;
  font-size: 14px;
}
.main-handle input::placeholder {
  color: #3e4954;
  text-align: center;
  font-size: 14px;
}
// 日期选择器
.select-box {
  position: relative;
  .select-btn {
    width: 200px;
    height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 10px;
    background: #fff;
    cursor: pointer;
    .Calendar,
    .Switch {
      font-size: 18px;
      color: #2f4cdd;
    }
  }
}
.search-btn {
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 30px;
  background: #445581;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.crumbs-box {
  position: fixed;
  top: 70px;
  left: 220px;
  padding-left: 20px;
  width: 100%;
  display: flex;
  background-color: #f2f2f2;
  z-index: 999;
  align-items: center;
  height: 30px;
  font-size: 16px;
  color: #3e4954;
  .crumbs-box-item {
    display: flex;
    align-items: center;
  }
  .el-icon {
    margin: 0 10px;
  }
  .parent {
    color: #2f4cdd;
    cursor: pointer;
  }
  .line {
    margin: 0 15px;
    width: 2px;
    height: 15px;
    background: #3e4954;
  }
}
</style>