<template>
  <div class="main">
    <div class="main-top_handle">
      <div class="title">{{ $t("homePage.home.ProcessCalculator") }}</div>
      <div class="main-handle flex">
        <!-- 状态 -->
        <div class="main-handle-item">
          {{ $t("ccls.ccl.enables") }}
          <el-select
            size="small"
            clearable
            v-model="searchForm.Enable"
            :placeholder="$t('templateLists.templateList.enable')"
          >
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="$t(item.label)"
              :value="item.value"
            />
          </el-select>
        </div>
        <div class="main-handle-item" style="margin-top: 15px">
          <input
            type="text"
            v-model="searchForm.Keyword"
            :placeholder="$t('unifiedPrompts.unifiedPrompt.keyword')"
          />
        </div>
        <div class="search-btn" style="margin-top: 15px" @click="search">
          {{ $t("tcmlists.tcmlist.search") }}
        </div>
      </div>
    </div>

    <div class="handle-btn flex">
      <div
        v-if="BtnLimits.Add"
        class="handle-btn-item"
        style="margin-right: 10px"
        @click="addEntry"
      >
        {{ $t("unifiedPrompts.unifiedPrompt.new") }}
      </div>
      <div
        v-if="BtnLimits.Enable"
        class="handle-btn-item"
        style="margin-right: 10px"
        @click="bathOperate('1')"
      >
        {{ $t("templateLists.templateList.batchEnable") }}
      </div>
      <div
        v-if="BtnLimits.Disable"
        class="handle-btn-item"
        style="margin-right: 10px"
        @click="bathOperate('0')"
      >
        {{ $t("calculators.calculator.Bdisabling") }}
      </div>
      <div
        v-if="BtnLimits.Delete"
        class="handle-btn-item"
        style="margin-right: 10px"
        @click="bathOperate('delete')"
      >
        {{ $t("unifiedPrompts.unifiedPrompt.batchDelete") }}
      </div>
    </div>
    <div class="tcm-table">
      <el-row :gutter="10">
        <el-col
          :span="4"
          style="
            width: 250px;
            min-width: 250px;
            max-width: 250px;
            background-color: #fff;
          "
        >
          <div class="tree-cla">
            <div class="tree-cla-search">
              <input
                v-model="filterText"
                :placeholder="$t('unifiedPrompts.unifiedPrompt.keyword')"
              />
            </div>
            <div :style="{ height: TableHeight + 'px' }" class="tree-box">
              <el-tree
                :empty-text="$t('tcmlists.tcmlist.noData')"
                ref="treeRef"
                class="filter-tree custom-el-tree"
                :data="treedata"
                :props="defaultProps"
                default-expand-all
                :filter-node-method="filterNode"
                :highlight-current="true"
                @node-click="handleNodeClick"
              >
                <template #default="{ node, data }">
                  <div class="custom-tree-node">
                    <span class="my-text" :title="node.label">{{
                      node.label
                    }}</span>
                    <span class="my-badge">{{ data.Quantity }}</span>
                  </div>
                </template>
              </el-tree>
            </div>
          </div>
        </el-col>
        <el-col :span="20" style="max-width: calc(100% - 250px)">
          <div class="regional_table">
            <el-table
              border
              stripe
              :empty-text="$t('tcmlists.tcmlist.noData')"
              :data="tableData"
              :height="TableHeight"
              style="width: 100%"
              @row-click="RowClick"
              @selection-change="handleSelectionChange"
            >
              <el-table-column
                type="selection"
                width="55"
                align="center"
              ></el-table-column>

              <el-table-column
                prop="process_Calculator_Code"
                :label="$t('calculators.calculator.calculatorCode')"
                align="center"
              />
              <el-table-column
                prop="process_Calculator_Name"
                :label="$t('calculators.calculator.calculatorName')"
                align="center"
              />
              <!-- <el-table-column prop="version" :label="$t('calculators.calculator.version')" width="110" align="center"/> -->
              <el-table-column
                prop="commodityName"
                :label="$t('tcmlists.tcmlist.commodity')"
                width="110"
                show-overflow-tooltip
                align="center"
              />
              <el-table-column
                prop="comments"
                :label="$t('calculators.calculator.comments')"
                align="center"
              />
              <el-table-column
                prop="enable"
                :label="$t('calculators.calculator.enable')"
                width="80"
                align="center"
              >
                <template #default="scope">
                  <el-icon
                    :size="16"
                    color="#40D4A8"
                    v-show="scope.row.enable == '1'"
                  >
                    <SuccessFilled />
                  </el-icon>
                  <el-icon
                    :size="16"
                    color="#F56C6C"
                    v-show="scope.row.enable == '2'"
                  >
                    <CircleCloseFilled />
                  </el-icon>
                </template>
              </el-table-column>
              <el-table-column
                prop="status"
                width="80"
                fixed="right"
                class-name="lastTd"
                :label="$t('templateLists.templateList.operate')"
                align="center"
              >
                <template #default="scope">
                  <el-popover placement="bottom" trigger="hover">
                    <template #reference>
                      <div style="text-align: center">
                        <el-icon><MoreFilled /></el-icon>
                      </div>
                    </template>
                    <div class="action-box">
                      <div
                        v-if="BtnLimits.Edit"
                        class="action-btn"
                        @click="edit(scope.row.id)"
                      >
                        <el-icon><Edit /></el-icon>
                        <span>
                          {{ $t("unifiedPrompts.unifiedPrompt.edit") }}</span
                        >
                      </div>
                      <div
                        v-if="BtnLimits.Delete"
                        class="action-btn"
                        @click="deleteCla(scope.row.id)"
                      >
                        <el-icon><Delete /></el-icon>
                        <span>{{
                          $t("unifiedPrompts.unifiedPrompt.delete")
                        }}</span>
                      </div>
                      <div
                        v-if="BtnLimits.Detail"
                        class="action-btn"
                        @click="preview(scope.row.id)"
                      >
                        <el-icon><View /></el-icon>
                        <span>{{
                          $t("unifiedPrompts.unifiedPrompt.preview")
                        }}</span>
                      </div>
                    </div>
                  </el-popover>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="pagination">
            <div>
              {{ $t("homePage.home.Showing") }} {{ pageSize }}
              {{ $t("homePage.home.From") }} {{ total }}
              {{ $t("homePage.home.Data") }}
              <el-popover placement="top" :width="70" trigger="click">
                <template #reference>
                  <el-icon
                    style="
                      margin-left: 5px;
                      font-size: 17px;
                      vertical-align: middle;
                    "
                    ><Operation
                  /></el-icon>
                </template>
                <el-input
                  v-model="searchForm.limit"
                  @input="
                    (val) => (searchForm.limit = val.replace(/[^\d]/g, ''))
                  "
                  :min="1"
                  style="width: 130px"
                  @change="handleSizeChange"
                >
                  <template #suffix> {{ $t("ccls.ccl.barPage") }}</template>
                </el-input>
              </el-popover>
            </div>
            <el-pagination
              layout="prev, pager, next"
              background
              :total="total"
              :page-size="pageSize"
              :current-page="searchForm.page"
              @current-change="handleCurrentChange"
            />
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import {
  getCalculatorList,
  GetOrgs,
  DeleteCalculatorDetail,
  EnableByIds,
  DisableByIds,
} from "../../assets/js/requestApi.js";
import commonUtil from "../../assets/js/commonUtil.js";
export default {
  name: "ProcessCalculatorManagement",
  activated() {
    // 在首次挂载、
    // 以及每次从缓存中被重新插入的时候调用
    this.search();
    this.getUnits();
  },
  deactivated() {
    clearTimeout(this.timer);
    // 在从 DOM 上移除、进入缓存
    // 以及组件卸载时调用
  },
  data() {
    return {
      TableHeight: 0,
      timers: "",
      searchForm: {
        CommodityId: "",
        Enable: 0,
        Keyword: "",
        page: 1,
        limit: 10,
        key: "",
      },
      // 已选商品类别
      CommodityName: "",
      // status选项
      statusOptions: [
        {
          value: 0,
          label: "other.other.All",
        },
        {
          value: 1,
          label: "Enable",
          label: "other.other.Enable",
        },
        {
          value: 2,
          label: "Disable",
          label: "other.other.Disable",
        },
      ],
      filterText: "",
      treedata: [],
      defaultProps: {
        children: "children",
        label: "Name",
      },
      // 表格数据
      tableData: [],
      total: 0,
      pageSize: 10,
      // 勾选项
      chooseData: [],
      timer: "",
      BtnLimits: {},
    };
  },
  watch: {
    filterText(val) {
      this.$refs.treeRef.filter(val);
    },
  },
  mounted() {
    this.autoHeight();
    this.getlist();
    this.getTreeData();
  },
  methods: {
    RowClick(row) {
      if (this.BtnLimits.Edit) {
        this.edit(row.id);
      }
    },
    autoHeight() {
      // 初始化的时候，设置高度
      this.setHeight();
      // 改变浏览器窗口大小的时候，设置高度
      window.addEventListener("resize", () => {
        this.setHeight();
      });
    },
    setHeight() {
      this.$nextTick(() => {
        this.TableHeight = window.innerHeight - 235; // 这里的X就是顶部栏的高度
      });
    },

    getUnits() {
      this.timer = setInterval(this.gettime, 1000);
    },
    gettime() {
      this.BtnLimits = JSON.parse(sessionStorage.getItem("Btn"));
    },
    getlist() {
      this.searchForm = {
        CommodityId: "",
        Enable: 0,
        Keyword: "",
        page: 1,
        limit: 10,
        key: "",
      };
      this.search();
    },
    // 查询
    search() {
      getCalculatorList(this.searchForm, (response) => {
        if (response && response.code == 200) {
          this.tableData = response.data;
          this.total = response.count;
        } else {
          commonUtil.showTipInfo(this.$t("userlists.userlist.inqueryTimeout"));
        }
      });
    },
    // 分页
    handleCurrentChange(val) {
      this.searchForm.page = val;
      this.search();
    },
    // 分页数量修改
    handleSizeChange() {
      console.log(this.searchForm.limit);
      this.pageSize = parseInt(this.searchForm.limit);
      if (this.searchForm.limit === "") {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.setPageNum"),
          "",
          "warning"
        );

        return;
      }
      if (this.searchForm.limit == 0) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.pageThanZero"),
          "",
          "warning"
        );

        return;
      }
      this.searchForm.page = 1;
      this.search();
    },
    // 新增
    addEntry() {
      // if (this.searchForm.CommodityId == '') {
      // 	commonUtil.showTipInfo('请选择商品类别！', '', 'warning')
      // 	return;
      // }
      this.$router.push({
        path: "/addCalculator",
        name: "AddCalculator",
        query: {
          type: "add",
          CommodityId: this.searchForm.CommodityId,
          CommodityName: this.CommodityName,
        },
      });
    },
    // 获取组织树数据
    getTreeData() {
      let _this = this;
      GetOrgs(
        {
          type: 0,
          versionType: 0,
          TypeCost: 0,
          isAll: 0,
        },
        (response) => {
          if (response && response.Code == 200) {
            this.treedata = _this.transferList(response.Result);
          }
          console.log(response);
        }
      );
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.Name.indexOf(value) !== -1;
    },
    // 树点击事件
    handleNodeClick(data) {
      console.log(data);
      this.searchForm.CommodityId = data.Id;
      this.CommodityName = data.Name;
      this.search();
    },
    // 转化树数据
    transferList(data) {
      const res = [];
      data.forEach((item) => {
        const parent = data.find((node) => node.Id === item.ParentId);
        if (parent) {
          parent.children = parent.children || [];
          parent.children.push(item);
        } else {
          // * 根节点
          res.push(item);
        }
      });
      return res;
    },
    // 查看详情
    preview(val) {
      this.$router.push({
        path: "/addCalculator",
        name: "AddCalculator",
        query: {
          type: "detail",
          id: val,
        },
      });
    },
    // 删除
    deleteCla(id) {
      let data = [];
      data.push(id);
      DeleteCalculatorDetail(data, (response) => {
        if (response && response.code == 200) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.deleteSuccessfully"),
            "",
            "success"
          );
          this.search();
        }
      });
    },
    // 编辑
    edit(id) {
      this.$router.push({
        path: "/addCalculator",
        name: "AddCalculator",
        query: {
          type: "edit",
          id: id,
        },
      });
    },
    // 表格多选
    handleSelectionChange(val) {
      console.log(val);
      this.chooseData = val;
    },
    // 批量操作
    bathOperate(type) {
      if (this.chooseData.length == 0) {
        commonUtil.showTipInfo(
          this.$t("calculators.calculator.selectManipulate"),
          "",
          "warning"
        );
        return;
      }
      let ids = [];
      this.chooseData.forEach(function (val, key) {
        ids.push(val.id);
      });
      if (type == "1") {
        // 启用
        EnableByIds(ids, (response) => {
          if (response && response.code == 200) {
            commonUtil.showTipInfo(
              this.$t("ccls.ccl.enableSuccess"),
              "",
              "success"
            );
            this.search();
          }
        });
      } else if (type == "0") {
        // 禁用
        DisableByIds(ids, (response) => {
          if (response && response.code == 200) {
            commonUtil.showTipInfo(
              this.$t("ccls.ccl.disableSuccess"),
              "",
              "success"
            );
            this.search();
          }
        });
      } else if (type == "delete") {
        // 批量删除
        DeleteCalculatorDetail(ids, (response) => {
          if (response && response.code == 200) {
            commonUtil.showTipInfo(
              this.$t("unifiedPrompts.unifiedPrompt.deleteSuccessfully"),
              "",
              "success"
            );
            this.search();
          }
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  width: 100%;
  height: calc(100%);
  background: url("../../assets/img/car-table-bg.png") no-repeat center;
  background-size: contain;
}

.main-top_handle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
}
.tree-cla {
  background: #fff;
  .tree-cla-search {
    padding: 10px;
  }
  input {
    display: block;
    width: calc(100% - 10px);
    height: 40px;
    border-radius: 10px;
    background: #f5f7ff;
  }
}
.main-handle {
  align-items: center;
}
.main-handle-item {
  margin-right: 10px;
  flex: 1;
}
.main-handle input,
.main-handle .el-select .el-input {
  width: 100%;
  border-radius: 10px;
  font-size: 14px;
}
.main-handle input::placeholder {
  color: #3e4954;
  text-align: center;
  font-size: 14px;
}

.handle-btn {
  align-items: center;
  height: 40px;
  .handle-btn-item {
    padding: 0 20px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    background: #b2bde7;
    color: #fff;
    border-radius: 25px;
    cursor: pointer;
    margin-left: 10px;
  }
}
// 日期选择器
.select-box {
  position: relative;
  .select-btn {
    width: 200px;
    height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 10px;
    background: #fff;
    cursor: pointer;
    .Calendar,
    .Switch {
      font-size: 18px;
      color: #2f4cdd;
    }
  }
}
.search-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 30px;
  background: #445581;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
.tree-box {
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>