const info = {
    // 中文
    // {{$t('unifiedPrompts.unifiedPrompt.preview')}}
    // {{$t('unifiedPrompts.unifiedPrompt.bRawMateria')}}
    // this.$t('unifiedPrompts.unifiedPrompt.startDate')
    // :start-placeholder="$t('unifiedPrompts.unifiedPrompt.startDate')"
    // :end-placeholder="$t('unifiedPrompts.unifiedPrompt.endDate')"
    ZH: {
        acquireFail: '获取失败',
        loadFail: '加载失败',
        operateFail: '操作失败',
        editFail: '编辑失败',
        addFail: '添加失败',
        deleteFail: '删除失败',
        importFail: '导入失败', //1
        exportFail: '导出失败',
        uploadFail: '上传失败',
        downloadFail: '下载失败',
        theSystem: '第1行,Commodity 报错:MessageCommodity字段在系统中不存在，请重新输入',
        theMessageInput: '第1行,Report Currency 报错:MessageInput字符串格式不正确。',
        acquireSuccess: '获取成功',
        loadedSuccessfully: '加载成功',
        operateSuccessfully: '操作成功', //1
        editSuccess: '编辑成功', //1
        addedSuccess: '新增成功', //1
        deleteSuccessfully: '删除成功', //1
        importSuccessfully: '导入成功', //1
        exportSuccessfully: '导出成功',
        uploadSuccessfully: '上传成功',
        downloadSuccessfully: '下载成功',
        currentAgain: '当前数据无汇率，请重新选择！',
        inqueryTimeout: '查询失败,请求超时', //1
        new: '新增',
        copy: '复制',
        batchDelete: '批量删除',
        edit: '编辑',
        selectTCM: '选择TCM',
        selectccl: '选择CCL',
        selectpcba: '选择PCBA',
        selectCBD: '选择CBD',
        selectCompleteCBD: '选择已完成的CBD',
        delete: '删除',
        preview: '预览',
        menuList: 'menuLists',
        keyword: '关键字',
        startDate: '开始日期',
        endDate: '结束日期',
        import: '导入',
        export: '导出',
        hideTheSame: '隐藏同类项',
        forexInformation: '外汇信息',
        bRawMateria: '原材料',
        cProcessing: '采购部件和外部加工',
        dProcessing: '工艺',
        eCustoms: '包装、物流、摊销、关税和海关',
        fPRICE: 'SGA和利润，售价',
        gCost: '成本汇总',
        onlyOne: '仅可对一条数据进行操作! ',
        noPermission: '您没有对该条数据进行操作的权限！',
        noRevokePermission: '您没有对该条数据进行撤回的权限！',
        noApprovePermission: '您没有对该条数据进行审批的权限！',
        underApprove: '该条数据进入审批状态无法操作！',
        noStartToRevoke: '该条数据还未操作，无法撤回！',
        noStartToApprove: '该条数据操还未操作，无法审批！',
        isApprovedNotToRevoke: '该条数据进入审批状态无法撤回！',
        isApprovedNotToOperate: '该条数据已审批通过，无法操作！',
        chooseEmail: '请选择邮箱！',
        operate: '操作',
        revoke: '返工',
        reject: '申请退回',
        approve: '审批',
        reworkApplication: '退回',
        downTemplate: '下载模板',
        operatedByother: '该条数据已被另一操作人处理，不可操作！',
        inProcessTonotStart: '只有进行中状态的数据可退回！',
        approvedTobe: '只有审批通过的数据可以操作！',
        delRootInfo: '根节点不可删除！',
        hasUntaxedToFill: '存在无税率的数据，请填写',
        notChangePrice: '已改价后不可再次改价！',
        isFinishedNotOperate: '已操作完成的数据不可再次进行操作！',
        rateThanZero: '税率需大于0！',
        duplicateImport: '为重复数据，不可导入！',
        dupImportQue: '数据重复，是否继续导入？',
        compareNotRepeat: '对比数据不可重复添加！',
        compareFive: '最多只可比较五条数据！',
        setPageNum: '请设置每页数据展示数量！',
        pageThanZero: '每页数据需大于0！',
        bar: '柱状图',
        pie: '饼图',
        remarkNotEmpty: '请填写备注信息！',
        pleaseMenuName: '请输入菜单名称',
        pleaseMoudlePath: '请填写路径',
        pleaseMoudleCode: '请填写模块标识',
        rejectApplication: '拒绝',
        DownCBDTemplate: 'CBD模版下载',
        ImportList: '导入列表',
        operationByFinished: '只能对已结束的状态操作',
    },
    EN: {
        acquireFail: 'acquire fail',
        loadFail: 'load fail',
        operateFail: 'operate fail',
        editFail: 'Edit fail',
        addFail: 'add fail',
        deleteFail: 'delete fail',
        importFail: 'Import fail',
        exportFail: 'Export fail',
        uploadFail: 'upload fail',
        downloadFail: 'Download fail',
        theSystem: 'The 1st line-error Commodity, Message Commodity field does not exist in the system',
        theMessageInput: 'The 1st line-error Report Currency，,MessageInput string format is not correct',
        acquireSuccess: 'acquire success',
        loadedSuccessfully: 'Loaded successfully',
        operateSuccessfully: 'Operate successfully',
        editSuccess: 'Edit success',
        addedSuccess: 'Added succes sfully',
        deleteSuccessfully: 'delete successfully',
        importSuccessfully: 'Import successfully',
        exportSuccessfully: 'Export successfully',
        uploadSuccessfully: 'upload successfully',
        downloadSuccessfully: 'download successfully',
        currentAgain: 'Current data no exchange rate, please choose again!',
        inqueryTimeout: 'Inquery failed, request timeout',
        new: 'New',
        copy: 'Copy',
        batchDelete: 'Batch Delete',
        edit: 'Edit',
        selectTCM: 'Select TCM',
        selectccl: 'Select CCL',
        selectpcba: 'Select PCBA',
        selectCBD: 'Select CBD',
        selectCompleteCBD: 'Select completed CBD',
        delete: 'Delete',
        preview: 'Preview',
        menuList: 'menuList',
        keyword: 'Keyword',
        startDate: 'Start date',
        endDate: 'End date',
        import: 'Import',
        export: 'Export',
        hideTheSame: 'Hide The Same Item',
        forexInformation: 'Forex Information',
        bRawMateria: 'B) Raw Material',
        cProcessing: 'C) Purchased Components & Outside Processing',
        dProcessing: ' D) Processing',
        eCustoms: 'E) Packaging, Logistics, Amortization, Duties & Customs',
        fPRICE: 'F) SGA & Profit, SELL PRICE',
        gCost: ' G) COST SUMMARY',
        onlyOne: 'You can operate only one piece of data! ',
        noPermission: 'You do not have permission to operate on this data!',
        noRevokePermission: 'You do not have permission to revoke on this data!',
        noApprovePermission: 'You do not have permission to revoke on this data!',
        underApprove: 'The data entered the approval state cannot be operated!',
        noStartToRevoke: 'This piece of data has not been operated, cannot be withdrawn!',
        noStartToApprove: 'This data operation has not been operated, cannot be approved!',
        isApprovedNotToRevoke: 'The data entered the approval state and cannot be withdrawn!',
        isApprovedNotToOperate: 'This data has been approved and cannot be operated!',
        chooseEmail: 'Please select email!',
        operate: 'Operate',
        revoke: 'Rework',
        reject: 'Reject',
        approve: 'Approve',
        reworkApplication: 'Rework',
        downTemplate: 'Standard Format',
        operatedByother: 'This data has been processed by another operator and cannot be operated!',
        inProcessTonotStart: 'Only data in process can be rejected!',
        approvedTobe: 'Only approved data can be operated!',
        delRootInfo: 'The root node cannot be deleted!',
        hasUntaxedToFill: 'There is no tax rate data, please fill in',
        notChangePrice: 'You cannot change the price again after you have changed the price!',
        isFinishedNotOperate: 'Data that has been operated cannot be operated again!',
        rateThanZero: 'The tax rate has to be greater than zero!',
        duplicateImport: ' is duplicate data and cannot be imported!',
        dupImportQue: ' is duplicate data, do you want to continue importing?',
        compareNotRepeat: 'Comparison data cannot be added repeatedly!',
        compareFive: 'You can only compare five numbers at most!',
        setPageNum: 'Please set the number of data displays per page!',
        pageThanZero: 'Data per page must be greater than 0!',
        bar: 'Bar',
        pie: 'Pie',
        remarkNotEmpty: 'Please fill in the remarks information!',
        pleaseMenuName: 'Please enter the menu name',
        pleaseMoudlePath: 'Please fill in the path',
        pleaseMoudleCode: 'Please fill in the module ID',
        rejectApplication: 'Reject',
        DownCBDTemplate: 'CBD Template Download',
        ImportList: 'Import List',
        operationByFinished: 'This operation can be performed only on the finished state',
    }
}
export default info