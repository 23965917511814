<template>
  <el-dialog v-model="visible" width="70%" :show-close="false" @close="close">
    <template #header="{ close, titleId, titleClass }">
      <div class="my-header">
        <div class="size-16">
          {{
            form.id === ""
              ? $t("unifiedPrompts.unifiedPrompt.new")
              : $t("unifiedPrompts.unifiedPrompt.edit")
          }}
        </div>
        <div @click="close">
          <el-icon :size="20"><Close /></el-icon>
        </div>
      </div>
    </template>
    <div class="add-cla-top">
      <el-row>
        <el-col :span="24">
          <el-form :inline="true" :model="form" label-width="150px">
            <el-row>
              <el-col :span="8">
                <el-form-item label="eBOM PN：" class="custom-el-form-rules">
                  <el-input
                    v-model="form.eBOMPN"
                    placeholder=""
                    style="width: 214px"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('ccls.ccl.name')">
                  <el-input
                    v-model="form.name"
                    placeholder=""
                    style="width: 214px"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('ccls.ccl.desc')">
                  <el-input
                    v-model="form.description"
                    placeholder=""
                    style="width: 214px"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item :label="$t('ccls.ccl.revision')">
                  <el-input
                    v-model="form.version"
                    placeholder=""
                    style="width: 214px"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('ccls.ccl.project')">
                  <el-input
                    v-model="form.project"
                    placeholder=""
                    style="width: 214px"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('ccls.ccl.annualVolumn')">
                  <el-input
                    v-model="form.annualVolume"
                    placeholder=""
                    style="width: 214px"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item
                  :label="$t('changePriceLists.changePriceList.region')"
                >
                  <el-select
                    clearable
                    filterable
                    v-model="form.regionId"
                    placeholder=""
                    style="width: 214px"
                  >
                    <el-option
                      v-for="item in regionOptions"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8"></el-col>
              <el-col :span="8"></el-col>
            </el-row>
          </el-form>
        </el-col>
      </el-row>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close">{{
          $t("changePriceLists.changePriceList.cancle")
        }}</el-button>
        <el-button
          style="
            background-color: var(--themeColor);
            color: var(--menuTextActive);
            margin-right: 16px;
          "
          @click="confirm"
          >{{ $t("ccls.ccl.confirm") }}</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { getRegions } from "../../../assets/js/requestApi.js";
import commonUtil from "../../../assets/js/commonUtil.js";
export default {
  emits: ["confirmInfo"],
  data() {
    return {
      visible: false,
      form: {
        id: "",
        eBOMPN: "",
        description: "",
        comment: "",
        extCurrencyId: "",
        extTotalPrice: "",
        extCurrencyId2: "",
        extTotalPrice2: "",
        version: "",
        project: "",
        regionId: "",
        annualVolume: "",
        year: "",
        priceType: "",
        name: "",
      },
      regionOptions: [],
    };
  },
  mounted() {
    // this.getRegion();
    this.regionOptions = JSON.parse(sessionStorage.getItem("Region"));
  },
  methods: {
    close() {
      this.visible = false;
      this.form = {
        id: "",
        eBOMPN: "",
        description: "",
        comment: "",
        extCurrencyId: "",
        extTotalPrice: "",
        extCurrencyId2: "",
        extTotalPrice2: "",
        version: "",
        project: "",
        regionId: "",
        annualVolume: "",
        year: "",
        priceType: "",
        name: "",
      };
    },
    // 获取区域列表选项
    getRegion() {
      getRegions(
        {
          Keyword: "",
          isEnable: 1,
          page: 1,
          limit: 9999,
          key: "",
        },
        (response) => {
          if (response && response.code == 200) {
            this.regionOptions = response.data;
          }
        }
      );
    },
    confirm() {
      if (this.form.eBOMPN == "") {
        commonUtil.showTipInfo("Please enter eBOM PN", "", "warning");
        return;
      }
      this.$emit("confirmInfo", this.form);
      // this.close()
    },
  },
};
</script>

<style scoped>
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.size-16 {
  font-size: 16px;
}
.padding-9 {
  padding: 9px;
}
.el-row {
  margin-bottom: 10px;
}
.color-red {
  color: red;
}
.align-right {
  text-align: right;
}
</style>