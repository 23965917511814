<template>
  <div style="padding: 15px" class="box-main">
    <div class="Total-MaterialCost" v-show="type != 'template'">
      <div class="MaterialCost">
        <p>{{ $t("changePriceLists.changePriceList.total") }}</p>
        <input v-model="laborCost_PRO" disabled type="text" />
      </div>
      <div class="MaterialCost">
        <p>{{ $t("changePriceLists.changePriceList.totalSetCost") }}</p>
        <input v-model="setUpCost_PRO" disabled type="text" />
      </div>
      <div class="MaterialCost">
        <p>{{ $t("changePriceLists.changePriceList.totalBurden") }}</p>
        <input v-model="burdenCost_PRO" disabled type="text" />
      </div>
      <div class="MaterialCost">
        <p>{{ $t("changePriceLists.changePriceList.totalManufacturing") }}</p>
        <input v-model="manufacturingCost_PRO" disabled type="text" />
      </div>
    </div>
    <div class="Raw-box">
      <div class="Raw-card" v-for="(item, index) in formData" :key="index">
        <div class="Raw-top">
          <div class="Raw-title">{{ $t("homePage.home.PRO") }} <br /></div>

          <div class="Raw-handle">
            <!-- <el-icon><MoreFilled /></el-icon> -->
            <div class="flex" v-show="type != 'template'">
              <img
                src="../../assets/img/tcm/zuojiantou.png"
                style="width: 20px; height: 20px"
                @click="reorder('left', index)"
              />
              <img
                src="../../assets/img/tcm/youjiantou.png"
                style="width: 20px; height: 20px"
                @click="reorder('right', index)"
              />
            </div>
          </div>
        </div>
        <div class="Raw-card-main">
          <!-- <div class="Raw-edit-btn">
            <el-icon><EditPen /></el-icon>
          </div> -->

          <!-- <div class="form-type-title">
            {{ $t("changePriceLists.changePriceList.description") }}
            <img
              src="../../assets/img/tcm/xiajiantoushixinxiao.png"
              class="form-type-title-img"
              v-show="item.descShow"
              @click="hideCell(index, 'hide', 'desc')"
            />
            <img
              src="../../assets/img/tcm/shangjiantoushixinxiao.png"
              class="form-type-title-img"
              v-show="!item.descShow"
              @click="hideCell(index, 'show', 'desc')"
            />
          </div> -->

          <div v-show="type == 'template'">
            <div class="Raw-card-item" v-show="operateType != 'add'">
              <p>{{ $t("templateLists.templateList.cellNo") }}</p>
              <el-input
                v-model="item.number"
                style="width: calc(100% - 20px)"
                disabled
              />
            </div>
            <!-- cellName -->
            <div class="Raw-card-item">
              <p>
                {{ $t("templateLists.templateList.cellName") }}
              </p>
              <el-input
                v-model="item.name"
                style="width: calc(100% - 20px)"
                :disabled="operateType == 'detail'"
              />
            </div>
            <!-- commodity -->
            <div class="Raw-card-item">
              <p>{{ $t("tcmlists.tcmlist.commodity") }}</p>
              <el-tree-select
                clearable
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                v-model="item.commodityId"
                append-to-body
                @change="changeCommodityId($event, index)"
                style="width: calc(100% - 20px)"
                :disabled="operateType == 'detail'"
                :data="CommodityOptions"
                :props="defaultProps"
                check-strictly
                :render-after-expand="false"
                :default-expand-all="true"
              />
            </div>
            <!-- cellNo -->
            <div>
              <div class="Raw-card-item">
                <p>{{ $t("templateLists.templateList.region") }}</p>
                <el-select
                  clearable
                  :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                  v-model="item.regionId"
                  placeholder=""
                  style="width: calc(100% - 20px)"
                  :disabled="operateType == 'detail'"
                >
                  <el-option
                    v-for="item in regionOptions"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </div>
              <div class="Raw-card-item" v-show="operateType != 'add'">
                <p>{{ $t("templateLists.templateList.version") }}</p>
                <el-input
                  v-model="item.version"
                  style="width: calc(100% - 20px)"
                  disabled
                />
              </div>
              <div class="Raw-card-item">
                <p>{{ $t("templateLists.templateList.comments") }}</p>
                <el-input
                  v-model="item.comment"
                  style="width: calc(100% - 20px)"
                  :disabled="operateType == 'detail'"
                />
              </div>
            </div>
          </div>
          <!-- ------------------------------------- -->
          <div class="form-type-title">
            {{ $t("changePriceLists.changePriceList.setUp") }}
            {{ index + 1 }}
          </div>
          <!-- todo todo -->
          <div>
            <div>
              <div class="Raw-card-item">
                <p>
                  {{ $t("changePriceLists.changePriceList.annualVolume") }}
                </p>
                <el-input
                  v-model="item.annualVolume"
                  style="width: calc(100% - 20px)"
                  :disabled="operateType == 'detail' || type != 'alloperate'"
                />
              </div>
              <div class="Raw-card-item">
                <p>
                  {{ $t("changePriceLists.changePriceList.setUpTime") }}
                </p>
                <el-input
                  v-model="item.setTime"
                  style="width: calc(100% - 20px)"
                  :disabled="operateType == 'detail'"
                />
              </div>
              <div class="Raw-card-item">
                <p>
                  {{ $t("changePriceLists.changePriceList.setUpPersons") }}
                </p>
                <el-input
                  v-model="item.setPerson"
                  style="width: calc(100% - 20px)"
                  type="number"
                  :disabled="operateType == 'detail'"
                />
              </div>
              <div class="Raw-card-item">
                <p>
                  {{ $t("changePriceLists.changePriceList.piecesPerLot") }}
                </p>
                <div class="flex">
                  <el-input
                    v-model="item.pieceLot"
                    style="width: calc(100% - 20px)"
                    type="number"
                    :disabled="operateType == 'detail' || type != 'alloperate'"
                  />
                </div>
              </div>
              <!-- unitMeasure -->
              <div class="Raw-card-item">
                <p>
                  {{ $t("changePriceLists.changePriceList.setUpCosts") }}
                </p>
                <div class="flex">
                  <el-input
                    v-model="item.pieceCost"
                    style="width: calc(100% - 20px)"
                    disabled
                  />
                </div>
              </div>
            </div>

            <div class="form-type-title">
              {{ $t("changePriceLists.changePriceList.operation") }} #
              {{ index + 1 }}
            </div>

            <div>
              <!-- - -->
              <!-- subTotalCost -->
              <div class="Raw-card-item">
                <p>
                  {{
                    $t("changePriceLists.changePriceList.operationDescription")
                  }}
                </p>
                <el-input
                  v-show="type == 'template'"
                  v-model="item.processName"
                  style="width: calc(100% - 20px)"
                  :disabled="operateType == 'detail'"
                />
                <el-input
                  v-show="type != 'template'"
                  v-model="item.name"
                  style="width: calc(100% - 20px)"
                  :disabled="operateType == 'detail'"
                />
              </div>
              <!-- <div class="Raw-card-item">
                <p>
                  {{ $t("changePriceLists.changePriceList.vendor") }}
                </p>
                <div class="flex" style="align-items: center">
                  <el-input
                    v-model="item.vendor"
                    style="width: calc(100% - 20px)"
                    :disabled="operateType == 'detail'"
                  />
                </div>
              </div> -->

              <div class="Raw-card-item">
                <p>
                  {{ $t("changePriceLists.changePriceList.descriptionSize") }}
                </p>
                <div class="flex" style="align-items: center">
                  <el-input
                    v-model="item.equSize"
                    style="width: calc(100% - 20px)"
                    :disabled="operateType == 'detail'"
                  />
                  <img
                    src="../../assets/img/tcm/daikuanjisuanqi.png"
                    v-show="type == 'alloperate' && operateType != 'detail'"
                    style="width: 25px; height: 25px; cursor: pointer"
                    @click="openCal(index, 'equSize')"
                  />
                </div>
              </div>

              <div class="Raw-card-item">
                <p>
                  {{ $t("changePriceLists.changePriceList.consumption") }}
                </p>
                <div class="flex" style="align-items: center">
                  <el-input
                    v-model="item.quantity"
                    style="width: calc(100% - 20px)"
                    type="number"
                    :disabled="operateType == 'detail' || type != 'alloperate'"
                  />
                </div>
              </div>

              <div class="Raw-card-item">
                <p>
                  {{ $t("changePriceLists.changePriceList.piecesHour") }}
                </p>
                <div class="flex" style="align-items: center">
                  <el-input
                    v-model="item.pieceHour"
                    style="width: calc(100% - 20px)"
                    :disabled="operateType == 'detail'"
                  />
                  <img
                    src="../../assets/img/tcm/daikuanjisuanqi.png"
                    v-show="type == 'alloperate' && operateType != 'detail'"
                    style="width: 25px; height: 25px; cursor: pointer"
                    @click="openCal(index, 'pieceHour')"
                  />
                </div>
              </div>

              <div class="Raw-card-item">
                <p>
                  {{ $t("changePriceLists.changePriceList.piecesPer") }}
                </p>
                <div class="flex" style="align-items: center">
                  <el-input
                    v-model="item.pieceCycle"
                    style="width: calc(100% - 20px)"
                    :disabled="operateType == 'detail'"
                  />
                </div>
              </div>

              <div class="Raw-card-item">
                <p>
                  {{
                    $t(
                      "changePriceLists.changePriceList.manufacturingEfficiency"
                    )
                  }}
                </p>
                <div class="flex" style="align-items: center">
                  <el-input
                    v-model="item.efficiencyPercent"
                    style="width: calc(100% - 20px)"
                    :disabled="operateType == 'detail'"
                  >
                    <template #suffix>
                      <el-icon class="el-input__icon">%</el-icon>
                    </template>
                  </el-input>
                </div>
              </div>

              <div class="Raw-card-item">
                <p>
                  {{ $t("changePriceLists.changePriceList.standardHour") }}
                </p>
                <div class="flex" style="align-items: center">
                  <el-input
                    v-model="item.standardHour"
                    style="width: calc(100% - 20px)"
                    disabled
                  />
                </div>
              </div>

              <div class="Raw-card-item">
                <p>
                  {{ $t("changePriceLists.changePriceList.directRate") }}
                </p>
                <div class="flex" style="align-items: center">
                  <el-input
                    v-model="item.directRate"
                    style="width: calc(100% - 20px)"
                    :disabled="operateType == 'detail'"
                  />
                </div>
              </div>

              <div class="Raw-card-item">
                <p>
                  {{ $t("changePriceLists.changePriceList.setUpRate") }}
                </p>
                <div class="flex" style="align-items: center">
                  <el-input
                    v-model="item.setUpRate"
                    style="width: calc(100% - 20px)"
                    :disabled="operateType == 'detail'"
                  />
                </div>
              </div>

              <div class="Raw-card-item">
                <p>
                  {{ $t("changePriceLists.changePriceList.directOperators") }}
                </p>
                <div class="flex" style="align-items: center">
                  <el-input
                    v-model="item.directOperateQty"
                    style="width: calc(100% - 20px)"
                    :disabled="operateType == 'detail'"
                  />
                </div>
              </div>

              <div class="Raw-card-item">
                <p>
                  {{ $t("changePriceLists.changePriceList.directAssembly") }}
                </p>
                <div class="flex" style="align-items: center">
                  <el-input
                    v-model="item.assemblyDirectCost"
                    style="width: calc(100% - 20px)"
                    disabled
                  />
                </div>
              </div>

              <div class="Raw-card-item">
                <p>
                  {{ $t("changePriceLists.changePriceList.burdenVariable") }}
                </p>
                <div class="flex" style="align-items: center">
                  <el-input
                    v-model="item.variableRate"
                    style="width: calc(100% - 20px)"
                    :disabled="operateType == 'detail'"
                  />
                </div>
              </div>

              <div class="Raw-card-item">
                <p>
                  {{ $t("changePriceLists.changePriceList.burdenRate") }}
                </p>
                <div class="flex" style="align-items: center">
                  <el-input
                    v-model="item.fixedRate"
                    style="width: calc(100% - 20px)"
                    :disabled="operateType == 'detail'"
                  />
                </div>
              </div>

              <div class="Raw-card-item">
                <p>
                  {{
                    $t("changePriceLists.changePriceList.burdenDepreciation")
                  }}
                </p>
                <div class="flex" style="align-items: center">
                  <el-input
                    v-model="item.depreciationRate"
                    style="width: calc(100% - 20px)"
                    :disabled="operateType == 'detail'"
                  />
                </div>
              </div>

              <div class="Raw-card-item">
                <p>
                  {{ $t("changePriceLists.changePriceList.totalBurdenRate") }}
                </p>
                <div class="flex" style="align-items: center">
                  <el-input
                    v-model="item.totalBurdenRate"
                    style="width: calc(100% - 20px)"
                    disabled
                  />
                </div>
              </div>

              <div class="Raw-card-item">
                <p>
                  {{ $t("changePriceLists.changePriceList.burdenAssembly") }}
                </p>
                <div class="flex" style="align-items: center">
                  <el-input
                    v-model="item.assemblyBurdenCost"
                    style="width: calc(100% - 20px)"
                    disabled
                  />
                </div>
              </div>

              <div class="Raw-card-item">
                <p>
                  {{ $t("changePriceLists.changePriceList.subTotalCost") }}
                </p>
                <div class="flex" style="align-items: center">
                  <el-input
                    v-model="item.totalCost"
                    style="width: calc(100% - 20px)"
                    disabled
                  />
                </div>
              </div>

              <div class="Raw-card-item">
                <p>
                  {{ $t("changePriceLists.changePriceList.processScrap") }}
                </p>
                <div class="flex" style="align-items: center">
                  <el-input
                    v-model="item.scrapPercent"
                    style="width: calc(100% - 20px)"
                    :disabled="operateType == 'detail'"
                  >
                    <template #suffix>
                      <el-icon class="el-input__icon">%</el-icon>
                    </template>
                  </el-input>
                </div>
              </div>
            </div>

            <div v-show="type == 'template' && operateType != 'detail'">
              <div class="flex" style="margin: 10px 0">
                <div class="submit-btn" @click="cancle">
                  {{ $t("userlists.userlist.cancel") }}
                </div>
                <div class="submit-btn" @click="confirm">
                  {{ $t("userlists.userlist.confirm") }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="Raw-top" style="padding: 10px 0px">
          <div
            class="Raw-title"
            v-show="type != 'template' && operateType != 'detail'"
            @click="addEmptyMoudle"
          >
            <!-- <el-icon><CirclePlus /></el-icon>
            <span style="padding-left: 10px">{{
              $t("changePriceLists.changePriceList.fromTemplate")
            }}</span> -->
          </div>
          <div
            class="Raw-handle"
            style="margin-right: -20px"
            v-show="type != 'template' && operateType != 'detail'"
          >
            <el-icon @click="copyMoudle(index)"><CopyDocument /></el-icon>
            <el-icon @click="deleteMoudle(index)" style="margin-left: 5px"
              ><DeleteFilled
            /></el-icon>
          </div>
        </div>
      </div>
      <div
        class="Raw-card"
        v-show="type != 'template' && operateType != 'detail'"
      >
        <div class="Raw-top">
          <div class="Raw-title">processing <br /></div>
        </div>
        <div class="tcm-form-solid">
          <div @click="addMoudle" class="add-cla-template">
            <el-icon :size="35" color="#8c8c8c"><Plus /></el-icon>
            <div>{{ $t("changePriceLists.changePriceList.fromTemplate") }}</div>
          </div>
        </div>
        <div class="Raw-top" style="padding: 10px 0px">
          <div
            class="Raw-title"
            v-show="type != 'template'"
            @click="addEmptyMoudle"
          >
            <el-icon><CirclePlus /></el-icon>
            <span style="padding-left: 10px">
              {{ $t("changePriceLists.changePriceList.materialInput") }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="bottom-box">
      <div class="handle-btn">Save</div>
    </div> -->
    <CalculatorList ref="calList" @chooseRow="chooseRow"></CalculatorList>
    <PreviewDia
      ref="preDia"
      type="template"
      :formulaPre="outform.formulaPre"
      :previewResultx="previewResultx"
      :fileListUrl="fileListUrl"
      :intermediateBondList="intermediateBondList"
      :valueList="valueList"
      @tocompute="tocompute"
      @toConfirm="toConfirm"
    ></PreviewDia>
    <ProDia
      ref="proDialog"
      :CommodityId="CommodityId"
      @getChooseProId="getChooseProId"
    ></ProDia>
  </div>

  <!-- <div class="tcm-content">
		<div class="tcm-content-title">
			<div class="dis-flex">
				{{type == 'template' ? $t('changePriceLists.changePriceList.newCell')  : ($t('changePriceLists.changePriceList.processing'))}}

			</div>
			<div class="total-cla"  v-show="type != 'template'">
				<el-row>
					<el-col :span="6">{{$t('changePriceLists.changePriceList.total')}}</el-col>
					<el-col :span="6"><el-input v-model="laborCost_PRO" disabled style="width: calc(100% - 20px);" /></el-col>
					<el-col :span="6">{{$t('changePriceLists.changePriceList.totalSetCost')}}</el-col>
					<el-col :span="6"><el-input v-model="setUpCost_PRO" disabled style="width: calc(100% - 20px);" /></el-col>
				</el-row>
				<el-row>
					<el-col :span="6">{{$t('changePriceLists.changePriceList.totalBurden')}}</el-col>
					<el-col :span="6"><el-input v-model="burdenCost_PRO" disabled style="width: calc(100% - 20px);" /></el-col>
					<el-col :span="6">{{$t('changePriceLists.changePriceList.totalManufacturing')}}</el-col>
					<el-col :span="6"><el-input v-model="manufacturingCost_PRO" disabled style="width: calc(100% - 20px);" /></el-col>
				</el-row>
			</div>
		</div>
		<div class="dis-flex tcm-form">
			<div class="tcm-content-form" v-for="(item, index) in formData" :key="index">
				<div class="tcm-arrows" v-show="type != 'template'">
					<img src="../../assets/img/tcm/zuojiantou.png" class="tcm-arrows-img mgr-10" @click="reorder('left', index)"/>
					<img src="../../assets/img/tcm/youjiantou.png" class="tcm-arrows-img" @click="reorder('right', index)"/>
				</div>
				<div v-show="type == 'template'" style="margin-bottom: 10px">
					<el-row v-show="operateType != 'add'">
						<el-col :span="9">
							<div class="size-14 padding-9-0"><span class="color-red">*</span>{{$t('templateLists.templateList.cellNo')}}</div>
						</el-col>
						<el-col :span="13"><el-input v-model="item.number" style="width: calc(100% - 20px);" disabled /></el-col>
						<el-col :span="2"></el-col>
					</el-row>
					
					<el-row>
						<el-col :span="9">
							<div class="size-14 padding-9-0"><span class="color-red">*</span>{{$t('templateLists.templateList.cellName')}}</div>
						</el-col>
						<el-col :span="13"><el-input v-model="item.name" style="width: calc(100% - 20px);" :disabled="operateType == 'detail'" /></el-col>
						<el-col :span="2"></el-col>
					</el-row>
					<el-row>
						<el-col :span="9">
							<div class="size-14 padding-9-0"><span class="color-red">*</span>{{$t('tcmlists.tcmlist.commodity')}}</div>
						</el-col>
						<el-col :span="13">
							<el-tree-select clearable  :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')" v-model="item.commodityId" append-to-body  @change="changeCommodityId($event, index)" style="width: calc(100% - 20px);" :disabled=" operateType == 'detail'" :data="CommodityOptions" :props="defaultProps" check-strictly :render-after-expand="false" :default-expand-all="true" />
						</el-col>
						<el-col :span="2"></el-col>
					</el-row>
					<el-row>
						<el-col :span="9">
							<div class="size-14 padding-9-0"><span class="color-red">*</span>{{$t('templateLists.templateList.region')}}</div>
						</el-col>
						<el-col :span="13">
							<el-select clearable   :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')" v-model="item.regionId" placeholder="" style="width: calc(100% - 20px);" :disabled=" operateType == 'detail'">
								<el-option
								    v-for="item in regionOptions"
								    :key="item.id"
								    :label="item.name"
								    :value="item.id"
								/>
							</el-select>
						</el-col>
						<el-col :span="2"></el-col>
					</el-row>
					<el-row v-show="operateType != 'add'">
						<el-col :span="9">
							<div class="size-14 padding-9-0">{{$t('templateLists.templateList.version')}}</div>
						</el-col>
						<el-col :span="13"><el-input v-model="item.version" style="width: calc(100% - 20px);" disabled /></el-col>
						<el-col :span="2"></el-col>
					</el-row>
					<el-row>
						<el-col :span="9">
							<div class="size-14 padding-9-0">{{$t('templateLists.templateList.comments')}}</div>
						</el-col>
						<el-col :span="13"><el-input v-model="item.comment" style="width: calc(100% - 20px);" :disabled="operateType == 'detail'" /></el-col>
						<el-col :span="2"></el-col>
					</el-row>
				</div>
				<div class="form-type-title">Set Up # {{index+1}}</div>
				<el-row>
					<el-col :span="9">
						<div class="size-14 padding-9-0">{{$t('changePriceLists.changePriceList.annualVolume')}}</div>
					</el-col>
					<el-col :span="13"><el-input v-model="item.annualVolume" style="width: calc(100% - 20px);" :disabled="operateType == 'detail' || type != 'alloperate'" /></el-col>
					<el-col :span="2"></el-col>
				</el-row>
				<el-row>
					<el-col :span="9">
						<div class="size-14 padding-9-0"><span class="color-red">*</span>{{$t('changePriceLists.changePriceList.setUpTime')}}</div>
					</el-col>
					<el-col :span="13"><el-input v-model="item.setTime" style="width: calc(100% - 20px);" type="number" :disabled="operateType == 'detail'"  /></el-col>
					<el-col :span="2"></el-col>
				</el-row>
				<el-row>
					<el-col :span="9">
						<div class="size-14 padding-9-0"><span class="color-red">*</span>{{$t('changePriceLists.changePriceList.setUpPersons')}}</div>
					</el-col>
					<el-col :span="13"><el-input v-model="item.setPerson" style="width: calc(100% - 20px);" type="number" :disabled="operateType == 'detail'" /></el-col>
					<el-col :span="2"></el-col>
				</el-row>
				<el-row>
					<el-col :span="9">
						<div class="size-14 padding-9-0">{{$t('changePriceLists.changePriceList.piecesPerLot')}}</div>
					</el-col>
					<el-col :span="13"><el-input v-model="item.pieceLot" style="width: calc(100% - 20px);" type="number" :disabled="operateType == 'detail' || type != 'alloperate'" /></el-col>
					<el-col :span="2"></el-col>
				</el-row>
				<el-row>
					<el-col :span="9">
						<div class="size-14 padding-9-0">{{$t('changePriceLists.changePriceList.setUpCosts')}}</div>
					</el-col>
					<el-col :span="13"><el-input v-model="item.pieceCost" style="width: calc(100% - 20px);" disabled /></el-col>
					<el-col :span="2"></el-col>
				</el-row>
				<div class="form-type-title">Operation # {{index+1}}</div>
				<el-row>
					<el-col :span="9">
						<div class="size-14 padding-9-0"><span class="color-red">*</span>{{$t('changePriceLists.changePriceList.operationDescription')}}</div>
					</el-col>
					<!== <el-col :span="13"><el-input v-model="item.processName" style="width: calc(100% - 20px);" :disabled="operateType == 'detail'" /></el-col> ==>
					<el-col :span="13" v-show="type == 'template'"><el-input v-model="item.processName" style="width: calc(100% - 20px);" :disabled="operateType == 'detail'" /></el-col>
					<el-col :span="13" v-show="type != 'template'"><el-input v-model="item.name" style="width: calc(100% - 20px);" :disabled="operateType == 'detail'" /></el-col>
					<el-col :span="2"></el-col>
				</el-row>
				<el-row>
					<el-col :span="9">
						<div class="size-14"><span class="color-red">*</span>{{$t('changePriceLists.changePriceList.descriptionSize')}}</div>
					</el-col>
					<el-col :span="13"><el-input v-model="item.equSize" style="width: calc(100% - 20px);" :disabled="operateType == 'detail'" /></el-col>
					<el-col :span="2">
						<img src="../../assets/img/tcm/daikuanjisuanqi.png" v-show="type == 'alloperate' && operateType != 'detail'" style="width: 25px;margin-top: 3.5px;cursor: pointer;"  @click="openCal(index, 'equSize')"/>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="9">
						<div class="size-14 padding-9-0"><span class="color-red">*</span>{{$t('changePriceLists.changePriceList.consumption')}}</div>
					</el-col>
					<el-col :span="13"><el-input v-model="item.quantity" style="width: calc(100% - 20px);" type="number" :disabled="operateType == 'detail'  || type != 'alloperate'" /></el-col>
					<el-col :span="2"></el-col>
				</el-row>
				<el-row>
					<el-col :span="9">
						<div class="size-14 padding-9-0"><span class="color-red">*</span>{{$t('changePriceLists.changePriceList.piecesHour')}}</div>
					</el-col>
					<el-col :span="13"><el-input v-model="item.pieceHour" type="number" style="width: calc(100% - 20px);" :disabled="operateType == 'detail'" /></el-col>
					<el-col :span="2">
						<img src="../../assets/img/tcm/daikuanjisuanqi.png" v-show="type == 'alloperate' && operateType != 'detail'" style="width: 25px;margin-top: 3.5px;cursor: pointer;" @click="openCal(index, 'pieceHour')"/>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="9">
						<div class="size-14 padding-9-0"><span class="color-red">*</span>{{$t('changePriceLists.changePriceList.piecesPer')}}</div>
					</el-col>
					<el-col :span="13"><el-input v-model="item.pieceCycle" style="width: calc(100% - 20px);" type="number" :disabled="operateType == 'detail'" /></el-col>
					<el-col :span="2"></el-col>
				</el-row>
				<el-row>
					<el-col :span="9">
						<div class="size-14"><span class="color-red">*</span>{{$t('changePriceLists.changePriceList.manufacturingEfficiency')}}</div>
					</el-col>
					<el-col :span="13">
						<el-input v-model="item.efficiencyPercent" style="width: calc(100% - 20px);" type="number" :disabled="operateType == 'detail'" >
							<template #suffix>
							    <el-icon class="el-input__icon">%</el-icon>
							</template>
						</el-input>
					</el-col>
					<el-col :span="2"></el-col>
				</el-row>
				<el-row>
					<el-col :span="9">
						<div class="size-14 padding-9-0">{{$t('changePriceLists.changePriceList.standardHour')}}</div>
					</el-col>
					<el-col :span="13"><el-input v-model="item.standardHour" style="width: calc(100% - 20px);" disabled /></el-col>
					<el-col :span="2"></el-col>
				</el-row>
				<el-row>
					<el-col :span="9">
						<div class="size-14 padding-9-0"><span class="color-red">*</span>{{$t('changePriceLists.changePriceList.directRate')}}</div>
					</el-col>
					<el-col :span="13"><el-input v-model="item.directRate" style="width: calc(100% - 20px);" type="number" :disabled="operateType == 'detail'" /></el-col>
					<el-col :span="2"></el-col>
				</el-row>
				<el-row>
					<el-col :span="9">
						<div class="size-14"><span class="color-red">*</span>{{$t('changePriceLists.changePriceList.setUpRate')}}</div>
					</el-col>
					<el-col :span="13"><el-input v-model="item.setUpRate" style="width: calc(100% - 20px);" type="number" :disabled="operateType == 'detail'" /></el-col>
					<el-col :span="2"></el-col>
				</el-row>
				<el-row>
					<el-col :span="9">
						<div class="size-14 padding-9-0"><span class="color-red">*</span>{{$t('changePriceLists.changePriceList.directOperators')}}</div>
					</el-col>
					<el-col :span="13"><el-input v-model="item.directOperateQty" style="width: calc(100% - 20px);" type="number" :disabled="operateType == 'detail'" /></el-col>
					<el-col :span="2"></el-col>
				</el-row>
				<el-row>
					<el-col :span="9">
						<div class="size-14">{{$t('changePriceLists.changePriceList.directAssembly')}}</div>
					</el-col>
					<el-col :span="13"><el-input v-model="item.assemblyDirectCost" style="width: calc(100% - 20px);" disabled /></el-col>
					<el-col :span="2"></el-col>
				</el-row>
				<el-row>
					<el-col :span="9">
						<div class="size-14"><span class="color-red">*</span>{{$t('changePriceLists.changePriceList.burdenVariable')}}</div>
					</el-col>
					<el-col :span="13"><el-input v-model="item.variableRate" style="width: calc(100% - 20px);" type="number" :disabled="operateType == 'detail'" /></el-col>
					<el-col :span="2"></el-col>
				</el-row>
				<el-row>
					<el-col :span="9">
						<div class="size-14 padding-9-0">{{$t('changePriceLists.changePriceList.burdenRate')}}</div>
					</el-col>
					<el-col :span="13"><el-input v-model="item.fixedRate" style="width: calc(100% - 20px);" type="number" :disabled="operateType == 'detail'" /></el-col>
					<el-col :span="2"></el-col>
				</el-row>
				<el-row>
					<el-col :span="9">
						<div class="size-14">{{$t('changePriceLists.changePriceList.burdenDepreciation')}}</div>
					</el-col>
					<el-col :span="13"><el-input v-model="item.depreciationRate" style="width: calc(100% - 20px);" type="number" :disabled="operateType == 'detail'" /></el-col>
					<el-col :span="2"></el-col>
				</el-row>
				<el-row>
					<el-col :span="9">
						<div class="size-14 padding-9-0">{{$t('changePriceLists.changePriceList.totalBurdenRate')}}</div>
					</el-col>
					<el-col :span="13"><el-input v-model="item.totalBurdenRate" style="width: calc(100% - 20px);" disabled /></el-col>
					<el-col :span="2"></el-col>
				</el-row>
				<el-row>
					<el-col :span="9">
						<div class="size-14 padding-9-0">{{$t('changePriceLists.changePriceList.burdenAssembly')}}</div>
					</el-col>
					<el-col :span="13"><el-input v-model="item.assemblyBurdenCost" style="width: calc(100% - 20px);" disabled /></el-col>
					<el-col :span="2"></el-col>
				</el-row>
				<el-row>
					<el-col :span="9">
						<div class="size-14 padding-9-0">{{$t('changePriceLists.changePriceList.subTotalCost')}}</div>
					</el-col>
					<el-col :span="13"><el-input v-model="item.totalCost" style="width: calc(100% - 20px);" disabled /></el-col>
					<el-col :span="2"></el-col>
				</el-row>
				<el-row>
					<el-col :span="9">
						<div class="size-14 padding-9-0"><span class="color-red">*</span>{{$t('changePriceLists.changePriceList.processScrap')}}</div>
					</el-col>
					<el-col :span="13">
						<el-input v-model="item.scrapPercent" style="width: calc(100% - 20px);" type="number" :disabled="operateType == 'detail'" >
							<template #suffix>
							    <el-icon class="el-input__icon">%</el-icon>
							</template>
						</el-input>
					</el-col>
					<el-col :span="2"></el-col>
				</el-row>
				<el-row  v-show="type != 'template' && operateType != 'detail'">
					<el-col>
						<div class="align-right">
							<el-button type="info" @click="deleteMoudle(index)">{{$t('unifiedPrompts.unifiedPrompt.delete')}}</el-button>
							<el-button type="info" @click="copyMoudle(index)">Copy</el-button>
						</div>
						
					</el-col>
				</el-row>
				<el-row v-show="type == 'template' && operateType != 'detail'">
					<el-col>
						<div class="align-right">
							<el-button type="info"  style="background-color: var(--themeColor); color: var(--menuTextActive);" @click="cancle">cancle</el-button>
							<el-button type="info"  style="background-color: var(--themeColor); color: var(--menuTextActive);" @click="confirm">confirm</el-button>
						</div>
						
					</el-col>
				</el-row>
			</div>
			<div class="tcm-form-solid"  v-show="type != 'template' && operateType != 'detail'" @click="addMoudle">
				<el-icon :size="35" color="#8c8c8c"><Plus /></el-icon>
			</div>
		</div>
		<CalculatorList ref="calList" @chooseRow="chooseRow"></CalculatorList>
		<PreviewDia ref="preDia" type="template" :formulaPre="outform.formulaPre" :previewResultx="previewResultx" :fileListUrl="fileListUrl" :intermediateBondList="intermediateBondList" :valueList="valueList" @tocompute="tocompute" @toConfirm="toConfirm"></PreviewDia>
		<ProDia ref="proDialog" :CommodityId="CommodityId" @getChooseProId="getChooseProId"></ProDia>
	</div> -->
</template>

<script>
import {
  GetCalculatorDetail,
  InputParameterAnalysis,
  getTemplateProcesssDetail,
} from "../../assets/js/requestApi.js";
import commonUtil from "../../assets/js/commonUtil.js";
import CalculatorList from "../processCalculatorManagement/calculatorListDia.vue";
import PreviewDia from "../processCalculatorManagement/previewDia.vue";
import ProDia from "../processingManagement/proDia.vue";
export default {
  props: [
    "type",
    "operateType",
    "CommodityId",
    "CommodityName",
    "CommodityOptions",
    "regionOptions",
  ],
  emits: ["goBack", "confirmData"],
  components: {
    CalculatorList,
    PreviewDia,
    ProDia,
  },
  data() {
    return {
      laborCost_PRO: "",
      setUpCost_PRO: "",
      burdenCost_PRO: "",
      manufacturingCost_PRO: "",
      formData: [
        {
          id: "",
          number: "",
          name: "",
          version: "",
          comment: "",
          isEnable: 1,
          annualVolume: "",
          setTime: "",
          setPerson: "",
          pieceLot: "",
          pieceCost: "",
          processName: "",
          equSize: "",
          quantity: "1",
          pieceHour: "",
          pieceCycle: "",
          efficiencyPercent: "",
          standardHour: "",
          directRate: "",
          setUpRate: "",
          directOperateQty: "",
          assemblyDirectCost: "",
          variableRate: "",
          fixedRate: "",
          depreciationRate: "",
          totalBurdenRate: "",
          assemblyBurdenCost: "",
          totalCost: "",
          scrapPercent: "",
          commodityId: "",
          regionId: "",
          cLines: [],
        },
      ],
      // 商品分类显示字段
      defaultProps: {
        children: "children",
        label: "Name",
      },
      // 工艺计算器详细数据
      form: {
        id: "",
        process_Calculator_Code: "",
        name: "",
        commodityId: "",
        commodityName: "",
        version: "",
        comments: "",
      },
      // 上传的文件
      fileListx: [],
      fileListUrl: [],
      // 表格内容
      tableData: [],
      outform: {
        name: "",
        unit: "",
        formula: "",
        formulaVal: "",
        formulaPre: "",
      },
      // 符号
      symbol: ["+", "-", "*", "/", "sum", "if", "(", ")"],
      // 计算结果
      previewResultx: 0,
      // 打开计算器输入框类型
      calType: "input",
      // 中间键列表
      intermediateBondList: [],
      // 数值列表
      valueList: [],
      // 当前操作数据下标
      operateIndex: "",
      // 当前操作数据字段名
      operateName: "",
      // 当前选择的工艺计算器id
      operateCalId: "",
      // 编辑时删除的组件id
      processDelIds: [],
      // Sub-Total Cost/Burden Cost / Assembly乘积之和
      sbSum: 0,
    };
  },
  methods: {
    // 取消
    cancle() {
      this.$emit("goBack");
    },
    // 提交
    confirm() {
      this.$emit("confirmData", this.formData);
    },
    // 打开计算器
    openCal(index, type) {
      if (this.operateType == "detail") {
        return;
      }
      this.operateIndex = index;
      this.operateName = type;
      this.$refs.calList.getlist();
      this.$refs.calList.getTreeData();
      this.$refs.calList.visible = true;
    },
    // 获取选择的工艺计算器
    chooseRow(row) {
      this.operateCalId = row.id;
      this.getDetail(row.id);
    },
    // 根据id获取选择的工艺计算器详情
    getDetail(id) {
      let _this = this;
      GetCalculatorDetail('"' + id + '"', (response) => {
        if (response && response.code == 200) {
          this.form = {
            id: response.data[0].id,
            process_Calculator_Code: response.data[0].process_Calculator_Code,
            name: response.data[0].process_Calculator_Name,
            commodityId: response.data[0].commodityId,
            commodityName: response.data[0].commodList[0].name,
            version: response.data[0].version,
            comments: response.data[0].comments,
          };
          _this.intermediateBondList = [];
          _this.valueList = [];
          this.tableData = response.data[0].edit_List;
          this.tableData.forEach(function (val, key) {
            val.formulaVal = val.formulaPre
              ? val.formulaPre.replace(/\|/g, "")
              : "";
            if (val.formula != "") {
              _this.intermediateBondList.push(val);
            } else {
              _this.valueList.push(val);
            }
          });
          this.outform = {
            name: response.data[0].outputName,
            unit: response.data[0].outputUnit,
            formula: response.data[0].outputFormulaPre.replace(/\|/g, ""),
            formulaVal: response.data[0].outputFormula,
            formulaPre: response.data[0].outputFormulaPre,
          };
          this.fileListx = response.data[0].pictureList;
          // 计算默认值
          let list = [];
          this.valueList.forEach(function (val, key) {
            list.push({
              paramValue: val.thedefaultvalue,
              paramentName: val.parameterName,
            });
          });
          this.intermediateBondList.forEach(function (val, key) {
            list.push({
              paramValue: val.formulaPre,
              paramentName: val.parameterName,
            });
          });
          let data = {
            outputFormula: this.outform.formulaPre,
            inputParameterList: list,
          };
          this.$refs.preDia.visible = true;
          // this.tocompute(data)
        }
      });
    },
    // 计算结果
    tocompute(val) {
      InputParameterAnalysis(val, (response) => {
        if (response && response.code == 200) {
          this.previewResultx = response.data;
          this.$refs.preDia.visible = true;
        }
      });
    },
    // 获取最后计算的值
    toConfirm(val) {
      this.$refs.calList.visible = false;
      this.formData[this.operateIndex][this.operateName] = val;
      let row = {};
      if (this.operateName == "equSize") {
        row = {
          fieldName: "equSize",
          calculatorTableId: this.operateCalId,
        };
      } else if (this.operateName == "pieceHour") {
        row = {
          fieldName: "pieceHour",
          calculatorTableId: this.operateCalId,
        };
      }
      if (this.formData[this.operateIndex].cLines.indexOf(row) > -1) {
        let index = this.formData[this.operateIndex].cLines.indexOf(row);
        this.formData[this.operateIndex].cLines[index] = row;
      } else {
        this.formData[this.operateIndex].cLines.push(row);
      }
      this.toComputeAll(this.formData[this.operateIndex]);
    },
    // 添加组件
    addMoudle() {
      if (this.CommodityId == "") {
        commonUtil.showTipInfo("请选择商品类别！", "", "warning");
        return;
      }
      this.$refs.proDialog.searchForm.page = 1;
      this.$refs.proDialog.getlist();
      this.$refs.proDialog.visible = true;
    },
    // 添加空组件
    addEmptyMoudle() {
      this.formData.push({
        id: "",
        number: "",
        name: "",
        version: "",
        comment: "",
        isEnable: 1,
        annualVolume: "",
        setTime: "",
        setPerson: "",
        pieceLot: "",
        pieceCost: "",
        processName: "",
        equSize: "",
        quantity: "1",
        pieceHour: "",
        pieceCycle: "",
        efficiencyPercent: "",
        standardHour: "",
        directRate: "",
        setUpRate: "",
        directOperateQty: "",
        assemblyDirectCost: "",
        variableRate: "",
        fixedRate: "",
        depreciationRate: "",
        totalBurdenRate: "",
        assemblyBurdenCost: "",
        totalCost: "",
        scrapPercent: "",
        commodityId: "",
        regionId: "",
        cLines: [],
      });
    },
    // 获取添加组件的详情信息
    getChooseProId(id) {
      let _this = this;
      getTemplateProcesssDetail(
        {
          id: id,
        },
        (response) => {
          if (response && response.code == 200) {
            // this.CommodityId = response.result.commodityId
            response.result.forEach(function (resVal, resIndex) {
              let cLines = [];
              resVal.cLines.forEach(function (val, index) {
                cLines.push({
                  fieldName: val.paramName,
                  calculatorTableId: val.calculator_TableId,
                });
              });
              let moudle = resVal;
              moudle.id = "";
              moudle.cLines = cLines;
              moudle.name = moudle.processName;
              if (_this.type == "alloperate") {
                moudle.annualVolume =
                  _this.$parent.$refs.gen.genList[0].peakVolume;
                moudle.pieceLot = _this.$parent.$refs.gen.form.lotSize;
              }
              _this.formData.push(moudle);
              _this.toComputeAll(moudle);
            });
            this.$refs.proDialog.visible = false;
          }
        }
      );
    },
    // 删除组件
    deleteMoudle(index) {
      if (this.processDelIds.indexOf(this.formData[index].id) > -1) {
        this.processDelIds.splice(
          this.processDelIds.indexOf(this.formData[index].id),
          1
        );
      } else {
        this.processDelIds.push(this.formData[index].id);
      }
      this.formData.splice(index, 1);
      if (this.formData.length > 0) {
        this.toComputeAll(this.formData[0]);
      }
    },
    // 复制组件
    copyMoudle(index) {
      let data = JSON.parse(JSON.stringify(this.formData[index]));
      data.id = "";
      this.formData.push(data);
      if (this.formData.length > 0) {
        this.toComputeAll(this.formData[0]);
      }
    },
    // 计算
    toComputeAll(row) {
      let _this = this;
      if (row) {
        // 计算Set Up Costs Per Piece
        if (
          row.setTime !== "" &&
          row.pieceLot !== "" &&
          row.quantity !== "" &&
          row.setPerson !== "" &&
          row.setUpRate !== "" &&
          row.variableRate !== "" &&
          row.fixedRate !== "" &&
          row.depreciationRate !== ""
        ) {
          if (
            (row.setTime === 0 || row.setTime === "") &&
            (row.pieceLot === "" || row.pieceLot === null)
          ) {
            row.pieceCost = 0;
          } else if (row.setTime >= 0) {
            row.pieceCost = (
              (parseFloat(row.setTime) /
                60 /
                parseFloat(
                  row.pieceLot === 0 ||
                    !row.pieceLot ||
                    row.pieceLot === "" ||
                    row.pieceLot === null
                    ? 1
                    : row.pieceLot
                ) /
                parseFloat(
                  row.quantity === 0 || !row.quantity || row.quantity === null
                    ? 1
                    : row.quantity
                )) *
              (parseFloat(
                row.setPerson === "" || row.setPerson === null
                  ? 0
                  : row.setPerson
              ) *
                parseFloat(
                  row.setUpRate === "" || row.setUpRate === null
                    ? 0
                    : row.setUpRate
                ) +
                parseFloat(
                  row.variableRate === "" || row.variableRate === null
                    ? 0
                    : row.variableRate
                ) +
                parseFloat(
                  row.fixedRate === "" || row.fixedRate === null
                    ? 0
                    : row.fixedRate
                ) +
                parseFloat(
                  row.depreciationRate === "" || row.depreciationRate === null
                    ? 0
                    : row.depreciationRate
                ))
            ).toString();
          }
        }
        // if ((row.pieceHour !== '' && row.pieceHour !== 0) && row.efficiencyPercent !== '' ) {
        row.standardHour =
          1 /
          parseFloat(
            row.pieceHour == 0 ||
              row.pieceHour === "" ||
              row.pieceHour === null ||
              !row.pieceHour
              ? 1
              : row.pieceHour
          ) /
          parseFloat(
            row.efficiencyPercent == 0 ||
              row.efficiencyPercent === "" ||
              row.efficiencyPercent === null ||
              !row.efficiencyPercent
              ? 1
              : row.efficiencyPercent / 100
          );
        // }
        if (
          row.standardHour === "" ||
          row.standardHour === null ||
          row.directRate === "" ||
          row.directRate === null
        ) {
          row.assemblyDirectCost = 0;
        } else {
          row.assemblyDirectCost =
            parseFloat(row.standardHour) *
            parseFloat(row.directRate) *
            parseFloat(row.directOperateQty == "" ? 1 : row.directOperateQty);
        }
        // if (row.standardHour != '' && row.variableRate != '' && row.fixedRate != '' && row.depreciationRate != '') {
        row.assemblyBurdenCost = (
          (parseFloat(row.variableRate === "" ? 0 : row.variableRate) +
            parseFloat(row.fixedRate === "" ? 0 : row.fixedRate) +
            parseFloat(
              row.depreciationRate === "" ? 0 : row.depreciationRate
            )) *
          parseFloat(row.standardHour === "" ? 0 : row.standardHour)
        ).toString();
        // }
        // if (row.assemblyDirectCost != '' && row.assemblyBurdenCost != '') {
        row.totalCost = (
          parseFloat(
            row.assemblyDirectCost === "" ? 0 : row.assemblyDirectCost
          ) +
          parseFloat(row.assemblyBurdenCost === "" ? 0 : row.assemblyBurdenCost)
        ).toString();
        // }
        if (
          row.variableRate != "" &&
          row.fixedRate != "" &&
          row.depreciationRate != ""
        ) {
          row.totalBurdenRate = (
            parseFloat(row.variableRate) +
            parseFloat(row.fixedRate) +
            parseFloat(row.depreciationRate)
          ).toString();
        }
        if (row.pieceHour == 0) {
          row.totalBurdenRate = "0";
          row.assemblyBurdenCost = "0";
          row.totalCost = "0";
        }
      }

      // assemblyDirectCost
      _this.laborCost_PRO = 0;
      _this.setUpCost_PRO = 0;
      _this.burdenCost_PRO = 0;
      _this.manufacturingCost_PRO = 0;
      _this.sbSum = 0;
      this.formData.forEach(function (val, index) {
        _this.laborCost_PRO += parseFloat(
          val.assemblyDirectCost == "" ? 0 : val.assemblyDirectCost
        );
        _this.burdenCost_PRO += parseFloat(
          val.assemblyBurdenCost == "" ? 0 : val.assemblyBurdenCost
        );
        _this.setUpCost_PRO += parseFloat(
          val.pieceCost == "" || !val.pieceCost ? 0 : val.pieceCost
        );
        if (_this.type != "template") {
          _this.manufacturingCost_PRO =
            parseFloat(_this.laborCost_PRO === "" ? 0 : _this.laborCost_PRO) +
            parseFloat(_this.burdenCost_PRO) +
            parseFloat(_this.setUpCost_PRO) +
            parseFloat(
              _this.$parent.$refs.rwTcm.materialCost_RM === ""
                ? 0
                : _this.$parent.$refs.rwTcm.materialCost_RM
            ) +
            parseFloat(
              _this.$parent.$refs.purTcm.purchaseCost_PUR === ""
                ? 0
                : _this.$parent.$refs.purTcm.purchaseCost_PUR
            );
          _this.sbSum +=
            parseFloat(val.scrapPercent == "" ? 0 : val.scrapPercent / 100) *
            parseFloat(val.totalCost == "" ? 0 : val.totalCost);
        }
        val.pieceCost =
          val.pieceCost == "" || !val.pieceCost
            ? ""
            : parseFloat(val.pieceCost).toFixed(3);
        val.standardHour =
          val.standardHour == "" || !val.standardHour
            ? ""
            : parseFloat(val.standardHour).toFixed(3);
        val.assemblyDirectCost =
          val.assemblyDirectCost == "" || !val.assemblyDirectCost
            ? ""
            : parseFloat(val.assemblyDirectCost).toFixed(3);
        val.totalCost =
          val.totalCost == "" || !val.totalCost
            ? ""
            : parseFloat(val.totalCost).toFixed(3);
        val.totalBurdenRate =
          val.totalBurdenRate == "" || !val.totalBurdenRate
            ? ""
            : parseFloat(val.totalBurdenRate).toFixed(3);
        val.assemblyBurdenCost =
          val.assemblyBurdenCost == "" || !val.assemblyBurdenCost
            ? ""
            : parseFloat(val.assemblyBurdenCost).toFixed(3);
      });
      _this.laborCost_PRO = _this.laborCost_PRO.toFixed(3);
      _this.burdenCost_PRO = _this.burdenCost_PRO.toFixed(3);
      _this.setUpCost_PRO = _this.setUpCost_PRO.toFixed(3);
      _this.manufacturingCost_PRO = _this.manufacturingCost_PRO.toFixed(3);
      _this.sbSum = _this.sbSum.toFixed(3);
      if (_this.type != "template") {
        _this.$parent.$refs.ohTcm.tocompute();
      }
    },
    // 修改分类
    changeCommodityId(val, index) {
      this.formData[index].commodityId = val;
    },
    // 切换顺序
    reorder(arrow, index) {
      if (index == 0 && arrow == "left") {
        commonUtil.showTipInfo("已在第一位，不可往左移！", "", "warning");
        return;
      }
      if (index == this.formData.length - 1 && arrow == "right") {
        commonUtil.showTipInfo("已在最后一位，不可往右移！", "", "warning");
        return;
      }
      if (arrow == "left") {
        [this.formData[index - 1], this.formData[index]] = [
          this.formData[index],
          this.formData[index - 1],
        ];
        this.$forceUpdate();
      } else if (arrow == "right") {
        [this.formData[index + 1], this.formData[index]] = [
          this.formData[index],
          this.formData[index + 1],
        ];
        this.$forceUpdate();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.bottom-box {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.handle-btn {
  color: #fff;
  width: 200px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #2f4cdd;
  border-radius: 10px;
  cursor: pointer;
}
.Raw-box {
  display: flex;
  flex-wrap: wrap;
}
.box-main {
  padding: 15px;
  background: rgba(255, 255, 255, 0.6);
}
.Raw-card {
  margin-right: 15px;
  margin-top: 15px;
  padding: 10px 35px 0px 20px;
  width: 30%;
  border-radius: 10px;
  color: #363b64;
  background: #f2f3f5;
  // background: linear-gradient(0deg, #f4f7ff, #f4f7ff),
  //   linear-gradient(94.84deg, #fff 3.78%, #fcfdff 9.75%, #e6eeff 102.09%),
  //   linear-gradient(126.58deg, #fff -18.12%, hsla(0, 0%, 100%, 0) 26.77%);
  .Raw-top {
    padding-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    font-size: 15px;
    .Raw-title {
      display: flex;
      align-items: center;
    }
    .el-icon {
      font-size: 18px;
      font-weight: 600;
      color: #a098ae;
    }
  }
  .Raw-edit-btn {
    margin-bottom: -20px;
    text-align: right;
    font-size: 20px;
  }
  .Raw-card-main {
    padding: 10px 20px 10px;
    width: 100%;
    height: 600px;
    overflow-y: scroll;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.8) !important;
    p {
      padding: 10px 0 10px 4px;
      font-weight: 600;
      color: #363b64;
    }
    input {
      width: 80%;
      background: #f6f6f6;
      border: 1px solid #718ebf;
      border-radius: 10px;
    }
  }
}
.Raw-card-item {
  .el-icon {
    margin-left: 10px;
    font-size: 18px;
    font-weight: 600;
    color: #a098ae;
  }
  img {
    display: inline-block;
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
}
.Total-MaterialCost {
  display: flex;
  justify-content: flex-end;
  font-size: 15px;
  color: #363b64;
  font-weight: 600;
  .MaterialCost {
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  input {
    width: 200px;
    font-size: 13px;
    background: #f6f6f6;
    border: 1px solid #718ebf;
    border-radius: 10px;
  }
}
.submit-btn {
  margin-right: 10px;
  padding: 0 30px;
  height: 30px;
  line-height: 30px;
  background: #445581;
  color: #fff;
  text-align: center;
  border-radius: 30px;
  cursor: pointer;
}

.tcm-form-solid {
  position: relative;
  height: 500px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  border: 1px dashed #c0c4cc;
}
.add-cla-template {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.form-type-title {
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  background: #fff;
  line-height: 35px;
  border-radius: 5px;
  padding: 0 10px;
  margin: 10px 0;
  position: relative;
  border: 1px solid #ccc;
}
.form-type-title-img {
  width: 25px;
  position: absolute;
  right: 10px;
  top: 5px;
}
</style>