<template>
  <div class="main">
    <div class="main-top_handle">
      <div class="title">
        {{ $t("other.other.CTCalculator") }} -
        {{ $t("other.other.CustomEquimentList") }}
      </div>
      <div class="main-handle flex">
        <div class="main-handle-item">
          <input
            type="text"
            v-model="searchForm.key"
            :placeholder="$t('unifiedPrompts.unifiedPrompt.keyword')"
          />
        </div>
        <div class="search-btn" @click="search">
          {{ $t("tcmlists.tcmlist.search") }}
        </div>
      </div>
    </div>
    <div class="handle-btn flex">
      <div v-if="BtnLimits.Add" class="handle-btn-item" @click="addData">
        {{ $t("pcbas.pcba.new") }}
      </div>
      <div v-if="BtnLimits.Edit" class="handle-btn-item" @click="editData">
        {{ $t("pcbas.pcba.edit") }}
      </div>
      <div v-if="BtnLimits.Delete" class="handle-btn-item" @click="deleteData">
        {{ $t("pcbas.pcba.delete") }}
      </div>
      <div class="handle-btn-item" @click="copyData">
        {{ $t("pcbas.pcba.copy") }}
      </div>
      <div v-if="BtnLimits.ToolList" class="handle-btn-item" @click="toTool">
        {{ $t("ccls.ccl.ToolList") }}
      </div>
    </div>
    <div class="regional_table">
      <el-table
        border
        stripe
        :empty-text="$t('tcmlists.tcmlist.noData')"
        ref="roleTable"
        :data="tableData"
        :height="TableHeight"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        @row-click="RowClick"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column
          prop="machiningType"
          :label="$t('ccls.ccl.Machiningtype')"
          align="center"
        />
        <el-table-column
          prop="chipToChip"
          :label="$t('ccls.ccl.ChipToChip')"
          align="center"
        />
        <el-table-column
          prop="positionTime"
          :label="$t('ccls.ccl.PositionTime')"
          align="center"
        />
        <el-table-column
          prop="toolChange"
          :label="$t('ccls.ccl.TOOLCHANGE')"
          align="center"
        />
        <el-table-column
          prop="indexValue"
          :label="$t('ccls.ccl.Index')"
          align="center"
        />
        <el-table-column
          prop="palletChange"
          :label="$t('ccls.ccl.PalletChange')"
          align="center"
        />
        <el-table-column
          prop="openOrCloseTime"
          :label="$t('ccls.ccl.Door_OpenClose')"
          align="center"
        />
        <el-table-column
          prop="loadingOrUnloadingTime"
          :label="$t('ccls.ccl.Loading_Unloading')"
          align="center"
        />
        <el-table-column
          prop="btsTime"
          :label="$t('ccls.ccl.BTStime')"
          align="center"
        />
        <el-table-column
          prop="cavity"
          :label="$t('ccls.ccl.Cavity')"
          align="center"
        />
        <el-table-column
          prop="maxSpeed"
          :label="$t('ccls.ccl.MaximumSplindleSpeed')"
          align="center"
        />
        <el-table-column
          prop="toolApproach"
          :label="$t('ccls.ccl.ToolApproach')"
          align="center"
        />
        <el-table-column
          prop="toolRetract"
          :label="$t('ccls.ccl.ToolRetract')"
          align="center"
        />
        <el-table-column
          prop="materialName"
          :label="$t('other.other.Material')"
          align="center"
        />
      </el-table>
    </div>
    <div class="pagination">
      <div>
        {{ $t("homePage.home.Showing") }} {{ pageSize }}
        {{ $t("homePage.home.From") }} {{ total }}
        {{ $t("homePage.home.Data") }}
        <el-popover placement="top" :width="70" trigger="click">
          <template #reference>
            <el-icon
              style="margin-left: 5px; font-size: 17px; vertical-align: middle"
              ><Operation
            /></el-icon>
          </template>
          <el-input
            v-model="searchForm.limit"
            @input="(val) => (searchForm.limit = val.replace(/[^\d]/g, ''))"
            :min="1"
            style="width: 130px"
            @change="handleSizeChange"
          >
            <template #suffix> {{ $t("ccls.ccl.barPage") }}</template>
          </el-input>
        </el-popover>
      </div>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="pageSize"
        :current-page="searchForm.page"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <AddEquiment ref="addDia" @confirmInfo="confirmInfo"></AddEquiment>
  </div>
</template>

<script>
import {
  LoadEquiment,
  AddOrUpdateEquiment,
  DeleteEquiment,
} from "../../../assets/js/requestApi.js";
import commonUtil from "../../../assets/js/commonUtil.js";
import AddEquiment from "./addEquiment.vue";
export default {
  name: "equimentList",
  activated() {
    // 在首次挂载、
    // 以及每次从缓存中被重新插入的时候调用
    this.search();
    this.getUnits();
  },
  deactivated() {
    clearTimeout(this.timer);
    // 在从 DOM 上移除、进入缓存
    // 以及组件卸载时调用
  },
  components: {
    AddEquiment,
  },
  data() {
    return {
      TableHeight: 0,
      searchForm: {
        description: "",
        page: 1,
        limit: 10,
        key: "",
      },
      tableData: [],
      // 已选择数据
      chooseData: [],
      // 已选择对比数据
      drawer: false,
      // 选择的对比数据列表
      compareList: [],
      // 总页数
      total: 0,
      pageSize: 10,
      timer: "",
      // 数据重复是否继续导入
      isContinue: false,
      BtnLimits: {},
    };
  },

  mounted() {
    this.autoHeight();
    this.getList();
  },
  methods: {
    RowClick(row) {
      if (!this.BtnLimits.Edit) {
        this.$refs.addDia.form = JSON.parse(JSON.stringify(row));
        this.$refs.addDia.visible = true;
      }
    },
    autoHeight() {
      // 初始化的时候，设置高度
      this.setHeight();
      // 改变浏览器窗口大小的时候，设置高度
      window.addEventListener("resize", () => {
        this.setHeight();
      });
    },
    setHeight() {
      this.$nextTick(() => {
        this.TableHeight = window.innerHeight - 235; // 这里的X就是顶部栏的高度
      });
    },

    getUnits() {
      this.timer = setInterval(this.gettime, 1000);
    },
    gettime() {
      this.BtnLimits = JSON.parse(sessionStorage.getItem("Btn"));
    },
    getList() {
      this.searchForm = {
        description: "",
        page: 1,
        limit: 10,
        key: "",
      };
      this.search();
    },
    search() {
      LoadEquiment(this.searchForm, (response) => {
        if (response && response.code == 200) {
          this.tableData = response.data;
          this.total = response.count;
        }
      });
    },
    // 分页
    handleCurrentChange(val) {
      this.searchForm.page = val;
      this.search();
    },
    handleSizeChange() {
      this.pageSize = parseInt(this.searchForm.limit);
      if (this.searchForm.limit === "") {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.setPageNum"),
          "",
          "warning"
        );
        return;
      }
      if (this.searchForm.limit == 0) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.pageThanZero"),
          "",
          "warning"
        );
        return;
      }
      this.searchForm.page = 1;
      this.search();
    },
    // 新增
    addData() {
      this.$refs.addDia.visible = true;
    },
    confirmInfo(form) {
      AddOrUpdateEquiment(form, (response) => {
        if (response && response.code == 200) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.operateSuccessfully"),
            "",
            "success"
          );
          this.$refs.addDia.close();
          this.getList();
        } else {
          commonUtil.showTipInfo(
            "Machining type data duplication",
            "",
            "warning"
          );
        }
      });
    },
    // 编辑数据
    editData() {
      if (this.chooseData.length == 0) {
        commonUtil.showTipInfo(
          this.$t("userlists.userlist.selecWant"),
          "",
          "warning"
        );
        return;
      }
      if (this.chooseData.length > 1) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.onlyOne"),
          "",
          "warning"
        );
        return;
      }
      this.$refs.addDia.form = JSON.parse(JSON.stringify(this.chooseData[0]));
      this.$refs.addDia.visible = true;
    },
    // 编辑详情数据
    editDetail(row) {
      this.$router.push({
        path: "/ctDetailList",
        name: "ctDetailList",
        query: {
          type: "edit",
          id: row.id,
        },
      });
    },
    // 删除
    deleteData() {
      if (this.chooseData.length == 0) {
        commonUtil.showTipInfo(
          this.$t("userlists.userlist.selecWant"),
          "",
          "warning"
        );
        return;
      }
      let ids = [];
      this.chooseData.forEach(function (val, index) {
        ids.push(val.id);
      });
      DeleteEquiment(ids, (response) => {
        if (response && response.code == 200) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.deleteSuccessfully"),
            "",
            "success"
          );
          this.search();
        }
      });
    },
    copyData() {
      if (this.chooseData.length == 0) {
        commonUtil.showTipInfo(
          this.$t("userlists.userlist.selecWant"),
          "",
          "warning"
        );
        return;
      }
      if (this.chooseData.length > 1) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.onlyOne"),
          "",
          "warning"
        );
        return;
      }
      let copyD = JSON.parse(JSON.stringify(this.chooseData[0]));
      copyD.copyId = copyD.id;
      copyD.id = "";
      this.$refs.addDia.form = copyD;
      this.$refs.addDia.visible = true;
    },
    // 表格多选
    handleSelectionChange(val) {
      this.chooseData = val;
    },
    // 跳转对应tool页面
    toTool() {
      if (this.chooseData.length == 0) {
        commonUtil.showTipInfo(
          this.$t("userlists.userlist.selecWant"),
          "",
          "warning"
        );
        return;
      }
      if (this.chooseData.length > 1) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.onlyOne"),
          "",
          "warning"
        );
        return;
      }
      this.$router.push({
        path: "/toolList",
        name: "toolList",
        query: {
          id: this.chooseData[0].id,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  width: 100%;
  height: 100%;
  background: url("../../../assets/img/car-table-bg.png") no-repeat center;
  background-size: contain;
}
.main-top_handle {
  display: flex;
  justify-content: space-between;
  height: 40px;
}
.handle-btn {
  align-items: center;
  height: 40px;
  .handle-btn-item {
    padding: 0 20px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    background: #b2bde7;
    color: #fff;
    border-radius: 25px;
    cursor: pointer;
    margin-left: 10px;
  }
}

.main-handle {
  align-items: center;
}
.main-handle-item {
  margin-right: 10px;
  flex: 1;
  display: flex;
  align-items: center;
}
.main-handle input,
.main-handle .el-select .el-input {
  width: 100%;
  border-radius: 10px;
  font-size: 14px;
}
.main-handle input::placeholder {
  color: #3e4954;
  text-align: center;
  font-size: 14px;
}
// 日期选择器
.select-box {
  position: relative;
  .select-btn {
    width: 200px;
    height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 10px;
    background: #fff;
    cursor: pointer;
    .Calendar,
    .Switch {
      font-size: 18px;
      color: #2f4cdd;
    }
  }
}
.search-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 30px;
  background: #445581;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
</style>