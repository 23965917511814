<template>
  <div class="main">
    <div class="main-top_handle">
      <div class="title">{{ $t("homePage.home.ProcessingModule") }}</div>
      <div class="main-handle flex">
        <!-- 状态 -->
        <div class="main-handle-item">
          {{ $t("templateLists.templateList.enable") }}
          <el-select
            size="small"
            clearable
            v-model="searchForm.Enable"
            :placeholder="$t('templateLists.templateList.enable')"
          >
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="$t(item.label)"
              :value="item.value"
            />
          </el-select>
        </div>
        <div class="main-handle-item">
          {{ $t("templateLists.templateList.region") }}
          <el-select
            size="small"
            clearable
            v-model="searchForm.RegionId"
            :placeholder="$t('templateLists.templateList.region')"
          >
            <el-option
              v-for="item in regionOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </div>
        <!-- 日期选择 -->
        <div class="main-handle-item" style="margin-top: 15px">
          <div class="select-box">
            <div class="select-btn">
              <el-icon class="Calendar"><Calendar /></el-icon>
              <span :style="{ color: searchForm.date ? '#606266' : '' }">{{
                searchForm.date
                  ? searchForm.date[0] + " - " + searchForm.date[1]
                  : $t("tcmdatalists.tcmdatalist.pleaseSlect")
              }}</span>
              <!-- <el-icon><ArrowDownBold /></el-icon> -->
              <el-icon v-show="!searchForm.date">
                <ArrowDownBold />
              </el-icon>
              <el-icon :size="18" v-show="searchForm.date">
                <Close />
              </el-icon>
            </div>
            <el-config-provider
              :locale="$t('homePage.home.locale') == 'locale' ? locale : ''"
            >
              <el-date-picker
                class="date-picker"
                style="
                  width: 200px;
                  height: 30px;
                  position: absolute;
                  top: 0;
                  left: 0;
                  opacity: 0;
                "
                v-model="searchForm.date"
                type="daterange"
                range-separator="To"
                :start-placeholder="
                  $t('unifiedPrompts.unifiedPrompt.startDate')
                "
                :end-placeholder="$t('unifiedPrompts.unifiedPrompt.endDate')"
                size="mini"
                value-format="YYYY-MM-DD"
                @change="changeDate"
              />
            </el-config-provider>
          </div>
        </div>
        <div class="main-handle-item" style="margin-top: 15px">
          <input
            type="text"
            v-model="searchForm.Keyword"
            :placeholder="$t('unifiedPrompts.unifiedPrompt.keyword')"
          />
        </div>
        <div class="search-btn" style="margin-top: 15px" @click="search">
          {{ $t("tcmlists.tcmlist.search") }}
        </div>
      </div>
    </div>
    <div class="handle-btn flex">
      <div
        v-if="BtnLimits.Add"
        class="handle-btn-item"
        style="margin-right: 10px"
        @click="addTemplate"
      >
        {{ $t("unifiedPrompts.unifiedPrompt.new") }}
      </div>
      <div
        v-if="BtnLimits.Enable"
        class="handle-btn-item"
        style="margin-right: 10px"
        @click="bathOperate('start')"
      >
        {{ $t("templateLists.templateList.batchEnable") }}
      </div>
      <div
        v-if="BtnLimits.Disable"
        class="handle-btn-item"
        style="margin-right: 10px"
        @click="bathOperate('stop')"
      >
        {{ $t("templateLists.templateList.batchDisabling") }}
      </div>
      <div
        v-if="BtnLimits.Delete"
        class="handle-btn-item"
        style="margin-right: 10px"
        @click="bathOperate('delete')"
      >
        {{ $t("unifiedPrompts.unifiedPrompt.batchDelete") }}
      </div>
      <el-upload
        ref="upload"
        class="upload-demo custom-el-upload-list-none"
        action="/"
        :on-change="importData"
        :auto-upload="false"
      >
        <template #trigger>
          <div
            v-if="BtnLimits.Import"
            class="handle-btn-item"
            style="margin-right: 10px"
          >
            {{ $t("ccls.ccl.import") }}
          </div>
        </template>
      </el-upload>
      <div
        v-if="BtnLimits.Export"
        class="handle-btn-item"
        style="margin-right: 10px"
        @click="exportData"
      >
        {{ $t("ccls.ccl.export") }}
      </div>
      <div
        v-if="BtnLimits.StandardFormat"
        class="handle-btn-item"
        @click="downTemplate"
      >
        {{ $t("unifiedPrompts.unifiedPrompt.downTemplate") }}
      </div>

      <!-- StandardFormat -->
      <!-- <div class="operate-btn" style="margin-right: 10px;" @click="bathOperate('delete')">批量删除</div> -->
      <div
        class="handle-btn-item"
        style="margin-left: 10px"
        v-if="BtnLimits.ChangeCommodity"
        @click="ChangeCommodityBtn"
      >
        {{ $t("templateLists.templateList.ChangeCommodity") }}
      </div>
      <div style="padding-left: 20px; text-align: right">
        <span style="line-height: 32px; margin-right: 10px; font-weight: 600">{{
          $t("templateLists.templateList.revisions")
        }}</span>
        <el-switch
          v-model="historyVal"
          class="ml-2"
          inline-prompt
          style="--el-switch-on-color: #b2bde7"
          active-text="Y"
          inactive-text="N"
        />
      </div>
    </div>
    <el-row :gutter="10">
      <el-col
        :span="4"
        style="
          width: 250px;
          min-width: 250px;
          max-width: 250px;
          background-color: #fff;
        "
      >
        <div class="tree-cla">
          <div class="tree-cla-search">
            <input
              v-model="filterText"
              :placeholder="$t('unifiedPrompts.unifiedPrompt.keyword')"
            />
          </div>
          <div :style="{ height: TableHeight + 'px' }" class="tree-box">
            <el-tree
              ref="treeRef"
              class="filter-tree custom-el-tree"
              :data="treedata"
              :props="defaultProps"
              default-expand-all
              :filter-node-method="filterNode"
              :highlight-current="true"
              @node-click="handleNodeClick"
            >
              <template #default="{ node, data }">
                <span class="custom-tree-node">
                  <span class="my-text" :title="node.label">{{
                    node.label
                  }}</span>
                  <span class="my-badge">{{ data.Quantity }}</span>
                </span>
              </template>
            </el-tree>
          </div>
        </div>
      </el-col>
      <el-col :span="20" style="max-width: calc(100% - 250px)">
        <div class="regional_table">
          <el-table
            :empty-text="$t('tcmlists.tcmlist.noData')"
            :data="tableData"
            border
            stripe
            @row-click="RowClick"
            style="width: 100%"
            :height="TableHeight"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              type="selection"
              width="55"
              align="center"
            ></el-table-column>

            <el-table-column
              prop="number"
              :label="$t('templateLists.templateList.cellNo')"
              width="180"
              show-overflow-tooltip
              align="center"
            />
            <el-table-column
              prop="name"
              :label="$t('templateLists.templateList.cellName')"
              show-overflow-tooltip
              align="center"
            />
            <el-table-column
              prop="vendor"
              :label="$t('changePriceLists.changePriceList.vendor')"
              show-overflow-tooltip
              align="center"
            />
            <el-table-column
              prop="description"
              :label="$t('templateLists.templateList.operationDescription')"
              show-overflow-tooltip
              align="center"
              width="130"
            />
            <el-table-column
              prop="regionName"
              :label="$t('templateLists.templateList.region')"
              show-overflow-tooltip
              align="center"
            />
            <el-table-column
              prop="currencyName"
              :label="$t('changePriceLists.changePriceList.currency')"
              width="90"
              show-overflow-tooltip
              align="center"
            />
            <el-table-column
              prop="commodityName"
              :label="$t('tcmdatalists.tcmdatalist.commodity')"
              show-overflow-tooltip
              align="center"
            />
            <el-table-column
              prop="version"
              :label="$t('templateLists.templateList.version')"
              show-overflow-tooltip
              align="center"
            />
            <el-table-column
              prop="comment"
              :label="$t('templateLists.templateList.comments')"
              width="110"
              show-overflow-tooltip
              align="center"
            />
            <el-table-column
              prop="creator"
              :label="$t('templateLists.templateList.creator')"
              width="90"
              show-overflow-tooltip
              align="center"
            />
            <el-table-column
              prop="date"
              :label="$t('templateLists.templateList.date')"
              width="110"
              show-overflow-tooltip
              align="center"
            />
            <el-table-column
              prop="enable"
              :label="$t('templateLists.templateList.enable')"
              width="80"
              show-overflow-tooltip
              align="center"
            >
              <template #default="scope">
                <el-icon
                  :size="16"
                  color="#40D4A8"
                  v-show="scope.row.isEnable == '1'"
                >
                  <SuccessFilled />
                </el-icon>
                <el-icon
                  :size="16"
                  color="#F56C6C"
                  v-show="scope.row.isEnable == '2'"
                >
                  <CircleCloseFilled />
                </el-icon>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('templateLists.templateList.operate')"
              width="80"
              fixed="right"
              class-name="lastTd"
              align="center"
            >
              <template #default="scope">
                <el-popover placement="bottom" trigger="hover">
                  <template #reference>
                    <div style="text-align: center">
                      <el-icon><MoreFilled /></el-icon>
                    </div>
                  </template>
                  <div class="action-box">
                    <div
                      v-if="BtnLimits.Detail"
                      class="action-btn"
                      @click="previewTemplate(scope.row)"
                    >
                      <el-icon><View /></el-icon>
                      <span>{{
                        $t("unifiedPrompts.unifiedPrompt.preview")
                      }}</span>
                    </div>
                    <div
                      class="action-btn"
                      v-if="
                        scope.row.isrelevance &&
                        scope.row.isrelevance == 1 &&
                        BtnLimits.Edit
                      "
                      @click="editTemplate(scope.row)"
                    >
                      <el-icon><Edit /></el-icon>
                      <span>
                        {{ $t("unifiedPrompts.unifiedPrompt.edit") }}</span
                      >
                    </div>
                  </div>
                </el-popover>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination">
          <div>
            {{ $t("homePage.home.Showing") }} {{ pageSize }}
            {{ $t("homePage.home.From") }} {{ total }}
            {{ $t("homePage.home.Data") }}
            <el-popover placement="top" :width="70" trigger="click">
              <template #reference>
                <el-icon
                  style="
                    margin-left: 5px;
                    font-size: 17px;
                    vertical-align: middle;
                  "
                  ><Operation
                /></el-icon>
              </template>
              <el-input
                v-model="searchForm.limit"
                @input="(val) => (searchForm.limit = val.replace(/[^\d]/g, ''))"
                :min="1"
                style="width: 130px"
                @change="handleSizeChange"
              >
                <template #suffix> {{ $t("ccls.ccl.barPage") }}</template>
              </el-input>
            </el-popover>
          </div>
          <el-pagination
            layout="prev, pager, next"
            background
            :total="total"
            :page-size="pageSize"
            :current-page="searchForm.page"
            @current-change="handleCurrentChange"
          />
        </div>
      </el-col>
    </el-row>
    <ChangeCommodity
      :treeData="treedata"
      @ModifyCommodity="ModifyCommodityFun"
      ref="ChangeCommodity"
    ></ChangeCommodity>
  </div>
</template>
<script>
import {
  GetOrgs,
  GetTemplateProcesss,
  deleteTemplateProcesss,
  startTemplateProcesss,
  stopTemplateProcesss,
  ImportTemplateMaterialExcel,
  getRegions,
  ExportDataTableExcel,
  GetDataIsDuplicate,
  ExportTemplateProcess,
  DownloadTemplateExcel,
  ModifyProcesssCommodityByIds,
} from "../../assets/js/requestApi.js";
import commonUtil from "../../assets/js/commonUtil.js";
import "../../assets/css/custom-el-tree.css";
import { ElMessageBox } from "element-plus";
import ChangeCommodity from "../../components/comments/ChangeCommodity.vue";
import zhCn from "element-plus/dist/locale/zh-cn.mjs";

export default {
  name: "ProList",
  components: {
    ChangeCommodity,
  },
  activated() {
    // this.autoHeight();
    // 在首次挂载、
    // 以及每次从缓存中被重新插入的时候调用
    this.search();
    this.getUnits();
  },
  deactivated() {
    clearTimeout(this.timer);
    // 在从 DOM 上移除、进入缓存
    // 以及组件卸载时调用
  },
  data() {
    return {
      locale: zhCn,
      TableHeight: 0,
      searchForm: {
        date: "",
        Enable: 0,
        StartDate: "",
        EndDate: "",
        status: "",
        Keyword: "",
        CommodityId: "",
        currencyId: "",
        VersionType: 1,
        RegionId: "",
        page: 1,
        limit: 10,
      },
      // 总页数
      total: 0,
      pageSize: 10,
      // status选项
      statusOptions: [
        {
          value: 0,
          label: "other.other.All",
        },
        {
          value: 1,
          label: "Enable",
          label: "other.other.Enable",
        },
        {
          value: 2,
          label: "Disable",
          label: "other.other.Disable",
        },
      ],
      filterText: "",
      // 商品分类数据
      treedata: [],
      defaultProps: {
        children: "children",
        label: "Name",
      },
      // 表格数据
      tableData: [],
      // 已选择的商品分类名称
      CommodityName: "",
      // 已勾选数据
      chooseData: [],
      // 是否为历史版本
      historyVal: false,
      regionOptions: [],
      timer: "",
      BtnLimits: {},
    };
  },
  watch: {
    filterText(val) {
      this.$refs.treeRef.filter(val);
    },
    historyVal(val) {
      if (val) {
        this.searchForm.page = 1;
        this.searchForm.VersionType = 2;
        this.search();
      } else {
        this.searchForm.page = 1;
        this.searchForm.VersionType = 1;
        this.search();
      }
    },
  },
  mounted() {
    this.autoHeight();
    this.getlist();
    this.getTreeData();
    // this.getRegion();
    this.regionOptions = JSON.parse(sessionStorage.getItem("Region"));
  },
  methods: {
    RowClick(row) {
      if (this.BtnLimits.Detail) {
        this.previewTemplate(row);
      }
    },
    autoHeight() {
      // 初始化的时候，设置高度
      this.setHeight();
      // 改变浏览器窗口大小的时候，设置高度
      window.addEventListener("resize", () => {
        this.setHeight();
      });
    },
    setHeight() {
      this.$nextTick(() => {
        this.TableHeight = window.innerHeight - 235; // 这里的X就是顶部栏的高度
      });
    },
    ModifyCommodityFun(CheckId) {
      let ids = [];
      this.chooseData.forEach(function (val, key) {
        ids.push(val.id);
      });
      let params = {
        ids: ids,
        commodityId: CheckId,
      };
      ModifyProcesssCommodityByIds(params, (response) => {
        if (response && response.code == 200) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.operateSuccessfully"),
            "",
            "success"
          );
          this.search();
        }
      });
    },
    ChangeCommodityBtn() {
      if (this.chooseData.length == 0) {
        commonUtil.showTipInfo(
          this.$t("userlists.userlist.selecWant"),
          "",
          "warning"
        );
        return;
      }
      this.$refs.ChangeCommodity.visible = true;
    },
    getUnits() {
      this.timer = setInterval(this.gettime, 1000);
    },
    gettime() {
      this.BtnLimits = JSON.parse(sessionStorage.getItem("Btn"));
    },
    getlist() {
      this.searchForm = {
        date: "",
        Enable: 0,
        StartDate: "",
        EndDate: "",
        status: "",
        Keyword: "",
        CommodityId: "",
        currencyId: "",
        VersionType: 1,
        RegionId: "",
        page: 1,
        limit: 10,
      };
      this.search();
    },
    // 查询
    search() {
      GetTemplateProcesss(this.searchForm, (response) => {
        if (response && response.code == 200) {
          this.tableData = response.data;
          this.total = response.count;
        } else {
          commonUtil.showTipInfo(this.$t("userlists.userlist.inqueryTimeout"));
        }
      });
    },
    // 分页
    handleCurrentChange(val) {
      this.searchForm.page = val;
      this.search();
    },
    handleSizeChange() {
      this.pageSize = parseInt(this.searchForm.limit);
      if (this.searchForm.limit === "") {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.setPageNum"),
          "",
          "warning"
        );

        return;
      }
      if (this.searchForm.limit == 0) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.pageThanZero"),
          "",
          "warning"
        );

        return;
      }
      this.searchForm.page = 1;
      this.search();
    },
    // 新增
    addTemplate() {
      this.$router.push({
        path: "/addPro",
        name: "AddPro",
        query: {
          type: "add",
          CommodityId: this.searchForm.CommodityId,
          CommodityName: this.CommodityName,
        },
      });
    },
    // 编辑
    editTemplate(row) {
      this.$router.push({
        path: "/addPro",
        name: "AddPro",
        query: {
          type: "edit",
          CommodityName: row.commodityName,
          id: row.id,
        },
      });
    },
    // 获取组织树数据
    getTreeData() {
      let _this = this;
      GetOrgs(
        {
          type: 3,
          versionType: this.searchForm.VersionType,
          TypeCost: 0,
          isAll: 1,
        },
        (response) => {
          if (response && response.Code == 200) {
            let data = response.Result;
            data.forEach(function (val, key) {
              val.disabled = val.isEnable == 1 ? false : true;
            });
            this.treedata = _this.transferList(response.Result);
          }
        }
      );
    },
    // 获取区域列表选项
    // getRegion() {
    //   getRegions(
    //     {
    //       Keyword: "",
    //       isEnable: 1,
    //       page: 1,
    //       limit: 9999,
    //       key: "",
    //     },
    //     (response) => {
    //       if (response && response.code == 200) {
    //         this.regionOptions = response.data;
    //       }
    //     }
    //   );
    // },
    filterNode(value, data) {
      if (!value) return true;
      return data.Name.indexOf(value) !== -1;
    },
    // 树点击事件
    handleNodeClick(data) {
      this.searchForm.CommodityId = "";
      this.CommodityName = "";
      if (data.disabled) {
        commonUtil.showTipInfo("当前商品分类已禁用！", "", "warning");
        return;
      }
      this.searchForm.CommodityId = data.Id;
      this.CommodityName = data.Name;
      this.search();
    },
    // 转化树数据
    transferList(data) {
      const res = [];
      data.forEach((item) => {
        const parent = data.find((node) => node.Id === item.ParentId);
        if (parent) {
          parent.children = parent.children || [];
          parent.children.push(item);
        } else {
          // * 根节点
          res.push(item);
        }
      });
      return res;
    },
    // 切换时间
    changeDate(val) {
      if (val != null) {
        this.searchForm.StartDate = val[0];
        this.searchForm.EndDate = val[1];
      } else {
        this.searchForm.StartDate = "";
        this.searchForm.EndDate = "";
      }
    },
    // 查看详情
    previewTemplate(row) {
      this.$router.push({
        path: "/addPro",
        name: "AddPro",
        query: {
          type: "detail",
          CommodityName: row.commodityName,
          id: row.id,
        },
      });
    },
    // 删除
    deleteTemplate(id) {
      let ids = [];
      ids.push(id);
      this.deleteTem(ids);
    },
    // 调用删除接口
    deleteTem(ids) {
      deleteTemplateProcesss(ids, (response) => {
        if (response && response.code == 200) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.deleteSuccessfully"),
            "",
            "success"
          );
          this.search();
        }
      });
    },
    // 表格多选
    handleSelectionChange(val) {
      this.chooseData = val;
    },
    // 批量操作
    bathOperate(type) {
      if (this.chooseData.length == 0) {
        commonUtil.showTipInfo(
          this.$t("userlists.userlist.selecWant"),
          "",
          "warning"
        );
        return;
      }
      let ids = [];
      this.chooseData.forEach(function (val, key) {
        ids.push(val.id);
      });
      if (type == "delete") {
        this.deleteTem(ids);
      } else if (type == "start") {
        // 启用
        startTemplateProcesss(ids, (response) => {
          commonUtil.showTipInfo(
            this.$t("ccls.ccl.enableSuccess"),
            "",
            "success"
          );
          this.search();
        });
      } else if (type == "stop") {
        // 禁用
        stopTemplateProcesss(ids, (response) => {
          commonUtil.showTipInfo(
            this.$t("ccls.ccl.disableSuccess"),
            "",
            "success"
          );
          this.search();
        });
      } else if (type == "delete") {
        this.deleteTem(ids);
      }
    },
    // 导入
    // importData(files) {
    //     console.log(files);
    //     let format = new FormData();
    //     format.append("file", files.raw);
    //     format.append("Type", 3);
    //     ImportTemplateMaterialExcel(
    //         format,
    //         (response) => {
    //             if (response && response.code == 200) {
    //                 this.getlist();
    //                 this.$refs.upload.clearFiles();
    //                 commonUtil.showTipInfo(
    //                     this.$t(
    //                         "unifiedPrompts.unifiedPrompt.importSuccessfully"
    //                     ),
    //                     "",
    //                     "success"
    //                 );
    //             }
    //         },
    //         (errRes) => {
    //             console.log(errRes);
    //             if (errRes && errRes.code == 500) {
    //                 ExportDataTableExcel('"' + errRes.data + '"', (res) => {
    //                     console.log(res);
    //                 });
    //             } else {
    //                 commonUtil.showTipInfo(
    //                     this.$t("unifiedPrompts.unifiedPrompt.importFail"),
    //                     "",
    //                     "error"
    //                 );
    //             }
    //             this.getlist();
    //             this.$refs.upload.clearFiles();
    //         }
    //     );
    // },
    // 导入
    importExcel(format) {
      ImportTemplateMaterialExcel(
        format,
        (response) => {
          if (response && response.code == 200) {
            this.getlist();
            this.$refs.upload.clearFiles();
            commonUtil.showTipInfo(
              this.$t("unifiedPrompts.unifiedPrompt.importSuccessfully"),
              "",
              "success"
            );
          }
        },
        (errRes) => {
          if (errRes && errRes.code == 500) {
            ExportDataTableExcel('"' + errRes.data + '"', (res) => {});
          } else {
            commonUtil.showTipInfo(
              this.$t("unifiedPrompts.unifiedPrompt.importFail"),
              "",
              "error"
            );
          }
          this.getlist();
          this.$refs.upload.clearFiles();
        }
      );
    },
    // 导入
    importData(files) {
      let format = new FormData();
      format.append("file", files.raw);
      format.append("Type", 3);
      let excelFormat = new FormData();
      excelFormat.append("file", files.raw);
      excelFormat.append("Type", 3);
      GetDataIsDuplicate(format, (response) => {
        if (response && response.code == 200) {
          // if (response.data) {
          if (response.data == "") {
            excelFormat.append("DataIsDuplicate", "");
            this.importExcel(excelFormat);
          } else {
            // 有重复数据
            ElMessageBox.confirm(response.data + "数据重复，是否继续导入？")
              .then(() => {
                excelFormat.append("DataIsDuplicate", "");
                this.isContinue = true;
                this.importExcel(excelFormat);
                done();
              })
              .catch(() => {
                if (!this.isContinue) {
                  excelFormat.append("DataIsDuplicate", response.data);
                  this.importExcel(excelFormat);
                }
                this.isContinue = false;
                // catch error
              });
          }
          // } else {
          // 	commonUtil.showTipInfo(response.message,"", "error");
          // 	this.getList()
          // 	this.$refs.upload.clearFiles()
          // }
        }
      });
    },
    // 导出(改版)
    exportData() {
      ExportTemplateProcess(this.searchForm, (response) => {});
    },
    // 下载模板
    downTemplate() {
      DownloadTemplateExcel(2, (response) => {});
    },
  },
};
</script>


<style scoped lang="scss">
.main {
  width: 100%;
  height: 100%;
  background: url("../../assets/img/car-table-bg.png") no-repeat center;
  background-size: contain;
}

.main-top_handle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
}
.tree-cla {
  background: #fff;
  .tree-cla-search {
    padding: 10px;
  }
  input {
    display: block;
    width: calc(100% - 10px);
    height: 40px;
    border-radius: 10px;
    background: #f5f7ff;
  }
}
.main-handle {
  align-items: center;
}
.main-handle-item {
  margin-right: 10px;
  flex: 1;
}
.main-handle input,
.main-handle .el-select .el-input {
  width: 100%;
  border-radius: 10px;
  font-size: 14px;
}
.main-handle input::placeholder {
  color: #3e4954;
  text-align: center;
  font-size: 14px;
}

.handle-btn {
  align-items: center;
  height: 40px;
  .handle-btn-item {
    padding: 0 20px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    background: #b2bde7;
    color: #fff;
    border-radius: 25px;
    cursor: pointer;
    margin-left: 10px;
  }
}
// 日期选择器
.select-box {
  position: relative;
  .select-btn {
    width: 200px;
    height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 10px;
    background: #fff;
    cursor: pointer;
    .Calendar,
    .Switch {
      font-size: 18px;
      color: #2f4cdd;
    }
  }
}
.search-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 30px;
  background: #445581;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.my-badge {
  color: #ff6d4d;
  background: #ffebe7;
  padding: 3px 8px;
  font-size: 12px;
  line-height: 12px;
  border-radius: 20px;
  text-align: center;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  width: 100%;
}
.tree-box {
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>
