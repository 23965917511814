<template>
  <div class="tcm-compare">
    <div class="top-box">
      <div class="crumbs-box">
        <div class="crumbs-box-item parent" @click="Goback">
          {{ $t("homePage.home.Back") }}
        </div>
        <div class="line"></div>
        <div class="crumbs-box-item" v-for="(item, index) in crumbs">
          {{ $t(item) }}
          <el-icon v-show="index != crumbs.length - 1"><ArrowRight /></el-icon>
        </div>
      </div>
    </div>

    <div class="tcm-title">
      <div class="line-45">
        {{ $t("unifiedPrompts.unifiedPrompt.hideTheSame") }}
      </div>
      <div class="line-45 margin-10">
        <el-switch v-model="hideSame" style="--el-switch-on-color: #335883" />
      </div>
      <div style="padding-top: 5px; margin: 0 10px">
        <img
          src="../../assets/img/tcm/barChart.svg"
          @click="showChart"
          style="width: 35px; height: 35px; cursor: pointer"
        />
      </div>
      <div>
        <el-tag
          v-for="(tag, index) in compareData"
          :key="tag.id"
          closable
          type="info"
          style="height: 45px; margin: 0 5px"
          @close="handleClose(index)"
        >
          <div>#{{ tag.documentNo }}</div>
          <div>Date:{{ tag.doDate }}</div>
        </el-tag>
      </div>
      <div
        v-for="(tag, index) in addListNum"
        class="add-cla"
        @click="addCompareData"
      >
        +
      </div>
      <div class="export-btn">
        <el-button
          style="
            background-color: var(--themeColor);
            color: var(--menuTextActive);
          "
          @click="toExport"
          >{{ $t("changePriceLists.changePriceList.exportPdf") }}</el-button
        >
      </div>
    </div>

    <div class="tcm-compare-cla" id="exportPdf">
      <div class="tcm-content-title">
        {{ $t("unifiedPrompts.unifiedPrompt.forexInformation") }}
      </div>
      <div class="tcm-content-form">
        <table>
          <tr>
            <td class="td-title-width">
              {{ $t("userlists.userlist.currency") }}
            </td>
            <td class="td-width-nobg" v-for="(item, index) in currencyInfo">
              <el-select
                clearable
                v-model="item.Currency"
                size="small"
                filterable
                @change="currencyChange(index)"
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                style="width: 100%"
              >
                <el-option
                  v-for="it in genCurrOptions[index].currencyOptions"
                  :key="it.currency"
                  :label="it.currencyName"
                  :value="it.currency"
                />
              </el-select>
            </td>
          </tr>
          <tr>
            <td class="td-title-width">
              {{ $t("userlists.userlist.reportCurrency") }}
            </td>
            <td class="td-width-nobg" v-for="(item, index) in currencyInfo">
              <el-select
                clearable
                v-model="item.ReportCurrency"
                size="small"
                filterable
                @change="reportCurrencyChange(index)"
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                style="width: 100%"
              >
                <el-option
                  v-for="it in genCurrOptions[index].currencyReportOptions"
                  :key="it.currencyCNYReportCurrency"
                  :label="it.currencyCNYReportCurrencyName"
                  :value="it.currencyCNYReportCurrency"
                />
              </el-select>
            </td>
          </tr>
          <tr>
            <td class="td-title-width">
              {{ $t("userlists.userlist.exchangeRate") }}
            </td>
            <td class="td-width-nobg" v-for="(item, index) in currencyInfo">
              <el-input
                v-model="item.rate"
                size="small"
                @change="changeRate(index)"
              />
            </td>
          </tr>
        </table>
      </div>
      <div class="tcm-content-title">
        A) {{ $t("changePriceLists.changePriceList.generalInformation") }}
      </div>
      <div class="tcm-content-form">
        <table>
          <tr>
            <td class="td-title-width">
              {{ $t("tcmlists.tcmlist.documentNO") }}
            </td>
            <td class="td-width" v-for="(tag, index) in compareData">
              {{ tag.documentNo }}
            </td>
          </tr>
          <tr v-show="!hideSame || !(genInfo.quoteTypeIdIssample && hideSame)">
            <td class="td-title-width">
              {{ $t("userlists.userlist.quoteType") }}
            </td>
            <td class="td-width" v-for="item in genInfo.arr">
              {{ item.quoteTypeName }}
            </td>
          </tr>
          <tr v-show="!hideSame || !(genInfo.commodityIdIssample && hideSame)">
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.commodity") }}
            </td>
            <td class="td-width" v-for="item in genInfo.arr">
              {{ item.commodityName }}
            </td>
          </tr>
          <tr v-show="!hideSame || !(genInfo.programNameIssample && hideSame)">
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.programName") }}
            </td>
            <td class="td-width" v-for="item in genInfo.arr">
              {{ item.programName }}
            </td>
          </tr>
          <tr v-show="!hideSame || !(genInfo.ecrnoIssample && hideSame)">
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.ecrNo") }}
            </td>
            <td class="td-width" v-for="item in genInfo.arr">
              {{ item.ecrno }}
            </td>
          </tr>
          <tr
            v-show="!hideSame || !(genInfo.partDescriptionIssample && hideSame)"
          >
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.partDescription") }}
            </td>
            <td class="td-width" v-for="item in genInfo.arr">
              {{ item.partDescription }}
            </td>
          </tr>
          <tr v-show="!hideSame || !(genInfo.partNumberIssample && hideSame)">
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.partNumber") }}
            </td>
            <td class="td-width" v-for="item in genInfo.arr">
              {{ item.partNumber }}
            </td>
          </tr>
          <tr v-show="!hideSame || !(genInfo.partRevisionIssample && hideSame)">
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.partRevision") }}
            </td>
            <td class="td-width" v-for="item in genInfo.arr">
              {{ item.partRevision }}
            </td>
          </tr>
          <tr v-show="!hideSame || !(forexInfo.currencyIssample && hideSame)">
            <td
              :class="
                !forexInfo.currencyIssample
                  ? 'color-red td-title-width'
                  : 'td-title-width'
              "
            >
              {{ $t("userlists.userlist.currency") }}
            </td>
            <td class="td-width" v-for="item in forexInfo.arr">
              {{ item.currency }}
            </td>
          </tr>
          <tr
            v-show="
              !hideSame || !(forexInfo.reportCurrencyIssample && hideSame)
            "
          >
            <td
              :class="
                !forexInfo.reportCurrencyIssample
                  ? 'color-red td-title-width'
                  : 'td-title-width'
              "
            >
              {{ $t("userlists.userlist.reportCurrency") }}
            </td>
            <td class="td-width" v-for="item in forexInfo.arr">
              {{ item.reportCurrency }}
            </td>
          </tr>
          <tr v-show="!hideSame || !(forexInfo.rateIssample && hideSame)">
            <td
              :class="
                !forexInfo.rateIssample
                  ? 'color-red td-title-width'
                  : 'td-title-width'
              "
            >
              {{ $t("userlists.userlist.exchangeRate") }}
            </td>
            <td class="td-width" v-for="item in forexInfo.arr">
              {{ item.exchangeRate }}
            </td>
          </tr>
          <tr v-show="!hideSame || !(genInfo.regionIdIssample && hideSame)">
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.region") }}
            </td>
            <td class="td-width" v-for="item in genInfo.arr">
              {{ item.regionName }}
            </td>
          </tr>
          <tr v-show="!hideSame || !(genInfo.supplierNameIssample && hideSame)">
            <td
              :class="
                !genInfo.supplierNameIssample
                  ? 'color-red td-title-width'
                  : 'td-title-width'
              "
            >
              {{ $t("changePriceLists.changePriceList.supplierName") }}
            </td>
            <td class="td-width" v-for="item in genInfo.arr">
              {{ item.supplierName }}
            </td>
          </tr>
          <tr
            v-show="
              !hideSame || !(genInfo.materialDescriptionIssample && hideSame)
            "
          >
            <td
              :class="
                !genInfo.materialDescriptionIssample
                  ? 'color-red td-title-width'
                  : 'td-title-width'
              "
            >
              {{ $t("changePriceLists.changePriceList.materialDescription") }}
            </td>
            <td class="td-width" v-for="item in genInfo.arr">
              {{ item.materialDescription }}
            </td>
          </tr>
          <tr v-show="!hideSame || !(genInfo.peakVolumeIssample && hideSame)">
            <td
              :class="
                !genInfo.peakVolumeIssample
                  ? 'color-red td-title-width'
                  : 'td-title-width'
              "
            >
              {{ $t("changePriceLists.changePriceList.peakVolume") }}
            </td>
            <td class="td-width" v-for="item in genInfo.arr">
              {{ item.peakVolume }}
            </td>
          </tr>
          <tr
            v-show="!hideSame || !(genInfo.lifetimeVolumeIssample && hideSame)"
          >
            <td
              :class="
                !genInfo.lifetimeVolumeIssample
                  ? 'color-red td-title-width'
                  : 'td-title-width'
              "
            >
              {{ $t("changePriceLists.changePriceList.lifetimeVolume") }}
            </td>
            <td class="td-width" v-for="item in genInfo.arr">
              {{ item.lifetimeVolume }}
            </td>
          </tr>
          <tr v-show="!hideSame || !(genInfo.pegPointIssample && hideSame)">
            <td
              :class="
                !genInfo.pegPointIssample
                  ? 'color-red td-title-width'
                  : 'td-title-width'
              "
            >
              {{ $t("changePriceLists.changePriceList.pegPoint") }}
            </td>
            <td class="td-width" v-for="item in genInfo.arr">
              {{ item.pegPoint }}
            </td>
          </tr>
          <tr v-show="!hideSame || !(genInfo.lotSizeIssample && hideSame)">
            <td
              :class="
                !genInfo.lotSizeIssample
                  ? 'color-red td-title-width'
                  : 'td-title-width'
              "
            >
              {{ $t("changePriceLists.changePriceList.productionBatch") }}
            </td>
            <td class="td-width" v-for="item in genInfo.arr">
              {{ item.lotSize }}
            </td>
          </tr>
          <tr
            :class="!genInfo.productionYearIssample ? 'color-red' : ''"
            v-show="!hideSame || !(genInfo.productionYearIssample && hideSame)"
          >
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.annualProduction") }}
            </td>
            <td class="td-width" v-for="item in genInfo.arr">
              {{ item.productionYear }}
            </td>
          </tr>
        </table>
      </div>
      <div class="tcm-content-title">
        {{ $t("unifiedPrompts.unifiedPrompt.bRawMateria") }}
      </div>
      <div class="tcm-content-form" v-for="(rawItem, rawIndex) in rawInfo">
        <table>
          <tr v-show="!hideSame || !(rawItem.descriptionIssample && hideSame)">
            <td
              :class="
                !rawItem.descriptionIssample
                  ? 'color-red td-title-width'
                  : 'td-title-width'
              "
            >
              {{ $t("changePriceLists.changePriceList.materialDescription") }}
            </td>
            <td class="td-width" v-for="item in rawItem.arr">
              {{ item.description }}
            </td>
          </tr>
          <tr v-show="!hideSame || !(rawItem.netMWeightIssample && hideSame)">
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.netWeight") }}
            </td>
            <td
              :class="
                !rawItem.netMWeightIssample ? 'color-red td-width' : 'td-width'
              "
              v-for="item in rawItem.arr"
            >
              {{ item.netMWeight }}
            </td>
          </tr>
          <tr v-show="!hideSame || !(rawItem.grossMWeightIssample && hideSame)">
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.grossWeight") }}
            </td>
            <td
              :class="
                !rawItem.grossMWeightIssample
                  ? 'color-red td-width'
                  : 'td-width'
              "
              v-for="item in rawItem.arr"
            >
              {{ item.grossMWeight }}
            </td>
          </tr>
          <tr
            v-show="!hideSame || !(rawItem.unitMeasureIdIssample && hideSame)"
          >
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.unitMeasure") }}
            </td>
            <td class="td-width" v-for="item in rawItem.arr">
              {{ item.unitMeasureName }}
            </td>
          </tr>
          <tr v-show="!hideSame || !(rawItem.unitCostIssample && hideSame)">
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.materialMeasure") }}
            </td>
            <td
              :class="
                !rawItem.unitCostIssample ? 'color-red td-width' : 'td-width'
              "
              v-for="item in rawItem.arr"
            >
              {{ item.unitCost }}
            </td>
          </tr>

          <tr
            v-show="!hideSame || !(rawItem.unitResaleValueIssample && hideSame)"
          >
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.scrapPrice") }}
            </td>
            <td class="td-width" v-for="item in rawItem.arr">
              {{ item.unitResaleValue }}
            </td>
          </tr>
          <tr v-show="!hideSame || !(rawItem.countryIssample && hideSame)">
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.country") }}
            </td>
            <td class="td-width" v-for="item in rawItem.arr">
              {{ item.country }}
            </td>
          </tr>
          <tr v-show="!hideSame || !(rawItem.dateIssample && hideSame)">
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.date") }}
            </td>
            <td class="td-width" v-for="item in rawItem.arr">
              {{ item.date }}
            </td>
          </tr>
          <tr v-show="!hideSame || !(rawItem.costIssample && hideSame)">
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.materialCost") }}
            </td>
            <td
              :class="!rawItem.costIssample ? 'color-red td-width' : 'td-width'"
              v-for="item in rawItem.arr"
            >
              {{ item.cost }}
            </td>
          </tr>
        </table>
      </div>
      <div class="tcm-content-title">
        {{ $t("unifiedPrompts.unifiedPrompt.cProcessing") }}
      </div>
      <div class="tcm-content-form" v-for="(proItem, porIndex) in proInfo">
        <table>
          <div class="form-type-title">
            {{ $t("changePriceLists.changePriceList.setUp") }}
            {{ porIndex + 1 }}
          </div>
          <tr v-show="!hideSame || !(proItem.annualVolumeIssample && hideSame)">
            <td
              :class="
                !proItem.annualVolumeIssample
                  ? 'color-red td-title-width'
                  : 'td-title-width'
              "
            >
              {{ $t("changePriceLists.changePriceList.annualVolume") }}
            </td>
            <td class="td-width" v-for="item in proItem.arr">
              {{ item.annualVolume }}
            </td>
          </tr>
          <tr v-show="!hideSame || !(proItem.setTimeIssample && hideSame)">
            <td
              :class="
                !proItem.setTimeIssample
                  ? 'color-red td-title-width'
                  : 'td-title-width'
              "
            >
              {{ $t("changePriceLists.changePriceList.setUpTime") }}
            </td>
            <td class="td-width" v-for="item in proItem.arr">
              {{ item.setTime }}
            </td>
          </tr>
          <tr v-show="!hideSame || !(proItem.setPersonIssample && hideSame)">
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.setUpPersons") }}
            </td>
            <td class="td-width" v-for="item in proItem.arr">
              {{ item.setPerson }}
            </td>
          </tr>
          <tr v-show="!hideSame || !(proItem.pieceLotIssample && hideSame)">
            <td
              :class="
                !proItem.pieceLotIssample
                  ? 'color-red td-title-width'
                  : 'td-title-width'
              "
            >
              {{ $t("changePriceLists.changePriceList.piecesPerLot") }}
            </td>
            <td class="td-width" v-for="item in proItem.arr">
              {{ item.pieceLot }}
            </td>
          </tr>
          <tr v-show="!hideSame || !(proItem.pieceCostIssample && hideSame)">
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.setUpCosts") }}
            </td>
            <td
              :class="
                !proItem.pieceCostIssample ? 'color-red td-width' : 'td-width'
              "
              v-for="item in proItem.arr"
            >
              {{ item.pieceCost }}
            </td>
          </tr>

          <div class="form-type-title">
            {{ $t("changePriceLists.changePriceList.operation") }} #
            {{ porIndex + 1 }}
          </div>

          <tr v-show="!hideSame || !(proItem.nameIssample && hideSame)">
            <td
              :class="
                !proItem.nameIssample
                  ? 'color-red td-title-width'
                  : 'td-title-width'
              "
            >
              {{ $t("changePriceLists.changePriceList.operationDescription") }}
            </td>
            <td class="td-width" v-for="item in proItem.arr">
              {{ item.name }}
            </td>
          </tr>
          <tr v-show="!hideSame || !(proItem.equSizeIssample && hideSame)">
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.descriptionSize") }}
            </td>
            <td class="td-width" v-for="item in proItem.arr">
              {{ item.equSize }}
            </td>
          </tr>
          <tr v-show="!hideSame || !(proItem.quantityIssample && hideSame)">
            <td
              :class="
                !proItem.quantityIssample
                  ? 'color-red td-title-width'
                  : 'td-title-width'
              "
            >
              {{ $t("changePriceLists.changePriceList.consumption") }}
            </td>
            <td class="td-width" v-for="item in proItem.arr">
              {{ item.quantity }}
            </td>
          </tr>
          <tr v-show="!hideSame || !(proItem.pieceHourIssample && hideSame)">
            <td
              :class="
                !proItem.pieceHourIssample
                  ? 'color-red td-title-width'
                  : 'td-title-width'
              "
            >
              {{ $t("changePriceLists.changePriceList.piecesHour") }}
            </td>
            <td class="td-width" v-for="item in proItem.arr">
              {{ item.pieceHour }}
            </td>
          </tr>
          <tr v-show="!hideSame || !(proItem.pieceCycleIssample && hideSame)">
            <td
              :class="
                !proItem.pieceCycleIssample
                  ? 'color-red td-title-width'
                  : 'td-title-width'
              "
            >
              {{ $t("changePriceLists.changePriceList.piecesPer") }}
            </td>
            <td class="td-width" v-for="item in proItem.arr">
              {{ item.pieceCycle }}
            </td>
          </tr>
          <tr
            v-show="
              !hideSame || !(proItem.efficiencyPercentIssample && hideSame)
            "
          >
            <td
              :class="
                !proItem.efficiencyPercentIssample
                  ? 'color-red td-title-width'
                  : 'td-title-width'
              "
            >
              {{
                $t("changePriceLists.changePriceList.manufacturingEfficiency")
              }}
            </td>
            <td class="td-width" v-for="item in proItem.arr">
              {{ item.efficiencyPercent }}%
            </td>
          </tr>
          <tr v-show="!hideSame || !(proItem.standardHourIssample && hideSame)">
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.standardHour") }}
            </td>
            <td class="td-width" v-for="item in proItem.arr">
              {{ item.standardHour }}
            </td>
          </tr>
          <tr v-show="!hideSame || !(proItem.directRateIssample && hideSame)">
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.directRate") }}
            </td>
            <td class="td-width" v-for="item in proItem.arr">
              {{ item.directRate }}
            </td>
          </tr>
          <tr v-show="!hideSame || !(proItem.setUpRateIssample && hideSame)">
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.setUpRate") }}
            </td>
            <td
              :class="
                !proItem.setUpRateIssample ? 'color-red td-width' : 'td-width'
              "
              v-for="item in proItem.arr"
            >
              {{ item.setUpRate }}
            </td>
          </tr>
          <tr
            v-show="
              !hideSame || !(proItem.directOperateQtyIssample && hideSame)
            "
          >
            <td
              :class="
                !proItem.directOperateQtyIssample
                  ? 'color-red td-title-width'
                  : 'td-title-width'
              "
            >
              {{ $t("changePriceLists.changePriceList.directOperators") }}
            </td>
            <td class="td-width" v-for="item in proItem.arr">
              {{ item.directOperateQty }}
            </td>
          </tr>
          <tr
            v-show="
              !hideSame || !(proItem.assemblyDirectCostIssample && hideSame)
            "
          >
            <td
              :class="
                !proItem.assemblyDirectCostIssample
                  ? 'color-red td-title-width'
                  : 'td-title-width'
              "
            >
              {{ $t("changePriceLists.changePriceList.directAssembly") }}
            </td>
            <td class="td-width" v-for="item in proItem.arr">
              {{ item.assemblyDirectCost }}
            </td>
          </tr>
          <tr v-show="!hideSame || !(proItem.variableRateIssample && hideSame)">
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.burdenVariable") }}
            </td>
            <td
              :class="
                !proItem.variableRateIssample
                  ? 'color-red td-width'
                  : 'td-width'
              "
              v-for="item in proItem.arr"
            >
              {{ item.variableRate }}
            </td>
          </tr>
          <tr v-show="!hideSame || !(proItem.fixedRateIssample && hideSame)">
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.burdenRate") }}
            </td>
            <td
              :class="
                !proItem.fixedRateIssample ? 'color-red td-width' : 'td-width'
              "
              v-for="item in proItem.arr"
            >
              {{ item.fixedRate }}
            </td>
          </tr>

          <tr
            v-show="
              !hideSame || !(proItem.depreciationRateIssample && hideSame)
            "
          >
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.burdenDepreciation") }}
            </td>
            <td class="td-width" v-for="item in proItem.arr">
              {{ item.depreciationRate }}
            </td>
          </tr>
          <tr
            v-show="!hideSame || !(proItem.totalBurdenRateIssample && hideSame)"
          >
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.totalBurdenRate") }}
            </td>
            <td class="td-width" v-for="item in proItem.arr">
              {{ item.totalBurdenRate }}
            </td>
          </tr>
          <tr
            v-show="
              !hideSame || !(proItem.assemblyBurdenCostIssample && hideSame)
            "
          >
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.burdenCost") }}
            </td>
            <td class="td-width" v-for="item in proItem.arr">
              {{ item.assemblyBurdenCost }}
            </td>
          </tr>
          <tr v-show="!hideSame || !(proItem.totalCostIssample && hideSame)">
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.subTotalCost") }}
            </td>
            <td class="td-width" v-for="item in proItem.arr">
              {{ item.totalCost }}
            </td>
          </tr>
          <tr v-show="!hideSame || !(proItem.scrapPercentIssample && hideSame)">
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.processScrap") }}
            </td>
            <td class="td-width" v-for="item in proItem.arr">
              {{ item.scrapPercent }} %
            </td>
          </tr>
        </table>
      </div>
      <div class="tcm-content-title">
        {{ $t("unifiedPrompts.unifiedPrompt.dProcessing") }}
      </div>
      <div class="tcm-content-form" v-for="(purItem, purIndex) in purInfo">
        <table>
          <tr v-show="!hideSame || !(purItem.nameIssample && hideSame)">
            <td
              :class="
                !purItem.nameIssample
                  ? 'color-red td-title-width'
                  : 'td-title-width'
              "
            >
              {{ $t("changePriceLists.changePriceList.processDescription") }}
            </td>
            <td class="td-width" v-for="item in purItem.arr">
              {{ item.name }}
            </td>
          </tr>
          <tr v-show="!hideSame || !(purItem.quantityIssample && hideSame)">
            <td
              :class="
                !purItem.quantityIssample
                  ? 'color-red td-title-width'
                  : 'td-title-width'
              "
            >
              {{ $t("changePriceLists.changePriceList.consumption") }}
            </td>
            <td class="td-width" v-for="item in purItem.arr">
              {{ item.quantity }}
            </td>
          </tr>
          <tr v-show="!hideSame || !(purItem.unitIssample && hideSame)">
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.unitMeasure") }}
            </td>
            <td
              :class="!purItem.unitIssample ? 'color-red td-width' : 'td-width'"
              v-for="item in purItem.arr"
            >
              {{ item.unitMeasureName }}
            </td>
          </tr>
          <tr v-show="!hideSame || !(purItem.unitCostIssample && hideSame)">
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.costUoM") }}
            </td>
            <td
              :class="
                !purItem.unitCostIssample ? 'color-red td-width' : 'td-width'
              "
              v-for="item in purItem.arr"
            >
              {{ item.unitCost }}
            </td>
          </tr>
          <tr v-show="!hideSame || !(purItem.totalCostIssample && hideSame)">
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.subTotalCost") }}
            </td>
            <td class="td-width" v-for="item in purItem.arr">
              {{ item.totalCost }}
            </td>
          </tr>
          <tr v-show="!hideSame || !(purItem.priceIssample && hideSame)">
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.priceReference") }}
            </td>
            <td class="td-width" v-for="item in purItem.arr">
              {{ item.price }}
            </td>
          </tr>
        </table>
      </div>
      <div class="tcm-content-title">
        {{ $t("unifiedPrompts.unifiedPrompt.eCustoms") }}
      </div>
      <div class="tcm-content-form">
        <table>
          <!-- <tr v-show="!hideSame || !(plInfo.calculationMethodIssample && hideSame)">
						<td class="td-title-width">{{$t('changePriceLists.changePriceList.calculationMethod')}}</td>
						<td class="td-width" v-for="item in plInfo.arr">{{item.calculationMethod}}</td>
					</tr> -->
          <tr v-show="!hideSame || !(plInfo.freightCostIssample && hideSame)">
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.freightCost") }}
            </td>
            <td
              :class="
                !plInfo.freightCostIssample ? 'color-red td-width' : 'td-width'
              "
              v-for="item in plInfo.arr"
            >
              {{ item.freightCost }}
              {{ item.calculationMethod_Freight == "数值" ? "" : "%" }}
            </td>
          </tr>
          <tr v-show="!hideSame || !(plInfo.wareHouseCostIssample && hideSame)">
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.wareCost") }}
            </td>
            <td
              :class="
                !plInfo.wareHouseCostIssample
                  ? 'color-red td-width'
                  : 'td-width'
              "
              v-for="item in plInfo.arr"
            >
              {{ item.wareHouseCost }}
              {{ item.calculationMethod_Ware == "数值" ? "" : "%" }}
            </td>
          </tr>
          <tr v-show="!hideSame || !(plInfo.packageCostIssample && hideSame)">
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.packagingCost") }}
            </td>
            <td
              :class="
                !plInfo.packageCostIssample ? 'color-red td-width' : 'td-width'
              "
              v-for="item in plInfo.arr"
            >
              {{ item.packageCost }}
              {{ item.calculationMethod_Package == "数值" ? "" : "%" }}
            </td>
          </tr>
          <tr v-show="!hideSame || !(plInfo.otherCostIssample && hideSame)">
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.taxesFees") }}
            </td>
            <td
              :class="
                !plInfo.otherCostIssample ? 'color-red td-width' : 'td-width'
              "
              v-for="item in plInfo.arr"
            >
              {{ item.otherCost }}
              {{ item.calculationMethod_Taxes == "数值" ? "" : "%" }}
            </td>
          </tr>

          <tr
            v-show="
              !hideSame || !(plInfo.amortizationDescriptionIssample && hideSame)
            "
          >
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.addedDescription") }}
            </td>
            <td class="td-width" v-for="item in plInfo.arr">
              {{ item.amortizationDescription }}
            </td>
          </tr>
          <tr v-show="!hideSame || !(plInfo.totalAmortizeIssample && hideSame)">
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.totalTooling") }}
            </td>
            <td
              :class="
                !plInfo.totalAmortizeIssample
                  ? 'color-red td-width'
                  : 'td-width'
              "
              v-for="item in plInfo.arr"
            >
              {{ item.totalAmortize }}
            </td>
          </tr>
          <tr v-show="!hideSame || !(plInfo.totalPiecesIssample && hideSame)">
            <td
              :class="
                !plInfo.totalPiecesIssample
                  ? 'color-red td-title-width'
                  : 'td-title-width'
              "
            >
              {{ $t("changePriceLists.changePriceList.totalAmortized") }}
            </td>
            <td class="td-width" v-for="item in plInfo.arr">
              {{ item.totalPieces }}
            </td>
          </tr>
          <tr v-show="!hideSame || !(plInfo.amortizeCostIssample && hideSame)">
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.amortizationCost") }}
            </td>
            <td
              :class="
                !plInfo.amortizeCostIssample ? 'color-red td-width' : 'td-width'
              "
              v-for="item in plInfo.arr"
            >
              {{ item.amortizeCost }}
            </td>
          </tr>
        </table>
      </div>
      <div class="tcm-content-title">
        {{ $t("unifiedPrompts.unifiedPrompt.fPRICE") }}
      </div>
      <div class="tcm-content-form">
        <table>
          <tr v-show="!hideSame || !(sgaInfo.sgaIssample && hideSame)">
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.sga") }}
            </td>
            <td
              :class="!sgaInfo.sgaIssample ? 'color-red td-width' : 'td-width'"
              v-for="item in sgaInfo.arr"
            >
              {{ item.sga }} %
            </td>
          </tr>
          <tr v-show="!hideSame || !(sgaInfo.sgaCostIssample && hideSame)">
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.sgaCost") }}
            </td>
            <td
              :class="
                !sgaInfo.sgaCostIssample ? 'color-red td-width' : 'td-width'
              "
              v-for="item in sgaInfo.arr"
            >
              {{ item.sgaCost }}
            </td>
          </tr>
          <tr
            v-show="!hideSame || !(sgaInfo.materialProfitIssample && hideSame)"
          >
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.profitMaterial") }}
            </td>
            <td
              :class="
                !sgaInfo.materialProfitIssample
                  ? 'color-red td-width'
                  : 'td-width'
              "
              v-for="item in sgaInfo.arr"
            >
              {{ item.materialProfit }} %
            </td>
          </tr>
          <tr v-show="!hideSame || !(sgaInfo.addProfitIssample && hideSame)">
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.profitComponents") }}
            </td>
            <td
              :class="
                !sgaInfo.addProfitIssample ? 'color-red td-width' : 'td-width'
              "
              v-for="item in sgaInfo.arr"
            >
              {{ item.addProfit }} %
            </td>
          </tr>
          <tr
            v-show="!hideSame || !(sgaInfo.purchaseProfitIssample && hideSame)"
          >
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.profitValue") }}
            </td>
            <td
              :class="
                !sgaInfo.purchaseProfitIssample
                  ? 'color-red td-width'
                  : 'td-width'
              "
              v-for="item in sgaInfo.arr"
            >
              {{ item.purchaseProfit }} %
            </td>
          </tr>

          <tr v-show="!hideSame || !(sgaInfo.totalProfitIssample && hideSame)">
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.totalProfit") }}
            </td>
            <td
              :class="
                !sgaInfo.totalProfitIssample ? 'color-red td-width' : 'td-width'
              "
              v-for="item in sgaInfo.arr"
            >
              {{ item.totalProfit }} %
            </td>
          </tr>
          <tr
            v-show="!hideSame || !(sgaInfo.totalProfitCostIssample && hideSame)"
          >
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.totalprofitCost") }}
            </td>
            <td
              :class="
                !sgaInfo.totalProfitCostIssample
                  ? 'color-red td-width'
                  : 'td-width'
              "
              v-for="item in sgaInfo.arr"
            >
              {{ item.totalProfitCost }}
            </td>
          </tr>
          <tr v-show="!hideSame || !(sgaInfo.materialPCIssample && hideSame)">
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.materialScrap") }}
            </td>
            <td
              :class="
                !sgaInfo.materialPCIssample ? 'color-red td-width' : 'td-width'
              "
              v-for="item in sgaInfo.arr"
            >
              {{ item.materialPC }} %
            </td>
          </tr>
        </table>
      </div>
      <div class="tcm-content-title">
        {{ $t("unifiedPrompts.unifiedPrompt.gCost") }}
      </div>
      <div class="tcm-content-form">
        <table>
          <tr
            v-show="
              !hideSame || !(sumInfo.materialCost_SUMIssample && hideSame)
            "
          >
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.rawMaterialCost") }}
            </td>
            <td
              :class="
                !sumInfo.materialCost_SUMIssample
                  ? 'color-red td-width'
                  : 'td-width'
              "
              v-for="item in sumInfo.arr"
            >
              {{ item.currencySymbolName }} {{ item.materialCost_SUM }}
            </td>
          </tr>

          <tr
            v-show="
              !hideSame || !(sumInfo.purchaseCost_SUMIssample && hideSame)
            "
          >
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.purchasedCost") }}
            </td>
            <td
              :class="
                !sumInfo.purchaseCost_SUMIssample
                  ? 'color-red td-width'
                  : 'td-width'
              "
              v-for="item in sumInfo.arr"
            >
              {{ item.currencySymbolName }} {{ item.purchaseCost_SUM }}
            </td>
          </tr>

          <tr
            v-show="!hideSame || !(sumInfo.laborCost_SUMIssample && hideSame)"
          >
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.directLaborCost") }}
            </td>
            <td
              :class="
                !sumInfo.laborCost_SUMIssample
                  ? 'color-red td-width'
                  : 'td-width'
              "
              v-for="item in sumInfo.arr"
            >
              {{ item.currencySymbolName }} {{ item.laborCost_SUM }}
            </td>
          </tr>

          <tr
            v-show="!hideSame || !(sumInfo.burdenCost_SUMIssample && hideSame)"
          >
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.burdenCost") }}
            </td>
            <td
              :class="
                !sumInfo.burdenCost_SUMIssample
                  ? 'color-red td-width'
                  : 'td-width'
              "
              v-for="item in sumInfo.arr"
            >
              {{ item.currencySymbolName }} {{ item.burdenCost_SUM }}
            </td>
          </tr>

          <tr
            v-show="
              !hideSame || !(sumInfo.manufacturingCost_PROIssample && hideSame)
            "
          >
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.totalManufacturing") }}
            </td>
            <td
              :class="
                !sumInfo.manufacturingCost_PROIssample
                  ? 'color-red td-width'
                  : 'td-width'
              "
              v-for="item in sumInfo.arr"
            >
              {{ item.currencySymbolName }} {{ item.manufacturingCost_PRO }}
            </td>
          </tr>
          <tr
            v-show="!hideSame || !(sumInfo.setUpCost_SUMIssample && hideSame)"
          >
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.setUpCost") }}
            </td>
            <td
              :class="
                !sumInfo.setUpCost_SUMIssample
                  ? 'color-red td-width'
                  : 'td-width'
              "
              v-for="item in sumInfo.arr"
            >
              {{ item.currencySymbolName }} {{ item.setUpCost_SUM }}
            </td>
          </tr>

          <tr
            v-show="!hideSame || !(sumInfo.packageCost_SUMIssample && hideSame)"
          >
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.packagingFreightCost") }}
            </td>
            <td
              :class="
                !sumInfo.packageCost_SUMIssample
                  ? 'color-red td-width'
                  : 'td-width'
              "
              v-for="item in sumInfo.arr"
            >
              {{ item.currencySymbolName }} {{ item.packageCost_SUM }}
            </td>
          </tr>
          <tr
            v-show="!hideSame || !(sumInfo.otherCost_SUMIssample && hideSame)"
          >
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.amortizationFees") }}
            </td>
            <td
              :class="
                !sumInfo.otherCost_SUMIssample
                  ? 'color-red td-width'
                  : 'td-width'
              "
              v-for="item in sumInfo.arr"
            >
              {{ item.currencySymbolName }} {{ item.otherCost_SUM }}
            </td>
          </tr>
          <tr
            v-show="
              !hideSame || !(sumInfo.amortizeCost_SUMIssample && hideSame)
            "
          >
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.amortizationCost") }}
            </td>
            <td
              :class="
                !sumInfo.amortizeCost_SUMIssample
                  ? 'color-red td-width'
                  : 'td-width'
              "
              v-for="item in sumInfo.arr"
            >
              {{ item.currencySymbolName }} {{ item.amortizeCost_SUM }}
            </td>
          </tr>
          <tr
            v-show="!hideSame || !(sumInfo.scrapCost_SUMIssample && hideSame)"
          >
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.processScrapCost") }}
            </td>
            <td
              :class="
                !sumInfo.scrapCost_SUMIssample
                  ? 'color-red td-width'
                  : 'td-width'
              "
              v-for="item in sumInfo.arr"
            >
              {{ item.currencySymbolName }} {{ item.scrapCost_SUM }}
            </td>
          </tr>
          <tr
            v-show="!hideSame || !(sumInfo.sgaProfit_SUMIssample && hideSame)"
          >
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.sgaAndProfit") }}
            </td>
            <td
              :class="
                !sumInfo.sgaProfit_SUMIssample
                  ? 'color-red td-width'
                  : 'td-width'
              "
              v-for="item in sumInfo.arr"
            >
              {{ item.currencySymbolName }} {{ item.sgaProfit_SUM }}
            </td>
          </tr>

          <tr
            v-show="
              !hideSame || !(sumInfo.totalPriceCurr_SUMIssample && hideSame)
            "
          >
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.totalSellPrice") }}
            </td>
            <td
              :class="
                !sumInfo.totalPriceCurr_SUMIssample
                  ? 'color-red td-width'
                  : 'td-width'
              "
              v-for="item in sumInfo.arr"
            >
              {{ item.currencySymbolName }} {{ item.totalPriceCurr_SUM }}
            </td>
          </tr>
          <tr
            v-show="
              !hideSame || !(sumInfo.totalPriceReport_SUMIssample && hideSame)
            "
          >
            <td class="td-title-width">
              {{ $t("changePriceLists.changePriceList.totalSellCurrency") }}
            </td>
            <td
              :class="
                !sumInfo.totalPriceReport_SUMIssample
                  ? 'color-red td-width'
                  : 'td-width'
              "
              v-for="item in sumInfo.arr"
            >
              {{ item.reportCurrencySymbolName }}
              {{ item.totalPriceReport_SUM }}
            </td>
          </tr>
        </table>
      </div>
    </div>
    <CompareDia ref="CompareDialog"></CompareDia>
    <ChooseCompare
      ref="chooseDia"
      :addListNum="addListNum"
      @getChooseId="getChooseId"
    ></ChooseCompare>
  </div>
</template>

<script>
import {
  GetApplyDetail,
  GetSelCurrency,
  GetRate,
} from "../../assets/js/requestApi.js";
import CompareDia from "./compareDia.vue";
import ChooseCompare from "./chooseCompareDia.vue";
import commonUtil from "../../assets/js/commonUtil.js";
import htmlToPdf from "../../assets/js/htmlToPdf.js";
export default {
  components: {
    CompareDia,
    ChooseCompare,
  },
  data() {
    return {
      // 比较数据的id集合
      ids: [],
      // 是否隐藏相同项
      hideSame: false,
      // 比较数据详情
      compareData: [],
      // 原始比较数据详情
      originalCompareData: [],
      // 添加模块显示个数
      addListNum: 4,
      // 每个模块货币选项
      genCurrOptions: [],
      currencyOptions: [],
      currencyReportOptions: [],
      // Forex Information数据
      forexInfo: {
        arr: [],
        currencyIssample: true,
        reportCurrencyIssample: true,
        rateIssample: true,
      },
      // 选择的货币数据
      currencyInfo: [],
      // General Information数据
      genInfo: {
        arr: [],
        quoteTypeIdIssample: true,
        commodityIdIssample: true,
        programNameIssample: true,
        ecrnoIssample: true,
        partDescriptionIssample: true,
        partNumberIssample: true,
        partRevisionIssample: true,
        regionIdIssample: true,
        supplierNameIssample: true,
        materialDescriptionIssample: true,
        peakVolumeIssample: true,
        lifetimeVolumeIssample: true,
        pegPointIssample: true,
        lotSizeIssample: true,
        productionYearIssample: true,
      },
      // Packaging, Logistics, Amortization, Duties & Customs数据
      plInfo: {
        arr: [],
        calculationMethodIssample: true,
        calculationMethod_FreightIssample: true,
        calculationMethod_WareIssample: true,
        calculationMethod_PackageIssample: true,
        calculationMethod_TaxesIssample: true,
        freightCostIssample: true,
        wareHouseCostIssample: true,
        packageCostIssample: true,
        otherCostIssample: true,
        amortizationDescriptionIssample: true,
        totalPiecesIssample: true,
        totalAmortizeIssample: true,
        amortizeCostIssample: true,
      },
      // SGA & Profit, SELL PRICE数据
      sgaInfo: {
        arr: [],
        sgaIssample: true,
        sgaCostIssample: true,
        materialProfitIssample: true,
        addProfitIssample: true,
        purchaseProfitIssample: true,
        totalProfitIssample: true,
        totalProfitCostIssample: true,
        materialPCIssample: true,
      },
      // sum数据
      sumInfo: {
        arr: [],
        materialCost_SUMIssample: true,
        setUpCost_SUMIssample: true,
        manufacturingCost_PROIssample: true,
        purchaseCost_SUMIssample: true,
        packageCost_SUMIssample: true,
        laborCost_SUMIssample: true,
        otherCost_SUMIssample: true,
        burdenCost_SUMIssample: true,
        amortizeCost_SUMIssample: true,
        sgaProfit_SUMIssample: true,
        scrapCost_SUMIssample: true,
        totalPriceCurr_SUMIssample: true,
        totalPriceReport_SUMIssample: true,
      },
      // Raw Materia数据
      rawInfo: [],
      purInfo: [],
      proInfo: [],
      // 当前日期
      currentDate: "",
      // 下载次数
      downloadNum: 0,
      crumbs: ["homePage.home.TCMData", "homePage.home.Compare"],
    };
  },
  mounted() {
    this.ids = JSON.parse(this.$route.query.ids);
    this.getCompareData(this.ids);
    this.initCurrencyOptions({
      CurrId: "",
      CurrCNYId: "",
      isEnable: 1,
    });
    this.currentDate = commonUtil.getTime(true, false, 0, "").replace(/-/g, "");
    if (localStorage.getItem("currentDate") != this.currentDate) {
      localStorage.setItem("currentDate", this.currentDate);
      this.downloadNum = 0;
    } else {
      this.downloadNum = localStorage.getItem("downloadNum")
        ? parseFloat(localStorage.getItem("downloadNum"))
        : 0;
    }
    console.log(this.currentDate);
  },
  methods: {
    Goback() {
      this.$router.go(-1);
    },
    // 获取对比数据
    getCompareData(ids) {
      let _this = this;
      GetApplyDetail(ids, (response) => {
        if (response && response.code == 200) {
          response.result.forEach(function (res, resIndex) {
            _this.compareData.push(res);
            _this.originalCompareData.push(JSON.parse(JSON.stringify(res)));
            _this.genCurrOptions.push({
              currencyOptions: _this.currencyOptions,
              currencyReportOptions: _this.currencyReportOptions,
            });
            console.log(_this.originalCompareData);
          });
          // this.compareData = response.result
          this.compareGen(this.compareData);
          this.comparePl(this.compareData);
          this.compareSga(this.compareData);
          this.compareSum(this.compareData);
          this.compareRaw(this.compareData);
          this.comparePur(this.compareData);
          this.comparePro(this.compareData);
        }
      });
    },
    // 对比gen数据
    compareGen(allInfo) {
      let _this = this;
      this.currencyInfo = [];
      this.forexInfo.arr = [];
      let currency = [];
      let reportCurrency = [];
      let rate = [];
      this.genInfo.arr = [];
      let quoteTypeId = [];
      let commodityId = [];
      let programName = [];
      let ecrno = [];
      let partDescription = [];
      let partNumber = [];
      let partRevision = [];
      let regionId = [];
      let supplierName = [];
      let materialDescription = [];
      let peakVolume = [];
      let lifetimeVolume = [];
      let pegPoint = [];
      let lotSize = [];
      let productionYear = [];
      allInfo.forEach(function (val, index) {
        // gen模块
        if (val.exchangeRate === null) {
          _this.forexInfo.arr.push({
            currency: "",
            reportCurrency: "",
            exchangeRate: "",
          });
          _this.currencyInfo.push({
            Currency: "",
            ReportCurrency: "",
            rate: "",
          });
          currency.push("");
          reportCurrency.push("");
          rate.push("");
        } else {
          _this.forexInfo.arr.push({
            currency: val.exchangeRate.name.split("-")[0],
            reportCurrency: val.exchangeRate.name.split("-")[1],
            exchangeRate: val.exchangeRate.exchangeRate,
          });
          _this.currencyInfo.push({
            Currency: val.exchangeRate.currency,
            ReportCurrency: val.exchangeRate.currencyCNYReportCurrency,
            rate: val.exchangeRate.exchangeRate,
          });
          currency.push(val.exchangeRate.name.split("-")[0]);
          reportCurrency.push(val.exchangeRate.name.split("-")[1]);
          rate.push(val.exchangeRate.exchangeRate);
        }
        _this.genInfo.arr.push({
          quoteTypeId: val.quoteTypeId,
          quoteTypeName: val.quoteTypeName,
          commodityId: val.commodityId,
          commodityName: val.commodityName,
          programName: val.programName,
          ecrno: val.ecrno,
          partDescription: val.partDescription,
          partNumber: val.partNumber,
          partRevision: val.partRevision,
          regionId: val.regionId,
          regionName: val.regionName,
          supplierName: val.supplierName,
          materialDescription: val.materialDescription,
          peakVolume: val.peakVolume,
          lifetimeVolume: val.lifetimeVolume,
          pegPoint: val.pegPoint,
          lotSize: val.lotSize,
          productionYear: val.productionYear,
        });
        quoteTypeId.push(val.quoteTypeId);
        commodityId.push(val.commodityId);
        programName.push(val.programName);
        ecrno.push(val.ecrno);
        partDescription.push(val.partDescription);
        partNumber.push(val.partNumber);
        partRevision.push(val.partRevision);
        regionId.push(val.regionId);
        supplierName.push(val.supplierName);
        materialDescription.push(val.materialDescription);
        peakVolume.push(val.peakVolume);
        lifetimeVolume.push(val.lifetimeVolume);
        pegPoint.push(val.pegPoint);
        lotSize.push(val.lotSize);
        productionYear.push(val.productionYear);
      });
      this.forexInfo.currencyIssample =
        Array.from(new Set(currency)).length > 1 ? false : true;
      this.forexInfo.reportCurrencyIssample =
        Array.from(new Set(reportCurrency)).length > 1 ? false : true;
      this.forexInfo.rateIssample =
        Array.from(new Set(rate)).length > 1 ? false : true;
      this.genInfo.quoteTypeIdIssample =
        Array.from(new Set(quoteTypeId)).length > 1 ? false : true;
      this.genInfo.commodityIdIssample =
        Array.from(new Set(commodityId)).length > 1 ? false : true;
      this.genInfo.programNameIssample =
        Array.from(new Set(programName)).length > 1 ? false : true;
      this.genInfo.ecrnoIssample =
        Array.from(new Set(ecrno)).length > 1 ? false : true;
      this.genInfo.partDescriptionIssample =
        Array.from(new Set(partDescription)).length > 1 ? false : true;
      this.genInfo.partNumberIssample =
        Array.from(new Set(partNumber)).length > 1 ? false : true;
      this.genInfo.partRevisionIssample =
        Array.from(new Set(partRevision)).length > 1 ? false : true;
      this.genInfo.regionIdIssample =
        Array.from(new Set(regionId)).length > 1 ? false : true;
      this.genInfo.supplierNameIssample =
        Array.from(new Set(supplierName)).length > 1 ? false : true;
      this.genInfo.materialDescriptionIssample =
        Array.from(new Set(materialDescription)).length > 1 ? false : true;
      this.genInfo.peakVolumeIssample =
        Array.from(new Set(peakVolume)).length > 1 ? false : true;
      this.genInfo.lifetimeVolumeIssample =
        Array.from(new Set(lifetimeVolume)).length > 1 ? false : true;
      this.genInfo.pegPointIssample =
        Array.from(new Set(pegPoint)).length > 1 ? false : true;
      this.genInfo.lotSizeIssample =
        Array.from(new Set(lotSize)).length > 1 ? false : true;
      this.genInfo.productionYearIssample =
        Array.from(new Set(productionYear)).length > 1 ? false : true;
    },
    // 对比pl数据
    comparePl(allInfo) {
      let _this = this;
      this.plInfo.arr = [];
      let calculationMethod = [];
      let calculationMethod_Freight = [];
      let calculationMethod_Ware = [];
      let calculationMethod_Package = [];
      let calculationMethod_Taxes = [];
      let freightCost = [];
      let wareHouseCost = [];
      let packageCost = [];
      let otherCost = [];
      let amortizationDescription = [];
      let totalPieces = [];
      let totalAmortize = [];
      let amortizeCost = [];
      allInfo.forEach(function (val, index) {
        // pl模块
        _this.plInfo.arr.push({
          calculationMethod: val.calculationMethod == "1" ? "数值" : "百分比",
          calculationMethod_Freight:
            val.calculationMethod_Freight == "1" ? "数值" : "百分比",
          calculationMethod_Ware:
            val.calculationMethod_Ware == "1" ? "数值" : "百分比",
          calculationMethod_Package:
            val.calculationMethod_Package == "1" ? "数值" : "百分比",
          calculationMethod_Taxes:
            val.calculationMethod_Taxes == "1" ? "数值" : "百分比",
          freightCost: val.freightCost,
          wareHouseCost: val.wareHouseCost,
          packageCost: val.packageCost,
          otherCost: val.otherCost,
          amortizationDescription: val.amortizationDescription,
          totalPieces: val.totalPieces,
          totalAmortize: val.totalAmortize,
          amortizeCost: val.amortizeCost,
        });
        calculationMethod.push(
          val.calculationMethod == "1" ? "数值" : "百分比"
        );
        calculationMethod_Freight.push(
          val.calculationMethod_Freight == "1" ? "数值" : "百分比"
        );
        calculationMethod_Ware.push(
          val.calculationMethod_Ware == "1" ? "数值" : "百分比"
        );
        calculationMethod_Package.push(
          val.calculationMethod_Package == "1" ? "数值" : "百分比"
        );
        calculationMethod_Taxes.push(
          val.calculationMethod_Taxes == "1" ? "数值" : "百分比"
        );
        freightCost.push(val.freightCost);
        wareHouseCost.push(val.wareHouseCost);
        packageCost.push(val.packageCost);
        otherCost.push(val.otherCost);
        amortizationDescription.push(val.amortizationDescription);
        totalPieces.push(val.totalPieces);
        totalAmortize.push(val.totalAmortize);
        amortizeCost.push(val.amortizeCost);
      });
      this.plInfo.calculationMethodIssample =
        Array.from(new Set(calculationMethod)).length > 1 ? false : true;
      this.plInfo.calculationMethod_FreightIssample =
        Array.from(new Set(calculationMethod_Freight)).length > 1
          ? false
          : true;
      this.plInfo.calculationMethod_WareIssample =
        Array.from(new Set(calculationMethod_Ware)).length > 1 ? false : true;
      this.plInfo.calculationMethod_PackageIssample =
        Array.from(new Set(calculationMethod_Package)).length > 1
          ? false
          : true;
      this.plInfo.calculationMethod_TaxesIssample =
        Array.from(new Set(calculationMethod_Taxes)).length > 1 ? false : true;
      if (!this.plInfo.calculationMethod_FreightIssample) {
        this.plInfo.freightCostIssample = false;
      } else {
        this.plInfo.freightCostIssample =
          Array.from(new Set(freightCost)).length > 1 ? false : true;
      }
      if (!this.plInfo.calculationMethod_WareIssample) {
        this.plInfo.wareHouseCostIssample = false;
      } else {
        this.plInfo.wareHouseCostIssample =
          Array.from(new Set(wareHouseCost)).length > 1 ? false : true;
      }
      if (!this.plInfo.calculationMethod_PackageIssample) {
        this.plInfo.packageCostIssample = false;
      } else {
        this.plInfo.packageCostIssample =
          Array.from(new Set(packageCost)).length > 1 ? false : true;
      }
      if (!this.plInfo.calculationMethod_TaxesIssample) {
        this.plInfo.otherCostIssample = false;
      } else {
        this.plInfo.otherCostIssample =
          Array.from(new Set(otherCost)).length > 1 ? false : true;
      }
      this.plInfo.amortizationDescriptionIssample =
        Array.from(new Set(amortizationDescription)).length > 1 ? false : true;
      this.plInfo.totalPiecesIssample =
        Array.from(new Set(totalPieces)).length > 1 ? false : true;
      this.plInfo.totalAmortizeIssample =
        Array.from(new Set(totalAmortize)).length > 1 ? false : true;
      this.plInfo.amortizeCostIssample =
        Array.from(new Set(amortizeCost)).length > 1 ? false : true;
    },
    // 对比sga数据
    compareSga(allInfo) {
      let _this = this;
      this.sgaInfo.arr = [];
      let sga = [];
      let sgaCost = [];
      let materialProfit = [];
      let addProfit = [];
      let purchaseProfit = [];
      let totalProfit = [];
      let totalProfitCost = [];
      let materialPC = [];
      allInfo.forEach(function (val, index) {
        // sga模块
        _this.sgaInfo.arr.push({
          sga: val.sga,
          sgaCost: val.sgaCost,
          materialProfit: val.materialProfit,
          addProfit: val.addProfit,
          purchaseProfit: val.purchaseProfit,
          totalProfit: val.totalProfit,
          totalProfitCost: val.totalProfitCost,
          materialPC: val.materialPC,
        });
        sga.push(val.sga);
        sgaCost.push(val.sgaCost);
        materialProfit.push(val.materialProfit);
        addProfit.push(val.addProfit);
        purchaseProfit.push(val.purchaseProfit);
        totalProfit.push(val.totalProfit);
        totalProfitCost.push(val.totalProfitCost);
        materialPC.push(val.materialPC);
      });
      this.sgaInfo.sgaIssample =
        Array.from(new Set(sga)).length > 1 ? false : true;
      this.sgaInfo.sgaCostIssample =
        Array.from(new Set(sgaCost)).length > 1 ? false : true;
      this.sgaInfo.materialProfitIssample =
        Array.from(new Set(materialProfit)).length > 1 ? false : true;
      this.sgaInfo.addProfitIssample =
        Array.from(new Set(addProfit)).length > 1 ? false : true;
      this.sgaInfo.purchaseProfitIssample =
        Array.from(new Set(purchaseProfit)).length > 1 ? false : true;
      this.sgaInfo.totalProfitIssample =
        Array.from(new Set(totalProfit)).length > 1 ? false : true;
      this.sgaInfo.totalProfitCostIssample =
        Array.from(new Set(totalProfitCost)).length > 1 ? false : true;
      this.sgaInfo.materialPCIssample =
        Array.from(new Set(materialPC)).length > 1 ? false : true;
    },
    // 对比sum数据
    compareSum(allInfo) {
      let _this = this;
      this.sumInfo.arr = [];
      let materialCost_SUM = [];
      let setUpCost_SUM = [];
      let purchaseCost_SUM = [];
      let packageCost_SUM = [];
      let laborCost_SUM = [];
      let otherCost_SUM = [];
      let burdenCost_SUM = [];
      let amortizeCost_SUM = [];
      let sgaProfit_SUM = [];
      let scrapCost_SUM = [];
      let totalPriceCurr_SUM = [];
      let totalPriceReport_SUM = [];
      let manufacturingCost_PRO = [];
      this.compareData.forEach(function (val, index) {
        // sum模块
        _this.sumInfo.arr.push({
          materialCost_SUM: val.materialCost_SUM,
          setUpCost_SUM: val.setUpCost_SUM,
          purchaseCost_SUM: val.purchaseCost_SUM,
          packageCost_SUM: val.packageCost_SUM,
          laborCost_SUM: val.laborCost_SUM,
          otherCost_SUM: val.otherCost_SUM,
          burdenCost_SUM: val.burdenCost_SUM,
          amortizeCost_SUM: val.amortizeCost_SUM,
          sgaProfit_SUM: val.sgaProfit_SUM,
          scrapCost_SUM: val.scrapCost_SUM,
          totalPriceCurr_SUM: val.totalPriceCurr_SUM,
          totalPriceReport_SUM: val.totalPriceReport_SUM,
          currencySymbolName:
            val.exchangeRate && val.exchangeRate.currencySymbolName
              ? val.exchangeRate.currencySymbolName
              : "",
          reportCurrencySymbolName:
            val.exchangeRate && val.exchangeRate.reportCurrencySymbolName
              ? val.exchangeRate.reportCurrencySymbolName
              : "",
          manufacturingCost_PRO: val.manufacturingCost_PRO,
        });
        materialCost_SUM.push(val.materialCost_SUM);
        setUpCost_SUM.push(val.setUpCost_SUM);
        purchaseCost_SUM.push(val.purchaseCost_SUM);
        packageCost_SUM.push(val.packageCost_SUM);
        laborCost_SUM.push(val.laborCost_SUM);
        otherCost_SUM.push(val.otherCost_SUM);
        burdenCost_SUM.push(val.burdenCost_SUM);
        amortizeCost_SUM.push(val.amortizeCost_SUM);
        sgaProfit_SUM.push(val.sgaProfit_SUM);
        scrapCost_SUM.push(val.scrapCost_SUM);
        totalPriceCurr_SUM.push(val.totalPriceCurr_SUM);
        totalPriceReport_SUM.push(val.totalPriceReport_SUM);
        manufacturingCost_PRO.push(val.manufacturingCost_PRO);
      });

      this.sumInfo.materialCost_SUMIssample =
        Array.from(new Set(materialCost_SUM)).length > 1 ? false : true;
      this.sumInfo.setUpCost_SUMIssample =
        Array.from(new Set(setUpCost_SUM)).length > 1 ? false : true;
      this.sumInfo.purchaseCost_SUMIssample =
        Array.from(new Set(purchaseCost_SUM)).length > 1 ? false : true;
      this.sumInfo.packageCost_SUMIssample =
        Array.from(new Set(packageCost_SUM)).length > 1 ? false : true;
      this.sumInfo.laborCost_SUMIssample =
        Array.from(new Set(laborCost_SUM)).length > 1 ? false : true;
      this.sumInfo.otherCost_SUMIssample =
        Array.from(new Set(otherCost_SUM)).length > 1 ? false : true;
      this.sumInfo.burdenCost_SUMIssample =
        Array.from(new Set(burdenCost_SUM)).length > 1 ? false : true;
      this.sumInfo.amortizeCost_SUMIssample =
        Array.from(new Set(amortizeCost_SUM)).length > 1 ? false : true;
      this.sumInfo.sgaProfit_SUMIssample =
        Array.from(new Set(sgaProfit_SUM)).length > 1 ? false : true;
      this.sumInfo.scrapCost_SUMIssample =
        Array.from(new Set(scrapCost_SUM)).length > 1 ? false : true;
      this.sumInfo.totalPriceCurr_SUMIssample =
        Array.from(new Set(totalPriceCurr_SUM)).length > 1 ? false : true;
      this.sumInfo.totalPriceReport_SUMIssample =
        Array.from(new Set(totalPriceReport_SUM)).length > 1 ? false : true;
      this.sumInfo.manufacturingCost_PROIssample =
        Array.from(new Set(manufacturingCost_PRO)).length > 1 ? false : true;
    },
    // 对比物料单元数据
    compareRaw(allInfo) {
      let _this = this;
      this.rawInfo = [];
      // 找出raw最大长度
      let rawMaxLength = 0;
      allInfo.forEach(function (val, index) {
        if (index == 0) {
          rawMaxLength = val.materialList.length;
        }
        if (rawMaxLength < val.materialList.length) {
          rawMaxLength = val.materialList.length;
        }
      });
      // 根据raw最大长度生成对比数组
      for (let i = 0; i < rawMaxLength; i++) {
        this.rawInfo.push({
          arr: [],
          descriptionIssample: true,
          netMWeightIssample: true,
          grossMWeightIssample: true,
          unitMeasureIdIssample: true,
          unitCostIssample: true,
          unitResaleValueIssample: true,
          countryIssample: true,
          dateIssample: true,
          costIssample: true,
        });
      }
      // 往对比数组中放值
      _this.rawInfo.forEach(function (rawVal, rawIndex) {
        allInfo.forEach(function (val, index) {
          if (val.materialList[rawIndex]) {
            _this.rawInfo[rawIndex].arr.push(val.materialList[rawIndex]);
          } else {
            _this.rawInfo[rawIndex].arr.push({
              cLines: [],
              cost: "",
              country: "",
              createId: "",
              createTime: "",
              description: "",
              grossMWeight: "",
              id: "",
              isDeleted: "",
              mainId: "",
              modifyId: "",
              modifyTime: "",
              netMWeight: "",
              type: "",
              unitCost: "",
              unitMeasureId: "",
              unitResaleValue: "",
            });
          }
        });
      });
      let description = [];
      let netMWeight = [];
      let grossMWeight = [];
      let unitMeasureId = [];
      let unitCost = [];
      let unitResaleValue = [];
      let country = [];
      let cost = [];
      let date = [];
      // 根据对比数组形成每个字段对应的数组并对比数据是否相同
      _this.rawInfo.forEach(function (val, index) {
        description = [];
        netMWeight = [];
        grossMWeight = [];
        unitMeasureId = [];
        unitCost = [];
        unitResaleValue = [];
        country = [];
        cost = [];
        date = [];
        val.arr.forEach(function (arrval, arrindex) {
          description.push(arrval.description);
          netMWeight.push(arrval.netMWeight);
          grossMWeight.push(arrval.grossMWeight);
          unitMeasureId.push(arrval.unitMeasureId);
          unitCost.push(arrval.unitCost);
          unitResaleValue.push(arrval.unitResaleValue);
          country.push(arrval.country);
          date.push(arrval.date);
          cost.push(arrval.cost);
        });
        val.descriptionIssample =
          Array.from(new Set(description)).length > 1 ? false : true;
        val.netMWeightIssample =
          Array.from(new Set(netMWeight)).length > 1 ? false : true;
        val.grossMWeightIssample =
          Array.from(new Set(grossMWeight)).length > 1 ? false : true;
        val.unitMeasureIdIssample =
          Array.from(new Set(unitMeasureId)).length > 1 ? false : true;
        val.unitCostIssample =
          Array.from(new Set(unitCost)).length > 1 ? false : true;
        val.unitResaleValueIssample =
          Array.from(new Set(unitResaleValue)).length > 1 ? false : true;
        val.countryIssample =
          Array.from(new Set(country)).length > 1 ? false : true;
        val.dateIssample = Array.from(new Set(date)).length > 1 ? false : true;
        val.costIssample = Array.from(new Set(cost)).length > 1 ? false : true;
      });
      console.log(this.rawInfo);
    },
    // 对比pur单元数据
    comparePur(allInfo) {
      let _this = this;
      this.purInfo = [];
      // 找出pur最大长度
      let purMaxLength = 0;
      allInfo.forEach(function (val, index) {
        if (index == 0) {
          purMaxLength = val.purchaseList.length;
        }
        if (purMaxLength < val.purchaseList.length) {
          purMaxLength = val.purchaseList.length;
        }
      });
      // name
      // quantity
      // unit
      // unitCost
      // totalCost
      // price
      // 根据pur最大长度生成对比数组
      for (let i = 0; i < purMaxLength; i++) {
        this.purInfo.push({
          arr: [],
          nameIssample: true,
          quantityIssample: true,
          unitIssample: true,
          unitCostIssample: true,
          totalCostIssample: true,
          priceIssample: true,
        });
      }
      // 往对比数组中放值
      _this.purInfo.forEach(function (purVal, purIndex) {
        allInfo.forEach(function (val, index) {
          if (val.purchaseList[purIndex]) {
            _this.purInfo[purIndex].arr.push(val.purchaseList[purIndex]);
          } else {
            _this.purInfo[purIndex].arr.push({
              createId: "",
              createTime: "",
              id: "",
              isDeleted: "",
              mainId: "",
              modifyId: "",
              modifyTime: "",
              name: "",
              price: "",
              quantity: "",
              totalCost: "",
              type: "",
              unit: "",
              unitCost: "",
            });
          }
        });
      });
      let name = [];
      let quantity = [];
      let unit = [];
      let unitCost = [];
      let totalCost = [];
      let price = [];
      // 根据对比数组形成每个字段对应的数组并对比数据是否相同
      _this.purInfo.forEach(function (val, index) {
        name = [];
        quantity = [];
        unit = [];
        unitCost = [];
        totalCost = [];
        price = [];
        val.arr.forEach(function (arrval, arrindex) {
          name.push(arrval.name);
          quantity.push(arrval.quantity);
          unit.push(arrval.unit);
          unitCost.push(arrval.unitCost);
          totalCost.push(arrval.totalCost);
          price.push(arrval.price);
        });
        val.nameIssample = Array.from(new Set(name)).length > 1 ? false : true;
        val.quantityIssample =
          Array.from(new Set(quantity)).length > 1 ? false : true;
        val.unitIssample = Array.from(new Set(unit)).length > 1 ? false : true;
        val.unitCostIssample =
          Array.from(new Set(unitCost)).length > 1 ? false : true;
        val.totalCostIssample =
          Array.from(new Set(totalCost)).length > 1 ? false : true;
        val.priceIssample =
          Array.from(new Set(price)).length > 1 ? false : true;
      });
      console.log(this.purInfo);
    },
    // 对比pro单元数据
    comparePro(allInfo) {
      let _this = this;
      this.proInfo = [];
      // 找出pro最大长度
      let proMaxLength = 0;
      allInfo.forEach(function (val, index) {
        if (index == 0) {
          proMaxLength = val.processList.length;
        }
        if (proMaxLength < val.processList.length) {
          proMaxLength = val.processList.length;
        }
      });
      // annualVolume
      // setTime
      // setPerson
      // pieceLot
      // pieceCost
      // name
      // equSize
      // quantity
      // pieceHour
      // pieceCycle
      // efficiencyPercent
      // standardHour
      // directRate
      // setUpRate
      // directOperateQty
      // assemblyDirectCost
      // variableRate
      // fixedRate
      // depreciationRate
      // totalBurdenRate
      // assemblyBurdenCost
      // totalCost
      // scrapPercent
      // 根据pro最大长度生成对比数组
      for (let i = 0; i < proMaxLength; i++) {
        this.proInfo.push({
          arr: [],
          annualVolumeIssample: true,
          setTimeIssample: true,
          setPersonIssample: true,
          pieceLotIssample: true,
          pieceCostIssample: true,
          nameIssample: true,
          equSizeIssample: true,
          quantityIssample: true,
          pieceHourIssample: true,
          pieceCycleIssample: true,
          efficiencyPercentIssample: true,
          standardHourIssample: true,
          directRateIssample: true,
          setUpRateIssample: true,
          directOperateQtyIssample: true,
          assemblyDirectCostIssample: true,
          variableRateIssample: true,
          fixedRateIssample: true,
          depreciationRateIssample: true,
          totalBurdenRateIssample: true,
          assemblyBurdenCostIssample: true,
          totalCostIssample: true,
          scrapPercentIssample: true,
        });
      }
      // 往对比数组中放值
      _this.proInfo.forEach(function (proVal, proIndex) {
        allInfo.forEach(function (val, index) {
          if (val.processList[proIndex]) {
            _this.proInfo[proIndex].arr.push(val.processList[proIndex]);
          } else {
            _this.proInfo[proIndex].arr.push({
              annualVolume: "",
              assemblyBurdenCost: "",
              assemblyDirectCost: "",
              cLines: [],
              createId: "",
              createTime: "",
              depreciationRate: "",
              directOperateQty: "",
              directRate: "",
              efficiencyPercent: "",
              equSize: "",
              fixedRate: "",
              id: "",
              isDeleted: "",
              mainId: "",
              modifyId: "",
              modifyTime: "",
              name: "",
              pieceCost: "",
              pieceCycle: "",
              pieceHour: "",
              pieceLot: "",
              quantity: "",
              scrapPercent: "",
              setPerson: "",
              setTime: "",
              setUpRate: "",
              standardHour: "",
              totalBurdenRate: "",
              totalCost: "",
              type: "",
              variableRate: "",
            });
          }
        });
      });
      let annualVolume = [];
      let setTime = [];
      let setPerson = [];
      let pieceLot = [];
      let pieceCost = [];
      let name = [];
      let equSize = [];
      let quantity = [];
      let pieceHour = [];
      let pieceCycle = [];
      let efficiencyPercent = [];
      let standardHour = [];
      let directRate = [];
      let setUpRate = [];
      let directOperateQty = [];
      let assemblyDirectCost = [];
      let variableRate = [];
      let fixedRate = [];
      let depreciationRate = [];
      let totalBurdenRate = [];
      let assemblyBurdenCost = [];
      let totalCost = [];
      let scrapPercent = [];
      // 根据对比数组形成每个字段对应的数组并对比数据是否相同
      _this.proInfo.forEach(function (val, index) {
        annualVolume = [];
        setTime = [];
        setPerson = [];
        pieceLot = [];
        pieceCost = [];
        name = [];
        equSize = [];
        quantity = [];
        pieceHour = [];
        pieceCycle = [];
        efficiencyPercent = [];
        standardHour = [];
        directRate = [];
        setUpRate = [];
        directOperateQty = [];
        assemblyDirectCost = [];
        variableRate = [];
        fixedRate = [];
        depreciationRate = [];
        totalBurdenRate = [];
        assemblyBurdenCost = [];
        totalCost = [];
        scrapPercent = [];
        val.arr.forEach(function (arrval, arrindex) {
          annualVolume.push(arrval.annualVolume);
          setTime.push(arrval.setTime);
          setPerson.push(arrval.setPerson);
          pieceLot.push(arrval.pieceLot);
          pieceCost.push(arrval.pieceCost);
          name.push(arrval.name);
          equSize.push(arrval.equSize);
          quantity.push(arrval.quantity);
          pieceHour.push(arrval.pieceHour);
          pieceCycle.push(arrval.pieceCycle);
          efficiencyPercent.push(arrval.efficiencyPercent);
          standardHour.push(arrval.standardHour);
          directRate.push(arrval.directRate);
          setUpRate.push(arrval.setUpRate);
          directOperateQty.push(arrval.directOperateQty);
          assemblyDirectCost.push(arrval.assemblyDirectCost);
          variableRate.push(arrval.variableRate);
          fixedRate.push(arrval.fixedRate);
          depreciationRate.push(arrval.depreciationRate);
          totalBurdenRate.push(arrval.totalBurdenRate);
          assemblyBurdenCost.push(arrval.assemblyBurdenCost);
          totalCost.push(arrval.totalCost);
          scrapPercent.push(arrval.scrapPercent);
        });
        val.annualVolumeIssample =
          Array.from(new Set(annualVolume)).length > 1 ? false : true;
        val.setTimeIssample =
          Array.from(new Set(setTime)).length > 1 ? false : true;
        val.setPersonIssample =
          Array.from(new Set(setPerson)).length > 1 ? false : true;
        val.pieceLotIssample =
          Array.from(new Set(pieceLot)).length > 1 ? false : true;
        val.pieceCostIssample =
          Array.from(new Set(pieceCost)).length > 1 ? false : true;
        val.nameIssample = Array.from(new Set(name)).length > 1 ? false : true;
        val.equSizeIssample =
          Array.from(new Set(equSize)).length > 1 ? false : true;
        val.quantityIssample =
          Array.from(new Set(quantity)).length > 1 ? false : true;
        val.pieceHourIssample =
          Array.from(new Set(pieceHour)).length > 1 ? false : true;
        val.pieceCycleIssample =
          Array.from(new Set(pieceCycle)).length > 1 ? false : true;
        val.efficiencyPercentIssample =
          Array.from(new Set(efficiencyPercent)).length > 1 ? false : true;
        val.standardHourIssample =
          Array.from(new Set(standardHour)).length > 1 ? false : true;
        val.directRateIssample =
          Array.from(new Set(directRate)).length > 1 ? false : true;
        val.setUpRateIssample =
          Array.from(new Set(setUpRate)).length > 1 ? false : true;
        val.directOperateQtyIssample =
          Array.from(new Set(directOperateQty)).length > 1 ? false : true;
        val.assemblyDirectCostIssample =
          Array.from(new Set(assemblyDirectCost)).length > 1 ? false : true;
        val.variableRateIssample =
          Array.from(new Set(variableRate)).length > 1 ? false : true;
        val.fixedRateIssample =
          Array.from(new Set(fixedRate)).length > 1 ? false : true;
        val.depreciationRateIssample =
          Array.from(new Set(depreciationRate)).length > 1 ? false : true;
        val.totalBurdenRateIssample =
          Array.from(new Set(totalBurdenRate)).length > 1 ? false : true;
        val.assemblyBurdenCostIssample =
          Array.from(new Set(assemblyBurdenCost)).length > 1 ? false : true;
        val.totalCostIssample =
          Array.from(new Set(totalCost)).length > 1 ? false : true;
        val.scrapPercentIssample =
          Array.from(new Set(scrapPercent)).length > 1 ? false : true;
      });
      console.log(this.proInfo);
    },
    // 展示柱状图
    showChart() {
      let reportData = {
        partDescription: [],
        materialCost_SUM: [],
        setUpCost_SUM: [],
        purchaseCost_SUM: [],
        packageCost_SUM: [],
        laborCost_SUM: [],
        burdenCost_SUM: [],
        amortizeCost_SUM: [],
        sgaProfit_SUM: [],
        scrapCost_SUM: [],
        totalPriceCurr_SUM: [],
        totalPriceReport_SUM: [],
      };
      this.compareData.forEach(function (val, index) {
        reportData.partDescription.push(val.partDescription);
        reportData.materialCost_SUM.push(
          parseFloat(val.materialCost_SUM).toFixed(3)
        );
        reportData.setUpCost_SUM.push(parseFloat(val.setUpCost_SUM).toFixed(3));
        reportData.purchaseCost_SUM.push(
          parseFloat(val.purchaseCost_SUM).toFixed(3)
        );
        reportData.packageCost_SUM.push(
          parseFloat(val.packageCost_SUM).toFixed(3)
        );
        reportData.laborCost_SUM.push(parseFloat(val.laborCost_SUM).toFixed(3));
        reportData.burdenCost_SUM.push(
          parseFloat(val.burdenCost_SUM).toFixed(3)
        );
        reportData.amortizeCost_SUM.push(
          parseFloat(val.amortizeCost_SUM).toFixed(3)
        );
        reportData.sgaProfit_SUM.push(parseFloat(val.sgaProfit_SUM).toFixed(3));
        reportData.scrapCost_SUM.push(parseFloat(val.scrapCost_SUM).toFixed(3));
        reportData.totalPriceCurr_SUM.push(
          parseFloat(val.totalPriceCurr_SUM).toFixed(3)
        );
        reportData.totalPriceReport_SUM.push(
          parseFloat(val.totalPriceReport_SUM).toFixed(3)
        );
      });
      this.$refs.CompareDialog.drawChart(reportData);

      console.log(reportData);
    },
    // 删除对比数据
    handleClose(index) {
      console.log(index);
      this.compareData.splice(index, 1);
      this.compareGen(this.compareData);
      this.comparePl(this.compareData);
      this.compareSga(this.compareData);
      this.compareSum(this.compareData);
      this.compareRaw(this.compareData);
      this.comparePur(this.compareData);
      this.comparePro(this.compareData);
      this.addListNum++;
      // this.getCompareData()
    },
    // 初始化税率表区域选项
    initCurrencyOptions(data) {
      GetSelCurrency(data, (response) => {
        if (response && response.code == 200) {
          this.currencyOptions = response.data[0].exchangeList;
          this.currencyReportOptions = response.data[0].exchangeCNYList;
          this.genCurrOptions[0] = {
            currencyOptions: this.currencyOptions,
            currencyReportOptions: this.currencyReportOptions,
          };
        }
      });
    },
    // currency下拉框change事件
    currencyChange(index) {
      console.log(index);
      if (this.currencyInfo[index].Currency != "") {
        this.getCurrencyOptions(index);
      }
      if (this.currencyInfo[index].ReportCurrency != "") {
        let data = {
          currency: this.currencyInfo[index].Currency,
          reportCurrency: this.currencyInfo[index].ReportCurrency,
        };
        this.getRates(data, index);
      }
    },
    // reportcurrency下拉框change事件
    reportCurrencyChange(index) {
      console.log(index);
      if (this.currencyInfo[index].ReportCurrency != "") {
        this.getCurrencyOptions(index);
      }
      if (this.currencyInfo[index].Currency != "") {
        let data = {
          currency: this.currencyInfo[index].Currency,
          reportCurrency: this.currencyInfo[index].ReportCurrency,
        };
        this.getRates(data, index);
      }
    },
    // 查询税率表区域选项
    getCurrencyOptions(index) {
      GetSelCurrency(
        {
          CurrId: this.currencyInfo[index].Currency,
          CurrCNYId: this.currencyInfo[index].ReportCurrency,
          isEnable: 1,
        },
        (response) => {
          if (response && response.code == 200) {
            this.genCurrOptions[index] = {
              currencyOptions: response.data[0].exchangeList,
              currencyReportOptions: response.data[0].exchangeCNYList,
            };
            this.$forceUpdate();
          } else {
            commonUtil.showTipInfo(
              this.$t("userlists.userlist.inqueryTimeout")
            );
          }
        }
      );
    },
    // 获取汇率方法
    getRates(data, index) {
      GetRate(data, (response) => {
        if (response && response.code == 200) {
          if (response.result == null) {
            commonUtil.showTipInfo(
              "当前数据无汇率，请重新选择！",
              "",
              "warning"
            );
            this.currencyInfo[index].rate = "";
            this.currencyInfo[index].currencyRateId = "";
          } else {
            this.currencyInfo[index].rate = response.result.exchangeRate;
            this.currencyInfo[index].currencyRateId = response.result.id;
          }
          this.recount(index);
        }
        console.log(response);
      });
    },
    // 转变汇率重新计算
    recount(index) {
      let _this = this;
      console.log(this.originalCompareData[index].exchangeRate);
      GetRate(
        {
          currency: this.originalCompareData[index].exchangeRate.currency,
          reportCurrency: this.currencyInfo[index].Currency,
        },
        (response) => {
          if (response && response.code == 200) {
            if (response.result == null) {
              let materialList = JSON.parse(
                JSON.stringify(this.originalCompareData[index].materialList)
              );
              materialList.forEach(function (val, index) {
                val.netMWeight =
                  parseFloat(
                    val.netMWeight === "" || val.netMWeight === null
                      ? 0
                      : val.netMWeight
                  ) * 0;
                val.grossMWeight =
                  parseFloat(
                    val.grossMWeight === "" || val.grossMWeight === null
                      ? 0
                      : val.grossMWeight
                  ) * 0;
                val.unitCost =
                  parseFloat(
                    val.unitCost === "" || val.unitCost === null
                      ? 0
                      : val.unitCost
                  ) * 0;
                val.unitResaleValue =
                  parseFloat(
                    val.unitResaleValue === "" || val.unitResaleValue === null
                      ? 0
                      : val.unitResaleValue
                  ) * 0;
                val.cost =
                  parseFloat(
                    val.cost === "" || val.cost === null ? 0 : val.cost
                  ) * 0;
              });
              let purchaseList = JSON.parse(
                JSON.stringify(this.originalCompareData[index].purchaseList)
              );
              purchaseList.forEach(function (val, index) {
                val.quantity =
                  parseFloat(
                    val.quantity === "" || val.quantity === null
                      ? 0
                      : val.quantity
                  ) * 0;
                val.unitCost =
                  parseFloat(
                    val.unitCost === "" || val.unitCost === null
                      ? 0
                      : val.unitCost
                  ) * 0;
                val.totalCost =
                  parseFloat(
                    val.totalCost === "" || val.totalCost === null
                      ? 0
                      : val.totalCost
                  ) * 0;
                val.price =
                  parseFloat(
                    val.price === "" || val.price === null ? 0 : val.price
                  ) * 0;
              });
              let processList = JSON.parse(
                JSON.stringify(this.originalCompareData[index].processList)
              );
              processList.forEach(function (val, index) {
                val.annualVolume =
                  parseFloat(
                    val.annualVolume === "" || val.annualVolume === null
                      ? 0
                      : val.annualVolume
                  ) * 0;
                val.setTime =
                  parseFloat(
                    val.setTime === "" || val.setTime === null ? 0 : val.setTime
                  ) * 0;
                val.setPerson =
                  parseFloat(
                    val.setPerson === "" || val.setPerson === null
                      ? 0
                      : val.setPerson
                  ) * 0;
                val.pieceLot =
                  parseFloat(
                    val.pieceLot === "" || val.pieceLot === null
                      ? 0
                      : val.pieceLot
                  ) * 0;
                val.pieceCost =
                  parseFloat(
                    val.pieceCost === "" || val.pieceCost === null
                      ? 0
                      : val.pieceCost
                  ) * 0;
                val.quantity =
                  parseFloat(
                    val.quantity === "" || val.quantity === null
                      ? 0
                      : val.quantity
                  ) * 0;
                val.pieceHour =
                  parseFloat(
                    val.pieceHour === "" || val.pieceHour === null
                      ? 0
                      : val.pieceHour
                  ) * 0;
                val.pieceCycle =
                  parseFloat(
                    val.pieceCycle === "" || val.pieceCycle === null
                      ? 0
                      : val.pieceCycle
                  ) * 0;
                val.standardHour =
                  parseFloat(
                    val.standardHour === "" || val.standardHour === null
                      ? 0
                      : val.standardHour
                  ) * 0;
                val.directRate =
                  parseFloat(
                    val.directRate === "" || val.directRate === null
                      ? 0
                      : val.directRate
                  ) * 0;
                val.setUpRate =
                  parseFloat(
                    val.setUpRate === "" || val.setUpRate === null
                      ? 0
                      : val.setUpRate
                  ) * 0;
                val.directOperateQty =
                  parseFloat(
                    val.directOperateQty === "" || val.directOperateQty === null
                      ? 0
                      : val.directOperateQty
                  ) * 0;
                val.assemblyDirectCost =
                  parseFloat(
                    val.assemblyDirectCost === "" ||
                      val.assemblyDirectCost === null
                      ? 0
                      : val.assemblyDirectCost
                  ) * 0;
                val.variableRate =
                  parseFloat(
                    val.variableRate === "" || val.variableRate === null
                      ? 0
                      : val.variableRate
                  ) * 0;
                val.fixedRate =
                  parseFloat(
                    val.fixedRate === "" || val.fixedRate === null
                      ? 0
                      : val.fixedRate
                  ) * 0;
                val.depreciationRate =
                  parseFloat(
                    val.depreciationRate === "" || val.depreciationRate === null
                      ? 0
                      : val.depreciationRate
                  ) * 0;
                val.totalBurdenRate =
                  parseFloat(
                    val.totalBurdenRate === "" || val.totalBurdenRate === null
                      ? 0
                      : val.totalBurdenRate
                  ) * 0;
                val.assemblyBurdenCost =
                  parseFloat(
                    val.assemblyBurdenCost === "" ||
                      val.assemblyBurdenCost === null
                      ? 0
                      : val.assemblyBurdenCost
                  ) * 0;
                val.totalCost =
                  parseFloat(
                    val.totalCost === "" || val.totalCost === null
                      ? 0
                      : val.totalCost
                  ) * 0;
              });
              this.compareData[index].materialList = materialList;
              this.compareData[index].purchaseList = purchaseList;
              this.compareData[index].processList = processList;
              if (this.originalCompareData[index].calculationMethod == "1") {
                this.compareData[index].freightCost =
                  parseFloat(
                    this.originalCompareData[index].freightCost === "" ||
                      this.originalCompareData[index].freightCost === null
                      ? 0
                      : this.originalCompareData[index].freightCost
                  ) * 0;
                this.compareData[index].wareHouseCost =
                  parseFloat(
                    this.originalCompareData[index].wareHouseCost === "" ||
                      this.originalCompareData[index].wareHouseCost === null
                      ? 0
                      : this.originalCompareData[index].wareHouseCost
                  ) * 0;
                this.compareData[index].packageCost =
                  parseFloat(
                    this.originalCompareData[index].packageCost === "" ||
                      this.originalCompareData[index].packageCost === null
                      ? 0
                      : this.originalCompareData[index].packageCost
                  ) * 0;
                this.compareData[index].otherCost =
                  parseFloat(
                    this.originalCompareData[index].otherCost === "" ||
                      this.originalCompareData[index].otherCost === null
                      ? 0
                      : this.originalCompareData[index].otherCost
                  ) * 0;
              }
              this.compareData[index].totalPieces =
                parseFloat(
                  this.originalCompareData[index].totalPieces === "" ||
                    this.originalCompareData[index].totalPieces === null
                    ? 0
                    : this.originalCompareData[index].totalPieces
                ) * 0;
              this.compareData[index].totalAmortize =
                parseFloat(
                  this.originalCompareData[index].totalAmortize === "" ||
                    this.originalCompareData[index].totalAmortize === null
                    ? 0
                    : this.originalCompareData[index].totalAmortize
                ) * 0;

              this.compareData[index].sgaCost =
                parseFloat(
                  this.originalCompareData[index].sgaCost === "" ||
                    this.originalCompareData[index].sgaCost === null
                    ? 0
                    : this.originalCompareData[index].sgaCost
                ) * 0;
              this.compareData[index].totalProfitCost =
                parseFloat(
                  this.originalCompareData[index].totalProfitCost === "" ||
                    this.originalCompareData[index].totalProfitCost === null
                    ? 0
                    : this.originalCompareData[index].totalProfitCost
                ) * 0;

              this.compareData[index].materialCost_SUM =
                parseFloat(
                  this.originalCompareData[index].materialCost_SUM === "" ||
                    this.originalCompareData[index].materialCost_SUM === null
                    ? 0
                    : this.originalCompareData[index].materialCost_SUM
                ) * 0;
              this.compareData[index].setUpCost_SUM =
                parseFloat(
                  this.originalCompareData[index].setUpCost_SUM === "" ||
                    this.originalCompareData[index].setUpCost_SUM === null
                    ? 0
                    : this.originalCompareData[index].setUpCost_SUM
                ) * 0;
              this.compareData[index].purchaseCost_SUM =
                parseFloat(
                  this.originalCompareData[index].purchaseCost_SUM === "" ||
                    this.originalCompareData[index].purchaseCost_SUM === null
                    ? 0
                    : this.originalCompareData[index].purchaseCost_SUM
                ) * 0;
              this.compareData[index].packageCost_SUM =
                parseFloat(
                  this.originalCompareData[index].packageCost_SUM === "" ||
                    this.originalCompareData[index].packageCost_SUM === null
                    ? 0
                    : this.originalCompareData[index].packageCost_SUM
                ) * 0;
              this.compareData[index].laborCost_SUM =
                parseFloat(
                  this.originalCompareData[index].laborCost_SUM === "" ||
                    this.originalCompareData[index].laborCost_SUM === null
                    ? 0
                    : this.originalCompareData[index].laborCost_SUM
                ) * 0;
              this.compareData[index].otherCost_SUM =
                parseFloat(
                  this.originalCompareData[index].otherCost_SUM === "" ||
                    this.originalCompareData[index].otherCost_SUM === null
                    ? 0
                    : this.originalCompareData[index].otherCost_SUM
                ) * 0;
              this.compareData[index].burdenCost_SUM =
                parseFloat(
                  this.originalCompareData[index].burdenCost_SUM === "" ||
                    this.originalCompareData[index].burdenCost_SUM === null
                    ? 0
                    : this.originalCompareData[index].burdenCost_SUM
                ) * 0;
              this.compareData[index].amortizeCost_SUM =
                parseFloat(
                  this.originalCompareData[index].amortizeCost_SUM === "" ||
                    this.originalCompareData[index].amortizeCost_SUM === null
                    ? 0
                    : this.originalCompareData[index].amortizeCost_SUM
                ) * 0;
              this.compareData[index].sgaProfit_SUM =
                parseFloat(
                  this.originalCompareData[index].sgaProfit_SUM === "" ||
                    this.originalCompareData[index].sgaProfit_SUM === null
                    ? 0
                    : this.originalCompareData[index].sgaProfit_SUM
                ) * 0;
              this.compareData[index].scrapCost_SUM =
                parseFloat(
                  this.originalCompareData[index].scrapCost_SUM === "" ||
                    this.originalCompareData[index].scrapCost_SUM === null
                    ? 0
                    : this.originalCompareData[index].scrapCost_SUM
                ) * 0;
              this.compareData[index].totalPriceCurr_SUM =
                parseFloat(
                  this.originalCompareData[index].totalPriceCurr_SUM === "" ||
                    this.originalCompareData[index].totalPriceCurr_SUM === null
                    ? 0
                    : this.originalCompareData[index].totalPriceCurr_SUM
                ) * 0;
              this.compareData[index].manufacturingCost_PRO =
                parseFloat(
                  this.originalCompareData[index].manufacturingCost_PRO ===
                    "" ||
                    this.originalCompareData[index].manufacturingCost_PRO ===
                      null
                    ? 0
                    : this.originalCompareData[index].manufacturingCost_PRO
                ) * 0;
              console.log(this.currencyInfo[index].rate);
              if (this.currencyInfo[index].rate === "") {
                this.compareData[index].totalPriceReport_SUM = 0;
              } else {
                this.compareData[index].totalPriceReport_SUM =
                  parseFloat(
                    this.compareData[index].totalPriceCurr_SUM === "" ||
                      this.compareData[index].totalPriceCurr_SUM === null
                      ? 0
                      : this.compareData[index].totalPriceCurr_SUM
                  ) * parseFloat(this.currencyInfo[index].rate);
              }
              console.log(_this.currencyInfo[index]);
              this.genCurrOptions[index].currencyOptions.forEach(function (
                val,
                ind
              ) {
                if (val.currency === _this.currencyInfo[index].Currency) {
                  _this.compareData[index].exchangeRate.currencySymbolName =
                    val.currencySymbol;
                }
              });
              this.genCurrOptions[index].currencyReportOptions.forEach(
                function (val, ind) {
                  if (
                    val.currencyCNYReportCurrency ===
                    _this.currencyInfo[index].ReportCurrency
                  ) {
                    _this.compareData[
                      index
                    ].exchangeRate.reportCurrencySymbolName =
                      val.currencyCNYSymbol;
                  }
                }
              );
              //
              this.compareRaw(this.compareData);
              this.comparePur(this.compareData);
              this.comparePro(this.compareData);
              this.comparePl(this.compareData);
              this.compareSga(this.compareData);
              this.compareSum(this.compareData);
            } else {
              let materialList = JSON.parse(
                JSON.stringify(this.originalCompareData[index].materialList)
              );
              materialList.forEach(function (val, index) {
                val.netMWeight =
                  parseFloat(
                    val.netMWeight === "" || val.netMWeight === null
                      ? 0
                      : val.netMWeight
                  ) * parseFloat(response.result.exchangeRate);
                val.grossMWeight =
                  parseFloat(
                    val.grossMWeight === "" || val.grossMWeight === null
                      ? 0
                      : val.grossMWeight
                  ) * parseFloat(response.result.exchangeRate);
                val.unitCost =
                  parseFloat(
                    val.unitCost === "" || val.unitCost === null
                      ? 0
                      : val.unitCost
                  ) * parseFloat(response.result.exchangeRate);
                val.unitResaleValue =
                  parseFloat(
                    val.unitResaleValue === "" || val.unitResaleValue === null
                      ? 0
                      : val.unitResaleValue
                  ) * parseFloat(response.result.exchangeRate);
                val.cost =
                  parseFloat(
                    val.cost === "" || val.cost === null ? 0 : val.cost
                  ) * parseFloat(response.result.exchangeRate);
              });
              let purchaseList = JSON.parse(
                JSON.stringify(this.originalCompareData[index].purchaseList)
              );
              purchaseList.forEach(function (val, index) {
                val.quantity =
                  parseFloat(
                    val.quantity === "" || val.quantity === null
                      ? 0
                      : val.quantity
                  ) * parseFloat(response.result.exchangeRate);
                val.unitCost =
                  parseFloat(
                    val.unitCost === "" || val.unitCost === null
                      ? 0
                      : val.unitCost
                  ) * parseFloat(response.result.exchangeRate);
                val.totalCost =
                  parseFloat(
                    val.totalCost === "" || val.totalCost === null
                      ? 0
                      : val.totalCost
                  ) * parseFloat(response.result.exchangeRate);
                val.price =
                  parseFloat(
                    val.price === "" || val.price === null ? 0 : val.price
                  ) * parseFloat(response.result.exchangeRate);
              });
              let processList = JSON.parse(
                JSON.stringify(this.originalCompareData[index].processList)
              );
              processList.forEach(function (val, index) {
                val.annualVolume =
                  parseFloat(
                    val.annualVolume === "" || val.annualVolume === null
                      ? 0
                      : val.annualVolume
                  ) * parseFloat(response.result.exchangeRate);
                val.setTime =
                  parseFloat(
                    val.setTime === "" || val.setTime === null ? 0 : val.setTime
                  ) * parseFloat(response.result.exchangeRate);
                val.setPerson =
                  parseFloat(
                    val.setPerson === "" || val.setPerson === null
                      ? 0
                      : val.setPerson
                  ) * parseFloat(response.result.exchangeRate);
                val.pieceLot =
                  parseFloat(
                    val.pieceLot === "" || val.pieceLot === null
                      ? 0
                      : val.pieceLot
                  ) * parseFloat(response.result.exchangeRate);
                val.pieceCost =
                  parseFloat(
                    val.pieceCost === "" || val.pieceCost === null
                      ? 0
                      : val.pieceCost
                  ) * parseFloat(response.result.exchangeRate);
                val.quantity =
                  parseFloat(
                    val.quantity === "" || val.quantity === null
                      ? 0
                      : val.quantity
                  ) * parseFloat(response.result.exchangeRate);
                val.pieceHour =
                  parseFloat(
                    val.pieceHour === "" || val.pieceHour === null
                      ? 0
                      : val.pieceHour
                  ) * parseFloat(response.result.exchangeRate);
                val.pieceCycle =
                  parseFloat(
                    val.pieceCycle === "" || val.pieceCycle === null
                      ? 0
                      : val.pieceCycle
                  ) * parseFloat(response.result.exchangeRate);
                val.standardHour =
                  parseFloat(
                    val.standardHour === "" || val.standardHour === null
                      ? 0
                      : val.standardHour
                  ) * parseFloat(response.result.exchangeRate);
                val.directRate =
                  parseFloat(
                    val.directRate === "" || val.directRate === null
                      ? 0
                      : val.directRate
                  ) * parseFloat(response.result.exchangeRate);
                val.setUpRate =
                  parseFloat(
                    val.setUpRate === "" || val.setUpRate === null
                      ? 0
                      : val.setUpRate
                  ) * parseFloat(response.result.exchangeRate);
                val.directOperateQty =
                  parseFloat(
                    val.directOperateQty === "" || val.directOperateQty === null
                      ? 0
                      : val.directOperateQty
                  ) * parseFloat(response.result.exchangeRate);
                val.assemblyDirectCost =
                  parseFloat(
                    val.assemblyDirectCost === "" ||
                      val.assemblyDirectCost === null
                      ? 0
                      : val.assemblyDirectCost
                  ) * parseFloat(response.result.exchangeRate);
                val.variableRate =
                  parseFloat(
                    val.variableRate === "" || val.variableRate === null
                      ? 0
                      : val.variableRate
                  ) * parseFloat(response.result.exchangeRate);
                val.fixedRate =
                  parseFloat(
                    val.fixedRate === "" || val.fixedRate === null
                      ? 0
                      : val.fixedRate
                  ) * parseFloat(response.result.exchangeRate);
                val.depreciationRate =
                  parseFloat(
                    val.depreciationRate === "" || val.depreciationRate === null
                      ? 0
                      : val.depreciationRate
                  ) * parseFloat(response.result.exchangeRate);
                val.totalBurdenRate =
                  parseFloat(
                    val.totalBurdenRate === "" || val.totalBurdenRate === null
                      ? 0
                      : val.totalBurdenRate
                  ) * parseFloat(response.result.exchangeRate);
                val.assemblyBurdenCost =
                  parseFloat(
                    val.assemblyBurdenCost === "" ||
                      val.assemblyBurdenCost === null
                      ? 0
                      : val.assemblyBurdenCost
                  ) * parseFloat(response.result.exchangeRate);
                val.totalCost =
                  parseFloat(
                    val.totalCost === "" || val.totalCost === null
                      ? 0
                      : val.totalCost
                  ) * parseFloat(response.result.exchangeRate);
              });
              this.compareData[index].materialList = materialList;
              this.compareData[index].purchaseList = purchaseList;
              this.compareData[index].processList = processList;
              if (this.originalCompareData[index].calculationMethod == "1") {
                this.compareData[index].freightCost =
                  parseFloat(
                    this.originalCompareData[index].freightCost === "" ||
                      this.originalCompareData[index].freightCost === null
                      ? 0
                      : this.originalCompareData[index].freightCost
                  ) * parseFloat(response.result.exchangeRate);
                this.compareData[index].wareHouseCost =
                  parseFloat(
                    this.originalCompareData[index].wareHouseCost === "" ||
                      this.originalCompareData[index].wareHouseCost === null
                      ? 0
                      : this.originalCompareData[index].wareHouseCost
                  ) * parseFloat(response.result.exchangeRate);
                this.compareData[index].packageCost =
                  parseFloat(
                    this.originalCompareData[index].packageCost === "" ||
                      this.originalCompareData[index].packageCost === null
                      ? 0
                      : this.originalCompareData[index].packageCost
                  ) * parseFloat(response.result.exchangeRate);
                this.compareData[index].otherCost =
                  parseFloat(
                    this.originalCompareData[index].otherCost === "" ||
                      this.originalCompareData[index].otherCost === null
                      ? 0
                      : this.originalCompareData[index].otherCost
                  ) * parseFloat(response.result.exchangeRate);
              }
              this.compareData[index].totalPieces =
                parseFloat(
                  this.originalCompareData[index].totalPieces === "" ||
                    this.originalCompareData[index].totalPieces === null
                    ? 0
                    : this.originalCompareData[index].totalPieces
                ) * parseFloat(response.result.exchangeRate);
              this.compareData[index].totalAmortize =
                parseFloat(
                  this.originalCompareData[index].totalAmortize === "" ||
                    this.originalCompareData[index].totalAmortize === null
                    ? 0
                    : this.originalCompareData[index].totalAmortize
                ) * parseFloat(response.result.exchangeRate);

              this.compareData[index].sgaCost =
                parseFloat(
                  this.originalCompareData[index].sgaCost === "" ||
                    this.originalCompareData[index].sgaCost === null
                    ? 0
                    : this.originalCompareData[index].sgaCost
                ) * parseFloat(response.result.exchangeRate);
              this.compareData[index].totalProfitCost =
                parseFloat(
                  this.originalCompareData[index].totalProfitCost === "" ||
                    this.originalCompareData[index].totalProfitCost === null
                    ? 0
                    : this.originalCompareData[index].totalProfitCost
                ) * parseFloat(response.result.exchangeRate);

              this.compareData[index].materialCost_SUM =
                parseFloat(
                  this.originalCompareData[index].materialCost_SUM === "" ||
                    this.originalCompareData[index].materialCost_SUM === null
                    ? 0
                    : this.originalCompareData[index].materialCost_SUM
                ) * parseFloat(response.result.exchangeRate);
              this.compareData[index].setUpCost_SUM =
                parseFloat(
                  this.originalCompareData[index].setUpCost_SUM === "" ||
                    this.originalCompareData[index].setUpCost_SUM === null
                    ? 0
                    : this.originalCompareData[index].setUpCost_SUM
                ) * parseFloat(response.result.exchangeRate);
              this.compareData[index].purchaseCost_SUM =
                parseFloat(
                  this.originalCompareData[index].purchaseCost_SUM === "" ||
                    this.originalCompareData[index].purchaseCost_SUM === null
                    ? 0
                    : this.originalCompareData[index].purchaseCost_SUM
                ) * parseFloat(response.result.exchangeRate);
              this.compareData[index].packageCost_SUM =
                parseFloat(
                  this.originalCompareData[index].packageCost_SUM === "" ||
                    this.originalCompareData[index].packageCost_SUM === null
                    ? 0
                    : this.originalCompareData[index].packageCost_SUM
                ) * parseFloat(response.result.exchangeRate);
              this.compareData[index].laborCost_SUM =
                parseFloat(
                  this.originalCompareData[index].laborCost_SUM === "" ||
                    this.originalCompareData[index].laborCost_SUM === null
                    ? 0
                    : this.originalCompareData[index].laborCost_SUM
                ) * parseFloat(response.result.exchangeRate);
              this.compareData[index].otherCost_SUM =
                parseFloat(
                  this.originalCompareData[index].otherCost_SUM === "" ||
                    this.originalCompareData[index].otherCost_SUM === null
                    ? 0
                    : this.originalCompareData[index].otherCost_SUM
                ) * parseFloat(response.result.exchangeRate);
              this.compareData[index].burdenCost_SUM =
                parseFloat(
                  this.originalCompareData[index].burdenCost_SUM === "" ||
                    this.originalCompareData[index].burdenCost_SUM === null
                    ? 0
                    : this.originalCompareData[index].burdenCost_SUM
                ) * parseFloat(response.result.exchangeRate);
              this.compareData[index].amortizeCost_SUM =
                parseFloat(
                  this.originalCompareData[index].amortizeCost_SUM === "" ||
                    this.originalCompareData[index].amortizeCost_SUM === null
                    ? 0
                    : this.originalCompareData[index].amortizeCost_SUM
                ) * parseFloat(response.result.exchangeRate);
              this.compareData[index].sgaProfit_SUM =
                parseFloat(
                  this.originalCompareData[index].sgaProfit_SUM === "" ||
                    this.originalCompareData[index].sgaProfit_SUM === null
                    ? 0
                    : this.originalCompareData[index].sgaProfit_SUM
                ) * parseFloat(response.result.exchangeRate);
              this.compareData[index].scrapCost_SUM =
                parseFloat(
                  this.originalCompareData[index].scrapCost_SUM === "" ||
                    this.originalCompareData[index].scrapCost_SUM === null
                    ? 0
                    : this.originalCompareData[index].scrapCost_SUM
                ) * parseFloat(response.result.exchangeRate);
              this.compareData[index].totalPriceCurr_SUM =
                parseFloat(
                  this.originalCompareData[index].totalPriceCurr_SUM === "" ||
                    this.originalCompareData[index].totalPriceCurr_SUM === null
                    ? 0
                    : this.originalCompareData[index].totalPriceCurr_SUM
                ) * parseFloat(response.result.exchangeRate);
              this.compareData[index].manufacturingCost_PRO =
                parseFloat(
                  this.originalCompareData[index].manufacturingCost_PRO ===
                    "" ||
                    this.originalCompareData[index].manufacturingCost_PRO ===
                      null
                    ? 0
                    : this.originalCompareData[index].manufacturingCost_PRO
                ) * parseFloat(response.result.manufacturingCost_PRO);
              console.log(this.currencyInfo[index].rate);
              if (this.currencyInfo[index].rate === "") {
                this.compareData[index].totalPriceReport_SUM = 0;
              } else {
                this.compareData[index].totalPriceReport_SUM =
                  parseFloat(
                    this.compareData[index].totalPriceCurr_SUM === "" ||
                      this.compareData[index].totalPriceCurr_SUM === null
                      ? 0
                      : this.compareData[index].totalPriceCurr_SUM
                  ) * parseFloat(this.currencyInfo[index].rate);
              }
              console.log(_this.currencyInfo[index]);
              this.genCurrOptions[index].currencyOptions.forEach(function (
                val,
                ind
              ) {
                if (val.currency === _this.currencyInfo[index].Currency) {
                  _this.compareData[index].exchangeRate.currencySymbolName =
                    val.currencySymbol;
                }
              });
              this.genCurrOptions[index].currencyReportOptions.forEach(
                function (val, ind) {
                  if (
                    val.currencyCNYReportCurrency ===
                    _this.currencyInfo[index].ReportCurrency
                  ) {
                    _this.compareData[
                      index
                    ].exchangeRate.reportCurrencySymbolName =
                      val.currencyCNYSymbol;
                  }
                }
              );
              //
              this.compareRaw(this.compareData);
              this.comparePur(this.compareData);
              this.comparePro(this.compareData);
              this.comparePl(this.compareData);
              this.compareSga(this.compareData);
              this.compareSum(this.compareData);
            }
          }
        }
      );
      console.log(this.compareData[index]);
    },
    // 新增对比数据
    addCompareData() {
      this.$refs.chooseDia.getlist();
      this.$refs.chooseDia.visible = true;
    },
    // 获取选择的对比数据
    getChooseId(ids) {
      this.getCompareData(ids);
      this.addListNum = this.addListNum - ids.length;
    },
    // 汇率转变
    changeRate(index) {
      console.log(index);
      console.log(this.currencyInfo[index].rate);
      if (this.currencyInfo[index].rate === "") {
        this.compareData[index].totalPriceReport_SUM = 0;
      } else {
        this.compareData[index].totalPriceReport_SUM =
          parseFloat(
            this.compareData[index].totalPriceCurr_SUM === "" ||
              this.compareData[index].totalPriceCurr_SUM === null
              ? 0
              : this.compareData[index].totalPriceCurr_SUM
          ) * parseFloat(this.currencyInfo[index].rate);
      }
      this.compareSum(this.compareData);
    },
    // 导出pdf
    toExport() {
      this.downloadNum++;
      localStorage.setItem("downloadNum", this.downloadNum);
      let num = this.prefixZero(this.downloadNum, 3);
      console.log(num);
      // this.reportShow = false
      htmlToPdf.downloadPDF(
        document.querySelector("#exportPdf"),
        "COMPARE" + this.currentDate + num
      );
    },
    // 位数补足
    prefixZero(n, m) {
      var _a = (Array(m).join(0) + n).slice(-m);
      return _a;
    },
  },
};
</script>

<style scoped lang="scss">
.tcm-compare {
  padding-top: 90px;
  position: relative;
  background: #fff;
}
.tcm-title {
  position: relative;
  position: fixed;
  top: 100px;
  left: 235px;
  width: calc(100% - 260px);
  border-bottom: 1px solid #b2b2b2;
  padding: 20px;
  box-sizing: border-box;
  font-size: 14px;
  display: flex;
  align-items: center;
  font-weight: bold;
  background-color: #fff;
  z-index: 999;
}
.line-45 {
  line-height: 45px;
  white-space: nowrap;
}
.margin-10 {
  margin: 0 10px;
}
.tcm-compare-cla {
  padding: 20px 90px 20px 20px;
  box-sizing: border-box;
}
.tcm-content-title {
  /* background-color: #f2f2f2; */
  border-radius: 5px;
  padding: 20px 20px 20px 50px;
  font-size: 16px;
  font-weight: bold;
  position: relative;
}
.tcm-content-title:before {
  content: "";
  display: table;
  width: 6px;
  height: 6px;
  background: #000;
  border-radius: 50%;
  position: absolute;
  left: 30px;
  top: 27px;
}
.tcm-content-form {
  width: calc(100% - 50px);
  margin: 10px 0;
  margin-left: 50px;
  background-color: #f2f2f2;
  border-radius: 5px;
  padding: 20px;
  box-sizing: border-box;
  overflow-x: auto;
}
.color-red {
  color: red;
}
.td-width {
  width: 16%;
  padding: 0 10px;
  box-sizing: border-box;
  background: #fff;
  border-radius: 5px;
  margin-right: 10px;
}
.td-width-nobg {
  width: 16%;
  /* padding: 0 10px; */
  box-sizing: border-box;
  border-radius: 5px;
  margin-right: 10px;
}
.td-title-width {
  width: 16%;
  margin-right: 10px;
}
table {
  width: 100%;
  box-sizing: border-box;
  border-spacing: 5px 10px;
  table-layout: fixed;
}
.tcm-content-form tr {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 10px;
}
.form-type-title {
  width: 100px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  background: #fff;
  line-height: 35px;
  border-radius: 5px;
  padding: 0 10px;
  margin-bottom: 10px;
}
.add-cla {
  width: 140px;
  height: 45px;
  color: #909399;
  font-size: 16px;
  border: 1px solid #e9e9eb;
  border-radius: 4px;
  text-align: center;
  line-height: 45px;
  margin-right: 5px;
  cursor: pointer;
}
.export-btn {
  margin-left: 15px;
  // position: absolute;
  // right: 90px;
  // top: 28px;
}
.top-box {
  position: fixed;
  width: calc(100% - 260px);
  top: 70px;
  left: 235px;
  z-index: 99;
  // background-color: #fff;
}
.crumbs-box {
  padding-left: 20px;
  width: 100%;
  display: flex;
  background-color: #f2f2f2;
  // border-bottom: 1px solid #e4e7ed;
  align-items: center;
  height: 30px;
  font-size: 16px;
  color: #3e4954;
  .crumbs-box-item {
    display: flex;
    align-items: center;
  }
  .el-icon {
    margin: 0 10px;
  }
  .parent {
    color: #2f4cdd;
    cursor: pointer;
  }
  .line {
    margin: 0 15px;
    width: 2px;
    height: 15px;
    background: #3e4954;
  }
}
</style>