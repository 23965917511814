<template>
    <el-dialog v-model="visible" width="400px" :show-close="false">
        <template #header="{ close }">
            <div class="my-header">
                <div class="size-16">
                    {{ $t("tcmlists.tcmlist.department") }}
                </div>
                <div style="cursor: pointer" @click="close">
                    <el-icon :size="20"  style="color: var(--themeColor)">
                        <CircleCloseFilled />
                    </el-icon>
                </div>
            </div>
        </template>
        <div class="tcm-table">
            <el-row>
                <el-col>
                    <div class="tree-cla">
                        <el-tree
                            :empty-text="$t('tcmlists.tcmlist.noData')"
                            ref="treeRef"
                            class="filter-tree"
                            :data="treedata"
                            node-key="id"
                            :props="defaultProps"
                            default-expand-all
                            show-checkbox
                            @check-change="handleCheckChange"
                            :check-strictly="true"
                        />
                    </div>
                </el-col>
            </el-row>
        </div>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="close">{{
                    $t("ccls.ccl.cancel")
                }}</el-button>
                <el-button
                    style="background-color: var(--themeColor); color: var(--menuTextActive);margin-right: 16px"
                    @click="handleSubmit"
                    >{{ $t("ccls.ccl.confirm") }}</el-button
                >
            </span>
        </template>
    </el-dialog>
</template>

<script>
import { GetOrgs } from "@/assets/js/requestApi.js";
import commonUtil from "../../assets/js/commonUtil.js";
export default {
    props: ["userId"],
    data() {
        return {
            visible: false,
            defaultProps: {
                children: "children",
                label: "Name",
            },
            treedata: [],
            form: {
                Id: "",
                Name: "",
            },
        };
    },

    methods: {
        close() {
            this.visible = false;
        },
        // 树点击
        handleCheckChange(data, checked, indeterminate) {
        },
        // 提交按钮
        handleSubmit() {
            let res = this.$refs.treeRef.getCheckedNodes();
            if (res == "") {
                commonUtil.showTipInfo(
                    this.$t(
                        "changePriceLists.changePriceList.commodityRequired"
                    ),
                    "",
                    "warning"
                );
            }
            this.visible = false;
            this.form.Id = res[0].Id;
            this.form.Name = res[0].Name;
            this.$emit("handleSubmit", this.form);
            this.visible = false;
        },

        // 树接口
        getTree() {
            GetOrgs(
                {
                    type: 0,
                    versionType: 0,
					TypeCost: 0,
					isAll: 0
                },
                (response) => {
                    if (response && response.Code == 200) {
                        // let treedata = response.Result;
                        this.treedata = this.transferList(response.Result);
                        // 部门列表回显
                        // echoList('"' + this.userId + '"', (response) => {
                        //   if (response && response.code == 200) {
                        //     let arr = [];
                        //     for (let i = 0; i < treedata.length; i++) {
                        //       for (let j = 0; j < response.data.length; j++) {
                        //         if (treedata[i].id == response.data[j].id) {
                        //           arr.push(treedata[i]);
                        //         }
                        //       }
                        //     }
                        //     console.log("arr", arr);
                        //     this.arr = arr;
                        //     this.$refs.treeRef.setCheckedNodes(arr);
                        //   }
                        // });
                    }
                }
            );
        },
        filterNode(value, data) {
            if (!value) return true;
            return data.label.indexOf(value) !== -1;
        },
        // 数据转化
        transferList(data) {
            const res = [];
            data.forEach((item) => {
                const parent = data.find((node) => node.Id === item.ParentId);
                if (parent) {
                    parent.children = parent.children || [];
                    parent.children.push(item);
                } else {
                    // * 根节点
                    res.push(item);
                }
            });
            return res;
        },
    },
};
</script>

<style scoped>
.my-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.size-16 {
    font-size: 16px;
}
</style>
