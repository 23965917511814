import Home from './data/homePage/index.js'
import Login from './data/login/index.js'
import Tcmdatalist from './data/tcmdatalist/index'
import ChangePriceList from './data/changePriceList/index'
import Calculator from './data/calculator/index'
import TemplateList from './data/templateList/index'
import Userlist from './data/userlist/index'
import Ccl from './data/ccl/index'
import Tcmlist from './data/tcmlist/index'
import Caidan from './data/caidan/index'
import UnifiedPrompt from './data/unifiedPrompt/index'
import Pcba from './data/pcba/index'
import AuditComments from './data/components/index'
import other from './data/other/index'

const en = {
    language: {
        name: 'English'
    },
    homePage: {
        home: Home.HomePage.EN
    },
    logins: {
        login: Login.Logins.EN
    },
    tcmdatalists: {
        tcmdatalist: Tcmdatalist.Tcmdatalists.EN
    },
    changePriceLists: {
        changePriceList: ChangePriceList.ChangePriceLists.EN
    },
    calculators: {
        calculator: Calculator.Calculators.EN
    },
    templateLists: {
        templateList: TemplateList.TemplateLists.EN
    },
    userlists: {
        userlist: Userlist.Userlists.EN
    },
    ccls: {
        ccl: Ccl.Ccls.EN
    },
    tcmlists: {
        tcmlist: Tcmlist.Tcmlists.EN
    },
    caidans: {
        caidan: Caidan.Caidans.EN
    },
    unifiedPrompts: {
        unifiedPrompt: UnifiedPrompt.UnifiedPrompts.EN
    },
    pcbas: {
        pcba: Pcba.Pcbas.EN
    },
    components: {
        AuditComments: AuditComments.AuditComments.EN
    },
    other: {
        other: other.other.EN
    }
}
export default en