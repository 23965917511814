<template>
  <div>
    <div class="crumbs-box">
      <div class="crumbs-box-item parent" @click="Goback">
        {{ $t("homePage.home.Back") }}
      </div>
      <div class="line"></div>
      <div class="crumbs-box-item" v-for="(item, index) in crumbs">
        {{ item }}
        <el-icon v-show="index != crumbs.length - 1"><ArrowRight /></el-icon>
      </div>
    </div>
    <div class="main">
      <div class="handle-box" v-show="type != 'detail'">
        <div class="submit-btn" @click="submit">
          {{ $t("ccls.ccl.submit") }}
        </div>
      </div>
      <TcmGen
        ref="gen"
        :type="type"
        operateType="application"
        :QuoteTypeOptions="typeOptions"
        :RegionOptions="regionOptions"
        :CommodityOptions="CommodityOptions"
        :urgencyOptions="urgencyOptions"
        :urgencyOptionss="urgencyOptionss"
        :currencyOptions="currencyOptions"
        :currencyReportOptions="currencyReportOptions"
      ></TcmGen>
      <tcmInfo ref="tcmInfo"></tcmInfo>
    </div>
    <el-dialog
      v-model="OpenModalFlag"
      width="75%"
      destroy-on-close
      :show-close="false"
    >
      <template #header="{ close }">
        <div class="my-header">
          <div class="size-16"></div>
          <div style="cursor: pointer" @click="close">
            <el-icon :size="20"><Close /></el-icon>
          </div>
        </div>
      </template>
      <el-dialog v-model="innerVisible" width="50%" title="Tips" append-to-body>
        <el-alert
          style="margin-bottom: 20px; font-size: 16px"
          :closable="false"
          :title="$t('changePriceLists.changePriceList.onlyOnePartNumber')"
          type="warning"
        />
        <el-table :data="repeatData" style="width: 100%">
          <el-table-column
            prop="regionName"
            :label="$t('changePriceLists.changePriceList.region')"
          />
          <el-table-column
            prop="partNumber"
            :label="$t('changePriceLists.changePriceList.partNumber')"
          >
            <template #default="scope">
              <span
                style="color: blue; cursor: pointer; text-decoration: underline"
                @click="PreviewBtn(scope.row.costApplyId)"
                >{{ scope.row.partNumber }}</span
              >
            </template>
          </el-table-column>
        </el-table>
      </el-dialog>
      <el-alert
        style="margin-bottom: 20px; font-size: 17px"
        :closable="false"
        :title="$t('templateLists.templateList.tcmaddTips')"
        type="warning"
      />
      <el-table
        :data="tableData"
        style="width: 100%"
        height="400"
        @select="onSelect"
        @select-all="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column
          prop="regionName"
          :label="$t('changePriceLists.changePriceList.region')"
        />
        <el-table-column
          prop="partNumber"
          :label="$t('changePriceLists.changePriceList.partNumber')"
        >
          <template #default="scope">
            <span
              style="color: blue; cursor: pointer; text-decoration: underline"
              @click="PreviewBtn(scope.row.costApplyId)"
              >{{ scope.row.partNumber }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="partDescription"
          :label="$t('changePriceLists.changePriceList.partDescription')"
        >
          <template #default="scope">
            <span
              style="color: blue; cursor: pointer; text-decoration: underline"
              @click="PreviewBtn(scope.row.costApplyId)"
              >{{ scope.row.partDescription }}</span
            >
            <!-- partDescription partDescription  -->
          </template>
        </el-table-column>
        <el-table-column
          prop="version"
          :label="$t('templateLists.templateList.version')"
        />
        <el-table-column
          prop="comment"
          :label="$t('changePriceLists.changePriceList.comment')"
        />
        <el-table-column
          prop="reportCurrencyName"
          :label="$t('changePriceLists.changePriceList.reportCurrency')"
        />
        <el-table-column
          prop="totalPriceReport_SUM"
          :label="$t('changePriceLists.changePriceList.totalPrice')"
        >
          <template #default="scope">
            {{
              scope.row.totalPriceReport_SUM - 0
                ? (scope.row.totalPriceReport_SUM - 0).toFixed(3)
                : ""
            }}
          </template>
        </el-table-column>
      </el-table>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="close">{{
            $t("userlists.userlist.cancel")
          }}</el-button>
          <el-button
            style="
              background-color: var(--themeColor);
              color: var(--menuTextActive);
              margin-right: 16px;
            "
            @click="SelectRegionConfirm"
            >{{ $t("userlists.userlist.confirm") }}</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import TcmGen from "./ApplicationGen.vue";
import tcmInfo from "../tcmTemplateManagement/ChangePrice.vue";
import {
  getQuoteTypes,
  getRegions,
  GetOrgs,
  getCategorys,
  uploadFile,
  AddOrUpdateForAppllicant,
  GetApplyDetail,
  GetSelCurrency,
  GetApplicationReqs,
} from "../../assets/js/requestApi.js";
import commonUtil from "../../assets/js/commonUtil.js";
export default {
  name: "TcmAdd",
  components: {
    TcmGen,
    tcmInfo,
  },
  provide() {
    return {
      showHandleBtn: true,
    };
  },
  data() {
    return {
      id: "",
      type: "add",
      chooseCurrent: "gen",
      // 报价类型选项
      typeOptions: [],
      // 区域选项
      regionOptions: [],
      // 商品分类选项
      CommodityOptions: [],
      // 等级选项
      urgencyOptions: [],
      urgencyOptionss: [],
      // 上传后的文件列表
      pictureList: [],
      // 是否上传结束
      uploadStatus: false,
      // 税率表区域选项
      currencyOptions: [],
      currencyReportOptions: [],
      // 操作时间
      doDate: "",
      OpenModalFlag: false,
      tableData: [],
      crumbs: [],
      chooseData: [],
      innerVisible: false,
      repeatData: [],
      // 序号
    };
  },
  activated() {},
  mounted() {
    this.$route.meta.showTag = true;
    this.getQuoteType();
    // this.getRegion();
    this.regionOptions = JSON.parse(sessionStorage.getItem("Region"));
    // this.getCommdity();
    // 获取getCommdity
    this.CommodityOptions = JSON.parse(sessionStorage.getItem("Commodity"));
    this.getCategorysOptions();
    this.getCategorysOptionss();
    this.getCurrencyOptions({
      CurrId: "",
      CurrCNYId: "",
      isEnable: 1,
    });

    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getDetail();
    }
  },
  watch: {
    uploadStatus: {
      handler(nval, oval) {
        if (nval) {
          this.addTcm();
        }
      },
      deep: true,
    },
    "$i18n.locale"() {
      this.ChangeLan();
    },
    $route: {
      immediate: true,
      handler(nval) {
        this.type = this.$route.query.type;
        this.ChangeLan(nval);
      },
    },
  },
  methods: {
    ChangeLan() {
      switch (this.type) {
        case "add":
          this.crumbs = [
            this.$t("homePage.home.Application"),
            this.$t("unifiedPrompts.unifiedPrompt.new"),
          ];
          break;
        case "editCopy":
          this.crumbs = [
            this.$t("homePage.home.Application"),
            this.$t("unifiedPrompts.unifiedPrompt.copy"),
          ];
          break;
        case "edit":
          this.crumbs = [
            this.$t("homePage.home.Application"),
            this.$t("unifiedPrompts.unifiedPrompt.edit"),
          ];
          break;
        case "detail":
          this.crumbs = [
            this.$t("homePage.home.CC"),
            this.$t("ccls.ccl.detail"),
          ];
          break;
      }
    },
    handleSelectionChange(val) {
      if (val.length) {
        this.tableData.forEach((itm) => {
          itm.status = 1;
        });
      } else {
        this.tableData.forEach((itm) => {
          itm.status = 0;
        });
      }
    },
    onSelect(rows, row) {
      let selected = rows.length && rows.indexOf(row) !== -1;
      row.status = selected ? 1 : 0;
    },
    // ----
    SelectRegionConfirm() {
      let pictures = this.$refs.gen.fileListx;
      this.uploadFiles(pictures);
    },
    // 查看
    PreviewBtn(id) {
      this.$refs.tcmInfo.GetChangePriceId(id);
    },
    // 接受
    AcceptBtn(row) {
      row.status = row.status == 1 ? "0" : "1";
    },
    close() {
      this.OpenModalFlag = false;
    },
    // 获取报价类型选项
    getQuoteType() {
      getQuoteTypes(
        {
          Keyword: "",
          isEnable: 1,
          page: 1,
          limit: 9999,
          key: "",
        },
        (response) => {
          if (response && response.code == 200) {
            this.typeOptions = response.data;
          }
        }
      );
    },
    // 获取区域列表选项
    // getRegion() {
    //   getRegions(
    //     {
    //       Keyword: "",
    //       isEnable: 1,
    //       page: 1,
    //       limit: 9999,
    //       key: "",
    //     },
    //     (response) => {
    //       if (response && response.code == 200) {
    //         this.regionOptions = response.data;
    //       }
    //     }
    //   );
    // },
    // 获取商品分类数据
    // getCommdity() {
    //   let _this = this;
    //   GetOrgs(
    //     {
    //       type: 0,
    //       versionType: 0,
    //       TypeCost: 1,
    //       isAll: 0,
    //     },
    //     (response) => {
    //       if (response && response.Code == 200) {
    //         this.CommodityOptions = commonUtil.transferList(response.Result);
    //       }
    //     }
    //   );
    // },
    // 获取等级选项
    getCategorysOptions() {
      getCategorys(
        {
          TypeID: "ApplyUrgency",
          Keyword: "",
          page: 1,
          limit: 9999,
          key: "",
        },
        (response) => {
          if (response && response.code == 200) {
            this.urgencyOptions = response.data;
          }
        }
      );
    },
    getCategorysOptionss() {
      getCategorys(
        {
          TypeID: "ApplyUrgency",
          Keyword: "",
          page: 1,
          limit: 9999,
          key: "",
        },
        (response) => {
          if (response && response.code == 200) {
            response.data.forEach((e) => {
              e.name = e.description;
            });
            this.urgencyOptionss = response.data;
          }
        }
      );
    },
    // 查询税率表区域选项
    getCurrencyOptions(data) {
      GetSelCurrency(data, (response) => {
        if (response && response.code == 200) {
          this.currencyOptions = response.data[0].exchangeList;
          this.currencyReportOptions = response.data[0].exchangeCNYList;
          this.$refs.gen.genCurrOptions[0] = {
            currencyOptions: this.currencyOptions,
            currencyReportOptions: this.currencyReportOptions,
          };
        } else {
          commonUtil.showTipInfo(this.$t("userlists.userlist.inqueryTimeout"));
        }
      });
    },
    // 获取详情
    getDetail() {
      let _this = this;
      let ids = [];
      ids.push(this.id);
      GetApplyDetail(ids, (response) => {
        if (response && response.code == 200) {
          let data = response.result[0];
          this.documentNo = data.documentNo;
          this.doDate = data.doDate;
          if (data.exchangeRate != null) {
            // this.$refs.fx.form.Currency = data.exchangeRate.currency
            // this.$refs.fx.form.ReportCurrency = data.exchangeRate.currencyCNYReportCurrency
            // this.$refs.fx.form.rate = data.exchangeRate.exchangeRate
            // this.$refs.fx.form.currencyRateId = data.exchangeRate.id
          }
          this.$refs.gen.form.quoteTypeId = data.quoteTypeId;
          this.$refs.gen.form.programName = data.programName;
          this.$refs.gen.form.ecrno = data.ecrno;
          this.$refs.gen.form.commodityId = data.commodityId;
          this.$refs.gen.form.partDescription = data.partDescription;
          this.$refs.gen.form.partNumber = data.partNumber;
          this.$refs.gen.form.partRevision = data.partRevision;
          this.$refs.gen.form.lotSize = data.lotSize;
          this.$refs.gen.form.productionYear = data.productionYear;
          this.$refs.gen.form.doDate = data.doDate;
          this.$refs.gen.form.urgencyId = data.urgencyId;
          if (data.exchangeRate != null) {
            this.$refs.gen.genList[0].Currency = data.exchangeRate.currency;
            this.$refs.gen.genList[0].ReportCurrency =
              data.exchangeRate.currencyCNYReportCurrency;
            this.$refs.gen.genList[0].rate = data.rate;
            this.$refs.gen.genList[0].currencyRateId = data.exchangeRate.id;
          }
          this.$refs.gen.genList[0].regionId = data.regionId;
          this.$refs.gen.genList[0].regionName = data.regionName;
          this.$refs.gen.genList[0].supplierName = data.supplierName;
          this.$refs.gen.genList[0].materialDescription =
            data.materialDescription;
          this.$refs.gen.genList[0].peakVolume = data.peakVolume;
          this.$refs.gen.genList[0].lifetimeVolume = data.lifetimeVolume;
          this.$refs.gen.genList[0].pegPoint = data.pegPoint;
          this.$refs.gen.genList[0].yearVolumeList = data.yearVolumeList;
          this.$refs.gen.genList[0].comment = data.comment;
          if (this.type != "editCopy") {
            this.$refs.gen.mailTable = data.mailList;
            this.$refs.gen.form.mailComment = data.mailComment;
            this.$refs.gen.fileListx = data.attachmentList;
          } else {
            this.$refs.gen.fileListx = [];
            data.attachmentList.forEach(function (val, index) {
              _this.$refs.gen.fileListx.push({
                name: val.name,
                url: val.url,
              });
            });
          }
          /*zj 2023.9.14 新增字段 status*/
          this.$refs.gen.status = data.status;
        }
      });
    },
    chooseMenu(val) {
      val.forEach((itm) => {
        itm.status = 1;
      });
      this.chooseData = val;
    },

    // 确定按钮点击事件
    submit() {
      let genForm = this.$refs.gen.form;
      let genList = this.$refs.gen.genList;
      if (
        genForm.quoteTypeId === "" ||
        genForm.quoteTypeId === null ||
        !genForm.quoteTypeId
      ) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.requestRequired"),
          "",
          "warning"
        );
        return;
      }
      if (
        (genForm.quoteTypeName === "New pursuit" ||
          genForm.quoteTypeName === "Soucing") &&
        (genForm.programName === "" ||
          genForm.programName === null ||
          !genForm.programName)
      ) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.projectRequired"),
          "",
          "warning"
        );
        return;
      }
      if (
        genForm.quoteTypeName === "New pursuit" &&
        (genForm.ecrno === "" || genForm.ecrno === null || !genForm.ecrno)
      ) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.ecrNoRequired"),
          "",
          "warning"
        );
        return;
      }
      if (
        genForm.commodityId === "" ||
        genForm.commodityId === null ||
        !genForm.commodityId
      ) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.commodityRequired"),
          "",
          "warning"
        );
        return;
      }
      if (
        genForm.partDescription === "" ||
        genForm.partDescription === null ||
        !genForm.partDescription
      ) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.partRequired"),
          "",
          "warning"
        );
        return;
      }
      if (
        (genForm.quoteTypeName === "New pursuit" ||
          genForm.quoteTypeName === "Soucing" ||
          genForm.quoteTypeName === "SOP") &&
        (genForm.partNumber === "" ||
          genForm.partNumber === null ||
          !genForm.partNumber)
      ) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.partNumberRequired"),
          "",
          "warning"
        );
        return;
      }
      let currency = false;
      let reportCurrency = false;
      let rate = false;
      let region = false;
      let params = [];
      genList.forEach((val, index) => {
        if (val.Currency === "" || val.Currency === null || !val.Currency) {
          currency = true;
          return;
        } else if (
          val.ReportCurrency === "" ||
          val.ReportCurrency === null ||
          !val.ReportCurrency
        ) {
          reportCurrency = true;
          return;
        } else if (val.rate === "" || val.rate === null || !val.rate) {
          rate = true;
          return;
        } else if (
          val.regionId === "" ||
          val.regionId === null ||
          !val.regionId
        ) {
          region = true;
          return;
        }
        params.push({
          regionId: val.regionId,
          regionName: "",
          partNumber: this.$refs.gen.form.partNumber,
          status: "",
          costApplyId: "",
        });
      });
      if (currency) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.currencyRequired"),
          "",
          "warning"
        );
        return;
      }
      if (reportCurrency) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.reportRequired"),
          "",
          "warning"
        );
        return;
      }
      if (rate) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.exRquired"),
          "",
          "warning"
        );
        return;
      }
      if (region) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.regionRquired"),
          "",
          "warning"
        );
        return;
      }
      let hasOperator = false;
      this.$refs.gen.mailTable.forEach(function (val, index) {
        if (val.type == 1) {
          hasOperator = true;
        }
      });
      if (
        this.$refs.gen.mailTable.length === 0 ||
        (this.$refs.gen.mailTable.length > 0 && !hasOperator)
      ) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.chooseEmail"),
          "",
          "warning"
        );
        return;
      }
      let pictures = this.$refs.gen.fileListx;
      GetApplicationReqs(params, (response) => {
        if (response && response.code == 200) {
          this.tableData = response.result;
          if (this.tableData.length) {
            this.$alert(
              this.$refs.gen.form.partNumber +
                "   " +
                this.$t("changePriceLists.changePriceList.alreadyExists") +
                response.result.length +
                "   " +
                this.$t("changePriceLists.changePriceList.recordFounded"),
              this.$t("changePriceLists.changePriceList.Tips"),
              {
                confirmButtonText: this.$t(
                  "changePriceLists.changePriceList.View"
                ),
                cancelButtonText: this.$t(
                  "changePriceLists.changePriceList.submit"
                ),
                showClose: false,
                confirmButtonClass: "operate-btn",
                showCancelButton: true,
                callback: (action) => {
                  if (action == "confirm") {
                    this.OpenModalFlag = true;
                  } else {
                    this.tableData = [];
                    this.uploadFiles(pictures);
                  }
                },
              }
            );
          } else {
            this.tableData = [];
            this.uploadFiles(pictures);
          }
        }
      });
    },
    addTcm() {
      // let fxForm = this.$refs.fx.form
      let genForm = this.$refs.gen.form;
      let genList = this.$refs.gen.genList;
      let mailTable = this.$refs.gen.mailTable;
      let userIds = [];
      let mailCCIds = [];
      let mailCCMails = [];
      mailTable.forEach(function (val, index) {
        if (val.type == 1) {
          userIds.push(val.id);
        }
        // if () {
        //   mailCCIds.push(val.id);
        // }
        if (val.type == 3 || val.type == 4) {
          mailCCMails.push(val);
        }
      });
      if (this.type == "editCopy") {
        genList.forEach(function (genval, genIndex) {
          genval.yearVolumeList.forEach(function (yearVal, yearIndex) {
            yearVal.costApplyId = "";
            yearVal.createId = "";
            yearVal.createTime = "";
            yearVal.id = "";
            yearVal.modifyId = "";
            yearVal.modifyTime = "";
          });
        });
      }
      let data = {
        id: this.type == "editCopy" ? "" : this.id,
        templeteId: "",
        // rate: fxForm.rate,
        // currencyRateId: fxForm.currencyRateId,
        quoteTypeId: genForm.quoteTypeId,
        programName: genForm.programName,
        ecrno: genForm.ecrno,
        commodityId: genForm.commodityId,
        partDescription: genForm.partDescription,
        partNumber: genForm.partNumber,
        partRevision: genForm.partRevision,
        lotSize: genForm.lotSize,
        productionYear: genForm.productionYear,
        doDate: genForm.doDate,
        urgencyId: genForm.urgencyId,
        // comment: genForm.comment,
        genList: genList,
        attachmentList: this.pictureList,
        mailList: [],
        UserId: userIds.toString(),
        mailCCIds: mailCCIds.toString(),
        mailCCMails: mailCCMails,
        mailComment: genForm.mailComment,
      };
      if (this.tableData.length) {
        data.lstAppCheck = this.tableData;
      }
      AddOrUpdateForAppllicant(
        data,
        (response) => {
          if (response && response.code == 200) {
            commonUtil.showTipInfo(
              this.$t("unifiedPrompts.unifiedPrompt.addedSuccess"),
              "",
              "success"
            );
            this.$refs.gen.emptyInput();
            this.$route.meta.showTag = false;
            this.$router.back(-1);
          }
          if (response && response.code == 300) {
            this.repeatData = response.result;
            this.innerVisible = true;
          }
        },
        (errorRes) => {
          this.uploadStatus = false;
        }
      );
    },
    // 文件上传
    uploadFiles(files) {
      let _this = this;
      this.pictureList = [];
      this.uploadStatus = false;
      let format = new FormData();
      // 需要上传的文件
      let uploadList = [];
      files.forEach(function (value, key) {
        if (value.raw) {
          format.append("file", value.raw);
          uploadList.push(value);
        } else {
          _this.pictureList.push(value);
        }
      });
      if (uploadList.length > 0) {
        uploadFile(format, (response) => {
          if (response && response.code == 200) {
            response.data.forEach(function (val, key) {
              _this.pictureList.push(val);
            });
            _this.uploadStatus = true;
          }
        });
      } else {
        _this.uploadStatus = true;
      }
    },
    Goback() {
      this.$router.go(-1);
    },
  },
};
</script>


<style lang="scss" scoped>
.main {
  padding-top: 50px;
  width: 100%;
  background: url("../../assets/img/car-table-bg.png") no-repeat right;
  background-position: right 4% bottom 0;
  background-size: 650px 600px;
  border-radius: 20px;
}
.crumbs-box {
  position: fixed;
  top: 70px;
  left: 220px;
  padding-left: 20px;
  width: 100%;
  display: flex;
  background-color: #f2f2f2;
  z-index: 999;
  // border-bottom: 1px solid #e4e7ed;
  align-items: center;
  height: 30px;
  font-size: 16px;
  color: #3e4954;
  .crumbs-box-item {
    display: flex;
    align-items: center;
  }
  .el-icon {
    margin: 0 10px;
  }
  .parent {
    color: #2f4cdd;
    cursor: pointer;
  }
  .line {
    margin: 0 15px;
    width: 2px;
    height: 15px;
    background: #3e4954;
  }
}
.handle-box {
  position: fixed;
  top: 100px;
  left: 220px;
  width: calc(100% - 220px);
  height: 35px;
  display: flex;
  justify-content: flex-end;
  background-color: #f2f2f2;
  z-index: 9;
}
.submit-btn {
  margin-right: 50px;
  padding: 0 30px;
  height: 30px;
  line-height: 30px;
  background: #445581;
  color: #fff;
  text-align: center;
  border-radius: 30px;
  cursor: pointer;
  font-weight: 600;
}
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
