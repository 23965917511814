<template>
  <el-dialog v-model="visible" width="500px" :show-close="false" @close="close">
    <template #header="{ close, titleId, titleClass }">
      <div class="my-header">
        <div class="size-16">{{ $t("unifiedPrompts.unifiedPrompt.edit") }}</div>
        <div style="cursor: pointer" @click="close">
          <el-icon :size="20"><Close /></el-icon>
        </div>
      </div>
    </template>
    <div>
      <el-form ref="formList" :model="form" label-width="140px">
        <el-form-item :label="$t('homePage.home.Calculated')">
          <el-select
            clearable
            :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
            v-model="form.type"
            style="width: 214px"
          >
            <el-option
              v-for="item in typeOptions"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('other.other.StrengthDetails')">
          <el-input v-model="form.fieId" style="width: 214px" />
        </el-form-item>
        <el-form-item :label="$t('other.other.Numbers')">
          <el-input
            v-model="form.number"
            @input="(v) => (form.number = v.replace(/[^\d]/g, ''))"
            style="width: 214px"
          />
        </el-form-item>
        <el-form-item :label="$t('ccls.ccl.enable')">
          <el-switch
            v-model="form.isEnable"
            active-value="1"
            inactive-value="2"
          />
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close"> {{ $t("ccls.ccl.cancel") }}</el-button>
        <el-button
          style="
            background-color: var(--themeColor);
            color: var(--menuTextActive);
            margin-right: 16px;
          "
          @click="handleSubmit"
          >{{ $t("ccls.ccl.confirm") }}</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import commonUtil from "../../assets/js/commonUtil.js";
export default {
  emits: ["handleSubmit"],
  data() {
    return {
      visible: false,
      typeOptions: ["Ferrous", "Nonferrous"],
      form: {
        id: "",
        type: "",
        number: "",
        fieId: "",
        isEnable: 1,
      },
    };
  },
  methods: {
    handleSubmit() {
      // 给出添加弹框
      if (this.form.number == "") {
        commonUtil.showTipInfo(this.$t("other.other.EnterNum"), "", "warning");
        return;
      }
      if (this.form.company == "") {
        commonUtil.showTipInfo(
          this.$t("other.other.EnterCompany"),
          "",
          "warning"
        );
        return;
      }
      let form = {
        id: this.form.id,
        number: parseFloat(this.form.number),
        type: this.form.type,
        fieId: this.form.fieId,
        isEnable: this.form.isEnable,
      };
      this.$emit("handleSubmit", form);
      this.close();
    },
    close() {
      this.visible = false;
      this.form = {
        id: "",
        type: "",
        number: "",
        fieId: "",
        isEnable: 1,
      };
    },
  },
};
</script>

<style scoped>
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.size-16 {
  font-size: 16px;
}
</style>