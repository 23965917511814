<template>
  <el-dialog v-model="visible" width="500px" :show-close="false" @close="close">
    <template #header="{ close }">
      <div class="my-header">
        <div class="size-16">{{ $t("userlists.userlist.changePassword") }}</div>
        <div style="cursor: pointer" @click="close">
          <el-icon :size="20">
            <Close />
          </el-icon>
        </div>
      </div>
    </template>
    <div>
      <el-form :model="form" :rules="rules" ref="form" label-width="140px">
        <el-form-item
          style="margin-bottom: 30px"
          :label="$t('userlists.userlist.name')"
          prop="account"
        >
          <el-input v-model="form.name" style="width: 240px" disabled />
        </el-form-item>
        <el-form-item
          style="margin-bottom: 30px"
          :label="$t('userlists.userlist.password')"
          prop="password"
        >
          <el-input v-model="form.password" style="width: 240px" />
        </el-form-item>
        <el-form-item
          :label="$t('userlists.userlist.surePassword')"
          prop="confirmpassword"
        >
          <el-input v-model="form.confirmpassword" style="width: 240px" />
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close">{{ $t("ccls.ccl.cancel") }}</el-button>
        <el-button
          style="
            background-color: var(--themeColor);
            color: var(--menuTextActive);
            margin-right: 16px;
          "
          @click="addSubmit"
          >{{ $t("ccls.ccl.confirm") }}</el-button
        >
      </span>
    </template>
    <TreeDepartment
      ref="TreeDepartmentDialog"
      @handleSubmit="DepartmentTree"
    ></TreeDepartment>
  </el-dialog>
</template>

<script>
// import TreeDepartment from "./treeDepartment.vue";
import commonUtil from "@/assets/js/commonUtil.js";
export default {
  components: {
    // TreeDepartment,
  },
  watch: {
    "$i18n.locale"(val) {
      this.ChangeLan();
    },
  },
  data() {
    return {
      visible: false,
      form: {
        // id:'',
        name: "",
        account: "",
        password: "",
        confirmpassword: "",
      },
      rules: {},
    };
  },
  mounted() {
    this.ChangeLan();
  },
  methods: {
    ChangeLan() {
      var passwordRules = (rule, value, callback) => {
        let pwdReg =
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[#?!@$%^&*-.()]).{12,25}$/;
        if (!pwdReg.test(value)) {
          callback(new Error(this.$t("other.other.PwsRulesErr")));
        } else {
          callback();
        }
      };

      this.rules = {
        account: [
          {
            required: true,
            message: "请输入账号",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            validator: passwordRules,
            trigger: "blur",
          },
        ],
        confirmpassword: [
          {
            required: true,
            validator: passwordRules,
            trigger: "blur",
          },
        ],
      };
    },
    close() {
      this.$refs.form.resetFields();
      this.visible = false;
    },
    addSubmit() {
      if (this.form.password !== this.form.confirmpassword) {
        commonUtil.showTipInfo(
          this.$t("other.other.ConfrimPwdErr"),
          "",
          "warning"
        );
        return false;
      }
      this.$refs.form.validate((e) => {
        if (e) {
          this.visible = false;
          this.$emit("handleSubmit", this.form);
          this.$refs.form.resetFields();
        }
      });

      // this.visible = false;
      // if (this.form.password == this.form.confirmpassword) {
      //   this.$emit("handleSubmit", this.form);
      // } else {
      //   commonUtil.showTipInfo(
      //     "两次密码输入不相等，请重新输入！",
      //     "",
      //     "warning"
      //   );
      // }
    },
  },
};
</script>

<style scoped>
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.size-16 {
  font-size: 16px;
}
</style>
