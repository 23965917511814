<template>
  <div class="tcm-list">
    <div class="tcm-search">
      <el-row>
        <el-col :span="20">
          <table>
            <tr>
              <td>
                <el-input
                  v-model="searchForm.keywords"
                  :placeholder="$t('unifiedPrompts.unifiedPrompt.keyword')"
                />
              </td>
              <td>
                <div class="operate-btn" style="margin-right: 10px">
                  {{ $t("tcmlists.tcmlist.search") }}
                </div>
              </td>
            </tr>
          </table>
        </el-col>
        <el-col :span="4">
          <div class="align-right">
            <div
              class="operate-btn"
              style="margin-top: 3px; margin-right: 10px"
            >
              {{ $t("ccls.ccl.import") }}
            </div>
            <div class="operate-btn" style="margin-top: 3px">
              {{ $t("ccls.ccl.export") }}
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="tcm-table">
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        :empty-text="$t('tcmlists.tcmlist.noData')"
      >
        <el-table-column prop="name" label="ID" align="center" />
        <el-table-column prop="number" label="Name" align="center" />
        <el-table-column prop="phone" label="Currency" align="center" />
        <el-table-column
          prop="phone"
          label="Currency CNY Report Currency"
          align="center"
        />
        <el-table-column prop="phone" label="Exchange Rate" align="center" />
        <el-table-column
          prop="enable"
          :label="$t('ccls.ccl.enable')"
          align="center"
        >
          <template #default="scope">
            <el-icon
              :size="16"
              color="#40D4A8"
              v-show="scope.row.enable == '1'"
            >
              <SuccessFilled />
            </el-icon>
            <el-icon
              :size="16"
              color="#F56C6C"
              v-show="scope.row.enable == '0'"
            >
              <CircleCloseFilled />
            </el-icon>
          </template>
        </el-table-column>
      </el-table>
      <div class="page-cla">
        <el-pagination layout="prev, pager, next" :total="1000" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      searchForm: {
        commodity: "",
        date: "",
        status: "",
        keywords: "",
      },
      // commodity选项
      commodityOptions: [
        {
          value: "Option1",
          label: "Option1",
        },
        {
          value: "Option2",
          label: "Option2",
        },
      ],
      tableData: [
        {
          name: "xxx",
          number: "DS786532",
          phone: "15012345678",
          department: "procurement",
          enable: "0",
        },
        {
          name: "xxx",
          number: "DS786532",
          phone: "15012345678",
          department: "procurement",
          enable: "1",
        },
        {
          name: "xxx",
          number: "DS786532",
          phone: "15012345678",
          department: "procurement",
          enable: "0",
        },
        {
          name: "xxx",
          number: "DS786532",
          phone: "15012345678",
          department: "procurement",
          enable: "1",
        },
      ],
      filterText: "",
      defaultProps: {
        children: "children",
        label: "label",
      },
      treedata: [],
    };
  },
  methods: {
    // 新增
    add() {
      this.$refs.addDialog.visible = true;
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    // 显示选择部门弹窗
    showMoudleDia(val) {
      if (val) {
        this.$refs.moudleDialog.visible = true;
      }
    },
  },
};
</script>

<style scoped>
.tcm-list {
  height: 100%;
}
.tcm-search {
  width: 100%;
  background-color: var(--searchBg);
  padding: 20px;
  box-sizing: border-box;
}
.tcm-search td {
  /* width: 20%; */
  padding: 0 10px;
}
.operate-btn {
  border-radius: 5px;
  background-color: var(--themeColor);
  color: #fff;
  text-align: center;
  padding: 7px 10px;
  display: inline-block;
  min-width: 80px;
  cursor: pointer;
}
.color-ff0000 {
  color: #ff0000;
}
.color-F59A23 {
  color: #f59a23;
}
.color-0000FF {
  color: #0000ff;
}
.color-D9001B {
  color: #d9001b;
}
.align-right {
  text-align: right;
}
.flex-around {
  display: flex;
  justify-content: space-around;
}
.page-cla {
  padding: 20px;
  display: flex;
  justify-content: flex-end;
}
.operate-btnx {
  border-radius: 5px;
  background-color: var(--themeColor);
  color: #fff;
  font-size: 12px;
  text-align: center;
  padding: 2px 5px;
  min-width: 60px;
  cursor: pointer;
}
.tcm-table {
  height: calc(100% - 90px);
  padding: 0 20px;
  box-sizing: border-box;
}
.tree-cla {
  padding: 10px;
  box-sizing: border-box;
  height: 100%;
  border: 1px solid #ebeef5;
}
.el-row {
  height: 100%;
}
</style>