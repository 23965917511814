<template>
  <div style="height: 100%">
    <div class="top-box">
      <div class="crumbs-box">
        <div class="crumbs-box-item parent" @click="Goback">
          {{ $t("homePage.home.Back") }}
        </div>
        <div class="line"></div>
        <div class="crumbs-box-item" v-for="(item, index) in crumbs">
          {{ $t(item) }}
          <el-icon v-show="index != crumbs.length - 1"><ArrowRight /></el-icon>
        </div>
      </div>
      <div class="step-menubox">
        <StepMenu
          :MenuData="MenuData"
          :chooseCurrent="chooseCurrent"
          @chooseMenu="chooseMenu"
        ></StepMenu>
      </div>
      <div class="handle-box">
        <div class="handle-box-item">
          <el-tooltip
            :content="$t('other.other.History')"
            placement="top"
            effect="light"
          >
            <el-icon
              style="--color: black; font-size: 20px; margin-right: 10px"
              @click="ShowComments"
              ><History
            /></el-icon>
          </el-tooltip>
          <el-button
            style="
              background-color: var(--themeColor);
              color: var(--menuTextActive);
            "
            @click="toExport"
            >{{ $t("changePriceLists.changePriceList.exportPdf") }}</el-button
          >
        </div>
        <div class="handle-box-item">
          <!-- v-show="reportShow" -->
          <div class="report-cur">
            <div class="mgr-10">
              {{ $t("changePriceLists.changePriceList.reportCurrency") }}
            </div>
            <div class="mgr-10">
              <el-select
                clearable
                v-model="reportCurrency"
                filterable
                style="width: 150px"
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
              >
                <el-option
                  v-for="it in currencyReportOptions"
                  :key="it.id"
                  :label="it.name"
                  :value="it.id"
                />
              </el-select>
            </div>
            <el-button
              style="
                background-color: var(--themeColor);
                color: var(--menuTextActive);
              "
              @click="reportForm"
              >{{
                $t("changePriceLists.changePriceList.reportForm")
              }}</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div class="main">
      <div
        style="
          padding: 20px;
          width: 100%;
          padding-top: 10px;
          box-sizing: border-box;
        "
      >
        <div
          class="content"
          style="width: 100%"
          v-show="chooseCurrent == 'sumEcharts'"
        >
          <div class="content-left">
            <!-- sum -->
            <div class="card-item">
              <div class="card-item-title">
                <span>{{ $t("homePage.home.Summary") }}</span>
              </div>
              <!-- ref="second" -->
              <div id="echartsbox"></div>
            </div>
            <!-- General Information -->
            <div class="card-item" @click="chooseMenu('gen')">
              <div class="card-item-title">
                <span>{{ $t("homePage.home.Gen") }}</span>
                <!-- <el-icon :size="25" color="#A098AE"><EditPen /></el-icon> -->
              </div>
              <div class="item-span">
                <span
                  >{{
                    $t("changePriceLists.changePriceList.partNumber")
                  }}:</span
                >
                <span> {{ genForm.partNumber }}</span>
              </div>
              <div class="item-span">
                <span
                  >{{
                    $t("changePriceLists.changePriceList.commodity")
                  }}
                  :</span
                >
                <span>{{ genForm.commodityName }}</span>
              </div>
              <div class="item-span">
                <span
                  >{{ $t("changePriceLists.changePriceList.partDescription") }}:
                </span>
                <span> {{ genForm.partDescription }}</span>
              </div>
            </div>
            <template v-if="previewData.materialLine">
              <!-- raw -->
              <el-popover
                :disabled="!previewData.materialLine.length"
                placement="top"
                :width="780"
                trigger="hover"
              >
                <div class="regional_table">
                  <el-table :data="previewData.materialLine" max-height="400">
                    <el-table-column
                      width="60"
                      type="index"
                      label="
                        #
                      "
                    />
                    <el-table-column
                      property="description"
                      :label="
                        $t(
                          'changePriceLists.changePriceList.materialDescription'
                        )
                      "
                    />
                    <el-table-column
                      property="netMWeight"
                      :label="$t('changePriceLists.changePriceList.netWeight')"
                    />
                    <el-table-column
                      property="grossMWeight"
                      :label="
                        $t('changePriceLists.changePriceList.grossWeight')
                      "
                    />
                    <el-table-column
                      property="cost"
                      :label="
                        $t('changePriceLists.changePriceList.materialCost')
                      "
                    />
                  </el-table>
                </div>
                <template #reference>
                  <div
                    class="card-item"
                    style="height: 222px"
                    @click="chooseMenu('rm')"
                  >
                    <div class="card-item-title">
                      <span>{{ $t("homePage.home.Rm") }}</span>
                      <span class="card-item-total"
                        >{{ $t("changePriceLists.changePriceList.total") }}:
                        {{ exchangeRateUnit }}
                        {{ previewData.materialCost_SUM }}</span
                      >
                      <!-- <el-icon :size="25" color="#A098AE"><EditPen /></el-icon> -->
                    </div>

                    <div class="raw-box">
                      <table>
                        <tr class="table-header">
                          <td>
                            {{
                              $t(
                                "changePriceLists.changePriceList.materialDescription"
                              )
                            }}
                          </td>
                          <td>
                            {{
                              $t("changePriceLists.changePriceList.netWeight")
                            }}
                          </td>
                          <td>
                            {{
                              $t("changePriceLists.changePriceList.grossWeight")
                            }}
                          </td>
                          <td>
                            {{
                              $t(
                                "changePriceLists.changePriceList.materialCost"
                              )
                            }}
                          </td>
                        </tr>
                        <tr
                          v-for="(item, index) in previewData.materialLine"
                          :key="item"
                        >
                          <td v-if="index < 3">{{ item.description }}</td>
                          <td v-if="index < 3">
                            <div class="text-around">
                              <span> {{ item.netMWeight }}</span>
                              <!-- <span class="mar-left">
                                {{ genList.Currency }}
                              </span> -->
                            </div>
                          </td>
                          <td v-if="index < 3">
                            <div class="text-around">
                              <span> {{ item.grossMWeight }}</span>
                              <!-- <span class="mar-left">
                                {{ genList.Currency }}
                              </span> -->
                            </div>
                          </td>
                          <td v-if="index < 3">
                            <div class="text-around">
                              <span> {{ item.cost }}</span>
                              <span class="mar-left">
                                {{ genList.Currency }}
                              </span>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </div>
                    <!--  -->
                    <div
                      v-if="previewData.materialLine.length > 3"
                      class="more-txt"
                    >
                      {{ $t("homePage.home.More") }}
                    </div>
                  </div>
                </template>
              </el-popover>
            </template>
          </div>
          <div class="content-right">
            <!-- pur -->
            <template v-if="previewData.purchaseLine">
              <el-popover
                :disabled="!previewData.purchaseLine.length"
                placement="right"
                :width="500"
                trigger="hover"
              >
                <div class="regional_table">
                  <el-table :data="previewData.purchaseLine" max-height="400">
                    <el-table-column
                      width="60"
                      type="index"
                      label="
                        #
                      "
                    />

                    <el-table-column
                      property="name"
                      :label="
                        $t(
                          'changePriceLists.changePriceList.processDescription'
                        )
                      "
                    />
                    <el-table-column
                      property="totalCost"
                      :label="
                        $t('changePriceLists.changePriceList.subTotalCost')
                      "
                    />
                  </el-table>
                </div>

                <template #reference>
                  <!-- pur -->
                  <div
                    class="card-item"
                    style="height: 222px"
                    @click="chooseMenu('pur')"
                  >
                    <div class="card-item-title">
                      <span>{{ $t("homePage.home.PUR") }}</span>
                      <span class="card-item-total">
                        {{ $t("changePriceLists.changePriceList.total") }}:
                        {{ exchangeRateUnit }}
                        {{ previewData.purchaseCost_SUM }}
                      </span>
                      <!-- <el-icon :size="25" color="#A098AE"><EditPen /></el-icon> -->
                    </div>
                    <div class="raw-box">
                      <table>
                        <tr class="table-header">
                          <td>
                            {{
                              $t(
                                "changePriceLists.changePriceList.processDescription"
                              )
                            }}
                          </td>
                          <td>
                            {{
                              $t(
                                "changePriceLists.changePriceList.subTotalCost"
                              )
                            }}
                          </td>
                        </tr>
                        <tr
                          v-for="(item, index) in previewData.purchaseLine"
                          :key="item"
                        >
                          <td v-if="index < 3">{{ item.name }}</td>
                          <td v-if="index < 3">
                            <div class="text-around">
                              <span> {{ item.totalCost }}</span>
                              <span class="mar-left">
                                {{ genList.Currency }}
                              </span>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </div>
                    <!--  -->
                    <div
                      v-if="previewData.purchaseLine.length > 3"
                      class="more-txt"
                    >
                      {{ $t("homePage.home.More") }}
                    </div>
                  </div>
                </template>
              </el-popover>
            </template>

            <!-- pro -->
            <template v-if="previewData.processLine">
              <el-popover
                :disabled="!previewData.processLine.length"
                placement="right"
                :width="500"
                trigger="hover"
              >
                <div class="regional_table">
                  <el-table :data="previewData.processLine" max-height="400">
                    <el-table-column
                      width="60"
                      type="index"
                      label="
                        #
                      "
                    />

                    <el-table-column
                      property="name"
                      :label="
                        $t(
                          'changePriceLists.changePriceList.operationDescription'
                        )
                      "
                    />
                    <el-table-column
                      property="totalCost"
                      :label="
                        $t('changePriceLists.changePriceList.subTotalCost')
                      "
                    />
                  </el-table>
                </div>

                <template #reference>
                  <!-- pro -->
                  <div
                    class="card-item"
                    style="height: 224px"
                    @click="chooseMenu('pur')"
                  >
                    <div class="card-item-title">
                      <span>{{ $t("homePage.home.PRO") }}</span>
                      <span class="card-item-total">
                        {{ $t("changePriceLists.changePriceList.total") }}:
                        {{ exchangeRateUnit }}
                        {{ ProTotal }}
                      </span>
                    </div>
                    <div class="raw-box">
                      <table>
                        <tr class="table-header">
                          <td>
                            {{
                              $t(
                                "changePriceLists.changePriceList.operationDescription"
                              )
                            }}
                          </td>
                          <td>
                            {{
                              $t(
                                "changePriceLists.changePriceList.subTotalCost"
                              )
                            }}
                          </td>
                        </tr>
                        <tr
                          v-for="(item, index) in previewData.processLine"
                          :key="item"
                        >
                          <td v-if="index < 3">{{ item.name }}</td>
                          <td v-if="index < 3">
                            <div class="text-around">
                              <span> {{ item.totalCost }}</span>
                              <span class="mar-left">
                                {{ genList.Currency }}
                              </span>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </div>
                    <!--  -->

                    <div
                      v-if="previewData.processLine.length > 3"
                      class="more-txt"
                    >
                      {{ $t("homePage.home.More") }}
                    </div>
                  </div>
                </template>
              </el-popover>
            </template>

            <!-- pl -->
            <div class="card-item" @click="chooseMenu('p&l')">
              <div class="card-item-title">
                <span> {{ $t("homePage.home.Pl") }}</span>
                <span class="card-item-total">
                  {{ $t("changePriceLists.changePriceList.total") }}:
                  {{ exchangeRateUnit }}
                  {{ PackagingTotal }}
                </span>
                <!-- <el-icon :size="25" color="#A098AE"><EditPen /></el-icon> -->
              </div>
              <div class="item-span" style="padding-top: 25px">
                <span>
                  {{ $t("changePriceLists.changePriceList.taxesFees") }}:</span
                >
                <span>
                  {{ previewData.otherCost }}
                  {{
                    previewData.calculationMethod_Taxes == 2 ? "%" : ""
                  }}</span
                >
                <span class="mar-left">
                  {{ genList.Currency }}
                </span>
              </div>
              <div class="item-span">
                <span>
                  {{ $t("changePriceLists.changePriceList.addedCost") }}:</span
                >
                <span>{{ previewData.amortizeCost }}</span>
                <span class="mar-left">
                  {{ genList.Currency }}
                </span>
              </div>
              <div class="item-span" style="height: 22px"></div>
            </div>
            <!-- oh -->
            <div class="card-item" @click="chooseMenu('sga')">
              <div class="card-item-title">
                <span>OH</span>
                <span class="card-item-total">
                  {{ $t("changePriceLists.changePriceList.total") }}:
                  {{ exchangeRateUnit }}
                  {{ OhTotal }}
                </span>
                <!-- <el-icon :size="25" color="#A098AE"><EditPen /></el-icon> -->
              </div>
              <div class="item-span" style="padding-top: 25px">
                <span> {{ $t("changePriceLists.changePriceList.sga") }}:</span>
                <span>{{ previewData.sga }}</span>
                <span></span>
              </div>
              <div class="item-span">
                <span>
                  {{
                    $t("changePriceLists.changePriceList.materialScrap")
                  }}:</span
                >
                <span> {{ previewData.materialPC }}</span>
                <span class="mar-left">
                  {{ genList.Currency }}
                </span>
              </div>
              <div class="item-span">
                <span>
                  {{
                    $t("changePriceLists.changePriceList.totalprofitCost")
                  }}:</span
                >
                <span> {{ previewData.totalProfitCost }}</span>
                <span class="mar-left">
                  {{ genList.Currency }}
                </span>
              </div>
              <div class="item-span" style="height: 55px"></div>
            </div>
          </div>
        </div>
        <!-- gen -->
        <div class="card" v-show="chooseCurrent == 'gen'">
          <div class="form-box">
            <div class="tcm-content-form-all">
              <el-form
                :model="genForm"
                label-width="150px"
                class="custom-el-form-label-bold"
                label-position="left"
              >
                <el-row>
                  <el-col :span="8">
                    <el-form-item :label="$t('userlists.userlist.quoteType')">
                      {{ genForm.quoteTypeName }}
                    </el-form-item>
                  </el-col>
                  <el-col :span="8"> </el-col>
                  <el-col :span="8"> </el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">
                    <el-form-item
                      :label="
                        $t('changePriceLists.changePriceList.programName')
                      "
                    >
                      {{ genForm.programName }}
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item
                      :label="$t('changePriceLists.changePriceList.ecrNo')"
                    >
                      {{ genForm.ecrno }}
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item
                      :label="$t('changePriceLists.changePriceList.commodity')"
                    >
                      {{ genForm.commodityName }}
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">
                    <el-form-item
                      :label="
                        $t('changePriceLists.changePriceList.partDescription')
                      "
                    >
                      {{ genForm.partDescription }}
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item
                      :label="$t('changePriceLists.changePriceList.partNumber')"
                    >
                      {{ genForm.partNumber }}
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item
                      :label="
                        $t('changePriceLists.changePriceList.partRevision')
                      "
                    >
                      {{ genForm.partRevision }}
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">
                    <el-form-item
                      :label="$t('changePriceLists.changePriceList.currency')"
                    >
                      {{ genList.Currency }}
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item
                      :label="
                        $t('changePriceLists.changePriceList.reportCurrency')
                      "
                    >
                      {{ genList.ReportCurrency }}
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item
                      :label="
                        $t('changePriceLists.changePriceList.exchangeRate')
                      "
                    >
                      {{ genList.rate }}
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">
                    <el-form-item
                      :label="$t('changePriceLists.changePriceList.region')"
                    >
                      {{ genList.regionName }}
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item
                      :label="
                        $t('changePriceLists.changePriceList.supplierName')
                      "
                    >
                      {{ genList.supplierName }}
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item
                      :label="
                        $t(
                          'changePriceLists.changePriceList.materialDescription'
                        )
                      "
                    >
                      {{ genList.materialDescription }}
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">
                    <el-form-item
                      :label="$t('changePriceLists.changePriceList.peakVolume')"
                    >
                      {{ genList.peakVolume }}
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item
                      :label="
                        $t('changePriceLists.changePriceList.lifetimeVolume')
                      "
                    >
                      {{ genList.lifetimeVolume }}
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item
                      :label="$t('changePriceLists.changePriceList.pegPoint')"
                    >
                      {{ genList.pegPoint }}
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </div>
          </div>
        </div>
        <!-- rawMaterial -->
        <div class="card" v-show="chooseCurrent == 'rm' && !TcmDataFalg">
          <div class="tcm-content-rawPic" v-if="previewData.attachmentList">
            <el-image
              v-for="(item, index) in previewData.attachmentList"
              style="width: 40px; height: 40px; margin-right: 10px"
              :src="item.url"
              :zoom-rate="1.2"
              :preview-src-list="attachmentList"
              :initial-index="index"
              fit="cover"
            />
          </div>
          <div class="tcm-content-form-all dis-wrap">
            <div class="width-preview" v-for="item in previewData.materialLine">
              <el-row>
                <el-col :span="11">
                  <div class="size-14 padding-9-0 font-bold">
                    {{
                      $t("changePriceLists.changePriceList.materialDescription")
                    }}
                  </div>
                </el-col>
                <el-col :span="13" class="size-14 padding-9-0">{{
                  item.description
                }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="11">
                  <div class="size-14 padding-9-0 font-bold">
                    {{ $t("changePriceLists.changePriceList.vendor") }}
                  </div>
                </el-col>
                <el-col :span="13" class="size-14 padding-9-0">{{
                  item.vendor
                }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="11">
                  <div class="size-14 padding-9-0 font-bold">
                    {{ $t("changePriceLists.changePriceList.netWeight") }}
                  </div>
                </el-col>
                <el-col :span="13" class="size-14 padding-9-0">{{
                  item.netMWeight
                }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="11">
                  <div class="size-14 padding-9-0 font-bold">
                    {{ $t("changePriceLists.changePriceList.grossWeight") }}
                  </div>
                </el-col>
                <el-col :span="11" class="size-14 padding-9-0">{{
                  item.grossMWeight
                }}</el-col>
                <el-col :span="2">
                  <img
                    src="../../assets/img/tcm/daikuanjisuanqi.png"
                    v-show="item.calculateData !== ''"
                    style="width: 22px; margin-top: -4px; cursor: pointer"
                    @click="openCal(item, 'grossMWeight')"
                  />
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="11">
                  <div class="size-14 padding-9-0 font-bold">
                    {{ $t("changePriceLists.changePriceList.unitMeasure") }}
                  </div>
                </el-col>
                <el-col :span="13" class="size-14 padding-9-0">{{
                  item.unitMeasureName
                }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="11">
                  <div class="size-14 padding-9-0 font-bold">
                    {{ $t("changePriceLists.changePriceList.materialMeasure") }}
                  </div>
                </el-col>
                <el-col
                  :span="13"
                  class="size-14 padding-9-0"
                  :style="
                    item.unitCost_Before != item.unitCost &&
                    showType == 'changePriceDetail'
                      ? 'color: red'
                      : ''
                  "
                  >{{ item.unitCost }}</el-col
                >
              </el-row>
              <el-row v-show="showType == 'changePriceDetail'">
                <el-col :span="11">
                  <div class="size-14 padding-9-0 font-bold">
                    {{ $t("changePriceLists.changePriceList.materialMeasure") }}
                    ({{
                      $t("changePriceLists.changePriceList.beforeChangePrice")
                    }})
                  </div>
                </el-col>
                <el-col
                  :span="13"
                  class="size-14 padding-9-0"
                  :style="
                    item.unitCost_Before != item.unitCost &&
                    showType == 'changePriceDetail'
                      ? 'color: red'
                      : ''
                  "
                  >{{ item.unitCost_Before }}</el-col
                >
              </el-row>
              <el-row>
                <el-col :span="11">
                  <div class="size-14 padding-9-0 font-bold">
                    {{ $t("changePriceLists.changePriceList.scrapPrice") }}
                  </div>
                </el-col>
                <el-col :span="13" class="size-14 padding-9-0">{{
                  item.unitResaleValue
                }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="11">
                  <div class="size-14 padding-9-0 font-bold">
                    {{ $t("changePriceLists.changePriceList.country") }}
                  </div>
                </el-col>
                <el-col :span="13" class="size-14 padding-9-0">{{
                  item.country
                }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="11">
                  <div class="size-14 padding-9-0 font-bold">
                    {{ $t("changePriceLists.changePriceList.date") }}
                  </div>
                </el-col>
                <el-col :span="13" class="size-14 padding-9-0">{{
                  item.date
                }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="11">
                  <div class="size-14 padding-9-0 font-bold">
                    {{ $t("changePriceLists.changePriceList.materialCost") }}
                  </div>
                </el-col>
                <el-col :span="13" class="size-14 padding-9-0">{{
                  item.cost
                }}</el-col>
              </el-row>
            </div>
          </div>
        </div>
        <!-- rawMaterial  --tcmdata-->
        <div class="card" v-show="chooseCurrent == 'rm' && TcmDataFalg">
          <div class="tcm-content-rawPic" v-if="previewData.attachmentList">
            <el-image
              v-for="(item, index) in previewData.attachmentList"
              style="width: 40px; height: 40px; margin-right: 10px"
              :src="item.url"
              :zoom-rate="1.2"
              :preview-src-list="attachmentList"
              :initial-index="index"
              fit="cover"
            />
          </div>
          <div class="regional_table">
            <span class="card-item-total"
              >{{ $t("changePriceLists.changePriceList.total") }}:
              {{ exchangeRateUnit }}
              {{ previewData.materialCost_SUM }}</span
            >
            <el-table
              :empty-text="$t('tcmlists.tcmlist.noData')"
              :data="previewData.materialLine"
              border
              stripe
              style="width: 100%; height: calc(100vh - 320px); margin-top: 10px"
            >
              <el-table-column
                type="index"
                fixed
                label="
                  #
                "
                align="center"
                show-overflow-tooltip
              />
              <el-table-column
                prop="description"
                :label="
                  $t('changePriceLists.changePriceList.materialDescription')
                "
                align="center"
                show-overflow-tooltip
              />
              <el-table-column
                width="100"
                prop="currencyName"
                :label="$t('changePriceLists.changePriceList.currency')"
                align="center"
              >
                <template #default="scope">
                  {{ filtrationCurrency(scope.row.currencyId) }}
                </template>
              </el-table-column>
              <el-table-column
                width="110"
                prop="convertCurrencyName"
                :label="$t('changePriceLists.changePriceList.convertCurrency')"
                align="center"
              >
                <template #default="scope">
                  {{ filtrationCurrency(scope.row.convertCurrencyId) }}
                </template>
              </el-table-column>
              <el-table-column
                width="110"
                prop="converRate"
                :label="$t('changePriceLists.changePriceList.exchangeRate')"
                align="center"
              />
              <el-table-column
                prop="vendor"
                :label="$t('changePriceLists.changePriceList.vendor')"
                align="center"
                width="120"
                show-overflow-tooltip
              />
              <el-table-column
                prop="netMWeight"
                :label="$t('changePriceLists.changePriceList.netWeight')"
                align="center"
                width="120"
                show-overflow-tooltip
              />
              <el-table-column
                prop="grossMWeight"
                :label="$t('changePriceLists.changePriceList.grossWeight')"
                align="center"
                width="120"
                show-overflow-tooltip
              >
                <template #default="scope">
                  <div class="flex" style="align-items: center">
                    {{ scope.row.grossMWeight }}
                    <img
                      v-show="scope.row.calculateData !== ''"
                      src="../../assets/img/tcm/daikuanjisuanqi.png"
                      style="width: 22px; margin-top: -4px; cursor: pointer"
                      @click="openCal(scope.row, 'grossMWeight')"
                    />
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="unitMeasureName"
                :label="$t('changePriceLists.changePriceList.unitMeasure')"
                align="center"
                width="120"
                show-overflow-tooltip
              />

              <el-table-column
                prop=""
                :label="$t('changePriceLists.changePriceList.materialMeasure')"
                align="center"
                show-overflow-tooltip
              >
                <template #default="scope">
                  <span
                    :style="
                      scope.row.unitCost_Before != scope.row.unitCost &&
                      showType == 'changePriceDetail'
                        ? 'color: red'
                        : ''
                    "
                  >
                    {{ scope.row.unitCost }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                v-if="showType == 'changePriceDetail'"
                prop=""
                :label="
                  $t('changePriceLists.changePriceList.materialMeasure') +
                  '(' +
                  $t('changePriceLists.changePriceList.beforeChangePrice') +
                  ')'
                "
                align="center"
                show-overflow-tooltip
              >
                <template #default="scope">
                  <span
                    :style="
                      scope.row.unitCost_Before != scope.row.unitCost &&
                      showType == 'changePriceDetail'
                        ? 'color: red'
                        : ''
                    "
                  >
                    {{ scope.row.unitCost_Before }}
                  </span>
                </template>
              </el-table-column>

              <el-table-column
                prop="unitResaleValue"
                :label="$t('changePriceLists.changePriceList.scrapPrice')"
                align="center"
                show-overflow-tooltip
              />
              <el-table-column
                prop="country"
                :label="$t('changePriceLists.changePriceList.country')"
                align="center"
                width="120"
                show-overflow-tooltip
              />
              <el-table-column
                prop="date"
                :label="$t('changePriceLists.changePriceList.date')"
                align="center"
                show-overflow-tooltip
              />
              <el-table-column
                prop="cost"
                :label="$t('changePriceLists.changePriceList.materialCost')"
                align="center"
                width="120"
                show-overflow-tooltip
              />
            </el-table>
          </div>
        </div>

        <!-- pur -->
        <div class="card" v-show="chooseCurrent == 'pur' && !TcmDataFalg">
          <div class="tcm-content-form-all dis-wrap">
            <div class="width-preview" v-for="item in previewData.purchaseLine">
              <el-row>
                <el-col :span="11">
                  <div class="size-14 padding-9-0 font-bold">
                    {{
                      $t("changePriceLists.changePriceList.processDescription")
                    }}
                  </div>
                </el-col>
                <el-col :span="13" class="size-14 padding-9-0">{{
                  item.name
                }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="11">
                  <div class="size-14 padding-9-0 font-bold">
                    {{ $t("changePriceLists.changePriceList.vendor") }}
                  </div>
                </el-col>
                <el-col :span="13" class="size-14 padding-9-0">{{
                  item.vendor
                }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="11">
                  <div class="size-14 padding-9-0 font-bold">
                    {{ $t("changePriceLists.changePriceList.consumption") }}
                  </div>
                </el-col>
                <el-col :span="13" class="size-14 padding-9-0">{{
                  item.quantity
                }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="11">
                  <div class="size-14 padding-9-0 font-bold">
                    {{ $t("changePriceLists.changePriceList.unitMeasure") }}
                  </div>
                </el-col>
                <el-col :span="13" class="size-14 padding-9-0">{{
                  item.unitMeasureName
                }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="11">
                  <div class="size-14 padding-9-0 font-bold">
                    {{ $t("changePriceLists.changePriceList.costUoM") }}
                  </div>
                </el-col>
                <el-col
                  :span="11"
                  class="size-14 padding-9-0"
                  :style="
                    item.unitCost_Before != item.unitCost &&
                    showType == 'changePriceDetail'
                      ? 'color: red'
                      : ''
                  "
                  >{{ item.unitCost }}</el-col
                >
                <el-col :span="2">
                  <img
                    src="../../assets/img/tcm/daikuanjisuanqi.png"
                    v-show="item.calculateData !== ''"
                    style="width: 22px; margin-top: -4px; cursor: pointer"
                    @click="openCal(item, 'unitCost')"
                  />
                </el-col>
              </el-row>
              <el-row v-show="showType == 'changePriceDetail'">
                <el-col :span="11">
                  <div class="size-14 padding-9-0 font-bold">
                    {{ $t("changePriceLists.changePriceList.costUoM") }}
                    ({{
                      $t("changePriceLists.changePriceList.beforeChangePrice")
                    }})
                  </div>
                </el-col>
                <el-col
                  :span="11"
                  class="size-14 padding-9-0"
                  :style="
                    item.unitCost_Before != item.unitCost &&
                    showType == 'changePriceDetail'
                      ? 'color: red'
                      : ''
                  "
                  >{{ item.unitCost_Before }}</el-col
                >
              </el-row>
              <el-row>
                <el-col :span="11">
                  <div class="size-14 padding-9-0 font-bold">
                    {{ $t("changePriceLists.changePriceList.subTotalCost") }}
                  </div>
                </el-col>
                <el-col :span="13" class="size-14 padding-9-0">{{
                  item.totalCost
                }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="11">
                  <div class="size-14 padding-9-0 font-bold">
                    {{ $t("changePriceLists.changePriceList.priceReference") }}
                  </div>
                </el-col>
                <el-col :span="13" class="size-14 padding-9-0">
                  {{ item.price }}
                  <!-- 改价调整 不使用 -->
                  <!-- <el-icon
					class="Modify-Price"
					:size="20"
					v-if="item.completeApplyId"
					@click="ChangePriceBtn(item.completeApplyId)"
					style="color: var(--themeColor)"
					><Edit
				  /></el-icon> -->
                </el-col>
              </el-row>
            </div>
          </div>
        </div>

        <!-- pur -- tcmdata -->
        <div class="card" v-show="chooseCurrent == 'pur' && TcmDataFalg">
          <div class="regional_table">
            <span class="card-item-total">
              {{ $t("changePriceLists.changePriceList.total") }}:
              {{ exchangeRateUnit }}
              {{ previewData.purchaseCost_SUM }}
            </span>
            <el-table
              :empty-text="$t('tcmlists.tcmlist.noData')"
              :data="previewData.purchaseLine"
              border
              stripe
              style="width: 100%; height: calc(100vh - 260px); margin-top: 10px"
            >
              <el-table-column
                type="index"
                fixed
                label="
                  #
                "
                align="center"
              />

              <el-table-column
                prop="name"
                :label="
                  $t('changePriceLists.changePriceList.processDescription')
                "
                align="center"
              />
              <el-table-column
                width="100"
                prop="currencyName"
                :label="$t('changePriceLists.changePriceList.currency')"
                align="center"
              >
                <template #default="scope">
                  {{ filtrationCurrency(scope.row.currencyId) }}
                </template>
              </el-table-column>
              <el-table-column
                width="110"
                prop="convertCurrencyName"
                :label="$t('changePriceLists.changePriceList.convertCurrency')"
                align="center"
              >
                <template #default="scope">
                  {{ filtrationCurrency(scope.row.convertCurrencyId) }}
                </template>
              </el-table-column>
              <el-table-column
                width="110"
                prop="converRate"
                :label="$t('changePriceLists.changePriceList.exchangeRate')"
                align="center"
              />
              <el-table-column
                prop="vendor"
                :label="$t('changePriceLists.changePriceList.vendor')"
                align="center"
              />
              <el-table-column
                prop="quantity"
                :label="$t('changePriceLists.changePriceList.consumption')"
                align="center"
              />
              <el-table-column
                prop="unitMeasureName"
                :label="$t('changePriceLists.changePriceList.unitMeasure')"
                align="center"
              />
              <el-table-column
                prop="unitMeasureName"
                :label="$t('changePriceLists.changePriceList.costUoM')"
                align="center"
              >
                <template #default="scope">
                  <div>
                    <span
                      :style="
                        scope.row.unitCost_Before != scope.row.unitCost &&
                        showType == 'changePriceDetail'
                          ? 'color: red'
                          : ''
                      "
                      >{{ scope.row.unitCost }}</span
                    >
                    <img
                      src="../../assets/img/tcm/daikuanjisuanqi.png"
                      v-show="scope.row.calculateData !== ''"
                      style="width: 22px; margin-top: -4px; cursor: pointer"
                      @click="openCal(scope.row, 'unitCost')"
                    />
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                v-if="showType == 'changePriceDetail'"
                prop="quantity"
                :label="
                  $t('changePriceLists.changePriceList.costUoM') +
                  '(' +
                  $t('changePriceLists.changePriceList.beforeChangePrice') +
                  ')'
                "
                align="center"
              >
                <template #default="scope">
                  <span
                    :style="
                      scope.row.unitCost_Before != scope.row.unitCost &&
                      showType == 'changePriceDetail'
                        ? 'color: red'
                        : ''
                    "
                  >
                    {{ scope.row.unitCost_Before }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                prop="totalCost"
                :label="$t('changePriceLists.changePriceList.subTotalCost')"
                align="center"
              />
              <el-table-column
                prop="price"
                :label="$t('changePriceLists.changePriceList.priceReference')"
                align="center"
              />
            </el-table>
          </div>
        </div>

        <!-- pro -->
        <div class="card" v-show="chooseCurrent == 'pro' && !TcmDataFalg">
          <div class="tcm-content-form-all dis-wrap">
            <div
              class="width-preview"
              v-for="(item, index) in previewData.processLine"
            >
              <div class="form-type-title">
                {{ $t("changePriceLists.changePriceList.setUp") }} #
                {{ index + 1 }}
              </div>
              <el-row>
                <el-col :span="11">
                  <div class="size-14 padding-9-0 font-bold">
                    {{ $t("changePriceLists.changePriceList.annualVolume") }}
                  </div>
                </el-col>
                <el-col :span="13" class="size-14 padding-9-0">{{
                  item.annualVolume
                }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="11">
                  <div class="size-14 padding-9-0 font-bold">
                    {{ $t("changePriceLists.changePriceList.setUpTime") }}
                  </div>
                </el-col>
                <el-col :span="13" class="size-14 padding-9-0">{{
                  item.setTime
                }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="11">
                  <div class="size-14 padding-9-0 font-bold">
                    {{ $t("changePriceLists.changePriceList.setUpPersons") }}
                  </div>
                </el-col>
                <el-col :span="13" class="size-14 padding-9-0">{{
                  item.setPerson
                }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="11">
                  <div class="size-14 padding-9-0 font-bold">
                    {{ $t("changePriceLists.changePriceList.piecesPerLot") }}
                  </div>
                </el-col>
                <el-col :span="13" class="size-14 padding-9-0">{{
                  item.pieceLot
                }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="11">
                  <div class="size-14 padding-9-0 font-bold">
                    {{ $t("changePriceLists.changePriceList.setUpCosts") }}
                  </div>
                </el-col>
                <el-col :span="13" class="size-14 padding-9-0">{{
                  item.pieceCost
                }}</el-col>
              </el-row>
              <div class="form-type-title">
                {{ $t("changePriceLists.changePriceList.operation") }}
                # {{ index + 1 }}
              </div>
              <el-row>
                <el-col :span="11">
                  <div class="size-14 padding-9-0 font-bold">
                    {{
                      $t(
                        "changePriceLists.changePriceList.operationDescription"
                      )
                    }}
                  </div>
                </el-col>
                <el-col :span="13" class="size-14 padding-9-0">{{
                  item.name
                }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="11">
                  <div class="size-14 padding-9-0 font-bold">
                    {{ $t("changePriceLists.changePriceList.vendor") }}
                  </div>
                </el-col>
                <el-col :span="13" class="size-14 padding-9-0">{{
                  item.vendor
                }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="11">
                  <div class="size-14 padding-9-0 font-bold">
                    {{ $t("changePriceLists.changePriceList.descriptionSize") }}
                  </div>
                </el-col>
                <el-col :span="11" class="size-14 padding-9-0">{{
                  item.equSize
                }}</el-col>
                <el-col :span="2">
                  <img
                    src="../../assets/img/tcm/daikuanjisuanqi.png"
                    v-show="item.equSizeCal"
                    style="width: 22px; margin-top: -4px; cursor: pointer"
                    @click="openCal(item, 'equSize')"
                  />
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="11">
                  <div class="size-14 padding-9-0 font-bold">
                    {{ $t("changePriceLists.changePriceList.consumption") }}
                  </div>
                </el-col>
                <el-col :span="13" class="size-14 padding-9-0">{{
                  item.quantity
                }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="11">
                  <div class="size-14 padding-9-0 font-bold">
                    {{ $t("changePriceLists.changePriceList.piecesHour") }}
                  </div>
                </el-col>
                <el-col :span="11" class="size-14 padding-9-0">{{
                  item.pieceHour
                }}</el-col>
                <el-col :span="2">
                  <img
                    src="../../assets/img/tcm/daikuanjisuanqi.png"
                    v-show="item.pieceHourCal"
                    style="width: 22px; margin-top: -4px; cursor: pointer"
                    @click="openCal(item, 'pieceHour')"
                  />
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="11">
                  <div class="size-14 padding-9-0 font-bold">
                    {{ $t("changePriceLists.changePriceList.piecesPer") }}
                  </div>
                </el-col>
                <el-col :span="13" class="size-14 padding-9-0">{{
                  item.pieceCycle
                }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="11">
                  <div class="size-14 padding-9-0 font-bold">
                    {{
                      $t(
                        "changePriceLists.changePriceList.manufacturingEfficiency"
                      )
                    }}
                  </div>
                </el-col>
                <el-col :span="13" class="size-14 padding-9-0">{{
                  item.efficiencyPercent
                }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="11">
                  <div class="size-14 padding-9-0 font-bold">
                    {{ $t("changePriceLists.changePriceList.standardHour") }}
                  </div>
                </el-col>
                <el-col :span="13" class="size-14 padding-9-0">{{
                  item.standardHour
                }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="11">
                  <div class="size-14 padding-9-0 font-bold">
                    {{ $t("changePriceLists.changePriceList.directRate") }}
                  </div>
                </el-col>
                <el-col :span="13" class="size-14 padding-9-0">{{
                  item.directRate
                }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="11">
                  <div class="size-14 padding-9-0 font-bold">
                    {{ $t("changePriceLists.changePriceList.setUpRate") }}
                  </div>
                </el-col>
                <el-col :span="13" class="size-14 padding-9-0">{{
                  item.setUpRate
                }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="11">
                  <div class="size-14 padding-9-0 font-bold">
                    {{ $t("changePriceLists.changePriceList.directOperators") }}
                  </div>
                </el-col>
                <el-col :span="13" class="size-14 padding-9-0">{{
                  item.directOperateQty
                }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="11">
                  <div class="size-14 padding-9-0 font-bold">
                    {{ $t("changePriceLists.changePriceList.directAssembly") }}
                  </div>
                </el-col>
                <el-col :span="13" class="size-14 padding-9-0">{{
                  item.assemblyDirectCost
                }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="11">
                  <div class="size-14 padding-9-0 font-bold">
                    {{ $t("changePriceLists.changePriceList.burdenVariable") }}
                  </div>
                </el-col>
                <el-col :span="13" class="size-14 padding-9-0">{{
                  item.variableRate
                }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="11">
                  <div class="size-14 padding-9-0 font-bold">
                    {{ $t("changePriceLists.changePriceList.burdenRate") }}
                  </div>
                </el-col>
                <el-col :span="13" class="size-14 padding-9-0">{{
                  item.fixedRate
                }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="11">
                  <div class="size-14 padding-9-0 font-bold">
                    {{
                      $t("changePriceLists.changePriceList.burdenDepreciation")
                    }}
                  </div>
                </el-col>
                <el-col :span="13" class="size-14 padding-9-0">{{
                  item.depreciationRate
                }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="11">
                  <div class="size-14 padding-9-0 font-bold">
                    {{ $t("changePriceLists.changePriceList.totalBurdenRate") }}
                  </div>
                </el-col>
                <el-col :span="13" class="size-14 padding-9-0">{{
                  item.totalBurdenRate
                }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="11">
                  <div class="size-14 padding-9-0 font-bold">
                    {{ $t("changePriceLists.changePriceList.burdenCost") }}
                  </div>
                </el-col>
                <el-col :span="13" class="size-14 padding-9-0">{{
                  item.assemblyBurdenCost
                }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="11">
                  <div class="size-14 padding-9-0 font-bold">
                    {{ $t("changePriceLists.changePriceList.subTotalCost") }}
                  </div>
                </el-col>
                <el-col :span="13" class="size-14 padding-9-0">{{
                  item.totalCost
                }}</el-col>
              </el-row>
              <el-row>
                <el-col :span="11">
                  <div class="size-14 padding-9-0 font-bold">
                    {{ $t("changePriceLists.changePriceList.processScrap") }}
                  </div>
                </el-col>
                <el-col :span="13" class="size-14 padding-9-0">{{
                  item.scrapPercent
                }}</el-col>
              </el-row>
            </div>
          </div>
        </div>

        <!-- pro --tcmdata pro -->

        <div class="card" v-show="chooseCurrent == 'pro' && TcmDataFalg">
          <div class="regional_table">
            <span class="card-item-total">
              {{ $t("changePriceLists.changePriceList.total") }}:
              {{ exchangeRateUnit }}
              {{ ProTotal }}
            </span>
            <el-table
              :header-cell-style="{ fontSize: '11px' }"
              :empty-text="$t('tcmlists.tcmlist.noData')"
              :data="previewData.processLine"
              border
              stripe
              style="width: 100%; height: calc(100vh - 260px); margin-top: 10px"
            >
              <el-table-column
                type="inex"
                class-name="lastTd"
                fixed
                label="#"
                align="center"
              >
                <template #default="scope">
                  {{ scope.$index + 1 }}
                </template>
              </el-table-column>
              <el-table-column
                class-name="lastTd"
                fixed
                width="130"
                prop="name"
                :label="
                  $t('changePriceLists.changePriceList.operationDescription')
                "
                align="center"
              />
              <el-table-column
                width="100"
                prop="currencyName"
                :label="$t('changePriceLists.changePriceList.currency')"
                align="center"
              >
                <template #default="scope">
                  {{ filtrationCurrency(scope.row.currencyId) }}
                </template>
              </el-table-column>
              <el-table-column
                width="100"
                prop="convertCurrencyName"
                :label="$t('changePriceLists.changePriceList.convertCurrency')"
                align="center"
              >
                <template #default="scope">
                  {{ filtrationCurrency(scope.row.convertCurrencyId) }}
                </template>
              </el-table-column>
              <el-table-column
                width="100"
                prop="converRate"
                :label="$t('changePriceLists.changePriceList.exchangeRate')"
                align="center"
              />
              <el-table-column
                width="100"
                prop="annualVolume"
                :label="$t('changePriceLists.changePriceList.annualVolume')"
                align="center"
              />
              <el-table-column
                prop="setTime"
                width="130"
                :label="$t('changePriceLists.changePriceList.setUpTime')"
                align="center"
              />
              <el-table-column
                width="120"
                prop="setPerson"
                :label="$t('changePriceLists.changePriceList.setUpPersons')"
                align="center"
              />
              <el-table-column
                width="100"
                prop="pieceLot"
                :label="$t('changePriceLists.changePriceList.piecesPerLot')"
                align="center"
              />
              <el-table-column
                width="130"
                prop="pieceCost"
                :label="$t('changePriceLists.changePriceList.setUpCosts')"
                align="center"
              />

              <el-table-column
                width="80"
                prop="vendor"
                :label="$t('changePriceLists.changePriceList.vendor')"
                align="center"
              />
              <el-table-column
                width="130"
                prop=""
                :label="$t('changePriceLists.changePriceList.descriptionSize')"
                align="center"
              >
                <template #default="scope">
                  <div>
                    <span>{{ scope.row.equSize }}</span>
                    <img
                      src="../../assets/img/tcm/daikuanjisuanqi.png"
                      v-show="scope.row.equSizeCal"
                      style="width: 22px; cursor: pointer"
                      @click="openCal(scope.row, 'equSize')"
                    />
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="quantity"
                :label="$t('changePriceLists.changePriceList.consumption')"
                align="center"
              />
              <el-table-column
                width="100"
                prop="pieceHour"
                :label="$t('changePriceLists.changePriceList.piecesHour')"
                align="center"
              >
                <template #default="scope">
                  <div>
                    <span>{{ scope.row.pieceHour }}</span>
                    <img
                      src="../../assets/img/tcm/daikuanjisuanqi.png"
                      v-show="scope.row.pieceHourCal"
                      style="width: 22px; cursor: pointer"
                      @click="openCal(scope.row, 'pieceHour')"
                    />
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="pieceCycle"
                width="100"
                :label="$t('changePriceLists.changePriceList.piecesPer')"
                align="center"
              />
              <el-table-column
                prop="efficiencyPercent"
                width="130"
                :label="
                  $t('changePriceLists.changePriceList.manufacturingEfficiency')
                "
                align="center"
              />
              <el-table-column
                prop="standardHour"
                :label="$t('changePriceLists.changePriceList.standardHour')"
                align="center"
              />
              <el-table-column
                width="100"
                prop="directRate"
                :label="$t('changePriceLists.changePriceList.directRate')"
                align="center"
              />
              <el-table-column
                prop="setUpRate"
                width="100"
                :label="$t('changePriceLists.changePriceList.setUpRate')"
                align="center"
              />
              <el-table-column
                width="100"
                prop="directOperateQty"
                :label="$t('changePriceLists.changePriceList.directOperators')"
                align="center"
              />
              <el-table-column
                width="100"
                prop="assemblyDirectCost"
                :label="$t('changePriceLists.changePriceList.directAssembly')"
                align="center"
              />
              <el-table-column
                prop="variableRate"
                width="120"
                :label="$t('changePriceLists.changePriceList.burdenVariable')"
                align="center"
              />
              <el-table-column
                width="130"
                prop="fixedRate"
                :label="$t('changePriceLists.changePriceList.burdenRate')"
                align="center"
              />
              <el-table-column
                width="100"
                prop="depreciationRate"
                :label="
                  $t('changePriceLists.changePriceList.burdenDepreciation')
                "
                align="center"
              />
              <el-table-column
                prop="totalBurdenRate"
                :label="$t('changePriceLists.changePriceList.totalBurdenRate')"
                align="center"
              />
              <el-table-column
                width="100"
                prop="assemblyBurdenCost"
                :label="$t('changePriceLists.changePriceList.burdenCost')"
                align="center"
              />
              <el-table-column
                width="100"
                prop="totalCost"
                :label="$t('changePriceLists.changePriceList.subTotalCost')"
                align="center"
              />
              <el-table-column
                width="100"
                prop="scrapPercent"
                :label="$t('changePriceLists.changePriceList.processScrap')"
                align="center"
              />
            </el-table>
          </div>
        </div>

        <!-- <div class="tcm-content-title mgt-10">
          {{ $t("changePriceLists.changePriceList.dutiesCustoms") }}
        </div> -->
        <div class="card" v-show="chooseCurrent == 'p&l'">
          <div class="form-box">
            <div
              class="tcm-content-form-all"
              style="padding: 20px 20px 40px 20px"
            >
              <el-form
                :model="previewData"
                label-width="500px"
                class="custom-el-form-label-bold"
                label-position="left"
              >
                <div class="form-type-title">
                  {{ $t("changePriceLists.changePriceList.Packaging") }}
                </div>
                <el-form-item
                  style="margin-left: 140px"
                  :label="$t('changePriceLists.changePriceList.freightCost')"
                >
                  {{ previewData.freightCost }}
                  {{ previewData.calculationMethod_Freight == 2 ? "%" : "" }}

                  <span
                    style="margin-left: 10px; color: gray; line-height: 32px"
                  >
                    {{
                      previewData.calculationMethod_Freight == 2 &&
                      previewData.manufacturingCost_PRO !== "" &&
                      previewData.freightCost !== "" &&
                      previewData.manufacturingCost_PRO !== null &&
                      previewData.freightCost
                        ? (
                            (previewData.freightCost / 100) *
                            previewData.manufacturingCost_PRO
                          ).toFixed(3)
                        : ""
                    }}
                  </span>
                </el-form-item>

                <el-form-item
                  style="margin-left: 140px"
                  :label="$t('changePriceLists.changePriceList.wareCost')"
                >
                  {{ previewData.wareHouseCost }}
                  {{ previewData.calculationMethod_Ware == 2 ? "%" : "" }}

                  <span
                    style="margin-left: 10px; color: gray; line-height: 32px"
                  >
                    {{
                      previewData.calculationMethod_Freight == 2 &&
                      previewData.manufacturingCost_PRO !== "" &&
                      previewData.wareHouseCost !== "" &&
                      previewData.manufacturingCost_PRO !== null &&
                      previewData.wareHouseCost
                        ? (
                            (previewData.wareHouseCost / 100) *
                            previewData.manufacturingCost_PRO
                          ).toFixed(3)
                        : ""
                    }}
                  </span>
                </el-form-item>

                <el-form-item
                  style="margin-left: 140px"
                  :label="$t('changePriceLists.changePriceList.packagingCost')"
                >
                  {{ previewData.packageCost }}
                  {{ previewData.calculationMethod_Package == 2 ? "%" : "" }}
                  <span
                    style="margin-left: 10px; color: gray; line-height: 32px"
                  >
                    {{
                      previewData.calculationMethod_Freight == 2 &&
                      previewData.manufacturingCost_PRO !== "" &&
                      previewData.packageCost !== "" &&
                      previewData.manufacturingCost_PRO !== null &&
                      previewData.packageCost
                        ? (
                            (previewData.packageCost / 100) *
                            previewData.manufacturingCost_PRO
                          ).toFixed(3)
                        : ""
                    }}
                  </span>
                </el-form-item>

                <el-form-item
                  style="margin-left: 140px"
                  :label="$t('changePriceLists.changePriceList.taxesFees')"
                >
                  {{ previewData.otherCost }}
                  {{ previewData.calculationMethod_Taxes == 2 ? "%" : "" }}
                  <span
                    style="margin-left: 10px; color: gray; line-height: 32px"
                  >
                    {{
                      previewData.otherCost == 2 &&
                      previewData.manufacturingCost_PRO !== "" &&
                      previewData.otherCost !== "" &&
                      previewData.manufacturingCost_PRO !== null &&
                      previewData.otherCost
                        ? (
                            (previewData.otherCost / 100) *
                            previewData.manufacturingCost_PRO
                          ).toFixed(3)
                        : ""
                    }}
                  </span>
                </el-form-item>
                <div class="form-type-title">
                  {{ $t("changePriceLists.changePriceList.Absorbed") }}
                </div>
                <el-form-item
                  style="margin-left: 140px"
                  :label="
                    $t('changePriceLists.changePriceList.addedDescription')
                  "
                >
                  {{ previewData.amortizationDescription }}
                </el-form-item>
                <el-form-item
                  style="margin-left: 140px"
                  :label="$t('changePriceLists.changePriceList.totalAddedCost')"
                >
                  {{ previewData.totalPieces }}
                </el-form-item>
                <el-form-item
                  style="margin-left: 140px"
                  :label="
                    $t('changePriceLists.changePriceList.totalPiecesAdded')
                  "
                >
                  {{ previewData.totalAmortize }}
                </el-form-item>
                <el-form-item
                  style="margin-left: 140px"
                  :label="$t('changePriceLists.changePriceList.addedCost')"
                >
                  {{ previewData.amortizeCost }}
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>

        <div class="card" v-show="chooseCurrent == 'sga'">
          <div class="form-box">
            <div class="tcm-content-form-all">
              <el-form
                :model="previewData"
                label-width="300px"
                label-position="left"
                class="custom-el-form-label-bold"
              >
                <div class="form-type-title">
                  {{ $t("changePriceLists.changePriceList.sgas") }}
                </div>

                <el-form-item
                  style="margin-left: 140px"
                  :label="$t('changePriceLists.changePriceList.sga')"
                >
                  {{ previewData.sga }}
                </el-form-item>

                <el-form-item
                  style="margin-left: 140px"
                  :label="$t('changePriceLists.changePriceList.sgaCost')"
                >
                  {{ previewData.sgaCost }}
                </el-form-item>

                <div class="form-type-title">
                  {{ $t("changePriceLists.changePriceList.profit") }}
                </div>

                <el-form-item
                  style="margin-left: 140px"
                  :label="$t('changePriceLists.changePriceList.profitMaterial')"
                >
                  {{ previewData.materialProfit }}
                </el-form-item>

                <el-form-item
                  style="margin-left: 140px"
                  :label="
                    $t('changePriceLists.changePriceList.profitComponents')
                  "
                >
                  {{ previewData.addProfit }}
                </el-form-item>

                <el-form-item
                  style="margin-left: 140px"
                  :label="$t('changePriceLists.changePriceList.profitValue')"
                >
                  {{ previewData.purchaseProfit }}
                </el-form-item>

                <el-form-item
                  style="margin-left: 140px"
                  :label="$t('changePriceLists.changePriceList.totalProfit')"
                >
                  {{ previewData.totalProfit }}
                </el-form-item>
                <el-form-item
                  style="margin-left: 140px"
                  :label="
                    $t('changePriceLists.changePriceList.totalprofitCost')
                  "
                >
                  {{ previewData.totalProfitCost }}
                </el-form-item>

                <div class="form-type-title">
                  {{ $t("changePriceLists.changePriceList.Scrap") }}
                </div>

                <el-form-item
                  style="margin-left: 140px"
                  :label="$t('changePriceLists.changePriceList.materialScrap')"
                >
                  {{ previewData.materialPC }}
                </el-form-item>
                <!-- <div class="form-type-title">
                  {{ $t("changePriceLists.changePriceList.total") }}
                </div>
                <el-form-item
                  style="margin-left: 140px"
                  :label="$t('changePriceLists.changePriceList.total')"
                >
                  {{ previewData.materialPC }}
                </el-form-item> -->
              </el-form>
            </div>
          </div>
        </div>
        <div class="card" v-show="chooseCurrent == 'sum'">
          <div class="form-box">
            <div class="tcm-content-form-all">
              <el-form
                :model="previewData"
                label-width="310px"
                class="custom-el-form-label-bold"
                label-position="left"
              >
                <el-row>
                  <el-col :span="10">
                    <el-form-item
                      :label="
                        $t('changePriceLists.changePriceList.rawMaterialCost')
                      "
                    >
                      {{ exchangeRateUnit }} {{ previewData.materialCost_SUM }}
                    </el-form-item>
                  </el-col>
                  <el-col :span="2">
                    <span style="color: red; line-height: 32px">{{
                      previewData.materialCost_SUM_After
                        ? previewData.materialCost_SUM_After
                        : ""
                    }}</span>
                  </el-col>
                  <el-col :span="2">
                    <span
                      style="margin-left: 10px; color: gray; line-height: 32px"
                    >
                      {{
                        isNaN(materialCost_SUM_Compute)
                          ? ""
                          : materialCost_SUM_Compute + "%"
                      }}
                    </span>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="10">
                    <el-form-item
                      :label="
                        $t('changePriceLists.changePriceList.purchasedCost')
                      "
                    >
                      {{ exchangeRateUnit }} {{ previewData.purchaseCost_SUM }}
                    </el-form-item>
                  </el-col>
                  <el-col :span="2">
                    <span style="color: red; line-height: 32px">
                      {{
                        previewData.purchaseCost_SUM_After
                          ? previewData.purchaseCost_SUM_After
                          : ""
                      }}</span
                    >
                  </el-col>
                  <el-col :span="2">
                    <span
                      style="margin-left: 10px; color: gray; line-height: 32px"
                    >
                      {{
                        isNaN(purchaseCost_SUM_Compute)
                          ? ""
                          : purchaseCost_SUM_Compute + "%"
                      }}
                    </span>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item
                      :label="
                        $t('changePriceLists.changePriceList.directLaborCost')
                      "
                    >
                      {{ exchangeRateUnit }} {{ previewData.laborCost_SUM }}
                    </el-form-item>
                  </el-col>
                  <el-col :span="2">
                    <span
                      style="margin-left: 10px; color: gray; line-height: 32px"
                    >
                      {{ isNaN(t1) ? "" : t1 + "%" }}
                    </span>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item
                      :label="$t('changePriceLists.changePriceList.burdenCost')"
                    >
                      {{ exchangeRateUnit }} {{ previewData.burdenCost_SUM }}
                    </el-form-item>
                  </el-col>
                  <el-col :span="2">
                    <span
                      style="margin-left: 10px; color: gray; line-height: 32px"
                    >
                      {{ isNaN(t2) ? "" : t2 + "%" }}
                    </span>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item
                      :label="$t('changePriceLists.changePriceList.setUpCost')"
                    >
                      {{ exchangeRateUnit }} {{ previewData.setUpCost_SUM }}
                    </el-form-item>
                  </el-col>
                  <el-col :span="2">
                    <span
                      style="margin-left: 10px; color: gray; line-height: 32px"
                    >
                      {{ isNaN(t3) ? "" : t3 + "%" }}
                    </span>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item
                      :label="
                        $t(
                          'changePriceLists.changePriceList.totalManufacturing'
                        )
                      "
                    >
                      {{ exchangeRateUnit }}
                      {{ previewData.manufacturingCost_PRO }}
                    </el-form-item>
                  </el-col>
                  <el-col :span="2">
                    <span
                      style="margin-left: 10px; color: gray; line-height: 32px"
                    >
                      {{
                        (
                          parseFloat(
                            isNaN(materialCost_SUM_Compute)
                              ? 0
                              : materialCost_SUM_Compute
                          ) +
                          parseFloat(
                            isNaN(purchaseCost_SUM_Compute)
                              ? 0
                              : purchaseCost_SUM_Compute
                          ) +
                          parseFloat(isNaN(t1) ? 0 : t1) +
                          parseFloat(isNaN(t2) ? 0 : t2) +
                          parseFloat(isNaN(t3) ? 0 : t3)
                        ).toFixed(3)
                      }}%
                    </span>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item
                      :label="
                        $t(
                          'changePriceLists.changePriceList.packagingFreightCost'
                        )
                      "
                    >
                      {{ exchangeRateUnit }} {{ previewData.packageCost_SUM }}
                    </el-form-item>
                  </el-col>
                  <el-col :span="2">
                    <span
                      style="margin-left: 10px; color: gray; line-height: 32px"
                    >
                      {{
                        previewData.packageCost_SUM !== "" &&
                        previewData.totalPriceCurr_SUM !== "" &&
                        previewData.packageCost_SUM !== null &&
                        previewData.totalPriceCurr_SUM
                          ? (
                              (previewData.packageCost_SUM /
                                previewData.totalPriceCurr_SUM) *
                              100
                            ).toFixed(3) + "%"
                          : ""
                      }}
                    </span>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item
                      :label="
                        $t('changePriceLists.changePriceList.amortizationFees')
                      "
                    >
                      {{ exchangeRateUnit }} {{ previewData.otherCost_SUM }}
                    </el-form-item>
                  </el-col>
                  <el-col :span="2">
                    <span
                      style="margin-left: 10px; color: gray; line-height: 32px"
                    >
                      {{
                        previewData.otherCost_SUM !== "" &&
                        previewData.totalPriceCurr_SUM !== "" &&
                        previewData.otherCost_SUM !== null &&
                        previewData.totalPriceCurr_SUM
                          ? (
                              (previewData.otherCost_SUM /
                                previewData.totalPriceCurr_SUM) *
                              100
                            ).toFixed(3) + "%"
                          : ""
                      }}
                    </span>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item
                      :label="
                        $t('changePriceLists.changePriceList.amortizationCost')
                      "
                    >
                      {{ exchangeRateUnit }}
                      {{ previewData.amortizeCost_SUM }}
                    </el-form-item>
                  </el-col>
                  <el-col :span="2">
                    <span
                      style="margin-left: 10px; color: gray; line-height: 32px"
                    >
                      {{
                        previewData.amortizeCost_SUM !== "" &&
                        previewData.totalPriceCurr_SUM !== "" &&
                        previewData.amortizeCost_SUM !== null &&
                        previewData.totalPriceCurr_SUM
                          ? (
                              (previewData.amortizeCost_SUM /
                                previewData.totalPriceCurr_SUM) *
                              100
                            ).toFixed(3) + "%"
                          : ""
                      }}
                    </span>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item
                      :label="
                        $t('changePriceLists.changePriceList.processScrapCost')
                      "
                    >
                      {{ exchangeRateUnit }} {{ previewData.scrapCost_SUM }}
                    </el-form-item>
                  </el-col>
                  <el-col :span="2">
                    <span
                      style="margin-left: 10px; color: gray; line-height: 32px"
                    >
                      {{
                        previewData.scrapCost_SUM !== "" &&
                        previewData.totalPriceCurr_SUM !== "" &&
                        previewData.scrapCost_SUM !== null &&
                        previewData.totalPriceCurr_SUM
                          ? (
                              (previewData.scrapCost_SUM /
                                previewData.totalPriceCurr_SUM) *
                              100
                            ).toFixed(3) + "%"
                          : ""
                      }}
                    </span>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item
                      :label="
                        $t('changePriceLists.changePriceList.sgaAndProfit')
                      "
                    >
                      {{ exchangeRateUnit }} {{ previewData.sgaProfit_SUM }}
                    </el-form-item>
                  </el-col>
                  <el-col :span="2">
                    <span
                      style="margin-left: 10px; color: gray; line-height: 32px"
                    >
                      {{
                        previewData.sgaProfit_SUM !== "" &&
                        previewData.totalPriceCurr_SUM !== "" &&
                        previewData.sgaProfit_SUM !== null &&
                        previewData.totalPriceCurr_SUM
                          ? (
                              (previewData.sgaProfit_SUM /
                                previewData.totalPriceCurr_SUM) *
                              100
                            ).toFixed(3) + "%"
                          : ""
                      }}
                    </span>
                  </el-col>
                </el-row>
                <!-- 改价之前详情 -->
                <el-row v-show="showType != 'changePriceDetail'">
                  <el-col :span="16">
                    <el-form-item
                      :label="
                        $t('changePriceLists.changePriceList.totalSellPrice')
                      "
                    >
                      {{ exchangeRateUnit }}
                      <span
                        :style="
                          previewData.totalPriceCurr_SUM_Before !=
                            previewData.totalPriceCurr_SUM &&
                          showType == 'changePriceDetail'
                            ? 'color: red'
                            : ''
                        "
                      >
                        {{
                          parseFloat(
                            previewData.totalPriceCurr_SUM === "" ||
                              previewData.totalPriceCurr_SUM === null
                              ? 0
                              : previewData.totalPriceCurr_SUM
                          ).toFixed(3)
                        }}
                      </span>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row v-show="showType != 'changePriceDetail'">
                  <el-col :span="16">
                    <el-form-item
                      :label="
                        $t('changePriceLists.changePriceList.totalSellCurrency')
                      "
                    >
                      {{ reportExchangeRateUnit }}
                      <span
                        :style="
                          previewData.totalPriceCurr_SUM_Before !=
                            previewData.totalPriceCurr_SUM &&
                          showType == 'changePriceDetail'
                            ? 'color: red'
                            : ''
                        "
                      >
                        {{
                          parseFloat(
                            previewData.totalPriceReport_SUM === "" ||
                              previewData.totalPriceReport_SUM === null
                              ? 0
                              : previewData.totalPriceReport_SUM
                          ).toFixed(3)
                        }}
                      </span>
                    </el-form-item>
                  </el-col>
                </el-row>
                <!-- 改价后 before为改价后的数据 -->
                <el-row v-show="showType == 'changePriceDetail'">
                  <el-col :span="16">
                    <el-form-item
                      :label="
                        $t('changePriceLists.changePriceList.totalSellPrice')
                      "
                    >
                      {{ exchangeRateUnit }}
                      <span
                        :style="
                          previewData.totalPriceCurr_SUM_Before !=
                            previewData.totalPriceCurr_SUM &&
                          showType == 'changePriceDetail'
                            ? 'color: red'
                            : ''
                        "
                      >
                        {{
                          parseFloat(
                            previewData.totalPriceCurr_SUM_Before === "" ||
                              previewData.totalPriceCurr_SUM_Before === null
                              ? 0
                              : previewData.totalPriceCurr_SUM_Before
                          ).toFixed(3)
                        }}
                      </span>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row v-show="showType == 'changePriceDetail'">
                  <el-col :span="16">
                    <el-form-item
                      :label="
                        $t(
                          'changePriceLists.changePriceList.totalSellPriceBefore'
                        )
                      "
                    >
                      {{ exchangeRateUnit }}
                      <span
                        :style="
                          previewData.totalPriceCurr_SUM_Before !=
                            previewData.totalPriceCurr_SUM &&
                          showType == 'changePriceDetail'
                            ? 'color: red'
                            : ''
                        "
                      >
                        {{
                          parseFloat(
                            previewData.totalPriceCurr_SUM === "" ||
                              previewData.totalPriceCurr_SUM === null
                              ? 0
                              : previewData.totalPriceCurr_SUM
                          ).toFixed(3)
                        }}
                      </span>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row v-show="showType == 'changePriceDetail'">
                  <el-col :span="16">
                    <el-form-item
                      :label="
                        $t('changePriceLists.changePriceList.totalSellCurrency')
                      "
                    >
                      {{ reportExchangeRateUnit }}
                      <span
                        :style="
                          previewData.totalPriceReport_SUM_Before !=
                            previewData.totalPriceReport_SUM &&
                          showType == 'changePriceDetail'
                            ? 'color: red'
                            : ''
                        "
                      >
                        {{
                          parseFloat(
                            previewData.totalPriceReport_SUM_Before === "" ||
                              previewData.totalPriceReport_SUM_Before === null
                              ? 0
                              : previewData.totalPriceReport_SUM_Before
                          ).toFixed(3)
                        }}
                      </span>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row v-show="showType == 'changePriceDetail'">
                  <el-col :span="16">
                    <el-form-item
                      :label="
                        $t(
                          'changePriceLists.changePriceList.totalSellCurrencyBefore'
                        )
                      "
                    >
                      {{ reportExchangeRateUnit }}
                      <span
                        :style="
                          previewData.totalPriceCurr_SUM_Before !=
                            previewData.totalPriceCurr_SUM &&
                          showType == 'changePriceDetail'
                            ? 'color: red'
                            : ''
                        "
                      >
                        {{
                          parseFloat(
                            previewData.totalPriceReport_SUM === "" ||
                              previewData.totalPriceReport_SUM === null
                              ? 0
                              : previewData.totalPriceReport_SUM
                          ).toFixed(3)
                        }}
                      </span>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="exportPdf">
      <div
        v-show="reportShow"
        style="padding: 20px 90px 0 20px; box-sizing: border-box"
      >
        <div class="tcm-content-title">
          {{ $t("changePriceLists.changePriceList.generalInformation") }}
        </div>
        <div class="tcm-content-form-all">
          <el-form
            :model="genForm"
            label-width="150px"
            class="custom-el-form-label-bold"
            label-position="left"
          >
            <el-row>
              <el-col :span="8">
                <el-form-item :label="$t('userlists.userlist.quoteType')">
                  {{ genForm.quoteTypeName }}
                </el-form-item>
              </el-col>
              <el-col :span="8"> </el-col>
              <el-col :span="8"> </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item
                  :label="$t('changePriceLists.changePriceList.programName')"
                >
                  {{ genForm.programName }}
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  :label="$t('changePriceLists.changePriceList.ecrNo')"
                >
                  {{ genForm.ecrno }}
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  :label="$t('changePriceLists.changePriceList.commodity')"
                >
                  {{ genForm.commodityName }}
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item
                  :label="
                    $t('changePriceLists.changePriceList.partDescription')
                  "
                >
                  {{ genForm.partDescription }}
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  :label="$t('changePriceLists.changePriceList.partNumber')"
                >
                  {{ genForm.partNumber }}
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  :label="$t('changePriceLists.changePriceList.partRevision')"
                >
                  {{ genForm.partRevision }}
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item
                  :label="$t('changePriceLists.changePriceList.currency')"
                >
                  {{ genList.Currency }}
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  :label="$t('changePriceLists.changePriceList.reportCurrency')"
                >
                  {{ genList.ReportCurrency }}
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  :label="$t('changePriceLists.changePriceList.exchangeRate')"
                >
                  {{ genList.rate }}
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item
                  :label="$t('changePriceLists.changePriceList.region')"
                >
                  {{ genList.regionName }}
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  :label="$t('changePriceLists.changePriceList.supplierName')"
                >
                  {{ genList.supplierName }}
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  :label="
                    $t('changePriceLists.changePriceList.materialDescription')
                  "
                >
                  {{ genList.materialDescription }}
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item
                  :label="$t('changePriceLists.changePriceList.peakVolume')"
                >
                  {{ genList.peakVolume }}
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  :label="$t('changePriceLists.changePriceList.lifetimeVolume')"
                >
                  {{ genList.lifetimeVolume }}
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  :label="$t('changePriceLists.changePriceList.pegPoint')"
                >
                  {{ genList.pegPoint }}
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div class="tcm-content-title mgt-10">
          {{ $t("changePriceLists.changePriceList.rawMaterial") }}
          <div class="tcm-content-rawPic">
            <el-image
              v-for="(item, index) in previewData.attachmentList"
              style="width: 40px; height: 40px; margin-right: 10px"
              :src="item.url"
              :zoom-rate="1.2"
              :preview-src-list="attachmentList"
              :initial-index="index"
              fit="cover"
            />
          </div>
        </div>
        <div class="tcm-content-form-all dis-wrap">
          <div class="width-preview" v-for="item in previewData.materialLine">
            <el-row>
              <el-col :span="11">
                <div class="size-14 padding-9-0 font-bold">
                  {{
                    $t("changePriceLists.changePriceList.materialDescription")
                  }}
                </div>
              </el-col>
              <el-col :span="13" class="size-14 padding-9-0">{{
                item.description
              }}</el-col>
            </el-row>
            <el-row>
              <el-col :span="11">
                <div class="size-14 padding-9-0 font-bold">
                  {{ $t("changePriceLists.changePriceList.vendor") }}
                </div>
              </el-col>
              <el-col :span="13" class="size-14 padding-9-0">{{
                item.vendor
              }}</el-col>
            </el-row>
            <el-row>
              <el-col :span="11">
                <div class="size-14 padding-9-0 font-bold">
                  {{ $t("changePriceLists.changePriceList.netWeight") }}
                </div>
              </el-col>
              <el-col :span="13" class="size-14 padding-9-0">{{
                item.netMWeight
              }}</el-col>
            </el-row>
            <el-row>
              <el-col :span="11">
                <div class="size-14 padding-9-0 font-bold">
                  {{ $t("changePriceLists.changePriceList.grossWeight") }}
                </div>
              </el-col>
              <el-col :span="11" class="size-14 padding-9-0">{{
                item.grossMWeight
              }}</el-col>
              <el-col :span="2">
                <img
                  src="../../assets/img/tcm/daikuanjisuanqi.png"
                  v-show="item.calculateData !== ''"
                  style="width: 22px; margin-top: -4px; cursor: pointer"
                  @click="openCal(item, 'grossMWeight')"
                />
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="11">
                <div class="size-14 padding-9-0 font-bold">
                  {{ $t("changePriceLists.changePriceList.unitMeasure") }}
                </div>
              </el-col>
              <el-col :span="13" class="size-14 padding-9-0">{{
                item.unitMeasureName
              }}</el-col>
            </el-row>
            <el-row>
              <el-col :span="11">
                <div class="size-14 padding-9-0 font-bold">
                  {{ $t("changePriceLists.changePriceList.materialMeasure") }}
                </div>
              </el-col>
              <el-col
                :span="13"
                class="size-14 padding-9-0"
                :style="
                  item.unitCost_Before != item.unitCost &&
                  showType == 'changePriceDetail'
                    ? 'color: red'
                    : ''
                "
                >{{ item.unitCost }}</el-col
              >
            </el-row>
            <el-row v-show="showType == 'changePriceDetail'">
              <el-col :span="11">
                <div class="size-14 padding-9-0 font-bold">
                  {{ $t("changePriceLists.changePriceList.materialMeasure") }}
                  ({{
                    $t("changePriceLists.changePriceList.beforeChangePrice")
                  }})
                </div>
              </el-col>
              <el-col
                :span="13"
                class="size-14 padding-9-0"
                :style="
                  item.unitCost_Before != item.unitCost &&
                  showType == 'changePriceDetail'
                    ? 'color: red'
                    : ''
                "
                >{{ item.unitCost_Before }}</el-col
              >
            </el-row>
            <el-row>
              <el-col :span="11">
                <div class="size-14 padding-9-0 font-bold">
                  {{ $t("changePriceLists.changePriceList.scrapPrice") }}
                </div>
              </el-col>
              <el-col :span="13" class="size-14 padding-9-0">{{
                item.unitResaleValue
              }}</el-col>
            </el-row>
            <el-row>
              <el-col :span="11">
                <div class="size-14 padding-9-0 font-bold">
                  {{ $t("changePriceLists.changePriceList.country") }}
                </div>
              </el-col>
              <el-col :span="13" class="size-14 padding-9-0">{{
                item.country
              }}</el-col>
            </el-row>
            <el-row>
              <el-col :span="11">
                <div class="size-14 padding-9-0 font-bold">
                  {{ $t("changePriceLists.changePriceList.date") }}
                </div>
              </el-col>
              <el-col :span="13" class="size-14 padding-9-0">{{
                item.date
              }}</el-col>
            </el-row>
            <el-row>
              <el-col :span="11">
                <div class="size-14 padding-9-0 font-bold">
                  {{ $t("changePriceLists.changePriceList.materialCost") }}
                </div>
              </el-col>
              <el-col :span="13" class="size-14 padding-9-0">{{
                item.cost
              }}</el-col>
            </el-row>
          </div>
        </div>
        <div class="tcm-content-title mgt-10">
          {{ $t("changePriceLists.changePriceList.purchasedComponents") }}
        </div>
        <div class="tcm-content-form-all dis-wrap">
          <div class="width-preview" v-for="item in previewData.purchaseLine">
            <el-row>
              <el-col :span="11">
                <div class="size-14 padding-9-0 font-bold">
                  {{
                    $t("changePriceLists.changePriceList.processDescription")
                  }}
                </div>
              </el-col>
              <el-col :span="13" class="size-14 padding-9-0">{{
                item.name
              }}</el-col>
            </el-row>
            <el-row>
              <el-col :span="11">
                <div class="size-14 padding-9-0 font-bold">
                  {{ $t("changePriceLists.changePriceList.vendor") }}
                </div>
              </el-col>
              <el-col :span="13" class="size-14 padding-9-0">{{
                item.vendor
              }}</el-col>
            </el-row>
            <el-row>
              <el-col :span="11">
                <div class="size-14 padding-9-0 font-bold">
                  {{ $t("changePriceLists.changePriceList.consumption") }}
                </div>
              </el-col>
              <el-col :span="13" class="size-14 padding-9-0">{{
                item.quantity
              }}</el-col>
            </el-row>
            <el-row>
              <el-col :span="11">
                <div class="size-14 padding-9-0 font-bold">
                  {{ $t("changePriceLists.changePriceList.unitMeasure") }}
                </div>
              </el-col>
              <el-col :span="13" class="size-14 padding-9-0">{{
                item.unitMeasureName
              }}</el-col>
            </el-row>
            <el-row>
              <el-col :span="11">
                <div class="size-14 padding-9-0 font-bold">
                  {{ $t("changePriceLists.changePriceList.costUoM") }}
                </div>
              </el-col>
              <el-col
                :span="11"
                class="size-14 padding-9-0"
                :style="
                  item.unitCost_Before != item.unitCost &&
                  showType == 'changePriceDetail'
                    ? 'color: red'
                    : ''
                "
                >{{ item.unitCost }}</el-col
              >
              <el-col :span="2">
                <img
                  src="../../assets/img/tcm/daikuanjisuanqi.png"
                  v-show="item.calculateData !== ''"
                  style="width: 22px; margin-top: -4px; cursor: pointer"
                  @click="openCal(item, 'unitCost')"
                />
              </el-col>
            </el-row>
            <el-row v-show="showType == 'changePriceDetail'">
              <el-col :span="11">
                <div class="size-14 padding-9-0 font-bold">
                  {{ $t("changePriceLists.changePriceList.costUoM") }}
                  ({{
                    $t("changePriceLists.changePriceList.beforeChangePrice")
                  }})
                </div>
              </el-col>
              <el-col
                :span="11"
                class="size-14 padding-9-0"
                :style="
                  item.unitCost_Before != item.unitCost &&
                  showType == 'changePriceDetail'
                    ? 'color: red'
                    : ''
                "
                >{{ item.unitCost_Before }}</el-col
              >
            </el-row>
            <el-row>
              <el-col :span="11">
                <div class="size-14 padding-9-0 font-bold">
                  {{ $t("changePriceLists.changePriceList.subTotalCost") }}
                </div>
              </el-col>
              <el-col :span="13" class="size-14 padding-9-0">{{
                item.totalCost
              }}</el-col>
            </el-row>
            <el-row>
              <el-col :span="11">
                <div class="size-14 padding-9-0 font-bold">
                  {{ $t("changePriceLists.changePriceList.priceReference") }}
                </div>
              </el-col>
              <el-col :span="13" class="size-14 padding-9-0">
                {{ item.price }}
                <!-- 改价调整 不使用 -->
                <!-- <el-icon
                  class="Modify-Price"
                  :size="20"
                  v-if="item.completeApplyId"
                  @click="ChangePriceBtn(item.completeApplyId)"
                  style="color: var(--themeColor)"
                  ><Edit
                /></el-icon> -->
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="tcm-content-title mgt-10">
          {{ $t("changePriceLists.changePriceList.processing") }}
        </div>
        <div class="tcm-content-form-all dis-wrap">
          <div
            class="width-preview"
            v-for="(item, index) in previewData.processLine"
          >
            <div class="form-type-title">
              {{ $t("changePriceLists.changePriceList.setUp") }} #
              {{ index + 1 }}
            </div>
            <el-row>
              <el-col :span="11">
                <div class="size-14 padding-9-0 font-bold">
                  {{ $t("changePriceLists.changePriceList.annualVolume") }}
                </div>
              </el-col>
              <el-col :span="13" class="size-14 padding-9-0">{{
                item.annualVolume
              }}</el-col>
            </el-row>
            <el-row>
              <el-col :span="11">
                <div class="size-14 padding-9-0 font-bold">
                  {{ $t("changePriceLists.changePriceList.setUpTime") }}
                </div>
              </el-col>
              <el-col :span="13" class="size-14 padding-9-0">{{
                item.setTime
              }}</el-col>
            </el-row>
            <el-row>
              <el-col :span="11">
                <div class="size-14 padding-9-0 font-bold">
                  {{ $t("changePriceLists.changePriceList.setUpPersons") }}
                </div>
              </el-col>
              <el-col :span="13" class="size-14 padding-9-0">{{
                item.setPerson
              }}</el-col>
            </el-row>
            <el-row>
              <el-col :span="11">
                <div class="size-14 padding-9-0 font-bold">
                  {{ $t("changePriceLists.changePriceList.piecesPerLot") }}
                </div>
              </el-col>
              <el-col :span="13" class="size-14 padding-9-0">{{
                item.pieceLot
              }}</el-col>
            </el-row>
            <el-row>
              <el-col :span="11">
                <div class="size-14 padding-9-0 font-bold">
                  {{ $t("changePriceLists.changePriceList.setUpCosts") }}
                </div>
              </el-col>
              <el-col :span="13" class="size-14 padding-9-0">{{
                item.pieceCost
              }}</el-col>
            </el-row>
            <div class="form-type-title">
              {{ $t("changePriceLists.changePriceList.operation") }}
              # {{ index + 1 }}
            </div>
            <el-row>
              <el-col :span="11">
                <div class="size-14 padding-9-0 font-bold">
                  {{
                    $t("changePriceLists.changePriceList.operationDescription")
                  }}
                </div>
              </el-col>
              <el-col :span="13" class="size-14 padding-9-0">{{
                item.name
              }}</el-col>
            </el-row>
            <el-row>
              <el-col :span="11">
                <div class="size-14 padding-9-0 font-bold">
                  {{ $t("changePriceLists.changePriceList.vendor") }}
                </div>
              </el-col>
              <el-col :span="13" class="size-14 padding-9-0">{{
                item.vendor
              }}</el-col>
            </el-row>
            <el-row>
              <el-col :span="11">
                <div class="size-14 padding-9-0 font-bold">
                  {{ $t("changePriceLists.changePriceList.descriptionSize") }}
                </div>
              </el-col>
              <el-col :span="11" class="size-14 padding-9-0">{{
                item.equSize
              }}</el-col>
              <el-col :span="2">
                <img
                  src="../../assets/img/tcm/daikuanjisuanqi.png"
                  v-show="item.equSizeCal"
                  style="width: 22px; margin-top: -4px; cursor: pointer"
                  @click="openCal(item, 'equSize')"
                />
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="11">
                <div class="size-14 padding-9-0 font-bold">
                  {{ $t("changePriceLists.changePriceList.consumption") }}
                </div>
              </el-col>
              <el-col :span="13" class="size-14 padding-9-0">{{
                item.quantity
              }}</el-col>
            </el-row>
            <el-row>
              <el-col :span="11">
                <div class="size-14 padding-9-0 font-bold">
                  {{ $t("changePriceLists.changePriceList.piecesHour") }}
                </div>
              </el-col>
              <el-col :span="11" class="size-14 padding-9-0">{{
                item.pieceHour
              }}</el-col>
              <el-col :span="2">
                <img
                  src="../../assets/img/tcm/daikuanjisuanqi.png"
                  v-show="item.pieceHourCal"
                  style="width: 22px; margin-top: -4px; cursor: pointer"
                  @click="openCal(item, 'pieceHour')"
                />
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="11">
                <div class="size-14 padding-9-0 font-bold">
                  {{ $t("changePriceLists.changePriceList.piecesPer") }}
                </div>
              </el-col>
              <el-col :span="13" class="size-14 padding-9-0">{{
                item.pieceCycle
              }}</el-col>
            </el-row>
            <el-row>
              <el-col :span="11">
                <div class="size-14 padding-9-0 font-bold">
                  {{
                    $t(
                      "changePriceLists.changePriceList.manufacturingEfficiency"
                    )
                  }}
                </div>
              </el-col>
              <el-col :span="13" class="size-14 padding-9-0">{{
                item.efficiencyPercent
              }}</el-col>
            </el-row>
            <el-row>
              <el-col :span="11">
                <div class="size-14 padding-9-0 font-bold">
                  {{ $t("changePriceLists.changePriceList.standardHour") }}
                </div>
              </el-col>
              <el-col :span="13" class="size-14 padding-9-0">{{
                item.standardHour
              }}</el-col>
            </el-row>
            <el-row>
              <el-col :span="11">
                <div class="size-14 padding-9-0 font-bold">
                  {{ $t("changePriceLists.changePriceList.directRate") }}
                </div>
              </el-col>
              <el-col :span="13" class="size-14 padding-9-0">{{
                item.directRate
              }}</el-col>
            </el-row>
            <el-row>
              <el-col :span="11">
                <div class="size-14 padding-9-0 font-bold">
                  {{ $t("changePriceLists.changePriceList.setUpRate") }}
                </div>
              </el-col>
              <el-col :span="13" class="size-14 padding-9-0">{{
                item.setUpRate
              }}</el-col>
            </el-row>
            <el-row>
              <el-col :span="11">
                <div class="size-14 padding-9-0 font-bold">
                  {{ $t("changePriceLists.changePriceList.directOperators") }}
                </div>
              </el-col>
              <el-col :span="13" class="size-14 padding-9-0">{{
                item.directOperateQty
              }}</el-col>
            </el-row>
            <el-row>
              <el-col :span="11">
                <div class="size-14 padding-9-0 font-bold">
                  {{ $t("changePriceLists.changePriceList.directAssembly") }}
                </div>
              </el-col>
              <el-col :span="13" class="size-14 padding-9-0">{{
                item.assemblyDirectCost
              }}</el-col>
            </el-row>
            <el-row>
              <el-col :span="11">
                <div class="size-14 padding-9-0 font-bold">
                  {{ $t("changePriceLists.changePriceList.burdenVariable") }}
                </div>
              </el-col>
              <el-col :span="13" class="size-14 padding-9-0">{{
                item.variableRate
              }}</el-col>
            </el-row>
            <el-row>
              <el-col :span="11">
                <div class="size-14 padding-9-0 font-bold">
                  {{ $t("changePriceLists.changePriceList.burdenRate") }}
                </div>
              </el-col>
              <el-col :span="13" class="size-14 padding-9-0">{{
                item.fixedRate
              }}</el-col>
            </el-row>
            <el-row>
              <el-col :span="11">
                <div class="size-14 padding-9-0 font-bold">
                  {{
                    $t("changePriceLists.changePriceList.burdenDepreciation")
                  }}
                </div>
              </el-col>
              <el-col :span="13" class="size-14 padding-9-0">{{
                item.depreciationRate
              }}</el-col>
            </el-row>
            <el-row>
              <el-col :span="11">
                <div class="size-14 padding-9-0 font-bold">
                  {{ $t("changePriceLists.changePriceList.totalBurdenRate") }}
                </div>
              </el-col>
              <el-col :span="13" class="size-14 padding-9-0">{{
                item.totalBurdenRate
              }}</el-col>
            </el-row>
            <el-row>
              <el-col :span="11">
                <div class="size-14 padding-9-0 font-bold">
                  {{ $t("changePriceLists.changePriceList.burdenCost") }}
                </div>
              </el-col>
              <el-col :span="13" class="size-14 padding-9-0">{{
                item.assemblyBurdenCost
              }}</el-col>
            </el-row>
            <el-row>
              <el-col :span="11">
                <div class="size-14 padding-9-0 font-bold">
                  {{ $t("changePriceLists.changePriceList.subTotalCost") }}
                </div>
              </el-col>
              <el-col :span="13" class="size-14 padding-9-0">{{
                item.totalCost
              }}</el-col>
            </el-row>
            <el-row>
              <el-col :span="11">
                <div class="size-14 padding-9-0 font-bold">
                  {{ $t("changePriceLists.changePriceList.processScrap") }}
                </div>
              </el-col>
              <el-col :span="13" class="size-14 padding-9-0">{{
                item.scrapPercent
              }}</el-col>
            </el-row>
          </div>
        </div>
        <div class="tcm-content-title mgt-10">
          {{ $t("changePriceLists.changePriceList.dutiesCustoms") }}
        </div>
        <div class="tcm-content-form-all" style="padding: 20px 20px 40px 20px">
          <el-form
            :model="previewData"
            label-width="340px"
            class="custom-el-form-label-bold"
            label-position="left"
          >
            <el-row>
              <el-col :span="10">
                <el-form-item
                  :label="$t('changePriceLists.changePriceList.freightCost')"
                >
                  {{ previewData.freightCost }}
                  {{ previewData.calculationMethod_Freight == 2 ? "%" : "" }}
                </el-form-item>
              </el-col>
              <el-col :span="2">
                <span style="margin-left: 10px; color: gray; line-height: 32px">
                  {{
                    previewData.calculationMethod_Freight == 2 &&
                    previewData.manufacturingCost_PRO !== "" &&
                    previewData.freightCost !== "" &&
                    previewData.manufacturingCost_PRO !== null &&
                    previewData.freightCost
                      ? (
                          (previewData.freightCost / 100) *
                          previewData.manufacturingCost_PRO
                        ).toFixed(3)
                      : ""
                  }}
                </span>
              </el-col>
              <el-col :span="10">
                <el-form-item
                  :label="$t('changePriceLists.changePriceList.wareCost')"
                >
                  {{ previewData.wareHouseCost }}
                  {{ previewData.calculationMethod_Ware == 2 ? "%" : "" }}
                </el-form-item>
              </el-col>
              <el-col :span="2">
                <span style="margin-left: 10px; color: gray; line-height: 32px">
                  {{
                    previewData.calculationMethod_Freight == 2 &&
                    previewData.manufacturingCost_PRO !== "" &&
                    previewData.wareHouseCost !== "" &&
                    previewData.manufacturingCost_PRO !== null &&
                    previewData.wareHouseCost
                      ? (
                          (previewData.wareHouseCost / 100) *
                          previewData.manufacturingCost_PRO
                        ).toFixed(3)
                      : ""
                  }}
                </span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="10">
                <el-form-item
                  :label="$t('changePriceLists.changePriceList.packagingCost')"
                >
                  {{ previewData.packageCost }}
                  {{ previewData.calculationMethod_Package == 2 ? "%" : "" }}
                </el-form-item>
              </el-col>
              <el-col :span="2">
                <span style="margin-left: 10px; color: gray; line-height: 32px">
                  {{
                    previewData.calculationMethod_Freight == 2 &&
                    previewData.manufacturingCost_PRO !== "" &&
                    previewData.packageCost !== "" &&
                    previewData.manufacturingCost_PRO !== null &&
                    previewData.packageCost
                      ? (
                          (previewData.packageCost / 100) *
                          previewData.manufacturingCost_PRO
                        ).toFixed(3)
                      : ""
                  }}
                </span>
              </el-col>
              <el-col :span="10">
                <el-form-item
                  :label="$t('changePriceLists.changePriceList.taxesFees')"
                >
                  {{ previewData.otherCost }}
                  {{ previewData.calculationMethod_Taxes == 2 ? "%" : "" }}
                </el-form-item>
              </el-col>
              <el-col :span="2">
                <span style="margin-left: 10px; color: gray; line-height: 32px">
                  {{
                    previewData.otherCost == 2 &&
                    previewData.manufacturingCost_PRO !== "" &&
                    previewData.otherCost !== "" &&
                    previewData.manufacturingCost_PRO !== null &&
                    previewData.otherCost
                      ? (
                          (previewData.otherCost / 100) *
                          previewData.manufacturingCost_PRO
                        ).toFixed(3)
                      : ""
                  }}
                </span>
              </el-col>
            </el-row>
            <!-- </el-form>
				    <el-form :model="previewData" label-width="500px" class="custom-el-form-label-bold" label-position="left"> -->
            <!-- <el-row>
				            <el-col>
				                <el-form-item :label="
				                        $t('changePriceLists.changePriceList.taxesFees')
				                    ">
				                    <el-input v-model="previewData.otherCost" disabled style="width: 214px" />
				                </el-form-item>
				            </el-col>
				        </el-row> -->
            <el-row>
              <el-col :span="12">
                <el-form-item
                  :label="
                    $t('changePriceLists.changePriceList.addedDescription')
                  "
                >
                  {{ previewData.amortizationDescription }}
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  :label="$t('changePriceLists.changePriceList.totalAddedCost')"
                >
                  {{ previewData.totalPieces }}
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item
                  :label="
                    $t('changePriceLists.changePriceList.totalPiecesAdded')
                  "
                >
                  {{ previewData.totalAmortize }}
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  :label="$t('changePriceLists.changePriceList.addedCost')"
                >
                  {{ previewData.amortizeCost }}
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div class="tcm-content-title mgt-10">
          {{ $t("changePriceLists.changePriceList.sgaProfitScrap") }}
        </div>
        <div class="tcm-content-form-all">
          <el-form
            :model="previewData"
            label-width="230px"
            class="custom-el-form-label-bold"
            label-position="left"
          >
            <div class="form-type-title">
              {{ $t("changePriceLists.changePriceList.sgas") }}
            </div>
            <el-row>
              <el-col :span="8">
                <el-form-item
                  :label="$t('changePriceLists.changePriceList.sga')"
                >
                  {{ previewData.sga }}
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  :label="$t('changePriceLists.changePriceList.sgaCost')"
                >
                  {{ previewData.sgaCost }}
                </el-form-item>
              </el-col>
            </el-row>
            <div class="form-type-title">
              {{ $t("changePriceLists.changePriceList.profit") }}
            </div>
            <el-row>
              <el-col :span="8">
                <el-form-item
                  :label="$t('changePriceLists.changePriceList.profitMaterial')"
                >
                  {{ previewData.materialProfit }}
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  :label="
                    $t('changePriceLists.changePriceList.profitComponents')
                  "
                >
                  {{ previewData.addProfit }}
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  :label="
                    $t('changePriceLists.changePriceList.totalprofitCost')
                  "
                >
                  {{ previewData.totalProfitCost }}
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item
                  :label="$t('changePriceLists.changePriceList.profitValue')"
                >
                  {{ previewData.purchaseProfit }}
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  :label="$t('changePriceLists.changePriceList.totalProfit')"
                >
                  {{ previewData.totalProfit }}
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item
                  :label="$t('changePriceLists.changePriceList.materialScrap')"
                >
                  {{ previewData.materialPC }}
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div class="tcm-content-title mgt-10">
          {{ $t("changePriceLists.changePriceList.costSummary") }}
        </div>
        <div class="tcm-content-form-all">
          <el-form
            :model="previewData"
            label-width="310px"
            class="custom-el-form-label-bold"
            label-position="left"
          >
            <el-row>
              <el-col :span="10">
                <el-form-item
                  :label="
                    $t('changePriceLists.changePriceList.rawMaterialCost')
                  "
                >
                  {{ exchangeRateUnit }} {{ previewData.materialCost_SUM }}
                </el-form-item>
              </el-col>
              <el-col :span="2">
                <span style="color: red; line-height: 32px">{{
                  previewData.materialCost_SUM_After
                    ? previewData.materialCost_SUM_After
                    : ""
                }}</span>
              </el-col>
              <el-col :span="2">
                <span style="margin-left: 10px; color: gray; line-height: 32px">
                  {{
                    isNaN(materialCost_SUM_Compute)
                      ? ""
                      : materialCost_SUM_Compute + "%"
                  }}
                </span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="10">
                <el-form-item
                  :label="$t('changePriceLists.changePriceList.purchasedCost')"
                >
                  {{ exchangeRateUnit }} {{ previewData.purchaseCost_SUM }}
                </el-form-item>
              </el-col>
              <el-col :span="2">
                <span style="color: red; line-height: 32px">
                  {{
                    previewData.purchaseCost_SUM_After
                      ? previewData.purchaseCost_SUM_After
                      : ""
                  }}</span
                >
              </el-col>
              <el-col :span="2">
                <span style="margin-left: 10px; color: gray; line-height: 32px">
                  {{
                    isNaN(purchaseCost_SUM_Compute)
                      ? ""
                      : purchaseCost_SUM_Compute + "%"
                  }}
                </span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item
                  :label="
                    $t('changePriceLists.changePriceList.directLaborCost')
                  "
                >
                  {{ exchangeRateUnit }} {{ previewData.laborCost_SUM }}
                </el-form-item>
              </el-col>
              <el-col :span="2">
                <span style="margin-left: 10px; color: gray; line-height: 32px">
                  {{ isNaN(t1) ? "" : t1 + "%" }}
                </span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item
                  :label="$t('changePriceLists.changePriceList.burdenCost')"
                >
                  {{ exchangeRateUnit }} {{ previewData.burdenCost_SUM }}
                </el-form-item>
              </el-col>
              <el-col :span="2">
                <span style="margin-left: 10px; color: gray; line-height: 32px">
                  {{ isNaN(t2) ? "" : t2 + "%" }}
                </span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item
                  :label="$t('changePriceLists.changePriceList.setUpCost')"
                >
                  {{ exchangeRateUnit }} {{ previewData.setUpCost_SUM }}
                </el-form-item>
              </el-col>
              <el-col :span="2">
                <span style="margin-left: 10px; color: gray; line-height: 32px">
                  {{ isNaN(t3) ? "" : t3 + "%" }}
                </span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item
                  :label="
                    $t('changePriceLists.changePriceList.totalManufacturing')
                  "
                >
                  {{ exchangeRateUnit }} {{ previewData.manufacturingCost_PRO }}
                </el-form-item>
              </el-col>
              <el-col :span="2">
                <span style="margin-left: 10px; color: gray; line-height: 32px">
                  {{
                    (
                      parseFloat(
                        isNaN(materialCost_SUM_Compute)
                          ? 0
                          : materialCost_SUM_Compute
                      ) +
                      parseFloat(
                        isNaN(purchaseCost_SUM_Compute)
                          ? 0
                          : purchaseCost_SUM_Compute
                      ) +
                      parseFloat(isNaN(t1) ? 0 : t1) +
                      parseFloat(isNaN(t2) ? 0 : t2) +
                      parseFloat(isNaN(t3) ? 0 : t3)
                    ).toFixed(3)
                  }}%
                </span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item
                  :label="
                    $t('changePriceLists.changePriceList.packagingFreightCost')
                  "
                >
                  {{ exchangeRateUnit }} {{ previewData.packageCost_SUM }}
                </el-form-item>
              </el-col>
              <el-col :span="2">
                <span style="margin-left: 10px; color: gray; line-height: 32px">
                  {{
                    previewData.packageCost_SUM !== "" &&
                    previewData.totalPriceCurr_SUM !== "" &&
                    previewData.packageCost_SUM !== null &&
                    previewData.totalPriceCurr_SUM
                      ? (
                          (previewData.packageCost_SUM /
                            previewData.totalPriceCurr_SUM) *
                          100
                        ).toFixed(3) + "%"
                      : ""
                  }}
                </span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item
                  :label="
                    $t('changePriceLists.changePriceList.amortizationFees')
                  "
                >
                  {{ exchangeRateUnit }} {{ previewData.otherCost_SUM }}
                </el-form-item>
              </el-col>
              <el-col :span="2">
                <span style="margin-left: 10px; color: gray; line-height: 32px">
                  {{
                    previewData.otherCost_SUM !== "" &&
                    previewData.totalPriceCurr_SUM !== "" &&
                    previewData.otherCost_SUM !== null &&
                    previewData.totalPriceCurr_SUM
                      ? (
                          (previewData.otherCost_SUM /
                            previewData.totalPriceCurr_SUM) *
                          100
                        ).toFixed(3) + "%"
                      : ""
                  }}
                </span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item
                  :label="
                    $t('changePriceLists.changePriceList.amortizationCost')
                  "
                >
                  {{ exchangeRateUnit }} {{ previewData.amortizeCost_SUM }}
                </el-form-item>
              </el-col>
              <el-col :span="2">
                <span style="margin-left: 10px; color: gray; line-height: 32px">
                  {{
                    previewData.amortizeCost_SUM !== "" &&
                    previewData.totalPriceCurr_SUM !== "" &&
                    previewData.amortizeCost_SUM !== null &&
                    previewData.totalPriceCurr_SUM
                      ? (
                          (previewData.amortizeCost_SUM /
                            previewData.totalPriceCurr_SUM) *
                          100
                        ).toFixed(3) + "%"
                      : ""
                  }}
                </span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item
                  :label="
                    $t('changePriceLists.changePriceList.processScrapCost')
                  "
                >
                  {{ exchangeRateUnit }} {{ previewData.scrapCost_SUM }}
                </el-form-item>
              </el-col>
              <el-col :span="2">
                <span style="margin-left: 10px; color: gray; line-height: 32px">
                  {{
                    previewData.scrapCost_SUM !== "" &&
                    previewData.totalPriceCurr_SUM !== "" &&
                    previewData.scrapCost_SUM !== null &&
                    previewData.totalPriceCurr_SUM
                      ? (
                          (previewData.scrapCost_SUM /
                            previewData.totalPriceCurr_SUM) *
                          100
                        ).toFixed(3) + "%"
                      : ""
                  }}
                </span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item
                  :label="$t('changePriceLists.changePriceList.sgaAndProfit')"
                >
                  {{ exchangeRateUnit }} {{ previewData.sgaProfit_SUM }}
                </el-form-item>
              </el-col>
              <el-col :span="2">
                <span style="margin-left: 10px; color: gray; line-height: 32px">
                  {{
                    previewData.sgaProfit_SUM !== "" &&
                    previewData.totalPriceCurr_SUM !== "" &&
                    previewData.sgaProfit_SUM !== null &&
                    previewData.totalPriceCurr_SUM
                      ? (
                          (previewData.sgaProfit_SUM /
                            previewData.totalPriceCurr_SUM) *
                          100
                        ).toFixed(3) + "%"
                      : ""
                  }}
                </span>
              </el-col>
            </el-row>
            <!-- 改价之前详情 -->
            <el-row v-show="showType != 'changePriceDetail'">
              <el-col :span="16">
                <el-form-item
                  :label="$t('changePriceLists.changePriceList.totalSellPrice')"
                >
                  {{ exchangeRateUnit }}
                  <span
                    :style="
                      previewData.totalPriceCurr_SUM_Before !=
                        previewData.totalPriceCurr_SUM &&
                      showType == 'changePriceDetail'
                        ? 'color: red'
                        : ''
                    "
                  >
                    {{
                      parseFloat(
                        previewData.totalPriceCurr_SUM === "" ||
                          previewData.totalPriceCurr_SUM === null
                          ? 0
                          : previewData.totalPriceCurr_SUM
                      ).toFixed(3)
                    }}
                  </span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-show="showType != 'changePriceDetail'">
              <el-col :span="16">
                <el-form-item
                  :label="
                    $t('changePriceLists.changePriceList.totalSellCurrency')
                  "
                >
                  {{ reportExchangeRateUnit }}
                  <span
                    :style="
                      previewData.totalPriceCurr_SUM_Before !=
                        previewData.totalPriceCurr_SUM &&
                      showType == 'changePriceDetail'
                        ? 'color: red'
                        : ''
                    "
                  >
                    {{
                      parseFloat(
                        previewData.totalPriceReport_SUM === "" ||
                          previewData.totalPriceReport_SUM === null
                          ? 0
                          : previewData.totalPriceReport_SUM
                      ).toFixed(3)
                    }}
                  </span>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- 改价后 before为改价后的数据 -->
            <el-row v-show="showType == 'changePriceDetail'">
              <el-col :span="16">
                <el-form-item
                  :label="$t('changePriceLists.changePriceList.totalSellPrice')"
                >
                  {{ exchangeRateUnit }}
                  <span
                    :style="
                      previewData.totalPriceCurr_SUM_Before !=
                        previewData.totalPriceCurr_SUM &&
                      showType == 'changePriceDetail'
                        ? 'color: red'
                        : ''
                    "
                  >
                    {{
                      parseFloat(
                        previewData.totalPriceCurr_SUM_Before === "" ||
                          previewData.totalPriceCurr_SUM_Before === null
                          ? 0
                          : previewData.totalPriceCurr_SUM_Before
                      ).toFixed(3)
                    }}
                  </span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-show="showType == 'changePriceDetail'">
              <el-col :span="16">
                <el-form-item
                  :label="
                    $t('changePriceLists.changePriceList.totalSellPriceBefore')
                  "
                >
                  {{ exchangeRateUnit }}
                  <span
                    :style="
                      previewData.totalPriceCurr_SUM_Before !=
                        previewData.totalPriceCurr_SUM &&
                      showType == 'changePriceDetail'
                        ? 'color: red'
                        : ''
                    "
                  >
                    {{
                      parseFloat(
                        previewData.totalPriceCurr_SUM === "" ||
                          previewData.totalPriceCurr_SUM === null
                          ? 0
                          : previewData.totalPriceCurr_SUM
                      ).toFixed(3)
                    }}
                  </span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-show="showType == 'changePriceDetail'">
              <el-col :span="16">
                <el-form-item
                  :label="
                    $t('changePriceLists.changePriceList.totalSellCurrency')
                  "
                >
                  {{ reportExchangeRateUnit }}
                  <span
                    :style="
                      previewData.totalPriceReport_SUM_Before !=
                        previewData.totalPriceReport_SUM &&
                      showType == 'changePriceDetail'
                        ? 'color: red'
                        : ''
                    "
                  >
                    {{
                      parseFloat(
                        previewData.totalPriceReport_SUM_Before === "" ||
                          previewData.totalPriceReport_SUM_Before === null
                          ? 0
                          : previewData.totalPriceReport_SUM_Before
                      ).toFixed(3)
                    }}
                  </span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-show="showType == 'changePriceDetail'">
              <el-col :span="16">
                <el-form-item
                  :label="
                    $t(
                      'changePriceLists.changePriceList.totalSellCurrencyBefore'
                    )
                  "
                >
                  {{ reportExchangeRateUnit }}
                  <span
                    :style="
                      previewData.totalPriceCurr_SUM_Before !=
                        previewData.totalPriceCurr_SUM &&
                      showType == 'changePriceDetail'
                        ? 'color: red'
                        : ''
                    "
                  >
                    {{
                      parseFloat(
                        previewData.totalPriceReport_SUM === "" ||
                          previewData.totalPriceReport_SUM === null
                          ? 0
                          : previewData.totalPriceReport_SUM
                      ).toFixed(3)
                    }}
                  </span>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
    </div>

    <!-- 柱状图 -->
    <SumDia ref="sumDialog"></SumDia>
    <!-- 计算器弹窗 -->
    <CalculatorList
      ref="calList"
      :applyId="applyId"
      type="detail"
      @chooseRow="chooseRow"
      @submitPlastic="submitPlastic"
    ></CalculatorList>
    <!-- 历史记录 -->
    <AuditComments ref="comments"> </AuditComments>
    <ChangePrice ref="ChangePrice" @changePrice="ChangePrice"></ChangePrice>
  </div>
</template>
<script>
import SumDia from "./sumDia.vue";
import StepMenu from "@/components/stepMenu/index.vue";
import CalculatorList from "../processCalculatorManagement/calculatorListDia.vue";
import AuditComments from "../../components/comments/AuditComments.vue";
import * as echarts from "echarts";
import {
  getCategorys,
  getQuoteTypes,
  getRegions,
  GetOrgs,
  GetApplyDetail,
  GetSelCurrency,
  GetRate,
  loadCurrency,
  GetSummaryLine,
} from "../../assets/js/requestApi.js";
import commonUtil from "../../assets/js/commonUtil.js";
import htmlToPdf from "../../assets/js/htmlToPdf.js";
import "../../assets/css/custom-el-form-label-bold.css";
import ChangePrice from "../tcmTemplateManagement/ChangePrice.vue";
export default {
  components: {
    SumDia,
    CalculatorList,
    AuditComments,
    ChangePrice,
    StepMenu,
  },
  provide() {
    return {
      showHandleBtn: true,
    };
  },
  data() {
    return {
      chooseCurrent: "gen",
      timer: "",
      id: "",
      genForm: {},
      genList: {},
      unitOptions: [],
      previewData: "",
      exchangeRateUnit: "",
      reportExchangeRateUnit: "",
      typeOptions: [],
      regionOptions: [],
      CommodityOptions: [],
      urgencyOptions: [],
      currencyOptions: [],
      currencyReportOptions: [],
      // 转换货币
      reportCurrency: "",
      reportShow: false,
      calculationMethodOptions: [
        {
          value: 1,
          label: "Numerical value",
        },
        {
          value: 2,
          label: "Percentage",
        },
      ],
      calculationMethodOption: [
        {
          value: 1,
          label: "数值",
        },
        {
          value: 2,
          label: "百分比",
        },
      ],
      // 查看详情类型
      showType: "",
      attachmentList: [],
      crumbs: ["CBD Price Change", "CBD Price Change Detail"],
      ProTotal: "",
      PackagingTotal: "",
      OhTotal: "",

      // MenuData: [
      //   {
      //     key: "gen",
      //     lan: "General Information",
      //   },
      //   {
      //     key: "rm",
      //     lan: "Raw Material",
      //   },
      //   {
      //     key: "pur",
      //     lan: "Purchased Components&Outside Processing",
      //   },
      //   {
      //     key: "pro",
      //     lan: "Processing",
      //   },
      //   {
      //     key: "p&l",
      //     lan: "Packaging, Logistics, Amortization, Duties & Customs",
      //   },
      //   {
      //     key: "sga",
      //     lan: "OH",
      //   },
      //   {
      //     key: "sum",
      //     lan: "Cost Summary",
      //   },
      // ],
      echartsFlag: true,
      chartDom: null,
      TcmDataFalg: false,
    };
  },
  computed: {
    t1: function () {
      return this.previewData.laborCost_SUM !== "" &&
        this.previewData.totalPriceCurr_SUM !== "" &&
        this.previewData.laborCost_SUM !== null &&
        this.previewData.totalPriceCurr_SUM
        ? (
            (this.previewData.laborCost_SUM /
              this.previewData.totalPriceCurr_SUM) *
            100
          ).toFixed(3)
        : NaN;
    },
    t2: function () {
      return this.previewData.burdenCost_SUM !== "" &&
        this.previewData.totalPriceCurr_SUM !== "" &&
        this.previewData.burdenCost_SUM !== null &&
        this.previewData.totalPriceCurr_SUM
        ? (
            (this.previewData.burdenCost_SUM /
              this.previewData.totalPriceCurr_SUM) *
            100
          ).toFixed(3)
        : NaN;
    },
    t3: function () {
      return this.previewData.setUpCost_SUM !== "" &&
        this.previewData.totalPriceCurr_SUM !== "" &&
        this.previewData.setUpCost_SUM !== null &&
        this.previewData.totalPriceCurr_SUM
        ? (
            (this.previewData.setUpCost_SUM /
              this.previewData.totalPriceCurr_SUM) *
            100
          ).toFixed(3)
        : NaN;
    },
    materialCost_SUM_Compute: function () {
      return this.previewData.materialCost_SUM !== "" &&
        this.previewData.totalPriceCurr_SUM !== "" &&
        this.previewData.materialCost_SUM !== null &&
        this.previewData.totalPriceCurr_SUM
        ? (
            (this.previewData.materialCost_SUM /
              this.previewData.totalPriceCurr_SUM) *
            100
          ).toFixed(3)
        : NaN;
    },
    purchaseCost_SUM_Compute: function () {
      return this.previewData.purchaseCost_SUM !== "" &&
        this.previewData.totalPriceCurr_SUM !== "" &&
        this.previewData.purchaseCost_SUM !== null &&
        this.previewData.totalPriceCurr_SUM
        ? (
            (this.previewData.purchaseCost_SUM /
              this.previewData.totalPriceCurr_SUM) *
            100
          ).toFixed(3)
        : NaN;
    },
    MenuData() {
      return [
        {
          key: "gen",
          lan: this.$t("homePage.home.Gen"),
        },
        {
          key: "rm",
          lan: this.$t("homePage.home.Rm"),
        },
        {
          key: "pur",
          lan: this.$t("homePage.home.PUR"),
        },
        {
          key: "pro",
          lan: this.$t("homePage.home.PRO"),
        },
        {
          key: "p&l",
          lan: this.$t("homePage.home.Pl"),
        },
        {
          key: "sga",
          lan: this.$t("homePage.home.OH"),
        },
        {
          key: "sum",
          lan: this.$t("homePage.home.CostSummary"),
        },
      ];
    },
  },
  mounted() {
    this.getUnit();
    // this.getQuoteType();
    // this.getRegion();
    // this.getCommdity();
    // this.getCategorysOptions();
    this.getCurrencyOptions();
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getDetail();
    }
    if (this.$route.query.type) {
      this.showType = this.$route.query.type;
    }
    if (this.$route.query.crumbs) {
      this.crumbs = this.$route.query.crumbs;
    }
    this.getUnits();
    if (this.$route.query.flag == "tcmdata") {
      this.TcmDataFalg = true;
      this.chooseCurrent = "sumEcharts";
      this.GetSumLineData();
      // this.MenuData.splice(-1, 1);
      this.MenuData.unshift({
        key: "sumEcharts",
        lan: this.$t("homePage.home.CostSummary"),
      });
    }
  },
  beforeUnmount() {
    if (!this.chartDom) {
      return;
    }
    // window.removeEventListener('resize', this.__resizeHandler)
    this.chartDom.dispose();
    this.chartDom = null;
  },
  methods: {
    GetSumLineData() {
      const chart = echarts.init(document.getElementById("echartsbox"));
      GetSummaryLine({ id: this.id }, (response) => {
        if (response && response.code == 200) {
          let nodeData = response.result.nodes;

          const tempcost = nodeData[nodeData.length - 1];
          nodeData[nodeData.length - 1] = nodeData[nodeData.length - 2];
          nodeData[nodeData.length - 2] = tempcost;
          let nodeDataSum = nodeData[1].cost;
          const temp2 = nodeDataSum[nodeDataSum.length - 1];
          nodeDataSum[nodeDataSum.length - 1] =
            nodeDataSum[nodeDataSum.length - 2];
          nodeDataSum[nodeDataSum.length - 2] = temp2;
          this.ProTotal = nodeData[1].cost[2];
          this.PackagingTotal = nodeData[1].cost[3];
          this.OhTotal = nodeData[1].cost[4];
          let summaryName = response.result.summaryName;
          summaryName.splice(2, 0, "material", "Components");
          summaryName.splice(summaryName.length - 1, 1, "Logistics");
          const temp = summaryName[summaryName.length - 1];
          summaryName[summaryName.length - 1] =
            summaryName[summaryName.length - 2];
          summaryName[summaryName.length - 2] = temp;

          let seriesData = [];
          let placeholder = [];
          // let tempSum = response.result.purchaseCost_PUR;
          let tempSum = 0;
          nodeData.forEach((item, index) => {
            seriesData.push(item.cost[0]);
            if (index == 1) {
              seriesData.push(item.cost[0]);
              seriesData.push(item.cost[1]);
              tempSum = item.cost[0];
            }
          });
          seriesData.forEach((item, index) => {
            if (index > 2) {
              if (index > 3) tempSum = tempSum + seriesData[index - 1];
              placeholder.push(tempSum);
            } else {
              placeholder.push(0);
            }
          });
          let secondLine = nodeData[1].cost;
          var option = {
            xAxis: {
              type: "category",
              data: summaryName,
              axisLabel: {
                interval: 0, //横轴信息全部显示
                rotate: -20, //-15度角倾斜显示
                textStyle: {
                  fontSize: 9,
                },
              },
            },
            yAxis: {
              type: "value",
              // name: "单位：万元",
              nameTextStyle: {
                fontSize: 12,
                padding: [0, 0, 10, 0], //name文字位置 对应 上右下左
              },
              axisTick: {
                // 轴刻度
                show: false,
              },
            },
            series: [
              {
                name: "placeholder",
                type: "bar", //柱状图
                stack: "Search Engine",
                emphasis: {
                  //折线图的高亮状态。
                  focus: "series", //聚焦当前高亮的数据所在的系列的所有图形。
                },
                data: placeholder,
                itemStyle: {
                  color: "#00000000",
                },
                label: {
                  show: false,
                },
              },
              {
                name: "Engine",
                type: "bar",
                stack: "Search Engine",
                emphasis: {
                  focus: "series",
                },
                data: seriesData,
                label: {
                  show: true,
                  position: "top",
                  formatter: function (params) {
                    return params.value.toFixed(3);
                  },
                  textStyle: {
                    color: "#333", // 标签字体颜色
                    fontSize: 9, // 标签字体大小
                    fontStyle: "italic", // 标签字体斜体
                  },
                },

                // 使用 labelLayout 回调函数为第二个柱子单独设置标签位置
                labelLayout: function (params) {
                  // params 是一个包含当前标签信息的对象
                  var isSecondBar = params.dataIndex === 1; // 判断是否为第二个柱子
                  const barHeight = params.rect.height / 2;
                  if (isSecondBar) {
                    // 为第二个柱子设置特定的位置
                    return {
                      x: params.labelRect.x, // 标签位置的横坐标偏移量
                      y: params.labelRect.y + barHeight, // 标签位置的纵坐标偏移量
                    };
                  }
                },

                itemStyle: {
                  normal: {
                    //每个柱子的颜色即为colorList数组里的每一项，如果柱子数目多于colorList的长度，则柱子颜色循环使用该数组
                    color: function (params) {
                      var colorList = [
                        "#445581",
                        "#718ebf",
                        "#718ebf",
                        "#73c0de",
                        "#fc8452",
                        "#9a60b4",
                        "#ea7ccc",
                        "#F845F1",
                        "#AD46F3",
                        "#5045F6",
                        "#FF7811",
                        "#3ba272",
                        "#2ED1CB",
                        "#91cc75",
                        "#FF4343",
                        "#FCA146",
                        "#F6D54A",
                        "#45DBF7",
                        "#44AFF0",
                        "#c05050",
                        "#59678c",
                        "#c9ab00",
                        "#7eb00a",
                        "#6f5553",
                        "#c14089",
                        "#8d98b3",
                        "#97b552",
                        "#95706d",
                        "#dc69aa",
                        "#FE8463",
                        "#FAD860",
                        "#F3A43B",
                      ];
                      return colorList[params.dataIndex];
                    },
                  },
                },
              },
            ],
          };
          secondLine.forEach((item, index) => {
            if (index > 0) {
              option.series.push({
                name: "Engine",
                type: "bar",
                stack: "Search Engine",
                emphasis: {
                  focus: "series",
                },
                data: ["-", item],
                label: {
                  show: true,
                  position: "inside",
                  textStyle: {
                    color: "#333", // 标签字体颜色
                    fontSize: 9, // 标签字体大小
                    fontStyle: "italic", // 标签字体斜体
                  },
                },
                itemStyle: {
                  color: function (params) {
                    var colorlist = [
                      "#445581",
                      "#73c0de",
                      "#8d98b3",
                      "#ee6666",
                      "#fc8452",
                    ];
                    return colorlist[index];
                  },
                },
              });
            }
          });
          option && chart.setOption(option);
          this.chartDom = chart;
        }
      });
    },

    filtrationCurrency(id) {
      let Name;
      this.currencyOptions.map((item) => {
        if (item.currency == id) {
          Name = item.currencyName;
        }
      });
      return Name;
    },
    chooseMenu(val) {
      // if (val == "sumEcharts") {
      //   this.$nextTick(() => {
      //     console.log(this.chartDom, "this.chartDom");
      //     this.chartDom.resize();
      //   });
      // }
      // 假设你有一个函数来处理标签页的切换事件
      // 当标签页切换时，重置ECharts图表的尺寸
      this.chooseCurrent = val;
    },
    Goback() {
      this.$router.go(-1);
    },
    ChangePriceBtn(id) {
      this.$refs.ChangePrice.GetChangePriceId(id);
    },
    CommentPageChange() {},
    ShowComments() {
      this.$refs.comments.visible = true;
      this.$refs.comments.getList(this.id);
    },
    getUnits() {
      this.timer = setInterval(this.valChange, 1000);
    },
    valChange() {
      this.powers = JSON.parse(localStorage.getItem("translate"));
      if (this.powers == "en" || this.powers == null) {
        this.calculationMethodOptions = [
          {
            value: 1,
            label: "Numerical Value",
          },
          {
            value: 2,
            label: "Percentage",
          },
        ];
      } else {
        this.calculationMethodOptions = [
          {
            value: 1,
            label: "数值", //Numerical Value
          },
          {
            value: 2,
            label: "百分比", //Percentage
          },
        ];
      }
      clearInterval(this.timer);
    },
    // 获取单位选项
    getUnit() {
      getCategorys(
        {
          TypeID: "MeasureUnit",
          Keyword: "",
          page: 1,
          limit: 9999,
          key: "",
        },
        (response) => {
          if (response && response.code == 200) {
            this.unitOptions = response.data;
          }
        }
      );
    },
    // 获取报价类型选项
    getQuoteType() {
      getQuoteTypes(
        {
          Keyword: "",
          page: 1,
          isEnable: 1,
          limit: 9999,
          key: "",
        },
        (response) => {
          if (response && response.code == 200) {
            this.typeOptions = response.data;
          }
        }
      );
    },
    // 获取区域列表选项
    getRegion() {
      getRegions(
        {
          Keyword: "",
          isEnable: 1,
          page: 1,
          limit: 9999,
          key: "",
        },
        (response) => {
          if (response && response.code == 200) {
            this.regionOptions = response.data;
          }
        }
      );
    },
    // 获取商品分类数据
    getCommdity() {
      let _this = this;
      GetOrgs(
        {
          type: 0,
          versionType: 0,
          TypeCost: 0,
          isAll: 0,
        },
        (response) => {
          if (response && response.Code == 200) {
            this.CommodityOptions = commonUtil.transferList(response.Result);
          }
        }
      );
    },
    // 获取等级选项
    getCategorysOptions() {
      getCategorys(
        {
          TypeID: "ApplyUrgency",
          Keyword: "",
          page: 1,
          limit: 9999,
          key: "",
        },
        (response) => {
          if (response && response.code == 200) {
            this.urgencyOptions = response.data;
          }
        }
      );
    },
    // 查询税率表区域选项
    getCurrencyOptions() {
      loadCurrency(
        {
          IsOrderNumber: 0,
          Keyword: "",
          page: 1, //当前页
          limit: 99999, //每页个数
        },
        (response) => {
          if (response && response.code == 200) {
            this.currencyReportOptions = response.data;
          }
        }
      );
      GetSelCurrency(
        {
          CurrId: "",
          CurrCNYId: "",
          isEnable: 1,
        },
        (response) => {
          if (response && response.code == 200) {
            this.currencyOptions = response.data[0].exchangeList;
            // this.currencyReportOptions =
            //     response.data[0].exchangeCNYList;
            // this.$refs.gen.genCurrOptions[0] = {
            //     currencyOptions: this.currencyOptions,
            //     currencyReportOptions: this.currencyReportOptions,
            // };
          } else {
            commonUtil.showTipInfo(
              this.$t("userlists.userlist.inqueryTimeout")
            );
          }
        }
      );
    },
    getDetail() {
      let _this = this;
      let ids = [];
      ids.push(this.id);
      GetApplyDetail(ids, (response) => {
        if (response && response.code == 200) {
          let data = response.result[0];
          this.genForm.quoteTypeId = data.quoteTypeId;
          this.genForm.quoteTypeName = data.quoteTypeName;
          this.genForm.programName = data.programName;
          this.genForm.ecrno = data.ecrno;
          this.genForm.commodityName = data.commodityName;
          this.genForm.partDescription = data.partDescription;
          this.genForm.partNumber = data.partNumber;
          this.genForm.partRevision = data.partRevision;
          this.genForm.lotSize = data.lotSize;
          this.genForm.productionYear = data.productionYear;
          this.genForm.doDate = data.doDate;
          this.genForm.comment = data.comment;
          this.genForm.urgencyId = data.urgencyId;

          if (data.exchangeRate) {
            this.genList.Currency = data.exchangeRate.name.split("-")[0];
            this.genList.CurrencyId = data.exchangeRate.currency;
            this.genList.ReportCurrency = data.exchangeRate.name.split("-")[1];
            this.genList.rate = data.rate;
            this.genList.currencyRateId = data.exchangeRate.id;
            this.exchangeRateUnit = data.exchangeRate.currencySymbolName;
            this.reportExchangeRateUnit =
              data.exchangeRate.reportCurrencySymbolName;
          }

          this.genList.regionName = data.regionName;
          this.genList.supplierName = data.supplierName;
          this.genList.materialDescription = data.materialDescription;
          this.genList.peakVolume =
            !data.peakVolume || data.peakVolume === null
              ? ""
              : commonUtil.format_with_regex(data.peakVolume.toString());
          this.genList.lifetimeVolume =
            !data.lifetimeVolume || data.lifetimeVolume === null
              ? ""
              : commonUtil.format_with_regex(data.lifetimeVolume.toString());
          this.genList.pegPoint = data.pegPoint;
          // this.$refs.gen.genList[0].yearVolumeList =
          //     data.yearVolumeList;
          // this.$refs.gen.mailTable = data.mailList;
          let applicationList = [];
          let operateList = [];
          this.attachmentList = [];
          data.attachmentList.forEach(function (val, index) {
            if (val.applyType == 1) {
              operateList.push(val);
              _this.attachmentList.push(val.url);
            } else {
              applicationList.push(val);
            }
          });
          data.processList.forEach(function (val, index) {
            if (val.calculateData == "") {
              val.equSizeCal = false;
              val.pieceHourCal = false;
            } else {
              let calculateData = JSON.parse(val.calculateData);
              calculateData.forEach(function (calValue, calIndex) {
                if (calValue.operateName == "equSize") {
                  val.equSizeCal = true;
                }
                if (calValue.operateName == "pieceHour") {
                  val.pieceHourCal = true;
                }
              });
            }
          });
          //

          this.previewData = {
            id: this.id,
            documentNo: data.documentNo,
            materialCost_RM: data.materialCost_RM,
            materialLine: data.materialList,
            materialDelIds: data.materialDelIds,
            purchaseCost_PUR: data.purchaseCost_PUR,
            purchaseLine: data.purchaseList,
            purchaseDelIds: data.purchaseDelIds,
            laborCost_PRO: data.laborCost_PRO,
            setUpCost_PRO: data.setUpCost_PRO,
            burdenCost_PRO: data.burdenCost_PRO,
            manufacturingCost_PRO: data.manufacturingCost_PRO,
            processLine: data.processList,
            processDelIds: data.processDelIds,
            calculationMethod: data.calculationMethod,
            calculationMethod_Freight: data.calculationMethod_Freight,
            calculationMethod_Ware: data.calculationMethod_Ware,
            calculationMethod_Package: data.calculationMethod_Package,
            calculationMethod_Taxes: data.calculationMethod_Taxes,
            freightCost: data.freightCost,
            wareHouseCost: data.wareHouseCost,
            packageCost: data.packageCost,
            otherCost: data.otherCost,
            amortizationDescription: data.amortizationDescription,
            totalPieces: data.totalPieces,
            totalAmortize: data.totalAmortize,
            amortizeCost: data.amortizeCost,
            sga: data.sga,
            sgaCost: data.sgaCost,
            materialProfit: data.materialProfit,
            addProfit: data.addProfit,
            purchaseProfit: data.purchaseProfit,
            totalProfit: data.totalProfit,
            totalProfitCost: data.totalProfitCost,
            materialPC: data.materialPC,
            materialCost_SUM: data.materialCost_SUM,
            materialCost_SUM_After: data.materialCost_SUM_After,
            setUpCost_SUM: data.setUpCost_SUM,
            purchaseCost_SUM: data.purchaseCost_SUM,
            purchaseCost_SUM_After: data.purchaseCost_SUM_After,
            packageCost_SUM: data.packageCost_SUM,
            laborCost_SUM: data.laborCost_SUM,
            otherCost_SUM: data.otherCost_SUM,
            burdenCost_SUM: data.burdenCost_SUM,
            amortizeCost_SUM: data.amortizeCost_SUM,
            sgaProfit_SUM: data.sgaProfit_SUM,
            scrapCost_SUM: data.scrapCost_SUM,
            totalPriceCurr_SUM: data.totalPriceCurr_SUM,
            totalPriceReport_SUM: data.totalPriceReport_SUM,
            totalPriceCurr_SUM_Before: data.totalPriceCurr_SUM_Before,
            totalPriceReport_SUM_Before: data.totalPriceReport_SUM_Before,
            attachmentList: operateList,
          };
          this.previewData.manufacturingCost_PRO =
            Number(data.materialCost_SUM) +
            Number(data.purchaseCost_SUM) +
            Number(data.laborCost_SUM) +
            Number(data.burdenCost_SUM) +
            Number(data.setUpCost_SUM);
        }
      });
    },
    // 展示柱状图
    reportForm() {
      if (this.reportCurrency == "") {
        commonUtil.showTipInfo("请选择需要转换的货币！", "", "warning");
        return;
      }
      let currency = this.genList.CurrencyId;
      GetRate(
        {
          currency: currency,
          reportCurrency: this.reportCurrency,
        },
        (response) => {
          if (response && response.code == 200) {
            if (response.result == null) {
              commonUtil.showTipInfo(
                "当前数据无汇率，请重新选择！",
                "",
                "warning"
              );
            } else {
              let rate = response.result.exchangeRate;
              let reportData = {
                materialCost_SUM: (
                  parseFloat(this.previewData.materialCost_SUM) * rate
                ).toFixed(3),
                setUpCost_SUM: (
                  parseFloat(this.previewData.setUpCost_SUM) * rate
                ).toFixed(3),
                purchaseCost_SUM: (
                  parseFloat(this.previewData.purchaseCost_SUM) * rate
                ).toFixed(3),
                packageCost_SUM: (
                  parseFloat(this.previewData.packageCost_SUM) * rate
                ).toFixed(3),
                laborCost_SUM: (
                  parseFloat(this.previewData.laborCost_SUM) * rate
                ).toFixed(3),
                burdenCost_SUM: (
                  parseFloat(this.previewData.burdenCost_SUM) * rate
                ).toFixed(3),
                amortizeCost_SUM: (
                  parseFloat(this.previewData.amortizeCost_SUM) * rate
                ).toFixed(3),
                sgaProfit_SUM: (
                  parseFloat(this.previewData.sgaProfit_SUM) * rate
                ).toFixed(3),
                scrapCost_SUM: (
                  parseFloat(this.previewData.scrapCost_SUM) * rate
                ).toFixed(3),
                totalPriceCurr_SUM: (
                  parseFloat(this.previewData.totalPriceCurr_SUM) * rate
                ).toFixed(3),
                totalPriceReport_SUM: (
                  parseFloat(this.previewData.totalPriceReport_SUM) * rate
                ).toFixed(3),
              };
              this.$refs.sumDialog.drawChart(reportData);
            }
          }
        }
      );
    },
    // 导出pdf
    toExport() {
      this.reportShow = true;
      setTimeout(() => {
        htmlToPdf.downloadPDF(
          document.querySelector("#exportPdf"),
          this.previewData.documentNo
        );
      }, 2000);
      setTimeout(() => {
        this.reportShow = false;
      }, 4000);
    },
    // 打开计算器详情页
    openCal(item, name) {
      let _this = this;
      this.$refs.calList.operateName = name;
      let form = item.calculateData ? JSON.parse(item.calculateData) : [];
      this.$refs.calList.getTreeData();
      this.$refs.calList.visible = true;
      if (form.length > 0) {
        this.$refs.calList.getlist(1);
        form.forEach(function (val, index) {
          if (val.operateName == name) {
            _this.$refs.calList.setData(val);
          }
        });
      } else {
        this.$refs.calList.getlist(2);
        this.$refs.calList.activeName = "first";
        this.$refs.calList.activeRadio = "tonnage";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  padding-top: 95px;
  width: 100%;
  min-height: 100%;
  background: #fff;
  border-radius: 20px;
}
.tcm-content-rawPic {
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  // background-color: #fff;
}
.card {
  margin-bottom: 10px;
  padding: 20px;
  width: 100%;
  background-color: #f0f0fd;
  border-radius: 10px;
}
.form-box {
  // width: 100%;
  margin: 0 1.6%;
  margin-top: 10px;
  background-color: #fff;
}

.el-form-item {
  margin-bottom: 0px !important;
}
.tcm-content {
  position: relative;
  padding: 20px 90px 20px 20px;
}
.tcm-content-title {
  background-color: #f2f2f2;
  border-radius: 5px;
  padding: 20px 20px 20px 50px;
  font-size: 16px;
  font-weight: bold;
  position: relative;
}
// .tcm-content-rawPic {
//   position: absolute;
//   top: 10px;
//   left: 180px;
// }
.tcm-content-title:before {
  content: "";
  display: table;
  width: 6px;
  height: 6px;
  background: #000;
  border-radius: 50%;
  position: absolute;
  left: 30px;
  top: 27px;
}
.tcm-content-form {
  width: 100%;
  /* margin-left: 50px; */
  /* background-color: #f2f2f2;
	  border-radius: 5px;
	  margin-top: 10px;
	  padding: 20px; */
  box-sizing: border-box;
  color: #606266;
  /* overflow-y: auto; */
}
.tcm-content-form-all {
  width: 100%;
  /* margin-left: 50px; */
  // background-color: #f2f2f2;
  border-radius: 5px;
  padding: 20px;
  box-sizing: border-box;
  color: #606266;
  /* overflow-y: auto; */
}
.tcm-content-form tr {
  display: flex;
  flex-wrap: nowrap;
}
.tcm-content-form td {
  width: 150px;
}
.mgt-10 {
  margin-top: 10px;
}
.mgr-10 {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.form-type-title {
  width: 100px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  background: #b2bde7;
  background-color: #f0f0fd;

  line-height: 35px;
  border-radius: 5px;
  padding: 0 10px;
  margin-bottom: 10px;
}
.btn-cla {
  width: 50%;
  margin: 20px auto;
  justify-content: space-around;
  display: flex;
}
.report-cur {
  display: flex;
  /* top: 55px; */
  font-weight: normal;
  z-index: 10;
}
.export-btn {
  text-align: right;
  padding-right: 110px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.size-14 {
  font-size: 14px;
  position: relative;
}
.font-bold {
  font-weight: bold;
}
.el-row {
  margin-bottom: 10px;
}
.el-row:last-child {
  margin-bottom: 0;
}
.color-red {
  color: red;
  position: absolute;
  left: -8px;
}
.width-preview {
  width: 30%;
  color: #606266;
  margin: 0 1.6%;
  margin-top: 10px;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 5px;
  background: #fff;
}
.dis-wrap {
  display: flex;
  flex-wrap: wrap;
}
.Modify-Price {
  font-size: 12px;
  color: #409eff;
  cursor: pointer;
}

// -----------------------

.top-box {
  position: fixed;
  width: calc(100% - 260px);
  top: 70px;
  left: 235px;
  z-index: 99;
  // background-color: #fff;
}
.handle-box {
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: #fff;
  .handle-box-item {
    padding-right: 20px;
    display: flex;
    align-items: center;
  }
}
.step-menubox {
  width: 100%;
  background: #fff;
}
.crumbs-box {
  padding-left: 20px;
  width: 100%;
  display: flex;
  background-color: #f2f2f2;
  // border-bottom: 1px solid #e4e7ed;
  align-items: center;
  height: 30px;
  font-size: 16px;
  color: #3e4954;
  .crumbs-box-item {
    display: flex;
    align-items: center;
  }
  .el-icon {
    margin: 0 10px;
  }
  .parent {
    color: #2f4cdd;
    cursor: pointer;
  }
  .line {
    margin: 0 15px;
    width: 2px;
    height: 15px;
    background: #3e4954;
  }
}
.handle-box {
  display: flex;
}
.content {
  #echartsbox {
    margin-top: 10px;
    width: 720px;
    height: 400px;
    background: #fff;
  }
  padding: 20px;
  display: flex;
  background: #fff;
  min-height: calc(100vh - 115px);
  .content-right {
    flex: 1;
    width: 100%;
  }
  .content-left {
    width: 750px;
    margin-right: 20px;
  }
  .card-item {
    padding: 15px;
    width: 100%;
    // height: 400px;
    background: #f0f0fd;
    border-radius: 1%;
    margin-bottom: 20px;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
  }
  .card-item-title {
    display: flex;
    justify-content: space-between;
    span {
      color: #718ebf;
      font-size: 22px;
    }
  }
  .General-item {
    // flex: 1;
    font-size: 18px;
    padding-top: 10px;
    color: #000;
    font-weight: 600;
    // display: flex;
    // justify-content: space-between;
    select {
      background-color: transparent;
      border: none;
      outline: none;
      font-size: 17px;
      font-weight: 600;
    }
    span {
      font-size: 16px;
      // white-space: nowrap;
      padding-right: 20px;
      color: #3e4954;
    }
  }
}
.mar-left {
  font-weight: 600px !important;
  font-size: 16px;
  color: #5470c6 !important;
}
.card-item-total {
  color: #333 !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.raw-box {
  height: 140px;
  overflow: hidden;
}
table {
  margin-top: 10px;
  width: 100%;
  tr {
    height: 30px;
    display: flex;
  }
  td {
    flex: 1;
  }

  font-size: 14px;
  .table-header {
    font-size: 16px;
    font-weight: 600;
  }
}
.more-txt {
  font-size: 18px;
  text-align: center;
  cursor: pointer;
  color: #718ebf;
}
.text-around {
  display: flex;
  span {
    flex: 1;
  }
}
.item-span {
  padding-top: 14px;
  display: flex;
  span {
    flex: 1;
    font-size: 16px;
    font-weight: 600;
    color: #3e4954;
  }
}
</style>

<style  scoped>
.tcm-content {
  position: relative;
  padding: 20px 90px 20px 20px;
}
.tcm-content-title {
  background-color: #f2f2f2;
  border-radius: 5px;
  padding: 20px 20px 20px 50px;
  font-size: 16px;
  font-weight: bold;
  position: relative;
}
/* .tcm-content-rawPic {
  position: absolute;
  top: 10px;
  left: 180px;
} */
.tcm-content-title:before {
  content: "";
  display: table;
  width: 6px;
  height: 6px;
  background: #000;
  border-radius: 50%;
  position: absolute;
  left: 30px;
  top: 27px;
}
.tcm-content-form {
  width: 100%;
  /* margin-left: 50px; */
  /* background-color: #f2f2f2;
    border-radius: 5px;
    margin-top: 10px;
    padding: 20px; */
  box-sizing: border-box;
  color: #606266;
  /* overflow-y: auto; */
}
.tcm-content-form-all {
  width: 100%;
  /* margin-left: 50px; */
  /* // background-color: #f2f2f2; */
  border-radius: 5px;
  margin-top: 10px;
  padding: 20px;
  box-sizing: border-box;
  color: #606266;
  /* overflow-y: auto; */
}
.tcm-content-form tr {
  display: flex;
  flex-wrap: nowrap;
}
.tcm-content-form td {
  width: 150px;
}
.mgt-10 {
  margin-top: 10px;
}
.mgr-10 {
  margin-right: 10px;
}
.form-type-title {
  width: 100px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  background: #fff;
  line-height: 35px;
  border-radius: 5px;
  padding: 0 10px;
  margin-bottom: 10px;
}
.btn-cla {
  width: 50%;
  margin: 20px auto;
  justify-content: space-around;
  display: flex;
}
.report-cur {
  display: flex;
  /* line-height: 32px;
  position: absolute;
  right: 200px;
  top: 88px; */
  /* top: 55px; */
  font-weight: normal;
  z-index: 10;
}
.export-btn {
  text-align: right;
  padding-right: 110px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.size-14 {
  font-size: 14px;
  position: relative;
}
.font-bold {
  font-weight: bold;
}
.el-row {
  margin-bottom: 10px;
}
.el-row:last-child {
  margin-bottom: 0;
}
.width-preview {
  width: 30%;
  color: #606266;
  margin: 0 1.6%;
  margin-top: 10px;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 5px;
  /* background: #f2f2f2; */
}
.dis-wrap {
  display: flex;
  flex-wrap: wrap;
}
.Modify-Price {
  font-size: 12px;
  color: #409eff;
  cursor: pointer;
}
</style>