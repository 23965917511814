<template>
  <div>
    <div class="crumbs-box">
      <div class="crumbs-box-item parent" @click="Goback">
        {{ $t("homePage.home.Back") }}
      </div>
      <div class="line"></div>
      <div class="crumbs-box-item" v-for="(item, index) in crumbs">
        {{ item }}
        <el-icon v-show="index != crumbs.length - 1"><ArrowRight /></el-icon>
      </div>
    </div>
    <div class="main">
      <div class="tcm-content">
        <div class="tcm-content-title">{{ $t("ccls.ccl.NewCalculator") }}</div>
        <div class="tcm-content-form">
          <el-form :model="form" label-width="140px" :rules="rules">
            <el-form-item :label="$t('ccls.ccl.code')" v-show="type != 'add'">{{
              form.process_Calculator_Code
            }}</el-form-item>
            <el-form-item :label="$t('ccls.ccl.name')" prop="name">
              <el-input
                v-model="form.name"
                style="width: 214px"
                :disabled="type == 'detail'"
              />
            </el-form-item>
            <el-form-item :label="$t('ccls.ccl.commodity')" prop="version">
              <el-tree-select
                clearable
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                v-model="form.commodityId"
                :disabled="type == 'detail'"
                :data="CommodityOptions"
                :props="defaultProps"
                check-strictly
                :render-after-expand="false"
                :default-expand-all="true"
              />
            </el-form-item>
            <el-form-item
              :label="$t('ccls.ccl.version')"
              prop="version"
              v-show="type == 'detail'"
            >
              <el-input v-model="form.version" style="width: 214px" disabled />
            </el-form-item>
            <el-form-item :label="$t('ccls.ccl.comments')">
              <el-input
                v-model="form.comments"
                style="width: 214px"
                :disabled="type == 'detail'"
              />
            </el-form-item>
            <el-form-item :label="$t('ccls.ccl.pictures')">
              <el-row>
                <div class="file-cla" v-show="fileListx.length > 0">
                  <div
                    v-for="(item, index) in fileListx"
                    style="margin-right: 10px"
                  >
                    <div>
                      <img
                        src="../../assets/img/tcm/doc.png"
                        v-show="item.name.indexOf('.doc') > -1"
                        style="width: 30px; height: 30px"
                      />
                      <img
                        src="../../assets/img/tcm/tupian.png"
                        v-show="
                          item.name.indexOf('.png') > -1 ||
                          item.name.indexOf('.jpg') > -1 ||
                          item.name.indexOf('.jpeg') > -1
                        "
                        style="width: 30px; height: 30px"
                      />
                      <img
                        src="../../assets/img/tcm/pdf.png"
                        v-show="item.name.indexOf('.pdf') > -1"
                        style="width: 30px; height: 30px"
                      />
                      <img
                        src="../../assets/img/tcm/ppt.png"
                        v-show="item.name.indexOf('.ppt') > -1"
                        style="width: 30px; height: 30px"
                      />
                      <img
                        src="../../assets/img/tcm/txt.png"
                        v-show="item.name.indexOf('.txt') > -1"
                        style="width: 30px; height: 30px"
                      />
                      <img
                        src="../../assets/img/tcm/doc.png"
                        v-show="item.name.indexOf('.doc') > -1"
                        style="width: 30px; height: 30px"
                      />
                      <img
                        src="../../assets/img/tcm/xls.png"
                        v-show="
                          item.name.indexOf('.xls') > -1 ||
                          item.name.indexOf('.xlsx') > -1
                        "
                        style="width: 30px; height: 30px"
                      />
                    </div>
                    <div class="line-12">
                      <img
                        src="../../assets/img/tcm/cuowu.png"
                        style="
                          width: 12px;
                          height: 12px;
                          margin-right: 4px;
                          cursor: pointer;
                        "
                        @click="toDetele(index, 'file')"
                      />
                      <img
                        src="../../assets/img/tcm/yunxiazai.png"
                        style="width: 12px; height: 12px; cursor: pointer"
                        @click="downloadPicture(index)"
                      />
                    </div>
                  </div>
                </div>
                <el-col :span="2">
                  <el-upload
                    class="custom-el-upload-list-none"
                    style="display: inline-block"
                    action="/"
                    :auto-upload="false"
                    multiple
                    :limit="10"
                    :file-list="fileListx"
                    :on-change="filechange"
                    accept=".jpg,.jpeg,.png"
                    v-show="type != 'detail'"
                  >
                    <el-button size="small">{{
                      $t("ccls.ccl.upload")
                    }}</el-button>
                  </el-upload>
                </el-col>
              </el-row>
            </el-form-item>
          </el-form>
        </div>
        <div class="tcm-content-title dis-flex">
          {{ $t("ccls.ccl.input") }}
          <div class="add-cla" @click="addInput" v-show="type != 'detail'">
            <el-icon :size="18" style="color: var(--themeColor)">
              <CirclePlusFilled />
            </el-icon>
          </div>
        </div>
        <div class="tcm-content-form">
          <el-table
            :data="tableData"
            border
            style="width: 60%"
            v-show="type != 'detail'"
            :empty-text="$t('tcmlists.tcmlist.noData')"
          >
            <el-table-column
              prop="ParameterNumName"
              width="50"
              align="center"
            />
            <el-table-column
              prop="parameterName"
              :label="$t('ccls.ccl.name')"
              align="center"
            />
            <el-table-column
              prop="thedefaultvalue"
              :label="$t('ccls.ccl.defaultValue')"
              align="center"
            />
            <el-table-column
              prop="unit"
              :label="$t('ccls.ccl.unit')"
              align="center"
            />
            <el-table-column
              prop="formulaVal"
              :label="$t('ccls.ccl.formula')"
              align="center"
            />
            <el-table-column :label="$t('ccls.ccl.operate')" align="center">
              <template #default="scope">
                <img
                  src="../../assets/img/tcm/edit.svg"
                  style="
                    width: 16px;
                    height: 16px;
                    margin-right: 10px;
                    cursor: pointer;
                  "
                  @click="editInput(scope.row)"
                />
                <img
                  src="../../assets/img/tcm/cuowu.png"
                  style="width: 16px; height: 16px; cursor: pointer"
                  @click="toDetele(scope.row, 'input')"
                />
              </template>
            </el-table-column>
          </el-table>
          <el-table
            :data="tableData"
            border
            style="width: 60%"
            v-show="type == 'detail'"
            :empty-text="$t('tcmlists.tcmlist.noData')"
          >
            <el-table-column
              prop="ParameterNumName"
              width="50"
              align="center"
            />
            <el-table-column
              prop="parameterName"
              :label="$t('ccls.ccl.name')"
              align="center"
            />
            <el-table-column
              prop="thedefaultvalue"
              :label="$t('ccls.ccl.defaultValue')"
              align="center"
            />
            <el-table-column
              prop="unit"
              :label="$t('ccls.ccl.unit')"
              align="center"
            />
            <el-table-column
              prop="formulaVal"
              :label="$t('ccls.ccl.formula')"
              align="center"
            />
          </el-table>
        </div>
        <div class="tcm-content-title">{{ $t("ccls.ccl.output") }}</div>
        <div class="tcm-content-form">
          <el-form :model="outform" label-width="140px" :rules="outrules">
            <el-form-item :label="$t('ccls.ccl.name')" prop="name">
              <el-input
                v-model="outform.name"
                style="width: 214px"
                :disabled="type == 'detail'"
              />
            </el-form-item>
            <el-form-item :label="$t('ccls.ccl.unit')" prop="unit">
              <el-input
                v-model="outform.unit"
                style="width: 214px"
                :disabled="type == 'detail'"
              />
            </el-form-item>
            <el-form-item :label="$t('ccls.ccl.formula')" prop="formula">
              <div @click="showCalculator">
                <el-input
                  v-model="outform.formula"
                  type="textarea"
                  :rows="4"
                  resize="none"
                  :readonly="true"
                  style="width: 400px"
                  :disabled="type == 'detail'"
                />
              </div>
            </el-form-item>
            <el-form-item label="">
              <div class="dis-flex">
                <div class="operate-btnx mgr-20" @click="previewResult">
                  {{ $t("unifiedPrompts.unifiedPrompt.preview") }}
                </div>
                <div
                  class="operate-btnx mgr-20"
                  v-show="type != 'detail'"
                  @click="cancleCalculator"
                >
                  {{ $t("ccls.ccl.cancel") }}
                </div>
                <div
                  class="operate-btnx"
                  @click="confirmCalculator"
                  v-show="type != 'detail'"
                >
                  {{ $t("ccls.ccl.confirm") }}
                </div>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <NewParameter
          ref="parameterDia"
          :tableData="tableData"
          @showCalDia="showCalDia"
          @submitForm="submitForm"
        ></NewParameter>
        <CalculatorDia
          ref="calculatorDialog"
          :intermediateBondList="editIntermediateBondList"
          :valueList="editValueList"
          @submitFormula="submitFormula"
        ></CalculatorDia>
        <PreviewDia
          ref="previewDialog"
          type="preview"
          :formulaPre="outform.formulaPre"
          :previewResultx="previewResultx"
          :fileListUrl="previewImgList"
          :intermediateBondList="intermediateBondListPreview"
          :valueList="valueListPreview"
          :previewName="previewName"
          @tocompute="tocompute"
        ></PreviewDia>
      </div>
    </div>
  </div>
</template>

<script>
import NewParameter from "./newParameter.vue";
import CalculatorDia from "./calculatorDia.vue";
import PreviewDia from "./previewDia.vue";
import {
  GetOrgs,
  AddOrUpdate,
  CalculationFormulaResult,
  GetCalculatorDetail,
  uploadFile,
  InputParameterAnalysis,
} from "../../assets/js/requestApi.js";
import commonUtil from "../../assets/js/commonUtil.js";
export default {
  components: {
    NewParameter,
    CalculatorDia,
    PreviewDia,
  },
  data() {
    return {
      timer: "",
      form: {
        id: "",
        process_Calculator_Code: "",
        name: "",
        commodityId: "",
        commodityName: "",
        version: "",
        comments: "",
      },
      // 上传的文件
      fileListx: [],
      fileListUrl: [],
      // 表格内容
      tableData: [],
      outform: {
        name: "",
        unit: "",
        formula: "",
        formulaVal: "",
        formulaPre: "",
        outputFormulaArr: [],
        OutputFormulaNum: [],
      },
      type: "add",
      // 符号
      symbol: ["+", "-", "*", "/", "sum", "if", "(", ")", "Round", "Ceiling"],
      // 计算结果
      previewResultx: 0,
      // 打开计算器输入框类型
      calType: "input",
      // 中间键列表
      intermediateBondList: [],
      // 数值列表
      valueList: [],
      // 编辑数据时中间键列表
      editIntermediateBondList: [],
      // 编辑时数值列表
      editValueList: [],
      // 非空校验
      // rules: {},
      // outrules: {},
      // 图片上传后对应的列表
      pictureList: [],
      uploadStatus: false,
      // 商品分类选项
      CommodityOptions: [],
      // 商品分类显示字段
      defaultProps: {
        children: "children",
        label: "Name",
      },
      // 预览中间键数组
      valueListPreview: [],
      intermediateBondListPreview: [],
      previewName: "",
      // 大写字母数组
      capitalList: [],
      // 编辑中间键下标值
      editInputIndex: "",
      editValueIndex: "",
      editintermediateBondIndex: "",
      // 预览图片数组
      previewImgList: [],
      rules: {},
      outrules: {},
      crumbs: [],
    };
  },
  computed() {},
  mounted() {
    this.type = this.$route.query.type;
    if (this.$route.query.id) {
      this.form.id = this.$route.query.id;
      this.getDetail();
    }
    if (this.$route.query.CommodityId) {
      this.form.commodityId = this.$route.query.CommodityId;
      this.form.commodityName = this.$route.query.CommodityName;
    }
    // this.getCommdity();
    this.CommodityOptions = JSON.parse(sessionStorage.getItem("Commodity"));
    this.capitalList = [];
    for (var i = 65; i < 91; i++) {
      this.capitalList.push(String.fromCharCode(i));
    }
    // this.getUnits();
    this.changeLan();
  },
  watch: {
    uploadStatus: {
      handler(nval, oval) {
        if (nval) {
          this.addCalculator();
        }
      },
      deep: true,
    },
    type: {
      immediate: true,
      handler(nval) {
        switch (nval) {
          case "add":
            this.crumbs = [
              this.$t("homePage.home.ProcessCalculator"),
              this.$t("unifiedPrompts.unifiedPrompt.new"),
            ];
            break;
          case "edit":
            this.crumbs = [
              this.$t("homePage.home.ProcessCalculator"),
              this.$t("unifiedPrompts.unifiedPrompt.edit"),
            ];
            break;
          case "detail":
            this.crumbs = [
              this.$t("homePage.home.ProcessCalculator"),
              this.$t("ccls.ccl.detail"),
            ];
            break;
        }
      },
    },
    "$i18n.locale"() {
      this.changeLan();
      switch (this.type) {
        case "add":
          this.crumbs = [
            this.$t("homePage.home.ProcessCalculator"),
            this.$t("unifiedPrompts.unifiedPrompt.new"),
          ];
          break;
        case "edit":
          this.crumbs = [
            this.$t("homePage.home.ProcessCalculator"),
            this.$t("unifiedPrompts.unifiedPrompt.edit"),
          ];
          break;
        case "detail":
          this.crumbs = [
            this.$t("homePage.home.ProcessCalculator"),
            this.$t("ccls.ccl.detail"),
          ];
          break;
      }
    },
  },
  methods: {
    changeLan() {
      this.rules = {
        name: [
          {
            required: true,
            message: this.$t("changePriceLists.changePriceList.versionName"),
            trigger: "blur",
          },
        ],
        version: [
          {
            required: true,
            message: this.$t(
              "changePriceLists.changePriceList.commodityRequired"
            ),
            trigger: "blur",
          },
        ],
        comments: [
          {
            required: true,
            message: this.$t(
              "changePriceLists.changePriceList.commentsRequired"
            ),
            trigger: "blur",
          },
        ],
      };
      this.outrules = {
        name: [
          {
            required: true,
            message: this.$t("changePriceLists.changePriceList.pleaseNames"),
            trigger: "blur",
          },
        ],
        formula: [
          {
            required: true,
            message: this.$t("changePriceLists.changePriceList.pleaseFormula"),
            trigger: "blur",
          },
        ],
      };
    },
    getUnits() {
      // this.timer = setInterval(this.valChange, 1000);
      // this.$refs.parameterDia.getUnits();
    },
    // 根据id获取详情
    getDetail() {
      let _this = this;
      GetCalculatorDetail('"' + this.form.id + '"', (response) => {
        if (response && response.code == 200) {
          this.form = {
            id: response.data[0].id,
            process_Calculator_Code: response.data[0].process_Calculator_Code,
            name: response.data[0].process_Calculator_Name,
            commodityId: response.data[0].commodityId,
            commodityName: response.data[0].commodList[0].name,
            version: response.data[0].version,
            comments: response.data[0].comments,
          };
          this.tableData = response.data[0].edit_List;
          this.tableData.forEach(function (val, key) {
            if (val.formulaPre) {
              // let formulaPre = []
              // JSON.parse(val.formulaNum).forEach(function(value, index) {
              // 	if (Array.isArray(value)) {
              // 		value.forEach(function(ifvalue, ifindex) {
              // 			formulaPre.push(ifvalue)
              // 		})
              // 	} else {
              // 		formulaPre.push(value)
              // 	}
              // })
              // val.formulaVal = formulaPre.join('').replace(/\|/g, '')
              // val.formulaVal = JSON.parse(val.formulaNum).join('').replace(/\|/g, '')
              val.formulaVal = _this.converseDataSpecial(
                JSON.parse(val.formulaNum)
              );
            } else {
              val.formulaVal = "";
            }
            val.FormulaNum = val.formulaNum ? JSON.parse(val.formulaNum) : [];
            (val.FormulaNumArr = val.formulaNumArr
              ? JSON.parse(val.formulaNumArr)
              : []),
              (val.ParameterNumName = val.parameterNumName);
            if (val.formula != "") {
              _this.intermediateBondList.push(val);
            } else {
              _this.valueList.push(val);
            }
          });
          this.editIntermediateBondList = JSON.parse(
            JSON.stringify(_this.intermediateBondList)
          );
          this.editValueList = JSON.parse(JSON.stringify(_this.valueList));
          // let formulaPre = []
          // JSON.parse(response.data[0].outputFormulaNum).forEach(function(value, index) {
          // 	if (Array.isArray(value)) {
          // 		value.forEach(function(ifvalue, ifindex) {
          // 			formulaPre.push(ifvalue)
          // 		})
          // 	} else {
          // 		formulaPre.push(value)
          // 	}
          // })
          this.outform = {
            name: response.data[0].outputName,
            unit: response.data[0].outputUnit,
            formula: _this.converseDataSpecial(
              JSON.parse(response.data[0].outputFormulaNum)
            ),
            // formula: formulaPre.join('').replace(/\|/g, ''),
            // formula: JSON.parse(response.data[0].outputFormulaNum).join('').replace(/\|/g, ''),
            formulaVal: response.data[0].outputFormula,
            formulaPre: response.data[0].outputFormulaPre,
            outputFormulaArr: JSON.parse(response.data[0].outputFormulaArr),
            OutputFormulaNum: JSON.parse(response.data[0].outputFormulaNum),
          };

          // this.outform.formula = formulaPre.join('').replace(/\|/g, '')
          this.fileListx = response.data[0].pictureList;
        }
      });
    },
    // 获取商品分类数据
    getCommdity() {
      let _this = this;
      GetOrgs(
        {
          type: 0,
          versionType: 0,
          TypeCost: 0,
          isAll: 0,
        },
        (response) => {
          if (response && response.Code == 200) {
            this.CommodityOptions = commonUtil.transferList(response.Result);
          }
        }
      );
    },
    // 文件上传
    filechange(file, fileList) {
      this.fileListx.push(file);
      var imgURL = URL.createObjectURL(file.raw);
      this.fileListUrl.push({
        url: imgURL,
        name: file.name,
      });
    },
    // 删除文件
    toDetele(index, type) {
      let _this = this;
      if (type == "input") {
        // 判断是否使用
        let isContain = false;
        this.tableData.forEach(function (val, ind) {
          if (
            val.formulaPre &&
            val.formulaPre.indexOf("|" + index.parameterName + "|") > -1
          ) {
            isContain = true;
          }
        });
        if (isContain) {
          commonUtil.showTipInfo("该值已被使用不可删除！", "", "warning");
          return;
        }
        if (index.formula == "") {
          this.valueList.splice(this.valueList.indexOf(index), 1);
        } else {
          this.intermediateBondList.splice(
            this.intermediateBondList.indexOf(index),
            1
          );
        }
        this.editIntermediateBondList = JSON.parse(
          JSON.stringify(this.intermediateBondList)
        );
        this.editValueList = JSON.parse(JSON.stringify(this.valueList));
        this.tableData.splice(this.tableData.indexOf(index), 1);
      } else if (type == "file") {
        this.fileListx.splice(index, 1);
        this.fileListUrl.splice(index, 1);
      }
    },
    // 新增数据
    addInput() {
      this.$refs.parameterDia.type = "add";
      this.$refs.parameterDia.visible = true;
    },
    // 编辑数据
    editInput(row) {
      this.editintermediateBondIndex = "";
      this.editValueIndex = "";
      this.editInputIndex = this.tableData.indexOf(row);
      if (
        row.formulaVal &&
        row.formulaVal != "" &&
        row.formulaVal != undefined
      ) {
        this.editintermediateBondIndex = this.intermediateBondList.indexOf(row);
        this.editIntermediateBondList.splice(this.editintermediateBondIndex, 1);
      } else {
        this.editValueIndex = this.valueList.indexOf(row);
        this.editValueList.splice(this.editValueIndex, 1);
      }
      this.$refs.parameterDia.type = "edit";
      this.$refs.parameterDia.relName = row.parameterName;
      this.$refs.parameterDia.inputform = {
        ParameterNumName: row.ParameterNumName,
        parameterName: row.parameterName,
        thedefaultvalue: row.thedefaultvalue,
        unit: row.unit,
        formula: row.formula,
        formulaVal: row.formulaVal,
        formulaPre: row.formulaPre,
        FormulaNumArr: row.FormulaNumArr,
        FormulaNum: row.FormulaNum,
      };
      this.$refs.parameterDia.visible = true;
    },
    // 显示计算器弹窗
    showCalculator() {
      this.calType = "output";
      this.$refs.calculatorDialog.value = "";
      this.$refs.calculatorDialog.intermediateBond = "";
      this.$refs.calculatorDialog.formula = this.outform.formula;
      this.$refs.calculatorDialog.formulaArr = JSON.parse(
        JSON.stringify(this.outform.outputFormulaArr)
      );
      this.$refs.calculatorDialog.formulaPronoun = JSON.parse(
        JSON.stringify(this.outform.OutputFormulaNum)
      );
      this.$refs.calculatorDialog.visible = true;
    },
    // 新增数据弹窗回调显示计算器弹窗
    showCalDia(val) {
      if (val == "true") {
        this.calType = "input";
        this.$refs.calculatorDialog.value = "";
        this.$refs.calculatorDialog.intermediateBond = "";
        this.$refs.calculatorDialog.formula =
          this.$refs.parameterDia.inputform.formulaVal;
        this.$refs.calculatorDialog.formulaArr = this.$refs.parameterDia
          .inputform.FormulaNumArr
          ? JSON.parse(
              JSON.stringify(this.$refs.parameterDia.inputform.FormulaNumArr)
            )
          : [];
        this.$refs.calculatorDialog.formulaPronoun = this.$refs.parameterDia
          .inputform.FormulaNum
          ? JSON.parse(
              JSON.stringify(this.$refs.parameterDia.inputform.FormulaNum)
            )
          : [];
        this.$refs.calculatorDialog.visible = true;
      }
    },
    // 新增数据弹窗提交
    submitForm(val, type) {
      let _this = this;
      let data = val;
      if (type == "add") {
        if (this.tableData.length > 25) {
          this.capitalList.forEach(function (val, index) {
            if (
              _this.tableData[_this.tableData.length - 1].ParameterNumName ==
              "A" + val
            ) {
              data.ParameterNumName = "A" + _this.capitalList[index + 1];
            } else if (
              _this.tableData[_this.tableData.length - 1].ParameterNumName ==
              "Z"
            ) {
              data.ParameterNumName = "A" + _this.capitalList[0];
            }
          });
        } else {
          if (this.tableData.length > 0) {
            this.capitalList.forEach(function (val, index) {
              if (
                _this.tableData[_this.tableData.length - 1].ParameterNumName ==
                val
              ) {
                data.ParameterNumName = _this.capitalList[index + 1];
              }
            });
          } else {
            data.ParameterNumName = this.capitalList[this.tableData.length];
          }
        }
        this.tableData.push(data);
        if (val.formulaVal == "") {
          this.valueList.push(data);
        } else {
          this.intermediateBondList.push(data);
        }
        this.editIntermediateBondList = JSON.parse(
          JSON.stringify(this.intermediateBondList)
        );
        this.editValueList = JSON.parse(JSON.stringify(this.valueList));
      } else {
        this.tableData[this.editInputIndex] = data;
        if (this.editintermediateBondIndex !== "") {
          this.intermediateBondList[this.editintermediateBondIndex] = data;
        }
        if (this.editValueIndex !== "") {
          this.valueList[this.editValueIndex] = data;
        }
        this.editIntermediateBondList = JSON.parse(
          JSON.stringify(this.intermediateBondList)
        );
        this.editValueList = JSON.parse(JSON.stringify(this.valueList));
      }
    },
    // 计算器弹窗返回结果
    submitFormula(val, formulaPronoun) {
      if (this.calType == "output") {
        this.outform.outputFormulaArr = val;
        this.outform.OutputFormulaNum = formulaPronoun;
        // this.outform.formula = val.join('')
        // 中间键计算值格式转换
        let formulaPre = [];
        val.forEach(function (value, index) {
          if (Array.isArray(value)) {
            value.forEach(function (ifvalue, ifindex) {
              formulaPre.push(ifvalue);
            });
          } else {
            formulaPre.push(value);
          }
        });
        this.outform.formulaPre = formulaPre.join("");
        // 表格显示值中间键转换
        // let formulaPronounArr = []
        // formulaPronoun.forEach(function(value, index) {
        // 	if (Array.isArray(value)) {
        // 		value.forEach(function(ifvalue, ifindex) {
        // 			formulaPronounArr.push(ifvalue)
        // 		})
        // 	} else {
        // 		formulaPronounArr.push(value)
        // 	}
        // })
        this.outform.formula = this.converseDataSpecial(formulaPronoun);
        // this.outform.formula = formulaPronounArr.join('').replace(/\|/g, '')
        // this.outform.formulaPre = val.join('')
        // this.outform.formula = formulaPronoun.join('').replace(/\|/g, '')
        this.outform.formulaVal = this.converseData(val);
      } else {
        this.$refs.parameterDia.inputform.FormulaNumArr = val;
        this.$refs.parameterDia.inputform.FormulaNum = formulaPronoun;
        this.$refs.parameterDia.inputform.formula = this.converseData(val);
        // this.$refs.parameterDia.inputform.formulaVal = val.join('')
        // 中间键计算值格式转换
        let formulaPre = [];
        val.forEach(function (value, index) {
          if (Array.isArray(value)) {
            value.forEach(function (ifvalue, ifindex) {
              formulaPre.push(ifvalue);
            });
          } else {
            formulaPre.push(value);
          }
        });
        this.$refs.parameterDia.inputform.formulaPre = formulaPre.join("");
        // 表格显示值中间键转换
        // let formulaPronounArr = []
        // formulaPronoun.forEach(function(value, index) {
        // 	if (Array.isArray(value)) {
        // 		value.forEach(function(ifvalue, ifindex) {
        // 			formulaPronounArr.push(ifvalue)
        // 		})
        // 	} else {
        // 		formulaPronounArr.push(value)
        // 	}
        // })
        this.$refs.parameterDia.inputform.formulaVal =
          this.converseDataSpecial(formulaPronoun);
      }
    },
    // 计算器结果数据转化
    converseData(val) {
      let _this = this;
      let data = [];
      // 初始数字位
      let startnum = 0;
      let endnum = 0;
      val.forEach(function (value, key) {
        if (_this.symbol.indexOf(value) > -1) {
          endnum = key;
          let num = val.slice(startnum, endnum);
          data.push(num.join(""));
          if (value == "+") {
            data.push("%2B");
          } else {
            data.push(value);
          }
          startnum = key + 1;
        } else if (Array.isArray(value)) {
          endnum = key;
          let num = val.slice(startnum, endnum);
          data.push(num.join(""));
          value.forEach(function (valx, valueIndex) {
            data.push(valx);
          });
          startnum = key + 1;
        } else {
          if (key == val.length - 1) {
            endnum = -1;
            let num =
              val.slice(startnum - val.length, endnum).join("") + val.slice(-1);
            data.push(num);
          }
        }
      });
      data = "~" + data.join("$").replace(/\$/g, "~") + "~";
      return data;
    },
    // 计算器结果数据转化(round, if, and, or)
    converseDataSpecial(val) {
      let _this = this;
      let data = [];
      // 初始数字位
      let startnum = 0;
      let endnum = 0;
      val.forEach(function (value, key) {
        if (_this.symbol.indexOf(value) > -1) {
          endnum = key;
          let num = val.slice(startnum, endnum);
          data.push(num.join(""));
          data.push(value);
          startnum = key + 1;
        } else if (Array.isArray(value)) {
          endnum = key;
          let num = val.slice(startnum, endnum);
          data.push(num.join(""));
          if (value.indexOf("Round") > -1 || value.indexOf("RoundUp") > -1) {
            let roundArr = JSON.parse(JSON.stringify(value));
            roundArr.pop();
            roundArr.forEach(function (valx, valueIndex) {
              data.push(valx);
            });
          } else {
            value.forEach(function (valx, valueIndex) {
              data.push(valx);
            });
          }

          startnum = key + 1;
        } else {
          if (key == val.length - 1) {
            endnum = -1;
            let num =
              val.slice(startnum - val.length, endnum).join("") + val.slice(-1);
            data.push(num);
          }
        }
      });
      data = data.join("$").replace(/\$/g, "");
      data = data.replace(/\|/g, "");
      data = data.replace(/roundRound/g, "");
      data = data.replace(/round/g, "");
      return data;
    },
    // 计算结果
    previewResult() {
      let _this = this;
      if (this.outform.formulaVal == "") {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.pleaseData"),
          "",
          "warning"
        );
        return;
      }
      this.valueListPreview = JSON.parse(JSON.stringify(this.valueList));
      this.intermediateBondListPreview = JSON.parse(
        JSON.stringify(this.intermediateBondList)
      );
      this.previewName = this.outform.name;
      let list = [];
      this.valueList.forEach(function (val, key) {
        list.push({
          paramValue: val.thedefaultvalue,
          paramentName: val.parameterName,
        });
      });
      this.intermediateBondList.forEach(function (val, key) {
        list.push({
          paramValue: val.formulaPre,
          paramentName: val.parameterName,
        });
      });
      this.intermediateBondListPreview.forEach(function (val, key) {
        // val.formulaPre
        let data = {
          outputFormula: val.formulaPre,
          inputParameterList: list,
        };
        InputParameterAnalysis(data, (response) => {
          if (response && response.code == 200) {
            val.thedefaultvalue = response.data;
          }
        });
      });
      this.previewImgList = [];
      this.fileListx.forEach(function (val, index) {
        let url = "";
        if (val.raw) {
          const reader = new FileReader();
          reader.readAsDataURL(val.raw);
          reader.onload = () => {
            url = reader.result;
            _this.previewImgList.push({
              url: url,
            });
          };
        } else {
          _this.previewImgList.push({
            url: val.url,
          });
        }
      });
      // let data = {
      // 	outputFormula: this.outform.formulaPre,
      // 	inputParameterList: list
      // }
      this.previewResultx = 0;
      this.$refs.previewDialog.visible = true;
      // this.tocompute(data)
      // CalculationFormulaResult({
      // 	Formula: this.outform.formulaVal
      // }, response=>{
      // 	if (response && response.code == 200) {
      // 		this.previewResultx = response.data
      // 		this.$refs.previewDialog.visible = true
      // 	}
      // })
    },
    tocompute(val, valList, intermediateBondList) {
      InputParameterAnalysis(val, (response) => {
        if (response && response.code == 200) {
          this.previewResultx = response.data.toString();
          this.$refs.previewDialog.visible = true;
        }
      });
      this.intermediateBondListPreview = intermediateBondList;
      this.intermediateBondListPreview.forEach(function (valx, key) {
        // val.formulaPre
        let data = {
          outputFormula: valx.formulaPre,
          inputParameterList: val.inputParameterList,
        };
        InputParameterAnalysis(data, (response) => {
          if (response && response.code == 200) {
            valx.thedefaultvalue = response.data;
          }
        });
      });
    },
    // 提交
    confirmCalculator() {
      if (this.form.name == "") {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.versionName"),
          "",
          "warning"
        );
        return;
      }
      if (this.form.commodityId == "") {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.commodityRequired"),
          "",
          "warning"
        );
        return;
      }
      // if (this.form.version == '') {
      // 	commonUtil.showTipInfo('请输入版本版本！', '', 'warning')
      // 	return;
      // }
      // if (this.form.comments == '') {
      // 	commonUtil.showTipInfo('请输入注释！', '', 'warning')
      // 	return;
      // }
      if (this.outform.name == "") {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.pleaseNames"),
          "",
          "warning"
        );
        return;
      }
      if (this.outform.formula == "") {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.pleaseFormula"),
          "",
          "warning"
        );
        return;
      }
      this.uploadFiles(this.fileListx);
    },
    addCalculator() {
      let tableData = JSON.parse(JSON.stringify(this.tableData));
      tableData.forEach(function (val, index) {
        val.FormulaNum = val.FormulaNum ? JSON.stringify(val.FormulaNum) : "";
        val.FormulaNumArr = val.FormulaNumArr
          ? JSON.stringify(val.FormulaNumArr)
          : "";
      });
      let data = {
        id: this.form.id,
        commodityId: this.form.commodityId,
        process_Calculator_Name: this.form.name,
        version: this.form.version,
        comments: this.form.comments,
        enable: 1,
        outputName: this.outform.name,
        outputUnit: this.outform.unit,
        outputFormula: this.outform.formulaVal,
        outputFormulaPre: this.outform.formulaPre,
        outputFormulaArr: JSON.stringify(this.outform.outputFormulaArr),
        outputFormulaNum: JSON.stringify(this.outform.OutputFormulaNum),
        edit_List: tableData,
        pictureList: this.pictureList,
      };
      AddOrUpdate(data, (response) => {
        if (response && response.code == 200) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.addedSuccess"),
            "",
            "success"
          );
          this.$router.back(-1);
        }
      });
    },
    // 取消
    cancleCalculator() {
      this.$router.back(-1);
    },
    // 文件上传
    uploadFiles(files) {
      let _this = this;
      this.uploadStatus = false;
      let format = new FormData();
      // 需要上传的文件
      let uploadList = [];
      files.forEach(function (value, key) {
        if (value.raw) {
          format.append("file", value.raw);
          uploadList.push(value);
        } else {
          _this.pictureList.push(value);
        }
      });
      if (uploadList.length > 0) {
        uploadFile(format, (response) => {
          if (response && response.code == 200) {
            response.data.forEach(function (val, key) {
              _this.pictureList.push(val);
            });
            _this.uploadStatus = true;
          }
        });
      } else {
        _this.uploadStatus = true;
      }
    },
    // 图片下载
    downloadPicture(index) {
      if (this.fileListx[index].raw) {
        commonUtil.downloadPicByFile(
          this.fileListx[index].raw,
          this.fileListx[index].name.split(".")[0]
        );
      } else {
        commonUtil.downloadPicByUrl(
          this.fileListx[index].url,
          this.fileListx[index].name.split(".")[0]
        );
      }
    },
    Goback() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  padding-top: 20px;
  width: 100%;
  min-height: 100%;
  background: url("../../assets/img/car-table-bg.png") no-repeat right;
  background-position: right 4% bottom 0;
  background-size: 650px 600px;
  border-radius: 20px;
  background-color: #fff;
}
.tcm-content {
  padding: 20px 90px 20px 20px;
}
.tcm-content-title {
  background-color: #f0f2f570;
  border-radius: 5px;
  padding: 20px 20px 20px 50px;
  font-size: 16px;
  font-weight: bold;
  position: relative;
}
.tcm-content-title:before {
  content: "";
  display: table;
  width: 6px;
  height: 6px;
  background: #000;
  border-radius: 50%;
  position: absolute;
  left: 30px;
  top: 27px;
}
.tcm-content-form {
  width: calc(100% - 50px);
  margin-left: 50px;
  //   background-color: #f2f2f2;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 20px;
  box-sizing: border-box;
}
.line-12 {
  line-height: 12px;
}
.dis-flex {
  display: flex;
}
.file-cla {
  line-height: 30px;
  display: flex;
}
.add-cla {
  padding-top: 2px;
  margin-left: 10px;
  cursor: pointer;
}
.mgr-20 {
  margin-right: 20px;
}
.operate-btnx {
  border-radius: 5px;
  background-color: var(--themeColor);
  color: #fff;
  font-size: 12px;
  text-align: center;
  padding: 2px 5px;
  min-width: 60px;
  cursor: pointer;
}

.crumbs-box {
  position: fixed;
  top: 70px;
  left: 220px;
  padding-left: 20px;
  width: 100%;
  display: flex;
  background-color: #f2f2f2;
  z-index: 999;
  // border-bottom: 1px solid #e4e7ed;
  align-items: center;
  height: 30px;
  font-size: 16px;
  color: #3e4954;
  .crumbs-box-item {
    display: flex;
    align-items: center;
  }
  .el-icon {
    margin: 0 10px;
  }
  .parent {
    color: #2f4cdd;
    cursor: pointer;
  }
  .line {
    margin: 0 15px;
    width: 2px;
    height: 15px;
    background: #3e4954;
  }
}
</style>