<template>
  <div class="main">
    <div class="main-top_handle">
      <div class="title">PCBA - Yes Price</div>
      <div class="main-handle flex">
        <div class="main-handle-item">
          {{ $t("pcbas.pcba.nexteerPart") }}
          <input type="text" v-model="searchForm.key" />
        </div>
        <div class="main-handle-item">
          {{ $t("pcbas.pcba.manufacturingPart") }}
          <input type="text" v-model="searchForm.manufacturingPart" />
        </div>
        <div class="main-handle-item">
          {{ $t("pcbas.pcba.deviceType") }}
          <input type="text" v-model="searchForm.deviceType" />
        </div>
        <div class="main-handle-item">
          {{ $t("pcbas.pcba.status") }}
          <input type="text" v-model="searchForm.status" />
        </div>
        <div class="search-btn" style="margin-top: 15px" @click="search">
          {{ $t("tcmlists.tcmlist.search") }}
        </div>
      </div>
    </div>
    <div class="handle-btn flex">
      <div v-if="BtnLimits.Add" class="handle-btn-item" @click="addData">
        {{ $t("pcbas.pcba.new") }}
      </div>
      <div v-if="BtnLimits.Edit" class="handle-btn-item" @click="editData">
        {{ $t("pcbas.pcba.edit") }}
      </div>
      <div v-if="BtnLimits.Delete" class="handle-btn-item" @click="deleteData">
        {{ $t("pcbas.pcba.delete") }}
      </div>
      <el-upload
        ref="upload"
        class="upload-demo custom-el-upload-list-none"
        action="/"
        :on-change="importData"
        :auto-upload="false"
      >
        <template #trigger>
          <div class="handle-btn-item" v-if="BtnLimits.Import">
            {{ $t("pcbas.pcba.import") }}
          </div>
        </template>
      </el-upload>
      <div v-if="BtnLimits.Export" class="handle-btn-item" @click="exportData">
        {{ $t("pcbas.pcba.export") }}
      </div>
      <div class="handle-btn-item" @click="downTemplate">
        {{ $t("pcbas.pcba.downTemplate") }}
      </div>
    </div>
    <div class="regional_table">
      <el-table
        border
        stripe
        :empty-text="$t('tcmlists.tcmlist.noData')"
        :data="tableData"
        :height="TableHeight"
        style="width: 100%"
        @row-click="RowClick"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column
          prop="supplierName"
          :label="$t('pcbas.pcba.supplierName')"
          width="110"
          align="center"
        />
        <el-table-column
          prop="nexteerPart"
          :label="$t('pcbas.pcba.nexteerPart')"
          align="center"
        />
        <el-table-column
          prop="manufacturingPart"
          :label="$t('pcbas.pcba.manufacturingPart')"
          align="center"
        />
        <el-table-column
          prop="alternateOption"
          :label="$t('pcbas.pcba.alternateOption')"
          align="center"
        />
        <el-table-column
          prop="deviceType"
          :label="$t('pcbas.pcba.deviceType')"
          align="center"
        />
        <el-table-column
          prop="type"
          :label="$t('pcbas.pcba.type')"
          align="center"
        />
        <el-table-column
          prop="compDescription"
          :label="$t('pcbas.pcba.compDescription')"
          align="center"
        />
        <el-table-column
          prop="status"
          :label="$t('pcbas.pcba.status')"
          align="center"
        >
          <template #default="scope">
            <div :class="scope.row.status == 'EOL' ? 'color-ff0000' : ''">
              {{ scope.row.status }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="currencyName"
          :label="$t('pcbas.pcba.currency')"
          align="center"
        />
        <el-table-column
          prop="otherInfo"
          :label="$t('pcbas.pcba.otherInfo')"
          align="center"
        >
          <template #default="scope">
            <div class="color-F59A23">{{ scope.row.otherInfo }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="ppapComplete"
          :label="$t('pcbas.pcba.ppapComplete')"
          align="center"
        >
          <template #default="scope">
            <div class="color-F59A23">{{ scope.row.ppapComplete }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="dueDate"
          :label="$t('pcbas.pcba.ppapDueDate')"
          align="center"
        >
          <template #default="scope">
            <div class="color-F59A23">{{ scope.row.dueDate }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="note"
          :label="$t('pcbas.pcba.note')"
          align="center"
        >
          <template #default="scope">
            <div class="color-F59A23">{{ scope.row.note }}</div>
          </template>
        </el-table-column>
        <el-table-column
          width="80"
          fixed="right"
          class-name="lastTd"
          align="center"
          prop="action"
        >
          <template #default="scope">
            <el-popover placement="bottom" trigger="hover">
              <template #reference>
                <div style="text-align: center">
                  <el-icon><MoreFilled /></el-icon>
                </div>
              </template>
              <div class="action-box">
                <div class="action-btn" @click="viewData(scope.row.id)">
                  <el-icon><View /></el-icon>
                  <span>{{ $t("changePriceLists.changePriceList.View") }}</span>
                </div>
              </div>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <div>
        {{ $t("homePage.home.Showing") }} {{ pageSize }}
        {{ $t("homePage.home.From") }} {{ total }}
        {{ $t("homePage.home.Data") }}
        <el-popover placement="top" :width="70" trigger="click">
          <template #reference>
            <el-icon
              style="margin-left: 5px; font-size: 17px; vertical-align: middle"
              ><Operation
            /></el-icon>
          </template>
          <el-input
            v-model="searchForm.limit"
            @input="(val) => (searchForm.limit = val.replace(/[^\d]/g, ''))"
            :min="1"
            style="width: 130px"
            @change="handleSizeChange"
          >
            <template #suffix> {{ $t("ccls.ccl.barPage") }}</template>
          </el-input>
        </el-popover>
      </div>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="pageSize"
        :current-page="searchForm.page"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <ViewDia ref="viewDialog"></ViewDia>
  </div>
</template>

<script>
import {
  EBom_YESPricingsLoad,
  ImportYes,
  DeleteEBom_YESPricings,
  ExportYes,
  DownloadTemplateExcel,
} from "../../../assets/js/requestApi.js";
import commonUtil from "../../../assets/js/commonUtil.js";
import ViewDia from "./viewDia.vue";
export default {
  name: "yesPriceList",
  activated() {
    // 在首次挂载、
    // 以及每次从缓存中被重新插入的时候调用
    this.search();
    this.getUnits();
  },
  deactivated() {
    clearTimeout(this.timer);
    // 在从 DOM 上移除、进入缓存
    // 以及组件卸载时调用
  },
  components: {
    ViewDia,
  },
  data() {
    return {
      TableHeight: 0,
      searchForm: {
        manufacturingPart: "",
        deviceType: "",
        status: "",
        page: 1,
        limit: 10,
        key: "",
      },
      tableData: [],
      // 已选择数据
      chooseData: [],
      // 已选择对比数据
      drawer: false,
      // 选择的对比数据列表
      compareList: [],
      // 总页数
      total: 0,
      pageSize: 10,
      timer: "",
      // 数据重复是否继续导入
      isContinue: false,
      BtnLimits: {},
    };
  },

  mounted() {
    this.autoHeight();
    this.getList();
    // this.timer = setTimeout(this.gettime, 1000);
  },
  methods: {
    RowClick(row) {
      this.viewData(row.id);
    },
    autoHeight() {
      // 初始化的时候，设置高度
      this.setHeight();
      // 改变浏览器窗口大小的时候，设置高度
      window.addEventListener("resize", () => {
        this.setHeight();
      });
    },
    setHeight() {
      this.$nextTick(() => {
        this.TableHeight = window.innerHeight - 235; // 这里的X就是顶部栏的高度
      });
    },
    getUnits() {
      this.timer = setInterval(this.gettime, 1000);
    },
    gettime() {
      this.BtnLimits = JSON.parse(sessionStorage.getItem("Btn"));
    },
    getList() {
      this.searchForm = {
        manufacturingPart: "",
        deviceType: "",
        status: "",
        page: 1,
        limit: 10,
        key: "",
      };
      this.search();
    },
    search() {
      EBom_YESPricingsLoad(this.searchForm, (response) => {
        if (response && response.code == 200) {
          this.tableData = response.data;
          this.total = response.count;
        }
      });
    },
    // 分页
    handleCurrentChange(val) {
      this.searchForm.page = val;
      this.search();
    },
    handleSizeChange() {
      this.pageSize = parseInt(this.searchForm.limit);
      if (this.searchForm.limit === "") {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.setPageNum"),
          "",
          "warning"
        );
        return;
      }
      if (this.searchForm.limit == 0) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.pageThanZero"),
          "",
          "warning"
        );
        return;
      }
      this.searchForm.page = 1;
      this.search();
    },
    // 预览
    viewData(id) {
      this.$refs.viewDialog.getDetail(id);
      this.$refs.viewDialog.visible = true;
    },
    // 新增
    addData() {
      this.$router.push({
        path: "/addYesPrice",
        name: "addYesPrice",
        query: {
          type: "add",
        },
      });
    },
    // 编辑数据
    editData() {
      if (this.chooseData.length == 0) {
        commonUtil.showTipInfo(
          this.$t("userlists.userlist.selecWant"),
          "",
          "warning"
        );
        return;
      }
      if (this.chooseData.length > 1) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.onlyOne"),
          "",
          "warning"
        );
        return;
      }
      this.$router.push({
        path: "/addYesPrice",
        name: "addYesPrice",
        query: {
          type: "edit",
          id: this.chooseData[0].id,
        },
      });
    },
    // 删除
    deleteData() {
      if (this.chooseData.length == 0) {
        commonUtil.showTipInfo(
          this.$t("userlists.userlist.selecWant"),
          "",
          "warning"
        );
        return;
      }
      let ids = [];
      this.chooseData.forEach(function (val, index) {
        ids.push(val.id);
      });
      DeleteEBom_YESPricings(ids, (response) => {
        if (response && response.code == 200) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.deleteSuccessfully"),
            "",
            "success"
          );
          this.search();
        }
      });
    },
    // 导入
    importData(files) {
      let format = new FormData();
      format.append("file", files.raw);
      format.append("Type", 1);
      ImportYes(format, (response) => {
        if (response && response.code == 200) {
          this.getList();
          this.$refs.upload.clearFiles();
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.importSuccessfully"),
            "",
            "success"
          );
        } else {
          commonUtil.showTipInfo(response.message, "", "error");
        }
      });
    },
    // 表格多选
    handleSelectionChange(val) {
      this.chooseData = val;
    },
    // 导出
    exportData() {
      // if (this.chooseData.length == 0) {
      // 	commonUtil.showTipInfo(this.$t('userlists.userlist.selecWant'), '', 'warning')
      // 	return;
      // }
      // let ids = []
      // this.chooseData.forEach(function(val, key) {
      // 	ids.push(val.id)
      // })
      ExportYes(this.searchForm, (response) => {});
    },
    // 下载模板
    downTemplate() {
      DownloadTemplateExcel(5, (response) => {});
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  width: 100%;
  height: 100%;
  background: url("../../../assets/img/car-table-bg.png") no-repeat center;
  background-size: contain;
}
.main-top_handle {
  display: flex;
  justify-content: space-between;
  height: 40px;
}
.handle-btn {
  align-items: center;
  height: 40px;
  .handle-btn-item {
    padding: 0 20px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    background: #b2bde7;
    color: #fff;
    border-radius: 25px;
    cursor: pointer;
    margin-left: 10px;
  }
}

.main-handle {
  align-items: center;
}
.main-handle-item {
  width: 120px;
  margin-right: 10px;
}
.main-handle input,
.main-handle .el-select .el-input {
  width: 100%;
  border-radius: 10px;
  font-size: 14px;
}
.main-handle input::placeholder {
  color: #3e4954;
  text-align: center;
  font-size: 14px;
}
// 日期选择器
.select-box {
  position: relative;
  .select-btn {
    width: 200px;
    height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 10px;
    background: #fff;
    cursor: pointer;
    .Calendar,
    .Switch {
      font-size: 18px;
      color: #2f4cdd;
    }
  }
}
.search-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 30px;
  background: #445581;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
</style>