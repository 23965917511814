<template>
  <el-dialog v-model="visible" width="500px" :show-close="false" @close="close">
    <template #header="{ close }">
      <div class="my-header">
        <div class="size-16"></div>
        <div style="cursor: pointer" @click="close">
          <el-icon :size="20" style="color: var(--themeColor)">
            <CircleCloseFilled />
          </el-icon>
        </div>
      </div>
    </template>
    <div>
      <div class="rate-cla">
        <el-form
          :inline="true"
          :model="form"
          label-width="150px"
          class="custom-el-form-rules"
        >
          <el-form-item :label="$t('pcbas.pcba.year') + '：'">
            <el-select
              clearable
              :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
              v-model="form.year"
              placeholder=""
              style="width: 214px"
            >
              <el-option
                v-for="item in yearList"
                :key="item"
                :label="item"
                :value="item"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            :label="$t('pcbas.pcba.price') + '：'"
            class="custom-el-form-rules"
          >
            <el-input
              v-model="form.price"
              placeholder=""
              style="width: 214px"
            />
          </el-form-item>
        </el-form>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close">{{ $t("pcbas.pcba.cancle") }}</el-button>
        <el-button
          style="
            background-color: var(--themeColor);
            color: var(--menuTextActive);
            margin-right: 16px;
          "
          @click="addSubmit"
          >{{ $t("pcbas.pcba.confirm") }}</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import "../../../assets/css/custom-el-form-rules.css";
import commonUtil from "@/assets/js/commonUtil.js";
export default {
  emits: ["submitPrice"],
  data() {
    return {
      visible: false,
      form: {
        yesPricingId: "",
        id: "",
        year: "",
        price: "",
      },
      yearList: [],
    };
  },
  mounted() {
    let date = new Date();
    let year = date.getFullYear();
    let startYear = year - 20;
    this.yearList = [];
    for (let i = 0; i < 41; i++) {
      this.yearList.push((startYear + i).toString());
    }
  },
  methods: {
    close() {
      this.form = {
        yesPricingId: "",
        id: "",
        year: "",
        price: "",
      };
      this.visible = false;
    },
    addSubmit() {
      if (this.form.year == "") {
        commonUtil.showTipInfo("Please choose year", "", "warning");
        return;
      }
      if (this.form.price == "") {
        commonUtil.showTipInfo("Please enter price", "", "warning");
        return;
      }
      this.$emit("submitPrice", this.form);
      // this.close()
    },
  },
};
</script>

<style scoped>
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.size-16 {
  font-size: 16px;
}
.rate-cla {
  display: flex;
  margin-bottom: 10px;
}
.line-32 {
  line-height: 32px;
}
.mg-5 {
  margin: 5px;
}
.mgr-10 {
  margin-right: 10px;
}
.arrows-img {
  width: 20px;
}
</style>
