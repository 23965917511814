<template>
  <div class="tcm-content">
    <div class="tcm-content-title">
      {{ $t("changePriceLists.changePriceList.costSummary") }}
      <div class="report-cur" v-show="operateType != 'detail'">
        <div class="mgr-10">
          {{ $t("changePriceLists.changePriceList.reportCurrency") }}
        </div>
        <div class="mgr-10">
          <el-select
            clearable
            :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
            v-model="reportCurrency"
            filterable
            style="width: 120px"
          >
            <el-option
              v-for="it in ReportCurrencyOptions"
              :key="it.currencyCNYReportCurrency"
              :label="it.currencyCNYReportCurrencyName"
              :value="it.currencyCNYReportCurrency"
            />
          </el-select>
        </div>
        <el-button
          style="
            background-color: var(--themeColor);
            color: var(--menuTextActive);
          "
          @click="reportForm"
          >{{ $t("changePriceLists.changePriceList.reportForm") }}</el-button
        >
      </div>
    </div>
    <div class="tcm-content-form">
      <el-form :model="form" label-width="400px">
        <el-row>
          <el-col :span="24">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.rawMaterialCost')"
            >
              <el-input
                v-model="form.materialCost_SUM"
                disabled
                style="width: 214px"
              >
                <template #prefix>{{ exchangeRateUnit }}</template>
              </el-input>
              <span style="margin-left: 10px; color: gray; line-height: 32px">
                {{
                  isNaN(materialCost_SUM_Compute)
                    ? ""
                    : materialCost_SUM_Compute + "%"
                }}
              </span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.purchasedCost')"
            >
              <el-input
                v-model="form.purchaseCost_SUM"
                disabled
                style="width: 214px"
              >
                <template #prefix>{{ exchangeRateUnit }}</template>
              </el-input>
              <span style="margin-left: 10px; color: gray; line-height: 32px">
                {{
                  isNaN(purchaseCost_SUM_Compute)
                    ? ""
                    : purchaseCost_SUM_Compute + "%"
                }}
              </span>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.directLaborCost')"
            >
              <el-input
                v-model="form.laborCost_SUM"
                disabled
                style="width: 214px"
              >
                <template #prefix>{{ exchangeRateUnit }}</template>
              </el-input>
              <span style="margin-left: 10px; color: gray; line-height: 32px">
                {{ isNaN(t1) ? "" : t1 + "%" }}
              </span>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.burdenCost')"
            >
              <el-input
                v-model="form.burdenCost_SUM"
                disabled
                style="width: 214px"
              >
                <template #prefix>{{ exchangeRateUnit }}</template>
              </el-input>
              <span style="margin-left: 10px; color: gray; line-height: 32px">
                {{ isNaN(t2) ? "" : t2 + "%" }}
              </span>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.setUpCost')"
            >
              <el-input
                v-model="form.setUpCost_SUM"
                disabled
                style="width: 214px"
              >
                <template #prefix>{{ exchangeRateUnit }}</template>
              </el-input>
              <span style="margin-left: 10px; color: gray; line-height: 32px">
                {{ isNaN(t3) ? "" : t3 + "%" }}
              </span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.totalManufacturing')"
            >
              <!--制造成本总计-->
              <el-input
                v-model="form.manufacturingCost_PRO"
                disabled
                style="width: 214px"
              >
                <template #prefix>{{ exchangeRateUnit }}</template>
              </el-input>
              <span style="margin-left: 10px; color: gray; line-height: 32px">
                {{
                  (
                    parseFloat(
                      isNaN(materialCost_SUM_Compute)
                        ? 0
                        : materialCost_SUM_Compute
                    ) +
                    parseFloat(
                      isNaN(purchaseCost_SUM_Compute)
                        ? 0
                        : purchaseCost_SUM_Compute
                    ) +
                    parseFloat(isNaN(t1) ? 0 : t1) +
                    parseFloat(isNaN(t2) ? 0 : t2) +
                    parseFloat(isNaN(t3) ? 0 : t3)
                  ).toFixed(3)
                }}%
              </span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item
              :label="
                $t('changePriceLists.changePriceList.packagingFreightCost')
              "
            >
              <el-input
                v-model="form.packageCost_SUM"
                disabled
                style="width: 214px"
              >
                <template #prefix>{{ exchangeRateUnit }}</template>
              </el-input>
              <span style="margin-left: 10px; color: gray; line-height: 32px">
                {{
                  form.packageCost_SUM !== "" &&
                  form.totalPriceCurr_SUM !== "" &&
                  form.packageCost_SUM !== null &&
                  form.totalPriceCurr_SUM !== null &&
                  parseFloat(form.totalPriceCurr_SUM) !== 0
                    ? (
                        (form.packageCost_SUM / form.totalPriceCurr_SUM) *
                        100
                      ).toFixed(3) + "%"
                    : ""
                }}
              </span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.amortizationFees')"
            >
              <el-input
                v-model="form.otherCost_SUM"
                disabled
                style="width: 214px"
              >
                <template #prefix>{{ exchangeRateUnit }}</template>
              </el-input>
              <span style="margin-left: 10px; color: gray; line-height: 32px">
                {{
                  form.otherCost_SUM !== "" &&
                  form.totalPriceCurr_SUM !== "" &&
                  form.otherCost_SUM !== null &&
                  form.totalPriceCurr_SUM !== null &&
                  parseFloat(form.totalPriceCurr_SUM) !== 0
                    ? (
                        (form.otherCost_SUM / form.totalPriceCurr_SUM) *
                        100
                      ).toFixed(3) + "%"
                    : ""
                }}
              </span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.addedCost')"
            >
              <el-input
                v-model="form.amortizeCost_SUM"
                disabled
                style="width: 214px"
              >
                <template #prefix>{{ exchangeRateUnit }}</template>
              </el-input>
              <span style="margin-left: 10px; color: gray; line-height: 32px">
                {{
                  form.amortizeCost_SUM !== "" &&
                  form.totalPriceCurr_SUM !== "" &&
                  form.amortizeCost_SUM !== null &&
                  form.totalPriceCurr_SUM !== null &&
                  parseFloat(form.totalPriceCurr_SUM) !== 0
                    ? (
                        (form.amortizeCost_SUM / form.totalPriceCurr_SUM) *
                        100
                      ).toFixed(3) + "%"
                    : ""
                }}
              </span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.processScrapCost')"
            >
              <el-input
                v-model="form.scrapCost_SUM"
                disabled
                style="width: 214px"
              >
                <template #prefix>{{ exchangeRateUnit }}</template>
              </el-input>
              <span style="margin-left: 10px; color: gray; line-height: 32px">
                {{
                  form.scrapCost_SUM !== "" &&
                  form.totalPriceCurr_SUM !== "" &&
                  form.scrapCost_SUM !== null &&
                  form.totalPriceCurr_SUM !== null &&
                  parseFloat(form.totalPriceCurr_SUM) !== 0
                    ? (
                        (form.scrapCost_SUM / form.totalPriceCurr_SUM) *
                        100
                      ).toFixed(3) + "%"
                    : ""
                }}
              </span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.sgaAndProfit')"
            >
              <el-input
                v-model="form.sgaProfit_SUM"
                disabled
                style="width: 214px"
              >
                <template #prefix>{{ exchangeRateUnit }}</template>
              </el-input>
              <span style="margin-left: 10px; color: gray; line-height: 32px">
                {{
                  form.sgaProfit_SUM !== "" &&
                  form.totalPriceCurr_SUM !== "" &&
                  form.sgaProfit_SUM !== null &&
                  form.totalPriceCurr_SUM !== null &&
                  parseFloat(form.totalPriceCurr_SUM) !== 0
                    ? (
                        (form.sgaProfit_SUM / form.totalPriceCurr_SUM) *
                        100
                      ).toFixed(3) + "%"
                    : ""
                }}
              </span>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- <el-row>
					<el-col :span="12">
						<el-form-item label="Total Net Wt">
							<el-input v-model="form.Currency" style="width: 214px;" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="KBS Supplier Cost/Weight">
							<el-input v-model="form.Currency" style="width: 214px;" />
						</el-form-item>
					</el-col>
				</el-row> -->
      </el-form>
      <el-form :model="form" label-width="400px">
        <el-row>
          <el-col :span="24">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.totalSellPrice')"
            >
              <el-input
                v-model="totalPriceCurr_SUM"
                disabled
                style="width: 214px"
              >
                <template #prefix
                  >{{ exchangeRateUnit }}
                  {{
                    parseFloat(
                      form.totalPriceCurr_SUM === "" ||
                        form.totalPriceCurr_SUM === null
                        ? 0
                        : form.totalPriceCurr_SUM
                    ).toFixed(3)
                  }}</template
                >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.totalSellCurrency')"
            >
              <el-input
                v-model="totalPriceReport_SUM"
                disabled
                style="width: 214px"
              >
                <template #prefix
                  >{{ reportExchangeRateUnit }}
                  {{
                    parseFloat(
                      form.totalPriceReport_SUM === "" ||
                        form.totalPriceReport_SUM === null
                        ? 0
                        : form.totalPriceReport_SUM
                    ).toFixed(3)
                  }}</template
                >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-row>
					<el-col :span="24">
						<el-form-item :label="$t('changePriceLists.changePriceList.attachments')">
							<el-row>
								<div class="file-cla" v-show="fileListx.length > 0">
									<div v-for="(item, index) in fileListx" style="margin-right: 10px;">
										<div>
											<img src="../../assets/img/tcm/doc.png" style="width: 30px;height: 30px;"/>
										</div>
										<div style="text-align: center;">
											<img src="../../assets/img/tcm/cuowu.png" style="width: 12px;height: 12px;margin-right: 4px;cursor: pointer;" @click="toDetele(index, 'file')"/>
											<img src="../../assets/img/tcm/yunxiazai.png" style="width: 12px;height: 12px;cursor: pointer;"/>
										</div>
									</div>
								</div>
								<el-col :span="2" v-show="operateType != 'detail'">
									<el-upload
									  class="custom-el-upload-list-none"
									  style="display: inline-block;margin-top: 10px;"
									  action="/"
									  :auto-upload="false"
									  multiple
									  :limit="10"
									  :file-list="fileListx"
									  :on-change="filechange">
									  <el-button size="small">上传文件</el-button>
									</el-upload>
								</el-col>
							</el-row>
						</el-form-item>
						
					</el-col>
				</el-row> -->
      </el-form>
    </div>
    <!-- <div class="tcm-content-form">
			
		</div> -->
    <div class="btn-cla">
      <template v-if="!tcmFlag">
        <el-button
          style="
            background-color: var(--themeColor);
            color: var(--menuTextActive);
          "
          @click="cancle"
          >{{ $t("changePriceLists.changePriceList.cancle") }}</el-button
        >
        <el-button
          v-show="operateType != 'detail' && operateType != 'editImport'"
          style="
            background-color: var(--themeColor);
            color: var(--menuTextActive);
          "
          @click="saveTemplate"
          >{{ $t("changePriceLists.changePriceList.saveTemplata") }}</el-button
        >
        <el-button
          style="
            background-color: var(--themeColor);
            color: var(--menuTextActive);
          "
          v-show="operateType != 'detail' && operateType != 'editImport'"
          @click="saveDraft"
          >{{
            saveFlag
              ? $t("changePriceLists.changePriceList.Save")
              : $t("changePriceLists.changePriceList.saveDraft")
          }}</el-button
        >
      </template>
      <!-- v-show="operateType != 'detail' && operateType != 'editImport'" -->

      <el-button
        v-show="tcmFlag"
        style="
          background-color: var(--themeColor);
          color: var(--menuTextActive);
        "
        @click="saveTemplate"
        >{{ $t("changePriceLists.changePriceList.saveTemplata") }}</el-button
      >
      <el-button
        style="
          background-color: var(--themeColor);
          color: var(--menuTextActive);
        "
        @click="preview"
        >{{ $t("changePriceLists.changePriceList.submit") }}</el-button
      >
    </div>
    <SumDia ref="sumDialog"></SumDia>
  </div>
</template>

<script>
import { GetRate } from "../../assets/js/requestApi.js";
import commonUtil from "../../assets/js/commonUtil.js";
import SumDia from "./sumDia.vue";
export default {
  props: [
    "operateType",
    "exchangeRateUnit",
    "ReportCurrencyOptions",
    "reportExchangeRateUnit",
    "tcmFlag",
    "saveFlag",
  ],
  emits: ["cancle", "saveTemplate", "previewTemplate", "saveDraft"],
  components: {
    SumDia,
  },
  data() {
    return {
      form: {
        materialCost_SUM: "",
        setUpCost_SUM: "",
        purchaseCost_SUM: "",
        packageCost_SUM: "",
        laborCost_SUM: "",
        otherCost_SUM: "",
        burdenCost_SUM: "",
        amortizeCost_SUM: "",
        sgaProfit_SUM: "",
        scrapCost_SUM: "",
        totalPriceCurr_SUM: "",
        totalPriceReport_SUM: "",
        manufacturingCost_PRO: "", //
      },
      // currency选项
      // CurrencyOptions: [{
      // 	value: 'Option1',
      // 	label: 'Option1'
      // }, {
      // 	value: 'Option2',
      // 	label: 'Option2'
      // }],
      // ReportCurrency选项
      // ReportCurrencyOptions: [{
      // 	id: 'Option1',
      // 	name: 'Option1'
      // }, {
      // 	id: 'Option2',
      // 	name: 'Option2'
      // }],
      totalPriceCurr_SUM: "",
      totalPriceReport_SUM: "",
      fileListx: [],
      // 选择的货币
      reportCurrency: "",
    };
  },
  computed: {
    t1: function () {
      return this.form.laborCost_SUM !== "" &&
        this.form.totalPriceCurr_SUM !== "" &&
        this.form.laborCost_SUM !== null &&
        this.form.totalPriceCurr_SUM !== null &&
        parseFloat(this.form.totalPriceCurr_SUM) !== 0
        ? (
            (this.form.laborCost_SUM / this.form.totalPriceCurr_SUM) *
            100
          ).toFixed(3)
        : NaN;
    },
    t2: function () {
      return this.form.burdenCost_SUM !== "" &&
        this.form.totalPriceCurr_SUM !== "" &&
        this.form.burdenCost_SUM !== null &&
        this.form.totalPriceCurr_SUM !== null &&
        parseFloat(this.form.totalPriceCurr_SUM) !== 0
        ? (
            (this.form.burdenCost_SUM / this.form.totalPriceCurr_SUM) *
            100
          ).toFixed(3)
        : NaN;
    },
    t3: function () {
      return this.form.setUpCost_SUM !== "" &&
        this.form.totalPriceCurr_SUM !== "" &&
        this.form.setUpCost_SUM !== null &&
        this.form.totalPriceCurr_SUM !== null &&
        parseFloat(this.form.totalPriceCurr_SUM) !== 0
        ? (
            (this.form.setUpCost_SUM / this.form.totalPriceCurr_SUM) *
            100
          ).toFixed(3)
        : NaN;
    },
    materialCost_SUM_Compute: function () {
      return this.form.materialCost_SUM !== "" &&
        this.form.totalPriceCurr_SUM !== "" &&
        this.form.materialCost_SUM !== null &&
        this.form.totalPriceCurr_SUM !== null &&
        parseFloat(this.form.totalPriceCurr_SUM) !== 0
        ? (
            (this.form.materialCost_SUM / this.form.totalPriceCurr_SUM) *
            100
          ).toFixed(3)
        : NaN;
    },
    purchaseCost_SUM_Compute: function () {
      return this.form.purchaseCost_SUM !== "" &&
        this.form.totalPriceCurr_SUM !== "" &&
        this.form.purchaseCost_SUM !== null &&
        this.form.totalPriceCurr_SUM !== null &&
        parseFloat(this.form.totalPriceCurr_SUM) !== 0
        ? (
            (this.form.purchaseCost_SUM / this.form.totalPriceCurr_SUM) *
            100
          ).toFixed(3)
        : NaN;
    },
  },
  watch: {
    form: {
      handler(val, oldVal) {
        this.form.manufacturingCost_PRO =
          Number(this.form.materialCost_SUM) +
          Number(this.form.purchaseCost_SUM) +
          Number(this.form.laborCost_SUM) +
          Number(this.form.burdenCost_SUM) +
          Number(this.form.setUpCost_SUM);
      },
      deep: true, // 设为true可监测到对象内部数据变化
      immediate: true, // 首次加载的时候执行函数
    },
  },
  mounted() {},
  methods: {
    // 文件上传
    filechange(file, fileList) {
      this.fileListx.push(file);
      console.log(this.fileListx);
    },
    // 删除图片
    toDetele(index) {
      this.fileListx.splice(index, 1);
    },
    // 取消
    cancle() {
      this.$emit("cancle");
    },
    // 保存为模板
    saveTemplate() {
      this.$emit("saveTemplate", "template");
    },
    // 保存为草稿
    saveDraft() {
      if (this.saveFlag) {
        this.$emit("saveData");
      } else {
        this.$emit("saveDraft", "draft");
      }
    },
    // 预览
    preview() {
      this.$emit("previewTemplate");
    },
    // 展示柱状图
    reportForm() {
      if (this.reportCurrency == "") {
        commonUtil.showTipInfo("请选择需要转换的货币！", "", "warning");
        return;
      }
      let currency = this.$parent.$refs.gen.genList[0].Currency;
      GetRate(
        {
          currency: currency,
          reportCurrency: this.reportCurrency,
        },
        (response) => {
          if (response && response.code == 200) {
            if (response.result == null) {
              commonUtil.showTipInfo(
                "当前数据无汇率，请重新选择！",
                "",
                "warning"
              );
            } else {
              let rate = response.result.exchangeRate;
              let reportData = {
                materialCost_SUM: (
                  parseFloat(
                    this.form.materialCost_SUM === "" ||
                      this.form.materialCost_SUM === null
                      ? 1
                      : this.form.materialCost_SUM
                  ) * rate
                ).toFixed(3),
                setUpCost_SUM: (
                  parseFloat(
                    this.form.setUpCost_SUM === "" ||
                      this.form.setUpCost_SUM === null
                      ? 1
                      : this.form.setUpCost_SUM
                  ) * rate
                ).toFixed(3),
                purchaseCost_SUM: (
                  parseFloat(
                    this.form.purchaseCost_SUM === "" ||
                      this.form.purchaseCost_SUM === null
                      ? 1
                      : this.form.purchaseCost_SUM
                  ) * rate
                ).toFixed(3),
                packageCost_SUM: (
                  parseFloat(
                    this.form.packageCost_SUM === "" ||
                      this.form.packageCost_SUM === null
                      ? 1
                      : this.form.packageCost_SUM
                  ) * rate
                ).toFixed(3),
                laborCost_SUM: (
                  parseFloat(
                    this.form.laborCost_SUM === "" ||
                      this.form.laborCost_SUM === null
                      ? 1
                      : this.form.laborCost_SUM
                  ) * rate
                ).toFixed(3),
                burdenCost_SUM: (
                  parseFloat(
                    this.form.burdenCost_SUM === "" ||
                      this.form.burdenCost_SUM === null
                      ? 1
                      : this.form.burdenCost_SUM
                  ) * rate
                ).toFixed(3),
                amortizeCost_SUM: (
                  parseFloat(
                    this.form.amortizeCost_SUM === "" ||
                      this.form.amortizeCost_SUM === null
                      ? 1
                      : this.form.amortizeCost_SUM
                  ) * rate
                ).toFixed(3),
                sgaProfit_SUM: (
                  parseFloat(
                    this.form.sgaProfit_SUM === "" ||
                      this.form.sgaProfit_SUM === null
                      ? 1
                      : this.form.sgaProfit_SUM
                  ) * rate
                ).toFixed(3),
                scrapCost_SUM: (
                  parseFloat(
                    this.form.scrapCost_SUM === "" ||
                      this.form.scrapCost_SUM === null
                      ? 1
                      : this.form.scrapCost_SUM
                  ) * rate
                ).toFixed(3),
                totalPriceCurr_SUM: (
                  parseFloat(
                    this.form.totalPriceCurr_SUM === "" ||
                      this.form.totalPriceCurr_SUM === null
                      ? 1
                      : this.form.totalPriceCurr_SUM
                  ) * rate
                ).toFixed(3),
                totalPriceReport_SUM: (
                  parseFloat(
                    this.form.totalPriceReport_SUM === "" ||
                      this.form.totalPriceReport_SUM === null
                      ? 1
                      : this.form.totalPriceReport_SUM
                  ) * rate
                ).toFixed(3),
              };
              this.$refs.sumDialog.drawChart(reportData);
              console.log(reportData);
            }
          }
        }
      );
    },
  },
};
</script>

<style scoped>
.tcm-content {
  padding: 20px 90px 20px 20px;
}
.tcm-content-title {
  /* background-color: #f2f2f2; */
  border-radius: 5px;
  padding: 20px 20px 20px 50px;
  font-size: 16px;
  font-weight: bold;
  position: relative;
}
.tcm-content-title:before {
  content: "";
  display: table;
  width: 6px;
  height: 6px;
  background: #000;
  border-radius: 50%;
  position: absolute;
  left: 30px;
  top: 27px;
}
.tcm-content-form {
  width: calc(100% - 50px);
  margin-left: 50px;
  /* background-color: #f2f2f2; */
  border-radius: 5px;
  margin-top: 10px;
  padding: 20px;
  box-sizing: border-box;
}
.btn-cla {
  width: 50%;
  margin: 20px auto;
  justify-content: space-around;
  display: flex;
}
.file-cla {
  padding: 0px 20px 0px 0;
  display: flex;
}
.report-cur {
  display: flex;
  line-height: 32px;
  position: absolute;
  right: 20px;
  top: 15px;
  font-weight: normal;
}
.mgr-10 {
  margin-right: 10px;
}
</style>