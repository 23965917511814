<template>
  <el-dialog v-model="visible" width="500px" :show-close="false" @close="close">
    <template #header="{ close, titleId, titleClass }">
      <div class="my-header">
        <div class="size-16">
          {{ form.id === "" ? $t("pcbas.pcba.new") : $t("pcbas.pcba.edit") }}
        </div>
        <div style="cursor: pointer" @click="close">
          <el-icon :size="20"><Close /></el-icon>
        </div>
      </div>
    </template>
    <div class="add-cla-top">
      <el-row>
        <el-col :span="24">
          <el-form :inline="true" :model="form" label-width="150px">
            <el-row>
              <el-col :span="24">
                <el-form-item
                  :label="$t('changePriceLists.changePriceList.description')"
                  class="custom-el-form-rules"
                >
                  <el-input v-model="form.description" style="width: 214px" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item
                  :label="$t('changePriceLists.changePriceList.region')"
                  class="custom-el-form-rules"
                >
                  <el-select
                    clearable
                    filterable
                    :placeholder="$t('other.other.SelectRagion')"
                    v-model="form.regionId"
                    style="width: 214px"
                  >
                    <el-option
                      v-for="item in regionOptions"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                    <el-option
                      v-if="
                        !regionOptions.some(
                          (list) => list.id === form.regionId
                        ) && form.regionId != ''
                      "
                      :label="form.regionName"
                      :value="form.regionId"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-col>
      </el-row>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close">{{ $t("pcbas.pcba.cancle") }}</el-button>
        <el-button
          style="
            background-color: var(--themeColor);
            color: var(--menuTextActive);
            margin-right: 16px;
          "
          @click="confirm"
          >{{ $t("pcbas.pcba.confirm") }}</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { getRegions } from "../../assets/js/requestApi.js";
import commonUtil from "../../assets/js/commonUtil.js";
export default {
  emits: ["confirmInfo"],
  data() {
    return {
      visible: false,
      form: {
        id: "",
        description: "",
        regionId: "",
      },
      regionOptions: [],
    };
  },
  mounted() {
    // this.getRegion();
    this.regionOptions = JSON.parse(sessionStorage.getItem("Region"));
  },
  methods: {
    close() {
      this.visible = false;
      this.form = {
        id: "",
        description: "",
        regionId: "",
      };
    },
    // 获取区域列表选项
    getRegion() {
      getRegions(
        {
          Keyword: "",
          isEnable: 1,
          page: 1,
          limit: 9999,
          key: "",
        },
        (response) => {
          if (response && response.code == 200) {
            this.regionOptions = response.data;
          }
        }
      );
    },
    confirm() {
      if (this.form.description == "") {
        commonUtil.showTipInfo(
          this.$t("other.other.EnterDescription"),
          "",
          "warning"
        );
        return;
      }
      if (this.form.regionId == "") {
        commonUtil.showTipInfo(
          this.$t("other.other.SelectRagion"),
          "",
          "warning"
        );
        return;
      }
      this.$emit("confirmInfo", this.form);
      // this.close()
    },
  },
};
</script>

<style scoped>
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.size-16 {
  font-size: 16px;
}
.padding-9 {
  padding: 9px;
}
.el-row {
  margin-bottom: 10px;
}
.color-red {
  color: red;
}
.align-right {
  text-align: right;
}
</style>