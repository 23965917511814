<template>
  <div class="main">
    <div class="main-top_handle">
      <div class="title">
        {{ $t("homePage.home.UserManagement") }}
      </div>
      <div class="main-handle flex">
        <div class="main-handle-item">
          <input
            type="text"
            v-model="searchForm.key"
            :placeholder="$t('unifiedPrompts.unifiedPrompt.keyword')"
          />
        </div>
        <div class="search-btn" @click="search">
          {{ $t("tcmlists.tcmlist.search") }}
        </div>
      </div>
    </div>
    <div class="handle-btn flex">
      <div
        v-if="BtnLimits.Add"
        class="handle-btn-item"
        style="margin-right: 10px"
        @click="add"
      >
        {{ $t("unifiedPrompts.unifiedPrompt.new") }}
      </div>
    </div>
    <el-row :gutter="10">
      <el-col
        :span="4"
        style="
          width: 250px;
          min-width: 250px;
          max-width: 250px;
          background-color: #fff;
        "
      >
        <div class="tree-cla">
          <div class="tree-cla-search">
            <input
              v-model="filterText"
              :placeholder="$t('unifiedPrompts.unifiedPrompt.keyword')"
            />
          </div>
          <div :style="{ height: TableHeight + 'px' }" class="tree-box">
            <el-tree
              ref="treeRef"
              class="filter-tree custom-el-tree"
              :data="treeData"
              :props="defaultProps"
              default-expand-all
              :filter-node-method="filterNode"
              :highlight-current="true"
              @node-click="handleNodeClick"
            >
            </el-tree>
          </div>
        </div>
      </el-col>
      <el-col :span="20" style="max-width: calc(100% - 250px)">
        <div class="regional_table">
          <el-table
            :empty-text="$t('tcmlists.tcmlist.noData')"
            :data="user_List"
            border
            stripe
            style="width: 100%"
            :height="TableHeight"
            @row-click="RowClick"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              type="selection"
              width="55"
              align="center"
            ></el-table-column>

            <el-table-column
              prop="account"
              :label="$t('userlists.userlist.jobNumber')"
              align="center"
              width="120"
              show-overflow-tooltip
            />
            <el-table-column
              prop="name"
              :label="$t('userlists.userlist.name')"
              align="center"
              width="100"
              show-overflow-tooltip
            />
            <!-- <el-table-column prop="role" :label="$t('userlists.userlist.role')" align="center" /> -->
            <el-table-column
              prop="email"
              :label="$t('userlists.userlist.mailbox')"
              align="center"
              width="250"
              show-overflow-tooltip
            />
            <!-- <el-table-column prop="phone" :label="$t('userlists.userlist.phone')" align="center" /> -->
            <el-table-column
              prop="commodityNames"
              :label="$t('userlists.userlist.commodity')"
              align="center"
              show-overflow-tooltip
            />
            <!-- <el-table-column
              prop="roleNames"
              :label="$t('userlists.userlist.role')"
              align="center"
              show-overflow-tooltip
            /> -->
            <!-- <el-table-column
              prop="region"
              :label="$t('templateLists.templateList.region')"
              align="center"
              show-overflow-tooltip
            /> -->
            <el-table-column
              prop="status"
              :label="$t('userlists.userlist.isEnable')"
              align="center"
              width="100"
            >
              <template #default="scope">
                <el-icon
                  :size="16"
                  color="#40D4A8"
                  v-show="scope.row.status == '0'"
                >
                  <SuccessFilled />
                </el-icon>
                <el-icon
                  :size="16"
                  color="#F56C6C"
                  v-show="scope.row.status == '1'"
                >
                  <CircleCloseFilled />
                </el-icon>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('templateLists.templateList.operate')"
              width="80"
              fixed="right"
              class-name="lastTd"
              align="center"
            >
              <template #default="scope">
                <el-popover placement="bottom" trigger="hover">
                  <template #reference>
                    <div style="text-align: center">
                      <el-icon><MoreFilled /></el-icon>
                    </div>
                  </template>
                  <div class="action-box">
                    <div
                      v-if="BtnLimits.Delete"
                      class="action-btn"
                      @click="del(scope.row.id)"
                    >
                      <el-icon><Delete /></el-icon>
                      <span>
                        {{ $t("unifiedPrompts.unifiedPrompt.delete") }}
                      </span>
                    </div>
                    <div
                      v-if="BtnLimits.Edit"
                      class="action-btn"
                      @click="Edit(scope.row)"
                    >
                      <el-icon><Edit /></el-icon>
                      <span>
                        {{ $t("unifiedPrompts.unifiedPrompt.edit") }}</span
                      >
                    </div>
                    <div
                      v-if="BtnLimits.Permissions"
                      class="action-btn"
                      @click="setting(scope.row)"
                    >
                      <el-icon><Setting /></el-icon>
                      <span style="font-size: 13px">
                        {{ $t("other.other.SetPermissions") }}</span
                      >
                    </div>
                  </div>
                </el-popover>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination">
          <div>
            {{ $t("homePage.home.Showing") }} {{ pageSize }}
            {{ $t("homePage.home.From") }} {{ total }}
            {{ $t("homePage.home.Data") }}
            <el-popover placement="top" :width="70" trigger="click">
              <template #reference>
                <el-icon
                  style="
                    margin-left: 5px;
                    font-size: 17px;
                    vertical-align: middle;
                  "
                  ><Operation
                /></el-icon>
              </template>
              <el-input
                v-model="searchForm.limit"
                @input="(val) => (searchForm.limit = val.replace(/[^\d]/g, ''))"
                :min="1"
                style="width: 130px"
                @change="handleSizeChange"
              >
                <template #suffix> {{ $t("ccls.ccl.barPage") }}</template>
              </el-input>
            </el-popover>
          </div>
          <el-pagination
            layout="prev, pager, next"
            background
            :total="total"
            :page-size="pageSize"
            :current-page="searchForm.page"
            @current-change="handleCurrentChange"
          />
        </div>
      </el-col>
    </el-row>
  </div>
  <AddUser
    ref="addDialog"
    @showMoudleDia="showMoudleDia"
    :regionOptions="regionOptions"
    v-on:handleSubmit="addSubmit"
  ></AddUser>
  <EditUser
    ref="editDialog"
    :regionOptions="regionOptions"
    @showMoudleDia="showMoudleDia"
    v-on:handleSubmit="editSubmit"
  ></EditUser>
  <!-- <MoudleDia ref="moudleDialog"></MoudleDia> -->
</template>
<script>
import commonUtil from "../../assets/js/commonUtil.js";
import AddUser from "./addUser.vue";
import EditUser from "./editUser.vue";
import {
  delUser,
  userList,
  addUser,
  CommodityLsit,
  editUser,
  echoList,
  GetOrgs,
  changepassword,
  echoCommodity,
  echoSelUserRegions,
  SelUserRoles,
  getRegions,
} from "@/assets/js/requestApi.js";
export default {
  components: {
    AddUser,
    EditUser,
  },
  name: "UserList",
  activated() {
    // 在首次挂载、
    // 以及每次从缓存中被重新插入的时候调用
    this.search();
    this.getUnits();
  },
  deactivated() {
    clearTimeout(this.timer);
    // 在从 DOM 上移除、进入缓存
    // 以及组件卸载时调用
  },
  data() {
    return {
      TableHeight: 0,
      searchForm: {
        id: "",
        key: "",
        page: 1, // 当前页
        limit: 10, // 每页个数
      },
      user_List: [],
      total: 0,
      pageSize: 10,
      filterText: "",
      treeData: [],
      defaultProps: {
        children: "children",
        label: "Name",
      },
      timer: "",
      regionOptions: [],
      BtnLimits: {},
    };
  },
  mounted() {
    this.autoHeight();
    this.initList();
    this.getTreeData();
    this.GetRegionData();
  },
  methods: {
    GetRegionData() {
      getRegions(
        {
          Keyword: "",
          isEnable: 1,
          page: 1,
          limit: 9999,
          key: "",
        },
        (response) => {
          if (response && response.code == 200) {
            this.regionOptions = response.data;
          }
        }
      );
    },
    setting(row) {
      const { id, name } = row;
      this.$router.push({
        path: "/funSetting",
        query: {
          id: id,
          name: name,
        },
      });
    },
    RowClick(row) {
      if (this.BtnLimits.Edit) {
        this.Edit(row);
      }
    },
    handleSelectionChange() {},
    autoHeight() {
      // 初始化的时候，设置高度
      this.setHeight();
      // 改变浏览器窗口大小的时候，设置高度
      window.addEventListener("resize", () => {
        this.setHeight();
      });
    },
    setHeight() {
      this.$nextTick(() => {
        this.TableHeight = window.innerHeight - 235; // 这里的X就是顶部栏的高度
      });
    },

    // 渲染列表
    initList() {
      this.searchForm = {
        id: "",
        key: "",
        page: 1, //当前页
        limit: 10, //每页个数
      };
      this.search();
    },
    // 查询
    search() {
      userList(this.searchForm, (response) => {
        if (response && response.code == 200) {
          this.user_List = response.data;
          this.total = response.count;
        }
      });
    },
    // 删除用户
    del(id) {
      delUser((id = [id]), (response) => {
        if (response && response.code == 200) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.deleteSuccessfully"),
            "",
            "success"
          );
          this.search();
        }
      });
    },
    // 新增

    // 少年你和伙伴一起走过时间海哎 依然依然表在
    add() {
      this.$refs.addDialog.visible = true;
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    addSubmit(form) {
      form.sex = parseInt(form.sex);
      form.organizations = form.organizations.toString();
      form.organizationIds = form.organizationIds.toString();
      form.commodityName = form.commodityName.toString();
      form.commodityIds = form.commodityIds.toString();
      form.regionIds = form.regionIds.toString();
      console.log(form.regionIds.toString(), "form.regionIds.toString()");

      addUser(form, (response) => {
        if (response && response.code == 200) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.addedSuccess"),
            "",
            "success"
          );
          this.search();
        }
      });
    },
    // 编辑
    Edit(row) {
      this.$refs.editDialog.visible = true;
      this.$refs.editDialog.getUnits();
      this.$refs.editDialog.form.account = row.account;
      this.$refs.editDialog.form.name = row.name;
      this.$refs.editDialog.form.phone = row.phone;
      this.$refs.editDialog.form.email = row.email;
      this.$refs.editDialog.form.sex = row.sex;
      this.$refs.editDialog.form.password = row.password;
      this.$refs.editDialog.form.status = row.status;
      this.$refs.editDialog.form.id = row.id;
      let organizationIds = [];
      let organizations = [];
      echoList('"' + row.id + '"', (response) => {
        if (response && response.code == 200) {
          for (let i = 0; i < response.data.length; i++) {
            organizationIds.push(response.data[i].id);
            organizations.push(response.data[i].name);
            this.$refs.editDialog.form.organizationIds =
              organizationIds.toString();
            this.$refs.editDialog.form.organizations = organizations.toString();
          }
        }
      });
      let commodityIds = [];
      let commodityName = [];
      echoCommodity('"' + row.id + '"', (response) => {
        if (response && response.code == 200) {
          for (let i = 0; i < response.data.length; i++) {
            commodityIds.push(response.data[i].id);
            commodityName.push(response.data[i].name);
            this.$refs.editDialog.form.commodityIds = commodityIds.toString();
            this.$refs.editDialog.form.commodityName = commodityName.toString();
          }
        }
      });
      let roleId = [];
      let roleName = "";
      SelUserRoles('"' + row.id + '"', (response) => {
        if (response && response.code == 200) {
          for (let i = 0; i < response.data.length; i++) {
            roleId.push(response.data[i].id);
            roleName += response.data[i].name + ",";
          }
          this.$refs.editDialog.form.roleIds = roleId;
          this.$refs.editDialog.form.roleName = roleName;
        }
      });

      let regionIds = [];
      echoSelUserRegions('"' + row.id + '"', (response) => {
        if (response && response.code == 200) {
          for (let i = 0; i < response.data.length; i++) {
            regionIds.push(response.data[i].id);
          }
          this.$refs.editDialog.form.regionIds = regionIds;
        }
      });
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    editSubmit(form) {
      form.sex = parseInt(form.sex);
      form.organizations = form.organizations.toString();
      form.organizationIds = form.organizationIds.toString();
      form.commodityIds = form.commodityIds.toString();
      form.commodityName = form.commodityName.toString();
      form.regionIds = form.regionIds.toString();
      editUser(form, (response) => {
        if (response && response.code == 200) {
          // let password = form.password.toString()
          changepassword(form, (response) => {
            if (response && response.code == 200) {
            }
          });
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.editSuccess"),
            "",
            "success"
          );
          this.search();
        }
      });
    },
    // 分页
    handleCurrentChange(val) {
      this.searchForm.page = val;
      this.search();
    },
    // 分页数量修改
    handleSizeChange() {
      this.pageSize = parseInt(this.searchForm.limit);
      if (this.searchForm.limit === "") {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.setPageNum"),
          "",
          "warning"
        );
        return;
      }
      if (this.searchForm.limit == 0) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.pageThanZero"),
          "",
          "warning"
        );
        return;
      }
      this.searchForm.page = 1;
      this.search();
    },
    // 树转化
    getTreeData() {
      let _this = this;
      GetOrgs(
        {
          type: 0,
          versionType: 0,
          TypeCost: 0,
          isAll: 0,
        },
        (response) => {
          if (response && response.Code == 200) {
            let data = response.Result;
            data.forEach(function (val) {
              val.disabled = val.isEnable == 1 ? false : true;
            });
            this.treeData = _this.transferList(response.Result);
          }
        }
      );
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    // 树点击事件
    handleNodeClick(data) {
      this.searchForm.id = data.Id;
      this.searchForm.name = data.Name;
      this.search();
    },
    // 转化树数据
    transferList(data) {
      const res = [];
      data.forEach((item) => {
        const parent = data.find((node) => node.Id === item.ParentId);
        if (parent) {
          parent.children = parent.children || [];
          parent.children.push(item);
        } else {
          // * 根节点
          res.push(item);
        }
      });
      return res;
    },
    // 显示选择部门弹窗
    showMoudleDia(val) {
      if (val) {
        this.$refs.moudleDialog.visible = true;
      }
    },
    getUnits() {
      this.timer = setInterval(this.gettime, 1000);
    },
    gettime() {
      this.BtnLimits = JSON.parse(sessionStorage.getItem("Btn"));
    },
    // gettime() {
    //   this.power = JSON.parse(localStorage.getItem("power"));
    //   this.power.forEach((e) => {
    //     if (e.name == "添加" || e.name == "Add") {
    //       this.btnAdd = 1;
    //     }
    //     if (e.name == "删除" || e.name == "Delete") {
    //       this.btnDel = 1;
    //     }
    //     if (e.name == "编辑" || e.name == "Edit") {
    //       this.btnEdit = 1;
    //     }
    //     if (e.name == "预览" || e.name == "Preview") {
    //       this.preview = 1;
    //     }
    //   });
    //   clearTimeout(this.timer);
    // },
  },
};
</script>

<style scoped lang="scss">
.main {
  width: 100%;
  height: 100%;
  background: url("../../assets/img/car-table-bg.png") no-repeat center;
  background-size: contain;
}

.main-top_handle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
}
.tree-cla {
  background: #fff;
  .tree-cla-search {
    padding: 10px;
  }
  input {
    display: block;
    width: calc(100% - 10px);
    height: 40px;
    border-radius: 10px;
    background: #f5f7ff;
  }
}
.main-handle {
  align-items: center;
}
.main-handle-item {
  margin-right: 10px;
  flex: 1;
}
.main-handle input,
.main-handle .el-select .el-input {
  width: 100%;
  border-radius: 10px;
  font-size: 14px;
}
.main-handle input::placeholder {
  color: #3e4954;
  text-align: center;
  font-size: 14px;
}
.handle-btn {
  align-items: center;
  height: 40px;
  .handle-btn-item {
    padding: 0 20px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    background: #b2bde7;
    color: #fff;
    border-radius: 25px;
    cursor: pointer;
    margin-left: 10px;
  }
}
.select-box {
  position: relative;
  .select-btn {
    width: 200px;
    height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 10px;
    background: #fff;
    cursor: pointer;
    .Calendar,
    .Switch {
      font-size: 18px;
      color: #2f4cdd;
    }
  }
}
.search-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 30px;
  background: #445581;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
.tree-box {
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>