<template>
  <div style="padding: 15px">
    <div class="Total-MaterialCost">
      <div class="MaterialCost" v-show="type != 'template'">
        <p>{{ $t("changePriceLists.changePriceList.purchaseCost") }}</p>
        <input v-model="purchaseCost_PUR" disabled type="text" />
      </div>
    </div>
    <div class="flex" style="padding: 20px">
      <div class="table-box" id="idname2">
        <table class="add-table">
          <tr>
            <td></td>
            <td
              v-for="(item, index) in formData"
              :key="index"
              style="border-bottom: 1px solid #ccc"
            >
              <div
                class="flex"
                style="
                  position: relative;
                  width: 100%;
                  height: 100%;
                  justify-content: space-evenly;
                  align-items: center;
                "
              >
                <span class="Serial_number">#{{ index + 1 }}</span>
                <img
                  v-show="type != 'template'"
                  src="../../assets/img/tcm/zuojiantou.png"
                  style="width: 23px; height: 23px"
                  @click="reorder('left', index)"
                />
                <img
                  v-show="type != 'template'"
                  src="../../assets/img/tcm/youjiantou.png"
                  style="width: 23px; height: 23px"
                  @click="reorder('right', index)"
                />

                <el-icon
                  v-show="type != 'template' && operateType != 'detail'"
                  :size="18"
                  @click="copyMoudle(index)"
                  ><CopyDocument
                /></el-icon>
                <el-icon
                  v-show="type != 'template' && operateType != 'detail'"
                  :size="18"
                  @click="deleteMoudle(index)"
                  ><DeleteFilled
                /></el-icon>

                <div
                  class="submit-btn"
                  v-show="type == 'template' && operateType != 'detail'"
                  @click="cancle"
                >
                  {{ $t("userlists.userlist.cancel") }}
                </div>
                <div
                  class="submit-btn"
                  v-show="type == 'template' && operateType != 'detail'"
                  @click="confirm"
                >
                  {{ $t("userlists.userlist.confirm") }}
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <span class="color-red">*</span>
              {{ $t("changePriceLists.changePriceList.processDescription") }}
            </td>
            <td v-for="(item, index) in formData" :key="index">
              <el-input
                v-if="type == 'template'"
                v-model="item.processName"
                :disabled="operateType == 'detail'"
              />
              <el-input
                v-else
                v-model="item.name"
                :disabled="operateType == 'detail'"
              />
            </td>
          </tr>
          <tr>
            <td>
              <span class="color-red">*</span>
              {{ $t("changePriceLists.changePriceList.currency") }}
            </td>
            <td v-for="(item, index) in formData" :key="index">
              <el-select
                style="width: 100%"
                clearable
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                filterable
                v-model="item.currencyId"
                placeholder=""
                :disabled="operateType == 'detail' || type != 'template'"
              >
                <el-option
                  v-for="item in CurrencyOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </td>
          </tr>
          <tr v-show="type != 'template'">
            <td>
              <div>
                <span class="color-red">*</span>
                {{ $t("changePriceLists.changePriceList.convertCurrency") }}
              </div>
            </td>
            <td v-for="(item, index) in formData" :key="index">
              <el-select
                style="width: 100%"
                clearable
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                filterable
                v-model="item.convertCurrencyId"
                placeholder=""
                disabled
              >
                <el-option
                  v-for="item in CurrencyOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </td>
          </tr>
          <tr v-show="type != 'template'">
            <td>
              <div>
                <span class="color-red">*</span>
                {{ $t("changePriceLists.changePriceList.exchangeRate") }}
              </div>
            </td>
            <td v-for="(item, index) in formData" :key="index">
              <el-input
                v-model="item.converRate"
                :disabled="operateType == 'detail'"
                @input="
                  transferFormat($event, item, 'converRate'), toCompute(item)
                "
                @change="toCompute(item)"
                @blur="toCompute(item)"
              />
            </td>
          </tr>
          <!-- <template v-show="type == 'template'"> -->
          <tr v-show="operateType != 'add' && type == 'template'">
            <td>
              <span class="color-red">*</span>
              {{ $t("templateLists.templateList.cellNo") }}
            </td>
            <td v-for="(item, index) in formData" :key="index">
              <el-input v-model="item.number" disabled />
            </td>
          </tr>
          <tr v-show="type == 'template'">
            <td>
              <span class="color-red">*</span>
              {{ $t("templateLists.templateList.cellName") }}
            </td>
            <td v-for="(item, index) in formData" :key="index">
              <el-input
                v-model="item.name"
                :disabled="operateType == 'detail'"
              />
            </td>
          </tr>
          <tr v-show="type == 'template'">
            <td>
              <span class="color-red">*</span>
              {{ $t("changePriceLists.changePriceList.commodity") }}
            </td>
            <td v-for="(item, index) in formData" :key="index">
              <el-tree-select
                clearable
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                v-model="item.commodityId"
                append-to-body
                @change="changeCommodityId($event, index)"
                :disabled="operateType == 'detail'"
                :data="CommodityOptions"
                :props="defaultProps"
                check-strictly
                :render-after-expand="false"
                :default-expand-all="true"
              >
                <template #default="{ data: { Name } }">
                  <el-tooltip :content="Name" placement="top" effect="light">
                    {{ Name }}
                  </el-tooltip>
                </template>
              </el-tree-select>
            </td>
          </tr>
          <tr v-show="type == 'template'">
            <td>
              <span class="color-red">*</span>
              {{ $t("templateLists.templateList.region") }}
            </td>
            <td v-for="(item, index) in formData" :key="index">
              <el-select
                style="width: 100%"
                clearable
                v-model="item.regionId"
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                :disabled="operateType == 'detail'"
              >
                <el-option
                  v-for="item in regionOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
                <!-- <el-option
                  v-if="
                    !regionOptions.some((list) => list.id === item.regionId) &&
                    item.regionId != ''
                  "
                  :label="item.regionName"
                  :value="item.regionId"
                /> -->
              </el-select>
            </td>
          </tr>
          <tr v-show="operateType != 'add' && type == 'template'">
            <td>{{ $t("templateLists.templateList.version") }}</td>
            <td v-for="(item, index) in formData" :key="index">
              <el-input v-model="item.version" disabled />
            </td>
          </tr>
          <tr v-show="type == 'template'">
            <td>{{ $t("templateLists.templateList.comments") }}</td>
            <td v-for="(item, index) in formData" :key="index">
              <el-input
                v-model="item.comment"
                :disabled="operateType == 'detail'"
              />
            </td>
          </tr>
          <!-- </template> -->
          <!-- --------- -->

          <tr>
            <td>
              <span class="color-red" v-show="type != 'template'">*</span>
              {{ $t("changePriceLists.changePriceList.consumption") }}
            </td>
            <td v-for="(item, index) in formData" :key="index">
              <el-input
                v-model="item.quantity"
                :disabled="operateType == 'detail'"
                @input="
                  transferFormat($event, item, 'quantity'), toCompute(item)
                "
                @change="toCompute(item)"
                @blur="toCompute(item)"
              />
            </td>
          </tr>

          <tr>
            <td>
              <span class="color-red">*</span>
              {{ $t("changePriceLists.changePriceList.unitMeasure") }}
            </td>
            <td v-for="(item, index) in formData" :key="index">
              <el-select
                style="width: 100%"
                clearable
                filterable
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                v-model="item.unit"
                :disabled="operateType == 'detail'"
              >
                <el-option
                  v-for="it in unitOptions"
                  :key="it.id"
                  :label="it.name"
                  :value="it.id"
                />
              </el-select>
            </td>
          </tr>
          <tr>
            <td>
              <span class="color-red">*</span>
              {{ $t("changePriceLists.changePriceList.costUoM") }}
            </td>
            <td v-for="(item, index) in formData" :key="index">
              <div class="flex" style="align-items: center; width: 100%">
                <el-input
                  v-model="item.unitCost"
                  :disabled="operateType == 'detail'"
                  @input="
                    transferFormat($event, item, 'unitCost'), toCompute(item)
                  "
                  @change="toCompute(item)"
                  @blur="toCompute(item)"
                />
                <img
                  src="../../assets/img/tcm/daikuanjisuanqi.png"
                  v-show="operateType != 'detail'"
                  style="width: 25px; height: 25px; cursor: pointer"
                  @click="openCal(index, 'unitCost')"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td>
              {{ $t("changePriceLists.changePriceList.subTotalCost") }}
            </td>
            <td v-for="(item, index) in formData" :key="index">
              <el-input v-model="item.totalCost" disabled />
            </td>
          </tr>
          <tr>
            <td>{{ $t("changePriceLists.changePriceList.priceReference") }}</td>
            <td v-for="(item, index) in formData" :key="index">
              <div class="flex" style="align-items: center; width: 100%">
                <el-input
                  v-model="item.price"
                  :disabled="operateType == 'detail' || type != 'alloperate'"
                />
                <img
                  src="../../assets/img/tcm/renminbi.png"
                  v-show="operateType != 'detail'"
                  style="width: 25px; height: 25px; cursor: pointer"
                  @click="choosePur(index)"
                />
                <el-icon
                  class="Modify-Price"
                  :size="20"
                  v-if="ShowChangePrice && item.completeApplyId"
                  @click="
                    ShowChangePriceDig(
                      item.completeApplyId,
                      index,
                      item.oldData
                    )
                  "
                  style="color: var(--themeColor)"
                  ><Edit
                /></el-icon>
              </div>
            </td>
          </tr>
          <tr>
            <td>{{ $t("changePriceLists.changePriceList.vendor") }}</td>
            <td v-for="(item, index) in formData" :key="index">
              <el-input
                v-model="item.vendor"
                :disabled="operateType == 'detail'"
              />
            </td>
          </tr>
        </table>
      </div>
      <div
        class="add-column"
        v-show="type != 'template' && operateType != 'detail'"
      >
        <div @click="addMoudle">
          {{ $t("changePriceLists.changePriceList.fromTemplate") }}
        </div>
        <div @click="addEmptyMoudle" style="border-top: 1px solid #ccc">
          {{ $t("changePriceLists.changePriceList.materialInput") }}
        </div>
      </div>
    </div>

    <el-dialog
      v-model="visible"
      width="40%"
      destroy-on-close
      :show-close="false"
    >
      <template #header="{ close, titleId, titleClass }">
        <div class="my-header">
          <div class="size-16">
            {{ $t("changePriceLists.changePriceList.materialInput") }}
          </div>
          <div style="cursor: pointer" @click="visible = false">
            <el-icon :size="20"><Close /></el-icon>
          </div>
        </div>
      </template>
      <div class="Raw-card-main">
        <div class="Raw-card-item">
          <p>
            <span class="color-red">*</span>
            {{ $t("changePriceLists.changePriceList.processDescription") }}
          </p>
          <el-input v-model="AddForm.name" />
        </div>

        <!-- currency -->
        <div class="Raw-card-item" style="width: 100%">
          <p>
            <span class="color-red">*</span>
            {{ $t("changePriceLists.changePriceList.currency") }}
          </p>
          <el-select
            style="width: 100%"
            clearable
            :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
            filterable
            v-model="AddForm.currencyId"
            placeholder=""
            disabled
          >
            <el-option
              v-for="item in CurrencyOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </div>
        <!-- convertCurrency -->
        <div class="Raw-card-item" style="width: 100%">
          <p>
            <span class="color-red">*</span>
            {{ $t("changePriceLists.changePriceList.convertCurrency") }}
          </p>
          <el-select
            clearable
            style="width: 100%"
            :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
            filterable
            v-model="AddForm.convertCurrencyId"
            placeholder=""
            @change="CalcRate"
          >
            <el-option
              v-for="item in CurrencyOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </div>
        <!-- exchangeRate -->
        <div class="Raw-card-item">
          <p>
            <span class="color-red">*</span>
            {{ $t("changePriceLists.changePriceList.exchangeRate") }}
          </p>
          <el-input
            v-model="AddForm.converRate"
            @input="
              transferFormat($event, AddForm, 'converRate'), toCompute(AddForm)
            "
            @change="toCompute(AddForm)"
            @blur="toCompute(AddForm)"
          />
        </div>

        <div class="Raw-card-item">
          <p>
            <span class="color-red">*</span>
            {{ $t("changePriceLists.changePriceList.consumption") }}
          </p>
          <el-input
            v-model="AddForm.quantity"
            :disabled="operateType == 'detail'"
            @input="
              transferFormat($event, AddForm, 'quantity'), toCompute(AddForm)
            "
            @change="toCompute(AddForm)"
            @blur="toCompute(AddForm)"
          />
        </div>
        <div class="Raw-card-item">
          <p>
            <span class="color-red">*</span>

            {{ $t("changePriceLists.changePriceList.unitMeasure") }}
          </p>
          <div class="flex" style="width: 100%">
            <el-select
              clearable
              style="width: 100%"
              filterable
              :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
              v-model="AddForm.unit"
            >
              <el-option
                v-for="it in unitOptions"
                :key="it.id"
                :label="it.name"
                :value="it.id"
              />
            </el-select>
          </div>
        </div>
        <div class="Raw-card-item">
          <p>
            <span class="color-red">*</span>
            {{ $t("changePriceLists.changePriceList.costUoM") }}
          </p>
          <div class="flex" style="align-items: center; width: 100%">
            <el-input
              v-model="AddForm.unitCost"
              :disabled="operateType == 'detail'"
              @input="
                transferFormat($event, AddForm, 'unitCost'), toCompute(AddForm)
              "
              @change="toCompute(AddForm)"
              @blur="toCompute(AddForm)"
            />
            <img
              src="../../assets/img/tcm/daikuanjisuanqi.png"
              v-show="operateType != 'detail'"
              style="width: 25px; height: 25px; cursor: pointer"
              @click="openCal_Add('unitCost')"
            />
          </div>
        </div>
        <!-- materialMeasure -->
        <div class="Raw-card-item">
          <p>
            {{ $t("changePriceLists.changePriceList.subTotalCost") }}
          </p>
          <el-input v-model="AddForm.totalCost" disabled />
        </div>
        <div class="Raw-card-item">
          <p>
            {{ $t("changePriceLists.changePriceList.priceReference") }}
          </p>
          <el-input v-model="AddForm.price" />
        </div>
        <div class="Raw-card-item">
          <p>
            {{ $t("changePriceLists.changePriceList.vendor") }}
          </p>
          <el-input v-model="AddForm.vendor" />
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="visible = false">{{
            $t("userlists.userlist.cancel")
          }}</el-button>
          <el-button
            style="
              background-color: var(--themeColor);
              color: var(--menuTextActive);
              margin-right: 16px;
            "
            @click="addEmptyMoudleSubmit"
            >{{ $t("userlists.userlist.confirm") }}</el-button
          >
        </span>
      </template>
    </el-dialog>

    <PurDia
      ref="purDialog"
      :CommodityId="CommodityId"
      :CurrencyOptions="CurrencyOptions"
      :operateType="operateType"
      @getChoosePurId="getChoosePurId"
    ></PurDia>
    <PriceDia
      ref="priceDialog"
      :CommodityId="type != 'template' ? CommodityId : formData[0].commodityId"
      :CurrencyOptions="CurrencyOptions"
      :operateType="operateType"
      @getChoosePurId="getChoosePurId"
      @getChooseTcm="getChooseTcm"
      @getChooseccl="getChooseccl"
      @getChoosePcba="getChoosePcba"
    ></PriceDia>
    <AddRate ref="rateDialog" @rateSubmit="rateSubmit"></AddRate>
    <CalculatorList
      ref="calList"
      :applyId="applyId"
      type="edit"
      @chooseRow="chooseRow"
      @submitPlastic="submitPlastic"
    ></CalculatorList>
    <PreviewDia
      ref="preDia"
      type="template"
      :formulaPre="outform.formulaPre"
      :previewResultx="previewResultx"
      :fileListUrl="fileListUrl"
      :intermediateBondList="intermediateBondListPreview"
      :valueList="valueList"
      @tocompute="tocomputeCal"
      @toConfirm="toConfirm"
    ></PreviewDia>
  </div>
</template>

<script>
import {
  getCategorys,
  getTemplatePurchasesDetail,
  getMultiRate,
  GetApplyDetail,
  GetCalculatorDetail,
  InputParameterAnalysis,
} from "../../assets/js/requestApi.js";
import commonUtil from "../../assets/js/commonUtil.js";
import PurDia from "../purManagement/purDia.vue";
import PriceDia from "../purManagement/priceDia.vue";
import AddRate from "./addRate.vue";
import CalculatorList from "../processCalculatorManagement/calculatorListDia.vue";
import PreviewDia from "../processCalculatorManagement/previewDia.vue";
// import ChangePrice from "./ChangePrice.vue";
export default {
  props: [
    "type",
    "operateType",
    "CommodityId",
    "CommodityName",
    "CommodityOptions",
    "regionOptions",
    "CurrencyOptions",
    "currencyId",
    "applyId",
    "ShowChangePrice",
  ],
  emits: ["goBack", "confirmData"],
  components: {
    CalculatorList,
    PreviewDia,
    PurDia,
    PriceDia,
    AddRate,
    // ChangePrice,
  },
  data() {
    return {
      purchaseCost_PUR: "",
      formData: [
        {
          id: "",
          number: "",
          name: "",
          version: "",
          vendor: "",
          comment: "",
          isEnable: 1,
          processName: "",
          quantity: "1",
          unit: "",
          unitCost: "0",
          totalCost: "",
          price: "",
          commodityId: "",
          regionId: "",
          currencyId: "",
          convertCurrencyId: "",
          converRate: "",
          calculateData: "",
        },
      ],
      // 单位选项
      unitOptions: [],
      // 商品分类显示字段
      defaultProps: {
        children: "children",
        label: "Name",
      },
      // 编辑时删除的组件id
      purchaseDelIds: [],
      // 操作数据下标
      operateIndex: "",
      // 选择的组件税率转化数组
      addCellExchangeList: [],
      // 选择的组件数据
      addCellList: [],
      // 弹窗操作类型
      diaType: "add",
      outform: {
        name: "",
        unit: "",
        formula: "",
        formulaVal: "",
        formulaPre: "",
      },
      // 符号
      symbol: ["+", "-", "*", "/", "sum", "if", "(", ")"],
      // 计算结果
      previewResultx: 0,
      // 打开计算器输入框类型
      calType: "input",
      // 中间键列表
      intermediateBondList: [],
      // 数值列表
      valueList: [],
      // 计算后中间键列表
      intermediateBondListPreview: [],
      // 当前操作数据下标
      operateIndex: "",
      // 当前选择的工艺计算器id
      operateCalId: "",
      // 计算调用参数
      computeNum: 0,
      ShowChangePriceDigFlag: false,
      ChangePriceId: "",
      visible: false,
      AddForm: {},
      fileListUrl: [],
    };
  },
  mounted() {
    this.getUnit();
  },
  watch: {
    computeNum: {
      handler(nval, oval) {
        if (nval == this.intermediateBondListPreview.length) {
          this.$refs.preDia.visible = true;
        }
      },
      deep: true,
    },
  },
  methods: {
    ShowChangePriceDig(id, index, oldData) {
      this.$parent.ShowChangePriceDig(id, this.formData[index].oldData);
      this.diaType = "choosePrice";
      this.operateIndex = index;
    },
    // 获取单位选项
    getUnit() {
      getCategorys(
        {
          TypeID: "MeasureUnit",
          Keyword: "",
          page: 1,
          limit: 9999,
          key: "",
        },
        (response) => {
          if (response && response.code == 200) {
            this.unitOptions = response.data;
          }
        }
      );
    },
    // 取消
    cancle() {
      this.$emit("goBack");
    },
    // 提交
    confirm() {
      this.formData[0].convertCurrencyId = this.formData[0].currencyId;
      this.formData[0].converRate = "1";
      this.$emit("confirmData", this.formData);
    },
    // 添加组件
    addMoudle() {
      if (
        this.CommodityId == "" ||
        this.CommodityId == null ||
        !this.CommodityId
      ) {
        commonUtil.showTipInfo(
          this.$t("templateLists.templateList.commodityRequired"),
          "",
          "warning"
        );
        return;
      }
      if (
        this.currencyId == "" ||
        this.currencyId == null ||
        !this.currencyId
      ) {
        commonUtil.showTipInfo(
          this.$t("templateLists.templateList.currencyRequired"),
          "",
          "warning"
        );
        return;
      }
      this.diaType = "add";
      this.$refs.purDialog.searchForm.page = 1;
      this.$refs.purDialog.getlist();
      this.$refs.purDialog.visible = true;
    },
    // 添加空组件
    addEmptyMoudle() {
      this.visible = true;
      this.AddForm = {
        id: "",
        number: "",
        name: "",
        version: "",
        vendor: "",
        comment: "",
        isEnable: 1,
        processName: "",
        quantity: "1",
        unit: "",
        unitCost: "0",
        totalCost: "",
        price: "",
        commodityId: "",
        regionId: "",
        currencyId: this.currencyId ? this.currencyId : "",
        convertCurrencyId: this.currencyId ? this.currencyId : "",
        converRate: this.currencyId ? "1" : "",
        exchangeRate: this.currencyId ? "1" : "",
        calculateData: "",
      };
    },

    addEmptyMoudleSubmit() {
      this.formData.push(this.AddForm);
      this.visible = false;
      this.$nextTick(() => {
        let outDiv = document.getElementById("idname2");
        const scrollLeft = this.formData.length * 221 + 201;
        outDiv.scrollTo(scrollLeft, 0);
        this.formData.forEach((val, index) => {
          this.toCompute(val);
          // _this.purchaseCost_PUR += parseFloat(val.totalCost == "" ? 0 : val.totalCost);
        });
      });
    },
    // 获取添加组件的详情信息
    getChoosePurId(id) {
      let _this = this;
      getTemplatePurchasesDetail(
        {
          id: id,
        },
        (response) => {
          if (response && response.code == 200) {
            // this.CommodityId = response.result.commodityId
            // let cLines = []
            // response.result.cLines.forEach(function(val, index) {
            // 	cLines.push({
            // 		fieldName: val.paramName,
            // 		calculatorTableId: val.calculator_TableId
            // 	})
            // })
            if (this.diaType == "add") {
              this.getMultiRates(response.result);
              // response.result.forEach(function (resVal, resIndex) {
              //     let moudle = resVal;
              //     moudle.id = "";
              //     // moudle.cLines = cLines
              //     _this.formData.push(moudle);
              // });
              // _this.purchaseCost_PUR = 0;
              // this.formData.forEach(function (val, index) {
              //     _this.purchaseCost_PUR += parseFloat(val.totalCost == "" ? 0 : val.totalCost);
              // });
              // _this.purchaseCost_PUR = _this.purchaseCost_PUR.toFixed(3);
            } else if (this.diaType == "choosePrice") {
              this.getMultiRates(response.result);
              // this.formData[this.operateIndex].price = response.result[0].name
              // this.formData[this.operateIndex].unitCost = response.result[0].unitCost
              // this.toCompute(this.formData[this.operateIndex])
              this.$refs.priceDialog.visible = false;
            }
            this.$refs.purDialog.visible = false;
          }
        }
      );
    },
    // 获取选择已完成的cbd(参考价格)
    getChooseTcm(id) {
      this.ChangePriceId = id[0];
      GetApplyDetail(id, (response) => {
        if (response && response.code == 200) {
          this.getMultiRates(response.result, "tcm");
          this.$refs.priceDialog.visible = false;
        }
      });
    },
    // 获取ccl(参考价格)
    getChooseccl(row) {
      this.formData[this.operateIndex].completeApplyId = "";
      this.formData[this.operateIndex].price = row.cclSize_Code;
      this.formData[this.operateIndex].unitCost = row.price_pc;
      this.toCompute(this.formData[this.operateIndex]);
      this.$refs.priceDialog.visible = false;
    },
    // 获取pcba(参考价格)
    getChoosePcba(row) {
      if (!row.extCurrencyId) {
        commonUtil.showTipInfo(
          this.$t("templateLists.templateList.SelectErrMsg"),
          "",
          "warning"
        );
        return false;
      }
      this.formData[this.operateIndex].completeApplyId = "";
      this.formData[this.operateIndex].price = row.eBOMPN;
      this.formData[this.operateIndex].unitCost = row.extTotalPrice;
      // 24-4.30 选择pcba时带出formcurrency

      this.toCompute(this.formData[this.operateIndex]);
      // this.$refs.priceDialog.visible = false;

      const currencyId = this.formData[this.operateIndex].currencyId;
      // 新增4.30
      getMultiRate(
        {
          genList: [
            {
              id: "",
              currId: currencyId,
              currCNYId: row.extCurrencyId,
            },
          ],
        },
        (response) => {
          if (response && response.code == 200) {
            this.formData[this.operateIndex].convertCurrencyId =
              response.data[0].exchangeList[0].currencyCNYReportCurrency;
            this.formData[this.operateIndex].converRate =
              response.data[0].exchangeList[0].exchangeRate;
          }
          this.$refs.priceDialog.visible = false;
        }
      );

      //
    },
    // 获取选择组件税率对应关系
    getMultiRates(arr, type, currIdNum) {
      if (arr[0].oldData) {
        this.formData[this.operateIndex].oldData = arr[0].oldData;
      }
      let _this = this;
      let multiRow = [];
      arr.forEach(function (resVal, resIndex) {
        let moudle = resVal;
        // moudle.id  = ''
        moudle.currencyId = _this.currencyId;
        moudle.currId =
          type == "tcm" && moudle.exchangeRate
            ? moudle.exchangeRate.currency
            : moudle.convertCurrencyId;
        moudle.currCNYId =
          _this.type != "template"
            ? _this.currencyId
            : _this.formData[_this.operateIndex].currencyId;
        moudle.exchangeRate = "";
        // moudle.exchangeRate = ;
        // 修改之前
        // if (type == "tcm") {
        //   moudle.name = resVal.programName;
        //   moudle.unitCost = resVal.totalPriceCurr_SUM;
        //   moudle.completeApplyId = resVal.id;
        // }

        if (type == "tcm") {
          // 87da3317-4bf6-458c-aa09-b411309746a2
          moudle.name = resVal.partNumber;
          moudle.unitCost = resVal.totalPriceCurr_SUM;
          moudle.completeApplyId = resVal.id;
        }
        if (currIdNum) moudle.currId = currIdNum;
        moudle.calculateData = "";
        multiRow.push(moudle);
      });

      _this.addCellList = multiRow;
      this.$refs.purDialog.visible = false;
      getMultiRate(
        {
          genList: multiRow,
        },
        (response) => {
          if (response && response.code == 200) {
            _this.addCellExchangeList = [];
            let untaxedNum = 0;
            _this.addCellExchangeList = response.data[0].exchangeList;
            _this.addCellExchangeList.forEach(function (excVal, excIndex) {
              if (excVal.exchangeRate === "") {
                excVal.hasRate = false;
                untaxedNum++;
              } else {
                excVal.hasRate = true;
              }
            });
            // 如果其中有无税率的情况则弹窗输入
            if (untaxedNum > 0) {
              _this.$refs.rateDialog.addCellExchangeList =
                _this.addCellExchangeList;
              _this.$refs.rateDialog.visible = true;
            } else {
              // 如果税率都有则转换
              _this.$refs.rateDialog.visible = false;
              _this.addCellExchangeList.forEach(function (rateVal, rateIndex) {
                _this.addCellList.forEach(function (cellVal, cellIndex) {
                  if (rateVal.id == cellVal.id) {
                    cellVal.exchangeRate = rateVal.exchangeRate;
                    cellVal.converRate = rateVal.exchangeRate;
                    // 新增
                    cellVal.convertCurrencyId = rateVal.currencyId;
                    // --
                    cellVal.unitCost =
                      parseFloat(
                        cellVal.unitCost === "" ||
                          cellVal.unitCost === null ||
                          !cellVal.unitCost
                          ? "0"
                          : cellVal.unitCost
                      ) *
                      (parseFloat(cellVal.exchangeRate) === 0
                        ? 1
                        : parseFloat(cellVal.exchangeRate));
                    cellVal.totalCost =
                      parseFloat(
                        cellVal.totalCost === "" ||
                          cellVal.totalCost === null ||
                          !cellVal.totalCost
                          ? "0"
                          : cellVal.totalCost
                      ) *
                      (parseFloat(cellVal.exchangeRate) === 0
                        ? 1
                        : parseFloat(cellVal.exchangeRate));
                  }
                });
              });
              if (this.diaType == "add") {
                this.addCellTransfer();
              } else if (this.diaType == "choosePrice") {
                this.changePriceRefrence();
              }

              // _this.addCellTransfer()
            }
          }
        }
      );
    },
    // 填写完税率后获取并且转化数据
    rateSubmit(rateList) {
      // rateList 包含汇率 和货币id
      let _this = this;
      rateList.forEach(function (rateVal, rateIndex) {
        _this.addCellList.forEach(function (cellVal, cellIndex) {
          if (rateVal.id == cellVal.id) {
            // 新增
            cellVal.convertCurrencyId = rateVal.currencyId;
            // -
            cellVal.exchangeRate = rateVal.exchangeRate;
            cellVal.converRate = rateVal.exchangeRate;
            cellVal.unitCost =
              parseFloat(
                cellVal.unitCost === "" ||
                  cellVal.unitCost === null ||
                  !cellVal.unitCost
                  ? "0"
                  : cellVal.unitCost
              ) *
              (parseFloat(cellVal.exchangeRate) === 0
                ? 1
                : parseFloat(cellVal.exchangeRate));
            cellVal.totalCost =
              parseFloat(
                cellVal.totalCost === "" ||
                  cellVal.totalCost === null ||
                  !cellVal.totalCost
                  ? "0"
                  : cellVal.totalCost
              ) *
              (parseFloat(cellVal.exchangeRate) === 0
                ? 1
                : parseFloat(cellVal.exchangeRate));
          }
        });
      });
      _this.$refs.rateDialog.visible = false;

      if (this.diaType == "add") {
        this.addCellTransfer();
      } else if (this.diaType == "choosePrice") {
        this.changePriceRefrence();
      }
    },
    // 添加转化税率后的组件并重新计算
    addCellTransfer() {
      let _this = this;
      _this.addCellList.forEach(function (resVal, resIndex) {
        resVal.id = "";
        _this.formData.push(resVal);
      });
      // _this.purchaseCost_PUR = 0;
      this.formData.forEach(function (val, index) {
        _this.toCompute(val);
        // _this.purchaseCost_PUR += parseFloat(val.totalCost == "" ? 0 : val.totalCost);
      });
      // _this.purchaseCost_PUR = _this.purchaseCost_PUR.toFixed(3);
    },
    // 添加转化税率后的参考价格
    changePriceRefrence() {
      // todo 缺少form curreny 字段
      this.formData[this.operateIndex].completeApplyId = "";
      if (this.addCellList.length > 0) {
        if (this.addCellList[0].completeApplyId) {
          this.formData[this.operateIndex].completeApplyId =
            this.addCellList[0].completeApplyId;
        }
        // 新增
        this.formData[this.operateIndex].convertCurrencyId = this.addCellList[0]
          .convertCurrencyId
          ? this.addCellList[0].convertCurrencyId
          : this.currencyId;
        this.formData[this.operateIndex].exchangeRate =
          this.addCellList[0].exchangeRate;
        this.formData[this.operateIndex].converRate =
          this.addCellList[0].converRate;

        this.formData[this.operateIndex].price = this.addCellList[0].name;
        this.formData[this.operateIndex].unitCost =
          this.addCellList[0].unitCost;
        // 新增
        this.toCompute(this.formData[this.operateIndex]);
        // --
      }
    },
    // 删除组件
    deleteMoudle(index) {
      if (this.purchaseDelIds.indexOf(this.formData[index].id) > -1) {
        this.purchaseDelIds.splice(
          this.purchaseDelIds.indexOf(this.formData[index].id),
          1
        );
      } else {
        this.purchaseDelIds.push(this.formData[index].id);
      }
      this.formData.splice(index, 1);
      if (this.formData.length > 0) {
        this.toCompute(this.formData[0]);
      } else {
        this.purchaseCost_PUR = 0;
        if (this.type != "template") {
          this.$parent.$refs.proTcm.toComputeAll();
          this.$parent.$refs.ohTcm.tocompute();
        }
      }
    },
    // 复制组件
    copyMoudle(index) {
      let data = JSON.parse(JSON.stringify(this.formData[index]));
      data.id = "";
      this.formData.push(data);
      if (this.formData.length > 0) {
        this.toCompute(this.formData[0]);
      }
      this.$nextTick(() => {
        let outDiv = document.getElementById("idname2");
        const scrollLeft = this.formData.length * 221 + 201;
        outDiv.scrollTo(scrollLeft, 0);
      });
    },
    // 计算
    toCompute(row) {
      let _this = this;
      if (
        row.unitCost === "" ||
        row.quantity === "" ||
        row.unitCost === null ||
        row.quantity === null
      ) {
        return;
      }
      row.totalCost = (
        parseFloat(row.quantity) * parseFloat(row.unitCost)
      ).toFixed(12);
      _this.purchaseCost_PUR = 0;
      this.formData.forEach(function (val, index) {
        _this.purchaseCost_PUR += parseFloat(
          val.totalCost === "" || val.totalCost === null ? 0 : val.totalCost
        );
      });
      _this.purchaseCost_PUR = _this.purchaseCost_PUR.toFixed(12);
      if (_this.type != "template") {
        _this.$parent.$refs.proTcm.toComputeAll();
        _this.$parent.$refs.ohTcm.tocompute();
      }
    },
    // 修改分类
    changeCommodityId(val, index) {
      this.formData[index].commodityId = val;
    },
    // 切换顺序
    reorder(arrow, index) {
      if (index == 0 && arrow == "left") {
        commonUtil.showTipInfo(
          this.$t("templateLists.templateList.firstPlace"),
          "",
          "warning"
        );
        return;
      }
      if (index == this.formData.length - 1 && arrow == "right") {
        commonUtil.showTipInfo(
          this.$t("templateLists.templateList.endPlace"),
          "",
          "warning"
        );
        return;
      }
      if (arrow == "left") {
        [this.formData[index - 1], this.formData[index]] = [
          this.formData[index],
          this.formData[index - 1],
        ];
        this.$forceUpdate();
      } else if (arrow == "right") {
        [this.formData[index + 1], this.formData[index]] = [
          this.formData[index],
          this.formData[index + 1],
        ];
        this.$forceUpdate();
      }
    },
    // 选择价格
    choosePur(index) {
      if (this.formData[index].currencyId === "") {
        commonUtil.showTipInfo(
          this.$t("templateLists.templateList.currencyRequired"),
          "",
          "warning"
        );
        return;
      }
      if (this.type == "all") {
        if (this.CommodityId === "") {
          commonUtil.showTipInfo(
            this.$t("templateLists.templateList.commodityRequired"),
            "",
            "warning"
          );
          return;
        }
      }
      if (this.type == "template") {
        if (this.formData[index].commodityId === "") {
          commonUtil.showTipInfo(
            this.$t("templateLists.templateList.commodityRequired"),
            "",
            "warning"
          );
          return;
        }
      }
      if (this.type == "alloperate") {
        if (
          this.$parent.$refs.gen.form.commodityId === "" ||
          this.$parent.$refs.gen.form.commodityId === null
        ) {
          commonUtil.showTipInfo(
            this.$t("templateLists.templateList.commodityRequired"),
            "",
            "warning"
          );
          return;
        }
      }
      this.diaType = "choosePrice";
      this.operateIndex = index;
      this.$refs.priceDialog.activeName = "second";
      this.$refs.priceDialog.cellSearchForm.page = 1;
      this.$refs.priceDialog.getcellList();
      this.$refs.priceDialog.tcmSearchForm.page = 1;
      this.$refs.priceDialog.getTcmList();
      this.$refs.priceDialog.cclSearchForm.page = 1;
      this.$refs.priceDialog.getCclList();
      this.$refs.priceDialog.pcbaSearchForm.page = 1;
      this.$refs.priceDialog.getPcbaList();
      this.$refs.priceDialog.visible = true;
      // this.$refs.purDialog.searchForm.page = 1;
      // this.$refs.purDialog.getlist();
      // this.$refs.purDialog.visible = true;
    },
    // 限制
    transferFormat(val, item, name) {
      item[name] = val.replace(/^\D*(\d*(?:\.\d{0,12})?).*$/g, "$1");
      if (name === "converRate" && item[name] !== "") {
        if (this.type != "template" && this.currencyId === "") {
          commonUtil.showTipInfo(
            this.$t("templateLists.templateList.currencyRequired"),
            "",
            "warning"
          );
          return;
        }
        let exchangeList = item.exchangeRate.split(".");
        let converRateList = item[name].split(".");
        item.unitCost =
          (parseFloat(
            item.unitCost === "" || item.unitCost === null || !item.unitCost
              ? "0"
              : item.unitCost
          ) /
            parseFloat(
              item.exchangeRate === 0 ||
                item.exchangeRate === "0" ||
                (exchangeList.length == 2 && exchangeList[1] == "")
                ? 1
                : item.exchangeRate
            )) *
          (parseFloat(item[name]) === 0 ||
          (converRateList.length == 2 && converRateList[1] == "")
            ? 1
            : parseFloat(item[name]));
        item.totalCost =
          (parseFloat(
            item.totalCost === "" || item.totalCost === null || !item.totalCost
              ? "0"
              : item.totalCost
          ) /
            parseFloat(
              item.exchangeRate === 0 ||
                item.exchangeRate === "0" ||
                (exchangeList.length == 2 && exchangeList[1] == "")
                ? 1
                : item.exchangeRate
            )) *
          (parseFloat(item[name]) === 0 ||
          (converRateList.length == 2 && converRateList[1] == "")
            ? 1
            : parseFloat(item[name]));
        item.exchangeRate = item[name];
      }
      if (name === "unitCost") {
        item.calculateData = "";
      }
    },
    // 打开计算器
    openCal(index, name) {
      let _this = this;
      if (this.operateType == "detail") {
        return;
      }
      // if (this.formData[index].netMWeight == '') {
      // 	commonUtil.showTipInfo('请输入Net Material Weight！', '', 'warning')
      // 	return;
      // }
      this.operateIndex = index;
      this.$refs.calList.operateName = name;
      let form = this.formData[index].calculateData
        ? JSON.parse(this.formData[index].calculateData)
        : [];

      this.$refs.calList.getTreeData();
      this.$refs.calList.visible = true;
      if (form.length > 0) {
        this.$refs.calList.getlist(1);
        form.forEach(function (val, index) {
          if (val.operateName == name) {
            _this.$refs.calList.setData(val);
          }
        });
      } else {
        this.$refs.calList.getlist(2);
        this.$refs.calList.activeName = "first";
        this.$refs.calList.activeRadio = "tonnage";
      }
    },
    // 打开计算器
    openCal_Add(name) {
      let _this = this;
      // if (this.formData[index].netMWeight == '') {
      // 	commonUtil.showTipInfo('请输入Net Material Weight！', '', 'warning')
      // 	return;
      // }
      // this.operateIndex = index;
      this.$refs.calList.operateName = name;
      let form = this.AddForm.calculateData
        ? JSON.parse(this.AddForm.calculateData)
        : [];

      this.$refs.calList.getTreeData();
      this.$refs.calList.visible = true;
      if (form.length > 0) {
        this.$refs.calList.getlist(1);
        form.forEach(function (val, index) {
          if (val.operateName == name) {
            _this.$refs.calList.setData(val);
          }
        });
      } else {
        this.$refs.calList.getlist(2);
        this.$refs.calList.activeName = "first";
        this.$refs.calList.activeRadio = "tonnage";
      }
    },
    // 获取选择的工艺计算器
    chooseRow(row) {
      this.operateCalId = row.id;
      this.getDetail(row.id);
    },
    // 获取选择的plastic的值
    submitPlastic(value, form) {
      this.$refs.calList.visible = false;
      this.formData[this.operateIndex].unitCost = value;
      if (value !== "") {
        if (this.formData[this.operateIndex].calculateData === "") {
          let calculateData = [];
          calculateData.push(form);
          this.formData[this.operateIndex].calculateData =
            JSON.stringify(calculateData);
        } else {
          let calculateData = JSON.parse(
            this.formData[this.operateIndex].calculateData
          );
          let newCalculateData = [];
          // 是否包含当前数据
          let isContain = false;
          let containIndex = "";
          calculateData.forEach(function (val, index) {
            newCalculateData.push(val);
            if (val.operateName == form.operateName) {
              isContain = true;
              containIndex = index;
            }
          });
          if (isContain) {
            newCalculateData[containIndex] = form;
          } else {
            newCalculateData.push(form);
          }
          this.formData[this.operateIndex].calculateData =
            JSON.stringify(newCalculateData);
        }
      }

      this.toCompute(this.formData[this.operateIndex]);
    },
    // 根据id获取选择的工艺计算器详情
    getDetail(id) {
      let _this = this;
      this.computeNum = 0;
      GetCalculatorDetail('"' + id + '"', (response) => {
        if (response && response.code == 200) {
          this.form = {
            id: response.data[0].id,
            process_Calculator_Code: response.data[0].process_Calculator_Code,
            name: response.data[0].process_Calculator_Name,
            commodityId: response.data[0].commodityId,
            commodityName: response.data[0].commodList[0].name,
            version: response.data[0].version,
            comments: response.data[0].comments,
          };
          _this.intermediateBondList = [];
          _this.valueList = [];
          this.tableData = response.data[0].edit_List;
          this.tableData.forEach(function (val, key) {
            val.formulaVal = val.formulaPre
              ? val.formulaPre.replace(/\|/g, "")
              : "";
            if (val.formula != "") {
              _this.intermediateBondList.push(val);
            } else {
              _this.valueList.push(val);
            }
          });
          this.intermediateBondListPreview = JSON.parse(
            JSON.stringify(this.intermediateBondList)
          );
          this.outform = {
            name: response.data[0].outputName,
            unit: response.data[0].outputUnit,
            formula: response.data[0].outputFormulaPre.replace(/\|/g, ""),
            formulaVal: response.data[0].outputFormula,
            formulaPre: response.data[0].outputFormulaPre,
          };
          this.fileListx = response.data[0].pictureList;
          // 计算默认值
          let list = [];
          this.valueList.forEach(function (val, key) {
            list.push({
              paramValue: val.thedefaultvalue,
              paramentName: val.parameterName,
            });
          });
          this.intermediateBondList.forEach(function (val, key) {
            list.push({
              paramValue: val.formulaPre,
              paramentName: val.parameterName,
            });
          });

          this.intermediateBondListPreview.forEach(function (val, key) {
            // val.formulaPre
            let data = {
              outputFormula: val.formulaPre,
              inputParameterList: list,
            };
            InputParameterAnalysis(data, (response) => {
              _this.computeNum++;
              if (response && response.code == 200) {
                val.thedefaultvalue = response.data;
              }
            });
          });
          this.previewResultx = 0;
          if (this.intermediateBondListPreview.length == 0) {
            this.$refs.preDia.visible = true;
          }
        }
      });
    },
    // 计算结果
    tocomputeCal(val) {
      InputParameterAnalysis(val, (response) => {
        if (response && response.code == 200) {
          this.previewResultx = response.data;
          this.$refs.preDia.visible = true;
        }
      });
    },
    // 获取最后计算的值
    toConfirm(val) {
      this.$refs.calList.visible = false;
      this.formData[this.operateIndex].unitCost = val;
      this.formData[this.operateIndex].cLines = [];
      this.formData[this.operateIndex].cLines.push({
        fieldName: "GrossMWeight",
        calculatorTableId: this.operateCalId,
      });
      this.toCompute(this.formData[this.operateIndex]);
    },
    // 计算汇率
    CalcRate(val) {
      getMultiRate(
        {
          genList: [
            {
              id: "",
              currId: this.AddForm.currencyId,
              currCNYId: val,
            },
          ],
        },
        (response) => {
          if (response && response.code == 200) {
            this.AddForm.converRate =
              response.data[0].exchangeList[0].exchangeRate;
            // this.transferFormat(
            //   this.AddForm.converRate,
            //   this.AddForm,
            //   "converRate"
            // );
            // this.toComputeCost(this.AddForm);

            this.transferFormat(
              this.AddForm.converRate,
              this.AddForm,
              "converRate"
            );
            this.toCompute(this.AddForm);
          }
        }
      );
    },
  },
};
</script>

<style scoped lang="scss">
.bottom-box {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.handle-btn {
  color: #fff;
  width: 200px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #2f4cdd;
  border-radius: 10px;
  cursor: pointer;
}
.Raw-box {
  display: flex;
  flex-wrap: wrap;
}
.Raw-card {
  margin-right: 15px;
  margin-top: 15px;
  padding: 10px 35px 0px 20px;
  width: 30%;
  border-radius: 10px;
  color: #363b64;
  background: #f2f3f5;
  // background: linear-gradient(0deg, #f4f7ff, #f4f7ff),
  //   linear-gradient(94.84deg, #fff 3.78%, #fcfdff 9.75%, #e6eeff 102.09%),
  //   linear-gradient(126.58deg, #fff -18.12%, hsla(0, 0%, 100%, 0) 26.77%);
  .Raw-top {
    padding-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    font-size: 15px;
    .Raw-title {
      display: flex;
      align-items: center;
    }
    .el-icon {
      font-size: 18px;
      font-weight: 600;
      color: #a098ae;
    }
  }
  .Raw-edit-btn {
    margin-bottom: -20px;
    text-align: right;
    font-size: 20px;
  }
  .Raw-card-main {
    padding: 10px 20px 10px;
    width: 100%;
    height: 600px;
    overflow-y: scroll;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.8) !important;
    p {
      padding: 10px 0 10px 4px;
      font-weight: 600;
      color: #363b64;
    }
    input {
      width: 80%;
      background: #f6f6f6;
      border: 1px solid #718ebf;
      border-radius: 10px;
    }
  }
}
.Raw-card-item {
  display: flex;
  margin-bottom: 10px;
  p {
    width: 220px;
    font-size: 14px;
  }
  .el-icon {
    margin-left: 10px;
    font-size: 18px;
    font-weight: 600;
    color: #a098ae;
  }
  img {
    display: inline-block;
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
}
.Total-MaterialCost {
  display: flex;
  justify-content: flex-end;
  color: #363b64;
  font-weight: 600;
  font-size: 15px;
  .MaterialCost {
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  input {
    width: 200px;
    font-size: 14px;
    background: #f6f6f6;
    border: 1px solid #718ebf;
    border-radius: 10px;
  }
}
.submit-btn {
  margin-left: 5px;
  padding: 0 10px;
  height: 20px;
  line-height: 20px;
  background: #b2bde7;
  color: #fff;
  text-align: center;
  border-radius: 20px;
  cursor: pointer;
}

.tcm-form-solid {
  position: relative;
  height: 600px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  border: 1px dashed #c0c4cc;
}
.add-cla-template {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.add-table {
  max-width: 100%;
  border-collapse: collapse;
  // background: #f5f7fa;
}
.add-table td {
  // border: 1px solid #dcdfe6;
  border-right: 1px solid #dcdfe6;
  padding: 0px 5px;
  text-align: center;
}
.btn-text {
  color: var(--themeColor);
  cursor: pointer;
  font-size: 12px;
}
:deep .el-input__wrapper {
  background: transparent;
}

.table-box {
  overflow-x: scroll;
}
table {
  border: 1px solid #ccc;
  border-right: none;
  flex-shrink: 0;
}
table tr {
  display: flex;
  text-align: center;
}
table td {
  display: flex;
  align-items: center;
  width: 210px;
  height: 30px;
  word-wrap: break-word;
  word-break: break-all;
}

table td:nth-child(1) {
  width: 190px;
  font-size: 13px !important;
  text-align: center;
}

.add-column {
  flex-shrink: 0;
  width: 100px;
  height: 75px;
  line-height: 37px;
  border: 1px solid #dcdfe6;
  border-left: none;
  text-align: center;
  cursor: pointer;
}

table tr > td:first-child,
table tr > td:first-child {
  position: sticky;
  left: 0;
  z-index: 88;
  background-color: #f5f7fa;
  font-size: 15px;
  text-align: left;
}
.Serial_number {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 17px;
  font-weight: 600;
  color: #445581;
}

.el-input,
/deep/ .el-select .el-input__inner {
  height: 25px !important;
}

.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.size-16 {
  font-size: 16px;
}
</style>