import { createStore } from 'vuex'

export default createStore({
	state: {
		// HomePage
		keepAliveList: [
			'HomePage',
			'Tcmlist',
			'OperateList',
			'DraftList',
			'TcmCC',
			'tcmdatalist',
			'ProList',
			'PurList',
			'RawList',
			'importList',
			'ProcessCalculatorManagement',
			"allTonnageList",
			"CCLsize",
			"ctList",
			"UserList",
			"modulelist",
			"TypeList",
			"RegionList",
			"RateList",
			"CurrencyList",
			"commoditylist",
			"RoleList",
			"TemplateList",
			"ChangePriceList",
			"DepartmentManagement",
			"equimentList",
			"ProcessIndirect",
			"CCLsizeDetail",
			"OperationDetailsList",
			"gradelist",
			"CalculatedDatelist",
			"yesPriceList",
			"noPriceList",
		],
		hasLogin: true,
		ChangePriceId: ''
	},
	mutations: {
		// 添加缓存路由组件
		ADD_KEEP_ALIVE(state, route) {
			console.log(state)
			// if (state.keepAliveList.includes(route.name)) {
			// 	return
			// }
			// state.keepAliveList.push(route.name)
			console.log('add 缓存' + route.name)
		},
		// 移除缓存路由组件
		DEL_KEEP_ALIVE(state, route) {
			console.log(state, route)
			// if (route) {
			// 	const index = state.keepAliveList.indexOf(route.name)
			// 	if (index > -1) {
			// 		// state.keepAliveList.splice(index, 1)
			// 		console.log('del 缓存' + route.name)
			// 	}
			// }
		},
		// 清除缓存路由组件
		CLEAR_KEEP_ALIVE(state) {
			state.keepAliveList = ['HomePage']
		}
	},
	actions: {
	},
	modules: {
	}
})

