const info = {
	// 中文
	ZH: {
		new: '新增',
		edit: '修改',
		delete: '删除',
		import: '导入',
		export: '导出',
		downTemplate: '下载模板',
		cancle: '取消',
		confirm: '确定',
		search: '搜索',
		operate: '操作',
		nexteerPart: '耐世特零件',
		PCBCalculator: '计算器',
		manufacturingPart: '制造零件',
		deviceType: '设备类型',
		status: '状态',
		supplierName: '供应商名称',
		alternateOption: '替代零件项',
		type: '类型',
		compDescription: '详细的组件描述',
		currency: '货币',
		otherInfo: '其他信息',
		ppapComplete: '完成情况',
		ppapDueDate: '到期时间',
		note: '说明',
		no: 'No',
		year: '年',
		price: '价格',
		noPN: '子零件编号',
		manufactureName: '制造商名称',
		nexteerDirected: '耐世特直控',
		description: '描述',
		highPrice: 'EMS最高价',
		mediumPrice: 'EMS中等价格',
		lowPrice: 'EMS最低价格',
		sharath: '耐世特价格',
		remark: '备注',
		pleseEnterMain: '请先维护主表信息！',
		copy: "复制",
		ManufactoryPartNum: '制造零件号'

	},
	// 英文
	EN: {
		new: 'New',
		edit: 'Edit',
		delete: 'Delete',
		import: 'Import',
		export: 'Export',
		downTemplate: 'Download Template',
		cancle: 'Cancel',
		confirm: 'Confirm',
		search: 'Search',
		operate: 'Operate',
		nexteerPart: 'Nexteer Part',
		PCBCalculator: 'PCB Calculator',
		manufacturingPart: 'Manufacturing Part',
		deviceType: 'Device Type',
		status: 'Status',
		supplierName: 'Supplier Name',
		alternateOption: 'Alternate Option',
		type: 'Type',
		compDescription: 'Detailed Comp Description',
		currency: 'Currency',
		otherInfo: 'Other Info',
		ppapComplete: 'PPAP Complete',
		ppapDueDate: 'PPAP Due Date',
		note: 'Notes',
		no: 'No',
		year: 'Year',
		price: 'Price',
		noPN: 'Sub-Component PN',
		manufactureName: 'Manufacture Name',
		nexteerDirected: 'Nexteer Directed',
		description: 'Description',
		highPrice: 'EMS Highest Price',
		mediumPrice: 'EMS Medium Price',
		lowPrice: 'EMS Lowest Price',
		sharath: 'Nexteer Price',
		remark: 'Remark',

		pleseEnterMain: 'Please maintain the main table information first!',
		copy: "Copy",
		ManufactoryPartNum: 'MPN(Manufactory Part Number)'

	}
}
export default info