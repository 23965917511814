<template>
  <div class="tcm-add">
    <div class="top-box">
      <div class="crumbs-box">
        <div class="crumbs-box-item parent" @click="Goback">
          {{ $t("homePage.home.Back") }}
        </div>
        <div class="line"></div>
        <div class="crumbs-box-item" v-for="(item, index) in crumbs">
          {{ item }}
          <el-icon v-show="index != crumbs.length - 1"><ArrowRight /></el-icon>
        </div>
      </div>
      <div class="step-menubox">
        <StepMenu
          :MenuData="MenuData"
          :chooseCurrent="chooseCurrent"
          @chooseMenu="chooseMenu"
        ></StepMenu>
      </div>
      <div class="tcm-title" v-show="!previewShow">
        <div>
          <span class="mgr-20" v-show="operateType != 'add'"
            >{{ $t("changePriceLists.changePriceList.document") }} :
            {{ documentNo }}</span
          >
          <span class="mgr-20" v-show="operateType != 'add'"
            >{{ $t("changePriceLists.changePriceList.date") }} :{{
              doDate
            }}</span
          >
          <span>
            {{ $t("changePriceLists.changePriceList.totalPrice") }}:
          </span>
          <span class="total-price"
            >{{ exchangeRateUnit }}
            {{
              parseFloat(
                totalSellPrice === "" || totalSellPrice === null
                  ? 0
                  : totalSellPrice
              ).toFixed(3)
            }}</span
          >
        </div>
        <div>
          <el-button
            @click="chooseDraft"
            style="
              background-color: var(--themeColor);
              color: var(--menuTextActive);
            "
            >{{
              $t("changePriceLists.changePriceList.selectOwerTemplate")
            }}</el-button
          >
          <el-button
            style="
              background-color: var(--themeColor);
              color: var(--menuTextActive);
            "
            @click="chooseTemplate"
            >{{
              $t("changePriceLists.changePriceList.selectTemplate")
            }}</el-button
          >
          <el-button
            style="
              background-color: var(--themeColor);
              color: var(--menuTextActive);
            "
            @click="chooseCompleted"
          >
            {{ $t("changePriceLists.changePriceList.selectCompleted") }}
          </el-button>
          <!-- <el-button color="#335883" v-show="operateType != 'detail' && operateType != 'detailx'" @click="chooseDraft">Select Draft</el-button> -->
        </div>
      </div>
    </div>
    <div class="main">
      <TcmGen
        v-show="chooseCurrent == 'gen' && !previewShow"
        ref="gen"
        :type="operateType"
        operateType="operation"
        :QuoteTypeOptions="typeOptions"
        :RegionOptions="userRegionOptions"
        :CommodityOptions="CommodityOptions"
        :urgencyOptions="urgencyOptions"
        :urgencyOptionss="urgencyOptionss"
        :currencyOptions="currencyOptions"
        :currencyReportOptions="currencyReportOptions"
      ></TcmGen>
      <TcmRm
        v-show="chooseCurrent == 'rm' && !previewShow"
        ref="rwTcm"
        type="alloperate"
        operateType="edit"
        :CurrencyOptions="allCurrencyOptions"
        :CommodityId="CommodityId"
        :currencyId="currencyId"
        :applyId="id"
      ></TcmRm>
      <TcmPur
        v-show="chooseCurrent == 'pur' && !previewShow"
        ref="purTcm"
        type="alloperate"
        :ShowChangePrice="true"
        @GetDetail="getDetail"
        operateType="edit"
        :CurrencyOptions="allCurrencyOptions"
        :CommodityId="CommodityId"
        :currencyId="currencyId"
        :applyId="id"
      ></TcmPur>
      <TcmPro
        v-show="chooseCurrent == 'pro' && !previewShow"
        ref="proTcm"
        type="alloperate"
        operateType="edit"
        :CurrencyOptions="allCurrencyOptions"
        :CommodityId="CommodityId"
        :currencyId="currencyId"
        :applyId="id"
      ></TcmPro>
      <TcmPl
        v-show="chooseCurrent == 'p&l' && !previewShow"
        ref="plTcm"
        type="all"
        operateType="edit"
      ></TcmPl>
      <TcmOh
        v-show="chooseCurrent == 'oh' && !previewShow"
        ref="ohTcm"
        type="all"
        operateType="edit"
      ></TcmOh>
      <TcmSum
        v-show="chooseCurrent == 'sum' && !previewShow"
        ref="sumTcm"
        operateType="edit"
        :tcmFlag="true"
        :exchangeRateUnit="exchangeRateUnit"
        :reportExchangeRateUnit="reportExchangeRateUnit"
        :ReportCurrencyOptions="currencyReportOptions"
        @cancle="cancle"
        @saveTemplate="openTemplate"
        @saveDraft="saveDraft"
        @previewTemplate="previewTemplate"
      ></TcmSum>
      <CompletedDia
        ref="completedDialog"
        :CommodityOptions="userCommodityOptions"
        :currencyId="currencyId"
        @selectCompleted="selectCompleted"
      ></CompletedDia>
      <TemplateDia
        ref="templateDialog"
        :CommodityOptions="userCommodityOptions"
        :regionOptions="userRegionOptions"
        :isdraft="isdraft"
        :CurrencyOptions="currencyOptions"
        @selectTemplate="selectTemplate"
      ></TemplateDia>
      <TemplateInfo
        ref="temInfo"
        :saveType="saveType"
        :regionOptions="userRegionOptions"
        @confirmInfo="saveTemplate"
      ></TemplateInfo>
      <PreviewInfo
        ref="previewCla"
        :previewData="previewData"
        :noShowEmail="true"
        v-show="previewShow"
        :operateType="operateType"
        :exchangeRateUnit="exchangeRateUnit"
        :reportExchangeRateUnit="reportExchangeRateUnit"
        @canclePreview="canclePreview"
        @submitPreview="submitPreview"
      ></PreviewInfo>
      <AddRate ref="rateDialog" @rateSubmit="rateSubmit"></AddRate>
      <ChangePrice
        ref="ChangePrice"
        operateType="edit"
        @changePrice="ReceiveData"
      ></ChangePrice>
    </div>
  </div>
</template>

<script>
import StepMenu from "@/components/stepMenu/index.vue";
// import TcmGen from "../tcmApplication/tcmgen.vue";
import TcmGen from "../tcmApplication/editGen.vue";

import TcmRm from "../tcmTemplateManagement/tcmRm.vue";
import TcmPur from "../tcmTemplateManagement/tcmPur.vue";
import TcmPro from "../tcmTemplateManagement/tcmPro.vue";
import TcmPl from "../tcmTemplateManagement/tcmPL.vue";
import TcmOh from "../tcmTemplateManagement/tcmOh.vue";
import TcmSum from "../tcmOperation/tcmSum.vue";

import TemplateDia from "../tcmOperation/templateDia.vue";
import CompletedDia from "../tcmOperation/completedDia.vue";
import TemplateInfo from "../tcmOperation/templateInfo.vue";
import PreviewInfo from "../tcmOperation/previewInfo.vue";
import AddRate from "../tcmTemplateManagement/addRate.vue";
import ChangePrice from "../tcmTemplateManagement/ChangePrice.vue";
import {
  getQuoteTypes,
  getRegions,
  GetOrgs,
  getCategorys,
  GetApplyDetail,
  GetSelCurrency,
  UpdateApplyStatus,
  GetTemplateOrDraftDetail,
  AddOrUpdateTemplateApplys,
  TCMDataEdit,
  uploadFile,
  getMultiRate,
  loadCurrency,
  AddOrUpdateForAppllicant,
  UpdateApplyForStart,
  UpdateCostApplyForChangePriceReq,
} from "../../assets/js/requestApi.js";
import commonUtil from "../../assets/js/commonUtil.js";
export default {
  components: {
    TcmGen,
    TcmRm,
    TcmPur,
    TcmPro,
    TcmPl,
    TcmOh,
    TcmSum,
    TemplateDia,
    TemplateInfo,
    PreviewInfo,
    CompletedDia,
    AddRate,
    ChangePrice,
    StepMenu,
  },
  data() {
    return {
      disabled: false,
      chooseCurrent: "gen",
      id: "",
      type: "add",
      operateType: "detail",
      // 报价类型选项
      typeOptions: [],
      // 区域选项
      regionOptions: [],
      // 全部商品分类选项
      CommodityOptions: [],
      // 当前用户权限对应的商品分类选项
      userCommodityOptions: [],
      // 等级选项
      urgencyOptions: [],
      urgencyOptionss: [],
      // 税率表区域选项
      currencyOptions: [],
      currencyReportOptions: [],
      // gen对应的币种
      currencyId: "",
      // 商品分类id
      CommodityId: "",
      // 保存类型(模板或草稿)
      saveType: "template",
      // 选择的模板id
      templateId: "",
      // 预览页面是否显示
      previewShow: false,
      // 预览数据
      previewData: {},
      // 文件上传后对应的列表
      pictureList: [],
      uploadStatus: false,
      // gen文件上传后对应列表
      pictureListGen: [],
      uploadStatusGen: false,
      // 最终汇率单位
      exchangeRateUnit: "",
      reportExchangeRateUnit: "",
      // 打开弹窗是否为草稿
      isdraft: 0,
      totalSellPrice: 0,
      // 操作时间
      doDate: "",
      // 序号
      documentNo: "",
      // 选择的组件税率转化数组
      addCellExchangeList: [],
      // 选择的组件数据
      addCellList: [],
      // 所有货币选项
      allCurrencyOptions: [],
      submitType: "",
      // 保存草稿传值
      draftData: {},
      // 保存模板传值
      templateData: {},
      ChangePriceData: {},
      ChangePriceType: null,
      CompleteApplyId: null,
      crumbs: [],
      MenuData: [],
      userRegionOptions: [],
    };
  },
  mounted() {
    this.ChangeLan();
    this.$route.meta.showTag = true;
    this.getQuoteType();
    // this.getRegion();
    // 获取用户Region
    this.userRegionOptions = JSON.parse(sessionStorage.getItem("Region"));
    this.getCommdity();
    // this.getUserCommodity();
    this.userCommodityOptions = JSON.parse(sessionStorage.getItem("Commodity"));
    this.getCategorysOptions();
    this.getCategorysOptionss();
    this.getCurrencyOptions();
    this.getAllCurrencyOptions();
    this.operateType = this.$route.query.type;
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getDetail();
    }
    if (this.$route.query.disabled) {
      this.disabled = this.$route.query.disabled;
    }
  },
  watch: {
    uploadStatus: {
      handler(nval, oval) {
        if (nval) {
          if (this.submitType == "submitAll") {
            this.submit();
          } else if (this.submitType == "submitDraft") {
            this.submitDraft();
          } else if (this.submitType == "submitTemplate") {
            this.submitTemplate();
          }
        }
      },
      deep: true,
    },
    uploadStatusGen: {
      handler(nval, oval) {
        if (nval) {
          this.submitGen();
        }
      },
      deep: true,
    },
    "$i18n.locale"() {
      this.ChangeLan();
    },
  },
  methods: {
    ChangeLan() {
      this.crumbs = [
        this.$t("homePage.home.TCMData"),
        this.$t("unifiedPrompts.unifiedPrompt.edit"),
      ];
      this.MenuData = [
        {
          key: "gen",
          lan: this.$t("homePage.home.Gen"),
        },
        {
          key: "rm",
          lan: this.$t("homePage.home.Rm"),
        },
        {
          key: "pur",
          lan: this.$t("homePage.home.PUR"),
        },
        {
          key: "pro",
          lan: this.$t("homePage.home.PRO"),
        },
        {
          key: "p&l",
          lan: "P&L",
          lan: this.$t("homePage.home.Pl"),
        },
        {
          key: "oh",
          lan: "OH",
        },
        {
          key: "sum",
          lan: this.$t("homePage.home.CostSummary"),
        },
      ];
    },
    chooseMenu(val) {
      this.chooseCurrent = val;
    },

    ReceiveData(data, oldData) {
      data[0].oldData = oldData;
      this.$refs.purTcm.getMultiRates(data, "tcm", data[0].currId);
    },
    ShowChangePriceDig(id, oldData) {
      if (oldData) {
        UpdateCostApplyForChangePriceReq(oldData, (response) => {
          if (response && response.code == 200) {
            this.$refs.ChangePrice.GetChangePriceData(
              response.result[0],
              1,
              oldData
            );
          }
        });
      } else {
        this.CompleteApplyId = id;
        this.$refs.ChangePrice.GetChangePriceId(id, 1);
      }
    },
    chooseMenu(val) {
      this.chooseCurrent = val;
    },
    chooseTemplate() {
      this.$refs.templateDialog.searchForm = {
        date: "",
        Enable: 1,
        StartDate: "",
        EndDate: "",
        status: "",
        Keyword: "",
        regionId: "",
        CommodityId: "",
        currencyId: "",
        isDraft: 0,
        VersionType: 1,
        page: 1,
        limit: 10,
      };
      this.$refs.templateDialog.getlist();
      this.$refs.templateDialog.visible = true;
    },
    chooseCompleted() {
      this.$refs.completedDialog.getlist();
      this.$refs.completedDialog.visible = true;
    },
    chooseDraft() {
      this.$refs.templateDialog.searchForm = {
        date: "",
        Enable: 1,
        StartDate: "",
        EndDate: "",
        status: "",
        Keyword: "",
        regionId: "",
        CommodityId: "",
        currencyId: "",
        isDraft: 1,
        VersionType: 1,
        page: 1,
        limit: 10,
      };
      this.$refs.templateDialog.getlist();
      this.$refs.templateDialog.visible = true;
    },
    // 获取报价类型选项
    getQuoteType() {
      getQuoteTypes(
        {
          Keyword: "",
          isEnable: 1,
          page: 1,
          limit: 9999,
          key: "",
        },
        (response) => {
          if (response && response.code == 200) {
            this.typeOptions = response.data;
          }
        }
      );
    },
    // 获取区域列表选项
    getRegion() {
      getRegions(
        {
          Keyword: "",
          isEnable: 1,
          page: 1,
          limit: 9999,
          key: "",
        },
        (response) => {
          if (response && response.code == 200) {
            this.regionOptions = response.data;
          }
        }
      );
    },
    // 查询所有货币
    getAllCurrencyOptions() {
      loadCurrency(
        {
          IsOrderNumber: 0,
          Keyword: "",
          page: 1, //当前页
          limit: 99999, //每页个数
        },
        (response) => {
          if (response && response.code == 200) {
            this.allCurrencyOptions = response.data;
          }
        }
      );
    },
    // 获取商品分类数据
    getCommdity() {
      GetOrgs(
        {
          type: 0,
          versionType: 0,
          TypeCost: 0,
          isAll: 1,
        },
        (response) => {
          if (response && response.Code == 200) {
            this.CommodityOptions = commonUtil.transferList(response.Result);
          }
        }
      );
    },
    // 获取用户对应的商品分类选项
    // getUserCommodity() {
    //   let _this = this;
    //   GetOrgs(
    //     {
    //       type: 0,
    //       versionType: 0,
    //       TypeCost: 0,
    //       isAll: 0,
    //     },
    //     (response) => {
    //       if (response && response.Code == 200) {
    //         this.userCommodityOptions = commonUtil.transferList(
    //           response.Result
    //         );
    //       }
    //     }
    //   );
    // },
    // 获取等级选项
    getCategorysOptions() {
      getCategorys(
        {
          TypeID: "ApplyUrgency",
          Keyword: "",
          page: 1,
          limit: 9999,
          key: "",
        },
        (response) => {
          if (response && response.code == 200) {
            this.urgencyOptions = response.data;
          }
        }
      );
    },
    getCategorysOptionss() {
      getCategorys(
        {
          TypeID: "ApplyUrgency",
          Keyword: "",
          page: 1,
          limit: 9999,
          key: "",
        },
        (response) => {
          if (response && response.code == 200) {
            response.data.forEach((e) => {
              e.name = e.description;
            });
            this.urgencyOptionss = response.data;
          }
        }
      );
    },
    // 查询税率表区域选项
    getCurrencyOptions() {
      GetSelCurrency(
        {
          CurrId: "",
          CurrCNYId: "",
          isEnable: 1,
        },
        (response) => {
          if (response && response.code == 200) {
            this.currencyOptions = response.data[0].exchangeList;
            this.currencyReportOptions = response.data[0].exchangeCNYList;
            this.$refs.gen.genCurrOptions[0] = {
              currencyOptions: this.currencyOptions,
              currencyReportOptions: this.currencyReportOptions,
            };
          } else {
            commonUtil.showTipInfo(
              this.$t("userlists.userlist.inqueryTimeout")
            );
          }
        }
      );
    },
    // 获取详情
    getDetail(id) {
      let _this = this;
      let ids = [];
      id ? ids.push(id) : ids.push(this.id);
      //   ids.push(this.id);
      GetApplyDetail(ids, (response) => {
        if (response && response.code == 200) {
          let data = response.result[0];
          this.documentNo = data.documentNo;
          this.doDate = data.doDate;
          this.$refs.gen.form.quoteTypeId = data.quoteTypeId;
          this.$refs.gen.form.programName = data.programName;
          this.$refs.gen.form.ecrno = data.ecrno;
          this.$refs.gen.form.commodityId = data.commodityId;
          this.CommodityId = data.commodityId;
          this.$refs.gen.form.partDescription = data.partDescription;
          this.$refs.gen.form.partNumber = data.partNumber;
          this.$refs.gen.form.partRevision = data.partRevision;
          this.$refs.gen.form.lotSize =
            (data.lotSize === null || data.lotSize === "") &&
            data.productionYear !== "" &&
            data.productionYear !== null &&
            parseFloat(data.productionYear) != 0 &&
            data.peakVolume !== "" &&
            data.peakVolume !== null
              ? parseFloat(data.peakVolume.toString().replace(/,/g, "")) /
                data.productionYear
              : data.lotSize;
          this.$refs.proTcm.pieceLotNew =
            this.$refs.gen.form.lotSize === null ||
            this.$refs.gen.form.lotSize === ""
              ? ""
              : this.$refs.gen.form.lotSize.toFixed(12);
          this.$refs.proTcm.annualVolumeNew =
            data.peakVolume === null || data.peakVolume === ""
              ? ""
              : data.peakVolume.toString();
          this.$refs.gen.form.productionYear = data.productionYear;
          this.$refs.gen.form.doDate = data.doDate;
          if (this.operateType == "editImport") {
            this.$refs.gen.form.approvedTime = data.approvedTime;
          }
          this.$refs.gen.genList[0].comment = data.comment;
          this.$refs.gen.form.urgencyId = data.urgencyId;
          if (data.exchangeRate != null) {
            this.$refs.gen.genList[0].Currency = data.exchangeRate.currency;
            this.$refs.gen.genList[0].ReportCurrency =
              data.exchangeRate.currencyCNYReportCurrency;
            this.$refs.gen.genList[0].rate = data.rate;
            this.$refs.gen.genList[0].currencyRateId = data.exchangeRate.id;
            this.exchangeRateUnit = data.exchangeRate.currencySymbolName;
            this.reportExchangeRateUnit =
              data.exchangeRate.reportCurrencySymbolName;
            this.currencyId = data.exchangeRate.currency;
          }
          // this.$refs.gen.genList[0].Currency = data.exchangeRate.currency
          // this.$refs.gen.genList[0].ReportCurrency = data.exchangeRate.currencyCNYReportCurrency
          // this.$refs.gen.genList[0].rate = data.rate
          // this.$refs.gen.genList[0].currencyRateId = data.exchangeRate.id
          // this.exchangeRateUnit = data.exchangeRate.currencySymbolName
          // this.reportExchangeRateUnit = data.exchangeRate.reportCurrencySymbolName
          this.$refs.gen.genList[0].regionId = data.regionId;
          this.$refs.gen.genList[0].regionName = data.regionName;
          this.$refs.gen.genList[0].supplierName = data.supplierName;
          this.$refs.gen.genList[0].materialDescription =
            data.materialDescription;
          this.$refs.gen.genList[0].peakVolume =
            !data.peakVolume || data.peakVolume === null
              ? ""
              : commonUtil.format_with_regex(data.peakVolume.toString());
          this.$refs.gen.genList[0].lifetimeVolume =
            !data.lifetimeVolume || data.lifetimeVolume === null
              ? ""
              : commonUtil.format_with_regex(data.lifetimeVolume.toString());
          this.$refs.gen.genList[0].pegPoint = data.pegPoint;

          this.$refs.gen.genList[0].yearVolumeList = data.yearVolumeList;
          if (this.$refs.gen.genList[0].yearVolumeList.length) {
            this.$refs.gen.genList[0].yearVolumeList.forEach(function (
              val,
              index
            ) {
              val.volume = commonUtil.format_with_regex(val.volume);
            });
          }
          this.$refs.gen.mailTable = data.mailList;
          this.$refs.gen.form.mailComment = data.mailComment;
          /*zj 2023.9.14 新增字段 status*/
          this.$refs.gen.status = data.status;
          let applicationList = [];
          let operateList = [];
          data.attachmentList.forEach((val, index) => {
            if (val.applyType == 1) {
              operateList.push(val);
            } else {
              applicationList.push(val);
            }
          });
          console.log(
            applicationList,
            "applicationListapplicationListapplicationListapplicationList"
          );

          this.$refs.gen.fileListx = applicationList;
          this.$refs.ohTcm.form.sga =
            data.sga !== "" || data.sga !== null ? data.sga : "";
          this.$refs.ohTcm.form.sgaCost = data.sgaCost ? data.sgaCost : "";
          this.$refs.ohTcm.form.materialProfit =
            data.materialProfit !== "" || data.materialProfit !== null
              ? data.materialProfit
              : "";
          this.$refs.ohTcm.form.addProfit =
            data.addProfit !== "" || data.addProfit !== null
              ? data.addProfit
              : "";
          this.$refs.ohTcm.form.purchaseProfit =
            data.purchaseProfit !== "" || data.purchaseProfit !== null
              ? data.purchaseProfit
              : "";
          this.$refs.ohTcm.form.totalProfit = data.totalProfit
            ? data.totalProfit
            : "";
          this.$refs.ohTcm.form.totalProfitCost = data.totalProfitCost
            ? data.totalProfitCost
            : "";
          this.$refs.ohTcm.form.materialPC =
            data.materialPC !== "" || data.materialPC !== null
              ? data.materialPC
              : "";

          this.$refs.rwTcm.materialCost_RM = data.materialCost_RM
            ? data.materialCost_RM
            : "";

          if (data.materialList.length > 0) {
            data.materialList.forEach(function (val, index) {
              let cLines = [];
              val.cLines.forEach(function (va, ind) {
                cLines.push({
                  fieldName: va.paramName,
                  calculatorTableId: va.calculator_TableId,
                });
              });
              val.cLines = cLines;
              if (
                val.convertCurrencyId === "" ||
                val.convertCurrencyId === null
              ) {
                val.convertCurrencyId = _this.$refs.gen.genList[0].Currency;
                val.currencyId = _this.$refs.gen.genList[0].Currency;
                val.converRate = "1";
                val.exchangeRate = "1";
              } else {
                val.exchangeRate = val.converRate;
              }

              // val.id = ''
            });
            this.$refs.rwTcm.formData = data.materialList;
            this.$refs.rwTcm.formData.forEach(function (value, indexNum) {
              _this.$refs.rwTcm.toComputeCost(value);
            });
          } else {
            this.$refs.rwTcm.formData.forEach(function (val, index) {
              if (val.converRate === "") {
                val.convertCurrencyId = _this.$refs.gen.genList[0].Currency;
                val.converRate = "1";
                val.exchangeRate = "1";
              }
              val.currencyId = _this.$refs.gen.genList[0].Currency;
            });

            this.$refs.rwTcm.formData = data.materialList;
          }
          this.$refs.rwTcm.materialDelIds = [];

          // this.$refs.rwTcm.materialfileList = data.attachmentList
          this.$refs.purTcm.purchaseCost_PUR = data.purchaseCost_PUR
            ? data.purchaseCost_PUR
            : "";
          if (data.purchaseList.length > 0) {
            data.purchaseList.forEach(function (val, index) {
              if (
                val.convertCurrencyId === "" ||
                val.convertCurrencyId === null
              ) {
                val.convertCurrencyId = _this.$refs.gen.genList[0].Currency;
                val.currencyId = _this.$refs.gen.genList[0].Currency;
                val.converRate = "1";
                val.exchangeRate = "1";
              } else {
                val.exchangeRate = val.converRate;
              }
              // val.id = ''
            });
            this.$refs.purTcm.formData = data.purchaseList;
            this.$refs.purTcm.formData.forEach(function (value, indexNum) {
              value.ParentId = value.id;
              _this.$refs.purTcm.toCompute(value);
            });
          } else {
            if (this.$refs.purTcm.formData.length) {
              this.$refs.purTcm.formData.forEach(function (val, index) {
                if (val.converRate === "") {
                  val.convertCurrencyId = _this.$refs.gen.genList[0].Currency;
                  val.converRate = "1";
                  val.exchangeRate = "1";
                }
                val.ParentId = val.id;
                val.currencyId = _this.$refs.gen.genList[0].Currency;
              });
            }
            this.$refs.purTcm.formData = data.purchaseList;
          }
          this.$refs.purTcm.purchaseDelIds = [];

          this.$refs.proTcm.laborCost_PRO = data.laborCost_PRO
            ? data.laborCost_PRO
            : "";
          this.$refs.proTcm.setUpCost_PRO = data.setUpCost_PRO
            ? data.setUpCost_PRO
            : "";
          this.$refs.proTcm.burdenCost_PRO = data.burdenCost_PRO
            ? data.burdenCost_PRO
            : "";
          this.$refs.proTcm.manufacturingCost_PRO = data.manufacturingCost_PRO
            ? data.manufacturingCost_PRO
            : 0;
          if (data.processList.length > 0) {
            data.processList.forEach(function (val, index) {
              let cLines = [];
              val.cLines.forEach(function (va, ind) {
                cLines.push({
                  fieldName: va.paramName,
                  calculatorTableId: va.calculator_TableId,
                });
              });
              val.cLines = cLines;
              if (
                val.convertCurrencyId === "" ||
                val.convertCurrencyId === null
              ) {
                val.convertCurrencyId = _this.$refs.gen.genList[0].Currency;
                val.currencyId = _this.$refs.gen.genList[0].Currency;
                val.converRate = "1";
                val.exchangeRate = "1";
              } else {
                val.exchangeRate = val.converRate;
              }
              val.descShow = false;
              val.setShow = false;
              val.opeShow = true;
              // val.id = ''
            });
            this.$refs.proTcm.formData = data.processList;
            this.$refs.proTcm.formData.forEach(function (value, indexNum) {
              _this.$refs.proTcm.toComputeAll(value);
            });
          } else {
            this.$refs.proTcm.formData.forEach(function (val, index) {
              if (val.converRate === "") {
                val.convertCurrencyId = _this.$refs.gen.genList[0].Currency;
                val.converRate = "1";
                val.exchangeRate = "1";
              }
              val.currencyId = _this.$refs.gen.genList[0].Currency;
            });
            this.$refs.proTcm.formData = data.processList;
          }
          this.$refs.proTcm.processDelIds = [];

          this.$refs.plTcm.form.calculationMethod = data.calculationMethod
            ? data.calculationMethod
            : "";
          this.$refs.plTcm.form.calculationMethod_Freight =
            data.calculationMethod_Freight !== null
              ? data.calculationMethod_Freight
              : 1;
          this.$refs.plTcm.form.calculationMethod_Package =
            data.calculationMethod_Package !== null
              ? data.calculationMethod_Package
              : 1;
          this.$refs.plTcm.form.calculationMethod_Taxes =
            data.calculationMethod_Taxes !== null
              ? data.calculationMethod_Taxes
              : 1;
          this.$refs.plTcm.form.calculationMethod_Ware =
            data.calculationMethod_Ware !== null
              ? data.calculationMethod_Ware
              : 1;
          this.$refs.plTcm.form.freightCost =
            data.freightCost !== null ? data.freightCost : "";
          this.$refs.plTcm.form.wareHouseCost =
            data.wareHouseCost !== null ? data.wareHouseCost : "";
          this.$refs.plTcm.form.packageCost =
            data.packageCost !== null ? data.packageCost : "";
          this.$refs.plTcm.form.otherCost =
            data.otherCost !== null ? data.otherCost : "";
          this.$refs.plTcm.form.amortizationDescription =
            data.amortizationDescription !== null
              ? data.amortizationDescription
              : "";
          this.$refs.plTcm.form.totalPieces =
            data.totalPieces !== null ? data.totalPieces : "";
          this.$refs.plTcm.form.totalAmortize =
            data.totalAmortize !== null ? data.totalAmortize : "";
          this.$refs.plTcm.form.amortizeCost =
            data.amortizeCost !== null ? data.amortizeCost : "";

          this.$refs.sumTcm.form.materialCost_SUM = data.materialCost_SUM;
          this.$refs.sumTcm.form.setUpCost_SUM = data.setUpCost_SUM;
          this.$refs.sumTcm.form.purchaseCost_SUM = data.purchaseCost_SUM;
          this.$refs.sumTcm.form.packageCost_SUM = data.packageCost_SUM;
          this.$refs.sumTcm.form.laborCost_SUM = data.laborCost_SUM;
          this.$refs.sumTcm.form.otherCost_SUM = data.otherCost_SUM;
          this.$refs.sumTcm.form.burdenCost_SUM = data.burdenCost_SUM;
          this.$refs.sumTcm.form.amortizeCost_SUM = data.amortizeCost_SUM;
          this.$refs.sumTcm.form.sgaProfit_SUM = data.sgaProfit_SUM;
          this.$refs.sumTcm.form.scrapCost_SUM = data.scrapCost_SUM;
          this.$refs.sumTcm.form.totalPriceCurr_SUM = data.totalPriceCurr_SUM;
          this.$refs.sumTcm.form.totalPriceReport_SUM =
            data.totalPriceReport_SUM;

          this.$refs.rwTcm.materialfileList = operateList;
          this.totalSellPrice = data.totalPriceCurr_SUM
            ? data.totalPriceCurr_SUM
            : 0;
          this.$refs.plTcm.tocompute();
          this.$refs.sumTcm.form.manufacturingCost_PRO =
            this.$refs.proTcm.manufacturingCost_PRO;
        }
      });
    },
    // 更改申请状态
    updateStatus(status) {
      let data2 = {};
      let data = {};
      data.id = this.id;
      data2.id = this.id;
      data2.status = status;

      if (data2.status == 1) data2.flowstatus = 3;
      data2.nodeId = "2";

      if (status == 1) {
        //更新为status状态时，需要传入参数
        data.currencyRateId = this.$refs.gen.genList[0].currencyRateId;
        data.rate = this.$refs.gen.genList[0].rate;
        data.regionId = this.$refs.gen.genList[0].regionId;
        data.supplierName = this.$refs.gen.genList[0].supplierName;
        data.materialDescription =
          this.$refs.gen.genList[0].materialDescription;
        var that = this;
        UpdateApplyForStart(data, (response) => {
          if (response && response.code == 200) {
            UpdateApplyStatus(data2, (response2) => {
              if (response2 && response2.code == 200) {
                that.operateType = "edit";
                that.getDetail();
              }
            });
          }
        });
      } else {
        UpdateApplyStatus(data2, (response2) => {
          if (response2 && response2.code == 200) {
            that.operateType = "edit";
          }
        });
      }
    },
    // 获取选择模板id
    selectTemplate(id) {
      this.templateId = id;
      this.getTemplateDetail(id);
    },
    // 获取选择模板详情
    getTemplateDetail(id) {
      let _this = this;
      GetTemplateOrDraftDetail({ id: id }, (response) => {
        if (response && response.code == 200) {
          let result = response.result;
          this.getMultiRates([response.result]);
          // p&l
          // this.$refs.plTcm.form.calculationMethod = result.calculationMethod  ? result.calculationMethod : ''
          // console.log("freightCost")

          // this.$refs.plTcm.form.freightCost = result.freightCost === null ? '' : result.freightCost
          // console.log(this.$refs.plTcm.form.freightCost)
          // this.$refs.plTcm.form.wareHouseCost = result.wareHouseCost === null ? '' : result.wareHouseCost
          // this.$refs.plTcm.form.packageCost = result.packageCost ? result.packageCost : ''
          // this.$refs.plTcm.form.otherCost = result.otherCost === null ? '' : result.otherCost
          // this.$refs.plTcm.form.amortizationDescription = result.amortizationDescription ? result.amortizationDescription : ''
          // this.$refs.plTcm.form.totalPieces = result.totalPieces ? result.totalPieces : ''
          // this.$refs.plTcm.form.totalAmortize = result.totalAmortize ? result.totalAmortize : ''
          // this.$refs.plTcm.form.amortizeCost = result.amortizeCost ? result.amortizeCost : ''
          // // oh
          // this.$refs.ohTcm.form.sga = result.sga ? result.sga : ''
          // this.$refs.ohTcm.form.sgaCost = result.sgaCost ? result.sgaCost : ''
          // this.$refs.ohTcm.form.materialProfit = result.materialProfit ? result.materialProfit : ''
          // this.$refs.ohTcm.form.addProfit = result.addProfit ? result.addProfit : ''
          // this.$refs.ohTcm.form.purchaseProfit = result.purchaseProfit ? result.purchaseProfit : ''
          // this.$refs.ohTcm.form.totalProfit = result.totalProfit ? result.totalProfit : ''
          // this.$refs.ohTcm.form.totalProfitCost = result.totalProfitCost ? result.totalProfitCost : ''
          // this.$refs.ohTcm.form.materialPC = result.materialPC ? result.materialPC : ''
          // // raw
          // this.$refs.rwTcm.materialCost_RM = result.materialCost_RM
          // result.materialList.forEach(function(val, index){
          // 	let cLines = []
          // 	val.cLines.forEach(function(va, ind) {
          // 		cLines.push({
          // 			fieldName: va.paramName,
          // 			calculatorTableId: va.calculator_TableId
          // 		})
          // 	})
          // 	val.cLines = cLines
          // 	val.id = ''
          // })
          // this.$refs.rwTcm.formData = result.materialList
          // this.$refs.rwTcm.formData.forEach(function(val, index) {
          // 	_this.$refs.rwTcm.toComputeCost(val)
          // })
          // this.$refs.rwTcm.materialDelIds = []
          // // pru
          // this.$refs.purTcm.purchaseCost_PUR = result.purchaseCost_PUR
          // result.purchaseList.forEach(function(val,index) {
          // 	val.id = ''
          // })
          // this.$refs.purTcm.formData = result.purchaseList
          // this.$refs.purTcm.formData.forEach(function(val, index) {
          // 	_this.$refs.purTcm.toCompute(val)
          // })
          // this.$refs.purTcm.purchaseDelIds = []
          // // pro
          // this.$refs.proTcm.laborCost_PRO = result.laborCost_PRO
          // this.$refs.proTcm.setUpCost_PRO = result.setUpCost_PRO
          // this.$refs.proTcm.burdenCost_PRO = result.burdenCost_PRO
          // this.$refs.proTcm.manufacturingCost_PRO = result.manufacturingCost_PRO
          // result.processList.forEach(function(val, index){
          // 	let cLines = []
          // 	val.cLines.forEach(function(va, ind) {
          // 		cLines.push({
          // 			fieldName: va.paramName,
          // 			calculatorTableId: va.calculator_TableId
          // 		})
          // 	})
          // 	val.cLines = cLines
          // 	val.id = ''
          // 	console.log(_this.$refs.gen.form.lotSize, '_this.$refs.gen.form.productionYear')
          // 	val.annualVolume = _this.$refs.gen.genList[0].peakVolume
          // 	val.pieceLot = _this.$refs.gen.form.lotSize
          // })
          // this.$refs.proTcm.formData = result.processList
          // this.$refs.proTcm.formData.forEach(function(val, index) {
          // 	_this.$refs.proTcm.toComputeAll(val)
          // })
          // this.$refs.proTcm.processDelIds = []
          // // sum
          // this.$refs.sumTcm.form.otherCost_SUM = result.otherCost
          // this.$refs.sumTcm.form.amortizeCost_SUM  = result.amortizeCost
          // // this.$refs.sumTcm.form.sgaProfit_SUM =  parseFloat(result.sgaCost) + parseFloat(result.totalProfitCost)
          // // this.$refs.sumTcm.form.setUpCost_SUM = result.setUpCost_PRO
          // // this.CommodityId = result.commodityId
          // this.$refs.templateDialog.visible = false
        }
      });
    },
    // 查询选择的模板税率与gen中税率是否一致
    getMultiRates(arr) {
      let _this = this;
      let multiRow = [];
      arr.forEach(function (resVal, resIndex) {
        let moudle = resVal;
        moudle.currId = JSON.parse(JSON.stringify(moudle.currencyId));
        moudle.currCNYId = _this.$refs.gen.genList[0].Currency;
        moudle.exchangeRate = "";

        multiRow.push(moudle);
      });
      _this.addCellList = multiRow;
      this.$refs.templateDialog.visible = false;
      getMultiRate(
        {
          genList: multiRow,
        },
        (response) => {
          if (response && response.code == 200) {
            _this.addCellExchangeList = [];
            let untaxedNum = 0;
            _this.addCellExchangeList = response.data[0].exchangeList;
            _this.addCellExchangeList.forEach(function (excVal, excIndex) {
              if (excVal.exchangeRate === "") {
                excVal.hasRate = false;
                untaxedNum++;
              } else {
                excVal.hasRate = true;
              }
            });
            // 如果其中有无税率的情况则弹窗输入
            if (untaxedNum > 0) {
              _this.$refs.rateDialog.addCellExchangeList =
                _this.addCellExchangeList;
              _this.$refs.rateDialog.visible = true;
            } else {
              // 如果税率都有则转换
              _this.$refs.rateDialog.visible = false;
              _this.addCellExchangeList.forEach(function (rateVal, rateIndex) {
                _this.addCellList.forEach(function (cellVal, cellIndex) {
                  if (rateVal.currencyId == cellVal.currencyId) {
                    cellVal.exchangeRate = rateVal.exchangeRate;
                    cellVal.materialList.forEach(function (rwVal, rwIndex) {
                      rwVal.convertCurrencyId = JSON.parse(
                        JSON.stringify(rwVal.currencyId)
                      );
                      rwVal.currencyId = _this.$refs.gen.genList[0].Currency;
                      rwVal.converRate = cellVal.exchangeRate;
                      rwVal.exchangeRate = rwVal.converRate;
                      rwVal.unitCost =
                        parseFloat(
                          rwVal.unitCost === "" ||
                            rwVal.unitCost === null ||
                            !rwVal.unitCost
                            ? "0"
                            : rwVal.unitCost
                        ) * parseFloat(cellVal.exchangeRate);
                      rwVal.unitResaleValue =
                        parseFloat(
                          rwVal.unitResaleValue === "" ||
                            rwVal.unitResaleValue === null ||
                            !rwVal.unitResaleValue
                            ? "0"
                            : rwVal.unitResaleValue
                        ) * parseFloat(cellVal.exchangeRate);
                      rwVal.cost =
                        parseFloat(
                          rwVal.cost === "" ||
                            rwVal.cost === null ||
                            !rwVal.cost
                            ? "0"
                            : rwVal.cost
                        ) * parseFloat(cellVal.exchangeRate);
                    });
                    cellVal.purchaseList.forEach(function (purVal, purIndex) {
                      purVal.convertCurrencyId = JSON.parse(
                        JSON.stringify(purVal.currencyId)
                      );
                      purVal.currencyId = _this.$refs.gen.genList[0].Currency;
                      purVal.converRate = cellVal.exchangeRate;
                      purVal.exchangeRate = purVal.converRate;
                      purVal.unitCost =
                        parseFloat(
                          purVal.unitCost === "" ||
                            purVal.unitCost === null ||
                            !purVal.unitCost
                            ? "0"
                            : purVal.unitCost
                        ) * parseFloat(cellVal.exchangeRate);
                      purVal.totalCost =
                        parseFloat(
                          purVal.totalCost === "" ||
                            purVal.totalCost === null ||
                            !purVal.totalCost
                            ? "0"
                            : purVal.totalCost
                        ) * parseFloat(cellVal.exchangeRate);
                    });
                    cellVal.processList.forEach(function (proVal, proIndex) {
                      proVal.convertCurrencyId = JSON.parse(
                        JSON.stringify(proVal.currencyId)
                      );
                      proVal.currencyId = _this.$refs.gen.genList[0].Currency;
                      proVal.converRate = cellVal.exchangeRate;
                      proVal.exchangeRate = proVal.converRate;
                      proVal.variableRate =
                        parseFloat(
                          proVal.variableRate === "" ||
                            proVal.variableRate === null ||
                            !proVal.variableRate
                            ? "0"
                            : proVal.variableRate
                        ) * parseFloat(cellVal.exchangeRate);
                      proVal.fixedRate =
                        parseFloat(
                          proVal.fixedRate === "" ||
                            proVal.fixedRate === null ||
                            !proVal.fixedRate
                            ? "0"
                            : proVal.fixedRate
                        ) * parseFloat(cellVal.exchangeRate);
                      proVal.depreciationRate =
                        parseFloat(
                          proVal.depreciationRate === "" ||
                            proVal.depreciationRate === null ||
                            !proVal.depreciationRate
                            ? "0"
                            : proVal.depreciationRate
                        ) * parseFloat(cellVal.exchangeRate);
                    });
                    if (cellVal.calculationMethod_Freight == 1) {
                      cellVal.freightCost =
                        parseFloat(
                          cellVal.freightCost === "" ||
                            cellVal.freightCost === null ||
                            !cellVal.freightCost
                            ? "0"
                            : cellVal.freightCost
                        ) * parseFloat(cellVal.exchangeRate);
                    }
                    if (cellVal.calculationMethod_Ware == 1) {
                      cellVal.wareHouseCost =
                        parseFloat(
                          cellVal.wareHouseCost === "" ||
                            cellVal.wareHouseCost === null ||
                            !cellVal.wareHouseCost
                            ? "0"
                            : cellVal.wareHouseCost
                        ) * parseFloat(cellVal.exchangeRate);
                    }
                    if (cellVal.calculationMethod_Package == 1) {
                      cellVal.packageCost =
                        parseFloat(
                          cellVal.packageCost === "" ||
                            cellVal.packageCost === null ||
                            !cellVal.packageCost
                            ? "0"
                            : cellVal.packageCost
                        ) * parseFloat(cellVal.exchangeRate);
                    }
                    if (cellVal.calculationMethod_Taxes == 1) {
                      cellVal.otherCost =
                        parseFloat(
                          cellVal.otherCost === "" ||
                            cellVal.otherCost === null ||
                            !cellVal.otherCost
                            ? "0"
                            : cellVal.otherCost
                        ) * parseFloat(cellVal.exchangeRate);
                    }
                    cellVal.totalAmortize =
                      parseFloat(
                        cellVal.totalAmortize === "" ||
                          cellVal.totalAmortize === null ||
                          !cellVal.totalAmortize
                          ? "0"
                          : cellVal.totalAmortize
                      ) * parseFloat(cellVal.exchangeRate);
                    cellVal.amortizeCost =
                      parseFloat(
                        cellVal.amortizeCost === "" ||
                          cellVal.amortizeCost === null ||
                          !cellVal.amortizeCost
                          ? "0"
                          : cellVal.amortizeCost
                      ) * parseFloat(cellVal.exchangeRate);
                    cellVal.sgaCost =
                      parseFloat(
                        cellVal.sgaCost === "" ||
                          cellVal.sgaCost === null ||
                          !cellVal.sgaCost
                          ? "0"
                          : cellVal.sgaCost
                      ) * parseFloat(cellVal.exchangeRate);
                    cellVal.totalProfitCost =
                      parseFloat(
                        cellVal.totalProfitCost === "" ||
                          cellVal.totalProfitCost === null ||
                          !cellVal.totalProfitCost
                          ? "0"
                          : cellVal.totalProfitCost
                      ) * parseFloat(cellVal.exchangeRate);
                  }
                });
              });
              _this.addCellTransfer();
            }
          }
        }
      );
    },
    // 填写完税率后获取并且转化数据
    rateSubmit(rateList) {
      let _this = this;
      rateList.forEach(function (rateVal, rateIndex) {
        _this.addCellList.forEach(function (cellVal, cellIndex) {
          if (rateVal.currencyId == cellVal.currencyId) {
            cellVal.exchangeRate = rateVal.exchangeRate;
            cellVal.materialList.forEach(function (rwVal, rwIndex) {
              rwVal.convertCurrencyId = JSON.parse(
                JSON.stringify(rwVal.currencyId)
              );
              rwVal.currencyId = _this.$refs.gen.genList[0].Currency;
              rwVal.converRate = cellVal.exchangeRate;
              rwVal.exchangeRate = rwVal.converRate;
              rwVal.unitCost =
                parseFloat(
                  rwVal.unitCost === "" ||
                    rwVal.unitCost === null ||
                    !rwVal.unitCost
                    ? "0"
                    : rwVal.unitCost
                ) * parseFloat(cellVal.exchangeRate);
              rwVal.unitResaleValue =
                parseFloat(
                  rwVal.unitResaleValue === "" ||
                    rwVal.unitResaleValue === null ||
                    !rwVal.unitResaleValue
                    ? "0"
                    : rwVal.unitResaleValue
                ) * parseFloat(cellVal.exchangeRate);
              rwVal.cost =
                parseFloat(
                  rwVal.cost === "" || rwVal.cost === null || !rwVal.cost
                    ? "0"
                    : rwVal.cost
                ) * parseFloat(cellVal.exchangeRate);
            });
            cellVal.purchaseList.forEach(function (purVal, purIndex) {
              purVal.convertCurrencyId = JSON.parse(
                JSON.stringify(purVal.currencyId)
              );
              purVal.currencyId = _this.$refs.gen.genList[0].Currency;
              purVal.converRate = cellVal.exchangeRate;
              purVal.exchangeRate = purVal.converRate;
              purVal.unitCost =
                parseFloat(
                  purVal.unitCost === "" ||
                    purVal.unitCost === null ||
                    !purVal.unitCost
                    ? "0"
                    : purVal.unitCost
                ) * parseFloat(cellVal.exchangeRate);
              purVal.totalCost =
                parseFloat(
                  purVal.totalCost === "" ||
                    purVal.totalCost === null ||
                    !purVal.totalCost
                    ? "0"
                    : purVal.totalCost
                ) * parseFloat(cellVal.exchangeRate);
            });
            cellVal.processList.forEach(function (proVal, proIndex) {
              proVal.convertCurrencyId = JSON.parse(
                JSON.stringify(proVal.currencyId)
              );
              proVal.currencyId = _this.$refs.gen.genList[0].Currency;
              proVal.converRate = cellVal.exchangeRate;
              proVal.exchangeRate = proVal.converRate;
              proVal.variableRate =
                parseFloat(
                  proVal.variableRate === "" ||
                    proVal.variableRate === null ||
                    !proVal.variableRate
                    ? "0"
                    : proVal.variableRate
                ) * parseFloat(cellVal.exchangeRate);
              proVal.fixedRate =
                parseFloat(
                  proVal.fixedRate === "" ||
                    proVal.fixedRate === null ||
                    !proVal.fixedRate
                    ? "0"
                    : proVal.fixedRate
                ) * parseFloat(cellVal.exchangeRate);
              proVal.depreciationRate =
                parseFloat(
                  proVal.depreciationRate === "" ||
                    proVal.depreciationRate === null ||
                    !proVal.depreciationRate
                    ? "0"
                    : proVal.depreciationRate
                ) * parseFloat(cellVal.exchangeRate);
            });
            if (cellVal.calculationMethod_Freight == 1) {
              cellVal.freightCost =
                parseFloat(
                  cellVal.freightCost === "" ||
                    cellVal.freightCost === null ||
                    !cellVal.freightCost
                    ? "0"
                    : cellVal.freightCost
                ) * parseFloat(cellVal.exchangeRate);
            }
            if (cellVal.calculationMethod_Ware == 1) {
              cellVal.wareHouseCost =
                parseFloat(
                  cellVal.wareHouseCost === "" ||
                    cellVal.wareHouseCost === null ||
                    !cellVal.wareHouseCost
                    ? "0"
                    : cellVal.wareHouseCost
                ) * parseFloat(cellVal.exchangeRate);
            }
            if (cellVal.calculationMethod_Package == 1) {
              cellVal.packageCost =
                parseFloat(
                  cellVal.packageCost === "" ||
                    cellVal.packageCost === null ||
                    !cellVal.packageCost
                    ? "0"
                    : cellVal.packageCost
                ) * parseFloat(cellVal.exchangeRate);
            }
            if (cellVal.calculationMethod_Taxes == 1) {
              cellVal.otherCost =
                parseFloat(
                  cellVal.otherCost === "" ||
                    cellVal.otherCost === null ||
                    !cellVal.otherCost
                    ? "0"
                    : cellVal.otherCost
                ) * parseFloat(cellVal.exchangeRate);
            }
            cellVal.totalAmortize =
              parseFloat(
                cellVal.totalAmortize === "" ||
                  cellVal.totalAmortize === null ||
                  !cellVal.totalAmortize
                  ? "0"
                  : cellVal.totalAmortize
              ) * parseFloat(cellVal.exchangeRate);
            cellVal.amortizeCost =
              parseFloat(
                cellVal.amortizeCost === "" ||
                  cellVal.amortizeCost === null ||
                  !cellVal.amortizeCost
                  ? "0"
                  : cellVal.amortizeCost
              ) * parseFloat(cellVal.exchangeRate);
            cellVal.sgaCost =
              parseFloat(
                cellVal.sgaCost === "" ||
                  cellVal.sgaCost === null ||
                  !cellVal.sgaCost
                  ? "0"
                  : cellVal.sgaCost
              ) * parseFloat(cellVal.exchangeRate);
            cellVal.totalProfitCost =
              parseFloat(
                cellVal.totalProfitCost === "" ||
                  cellVal.totalProfitCost === null ||
                  !cellVal.totalProfitCost
                  ? "0"
                  : cellVal.totalProfitCost
              ) * parseFloat(cellVal.exchangeRate);
          }
        });
      });
      _this.$refs.rateDialog.visible = false;
      this.addCellTransfer();
    },
    addCellTransfer() {
      let _this = this;
      let result = this.addCellList[0];
      // p&l
      this.$refs.plTcm.form.calculationMethod = result.calculationMethod
        ? result.calculationMethod
        : "";
      this.$refs.plTcm.form.calculationMethod_Freight =
        result.calculationMethod_Freight ? result.calculationMethod_Freight : 1;
      this.$refs.plTcm.form.calculationMethod_Package =
        result.calculationMethod_Package ? result.calculationMethod_Package : 1;
      this.$refs.plTcm.form.calculationMethod_Taxes =
        result.calculationMethod_Taxes ? result.calculationMethod_Taxes : 1;
      this.$refs.plTcm.form.calculationMethod_Ware =
        result.calculationMethod_Ware ? result.calculationMethod_Ware : 1;

      this.$refs.plTcm.form.freightCost =
        result.freightCost === null ? "" : result.freightCost;
      this.$refs.plTcm.form.wareHouseCost =
        result.wareHouseCost === null ? "" : result.wareHouseCost;
      this.$refs.plTcm.form.packageCost = result.packageCost
        ? result.packageCost
        : "";
      this.$refs.plTcm.form.otherCost =
        result.otherCost === null ? "" : result.otherCost;
      this.$refs.plTcm.form.amortizationDescription =
        result.amortizationDescription ? result.amortizationDescription : "";
      this.$refs.plTcm.form.totalPieces = result.totalPieces
        ? result.totalPieces
        : "";
      this.$refs.plTcm.form.totalAmortize = result.totalAmortize
        ? result.totalAmortize
        : "";
      this.$refs.plTcm.form.amortizeCost = result.amortizeCost
        ? result.amortizeCost
        : "";
      // oh
      this.$refs.ohTcm.form.sga =
        result.sga !== "" || result.sga !== null ? result.sga : "";
      this.$refs.ohTcm.form.sgaCost = result.sgaCost ? result.sgaCost : "";
      this.$refs.ohTcm.form.materialProfit =
        result.materialProfit !== "" || result.materialProfit !== null
          ? result.materialProfit
          : "";
      this.$refs.ohTcm.form.addProfit =
        result.addProfit !== "" || result.addProfit !== null
          ? result.addProfit
          : "";
      this.$refs.ohTcm.form.purchaseProfit =
        result.purchaseProfit !== "" || result.purchaseProfit !== null
          ? result.purchaseProfit
          : "";
      this.$refs.ohTcm.form.totalProfit = result.totalProfit
        ? result.totalProfit
        : "";
      this.$refs.ohTcm.form.totalProfitCost = result.totalProfitCost
        ? result.totalProfitCost
        : "";
      this.$refs.ohTcm.form.materialPC =
        result.materialPC !== "" || result.materialPC !== null
          ? result.materialPC
          : "";
      // raw
      this.$refs.rwTcm.materialCost_RM = result.materialCost_RM
        ? result.materialCost_RM
        : "";
      this.$refs.rwTcm.materialDelIds = [];
      let oriMaterialList = this.$refs.rwTcm.formData;
      if (oriMaterialList.length > 0) {
        oriMaterialList.forEach(function (val, index) {
          _this.$refs.rwTcm.materialDelIds.push(val.id);
        });
      }
      result.materialList.forEach(function (val, index) {
        if (val.convertCurrencyId === "" || val.convertCurrencyId === null) {
          val.convertCurrencyId = result.currencyId;
        }
        let cLines = [];
        val.cLines.forEach(function (va, ind) {
          cLines.push({
            fieldName: va.paramName,
            calculatorTableId: va.calculator_TableId,
          });
        });
        val.cLines = cLines;
        val.id = "";
      });
      this.$refs.rwTcm.formData = result.materialList;
      this.$refs.rwTcm.formData.forEach(function (val, index) {
        _this.$refs.rwTcm.toComputeCost(val);
      });
      // pru
      this.$refs.purTcm.purchaseCost_PUR = result.purchaseCost_PUR
        ? result.purchaseCost_PUR
        : "";
      this.$refs.purTcm.purchaseDelIds = [];
      let oriPurchaseList = this.$refs.purTcm.formData;
      if (oriPurchaseList.length > 0) {
        oriPurchaseList.forEach(function (val, index) {
          _this.$refs.purTcm.purchaseDelIds.push(val.id);
        });
      }
      result.purchaseList.forEach(function (val, index) {
        if (val.convertCurrencyId === "" || val.convertCurrencyId === null) {
          val.convertCurrencyId = result.currencyId;
        }
        val.id = "";
      });
      this.$refs.purTcm.formData = result.purchaseList;
      this.$refs.purTcm.formData.forEach(function (val, index) {
        _this.$refs.purTcm.toCompute(val);
      });
      // pro
      this.$refs.proTcm.processDelIds = [];
      let oriProcessList = this.$refs.proTcm.formData;
      if (oriProcessList.length > 0) {
        oriProcessList.forEach(function (val, index) {
          _this.$refs.proTcm.processDelIds.push(val.id);
        });
      }

      result.processList.forEach(function (val, index) {
        if (val.convertCurrencyId === "" || val.convertCurrencyId === null) {
          val.convertCurrencyId = result.currencyId;
        }
        let cLines = [];
        val.cLines.forEach(function (va, ind) {
          cLines.push({
            fieldName: va.paramName,
            calculatorTableId: va.calculator_TableId,
          });
        });
        val.cLines = cLines;
        val.id = "";
        val.descShow = false;
        val.setShow = false;
        val.opeShow = true;
        val.annualVolume = _this.$refs.gen.genList[0].peakVolume;
        val.pieceLot = _this.$refs.gen.form.lotSize;
      });
      // 这串代码解决选择模版中 pieceHour 为0的时候。默认为1
      // if(result.processList.length) {
      //   result.processList.forEach((item) => {
      //     if(!item.pieceHour) {
      //       item.pieceHour = 1
      //     }
      //   })
      // }
      this.$refs.proTcm.formData = result.processList;
      // this.$refs.proTcm.toComputeByrate(result.processList)
      this.$refs.proTcm.formData.forEach(function (val, index) {
        _this.$refs.proTcm.toComputeAll(val);
      });
      // sum
      this.$refs.sumTcm.form.otherCost_SUM = result.otherCost;
      this.$refs.sumTcm.form.amortizeCost_SUM = result.amortizeCost;
      // this.$refs.sumTcm.form.sgaProfit_SUM =  parseFloat(result.sgaCost) + parseFloat(result.totalProfitCost)
      // this.$refs.sumTcm.form.setUpCost_SUM = result.setUpCost_PRO
      // this.CommodityId = result.commodityId
      this.$refs.plTcm.tocompute();
      this.$refs.sumTcm.form.manufacturingCost_PRO =
        this.$refs.proTcm.manufacturingCost_PRO;
    },
    // 获取选择已完成的CBDID
    selectCompleted(id) {
      this.getCompletedDetail(id);
    },
    getCompletedDetail(id) {
      let _this = this;
      let ids = [];
      ids.push(id);
      GetApplyDetail(ids, (response) => {
        if (response && response.code == 200) {
          let data = response.result[0];
          let applicationList = [];
          let operateList = [];
          data.attachmentList.forEach(function (val, index) {
            if (val.applyType == 1) {
              operateList.push({
                name: val.name,
                url: val.url,
              });
            } else {
              applicationList.push(val);
            }
          });
          this.$refs.ohTcm.form.sga =
            data.sga !== "" || data.sga !== null ? data.sga : "";
          this.$refs.ohTcm.form.sgaCost = data.sgaCost ? data.sgaCost : "";
          this.$refs.ohTcm.form.materialProfit =
            data.materialProfit !== "" || data.materialProfit !== null
              ? data.materialProfit
              : "";
          this.$refs.ohTcm.form.addProfit =
            data.addProfit !== "" || data.addProfit !== null
              ? data.addProfit
              : "";
          this.$refs.ohTcm.form.purchaseProfit =
            data.purchaseProfit !== "" || data.purchaseProfit !== null
              ? data.purchaseProfit
              : "";
          this.$refs.ohTcm.form.totalProfit = data.totalProfit
            ? data.totalProfit
            : "";
          this.$refs.ohTcm.form.totalProfitCost = data.totalProfitCost
            ? data.totalProfitCost
            : "";
          this.$refs.ohTcm.form.materialPC =
            data.materialPC !== "" || data.materialPC !== null
              ? data.materialPC
              : "";
          this.$refs.rwTcm.materialCost_RM = data.materialCost_RM
            ? data.materialCost_RM
            : "";
          this.$refs.rwTcm.materialDelIds = [];
          let oriMaterialList = this.$refs.rwTcm.formData;
          if (oriMaterialList.length > 0) {
            oriMaterialList.forEach(function (val, index) {
              _this.$refs.rwTcm.materialDelIds.push(val.id);
            });
          }
          if (data.materialList.length > 0) {
            data.materialList.forEach(function (val, index) {
              if (val.currencyId === "" || val.currencyId === null) {
                val.convertCurrencyId = _this.$refs.gen.genList[0].Currency;
                val.currencyId = _this.$refs.gen.genList[0].Currency;
                val.converRate = "1";
                val.exchangeRate = "1";
              } else {
                if (
                  val.convertCurrencyId === "" ||
                  val.convertCurrencyId === null
                ) {
                  val.convertCurrencyId = _this.$refs.gen.genList[0].Currency;
                  val.currencyId = _this.$refs.gen.genList[0].Currency;
                  val.converRate = "1";
                  val.exchangeRate = "1";
                } else {
                  val.exchangeRate = val.converRate;
                }
              }

              let cLines = [];
              val.cLines.forEach(function (va, ind) {
                cLines.push({
                  fieldName: va.paramName,
                  calculatorTableId: va.calculator_TableId,
                });
              });
              val.cLines = cLines;
              val.id = "";
            });
            this.$refs.rwTcm.formData = data.materialList;
          }

          // this.$refs.rwTcm.materialDelIds = []
          this.$refs.purTcm.purchaseCost_PUR = data.purchaseCost_PUR
            ? data.purchaseCost_PUR
            : "";
          this.$refs.purTcm.purchaseDelIds = [];
          let oriPurchaseList = this.$refs.purTcm.formData;
          if (oriPurchaseList.length > 0) {
            oriPurchaseList.forEach(function (val, index) {
              _this.$refs.purTcm.purchaseDelIds.push(val.id);
            });
          }
          data.purchaseList.forEach(function (val, index) {
            if (val.currencyId === "" || val.currencyId === null) {
              val.convertCurrencyId = _this.$refs.gen.genList[0].Currency;
              val.currencyId = _this.$refs.gen.genList[0].Currency;
              val.converRate = "1";
              val.exchangeRate = "1";
            } else {
              if (
                val.convertCurrencyId === "" ||
                val.convertCurrencyId === null
              ) {
                val.convertCurrencyId = _this.$refs.gen.genList[0].Currency;
                val.currencyId = _this.$refs.gen.genList[0].Currency;
                val.converRate = "1";
                val.exchangeRate = "1";
              } else {
                val.exchangeRate = val.converRate;
              }
            }
            val.id = "";
          });
          this.$refs.purTcm.formData = data.purchaseList;
          // this.$refs.purTcm.purchaseDelIds = []

          this.$refs.proTcm.laborCost_PRO = data.laborCost_PRO
            ? data.laborCost_PRO
            : "";
          this.$refs.proTcm.setUpCost_PRO = data.setUpCost_PRO
            ? data.setUpCost_PRO
            : "";
          this.$refs.proTcm.burdenCost_PRO = data.burdenCost_PRO
            ? data.burdenCost_PRO
            : "";
          this.$refs.proTcm.manufacturingCost_PRO = data.manufacturingCost_PRO
            ? data.manufacturingCost_PRO
            : 0;
          this.$refs.proTcm.processDelIds = [];
          let oriProcessList = this.$refs.proTcm.formData;
          if (oriProcessList.length > 0) {
            oriProcessList.forEach(function (val, index) {
              _this.$refs.proTcm.processDelIds.push(val.id);
            });
          }
          if (data.processList.length > 0) {
            data.processList.forEach(function (val, index) {
              if (val.currencyId === "" || val.currencyId === null) {
                val.convertCurrencyId = _this.$refs.gen.genList[0].Currency;
                val.currencyId = _this.$refs.gen.genList[0].Currency;
                val.converRate = "1";
                val.exchangeRate = "1";
              } else {
                if (
                  val.convertCurrencyId === "" ||
                  val.convertCurrencyId === null
                ) {
                  val.convertCurrencyId = _this.$refs.gen.genList[0].Currency;
                  val.currencyId = _this.$refs.gen.genList[0].Currency;
                  val.converRate = "1";
                  val.exchangeRate = "1";
                } else {
                  val.exchangeRate = val.converRate;
                }
              }
              let cLines = [];
              val.cLines.forEach(function (va, ind) {
                cLines.push({
                  fieldName: va.paramName,
                  calculatorTableId: va.calculator_TableId,
                });
              });
              val.cLines = cLines;
              val.id = "";
              val.descShow = false;
              val.setShow = false;
              val.opeShow = true;
              val.annualVolume = _this.$refs.gen.genList[0].peakVolume;
              val.pieceLot = _this.$refs.gen.form.lotSize;
            });
            this.$refs.proTcm.formData = data.processList;
            this.$refs.proTcm.formData.forEach(function (val, index) {
              _this.$refs.proTcm.toComputeAll(val);
            });
          }

          // this.$refs.proTcm.processDelIds = []

          this.$refs.plTcm.form.calculationMethod = data.calculationMethod
            ? data.calculationMethod
            : "";
          this.$refs.plTcm.form.calculationMethod_Freight =
            data.calculationMethod_Freight !== null
              ? data.calculationMethod_Freight
              : 1;
          this.$refs.plTcm.form.calculationMethod_Package =
            data.calculationMethod_Package !== null
              ? data.calculationMethod_Package
              : 1;
          this.$refs.plTcm.form.calculationMethod_Taxes =
            data.calculationMethod_Taxes !== null
              ? data.calculationMethod_Taxes
              : 1;
          this.$refs.plTcm.form.calculationMethod_Ware =
            data.calculationMethod_Ware !== null
              ? data.calculationMethod_Ware
              : 1;
          this.$refs.plTcm.form.freightCost =
            data.freightCost !== null ? data.freightCost : "";
          this.$refs.plTcm.form.wareHouseCost =
            data.wareHouseCost !== null ? data.wareHouseCost : "";
          this.$refs.plTcm.form.packageCost =
            data.packageCost !== null ? data.packageCost : "";
          this.$refs.plTcm.form.otherCost =
            data.otherCost !== null ? data.otherCost : "";
          this.$refs.plTcm.form.amortizationDescription =
            data.amortizationDescription !== null
              ? data.amortizationDescription
              : "";
          this.$refs.plTcm.form.totalPieces =
            data.totalPieces !== null ? data.totalPieces : "";
          this.$refs.plTcm.form.totalAmortize =
            data.totalAmortize !== null ? data.totalAmortize : "";
          this.$refs.plTcm.form.amortizeCost =
            data.amortizeCost !== null ? data.amortizeCost : "";

          this.$refs.sumTcm.form.materialCost_SUM = data.materialCost_SUM;
          this.$refs.sumTcm.form.setUpCost_SUM = data.setUpCost_SUM;
          this.$refs.sumTcm.form.purchaseCost_SUM = data.purchaseCost_SUM;
          this.$refs.sumTcm.form.packageCost_SUM = data.packageCost_SUM;
          this.$refs.sumTcm.form.laborCost_SUM = data.laborCost_SUM;
          this.$refs.sumTcm.form.otherCost_SUM = data.otherCost_SUM;
          this.$refs.sumTcm.form.burdenCost_SUM = data.burdenCost_SUM;
          this.$refs.sumTcm.form.amortizeCost_SUM = data.amortizeCost_SUM;
          this.$refs.sumTcm.form.sgaProfit_SUM = data.sgaProfit_SUM;
          this.$refs.sumTcm.form.scrapCost_SUM = data.scrapCost_SUM;
          this.$refs.sumTcm.form.totalPriceCurr_SUM = data.totalPriceCurr_SUM;
          this.$refs.sumTcm.form.totalPriceReport_SUM =
            data.totalPriceReport_SUM;

          // this.$refs.sumTcm.fileListx = operateList
          this.$refs.rwTcm.materialfileList = operateList;
          this.totalSellPrice = data.totalPriceCurr_SUM
            ? data.totalPriceCurr_SUM
            : 0;
          this.$refs.plTcm.tocompute();
          this.$refs.sumTcm.form.manufacturingCost_PRO =
            this.$refs.proTcm.manufacturingCost_PRO;
        }
      });
    },
    // 取消
    cancle() {
      this.$route.meta.showTag = false;
      this.$router.back(-1);
    },
    // 打开保存为模板弹窗
    openTemplate(type) {
      this.saveType = type;
      this.$refs.temInfo.visible = true;
    },
    // 保存模板
    saveTemplate(form) {
      let materialLine = JSON.parse(JSON.stringify(this.$refs.rwTcm.formData));
      materialLine.forEach(function (val, index) {
        val.type = 0;
        val.id = "";
      });
      let purchaseLine = JSON.parse(JSON.stringify(this.$refs.purTcm.formData));
      purchaseLine.forEach(function (val, index) {
        val.type = 0;
        val.id = "";
      });
      let processLine = JSON.parse(JSON.stringify(this.$refs.proTcm.formData));
      processLine.forEach(function (val, index) {
        val.type = 0;
        val.id = "";
      });
      this.templateData = {
        id: "",
        number: "",
        name: form.name,
        version: "",
        regionId: form.regionId,
        currencyId: this.currencyId,
        comment: form.comment,
        isEnable: 1,
        materialCost_RM: this.$refs.rwTcm.materialCost_RM,
        // materialLine: this.$refs.rwTcm.formData,
        materialLine: materialLine,
        materialDelIds: this.$refs.rwTcm.materialDelIds,
        purchaseCost_PUR: this.$refs.purTcm.purchaseCost_PUR,
        // purchaseLine: this.$refs.purTcm.formData,
        purchaseLine: purchaseLine,
        purchaseDelIds: this.$refs.purTcm.purchaseDelIds,
        laborCost_PRO: this.$refs.proTcm.laborCost_PRO,
        setUpCost_PRO: this.$refs.proTcm.setUpCost_PRO,
        burdenCost_PRO: this.$refs.proTcm.burdenCost_PRO,
        manufacturingCost_PRO: this.$refs.proTcm.manufacturingCost_PRO,
        processLine: processLine,
        // processLine: this.$refs.proTcm.formData,
        processDelIds: this.$refs.proTcm.processDelIds,
        calculationMethod: this.$refs.plTcm.form.calculationMethod,
        calculationMethod_Freight:
          this.$refs.plTcm.form.calculationMethod_Freight,
        calculationMethod_Ware: this.$refs.plTcm.form.calculationMethod_Ware,
        calculationMethod_Package:
          this.$refs.plTcm.form.calculationMethod_Package,
        calculationMethod_Taxes: this.$refs.plTcm.form.calculationMethod_Taxes,
        freightCost: this.$refs.plTcm.form.freightCost,
        wareHouseCost: this.$refs.plTcm.form.wareHouseCost,
        packageCost: this.$refs.plTcm.form.packageCost,
        otherCost: this.$refs.plTcm.form.otherCost,
        amortizationDescription: this.$refs.plTcm.form.amortizationDescription,
        totalPieces: this.$refs.plTcm.form.totalPieces,
        totalAmortize: this.$refs.plTcm.form.totalAmortize,
        amortizeCost: this.$refs.plTcm.form.amortizeCost,
        sga: this.$refs.ohTcm.form.sga,
        sgaCost: this.$refs.ohTcm.form.sgaCost,
        materialProfit: this.$refs.ohTcm.form.materialProfit,
        addProfit: this.$refs.ohTcm.form.addProfit,
        purchaseProfit: this.$refs.ohTcm.form.purchaseProfit,
        totalProfit: this.$refs.ohTcm.form.totalProfit,
        totalProfitCost: this.$refs.ohTcm.form.totalProfitCost,
        materialPC: this.$refs.ohTcm.form.materialPC,
        commodityId: this.CommodityId,
        isDraft: 1,
        attachmentList: this.$refs.rwTcm.materialfileList,
      };
      this.submitType = "submitTemplate";
      this.templateData.attachmentList.forEach(function (val, index) {
        val.id = "";
        val.mainId = "";
      });
      this.uploadFiles(this.templateData.attachmentList);
    },
    // 提交个人模板
    submitTemplate() {
      this.templateData.attachmentList = this.pictureList;
      AddOrUpdateTemplateApplys(this.templateData, (response) => {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.operateSuccessfully"),
          "",
          "success"
        );
      });
    },
    // 保存为草稿
    saveDraft() {
      let materialLine = this.$refs.rwTcm.formData;
      materialLine.forEach(function (val, index) {
        val.type = 0;
        // val.id = ''
      });
      let purchaseLine = this.$refs.purTcm.formData;
      purchaseLine.forEach(function (val, index) {
        val.type = 0;
        // val.id = ''
      });
      let processLine = this.$refs.proTcm.formData;
      processLine.forEach(function (val, index) {
        val.type = 0;
        // val.id = ''
      });
      this.draftData = {
        id: this.id,
        templeteId: this.templateId,
        materialCost_RM: this.$refs.rwTcm.materialCost_RM,
        materialLine: materialLine,
        materialDelIds: this.$refs.rwTcm.materialDelIds,
        purchaseCost_PUR: this.$refs.purTcm.purchaseCost_PUR,
        purchaseLine: purchaseLine,
        purchaseDelIds: this.$refs.purTcm.purchaseDelIds,
        laborCost_PRO: this.$refs.proTcm.laborCost_PRO,
        setUpCost_PRO: this.$refs.proTcm.setUpCost_PRO,
        burdenCost_PRO: this.$refs.proTcm.burdenCost_PRO,
        manufacturingCost_PRO: this.$refs.proTcm.manufacturingCost_PRO,
        processLine: processLine,
        processDelIds: this.$refs.proTcm.processDelIds,
        calculationMethod: this.$refs.plTcm.form.calculationMethod,
        calculationMethod_Freight:
          this.$refs.plTcm.form.calculationMethod_Freight,
        calculationMethod_Ware: this.$refs.plTcm.form.calculationMethod_Ware,
        calculationMethod_Package:
          this.$refs.plTcm.form.calculationMethod_Package,
        calculationMethod_Taxes: this.$refs.plTcm.form.calculationMethod_Taxes,
        freightCost: this.$refs.plTcm.form.freightCost,
        wareHouseCost: this.$refs.plTcm.form.wareHouseCost,
        packageCost: this.$refs.plTcm.form.packageCost,
        otherCost: this.$refs.plTcm.form.otherCost,
        amortizationDescription: this.$refs.plTcm.form.amortizationDescription,
        totalPieces: this.$refs.plTcm.form.totalPieces,
        totalAmortize: this.$refs.plTcm.form.totalAmortize,
        amortizeCost: this.$refs.plTcm.form.amortizeCost,
        sga: this.$refs.ohTcm.form.sga,
        sgaCost: this.$refs.ohTcm.form.sgaCost,
        materialProfit: this.$refs.ohTcm.form.materialProfit,
        addProfit: this.$refs.ohTcm.form.addProfit,
        purchaseProfit: this.$refs.ohTcm.form.purchaseProfit,
        totalProfit: this.$refs.ohTcm.form.totalProfit,
        totalProfitCost: this.$refs.ohTcm.form.totalProfitCost,
        materialPC: this.$refs.ohTcm.form.materialPC,
        materialCost_SUM: this.$refs.sumTcm.form.materialCost_SUM,
        setUpCost_SUM: this.$refs.sumTcm.form.setUpCost_SUM,
        purchaseCost_SUM: this.$refs.sumTcm.form.purchaseCost_SUM,
        packageCost_SUM: this.$refs.sumTcm.form.packageCost_SUM,
        laborCost_SUM: this.$refs.sumTcm.form.laborCost_SUM,
        otherCost_SUM: this.$refs.sumTcm.form.otherCost_SUM,
        burdenCost_SUM: this.$refs.sumTcm.form.burdenCost_SUM,
        amortizeCost_SUM: this.$refs.sumTcm.form.amortizeCost_SUM,
        sgaProfit_SUM: this.$refs.sumTcm.form.sgaProfit_SUM,
        scrapCost_SUM: this.$refs.sumTcm.form.scrapCost_SUM,
        totalPriceCurr_SUM: this.$refs.sumTcm.form.totalPriceCurr_SUM,
        totalPriceReport_SUM: this.$refs.sumTcm.form.totalPriceReport_SUM,
        attachmentList: this.$refs.rwTcm.materialfileList,
        // peakVolume: this.$refs.gen.genList[0].peakVolume,
        // lifetimeVolume: this.$refs.gen.genList[0].lifetimeVolume,
        lotSize: this.$refs.gen.form.lotSize,
        productionYear: this.$refs.gen.form.productionYear,
        currencyId: this.currencyId,
        IsDraft: 1,
        importType: 0,
      };
      this.submitType = "submitDraft";
      this.uploadFiles(this.draftData.attachmentList);
    },
    // 提交草稿
    submitDraft() {
      this.draftData.attachmentList = this.pictureList;
      // if (this.lstPrice.length) {
      //   this.lstPrice[0].CompleteApplyId = this.CompleteApplyId;
      //   this.draftData.lstPrice = this.lstPrice;
      // }
      let formData = this.$refs.purTcm.formData;
      let lstPrice = [];
      formData.forEach((item) => {
        if (item.oldData) {
          item.oldData.CompleteApplyId = item.completeApplyId;
          lstPrice.push(item.oldData);
        } else {
          lstPrice.push(null);
        }
      });
      if (lstPrice.length) {
        this.draftData.lstPrice = lstPrice;
      }
      TCMDataEdit(this.draftData, (response) => {
        if (response && response.code == 200) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.operateSuccessfully"),
            "",
            "success"
          );
          this.getDetail();
        }
      });
    },
    // 预览
    previewTemplate() {
      let _this = this;
      if (this.operateType == "editImport") {
        let genForm = this.$refs.gen.form;
        let genList = this.$refs.gen.genList;
        if (
          genForm.quoteTypeId === "" ||
          genForm.quoteTypeId === null ||
          !genForm.quoteTypeId
        ) {
          commonUtil.showTipInfo(
            this.$t("changePriceLists.changePriceList.requestRequired"),
            "",
            "warning"
          );
          return;
        }
        if (
          (genForm.quoteTypeName === "New pursuit" ||
            genForm.quoteTypeName === "Soucing") &&
          (genForm.programName === "" ||
            genForm.programName === null ||
            !genForm.programName)
        ) {
          commonUtil.showTipInfo(
            this.$t("changePriceLists.changePriceList.projectRequired"),
            "",
            "warning"
          );
          return;
        }
        if (
          genForm.quoteTypeName === "New pursuit" &&
          (genForm.ecrno === "" || genForm.ecrno === null || !genForm.ecrno)
        ) {
          commonUtil.showTipInfo(
            this.$t("changePriceLists.changePriceList.ecrNoRequired"),
            "",
            "warning"
          );
          return;
        }
        if (
          genForm.commodityId === "" ||
          genForm.commodityId === null ||
          !genForm.commodityId
        ) {
          commonUtil.showTipInfo(
            this.$t("changePriceLists.changePriceList.commodityRequired"),
            "",
            "warning"
          );
          return;
        }
        if (
          genForm.partDescription === "" ||
          genForm.partDescription === null ||
          !genForm.partDescription
        ) {
          commonUtil.showTipInfo(
            this.$t("changePriceLists.changePriceList.partRequired"),
            "",
            "warning"
          );
          return;
        }
        if (
          (genForm.quoteTypeName === "New pursuit" ||
            genForm.quoteTypeName === "Soucing" ||
            genForm.quoteTypeName === "SOP") &&
          (genForm.partNumber === "" ||
            genForm.partNumber === null ||
            !genForm.partNumber)
        ) {
          commonUtil.showTipInfo(
            this.$t("changePriceLists.changePriceList.partNumberRequired"),
            "",
            "warning"
          );
          return;
        }
        let currency = false;
        let reportCurrency = false;
        let rate = false;
        let region = false;
        genList.forEach(function (val, index) {
          if (val.Currency === "" || val.Currency === null || !val.Currency) {
            currency = true;
            return;
          } else if (
            val.ReportCurrency === "" ||
            val.ReportCurrency === null ||
            !val.ReportCurrency
          ) {
            reportCurrency = true;
            return;
          } else if (val.rate === "" || val.rate === null || !val.rate) {
            rate = true;
            return;
          } else if (
            val.regionId === "" ||
            val.regionId === null ||
            !val.regionId
          ) {
            region = true;
            return;
          }
        });
        if (currency) {
          commonUtil.showTipInfo(
            this.$t("changePriceLists.changePriceList.currencyRequired"),
            "",
            "warning"
          );
          return;
        }
        if (reportCurrency) {
          commonUtil.showTipInfo(
            this.$t("changePriceLists.changePriceList.reportRequired"),
            "",
            "warning"
          );
          return;
        }
        if (rate) {
          commonUtil.showTipInfo(
            this.$t("changePriceLists.changePriceList.exRquired"),
            "",
            "warning"
          );
          return;
        }
        if (region) {
          commonUtil.showTipInfo(
            this.$t("changePriceLists.changePriceList.regionRquired"),
            "",
            "warning"
          );
          return;
        }
      }
      // 判断物料必填项
      let rawDescriptionIsEmpty = false;
      let rawNetMWeightIsEmpty = false;
      let rawGrossMWeightIsEmpty = false;
      let rawUnitMeasureIdsEmpty = false;
      let rawUnitCostIsEmpty = false;
      let rawUnitResaleValueIsEmpty = false;
      let rawExchangerateIsEmpty = false;
      this.$refs.rwTcm.formData.forEach(function (val, index) {
        if (val.converRate === "") {
          rawExchangerateIsEmpty = true;
          return;
        }
        if (val.description === "") {
          rawDescriptionIsEmpty = true;
          return;
        }
        if (val.netMWeight === "" || val.netMWeight === null) {
          rawNetMWeightIsEmpty = true;
          return;
        }
        if (val.grossMWeight === "" || val.grossMWeight === null) {
          rawGrossMWeightIsEmpty = true;
          return;
        }
        if (val.unitMeasureId === "") {
          rawUnitMeasureIdsEmpty = true;
          return;
        }
        if (val.unitCost === "" || val.unitCost === null) {
          rawUnitCostIsEmpty = true;
          return;
        }
        if (val.unitResaleValue === "" || val.unitResaleValue === null) {
          rawUnitResaleValueIsEmpty = true;
          return;
        }
      });
      if (rawExchangerateIsEmpty) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.exRquired"),
          "",
          "warning"
        );
        return;
      }
      if (rawDescriptionIsEmpty) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.materialRequired"),
          "",
          "warning"
        );
        return;
      }
      if (rawNetMWeightIsEmpty) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.NetMWeightRequired"),
          "",
          "warning"
        );
        return;
      }
      if (rawGrossMWeightIsEmpty) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.GrossMWeightRequired"),
          "",
          "warning"
        );
        return;
      }
      if (rawUnitMeasureIdsEmpty) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.unitRequired"),
          "",
          "warning"
        );
        return;
      }
      if (rawUnitCostIsEmpty) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.UnitMaterialRequired"),
          "",
          "warning"
        );
        return;
      }
      if (rawUnitResaleValueIsEmpty) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.materialRequireds"),
          "",
          "warning"
        );
        return;
      }
      // 判断工艺必填项
      let setTimeIssample = false;
      let setPersonIssample = false;
      let proprocessNameIssample = false;
      let equSizeIssample = false;
      let pieceHourIssample = false;
      let pieceCycleIssample = false;
      let efficiencyPercentIssample = false;
      let directRateIssample = false;
      let setUpRateIssample = false;
      let directOperateQtyIssample = false;
      let variableRateIssample = false;
      let scrapPercentIssample = false;
      let proExchangerateIsEmpty = false;
      this.$refs.proTcm.formData.forEach(function (val, index) {
        if (val.converRate === "") {
          proExchangerateIsEmpty = true;
          return;
        }
        if (val.setTime === "") {
          setTimeIssample = true;
          return;
        }
        if (val.setPerson === "") {
          setPersonIssample = true;
          return;
        }
        if (val.name === "") {
          proprocessNameIssample = true;
          return;
        }
        if (val.equSize === "") {
          equSizeIssample = true;
          return;
        }
        if (val.pieceHour === "") {
          pieceHourIssample = true;
          return;
        }
        if (val.pieceCycle === "") {
          pieceCycleIssample = true;
          return;
        }
        if (val.efficiencyPercent === "") {
          efficiencyPercentIssample = true;
          return;
        }
        if (val.directRate === "") {
          directRateIssample = true;
          return;
        }
        if (val.setUpRate === "") {
          setUpRateIssample = true;
          return;
        }
        if (val.directOperateQty === "") {
          directOperateQtyIssample = true;
          return;
        }
        if (val.variableRate === "") {
          variableRateIssample = true;
          return;
        }
        if (val.scrapPercent === "") {
          scrapPercentIssample = true;
          return;
        }
      });
      if (proExchangerateIsEmpty) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.exRquired"),
          "",
          "warning"
        );
        return;
      }
      if (setTimeIssample) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.setRequired"),
          "",
          "warning"
        );
        return;
      }
      if (setPersonIssample) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.ofMissing"),
          "",
          "warning"
        );
        return;
      }
      if (proprocessNameIssample) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.pleaseDescription"),
          "",
          "warning"
        );
        return;
      }
      if (equSizeIssample) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.pleaseSize"),
          "",
          "warning"
        );
        return;
      }
      if (pieceHourIssample) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.pleaseHour"),
          "",
          "warning"
        );
        return;
      }
      if (pieceCycleIssample) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.pleasePieceCycle"),
          "",
          "warning"
        );
        return;
      }
      if (efficiencyPercentIssample) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.pleaseEfficiency"),
          "",
          "warning"
        );
        return;
      }
      if (directRateIssample) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.pleaseRate"),
          "",
          "warning"
        );
        return;
      }
      if (setUpRateIssample) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.setUpRates"),
          "",
          "warning"
        );
        return;
      }
      if (directOperateQtyIssample) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.pleaseOperators"),
          "",
          "warning"
        );
        return;
      }
      if (variableRateIssample) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.pleaseVariable"),
          "",
          "warning"
        );
        return;
      }
      if (scrapPercentIssample) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.pleaseScrap"),
          "",
          "warning"
        );
        return;
      }
      // 判断采购必填项
      let processNameIssample = false;
      let quantityIssample = false;
      let unitIssample = false;
      let unitCostIssample = false;
      let purExchangerateIsEmpty = false;
      this.$refs.purTcm.formData.forEach(function (val, index) {
        if (val.converRate === "") {
          purExchangerateIsEmpty = true;
          return;
        }
        if (val.name === "") {
          processNameIssample = true;
          return;
        }
        if (val.quantity === "") {
          quantityIssample = true;
          return;
        }
        if (val.unit === "") {
          unitIssample = true;
          return;
        }
        if (val.unitCost === "") {
          unitCostIssample = true;
          return;
        }
      });
      if (purExchangerateIsEmpty) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.exRquired"),
          "",
          "warning"
        );
        return;
      }
      if (processNameIssample) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.pleaseProcess"),
          "",
          "warning"
        );
        return;
      }
      if (quantityIssample) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.pleaseAssembly"),
          "",
          "warning"
        );
        return;
      }
      if (unitIssample) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.pleaseMeasure"),
          "",
          "warning"
        );
        return;
      }
      if (unitCostIssample) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.pleaseUoM"),
          "",
          "warning"
        );
        return;
      }
      // 管消费判断
      if (
        this.$refs.ohTcm.form.sga === "" ||
        this.$refs.ohTcm.form.sga === null
      ) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.sgaRequired"),
          "",
          "warning"
        );
        return;
      }
      let materialLine = this.$refs.rwTcm.formData;
      materialLine.forEach(function (val, index) {
        val.type = 0;
        // val.id = "";
      });
      let purchaseLine = this.$refs.purTcm.formData;
      purchaseLine.forEach(function (val, index) {
        val.type = 0;
        // val.id = ''
      });
      let processLine = this.$refs.proTcm.formData;
      processLine.forEach(function (val, index) {
        val.type = 0;
        // val.id = ''
      });
      this.previewData = {
        id: this.id,
        templeteId: this.templateId,
        materialCost_RM: this.$refs.rwTcm.materialCost_RM,
        materialLine: materialLine,
        materialDelIds: this.$refs.rwTcm.materialDelIds,
        purchaseCost_PUR: this.$refs.purTcm.purchaseCost_PUR,
        purchaseLine: purchaseLine,
        purchaseDelIds: this.$refs.purTcm.purchaseDelIds,
        laborCost_PRO: this.$refs.proTcm.laborCost_PRO,
        setUpCost_PRO: this.$refs.proTcm.setUpCost_PRO,
        burdenCost_PRO: this.$refs.proTcm.burdenCost_PRO,
        manufacturingCost_PRO: this.$refs.proTcm.manufacturingCost_PRO,
        processLine: processLine,
        processDelIds: this.$refs.proTcm.processDelIds,
        calculationMethod: this.$refs.plTcm.form.calculationMethod,
        calculationMethod_Freight:
          this.$refs.plTcm.form.calculationMethod_Freight,
        calculationMethod_Ware: this.$refs.plTcm.form.calculationMethod_Ware,
        calculationMethod_Package:
          this.$refs.plTcm.form.calculationMethod_Package,
        calculationMethod_Taxes: this.$refs.plTcm.form.calculationMethod_Taxes,
        freightCost: this.$refs.plTcm.form.freightCost,
        wareHouseCost: this.$refs.plTcm.form.wareHouseCost,
        packageCost: this.$refs.plTcm.form.packageCost,
        otherCost: this.$refs.plTcm.form.otherCost,
        amortizationDescription: this.$refs.plTcm.form.amortizationDescription,
        totalPieces: this.$refs.plTcm.form.totalPieces,
        totalAmortize: this.$refs.plTcm.form.totalAmortize,
        amortizeCost: this.$refs.plTcm.form.amortizeCost,
        sga: this.$refs.ohTcm.form.sga,
        sgaCost: this.$refs.ohTcm.form.sgaCost,
        materialProfit: this.$refs.ohTcm.form.materialProfit,
        addProfit: this.$refs.ohTcm.form.addProfit,
        purchaseProfit: this.$refs.ohTcm.form.purchaseProfit,
        totalProfit: this.$refs.ohTcm.form.totalProfit,
        totalProfitCost: this.$refs.ohTcm.form.totalProfitCost,
        materialPC: this.$refs.ohTcm.form.materialPC,
        materialCost_SUM: this.$refs.sumTcm.form.materialCost_SUM,
        setUpCost_SUM: this.$refs.sumTcm.form.setUpCost_SUM,
        purchaseCost_SUM: this.$refs.sumTcm.form.purchaseCost_SUM,
        packageCost_SUM: this.$refs.sumTcm.form.packageCost_SUM,
        laborCost_SUM: this.$refs.sumTcm.form.laborCost_SUM,
        otherCost_SUM: this.$refs.sumTcm.form.otherCost_SUM,
        burdenCost_SUM: this.$refs.sumTcm.form.burdenCost_SUM,
        amortizeCost_SUM: this.$refs.sumTcm.form.amortizeCost_SUM,
        sgaProfit_SUM: this.$refs.sumTcm.form.sgaProfit_SUM,
        scrapCost_SUM: this.$refs.sumTcm.form.scrapCost_SUM,
        totalPriceCurr_SUM: this.$refs.sumTcm.form.totalPriceCurr_SUM,
        totalPriceReport_SUM: this.$refs.sumTcm.form.totalPriceReport_SUM,
        attachmentList: this.$refs.rwTcm.materialfileList,
        // peakVolume: this.$refs.gen.genList[0].peakVolume,
        // lifetimeVolume: this.$refs.gen.genList[0].lifetimeVolume,
        lotSize: this.$refs.gen.form.lotSize,
        productionYear: this.$refs.gen.form.productionYear,
        IsDraft: 0,
        importType: this.operateType === "editImport" ? 2 : 0,
      };
      this.$refs.previewCla.attachmentList = [];
      this.$refs.rwTcm.materialfileList.forEach(function (val, index) {
        _this.$refs.previewCla.attachmentList.push(val.url);
      });
      this.$refs.previewCla.CommId = this.$refs.gen.form.commodityId;
      this.previewShow = true;
    },
    // 取消预览
    canclePreview() {
      this.previewShow = false;
    },
    // 提交
    submitPreview() {
      console.log(this.operateType, "this.operateType");
      console.log(this.pictureList, "this.pictureList");
      console.log(
        this.previewData.attachmentList,
        "this.previewData.attachmentList"
      );
      this.submitEditImport();
      // if (this.operateType == "editImport") {
      //   this.submitEditImport();
      // } else {
      //   this.submitType = "submitAll";
      //   this.uploadFiles(this.previewData.attachmentList);
      // }
    },
    submitEditImport() {
      let pictures = this.$refs.gen.fileListx;
      this.uploadFilesGen(pictures);
    },
    // 提交修改后的gen
    submitGen() {
      // let fxForm = this.$refs.fx.form
      let genForm = this.$refs.gen.form;
      let genList = this.$refs.gen.genList;
      let mailTable = this.$refs.gen.mailTable;
      let userIds = [];
      mailTable.forEach(function (val, index) {
        userIds.push(val.id);
      });
      let data = {
        id: this.id,
        templeteId: "",
        // rate: fxForm.rate,
        // currencyRateId: fxForm.currencyRateId,
        quoteTypeId: genForm.quoteTypeId,
        programName: genForm.programName,
        ecrno: genForm.ecrno,
        commodityId: genForm.commodityId,
        partDescription: genForm.partDescription,
        partNumber: genForm.partNumber,
        partRevision: genForm.partRevision,
        lotSize: genForm.lotSize,
        productionYear: genForm.productionYear,
        doDate: genForm.doDate,
        approvedTime: genForm.approvedTime,
        urgencyId: genForm.urgencyId,
        // comment: genForm.comment,
        genList: genList,
        attachmentList: this.pictureListGen,
        mailList: [],
        UserId: "",
      };
      AddOrUpdateForAppllicant(
        data,
        (response) => {
          if (response && response.code == 200) {
            // this.submit()
            this.submitType = "submitAll";
            this.uploadFiles(this.previewData.attachmentList);
          }
        },
        (errorRes) => {
          this.uploadStatusGen = false;
        }
      );
    },
    // 提交操作列表
    submit() {
      this.previewData.attachmentList = this.pictureList;
      // this.previewData.attachmentList
      // console.log(this.pictureListGen);
      // this.previewData.attachmentList = this.pictureList.concat(
      //   this.pictureListGen
      // );

      let userIds = [];
      this.$refs.previewCla.mailTable.forEach(function (val, index) {
        userIds.push(val.id);
      });
      this.previewData.UserId = userIds.toString();
      let formData = this.$refs.purTcm.formData;
      let lstPrice = [];
      formData.forEach((item) => {
        if (item.oldData) {
          item.oldData.CompleteApplyId = item.completeApplyId;
          lstPrice.push(item.oldData);
        } else {
          lstPrice.push(null);
        }
      });
      if (lstPrice.length) {
        this.previewData.lstPrice = lstPrice;
      }
      this.previewData.CommodityId = this.CommodityId;
      console.log(
        this.previewData.attachmentList,
        " this.previewData.attachmentList"
      );

      TCMDataEdit(this.previewData, (response) => {
        if (response && response.code == 200) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.operateSuccessfully"),
            "",
            "success"
          );
          this.$route.meta.showTag = false;
          this.$router.back(-1);
        }
      });
    },
    // 文件上传
    uploadFiles(files) {
      let _this = this;
      this.uploadStatus = false;
      let format = new FormData();
      // 需要上传的文件
      let uploadList = [];
      this.pictureList = [];
      files.forEach(function (value, key) {
        if (value.raw) {
          format.append("file", value.raw);
          uploadList.push(value);
        } else {
          _this.pictureList.push(value);
        }
      });
      if (uploadList.length > 0) {
        uploadFile(format, (response) => {
          if (response && response.code == 200) {
            response.data.forEach(function (val, key) {
              _this.pictureList.push(val);
            });
            _this.uploadStatus = true;
          }
        });
      } else {
        _this.uploadStatus = true;
      }
    },
    uploadFilesGen(files) {
      let _this = this;
      this.pictureListGen = [];
      this.uploadStatusGen = false;
      let format = new FormData();
      // 需要上传的文件
      let uploadList = [];
      files.forEach(function (value, key) {
        if (value.raw) {
          format.append("file", value.raw);
          uploadList.push(value);
        } else {
          _this.pictureListGen.push(value);
        }
      });
      if (uploadList.length > 0) {
        uploadFile(format, (response) => {
          if (response && response.code == 200) {
            response.data.forEach(function (val, key) {
              _this.pictureListGen.push(val);
            });
            _this.uploadStatusGen = true;
          }
        });
      } else {
        _this.uploadStatusGen = true;
      }
    },
    Goback() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped lang="scss">
.tcm-add {
  position: relative;
  padding-top: 150px;
  height: 100%;
}
.tcm-title {
  border-bottom: 1px solid #9a9a9a;
  padding: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 32px;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  background: #fff;
}
.hover-menu {
  width: 80px;
  padding: 15px 0;
  box-sizing: border-box;
  background-color: rgba(215, 215, 215, 0.474509803921569);
  border-radius: 5px;
  position: fixed;
  top: 200px;
  right: 0;
  z-index: 888;
}
.hover-btn {
  font-size: 18px;
  font-weight: bold;
  width: 50px;
  color: #fff;
  background: rgba(174, 174, 174, 0.623529411764706);
  text-align: center;
  border-radius: 5px;
  padding: 8px 0;
  margin-left: 30px;
  cursor: pointer;
}
.choose-btn {
  font-size: 18px;
  font-weight: bold;
  width: 50px;
  margin-left: 30px;
  text-align: center;
  border-radius: 0px 5px 5px 0;
  padding: 8px 0;
  background-color: #fff;
  border-left: 4px solid var(--themeColor);
  cursor: pointer;
}
.mgr-20 {
  margin-right: 20px;
}
.total-price {
  background: var(--themeColor);
  padding: 5px 10px;
  color: #fff;
  border-radius: 5px;
}
.crumbs-box {
  padding-left: 20px;
  display: flex;
  background-color: #f2f2f2;
  align-items: center;
  height: 30px;
  font-size: 16px;
  z-index: 99;
  color: #3e4954;
  .crumbs-box-item {
    display: flex;
    align-items: center;
  }
  .el-icon {
    margin: 0 10px;
  }
  .parent {
    color: #2f4cdd;
    cursor: pointer;
  }
  .line {
    margin: 0 15px;
    width: 2px;
    height: 15px;
    background: #3e4954;
  }
}
.top-box {
  position: fixed;
  width: calc(100% - 260px);
  top: 70px;
  left: 235px;
  z-index: 99;
  background-color: #fff;
}
.step-menubox {
  width: 100%;
  background: #fff;
}
.main {
  width: 100%;
  min-height: 100%;
  background: url("../../assets/img/car-table-bg.png") no-repeat;
  background-position: right 4% bottom 0;
  background-size: 650px 600px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 20px;
}
</style>
