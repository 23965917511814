<template>
  <div class="main">
    <div class="top-box">
      <div class="main-top_handle">
        <div class="title">CCL - {{ $t("other.other.DirectMaterial") }}</div>
      </div>
      <div class="handle-btn flex">
        <div v-if="BtnLimits.AddRow" class="handle-btn-item" @click="addRow">
          {{ $t("other.other.AddRow") }}
        </div>
        <div
          v-if="BtnLimits.AddColumn"
          class="handle-btn-item"
          @click="addColum"
        >
          {{ $t("other.other.AddColumn") }}
        </div>
        <div
          v-if="BtnLimits.Submit"
          class="handle-btn-item"
          @click="submitTable"
        >
          {{ $t("other.other.Submit") }}
        </div>
      </div>
    </div>

    <div class="table-cla">
      <div
        style="
          max-width: calc(100% - 30px);
          margin-right: 10px;
          overflow-x: auto;
        "
      >
        <table class="add-table">
          <tr style="background: #fff">
            <td>
              <div style="width: 60px">{{ $t("other.other.Operate") }}</div>
            </td>
            <td>
              <div style="width: 150px">{{ $t("ccls.ccl.type") }}</div>
            </td>
            <td>
              <div style="width: 150px">{{ $t("ccls.ccl.cclSize") }}</div>
            </td>
            <td>
              <div style="width: 214px">{{ $t("ccls.ccl.cclType") }}</div>
            </td>
            <!-- <td>
								<div style="width: 100px;">{{$t('ccls.ccl.lamination')}}</div>
							</td>
							<td>
								<div style="width: 100px;">{{$t('ccls.ccl.syLamination')}}</div>
							</td> -->
            <td v-for="(it, ind) in cycleLst">
              <el-input
                v-model="it.colName"
                :disabled="type == 'detail'"
                placeholder=""
                style="width: 100px"
              />
            </td>
          </tr>
          <tr v-for="(item, index) in tableData">
            <td>
              <div
                v-if="BtnLimits.Delete"
                class="btn-text"
                @click="deleteRow(index)"
              >
                {{ $t("unifiedPrompts.unifiedPrompt.delete") }}
              </div>
            </td>
            <td>
              <el-select
                clearable
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                filterable
                v-model="item.rowCategory"
                @change="ChangeTypeOptions(item.rowCategory, item)"
                placeholder=""
                style="width: 150px"
                :disabled="type == 'detail'"
              >
                <el-option
                  v-for="it in typeOptions"
                  :key="it.id"
                  :label="it.name"
                  :value="it.id"
                />
              </el-select>
            </td>
            <td>
              <el-select
                clearable
                v-show="item.rowCategory == 1"
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                filterable
                v-model="item.rowSize"
                placeholder=""
                style="width: 150px"
                :disabled="type == 'detail'"
              >
                <el-option
                  v-for="it in sizeOptions"
                  :key="it.id"
                  :label="it.cclSize"
                  :value="it.cclSize"
                />
              </el-select>
              <div v-for="it in sizeOptions">
                <span
                  v-show="it.cclSize == item.rowSize && item.rowCategory == 1"
                  >({{ it.cclLength }}mm × {{ it.cclWidth }}mm)</span
                >
              </div>
            </td>
            <td>
              <el-input
                v-model="item.rowType"
                placeholder=""
                style="width: 214px"
                :disabled="type == 'detail'"
              />
            </td>

            <!-- <td>
								<el-input v-model="item.materialName" placeholder="" style="width: 100px;" :disabled="type == 'detail'"/>
							</td>
							<td>
								<el-input v-model="item.materialName" placeholder="" style="width: 100px;" :disabled="type == 'detail'"/>
							</td> -->
            <td v-for="(it, ind) in item.directMaterialLst">
              <el-input
                v-model="it.value"
                :disabled="type == 'detail'"
                @input="(v) => (it.value = v.replace(/[^\d.]/g, ''))"
                placeholder=""
                style="width: 100px"
              />
            </td>
          </tr>
          <tr v-show="cycleLst.length > 1">
            <td>
              <div style="width: 60px"></div>
            </td>
            <td>
              <div style="width: 100px"></div>
            </td>
            <td>
              <div style="width: 100px"></div>
            </td>
            <td>
              <div style="width: 100px"></div>
            </td>
            <!-- <td>
								<div style="width: 100px;"></div>
							</td>
							<td>
								<div style="width: 100px;"></div>
							</td> -->
            <td v-for="(it, ind) in cycleLst">
              <div
                class="btn-text"
                v-show="ind != 0 && BtnLimits.Delete"
                @click="deleteColum(ind)"
              >
                Delete
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {
  AddDirectMaterial,
  GetDirectMaterialList,
  loadCCLSizeDetails,
} from "../../assets/js/requestApi.js";
import commonUtil from "../../assets/js/commonUtil.js";
import "../../assets/css/custom-el-input-red.css";
export default {
  data() {
    return {
      form: {
        moldClose: "",
        moldOpen: "",
        ejection: "",
        injection: "",
        holdingTime: "",
        coolingTime: "",
        total: "",
        wall: "",
        material: "",
      },
      wallOptions: [],
      materialOptions: [],
      sizeOptions: [],
      tableData: [],
      cycleLst: [],
      typeOptions: [
        {
          id: "1",
          name: "Core",
        },
        {
          id: "2",
          name: "PP",
        },
        {
          id: "3",
          name: "Copper Foil",
        },
      ],
      type: "",
      timer: "",
      BtnLimits: {},
    };
  },
  mounted() {
    this.getInit();
    this.getUnits();
    this.getSizeOptions();
  },
  beforeUnmount() {
    clearTimeout(this.timer);
  },
  methods: {
    getUnits() {
      this.timer = setInterval(this.gettime, 1000);
    },
    gettime() {
      this.BtnLimits = JSON.parse(sessionStorage.getItem("Btn"));
    },
    getInit() {
      let _this = this;
      GetDirectMaterialList("", (response) => {
        if (response && response.code == 200) {
          this.tableData = response.result;
          if (this.tableData.length > 0) {
            this.cycleLst = JSON.parse(
              JSON.stringify(this.tableData[0].directMaterialLst)
            );
          }
        }
      });
    },
    // 获取size下拉选项
    getSizeOptions() {
      loadCCLSizeDetails(
        {
          page: 1,
          limit: 999999,
        },
        (response) => {
          if (response && response.code == 200) {
            this.sizeOptions = response.data;
          } else {
            commonUtil.showTipInfo(
              this.$t("userlists.userlist.inqueryTimeout")
            );
          }
        }
      );
    },
    // 提交维护数据表格
    submitTable() {
      let data = [];
      let _this = this;
      let rowList = [];
      let colmunList = [];
      this.tableData.forEach(function (val, index) {
        if (val.directMaterialLst.length == 0) {
          data.push({
            rowSize: val.rowSize,
            rowCategory: val.rowCategory,
            rowType: val.rowType,
            rowIndex: index,
            colName: "",
            colIndex: 0,
            value: "",
          });
        } else {
          val.directMaterialLst.forEach(function (cycleVal, cycleIndex) {
            data.push({
              rowSize: val.rowSize,
              rowCategory: val.rowCategory,
              rowType: val.rowType,
              rowIndex: index,
              colName: _this.cycleLst[cycleIndex].colName,
              colIndex: cycleIndex,
              value: cycleVal.value,
            });
          });
        }

        rowList.push(val.rowType + val.rowSize + val.rowCategory);
      });
      let rowSizeIsNull = false;
      let rowTypeIsNull = false;
      let colmunNameIsNull = false;
      // let valueNameIsNull = false
      data.forEach(function (val, index) {
        if (val.rowSize === "" && val.rowCategory == "1") {
          rowSizeIsNull = true;
        }
        if (val.rowType === "") {
          rowTypeIsNull = true;
        }
        if (val.colName === "") {
          colmunNameIsNull = true;
        }
        // if (val.value === '') {
        // 	valueNameIsNull = true
        // }
      });
      this.cycleLst.forEach(function (val, index) {
        colmunList.push(val.colName);
      });
      colmunList.push("CCL Type");
      colmunList.push("Size");
      if (rowSizeIsNull) {
        commonUtil.showTipInfo(
          this.$t("ccls.ccl.pleaseCompleteInfo"),
          "",
          "warning"
        );
        return;
      }
      if (rowTypeIsNull) {
        commonUtil.showTipInfo(
          this.$t("ccls.ccl.pleaseCompleteInfo"),
          "",
          "warning"
        );
        return;
      }
      // if (colmunNameIsNull) {
      if (colmunNameIsNull && _this.cycleLst.length > 0) {
        commonUtil.showTipInfo(
          this.$t("ccls.ccl.pleaseCompleteInfo"),
          "",
          "warning"
        );
        return;
      }
      if (colmunNameIsNull && _this.cycleLst.length == 0) {
        commonUtil.showTipInfo(
          this.$t("ccls.ccl.pleaseCompleteSupplierInfo"),
          "",
          "warning"
        );
        return;
      }

      // }
      if (Array.from(new Set(rowList)).length != rowList.length) {
        commonUtil.showTipInfo(this.$t("ccls.ccl.csRepeat"), "", "warning");
        return;
      }
      if (Array.from(new Set(colmunList)).length != colmunList.length) {
        commonUtil.showTipInfo(this.$t("ccls.ccl.columnRepeat"), "", "warning");
        return;
      }
      AddDirectMaterial(data, (response) => {
        if (response && response.code == 200) {
          this.getInit();
        }
      });
    },
    // 添加列
    addColum() {
      this.cycleLst.push({
        colName: "",
      });
      this.tableData.forEach(function (val, index) {
        val.directMaterialLst.push({
          value: "",
          colName: "",
        });
      });
    },
    // 添加行
    addRow() {
      let rowData = {
        rowSize: "",
        rowType: "",
        rowCategory: "1",
        directMaterialLst: [],
      };
      this.cycleLst.forEach(function (val, index) {
        rowData.directMaterialLst.push({
          value: "",
          colName: "",
        });
      });
      this.tableData.push(rowData);
    },
    // 删除行
    deleteRow(index) {
      this.tableData.splice(index, 1);
    },
    // 删除列
    deleteColum(index) {
      this.tableData.forEach(function (val, ind) {
        val.directMaterialLst.splice(index, 1);
      });
      this.cycleLst.splice(index, 1);
    },
    ChangeTypeOptions(rowCategory, item) {
      if (rowCategory !== 1) {
        item.rowSize = "";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  width: 100%;
  padding-top: 70px;
  width: 100%;
  min-height: calc(100vh - 155px);
  background: url("../../assets/img/car-table-bg.png") no-repeat right;
  background-position: right 10% bottom 0;
  background-size: 650px 600px;
}
.top-box {
  padding-top: 15px;
  width: 100%;
  position: fixed;
  top: 70px;
  left: 235px;
  z-index: 999;
  background: #f2f2f2;
}

.handle-btn {
  align-items: center;
  height: 40px;
  .handle-btn-item {
    padding: 0 20px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    background: #b2bde7;
    color: #fff;
    border-radius: 25px;
    cursor: pointer;
    margin-left: 10px;
  }
}
.add-top {
  padding: 20px;
  box-sizing: border-box;
  border-bottom: 1px solid #8c8c8c;
}
.dis-flex {
  display: flex;
  justify-content: space-around;
}
.line-32 {
  line-height: 32px;
}
.height-32 {
  height: 32px;
}
.padding-20 {
  padding: 20px;
  box-sizing: border-box;
}
.mgr-20 {
  margin-right: 20px;
}
.mgr-10 {
  margin-right: 10px;
}
.mgt-15 {
  margin-top: 12px;
}
.align-right {
  text-align: right;
}
.add-top-text {
  font-size: 14px;
  width: 150px;
}
.operate-btn {
  border-radius: 5px;
  background-color: var(--themeColor);
  color: #fff;
  text-align: center;
  padding: 7px 10px;
  display: inline-block;
  min-width: 80px;
  cursor: pointer;
}
.size-16 {
  font-size: 16px;
}
.text-sub {
  font-size: 14px;
  margin-left: 20px;
  margin-top: 10px;
}
.btn-position {
  position: fixed;
  z-index: 10;
  top: 114px;
  width: calc(100% - 235px);
  padding: 20px 0;
  left: 222px;
  background-color: #fff;
}
.table-cla {
  position: relative;
  display: flex;
  margin-top: 10px;
}
.add-table {
  border-collapse: collapse;
}
.add-table td {
  border: 1px solid #dcdfe6;
  padding: 5px 10px;
  text-align: center;
}
.btn-text {
  color: #2f4cdd;
  cursor: pointer;
  font-size: 12px;
}
:deep .el-input__wrapper {
  background: transparent;
}
</style>