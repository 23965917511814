<template>
  <div class="tcm-content">
    <!-- <div class="tcm-content-title">
      {{ $t("changePriceLists.changePriceList.sgaProfitScrap") }}
    </div> -->
    <div class="tcm-content-form">
      <el-form :model="form" :rules="rules" label-width="300px">
        <div class="form-type-title">
          {{ $t("changePriceLists.changePriceList.sgas") }}
        </div>
        <el-row>
          <el-col :span="24">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.sga')"
              prop="sga"
            >
              <el-input
                v-model="form.sga"
                :disabled="operateType == 'detail'"
                style="width: 214px"
                @input="transferFormat($event, form, 'sga'), tocompute"
                @change="tocompute"
                @blur="tocompute"
              >
                <template #suffix>
                  <el-icon class="el-input__icon">%</el-icon>
                </template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- el-row el-row -->
        <!-- el-row el-input template -->
        <el-row>
          <el-col :span="24">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.sgaCost')"
            >
              <el-input v-model="form.sgaCost" disabled style="width: 214px" />
            </el-form-item>
          </el-col>
        </el-row>
        <div class="form-type-title">
          {{ $t("changePriceLists.changePriceList.profit") }}
        </div>
        <el-row>
          <el-col :span="24">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.profitMaterial')"
            >
              <el-input
                v-model="form.materialProfit"
                :disabled="operateType == 'detail'"
                style="width: 214px"
                @input="
                  transferFormat($event, form, 'materialProfit'), tocompute
                "
                @change="tocompute"
                @blur="tocompute"
              >
                <template #suffix>
                  <el-icon class="el-input__icon">%</el-icon>
                </template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.profitValue')"
            >
              <el-input
                v-model="form.purchaseProfit"
                :disabled="operateType == 'detail'"
                style="width: 214px"
                @input="
                  transferFormat($event, form, 'purchaseProfit'), tocompute
                "
                @change="tocompute"
                @blur="tocompute"
              >
                <template #suffix>
                  <el-icon class="el-input__icon">%</el-icon>
                </template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.profitComponents')"
            >
              <el-input
                v-model="form.addProfit"
                :disabled="operateType == 'detail'"
                style="width: 214px"
                @input="transferFormat($event, form, 'addProfit'), tocompute"
                @change="tocompute"
                @blur="tocompute"
              >
                <template #suffix>
                  <el-icon class="el-input__icon">%</el-icon>
                </template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.totalProfit')"
            >
              <el-input
                v-model="form.totalProfit"
                disabled
                style="width: 214px"
              >
                <template #suffix>
                  <el-icon class="el-input__icon">%</el-icon>
                </template>
              </el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="8"></el-col> -->
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.totalprofitCost')"
            >
              <el-input
                v-model="form.totalProfitCost"
                disabled
                style="width: 214px"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="tcm-content-form">
      <el-form :model="form" :rules="rules" label-width="300px">
        <el-row>
          <el-col :span="24">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.materialScrap')"
            >
              <el-input
                v-model="form.materialPC"
                :disabled="operateType == 'detail'"
                style="width: 214px"
                @input="transferFormat($event, form, 'materialPC'), tocompute"
                @change="tocompute"
                @blur="tocompute"
              >
                <template #suffix>
                  <el-icon class="el-input__icon">%</el-icon>
                </template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- <table class="tcm-content-table"> -->
      <!-- <tr>
          <td class="table-title" style="width: 155px; text-align: right">
            {{ $t("changePriceLists.changePriceList.materialScrap") }}
          </td>
          <td style="width: 250px">
            <el-input
              v-model="form.materialPC"
              :disabled="operateType == 'detail'"
              style="width: 214px"
              @input="transferFormat($event, form, 'materialPC'), tocompute"
              @change="tocompute"
              @blur="tocompute"
            >
              <template #suffix>
                <el-icon class="el-input__icon">%</el-icon>
              </template>
            </el-input>
          </td>
          <td class="table-title" style="width: 155px"></td>
          <td style="width: 250px"></td> -->
      <!-- <td class="table-title" style="width: 350px;">Freight + Warehousing Cost</td>
						<td><el-input v-model="form.ExchangeRate" style="width: 214px;" /></td> -->
      <!-- </tr> -->
      <!-- <tr>
						<td class="table-title">Material Scrap  Credit</td>
						<td><el-input v-model="form.ExchangeRate" style="width: 214px;" /></td>
						<td class="table-title">Total Sell Price(Supplier Local Currency)</td>
						<td><el-input v-model="form.ExchangeRate" style="width: 214px;" /></td>
					</tr>
					<tr>
						<td class="table-title">Process Scrap Cost</td>
						<td><el-input v-model="form.ExchangeRate" style="width: 214px;" /></td>
						<td class="table-title">Total Sell Price (Customer Paid Currency)</td>
						<td><el-input v-model="form.ExchangeRate" style="width: 214px;" /></td>
					</tr>
					<tr>
						<td class="table-title">Packaging Cost</td>
						<td><el-input v-model="form.ExchangeRate" style="width: 214px;" /></td>
						<td class="table-title">Amortization Cost + Customs, Duties,Taxes & Fees</td>
						<td><el-input v-model="form.ExchangeRate" style="width: 214px;" /></td>
					</tr> -->
      <!-- </table> -->
    </div>
  </div>
</template>
<script>
export default {
  props: ["operateType"],
  data() {
    return {
      form: {
        sga: "",
        sgaCost: "",
        materialProfit: "",
        addProfit: "",
        purchaseProfit: "",
        totalProfit: "",
        totalProfitCost: "",
        materialPC: "",
        ExchangeRate: "",
      },
      // currency选项
      CurrencyOptions: [
        {
          value: "Option1",
          label: "Option1",
        },
        {
          value: "Option2",
          label: "Option2",
        },
      ],
      //
      // ReportCurrency选项
      ReportCurrencyOptions: [
        {
          value: "Option1",
          label: "Option1",
        },
        {
          value: "Option2",
          label: "Option2",
        },
      ],
      rules: {
        sga: [{ required: true, message: "请输入sga", trigger: "blur" }],
      },
    };
  },
  methods: {
    // 计算
    tocompute() {
      let threeAll = 0;
      const materialCost_RM = parseFloat(
        this.$parent.$refs.rwTcm.materialCost_RM === "" ||
          this.$parent.$refs.rwTcm.materialCost_RM === null
          ? 0
          : this.$parent.$refs.rwTcm.materialCost_RM
      );
      const purchaseCost_PUR = parseFloat(
        this.$parent.$refs.purTcm.purchaseCost_PUR === "" ||
          this.$parent.$refs.purTcm.purchaseCost_PUR === null
          ? 0
          : this.$parent.$refs.purTcm.purchaseCost_PUR
      );
      const laborCost_PRO = parseFloat(
        this.$parent.$refs.proTcm.laborCost_PRO === "" ||
          this.$parent.$refs.proTcm.laborCost_PRO === null
          ? 0
          : this.$parent.$refs.proTcm.laborCost_PRO
      );
      const burdenCost_PRO = parseFloat(
        this.$parent.$refs.proTcm.burdenCost_PRO === "" ||
          this.$parent.$refs.proTcm.burdenCost_PRO === null
          ? 0
          : this.$parent.$refs.proTcm.burdenCost_PRO
      );
      const setUpCost_PRO = parseFloat(
        this.$parent.$refs.proTcm.setUpCost_PRO === "" ||
          this.$parent.$refs.proTcm.setUpCost_PRO === null
          ? 0
          : this.$parent.$refs.proTcm.setUpCost_PRO
      );
      threeAll =
        materialCost_RM +
        purchaseCost_PUR +
        laborCost_PRO +
        burdenCost_PRO +
        setUpCost_PRO;

      //     if (
      //       this.$parent.$refs.rwTcm.materialCost_RM != "" &&
      //       this.$parent.$refs.purTcm.purchaseCost_PUR != "" &&
      //       this.$parent.$refs.proTcm.laborCost_PRO != "" &&
      //       this.$parent.$refs.proTcm.burdenCost_PRO != "" &&
      //       this.$parent.$refs.proTcm.setUpCost_PRO != ""
      // // && this.form.sga !== "" && this.form.sga !== null
      //     ) {

      this.form.totalProfitCost = 0;

      // 重写的业务逻辑公式： profit on material % * raw material cost + profit on purchased components %* purchased components cost + profit on value add % * (driect labor cost + burdent cost + set up cost)
      const materialProfitPercent =
        parseFloat(
          this.form.materialProfit === "" || this.form.materialProfit === null
            ? 0
            : this.form.materialProfit
        ) / 100;
      const purchasedComponentsPercent =
        parseFloat(
          this.form.purchaseProfit === "" || this.form.purchaseProfit === null
            ? 0
            : this.form.purchaseProfit
        ) / 100;
      const addProfitPercent =
        parseFloat(
          this.form.addProfit === "" || this.form.addProfit === null
            ? 0
            : this.form.addProfit
        ) / 100;

      this.form.totalProfitCost =
        materialProfitPercent * materialCost_RM +
        purchasedComponentsPercent * purchaseCost_PUR +
        addProfitPercent * (laborCost_PRO + burdenCost_PRO + setUpCost_PRO);
      /****zj 20231009 注释后重写,如上是重写的内容
      if (
        this.form.materialProfit !== "" &&
        this.form.materialProfit !== null &&
        (this.form.addProfit === "" || this.form.purchaseProfit === "")
      ) {
        console.log(11);
        this.form.totalProfitCost =
          parseFloat(this.form.materialProfit / 100) *
            parseFloat(
              this.$parent.$refs.rwTcm.materialCost_RM === "" ||
                this.$parent.$refs.rwTcm.materialCost_RM === null
                ? 0
                : this.$parent.$refs.rwTcm.materialCost_RM
            ) +
          (this.form.addProfit == ""
            ? 0
            : parseFloat(this.form.addProfit) / 100) *
            parseFloat(
              this.$parent.$refs.purTcm.purchaseCost_PUR === "" ||
                this.$parent.$refs.purTcm.purchaseCost_PUR === null
                ? 0
                : this.$parent.$refs.purTcm.purchaseCost_PUR
            );
        // + (this.form.purchaseProfit == ""
        //          ? 0
        //          : parseFloat(this.form.purchaseProfit) / 100) *
        //          (parseFloat(this.$parent.$refs.proTcm.laborCost_PRO === '' || this.$parent.$refs.proTcm.laborCost_PRO === null ? 0 : this.$parent.$refs.proTcm.laborCost_PRO) +
        //        parseFloat(this.$parent.$refs.proTcm.burdenCost_PRO === '' || this.$parent.$refs.proTcm.burdenCost_PRO === null ? 0 : this.$parent.$refs.proTcm.burdenCost_PRO) +
        //        parseFloat(this.$parent.$refs.proTcm.setUpCost_PRO === '' || this.$parent.$refs.proTcm.setUpCost_PRO === null ? 0 : this.$parent.$refs.proTcm.setUpCost_PRO));
      } else if (
        this.form.addProfit !== "" &&
        this.form.addProfit !== null &&
        (this.form.materialProfit !== null || this.form.purchaseProfit == "")
      ) {
        console.log(22);
        this.form.totalProfitCost =
          ((this.form.materialProfit == ""
            ? 0
            : parseFloat(this.form.materialProfit / 100)) *
            parseFloat(
              this.$parent.$refs.rwTcm.materialCost_RM === "" ||
                this.$parent.$refs.rwTcm.materialCost_RM === null
                ? 0
                : this.$parent.$refs.rwTcm.materialCost_RM
            ) +
            parseFloat(this.form.addProfit) / 100) *
            parseFloat(
              this.$parent.$refs.purTcm.purchaseCost_PUR === "" ||
                this.$parent.$refs.purTcm.purchaseCost_PUR === null
                ? 0
                : this.$parent.$refs.purTcm.purchaseCost_PUR
            ) +
          (this.form.purchaseProfit == ""
            ? 0
            : parseFloat(this.form.purchaseProfit) / 100) *
            (parseFloat(
              this.$parent.$refs.proTcm.laborCost_PRO === "" ||
                this.$parent.$refs.proTcm.laborCost_PRO === null
                ? 0
                : this.$parent.$refs.proTcm.laborCost_PRO
            ) +
              parseFloat(
                this.$parent.$refs.proTcm.burdenCost_PRO === "" ||
                  this.$parent.$refs.proTcm.burdenCost_PRO === null
                  ? 0
                  : this.$parent.$refs.proTcm.burdenCost_PRO
              ) +
              parseFloat(
                this.$parent.$refs.proTcm.setUpCost_PRO === "" ||
                  this.$parent.$refs.proTcm.setUpCost_PRO === null
                  ? 0
                  : this.$parent.$refs.proTcm.setUpCost_PRO
              ));
      } else if (
        this.form.purchaseProfit !== "" &&
        this.form.purchaseProfit !== null &&
        (this.form.materialProfit !== null || this.form.addProfit == "")
      ) {
        console.log(33);
        console.log(this.form.purchaseProfit);
        this.form.totalProfitCost =
          (this.form.materialProfit == ""
            ? 0
            : parseFloat(this.form.materialProfit) / 100) *
            parseFloat(
              this.$parent.$refs.rwTcm.materialCost_RM === "" ||
                this.$parent.$refs.rwTcm.materialCost_RM === null
                ? 0
                : this.$parent.$refs.rwTcm.materialCost_RM
            ) +
          (this.form.addProfit == "" || this.form.addProfit == null
            ? 0
            : parseFloat(this.form.addProfit) / 100) *
            parseFloat(
              this.$parent.$refs.purTcm.purchaseCost_PUR === "" ||
                this.$parent.$refs.purTcm.purchaseCost_PUR === null
                ? 0
                : this.$parent.$refs.purTcm.purchaseCost_PUR
            ) +
          (parseFloat(this.form.purchaseProfit) / 100) *
            (parseFloat(
              this.$parent.$refs.proTcm.laborCost_PRO === "" ||
                this.$parent.$refs.proTcm.laborCost_PRO === null
                ? 0
                : this.$parent.$refs.proTcm.laborCost_PRO
            ) +
              parseFloat(
                this.$parent.$refs.proTcm.burdenCost_PRO === "" ||
                  this.$parent.$refs.proTcm.burdenCost_PRO === null
                  ? 0
                  : this.$parent.$refs.proTcm.burdenCost_PRO
              ) +
              parseFloat(
                this.$parent.$refs.proTcm.setUpCost_PRO === "" ||
                  this.$parent.$refs.proTcm.setUpCost_PRO === null
                  ? 0
                  : this.$parent.$refs.proTcm.setUpCost_PRO
              ));
      } else if (
        this.form.materialProfit !== "" &&
        this.form.materialProfit !== null &&
        this.form.addProfit !== "" &&
        this.form.addProfit !== null &&
        this.form.purchaseProfit !== "" &&
        this.form.purchaseProfit !== null
      ) {
        console.log(44);
        this.form.totalProfitCost =
          parseFloat(this.form.materialProfit / 100) *
            parseFloat(
              this.$parent.$refs.rwTcm.materialCost_RM === "" ||
                this.$parent.$refs.rwTcm.materialCost_RM === null
                ? 0
                : this.$parent.$refs.rwTcm.materialCost_RM
            ) +
          (parseFloat(this.form.addProfit) / 100) *
            parseFloat(
              this.$parent.$refs.purTcm.purchaseCost_PUR === "" ||
                this.$parent.$refs.purTcm.purchaseCost_PUR === null
                ? 0
                : this.$parent.$refs.purTcm.purchaseCost_PUR
            ) +
          (parseFloat(this.form.purchaseProfit) / 100) *
            (parseFloat(
              this.$parent.$refs.proTcm.laborCost_PRO === "" ||
                this.$parent.$refs.proTcm.laborCost_PRO === null
                ? 0
                : this.$parent.$refs.proTcm.laborCost_PRO
            ) +
              parseFloat(
                this.$parent.$refs.proTcm.burdenCost_PRO === "" ||
                  this.$parent.$refs.proTcm.burdenCost_PRO === null
                  ? 0
                  : this.$parent.$refs.proTcm.burdenCost_PRO
              ) +
              parseFloat(
                this.$parent.$refs.proTcm.setUpCost_PRO === "" ||
                  this.$parent.$refs.proTcm.setUpCost_PRO === null
                  ? 0
                  : this.$parent.$refs.proTcm.setUpCost_PRO
              ));
      }*/

      this.form.totalProfitCost = this.form.totalProfitCost.toFixed(12);
      // if (
      //   this.form.sgaCost !== "" && this.form.sgaCost !== null &&
      //   this.form.totalProfitCost !== "" && this.form.totalProfitCost != null &&
      //   this.form.totalProfitCost != 0 &&
      //   this.$parent.$refs.sumTcm
      // ) {
      //   console.log("this.form.sgaCost this.form.totalProfitCost");
      //   console.log(this.form.totalProfitCost);
      //   console.log(this.form.sgaCost);

      // }
      // }
      this.form.sgaCost = (
        (parseFloat(
          this.$parent.$refs.rwTcm.materialCost_RM === "" ||
            this.$parent.$refs.rwTcm.materialCost_RM === null
            ? 0
            : this.$parent.$refs.rwTcm.materialCost_RM
        ) +
          parseFloat(
            this.$parent.$refs.purTcm.purchaseCost_PUR === "" ||
              this.$parent.$refs.purTcm.purchaseCost_PUR === null
              ? 0
              : this.$parent.$refs.purTcm.purchaseCost_PUR
          ) +
          parseFloat(
            this.$parent.$refs.proTcm.laborCost_PRO === "" ||
              this.$parent.$refs.proTcm.laborCost_PRO === null
              ? 0
              : this.$parent.$refs.proTcm.laborCost_PRO
          ) +
          parseFloat(
            this.$parent.$refs.proTcm.burdenCost_PRO === "" ||
              this.$parent.$refs.proTcm.burdenCost_PRO === null
              ? 0
              : this.$parent.$refs.proTcm.burdenCost_PRO
          ) +
          parseFloat(
            this.$parent.$refs.proTcm.setUpCost_PRO === "" ||
              this.$parent.$refs.proTcm.setUpCost_PRO === null
              ? 0
              : this.$parent.$refs.proTcm.setUpCost_PRO
          )) *
        (parseFloat(
          this.form.sga === "" || this.form.sga === null ? 0 : this.form.sga
        ) /
          100)
      ).toFixed(12);

      if (this.$parent.$refs.sumTcm) {
        this.$parent.$refs.sumTcm.form.sgaProfit_SUM = (
          parseFloat(
            this.form.sgaCost === "" || this.form.sgaCost === null
              ? 0
              : this.form.sgaCost
          ) +
          parseFloat(
            this.form.totalProfitCost === "" ||
              this.form.totalProfitCost === null
              ? 0
              : this.form.totalProfitCost
          )
        ).toFixed(12);
        this.$parent.$refs.sumTcm.form.materialCost_SUM =
          this.$parent.$refs.rwTcm.materialCost_RM === "" ||
          this.$parent.$refs.rwTcm.materialCost_RM === null
            ? 0
            : this.$parent.$refs.rwTcm.materialCost_RM;
        this.$parent.$refs.sumTcm.form.setUpCost_SUM =
          this.$parent.$refs.proTcm.setUpCost_PRO === "" ||
          this.$parent.$refs.proTcm.setUpCost_PRO === null
            ? 0
            : this.$parent.$refs.proTcm.setUpCost_PRO;
        this.$parent.$refs.sumTcm.form.laborCost_SUM =
          this.$parent.$refs.proTcm.laborCost_PRO === "" ||
          this.$parent.$refs.proTcm.laborCost_PRO === null
            ? 0
            : this.$parent.$refs.proTcm.laborCost_PRO;
        this.$parent.$refs.sumTcm.form.burdenCost_SUM =
          this.$parent.$refs.proTcm.burdenCost_PRO === "" ||
          this.$parent.$refs.proTcm.burdenCost_PRO === null
            ? 0
            : this.$parent.$refs.proTcm.burdenCost_PRO;
        this.$parent.$refs.sumTcm.form.purchaseCost_SUM =
          this.$parent.$refs.purTcm.purchaseCost_PUR === "" ||
          this.$parent.$refs.purTcm.purchaseCost_PUR === null
            ? 0
            : this.$parent.$refs.purTcm.purchaseCost_PUR;
        let freightCost = "";
        let wareHouseCost = "";
        let packageCost = "";
        let otherCost = "";
        // if (this.$parent.$refs.plTcm.form.calculationMethod == 1) {
        //   freightCost =
        //     this.$parent.$refs.plTcm.form.freightCost === "" ||
        //     !this.$parent.$refs.plTcm.form.freightCost
        //       ? "0"
        //       : this.$parent.$refs.plTcm.form.freightCost;
        //   wareHouseCost =
        //     this.$parent.$refs.plTcm.form.wareHouseCost === "" ||
        //     !this.$parent.$refs.plTcm.form.wareHouseCost
        //       ? "0"
        //       : this.$parent.$refs.plTcm.form.wareHouseCost;
        //   packageCost =
        //     this.$parent.$refs.plTcm.form.packageCost === "" ||
        //     !this.$parent.$refs.plTcm.form.packageCost
        //       ? "0"
        //       : this.$parent.$refs.plTcm.form.packageCost;
        //   otherCost =
        //     this.$parent.$refs.plTcm.form.otherCost === "" ||
        //     !this.$parent.$refs.plTcm.form.otherCost
        //       ? "0"
        //       : this.$parent.$refs.plTcm.form.otherCost;
        // } else {
        //   freightCost =
        //     (this.$parent.$refs.plTcm.form.freightCost === "" ||
        //     !this.$parent.$refs.plTcm.form.freightCost
        //       ? "0"
        //       : parseFloat(this.$parent.$refs.plTcm.form.freightCost) / 100) *
        //     parseFloat(
        //       this.$parent.$refs.proTcm.manufacturingCost_PRO === "" ||
        //         !this.$parent.$refs.proTcm.manufacturingCost_PRO
        //         ? "0"
        //         : this.$parent.$refs.proTcm.manufacturingCost_PRO
        //     );
        //   wareHouseCost =
        //     (this.$parent.$refs.plTcm.form.wareHouseCost === "" ||
        //     !this.$parent.$refs.plTcm.form.wareHouseCost
        //       ? "0"
        //       : parseFloat(this.$parent.$refs.plTcm.form.wareHouseCost) / 100) *
        //     parseFloat(
        //       this.$parent.$refs.proTcm.manufacturingCost_PRO === "" ||
        //         !this.$parent.$refs.proTcm.manufacturingCost_PRO
        //         ? "0"
        //         : this.$parent.$refs.proTcm.manufacturingCost_PRO
        //     );
        //   packageCost =
        //     (this.$parent.$refs.plTcm.form.packageCost === "" ||
        //     !this.$parent.$refs.plTcm.form.packageCost
        //       ? "0"
        //       : parseFloat(this.$parent.$refs.plTcm.form.packageCost) / 100) *
        //     parseFloat(
        //       this.$parent.$refs.proTcm.manufacturingCost_PRO === "" ||
        //         !this.$parent.$refs.proTcm.manufacturingCost_PRO
        //         ? "0"
        //         : this.$parent.$refs.proTcm.manufacturingCost_PRO
        //     );
        //   otherCost =
        //     (this.$parent.$refs.plTcm.form.otherCost === "" ||
        //     !this.$parent.$refs.plTcm.form.otherCost
        //       ? "0"
        //       : parseFloat(this.$parent.$refs.plTcm.form.otherCost) / 100) *
        //     parseFloat(
        //       this.$parent.$refs.proTcm.manufacturingCost_PRO === "" ||
        //         !this.$parent.$refs.proTcm.manufacturingCost_PRO
        //         ? "0"
        //         : this.$parent.$refs.proTcm.manufacturingCost_PRO
        //     );
        //   this.$parent.$refs.plTcm.freightCostNum = freightCost.toFixed(12);
        //   this.$parent.$refs.plTcm.wareHouseCostNum = wareHouseCost.toFixed(12);
        //   this.$parent.$refs.plTcm.packageCostNum = packageCost.toFixed(12);
        //   this.$parent.$refs.plTcm.otherCostNum = otherCost.toFixed(12);
        // }
        if (this.$parent.$refs.plTcm.form.calculationMethod_Freight === 1) {
          freightCost =
            this.$parent.$refs.plTcm.form.freightCost === "" ||
            !this.$parent.$refs.plTcm.form.freightCost
              ? "0"
              : this.$parent.$refs.plTcm.form.freightCost;
        } else {
          freightCost =
            (this.$parent.$refs.plTcm.form.freightCost === "" ||
            !this.$parent.$refs.plTcm.form.freightCost
              ? "0"
              : parseFloat(this.$parent.$refs.plTcm.form.freightCost) / 100) *
            parseFloat(
              this.$parent.$refs.proTcm.manufacturingCost_PRO === "" ||
                !this.$parent.$refs.proTcm.manufacturingCost_PRO
                ? "0"
                : this.$parent.$refs.proTcm.manufacturingCost_PRO
            );
          this.$parent.$refs.plTcm.freightCostNum = freightCost.toFixed(12);
        }

        if (this.$parent.$refs.plTcm.form.calculationMethod_Ware === 1) {
          wareHouseCost =
            this.$parent.$refs.plTcm.form.wareHouseCost === "" ||
            !this.$parent.$refs.plTcm.form.wareHouseCost
              ? "0"
              : this.$parent.$refs.plTcm.form.wareHouseCost;
        } else {
          wareHouseCost =
            (this.$parent.$refs.plTcm.form.wareHouseCost === "" ||
            !this.$parent.$refs.plTcm.form.wareHouseCost
              ? "0"
              : parseFloat(this.$parent.$refs.plTcm.form.wareHouseCost) / 100) *
            parseFloat(
              this.$parent.$refs.proTcm.manufacturingCost_PRO === "" ||
                !this.$parent.$refs.proTcm.manufacturingCost_PRO
                ? "0"
                : this.$parent.$refs.proTcm.manufacturingCost_PRO
            );
          this.$parent.$refs.plTcm.wareHouseCostNum = wareHouseCost.toFixed(12);
        }

        if (this.$parent.$refs.plTcm.form.calculationMethod_Package === 1) {
          packageCost =
            this.$parent.$refs.plTcm.form.packageCost === "" ||
            !this.$parent.$refs.plTcm.form.packageCost
              ? "0"
              : this.$parent.$refs.plTcm.form.packageCost;
        } else {
          packageCost =
            (this.$parent.$refs.plTcm.form.packageCost === "" ||
            !this.$parent.$refs.plTcm.form.packageCost
              ? "0"
              : parseFloat(this.$parent.$refs.plTcm.form.packageCost) / 100) *
            parseFloat(
              this.$parent.$refs.proTcm.manufacturingCost_PRO === "" ||
                !this.$parent.$refs.proTcm.manufacturingCost_PRO
                ? "0"
                : this.$parent.$refs.proTcm.manufacturingCost_PRO
            );
          this.$parent.$refs.plTcm.packageCostNum = packageCost.toFixed(12);
        }

        if (this.$parent.$refs.plTcm.form.calculationMethod_Taxes === 1) {
          otherCost =
            this.$parent.$refs.plTcm.form.otherCost === "" ||
            !this.$parent.$refs.plTcm.form.otherCost
              ? "0"
              : this.$parent.$refs.plTcm.form.otherCost;
        } else {
          otherCost =
            (this.$parent.$refs.plTcm.form.otherCost === "" ||
            !this.$parent.$refs.plTcm.form.otherCost
              ? "0"
              : parseFloat(this.$parent.$refs.plTcm.form.otherCost) / 100) *
            parseFloat(
              this.$parent.$refs.proTcm.manufacturingCost_PRO === "" ||
                !this.$parent.$refs.proTcm.manufacturingCost_PRO
                ? "0"
                : this.$parent.$refs.proTcm.manufacturingCost_PRO
            );
          this.$parent.$refs.plTcm.otherCostNum = otherCost.toFixed(12);
        }
        this.$parent.$refs.sumTcm.form.packageCost_SUM = (
          parseFloat(freightCost) +
          parseFloat(wareHouseCost) +
          parseFloat(packageCost)
        ).toFixed(12);
        // if (threeAll != 0) {
        let processScapeCost = 0;
        // console.log(
        //   this.$parent.$refs.proTcm.manufacturingCost_PRO,
        //   "this.$parent.$refs.proTcm.manufacturingCost_PRO"
        // );
        // if (
        //   this.$parent.$refs.proTcm.manufacturingCost_PRO !== "" && this.$parent.$refs.proTcm.manufacturingCost_PRO !== null &&
        //   this.$parent.$refs.rwTcm.materialCost_RM !== "" && this.$parent.$refs.rwTcm.materialCost_RM !== null &&
        //   this.form.materialPC !== "" && this.form.materialPC !== null &&
        //   this.$parent.$refs.purTcm.purchaseCost_PUR !== "" && this.$parent.$refs.purTcm.purchaseCost_PUR !== null
        // ) {
        processScapeCost =
          parseFloat(
            this.$parent.$refs.proTcm.manufacturingCost_PRO === "" ||
              this.$parent.$refs.proTcm.manufacturingCost_PRO === null
              ? 0
              : this.$parent.$refs.proTcm.manufacturingCost_PRO
          ) *
          ((parseFloat(
            this.$parent.$refs.rwTcm.materialCost_RM === "" ||
              this.$parent.$refs.rwTcm.materialCost_RM === null
              ? 0
              : this.$parent.$refs.rwTcm.materialCost_RM
          ) *
            parseFloat(
              this.form.materialPC === "" || this.form.materialPC === null
                ? 0
                : this.form.materialPC / 100
            ) +
            parseFloat(
              this.$parent.$refs.purTcm.purchaseCost_PUR === "" ||
                this.$parent.$refs.purTcm.purchaseCost_PUR === null
                ? 0
                : this.$parent.$refs.purTcm.purchaseCost_PUR
            ) *
              parseFloat(
                this.form.materialPC === "" || this.form.materialPC === null
                  ? 0
                  : this.form.materialPC / 100
              ) +
            parseFloat(
              this.$parent.$refs.proTcm.sbSum === "" ||
                this.$parent.$refs.proTcm.sbSum === null
                ? 0
                : this.$parent.$refs.proTcm.sbSum
            )) /
            (threeAll === 0 ? 1 : threeAll));
        // }
        // console.log(
        //   this.$parent.$refs.proTcm.manufacturingCost_PRO,
        //   "this.$parent.$refs.proTcm.manufacturingCost_PRO"
        // );
        // console.log(
        //   this.$parent.$refs.proTcm.sbSum,
        //   "this.$parent.$refs.rwTcm.sbSum"
        // );
        // console.log(processScapeCost, "this.$parent.processScapeCost");

        let materialScrapCost =
          parseFloat(
            this.$parent.$refs.rwTcm.NmCost === "" ||
              !this.$parent.$refs.rwTcm.NmCost ||
              isNaN(this.$parent.$refs.rwTcm.NmCost)
              ? "0"
              : this.$parent.$refs.rwTcm.NmCost
          ) *
          ((parseFloat(
            this.$parent.$refs.rwTcm.materialCost_RM == ""
              ? "0"
              : this.$parent.$refs.rwTcm.materialCost_RM
          ) *
            parseFloat(
              this.form.materialPC == "" ? 0 : this.form.materialPC / 100
            ) +
            parseFloat(
              this.$parent.$refs.purTcm.purchaseCost_PUR == ""
                ? 0
                : this.$parent.$refs.purTcm.purchaseCost_PUR
            ) *
              parseFloat(
                this.form.materialPC == "" ? 0 : this.form.materialPC / 100
              ) +
            parseFloat(
              this.$parent.$refs.proTcm.sbSum == ""
                ? "0"
                : this.$parent.$refs.proTcm.sbSum
            )) /
            (threeAll === 0 ? 1 : threeAll));

        // console.log(materialScrapCost, "materialScrapCost");
        // console.log(processScapeCost, "processScapeCost");
        this.$parent.$refs.sumTcm.form.scrapCost_SUM =
          processScapeCost - materialScrapCost;
        this.$parent.$refs.sumTcm.form.scrapCost_SUM =
          this.$parent.$refs.sumTcm.form.scrapCost_SUM.toFixed(12);
        let freightWarehousingCost =
          parseFloat(freightCost) + parseFloat(wareHouseCost);
        // console.log(freightWarehousingCost, "freightWarehousingCost");
        let otherCost954 =
          parseFloat(otherCost) +
          parseFloat(
            this.$parent.$refs.plTcm.form.amortizeCost == ""
              ? 0
              : this.$parent.$refs.plTcm.form.amortizeCost
          );
        // console.log(otherCost954, "otherCost954");
        this.$parent.$refs.sumTcm.form.totalPriceCurr_SUM = (
          (threeAll === 0 ? 0 : threeAll) +
          parseFloat(
            this.$parent.$refs.sumTcm.form.sgaProfit_SUM === "" ||
              this.$parent.$refs.sumTcm.form.sgaProfit_SUM === null
              ? 0
              : this.$parent.$refs.sumTcm.form.sgaProfit_SUM
          ) +
          // processScapeCost +  修改过计算
          parseFloat(
            this.$parent.$refs.sumTcm.form.scrapCost_SUM === "" ||
              this.$parent.$refs.sumTcm.form.scrapCost_SUM === null
              ? 0
              : this.$parent.$refs.sumTcm.form.scrapCost_SUM
          ) +
          parseFloat(
            this.$parent.$refs.sumTcm.form.packageCost_SUM === "" ||
              this.$parent.$refs.sumTcm.form.packageCost_SUM === null
              ? 0
              : this.$parent.$refs.sumTcm.form.packageCost_SUM
          ) +
          otherCost954
        ).toFixed(12);
        this.$parent.totalSellPrice =
          this.$parent.$refs.sumTcm.form.totalPriceCurr_SUM;
        // console.log(this.$parent.totalSellPrice, "this.$parent.totalSellPrice");
        this.$parent.$refs.sumTcm.form.totalPriceReport_SUM = (
          parseFloat(this.$parent.$refs.sumTcm.form.totalPriceCurr_SUM) *
          parseFloat(
            this.$parent.$refs.gen.genList[0].rate == ""
              ? 0
              : this.$parent.$refs.gen.genList[0].rate
          )
        ).toFixed(12);
        // + freightWarehousingCost
        this.form.totalProfit = (
          this.form.totalProfitCost == 0
            ? 0
            : (this.form.totalProfitCost /
                this.$parent.$refs.sumTcm.form.totalPriceCurr_SUM) *
              100
        ).toFixed(12);
        // this.$parent.$refs.sumTcm.form.totalPriceCurr_SUM = this.$parent.$refs.sumTcm.form.totalPriceCurr_SUM.toFixed(3)
        // this.$parent.$refs.sumTcm.form.totalPriceReport_SUM = this.$parent.$refs.sumTcm.form.totalPriceReport_SUM.toFixed(3)
        // }
      }

      // console.log(this.$parent.$refs.proTcm.sbSum, "sbSum");
      // console.log(this.$parent.$refs.rwTcm.NmCost, "NmCost");
    },
    // 限制
    transferFormat(val, item, name) {
      item[name] = val.replace(/^\D*(\d*(?:\.\d{0,12})?).*$/g, "$1");
    },
  },
};
</script>
<style scoped>
.tcm-content {
  padding: 20px 20px 20px 20px;
}
.tcm-content-title {
  /* background-color: #f2f2f2; */
  border-radius: 5px;
  padding: 20px 20px 20px 20px;
  font-size: 16px;
  font-weight: bold;
  position: relative;
}
.tcm-content-title:before {
  content: "";
  display: table;
  width: 6px;
  height: 6px;
  background: #000;
  border-radius: 50%;
  position: absolute;
  left: 30px;
  top: 27px;
}
.tcm-content-form {
  /* width: calc(100% - 50px); */
  margin-left: 50px;
  /* background-color: #f2f3f5; */
  border-radius: 5px;
  margin-top: 10px;
  padding: 20px;
  box-sizing: border-box;
}
.form-type-title {
  width: 100px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  background: #fff;
  line-height: 35px;
  border-radius: 5px;
  padding: 0 10px;
  margin-bottom: 10px;
}
.tcm-content-table {
  width: 100%;
}
.tcm-content-table td {
  padding-bottom: 10px;
}
.table-title {
  font-size: 14px;
  color: #606266;
}
</style>