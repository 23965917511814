<template>
  <div style="height: 100%">
    <div class="top-box">
      <div class="crumbs-box">
        <div class="crumbs-box-item parent" @click="Goback">
          {{ $t("homePage.home.Back") }}
        </div>
        <div class="line"></div>
        <div class="crumbs-box-item" v-for="(item, index) in crumbs">
          {{ $t(item) }}
          <el-icon v-show="index != crumbs.length - 1"><ArrowRight /></el-icon>
        </div>
      </div>
      <div class="step-menubox">
        <StepMenu
          :MenuData="MenuData"
          :chooseCurrent="chooseCurrent"
          @chooseMenu="chooseMenu"
        ></StepMenu>
      </div>
    </div>
    <div class="main">
      <TcmGen
        v-show="chooseCurrent == 'gen' && !previewShow"
        ref="gen"
        :type="operateType"
        operateType="operation"
        :QuoteTypeOptions="typeOptions"
        :RegionOptions="regionOptions"
        :CommodityOptions="CommodityOptions"
        :urgencyOptions="urgencyOptions"
        :urgencyOptionss="urgencyOptionss"
        :currencyOptions="currencyOptions"
        :currencyReportOptions="currencyReportOptions"
      ></TcmGen>
      <TcmRm
        v-show="chooseCurrent == 'rm' && !previewShow"
        ref="rwTcm"
        type="alloperate"
        :operateType="operateType"
        :currencyOptions="currencyOptionsAll"
        :CommodityId="CommodityId"
      ></TcmRm>
      <TcmPur
        v-show="chooseCurrent == 'pur' && !previewShow"
        ref="purTcm"
        type="alloperate"
        :currencyId="currencyId"
        :operateType="operateType"
        :currencyOptions="currencyOptionsAll"
        :CommodityId="CommodityId"
        @GetDetail="getDetail"
      ></TcmPur>
      <TcmPro
        v-show="chooseCurrent == 'pro' && !previewShow"
        ref="proTcm"
        type="alloperate"
        :operateType="operateType"
        :currencyOptions="currencyOptionsAll"
        :CommodityId="CommodityId"
      ></TcmPro>
      <TcmPl
        v-show="chooseCurrent == 'p&l' && !previewShow"
        ref="plTcm"
        type="all"
        :operateType="operateType"
      ></TcmPl>
      <TcmOh
        v-show="chooseCurrent == 'oh' && !previewShow"
        ref="ohTcm"
        type="all"
        :operateType="operateType"
      ></TcmOh>
      <TcmSum
        v-show="chooseCurrent == 'sum' && !previewShow"
        ref="sumTcm"
        :operateType="operateType"
        :exchangeRateUnit="exchangeRateUnit"
        :reportExchangeRateUnit="reportExchangeRateUnit"
        :ReportCurrencyOptions="currencyReportOptions"
        @cancle="cancle"
        @saveTemplate="openTemplate"
        @saveDraft="saveDraft"
        @previewTemplate="previewTemplate"
      ></TcmSum>
      <ChangePrice
        ref="ChangePrice"
        operateType="edit"
        @changePrice="ReceiveData"
      ></ChangePrice>

      <!-- <div class="info-box">
      <div class="info-box-l flex">
        <p>Docment: 123123</p>
        <p>Docment: 123123</p>
        <p>
          total:
          <span>￥123323</span>
        </p>
      </div>
      <div class="info-box-r">
        Total Net Raw Material Cost: 258.0431 / 258.0431
      </div>
    </div>
    <!== <Gen></Gen> ==>
    <div class="card">
      <div class="form-box">
        <el-form label-width="120px" label-position="left">
          <el-form-item label="Activity name" v-for="item in 10">
            <input type="text" />
          </el-form-item>
        </el-form>
      </div>
      <div class="form-box">
        <el-form label-width="120px" label-position="left">
          <el-form-item label="Activity name" v-for="item in 10">
            <input type="text" />
          </el-form-item>
        </el-form>
      </div>
    </div> -->
    </div>
  </div>
</template>

<script>
import StepMenu from "@/components/stepMenu/index.vue";
import Gen from "@/components/gen.vue";
import TcmGen from "./tcmgen.vue";
import TcmRm from "./tcmRm.vue";
import TcmPur from "./tcmPur.vue";
import TcmPro from "./tcmPro.vue";
import TcmPl from "./tcmPl.vue";
import TcmOh from "./tcmOh.vue";
import TcmSum from "./tcmSum.vue";
import ChangePrice from "../tcmTemplateManagement/ChangePrice.vue";
// import TemplateDia from './templateDia.vue'
// import TemplateInfo from './templateInfo.vue'
// import PreviewInfo from './previewInfo.vue'
import {
  getQuoteTypes,
  getRegions,
  GetOrgs,
  getCategorys,
  GetApplyDetail,
  GetSelCurrency,
  updateStatusCostApplys,
  GetTemplateOrDraftDetail,
  AddOrUpdateTemplateApplys,
  UpdateCostApplyForOperateReq,
  uploadFile,
  UpdateForPrice,
  loadCurrency,
  UpdateCostApplyForChangePriceReq,
} from "../../assets/js/requestApi.js";
import commonUtil from "../../assets/js/commonUtil.js";
export default {
  components: {
    TcmGen,
    TcmRm,
    TcmPur,
    TcmPro,
    TcmPl,
    TcmOh,
    TcmSum,
    ChangePrice,
    StepMenu,
    Gen,
    // TemplateDia,
    // TemplateInfo,
    // PreviewInfo
  },
  data() {
    return {
      disabled: false,
      chooseCurrent: "pro",
      id: "",
      type: "add",
      operateType: "detail",
      // 报价类型选项
      typeOptions: [],
      // 区域选项
      regionOptions: [],
      // 商品分类选项
      CommodityOptions: [],
      // 等级选项
      urgencyOptions: [],
      urgencyOptionss: [],
      // 税率表区域选项
      currencyOptions: [],
      currencyReportOptions: [],
      // 所有币种选项
      currencyOptionsAll: [],
      // 商品分类id
      CommodityId: "",
      // 保存类型(模板或草稿)
      saveType: "template",
      // 选择的模板id
      templateId: "",
      // 预览页面是否显示
      previewShow: false,
      // 预览数据
      previewData: {},
      // 文件上传后对应的列表
      pictureList: [],
      uploadStatus: false,
      // 最终汇率单位
      exchangeRateUnit: "",
      reportExchangeRateUnit: "",
      // 打开弹窗是否为草稿
      isdraft: 0,
      totalSellPrice: 0,
      // 操作时间
      doDate: "",
      // 序号
      documentNo: "",
      currencyId: "",
      lstPrice: [],
      CompleteApplyId: null,
      PurIdList: [],
      crumbs: ["homePage.home.CBDPriceChange", "ccls.ccl.changePrice"],
      MenuData: [],
    };
  },
  mounted() {
    this.ChangeLan();
    this.getQuoteType();
    this.getRegion();
    this.getCommdity();
    this.getCategorysOptions();
    this.getCategorysOptionss();
    this.getCurrencyOptions();
    this.getCurrencyOptionsAll();
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getDetail();
    }
    if (this.$route.query.disabled) {
      this.disabled = this.$route.query.disabled;
    }
  },
  watch: {
    uploadStatus: {
      handler(nval, oval) {
        if (nval) {
          this.submit();
        }
      },
    },
    "$i18n.locale"() {
      this.ChangeLan();
    },
  },
  methods: {
    ChangeLan() {
      this.MenuData = [
        {
          key: "gen",
          lan: this.$t("homePage.home.Gen"),
        },
        {
          key: "rm",
          lan: this.$t("homePage.home.Rm"),
        },
        {
          key: "pur",
          lan: this.$t("homePage.home.PUR"),
        },
        {
          key: "pro",
          lan: this.$t("homePage.home.PRO"),
        },
        {
          key: "p&l",
          lan: this.$t("homePage.home.Pl"),
        },
        {
          key: "oh",
          lan: this.$t("homePage.home.OH"),
        },
        {
          key: "sum",
          lan: this.$t("homePage.home.CostSummary"),
        },
      ];
    },
    Goback() {
      this.$router.go(-1);
    },
    ReceiveData(data, oldData) {
      this.lstPrice = oldData;
      this.PurIdList.unshift(oldData[0]);
      this.$refs.purTcm.getMultiRates(data, "tcm");
    },
    ShowChangePriceDig(id, ParentId) {
      // this.CompleteApplyId = id;
      // this.$refs.ChangePrice.GetChangePriceId(id, 2);
      let flag = this.PurIdList.findIndex((n) => n.ParentId == ParentId);
      if (flag >= 0) {
        UpdateCostApplyForChangePriceReq(this.PurIdList[flag], (response) => {
          if (response && response.code == 200) {
            this.$refs.ChangePrice.GetChangePriceData(
              response.result[0],
              2,
              ParentId
            );
          }
        });
      } else {
        this.CompleteApplyId = id;
        this.$refs.ChangePrice.GetChangePriceId(id, 2, ParentId);
      }
    },
    chooseMenu(val) {
      this.chooseCurrent = val;
    },
    chooseTemplate() {
      this.isdraft = 0;
      this.$refs.templateDialog.getlist();
      this.$refs.templateDialog.visible = true;
    },
    chooseDraft() {
      this.isdraft = 1;
      this.$refs.templateDialog.getlist();
      this.$refs.templateDialog.visible = true;
    },
    // 查询货币
    getCurrencyOptionsAll() {
      loadCurrency(
        {
          IsOrderNumber: 0,
          Keyword: "",
          page: 1, //当前页
          limit: 99999, //每页个数
        },
        (response) => {
          if (response && response.code == 200) {
            this.currencyOptionsAll = response.data;
          }
        }
      );
    },
    // 获取报价类型选项
    getQuoteType() {
      getQuoteTypes(
        {
          Keyword: "",
          isEnable: 1,
          page: 1,
          limit: 9999,
          key: "",
        },
        (response) => {
          if (response && response.code == 200) {
            this.typeOptions = response.data;
          }
        }
      );
    },
    // 获取区域列表选项
    getRegion() {
      getRegions(
        {
          Keyword: "",
          isEnable: 1,
          page: 1,
          limit: 9999,
          key: "",
        },
        (response) => {
          if (response && response.code == 200) {
            this.regionOptions = response.data;
          }
        }
      );
    },
    // 获取商品分类数据
    getCommdity() {
      let _this = this;
      GetOrgs(
        {
          type: 0,
          versionType: 0,
          TypeCost: 0,
          isAll: 0,
        },
        (response) => {
          if (response && response.Code == 200) {
            this.CommodityOptions = commonUtil.transferList(response.Result);
          }
        }
      );
    },
    // 获取等级选项
    getCategorysOptions() {
      getCategorys(
        {
          TypeID: "ApplyUrgency",
          Keyword: "",
          page: 1,
          limit: 9999,
          key: "",
        },
        (response) => {
          if (response && response.code == 200) {
            this.urgencyOptions = response.data;
          }
        }
      );
    },
    getCategorysOptionss() {
      getCategorys(
        {
          TypeID: "ApplyUrgency",
          Keyword: "",
          page: 1,
          limit: 9999,
          key: "",
        },
        (response) => {
          if (response && response.code == 200) {
            response.data.forEach((e) => {
              e.name = e.description;
            });
            this.urgencyOptionss = response.data;
          }
        }
      );
    },
    // 查询税率表区域选项
    getCurrencyOptions() {
      GetSelCurrency(
        {
          CurrId: "",
          CurrCNYId: "",
          isEnable: 1,
        },
        (response) => {
          if (response && response.code == 200) {
            this.currencyOptions = response.data[0].exchangeList;
            this.currencyReportOptions = response.data[0].exchangeCNYList;
            this.$refs.gen.genCurrOptions[0] = {
              currencyOptions: this.currencyOptions,
              currencyReportOptions: this.currencyReportOptions,
            };
          } else {
            commonUtil.showTipInfo(
              this.$t("userlists.userlist.inqueryTimeout")
            );
          }
        }
      );
    },
    // 获取详情
    getDetail(id) {
      let ids = [];
      let _this = this;
      id ? ids.push(id) : ids.push(this.id);
      // ids.push(this.id);
      GetApplyDetail(ids, (response) => {
        if (response && response.code == 200) {
          let data = response.result[0];
          this.documentNo = data.documentNo;
          this.doDate = data.doDate;
          this.$refs.gen.form.quoteTypeId = data.quoteTypeId;
          this.$refs.gen.form.programName = data.programName;
          this.$refs.gen.form.ecrno = data.ecrno;
          this.$refs.gen.form.commodityId = data.commodityId;
          this.CommodityId = data.commodityId;
          this.$refs.gen.form.partDescription = data.partDescription;
          this.$refs.gen.form.partNumber = data.partNumber;
          this.$refs.gen.form.partRevision = data.partRevision;
          this.$refs.gen.form.lotSize = data.lotSize;
          this.$refs.gen.form.productionYear = data.productionYear;
          this.$refs.gen.form.doDate = data.doDate;
          this.$refs.gen.genList[0].comment = data.comment;
          this.$refs.gen.form.urgencyId = data.urgencyId;
          if (data.exchangeRate != null) {
            this.currencyId = data.exchangeRate.currency;
            this.$refs.gen.genList[0].Currency = data.exchangeRate.currency;
            this.$refs.gen.genList[0].ReportCurrency =
              data.exchangeRate.currencyCNYReportCurrency;
            this.$refs.gen.genList[0].rate = data.rate;
            this.$refs.gen.genList[0].currencyRateId = data.exchangeRate.id;
            this.exchangeRateUnit = data.exchangeRate.currencySymbolName;
            this.reportExchangeRateUnit =
              data.exchangeRate.reportCurrencySymbolName;
          }
          // this.$refs.gen.genList[0].Currency = data.exchangeRate.currency
          // this.$refs.gen.genList[0].ReportCurrency = data.exchangeRate.currencyCNYReportCurrency
          // this.$refs.gen.genList[0].rate = data.rate
          // this.$refs.gen.genList[0].currencyRateId = data.exchangeRate.id
          // this.exchangeRateUnit = data.exchangeRate.currencySymbolName
          // this.reportExchangeRateUnit = data.exchangeRate.reportCurrencySymbolName
          this.$refs.gen.genList[0].regionId = data.regionId;
          this.$refs.gen.genList[0].supplierName = data.supplierName;
          this.$refs.gen.genList[0].materialDescription =
            data.materialDescription;
          this.$refs.gen.genList[0].peakVolume = data.peakVolume;
          this.$refs.gen.genList[0].lifetimeVolume = data.lifetimeVolume;
          this.$refs.gen.genList[0].pegPoint = data.pegPoint;
          this.$refs.gen.genList[0].yearVolumeList = data.yearVolumeList;
          this.$refs.gen.mailTable = data.mailList;
          this.$refs.gen.form.mailComment = data.mailComment;
          let applicationList = [];
          let operateList = [];
          data.attachmentList.forEach(function (val, index) {
            if (val.applyType == 1) {
              operateList.push(val);
            } else {
              applicationList.push(val);
            }
          });
          this.$refs.gen.fileListx = applicationList;
          /*zj 2023.9.14 新增字段 status*/
          this.$refs.gen.status = data.status;
          this.$refs.ohTcm.form.sga = data.sga ? data.sga : "";
          this.$refs.ohTcm.form.sgaCost = data.sgaCost ? data.sgaCost : "";
          this.$refs.ohTcm.form.materialProfit = data.materialProfit
            ? data.materialProfit
            : "";
          this.$refs.ohTcm.form.addProfit = data.addProfit
            ? data.addProfit
            : "";
          this.$refs.ohTcm.form.purchaseProfit = data.purchaseProfit
            ? data.purchaseProfit
            : "";
          this.$refs.ohTcm.form.totalProfit = data.totalProfit
            ? data.totalProfit
            : "";
          this.$refs.ohTcm.form.totalProfitCost = data.totalProfitCost
            ? data.totalProfitCost
            : "";
          this.$refs.ohTcm.form.materialPC = data.materialPC
            ? data.materialPC
            : "";
          this.$refs.rwTcm.materialCost_RM = data.materialCost_RM
            ? data.materialCost_RM
            : "0.000";
          // if (data.materialList.length > 0) {
          //     data.materialList.forEach(function (val, index) {
          //         let cLines = [];
          //         val.cLines.forEach(function (va, ind) {
          //             cLines.push({
          //                 fieldName: va.paramName,
          //                 calculatorTableId: va.calculator_TableId,
          //             });
          //         });
          //         val.cLines = cLines;
          //         // val.id = ''
          //     });
          //     this.$refs.rwTcm.formData = data.materialList;
          // }
          if (data.materialList.length > 0) {
            data.materialList.forEach(function (val, index) {
              let cLines = [];
              val.cLines.forEach(function (va, ind) {
                cLines.push({
                  fieldName: va.paramName,
                  calculatorTableId: va.calculator_TableId,
                });
              });
              val.cLines = cLines;
              if (
                val.convertCurrencyId === "" ||
                val.convertCurrencyId === null
              ) {
                val.convertCurrencyId = _this.$refs.gen.genList[0].Currency;
                val.currencyId = _this.$refs.gen.genList[0].Currency;
                val.converRate = "1";
                val.exchangeRate = "1";
              } else {
                val.exchangeRate = val.converRate;
              }
              val.converRate_Before = JSON.parse(
                JSON.stringify(val.converRate)
              );
              val.unitCost_Before = JSON.parse(JSON.stringify(val.unitCost));
              val.cost_Before = JSON.parse(JSON.stringify(val.cost));
              // val.id = ''
            });
            this.$refs.rwTcm.formData = data.materialList;
          } else {
            this.$refs.rwTcm.formData = data.materialList;
            this.$refs.rwTcm.formData.forEach(function (val, index) {
              if (val.converRate === "") {
                val.convertCurrencyId = _this.$refs.gen.genList[0].Currency;
                val.converRate = "1";
                val.exchangeRate = "1";
              }
              val.currencyId = _this.$refs.gen.genList[0].Currency;
            });
          }
          this.$refs.rwTcm.materialDelIds = [];
          this.$refs.rwTcm.orimaterialCost_RM = JSON.parse(
            JSON.stringify(this.$refs.rwTcm.materialCost_RM)
          );
          this.$refs.rwTcm.oriFormData = JSON.parse(
            JSON.stringify(this.$refs.rwTcm.formData)
          );
          this.$refs.purTcm.purchaseCost_PUR = data.purchaseCost_PUR
            ? data.purchaseCost_PUR
            : "0.000";
          // if (data.purchaseList.length > 0) {
          //     data.purchaseList.forEach(function (val, index) {
          //         // val.id = ''
          //     });
          //     this.$refs.purTcm.formData = data.purchaseList;
          // }
          if (data.purchaseList.length > 0) {
            data.purchaseList.forEach(function (val, index) {
              if (
                val.convertCurrencyId === "" ||
                val.convertCurrencyId === null
              ) {
                val.convertCurrencyId = _this.$refs.gen.genList[0].Currency;
                val.currencyId = _this.$refs.gen.genList[0].Currency;
                val.converRate = "1";
                val.exchangeRate = "1";
              } else {
                val.exchangeRate = val.converRate;
              }
              val.converRate_Before = JSON.parse(
                JSON.stringify(val.converRate)
              );
              val.ParentId = val.id;
              val.unitCost_Before = JSON.parse(JSON.stringify(val.unitCost));
              val.totalCost_Before = JSON.parse(JSON.stringify(val.totalCost));
              // val.id = ''
            });
            this.$refs.purTcm.formData = data.purchaseList;
          } else {
            this.$refs.purTcm.formData = data.purchaseList;
            this.$refs.purTcm.formData.forEach(function (val, index) {
              if (val.converRate === "") {
                val.convertCurrencyId = _this.$refs.gen.genList[0].Currency;
                val.converRate = "1";
                val.exchangeRate = "1";
              }
              val.ParentId = val.id;
              val.currencyId = _this.$refs.gen.genList[0].Currency;
            });
          }
          this.$refs.purTcm.purchaseDelIds = [];
          this.$refs.purTcm.oripurchaseCost_PUR = JSON.parse(
            JSON.stringify(this.$refs.purTcm.purchaseCost_PUR)
          );
          this.$refs.purTcm.oriFormData = JSON.parse(
            JSON.stringify(this.$refs.purTcm.formData)
          );

          this.$refs.proTcm.laborCost_PRO = data.laborCost_PRO
            ? data.laborCost_PRO
            : "";
          this.$refs.proTcm.setUpCost_PRO = data.setUpCost_PRO
            ? data.setUpCost_PRO
            : "";
          this.$refs.proTcm.burdenCost_PRO = data.burdenCost_PRO
            ? data.burdenCost_PRO
            : "";
          this.$refs.proTcm.manufacturingCost_PRO = data.manufacturingCost_PRO
            ? data.manufacturingCost_PRO
            : "";
          // if (data.processList.length > 0) {
          //     data.processList.forEach(function (val, index) {
          //         let cLines = [];
          //         val.cLines.forEach(function (va, ind) {
          //             cLines.push({
          //                 fieldName: va.paramName,
          //                 calculatorTableId: va.calculator_TableId,
          //             });
          //         });
          //         val.cLines = cLines;
          //         // val.id = ''
          //     });
          //     this.$refs.proTcm.formData = data.processList;
          // }
          if (data.processList.length > 0) {
            data.processList.forEach(function (val, index) {
              let cLines = [];
              val.cLines.forEach(function (va, ind) {
                cLines.push({
                  fieldName: va.paramName,
                  calculatorTableId: va.calculator_TableId,
                });
              });
              val.cLines = cLines;
              if (
                val.convertCurrencyId === "" ||
                val.convertCurrencyId === null
              ) {
                val.convertCurrencyId = _this.$refs.gen.genList[0].Currency;
                val.currencyId = _this.$refs.gen.genList[0].Currency;
                val.converRate = "1";
                val.exchangeRate = "1";
              } else {
                val.exchangeRate = val.converRate;
              }
              // val.id = ''
            });
            this.$refs.proTcm.formData = data.processList;
          } else {
            this.$refs.proTcm.formData.forEach(function (val, index) {
              if (val.converRate === "") {
                val.convertCurrencyId = _this.$refs.gen.genList[0].Currency;
                val.converRate = "1";
                val.exchangeRate = "1";
              }
              val.currencyId = _this.$refs.gen.genList[0].Currency;
            });
          }
          this.$refs.proTcm.processDelIds = [];

          this.$refs.plTcm.form.calculationMethod = data.calculationMethod
            ? data.calculationMethod
            : "";
          this.$refs.plTcm.form.calculationMethod_Freight =
            data.calculationMethod_Freight ? data.calculationMethod_Freight : 1;
          this.$refs.plTcm.form.calculationMethod_Package =
            data.calculationMethod_Package ? data.calculationMethod_Package : 1;
          this.$refs.plTcm.form.calculationMethod_Taxes =
            data.calculationMethod_Taxes ? data.calculationMethod_Taxes : 1;
          this.$refs.plTcm.form.calculationMethod_Ware =
            data.calculationMethod_Ware ? data.calculationMethod_Ware : 1;
          this.$refs.plTcm.form.freightCost =
            data.freightCost !== null ? data.freightCost : "";
          this.$refs.plTcm.form.wareHouseCost =
            data.wareHouseCost !== null ? data.wareHouseCost : "";
          this.$refs.plTcm.form.packageCost =
            data.packageCost !== null ? data.packageCost : "";
          this.$refs.plTcm.form.otherCost =
            data.otherCost !== null ? data.otherCost : "";
          this.$refs.plTcm.form.amortizationDescription =
            data.amortizationDescription !== null
              ? data.amortizationDescription
              : "";
          this.$refs.plTcm.form.totalPieces =
            data.totalPieces !== null ? data.totalPieces : "";
          this.$refs.plTcm.form.totalAmortize =
            data.totalAmortize !== null ? data.totalAmortize : "";
          this.$refs.plTcm.form.amortizeCost =
            data.amortizeCost !== null ? data.amortizeCost : "";

          this.$refs.sumTcm.form.materialCost_SUM = data.materialCost_SUM;
          this.$refs.sumTcm.form.setUpCost_SUM = data.setUpCost_SUM;
          this.$refs.sumTcm.form.purchaseCost_SUM = data.purchaseCost_SUM;
          this.$refs.sumTcm.form.packageCost_SUM = data.packageCost_SUM;
          this.$refs.sumTcm.form.laborCost_SUM = data.laborCost_SUM;
          this.$refs.sumTcm.form.otherCost_SUM = data.otherCost_SUM;
          this.$refs.sumTcm.form.burdenCost_SUM = data.burdenCost_SUM;
          this.$refs.sumTcm.form.amortizeCost_SUM = data.amortizeCost_SUM;
          this.$refs.sumTcm.form.sgaProfit_SUM = data.sgaProfit_SUM;
          this.$refs.sumTcm.form.scrapCost_SUM = data.scrapCost_SUM;
          this.$refs.sumTcm.form.totalPriceCurr_SUM = data.totalPriceCurr_SUM;
          this.$refs.sumTcm.form.totalPriceReport_SUM =
            data.totalPriceReport_SUM;
          this.$refs.sumTcm.form.totalPriceCurr_SUM_Before = JSON.parse(
            JSON.stringify(data.totalPriceCurr_SUM)
          );
          this.$refs.sumTcm.form.totalPriceReport_SUM_Before = JSON.parse(
            JSON.stringify(data.totalPriceReport_SUM)
          );
          this.$refs.sumTcm.fileListx = operateList;
          this.totalSellPrice = data.totalPriceCurr_SUM
            ? data.totalPriceCurr_SUM
            : 0;
        }
      });
    },
    // 更改申请状态
    updateStatus(status) {
      updateStatusCostApplys(
        {
          status: status,
          id: this.id,
        },
        (response) => {
          if (response && response.code == 200) {
            // commonUtil.showTipInfo(response.message, '', 'success')
            this.operateType = "edit";
          }
        }
      );
    },
    // 获取选择模板id
    selectTemplate(id) {
      this.templateId = id;
      this.getTemplateDetail(id);
    },
    // 获取选择模板详情
    getTemplateDetail(id) {
      let _this = this;
      GetTemplateOrDraftDetail({ id: id }, (response) => {
        if (response && response.code == 200) {
          let result = response.result;
          // p&l
          this.$refs.plTcm.form.calculationMethod = result.calculationMethod
            ? result.calculationMethod
            : "";

          this.$refs.plTcm.form.freightCost =
            result.freightCost === null ? "" : result.freightCost;
          this.$refs.plTcm.form.wareHouseCost =
            result.wareHouseCost === null ? "" : result.wareHouseCost;
          this.$refs.plTcm.form.packageCost = result.packageCost
            ? result.packageCost
            : "";
          this.$refs.plTcm.form.otherCost =
            result.otherCost === null ? "" : result.otherCost;
          this.$refs.plTcm.form.amortizationDescription =
            result.amortizationDescription
              ? result.amortizationDescription
              : "";
          this.$refs.plTcm.form.totalPieces = result.totalPieces
            ? result.totalPieces
            : "";
          this.$refs.plTcm.form.totalAmortize = result.totalAmortize
            ? result.totalAmortize
            : "";
          this.$refs.plTcm.form.amortizeCost = result.amortizeCost
            ? result.amortizeCost
            : "";
          // oh
          this.$refs.ohTcm.form.sga = result.sga ? result.sga : "";
          this.$refs.ohTcm.form.sgaCost = result.sgaCost ? result.sgaCost : "";
          this.$refs.ohTcm.form.materialProfit = result.materialProfit
            ? result.materialProfit
            : "";
          this.$refs.ohTcm.form.addProfit = result.addProfit
            ? result.addProfit
            : "";
          this.$refs.ohTcm.form.purchaseProfit = result.purchaseProfit
            ? result.purchaseProfit
            : "";
          this.$refs.ohTcm.form.totalProfit = result.totalProfit
            ? result.totalProfit
            : "";
          this.$refs.ohTcm.form.totalProfitCost = result.totalProfitCost
            ? result.totalProfitCost
            : "";
          this.$refs.ohTcm.form.materialPC = result.materialPC
            ? result.materialPC
            : "";
          // raw
          this.$refs.rwTcm.materialCost_RM = result.materialCost_RM;
          result.materialList.forEach(function (val, index) {
            let cLines = [];
            val.cLines.forEach(function (va, ind) {
              cLines.push({
                fieldName: va.paramName,
                calculatorTableId: va.calculator_TableId,
              });
            });
            val.cLines = cLines;
            val.id = "";
          });
          this.$refs.rwTcm.formData = result.materialList;
          this.$refs.rwTcm.formData.forEach(function (val, index) {
            _this.$refs.rwTcm.toComputeCost(val);
          });
          this.$refs.rwTcm.materialDelIds = [];
          // pru
          this.$refs.purTcm.purchaseCost_PUR = result.purchaseCost_PUR;
          result.purchaseList.forEach(function (val, index) {
            val.id = "";
          });
          this.$refs.purTcm.formData = result.purchaseList;
          this.$refs.purTcm.formData.forEach(function (val, index) {
            _this.$refs.purTcm.toCompute(val);
          });
          this.$refs.purTcm.purchaseDelIds = [];

          // pro
          this.$refs.proTcm.laborCost_PRO = result.laborCost_PRO;
          this.$refs.proTcm.setUpCost_PRO = result.setUpCost_PRO;
          this.$refs.proTcm.burdenCost_PRO = result.burdenCost_PRO;
          this.$refs.proTcm.manufacturingCost_PRO =
            result.manufacturingCost_PRO;
          result.processList.forEach(function (val, index) {
            let cLines = [];
            val.cLines.forEach(function (va, ind) {
              cLines.push({
                fieldName: va.paramName,
                calculatorTableId: va.calculator_TableId,
              });
            });
            val.cLines = cLines;
            val.id = "";

            val.annualVolume = _this.$refs.gen.genList[0].peakVolume;
            val.pieceLot = _this.$refs.gen.form.lotSize;
          });
          this.$refs.proTcm.formData = result.processList;
          this.$refs.proTcm.formData.forEach(function (val, index) {
            _this.$refs.proTcm.toComputeAll(val);
          });
          this.$refs.proTcm.processDelIds = [];
          // sum
          this.$refs.sumTcm.form.otherCost_SUM = result.otherCost;
          this.$refs.sumTcm.form.amortizeCost_SUM = result.amortizeCost;
          // this.$refs.sumTcm.form.sgaProfit_SUM =  parseFloat(result.sgaCost) + parseFloat(result.totalProfitCost)
          // this.$refs.sumTcm.form.setUpCost_SUM = result.setUpCost_PRO
          // this.CommodityId = result.commodityId
          this.$refs.templateDialog.visible = false;
        }
      });
    },
    // 取消
    cancle() {
      this.$router.back(-1);
    },
    // 打开保存为模板弹窗
    openTemplate(type) {
      this.saveType = type;
      this.$refs.temInfo.visible = true;
    },
    // 保存模板
    saveTemplate(form) {
      let data = {
        id: "",
        number: "",
        name: form.name,
        version: form.version,
        comment: form.comment,
        isEnable: 1,
        materialCost_RM: this.$refs.rwTcm.materialCost_RM,
        materialLine: this.$refs.rwTcm.formData,
        materialDelIds: this.$refs.rwTcm.materialDelIds,
        purchaseCost_PUR: this.$refs.purTcm.purchaseCost_PUR,
        purchaseLine: this.$refs.purTcm.formData,
        purchaseDelIds: this.$refs.purTcm.purchaseDelIds,
        laborCost_PRO: this.$refs.proTcm.laborCost_PRO,
        setUpCost_PRO: this.$refs.proTcm.setUpCost_PRO,
        burdenCost_PRO: this.$refs.proTcm.burdenCost_PRO,
        manufacturingCost_PRO: this.$refs.proTcm.manufacturingCost_PRO,
        processLine: this.$refs.proTcm.formData,
        processDelIds: this.$refs.proTcm.processDelIds,
        calculationMethod: this.$refs.plTcm.form.calculationMethod,
        freightCost: this.$refs.plTcm.form.freightCost,
        wareHouseCost: this.$refs.plTcm.form.wareHouseCost,
        packageCost: this.$refs.plTcm.form.packageCost,
        otherCost: this.$refs.plTcm.form.otherCost,
        amortizationDescription: this.$refs.plTcm.form.amortizationDescription,
        totalPieces: this.$refs.plTcm.form.totalPieces,
        totalAmortize: this.$refs.plTcm.form.totalAmortize,
        amortizeCost: this.$refs.plTcm.form.amortizeCost,
        sga: this.$refs.ohTcm.form.sga,
        sgaCost: this.$refs.ohTcm.form.sgaCost,
        materialProfit: this.$refs.ohTcm.form.materialProfit,
        addProfit: this.$refs.ohTcm.form.addProfit,
        purchaseProfit: this.$refs.ohTcm.form.purchaseProfit,
        totalProfit: this.$refs.ohTcm.form.totalProfit,
        totalProfitCost: this.$refs.ohTcm.form.totalProfitCost,
        materialPC: this.$refs.ohTcm.form.materialPC,
        commodityId: this.CommodityId,
        isDraft: this.saveType == "template" ? 0 : 1,
      };
      AddOrUpdateTemplateApplys(data, (response) => {
        commonUtil.showTipInfo("保存成功！", "", "success");
      });
    },
    // 保存为草稿
    saveDraft() {
      let materialLine = this.$refs.rwTcm.formData;
      materialLine.forEach(function (val, index) {
        val.type = 0;
        // val.id = ''
      });
      let purchaseLine = this.$refs.purTcm.formData;
      purchaseLine.forEach(function (val, index) {
        val.type = 0;
        // val.id = ''
      });
      let processLine = this.$refs.proTcm.formData;
      processLine.forEach(function (val, index) {
        val.type = 0;
        // val.id = ''
      });
      let draftData = {
        id: this.id,
        templeteId: this.templateId,
        materialCost_RM: this.$refs.rwTcm.materialCost_RM,
        materialLine: materialLine,
        materialDelIds: this.$refs.rwTcm.materialDelIds,
        purchaseCost_PUR: this.$refs.purTcm.purchaseCost_PUR,
        purchaseLine: purchaseLine,
        purchaseDelIds: this.$refs.purTcm.purchaseDelIds,
        laborCost_PRO: this.$refs.proTcm.laborCost_PRO,
        setUpCost_PRO: this.$refs.proTcm.setUpCost_PRO,
        burdenCost_PRO: this.$refs.proTcm.burdenCost_PRO,
        manufacturingCost_PRO: this.$refs.proTcm.manufacturingCost_PRO,
        processLine: processLine,
        processDelIds: this.$refs.proTcm.processDelIds,
        calculationMethod: this.$refs.plTcm.form.calculationMethod,
        freightCost: this.$refs.plTcm.form.freightCost,
        wareHouseCost: this.$refs.plTcm.form.wareHouseCost,
        packageCost: this.$refs.plTcm.form.packageCost,
        otherCost: this.$refs.plTcm.form.otherCost,
        amortizationDescription: this.$refs.plTcm.form.amortizationDescription,
        totalPieces: this.$refs.plTcm.form.totalPieces,
        totalAmortize: this.$refs.plTcm.form.totalAmortize,
        amortizeCost: this.$refs.plTcm.form.amortizeCost,
        sga: this.$refs.ohTcm.form.sga,
        sgaCost: this.$refs.ohTcm.form.sgaCost,
        materialProfit: this.$refs.ohTcm.form.materialProfit,
        addProfit: this.$refs.ohTcm.form.addProfit,
        purchaseProfit: this.$refs.ohTcm.form.purchaseProfit,
        totalProfit: this.$refs.ohTcm.form.totalProfit,
        totalProfitCost: this.$refs.ohTcm.form.totalProfitCost,
        materialPC: this.$refs.ohTcm.form.materialPC,
        materialCost_SUM: this.$refs.sumTcm.form.materialCost_SUM,
        setUpCost_SUM: this.$refs.sumTcm.form.setUpCost_SUM,
        purchaseCost_SUM: this.$refs.sumTcm.form.purchaseCost_SUM,
        packageCost_SUM: this.$refs.sumTcm.form.packageCost_SUM,
        laborCost_SUM: this.$refs.sumTcm.form.laborCost_SUM,
        otherCost_SUM: this.$refs.sumTcm.form.otherCost_SUM,
        burdenCost_SUM: this.$refs.sumTcm.form.burdenCost_SUM,
        amortizeCost_SUM: this.$refs.sumTcm.form.amortizeCost_SUM,
        sgaProfit_SUM: this.$refs.sumTcm.form.sgaProfit_SUM,
        scrapCost_SUM: this.$refs.sumTcm.form.scrapCost_SUM,
        totalPriceCurr_SUM: this.$refs.sumTcm.form.totalPriceCurr_SUM,
        totalPriceReport_SUM: this.$refs.sumTcm.form.totalPriceReport_SUM,
        attachmentList: this.$refs.sumTcm.fileListx,
        // peakVolume: this.$refs.gen.genList[0].peakVolume,
        // lifetimeVolume: this.$refs.gen.genList[0].lifetimeVolume,
        lotSize: this.$refs.gen.form.lotSize,
        productionYear: this.$refs.gen.form.productionYear,
        IsDraft: 1,
      };
      if (this.lstPrice.length) {
        this.lstPrice[0].CompleteApplyId = this.CompleteApplyId;
        this.draftData.lstPrice = this.lstPrice;
      }
      UpdateCostApplyForOperateReq(draftData, (response) => {
        commonUtil.showTipInfo("保存成功！", "", "success");
      });
    },
    // 预览
    previewTemplate() {
      let materialLine = this.$refs.rwTcm.formData;
      materialLine.forEach(function (val, index) {
        val.type = 0;
        // val.id = ''
      });
      let purchaseLine = this.$refs.purTcm.formData;
      purchaseLine.forEach(function (val, index) {
        val.type = 0;
        // val.id = ''
      });
      let processLine = this.$refs.proTcm.formData;
      processLine.forEach(function (val, index) {
        val.type = 0;
        // val.id = ''
      });
      this.previewData = {
        id: this.id,
        templeteId: this.templateId,
        materialCost_RM: this.$refs.rwTcm.materialCost_RM,
        materialLine: materialLine,
        materialDelIds: this.$refs.rwTcm.materialDelIds,
        purchaseCost_PUR: this.$refs.purTcm.purchaseCost_PUR,
        purchaseLine: purchaseLine,
        purchaseDelIds: this.$refs.purTcm.purchaseDelIds,
        laborCost_PRO: this.$refs.proTcm.laborCost_PRO,
        setUpCost_PRO: this.$refs.proTcm.setUpCost_PRO,
        burdenCost_PRO: this.$refs.proTcm.burdenCost_PRO,
        manufacturingCost_PRO: this.$refs.proTcm.manufacturingCost_PRO,
        processLine: processLine,
        processDelIds: this.$refs.proTcm.processDelIds,
        calculationMethod: this.$refs.plTcm.form.calculationMethod,
        freightCost: this.$refs.plTcm.form.freightCost,
        wareHouseCost: this.$refs.plTcm.form.wareHouseCost,
        packageCost: this.$refs.plTcm.form.packageCost,
        otherCost: this.$refs.plTcm.form.otherCost,
        amortizationDescription: this.$refs.plTcm.form.amortizationDescription,
        totalPieces: this.$refs.plTcm.form.totalPieces,
        totalAmortize: this.$refs.plTcm.form.totalAmortize,
        amortizeCost: this.$refs.plTcm.form.amortizeCost,
        sga: this.$refs.ohTcm.form.sga,
        sgaCost: this.$refs.ohTcm.form.sgaCost,
        materialProfit: this.$refs.ohTcm.form.materialProfit,
        addProfit: this.$refs.ohTcm.form.addProfit,
        purchaseProfit: this.$refs.ohTcm.form.purchaseProfit,
        totalProfit: this.$refs.ohTcm.form.totalProfit,
        totalProfitCost: this.$refs.ohTcm.form.totalProfitCost,
        materialPC: this.$refs.ohTcm.form.materialPC,
        materialCost_SUM: this.$refs.sumTcm.form.materialCost_SUM,
        setUpCost_SUM: this.$refs.sumTcm.form.setUpCost_SUM,
        purchaseCost_SUM: this.$refs.sumTcm.form.purchaseCost_SUM,
        packageCost_SUM: this.$refs.sumTcm.form.packageCost_SUM,
        laborCost_SUM: this.$refs.sumTcm.form.laborCost_SUM,
        otherCost_SUM: this.$refs.sumTcm.form.otherCost_SUM,
        burdenCost_SUM: this.$refs.sumTcm.form.burdenCost_SUM,
        amortizeCost_SUM: this.$refs.sumTcm.form.amortizeCost_SUM,
        sgaProfit_SUM: this.$refs.sumTcm.form.sgaProfit_SUM,
        scrapCost_SUM: this.$refs.sumTcm.form.scrapCost_SUM,
        totalPriceCurr_SUM: this.$refs.sumTcm.form.totalPriceCurr_SUM_Before,
        totalPriceReport_SUM:
          this.$refs.sumTcm.form.totalPriceReport_SUM_Before,
        totalPriceCurr_SUM_Before: this.$refs.sumTcm.form.totalPriceCurr_SUM,
        totalPriceReport_SUM_Before:
          this.$refs.sumTcm.form.totalPriceReport_SUM,
        attachmentList: this.$refs.sumTcm.fileListx,
        // peakVolume: this.$refs.gen.genList[0].peakVolume,
        // lifetimeVolume: this.$refs.gen.genList[0].lifetimeVolume,
        lotSize: this.$refs.gen.form.lotSize,
        productionYear: this.$refs.gen.form.productionYear,
        IsDraft: 0,
        PriceType: 1,
        calculationMethod_Freight:
          this.$refs.plTcm.form.calculationMethod_Freight,
        calculationMethod_Package:
          this.$refs.plTcm.form.calculationMethod_Package,
        calculationMethod_Taxes: this.$refs.plTcm.form.calculationMethod_Taxes,
        calculationMethod_Ware: this.$refs.plTcm.form.calculationMethod_Ware,
      };
      // this.previewShow = true
      this.submitPreview();
    },
    // 取消预览
    canclePreview() {
      this.previewShow = false;
    },
    // 提交
    submitPreview() {
      this.uploadFiles(this.previewData.attachmentList);
    },
    submit() {
      this.previewData.attachmentList = this.pictureList;
      if (this.lstPrice.length) {
        this.lstPrice[0].CompleteApplyId = this.CompleteApplyId;
        this.previewData.lstPrice = this.lstPrice;
      }
      UpdateForPrice(this.previewData, (response) => {
        if (response && response.code == 200) {
          commonUtil.showTipInfo(response.message, "", "success");
          this.$router.back(-1);
        }
      });
    },
    // 文件上传
    uploadFiles(files) {
      let _this = this;
      this.uploadStatus = false;
      let format = new FormData();
      // 需要上传的文件
      let uploadList = [];
      files.forEach(function (value, key) {
        if (value.raw) {
          format.append("file", value.raw);
          uploadList.push(value);
        } else {
          _this.pictureList.push(value);
        }
      });
      if (uploadList.length > 0) {
        uploadFile(format, (response) => {
          if (response && response.code == 200) {
            response.data.forEach(function (val, key) {
              _this.pictureList.push(val);
            });
            _this.uploadStatus = true;
          }
        });
      } else {
        _this.uploadStatus = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  padding-top: 75px;
  width: 100%;
  min-height: 100%;
  background: url("../../assets/img/car-table-bg.png") no-repeat;
  background-position: right 4% bottom 0;
  background-size: 650px 600px;
  border-radius: 20px;
}
// .main-content {
//   background: url("../../assets/img/car-table-bg.png") no-repeat;
//   background-position: right 4% bottom 0;
//   background-size: 650px 600px;
// }
.top-box {
  position: fixed;
  width: calc(100% - 260px);
  top: 70px;
  left: 235px;
  z-index: 99;
}
.step-menubox {
  width: 100%;
  background: #fff;
}

.crumbs-box {
  padding-left: 20px;
  width: 100%;
  display: flex;
  background-color: #f2f2f2;
  align-items: center;
  height: 30px;
  font-size: 16px;
  color: #3e4954;
  .crumbs-box-item {
    display: flex;
    align-items: center;
  }
  .el-icon {
    margin: 0 10px;
  }
  .parent {
    color: #2f4cdd;
    cursor: pointer;
  }
  .line {
    margin: 0 15px;
    width: 2px;
    height: 15px;
    background: #3e4954;
  }
}

// .step-menubox {
//   width: calc(100vw - 260px);
//   position: fixed;
//   top: 100px;
//   left: 235px;
//   z-index: 999;
//   background: #fff;
//   border-radius: 10px;
// }
.info-box {
  width: 100%;
  padding: 0 15px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000;
  font-weight: 600;
  .info-box-l {
    font-size: 16px;
    p {
      line-height: 30px;
      margin-right: 20px;
    }
    span {
      margin-left: 10px;
      display: inline-block;
      height: 30px;
      padding: 0 20px;
      line-height: 30px;
      background-color: #2d60ff;
      color: #fff;
      text-align: center;
      border-radius: 5px;
    }
  }
}
.card {
  margin: 0 15px;
  padding: 30px;
  display: flex;
  background-color: #f0f0fd;
  border-radius: 10px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2),
    -2px -2px 5px rgba(255, 255, 255, 0.2);
  input {
    background: #f6f6f6;
    font-size: 14px;
    border-radius: 10px;
  }
}
.form-box {
  padding: 20px;
  margin-right: 40px;
  width: 40%;
  background-color: #fff;
}
</style>