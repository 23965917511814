<template>
  <el-dialog v-model="visible" width="40%" :show-close="false" @close="close">
    <template #header="{ close }">
      <div class="my-header">
        <div class="size-16">{{ $t("other.other.EditButton") }}</div>
        <div style="cursor: pointer" @click="close">
          <el-icon :size="20">
            <Close />
          </el-icon>
        </div>
      </div>
    </template>
    <div>
      <el-form :model="form" :rules="rules" ref="form" label-width="180px">
        <el-form-item
          :label="
            $t('other.other.ButtonName') + '(' + $t('other.other.English') + ')'
          "
          prop="nameEn"
        >
          <el-input v-model="form.nameEn" style="width: 240px" />
        </el-form-item>
        <el-form-item
          :label="
            $t('other.other.ButtonName') + '(' + $t('other.other.Chinese') + ')'
          "
          prop="name"
        >
          <el-input v-model="form.name" style="width: 240px" />
        </el-form-item>
        <el-form-item :label="$t('other.other.ButtonFlag')" prop="domId">
          <el-input
            v-model="form.domId"
            @input="handleInput"
            style="width: 240px"
          />
        </el-form-item>
        <el-form-item :label="$t('ccls.ccl.sort')" prop="sort">
          <el-input v-model="form.sort" style="width: 240px" />
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close">{{ $t("ccls.ccl.cancel") }}</el-button>
        <el-button
          style="
            background-color: var(--themeColor);
            color: var(--menuTextActive);
            margin-right: 16px;
          "
          @click="addSubmit"
          >{{ $t("ccls.ccl.confirm") }}</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import commonUtil from "../../assets/js/commonUtil.js";
export default {
  // components: {
  // 	Treemodule,
  // },
  data() {
    return {
      visible: false,
      form: {
        nameEn: "",
        name: "",
        sort: "",
        domId: "",
        icon: "layui-icon-edit",
        class: "layui-btn-normal",
      },
      rules: {},
    };
  },
  created() {
    this.getUnits();
  },
  methods: {
    handleInput(value) {
      // 使用正则表达式去除非英文字符及空格
      this.form.domId = value.replace(/[^a-zA-Z]/g, "");
    },
    getUnits() {
      this.timer = setInterval(this.valChange, 1000);
    },
    valChange() {
      this.powers = JSON.parse(localStorage.getItem("translate"));
      if (this.powers == "en" || this.powers == null) {
        this.rules = {
          nameEn: [
            {
              required: true,
              message: "Please enter the button name",
              trigger: "blur",
            },
          ],
          name: [
            {
              required: true,
              message: "Please enter the button name",
              trigger: "blur",
            },
          ],
          sort: [
            {
              required: true,
              message: "Please enter sort",
              trigger: "blur",
            },
          ],
          domId: [
            {
              required: true,
              message: "Please enter the button ID",
              trigger: "blur",
            },
          ],
        };
      } else {
        this.rules = {
          nameEn: [
            {
              required: true,
              message: "请输入按钮名称",
              trigger: "blur",
            },
          ],
          name: [
            {
              required: true,
              message: "请输入按钮名称",
              trigger: "blur",
            },
          ],
          sort: [
            {
              required: true,
              message: "请输入排序",
              trigger: "blur",
            },
          ],
          domId: [
            {
              required: true,
              message: "请输入按钮标识",
              trigger: "blur",
            },
          ],
        };
      }
      clearInterval(this.timer);
    },
    close() {
      this.$refs.form.resetFields();
      this.visible = false;
    },
    addSubmit() {
      // 给出添加弹框
      if (this.form.name == "") {
        commonUtil.showTipInfo(
          this.$t("ccls.ccl.pleaseMenuName"),
          "",
          "warning"
        );
        return;
      }
      if (this.form.domId == "") {
        commonUtil.showTipInfo(this.$t("ccls.ccl.pleaseMenuId"), "", "warning");
        return;
      }
      if (this.form.sort == "") {
        commonUtil.showTipInfo(this.$t("ccls.ccl.pleaseSort"), "", "warning");
        return;
      }
      this.visible = false;

      this.$emit("handleSubmit", this.form);
      this.$refs.form.resetFields();
    },
  },
};
</script>

<style scoped>
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.size-16 {
  font-size: 16px;
}
</style>
