<template>
  <el-dialog v-model="visible" width="80%" :show-close="false" @close="close">
    <template #header="{ close, titleId, titleClass }">
      <div class="my-header">
        <div class="size-16">
          {{ form.id === "" ? $t("pcbas.pcba.new") : $t("pcbas.pcba.edit") }}
        </div>
        <div style="cursor: pointer" @click="close">
          <el-icon :size="20"><Close /></el-icon>
        </div>
      </div>
    </template>
    <div class="add-cla-top">
      <el-row>
        <el-col :span="24">
          <el-form :inline="true" :model="form" label-width="150px">
            <el-row>
              <el-col :span="8">
                <el-form-item
                  :label="$t('ccls.ccl.nexteerPart')"
                  class="custom-el-form-rules"
                >
                  <el-input
                    v-model="form.nexteerPart"
                    @change="getPrice"
                    placeholder=""
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('ccls.ccl.Reference')">
                  <el-input v-model="form.reference" placeholder="" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('ccls.ccl.desc')">
                  <el-input v-model="form.description" placeholder="" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item :label="$t('ccls.ccl.Quantity')">
                  <el-input
                    v-model="form.quantity"
                    @input="transferFormat($event, form, 'quantity')"
                    @change="transferFormat($event, form, 'quantity')"
                    placeholder=""
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('ccls.ccl.NexteerChart')">
                  <el-input v-model="form.nexteerChart" placeholder="" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('ccls.ccl.PCBfootprint')">
                  <el-input v-model="form.footPrint" placeholder="" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item :label="$t('ccls.ccl.comments')">
                  <el-input v-model="form.comments" placeholder="" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('ccls.ccl.TempRating')">
                  <el-input v-model="form.tempRating" placeholder="" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('ccls.ccl.MSL')">
                  <el-input v-model="form.msl" placeholder="" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item
                  :label="$t('ccls.ccl.DirectBuy')"
                  class="custom-el-form-rules"
                >
                  <el-input
                    v-model="form.directBuy"
                    @change="getPrice"
                    placeholder=""
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('pcbas.pcba.year')">
                  <el-input v-model="form.year" disabled placeholder="" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('pcbas.pcba.status')">
                  <el-input v-model="form.status" placeholder="" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8"></el-col>
              <el-col :span="8">
                <el-form-item :label="$t('ccls.ccl.PriceType')" PriceType>
                  <el-input v-model="form.pirceType" disabled placeholder="" />
                </el-form-item>
              </el-col>
              <el-col :span="8"></el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item :label="$t('ccls.ccl.currency')">
                  <el-select
                    clearable
                    :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                    filterable
                    v-model="form.currencyId"
                    @change="changeCurrency"
                  >
                    <el-option
                      v-for="item in currencyOptions"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('ccls.ccl.UnitPrice')">
                  <el-input
                    v-model="form.unitPrice"
                    @input="transferFormat($event, form, 'unitPrice')"
                    @change="transferFormat($event, form, 'unitPrice')"
                    placeholder=""
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('ccls.ccl.SubTotalPrice')">
                  <el-input
                    v-model="form.subTotalPrice"
                    disabled
                    placeholder=""
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item :label="$t('ccls.ccl.ExtCurrency1')">
                  <el-select
                    clearable
                    :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                    filterable
                    v-model="form.extCurrencyId"
                    disabled
                  >
                    <el-option
                      v-for="item in currencyOptions"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  :label="$t('ccls.ccl.exchangeRate')"
                  class="custom-el-form-rules"
                >
                  <el-input
                    v-model="form.converRate"
                    disabled
                    @input="transferFormat($event, form, 'converRate')"
                    @change="transferFormat($event, form, 'converRate')"
                    placeholder=""
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('ccls.ccl.ExtPrice')">
                  <el-input v-model="form.extPrice" disabled placeholder="" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item :label="$t('ccls.ccl.ExtCurrency2')">
                  <el-select
                    clearable
                    :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                    filterable
                    v-model="form.extCurrencyId2"
                    disabled
                  >
                    <el-option
                      v-for="item in currencyOptions"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  :label="$t('ccls.ccl.ExchangeRate2')"
                  class="custom-el-form-rules"
                >
                  <el-input
                    v-model="form.converRate2"
                    disabled
                    @input="transferFormat($event, form, 'converRate2')"
                    @change="transferFormat($event, form, 'converRate2')"
                    placeholder=""
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('ccls.ccl.ExtPrice2')">
                  <el-input v-model="form.extPrice2" disabled placeholder="" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item :label="$t('ccls.ccl.comments')">
                  <el-input v-model="form.comment" placeholder="" />
                </el-form-item>
              </el-col>
              <el-col :span="8"></el-col>
              <el-col :span="8"></el-col>
            </el-row>
          </el-form>
        </el-col>
      </el-row>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close">{{ $t("pcbas.pcba.cancle") }}</el-button>
        <el-button
          style="
            background-color: var(--themeColor);
            color: var(--menuTextActive);
            margin-right: 16px;
          "
          @click="confirm"
          >{{ $t("pcbas.pcba.confirm") }}</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {
  GetEbomUnitPrice,
  EBomsGetRate,
} from "../../../assets/js/requestApi.js";
import commonUtil from "../../../assets/js/commonUtil.js";
export default {
  props: ["currencyOptions"],
  emits: ["confirmInfo"],
  data() {
    return {
      visible: false,
      form: {
        id: "",
        mainId: "",
        nexteerPart: "",
        reference: "",
        description: "",
        quantity: "",
        nexteerChart: "",
        footPrint: "",
        comments: "",
        tempRating: "",
        msl: "",
        directBuy: "",
        year: "",
        status: "",
        currencyId: "",
        extCurrencyId: "",
        converRate: "",
        unitPrice: "",
        extPrice: "",
        version: "",
        comment: "",
        extCurrencyId2: "",
        converRate2: "",
        extPrice2: "",
        subTotalPrice: "",
        pirceType: "",
      },
      regionOptions: [],
    };
  },
  methods: {
    close() {
      this.visible = false;
      this.form = {
        id: "",
        mainId: "",
        nexteerPart: "",
        reference: "",
        description: "",
        quantity: "",
        nexteerChart: "",
        footPrint: "",
        comments: "",
        tempRating: "",
        msl: "",
        directBuy: "",
        year: "",
        status: "",
        currencyId: "",
        extCurrencyId: "",
        converRate: "",
        unitPrice: "",
        extPrice: "",
        version: "",
        comment: "",
        extCurrencyId2: "",
        converRate2: "",
        extPrice2: "",
        subTotalPrice: "",
        pirceType: "",
      };
    },
    getPrice() {
      if (this.form.nexteerPart != "" && this.form.directBuy != "") {
        GetEbomUnitPrice(
          {
            mainId: this.form.mainId,
            priceType: this.form.priceType,
            year: this.form.year,
            nexteerPart: this.form.nexteerPart,
            directBuy: this.form.directBuy,
            priceType: this.form.pirceType,
          },
          (response) => {
            if (response && response.code == 200) {
              this.form.currencyId =
                response.result.currencyId === null
                  ? ""
                  : response.result.currencyId;
              this.form.converRate =
                response.result.exchangeRate1 === null
                  ? "1"
                  : response.result.exchangeRate1;
              this.form.converRate2 =
                response.result.exchangeRate2 === null
                  ? "1"
                  : response.result.exchangeRate2;
              this.form.unitPrice =
                response.result.unitPrice === null
                  ? "0"
                  : response.result.unitPrice;
              this.form.subTotalPrice = (
                parseFloat(this.form.unitPrice) *
                parseFloat(this.form.quantity == "" ? 0 : this.form.quantity)
              ).toFixed(12);
              this.form.extPrice = (
                parseFloat(this.form.subTotalPrice) *
                parseFloat(this.form.converRate)
              ).toFixed(12);
              this.form.extPrice2 = (
                parseFloat(this.form.extPrice) *
                parseFloat(this.form.converRate2)
              ).toFixed(12);
            }
          }
        );
      }
    },
    // 修改币种
    changeCurrency(val) {
      this.getRates({
        currency: this.form.currencyId,
        reportCurrency: this.form.extCurrencyId,
      });
    },
    // 获取汇率方法
    getRates(data) {
      EBomsGetRate(data, (response) => {
        if (response && response.code == 200) {
          if (response.result == null) {
            this.form.converRate = "1";
          } else {
            this.form.converRate = response.result;
          }
          let converRateList = this.form.converRate.split(".");
          this.form.extPrice = (
            parseFloat(
              this.form.subTotalPrice == "" ? 0 : this.form.subTotalPrice
            ) *
            parseFloat(
              this.form.converRate == "" ||
                (converRateList.length == 2 && converRateList[1] == "")
                ? 1
                : this.form.converRate
            )
          ).toFixed(12);
          let converRateList2 = this.form.converRate2.split(".");
          this.form.extPrice2 = (
            parseFloat(this.form.extPrice == "" ? 0 : this.form.extPrice) *
            parseFloat(
              this.form.converRate2 == "" ||
                (converRateList2.length == 2 && converRateList2[1] == "")
                ? 1
                : this.form.converRate2
            )
          ).toFixed(12);
        }
      });
    },
    confirm() {
      if (this.form.nexteerPart == "") {
        commonUtil.showTipInfo("Please enter Nexteer Part", "", "warning");
        return;
      }
      if (this.form.directBuy == "") {
        commonUtil.showTipInfo("Please enter Direct Buy", "", "warning");
        return;
      }
      if (this.form.converRate == "") {
        commonUtil.showTipInfo("Please enter Exchange Rate1", "", "warning");
        return;
      }
      if (this.form.converRate2 == "") {
        commonUtil.showTipInfo("Please enter Exchange Rate2", "", "warning");
        return;
      }
      // let form = {
      // 	id: this.form.id,
      // 	mainId: this.form.mainId,
      // 	nexteerPart: this.form.nexteerPart,
      // 	reference: this.form.reference,
      // 	description: this.form.description,
      // 	quantity: this.form.quantity,
      // 	nexteerChart: this.form.nexteerChart,
      // 	footPrint: this.form.footPrint,
      // 	comments: this.form.comments,
      // 	tempRating: this.form.tempRating,
      // 	msl: this.form.msl,
      // 	directBuy: this.form.directBuy,
      // 	status: this.form.status,
      // 	converRate: this.form.converRate,
      // 	version: this.form.version,
      // 	comment: this.form.comment,
      // 	converRate2: this.form.converRate2,
      // }
      this.form.unitPrice =
        this.form.unitPrice === "" || this.form.unitPrice === null
          ? "0"
          : this.form.unitPrice;
      this.$emit("confirmInfo", this.form);
      // this.close()
    },
    transferFormat(val, item, name) {
      item[name] = val.replace(/^\D*(\d*(?:\.\d{0,12})?).*$/g, "$1");
      if (name === "quantity" || name === "unitPrice") {
        this.form.converRate =
          this.form.converRate == "" ? "1" : this.form.converRate;
        this.form.converRate2 =
          this.form.converRate2 == "" ? "1" : this.form.converRate2;
        this.form.subTotalPrice = (
          parseFloat(this.form.unitPrice == "" ? 0 : this.form.unitPrice) *
          parseFloat(this.form.quantity == "" ? 0 : this.form.quantity)
        ).toFixed(12);
        let converRateList = item.converRate.split(".");
        this.form.extPrice = (
          parseFloat(
            this.form.subTotalPrice == "" ? 0 : this.form.subTotalPrice
          ) *
          parseFloat(
            this.form.converRate == "" ||
              (converRateList.length == 2 && converRateList[1] == "")
              ? 1
              : this.form.converRate
          )
        ).toFixed(12);
        let converRateList2 = item.converRate2.split(".");
        this.form.extPrice2 = (
          parseFloat(this.form.extPrice == "" ? 0 : this.form.extPrice) *
          parseFloat(
            this.form.converRate2 == "" ||
              (converRateList2.length == 2 && converRateList2[1] == "")
              ? 1
              : this.form.converRate2
          )
        ).toFixed(12);
      }
      if (name === "converRate") {
        let converRateList = item.converRate.split(".");
        this.form.extPrice = (
          parseFloat(
            this.form.subTotalPrice == "" ? 0 : this.form.subTotalPrice
          ) *
          parseFloat(
            this.form.converRate == "" ||
              (converRateList.length == 2 && converRateList[1] == "")
              ? 1
              : this.form.converRate
          )
        ).toFixed(12);
        let converRateList2 = item.converRate2.split(".");
        this.form.extPrice2 = (
          parseFloat(this.form.extPrice == "" ? 0 : this.form.extPrice) *
          parseFloat(
            this.form.converRate2 == "" ||
              (converRateList2.length == 2 && converRateList2[1] == "")
              ? 1
              : this.form.converRate2
          )
        ).toFixed(12);
      }
      if (name === "converRate2") {
        let converRateList = item.converRate2.split(".");
        this.form.extPrice2 = (
          parseFloat(this.form.extPrice == "" ? 0 : this.form.extPrice) *
          parseFloat(
            this.form.converRate2 == "" ||
              (converRateList.length == 2 && converRateList[1] == "")
              ? 1
              : this.form.converRate2
          )
        ).toFixed(12);
      }
    },
  },
};
</script>

<style scoped>
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.size-16 {
  font-size: 16px;
}
.padding-9 {
  padding: 9px;
}
.el-row {
  margin-bottom: 0px;
}
.color-red {
  color: red;
}
.align-right {
  text-align: right;
}
</style>