<template>
  <div style="min-height: 100%; background: #fff">
    <div class="crumbs-box">
      <div class="crumbs-box-item parent" @click="Goback">
        {{ $t("homePage.home.Back") }}
      </div>
      <div class="line"></div>
      <div class="crumbs-box-item" v-for="(item, index) in crumbs">
        {{ item }}
        <el-icon v-show="index != crumbs.length - 1"><ArrowRight /></el-icon>
      </div>
    </div>

    <!-- 顶部主表信息 -->
    <div class="add-cla-top">
      <el-row>
        <el-col :span="22">
          <el-form :inline="true" :model="form" label-width="200px">
            <el-row>
              <el-col :span="8">
                <el-form-item
                  :label="$t('pcbas.pcba.nexteerPart') + '：'"
                  class="custom-el-form-rules"
                >
                  <el-input
                    v-model="form.nexteerPart"
                    placeholder=""
                    style="width: 214px"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  :label="$t('pcbas.pcba.manufacturingPart') + '：'"
                  class="custom-el-form-rules"
                >
                  <el-input
                    v-model="form.manufacturingPart"
                    placeholder=""
                    style="width: 214px"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  :label="$t('pcbas.pcba.deviceType') + '：'"
                  class="custom-el-form-rules"
                >
                  <el-input
                    v-model="form.deviceType"
                    placeholder=""
                    style="width: 214px"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item :label="$t('pcbas.pcba.alternateOption') + '：'">
                  <el-input
                    v-model="form.alternateOption"
                    placeholder=""
                    style="width: 214px"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('pcbas.pcba.compDescription') + '：'">
                  <el-input
                    v-model="form.compDescription"
                    placeholder=""
                    style="width: 214px"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  :label="$t('pcbas.pcba.type') + '：'"
                  class="custom-el-form-rules"
                >
                  <el-input
                    v-model="form.type"
                    placeholder=""
                    style="width: 214px"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item
                  :label="$t('pcbas.pcba.currency') + '：'"
                  class="custom-el-form-rules"
                >
                  <el-select
                    clearable
                    :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                    filterable
                    v-model="form.currencyId"
                    placeholder=""
                    style="width: 214px"
                  >
                    <el-option
                      v-for="item in currencyOptions"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('pcbas.pcba.status') + '：'">
                  <el-input
                    v-model="form.status"
                    placeholder=""
                    style="width: 214px"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('pcbas.pcba.otherInfo') + '：'">
                  <el-input
                    v-model="form.otherInfo"
                    placeholder=""
                    style="width: 214px"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item :label="$t('pcbas.pcba.ppapComplete') + '：'">
                  <el-input
                    v-model="form.ppapComplete"
                    placeholder=""
                    style="width: 214px"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('pcbas.pcba.ppapDueDate') + '：'">
                  <el-input
                    v-model="form.dueDate"
                    placeholder=""
                    style="width: 214px"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('pcbas.pcba.note') + '：'">
                  <el-input
                    v-model="form.note"
                    placeholder=""
                    style="width: 214px"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item
                  :label="$t('pcbas.pcba.supplierName') + '：'"
                  class="custom-el-form-rules"
                >
                  <el-input
                    v-model="form.supplierName"
                    placeholder=""
                    style="width: 214px"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-col>
        <!-- <el-col :span="2">
					<div class="operate-btn submit-btn" @click="submitForm">{{$t('pcbas.pcba.confirm')}}</div>
				</el-col> -->
      </el-row>
    </div>
    <!-- 子表年份价格信息 -->
    <div class="add-cla-contain">
      <!-- <div class="operate-btn" @click="add">{{$t("pcbas.pcba.new")}}</div> -->
      <YearMoudle ref="yearCla"></YearMoudle>
      <!-- <table class="add-table">
				<tr>
					<td>{{$t('pcbas.pcba.no')}}</td>
					<td style="width: 200px;">{{$t('pcbas.pcba.operate')}}</td>
					<td>{{$t('pcbas.pcba.year')}}</td>
					<td>{{$t('pcbas.pcba.price')}}</td>
				</tr>
				<tr v-for="(item, index) in tableData">
					<td>{{index + 1}}</td>
					<td>
						<span class="btn-text" style="margin-right: 20px;" @click="editDetail(item)">{{$t("pcbas.pcba.edit")}}</span>
						<span class="btn-text" @click="deleteDetail(item)">{{$t("pcbas.pcba.delete")}}</span>
					</td>
					<td>{{item.year}}</td>
					<td class="color-F59A23">{{item.price}}</td>
				</tr>
			</table> -->
      <div class="btn-cla">
        <div class="operate-btn submit-btn" @click="submitForm">
          {{ $t("pcbas.pcba.confirm") }}
        </div>
      </div>
    </div>
    <AddPriceDia ref="addDia" @submitPrice="submitPrice"></AddPriceDia>
  </div>
</template>

<script>
import AddPriceDia from "./addPriceDia.vue";
import YearMoudle from "./yearMoudle.vue";
import {
  AddOrUpdateEBom_YESPricings,
  loadCurrency,
  EBom_YESPricingsGetDetail,
  AddOrUpdateDetailEBom_YESPricings,
  DetailDeleteEBom_YESPricings,
} from "../../../assets/js/requestApi.js";
import commonUtil from "@/assets/js/commonUtil.js";
import "../../../assets/css/custom-el-form-rules.css";
export default {
  components: {
    AddPriceDia,
    YearMoudle,
  },
  data() {
    return {
      HandleType: "",
      // 头部父表表单信息
      form: {
        id: "",
        nexteerPart: "",
        manufacturingPart: "",
        deviceType: "",
        alternateOption: "",
        compDescription: "",
        type: "",
        currencyId: "",
        status: "",
        otherInfo: "",
        ppapComplete: "",
        dueDate: "",
        note: "",
        supplierName: "",
        detailList: [],
      },
      // 子表年份价格
      tableData: [],
      // 货币选项
      currencyOptions: [],
      crumbs: [],
    };
  },
  mounted() {
    this.getCurrencyOptions();
    this.HandleType = this.$route.query.type;
    if (this.$route.query.id) {
      this.form.id = this.$route.query.id;
      this.getDetail();
    }
  },
  watch: {
    HandleType: {
      immediate: true,
      handler(nval) {
        this.ChangeLan(nval);
      },
    },
    "$i18n.locale"(val) {
      this.ChangeLan(this.HandleType);
    },
  },
  methods: {
    ChangeLan(val) {
      switch (val) {
        case "add":
          this.crumbs = [
            "PCBA",
            "Yes Price",
            this.$t("unifiedPrompts.unifiedPrompt.new"),
          ];
          break;
        case "edit":
          this.crumbs = [
            "PCBA",
            "Yes Price",
            this.$t("unifiedPrompts.unifiedPrompt.edit"),
          ];
          break;
      }
    },
    // 查询货币
    getCurrencyOptions() {
      loadCurrency(
        {
          IsOrderNumber: 0,
          Keyword: "",
          page: 1, //当前页
          limit: 99999, //每页个数
        },
        (response) => {
          if (response && response.code == 200) {
            this.currencyOptions = response.data;
          }
        }
      );
    },
    // 获取详情
    getDetail() {
      let _this = this;
      EBom_YESPricingsGetDetail(
        {
          id: this.form.id,
        },
        (response) => {
          if (response && response.code == 200) {
            this.form.nexteerPart = response.result.nexteerPart;
            this.form.manufacturingPart = response.result.manufacturingPart;
            this.form.deviceType = response.result.deviceType;
            this.form.alternateOption = response.result.alternateOption;
            this.form.compDescription = response.result.compDescription;
            this.form.type = response.result.type;
            this.form.currencyId = response.result.currencyId;
            this.form.status = response.result.status;
            this.form.otherInfo = response.result.otherInfo;
            this.form.ppapComplete = response.result.ppapComplete;
            this.form.dueDate = response.result.dueDate;
            this.form.note = response.result.note;
            this.form.supplierName = response.result.supplierName;
            let detailList = [];
            response.result.detailLst.forEach(function (item, index) {
              detailList.push({
                id: item.id,
                year: item.year,
                price: _this.$refs.yearCla.format_with_regex(item.price),
                volume: _this.$refs.yearCla.format_with_regex(item.volume),
              });
            });
            this.$refs.yearCla.detailList = detailList;
          }
        }
      );
    },
    // 提交父表信息
    submitForm() {
      this.form.detailList = JSON.parse(
        JSON.stringify(this.$refs.yearCla.detailList)
      );
      if (
        this.form.nexteerPart === "" ||
        this.form.nexteerPart === null ||
        !this.form.nexteerPart
      ) {
        commonUtil.showTipInfo("Please enter nexteerPart", "", "warning");
        return;
      }
      if (
        this.form.manufacturingPart === "" ||
        this.form.manufacturingPart === null ||
        !this.form.manufacturingPart
      ) {
        commonUtil.showTipInfo("Please enter manufacturingPart", "", "warning");
        return;
      }
      if (
        this.form.deviceType === "" ||
        this.form.deviceType === null ||
        !this.form.deviceType
      ) {
        commonUtil.showTipInfo("Please enter deviceType", "", "warning");
        return;
      }
      if (this.form.type === "" || this.form.type === null || !this.form.type) {
        commonUtil.showTipInfo("Please enter type", "", "warning");
        return;
      }
      if (
        this.form.currencyId === "" ||
        this.form.currencyId === null ||
        !this.form.currencyId
      ) {
        commonUtil.showTipInfo("Please choose currency", "", "warning");
        return;
      }
      if (
        this.form.supplierName === "" ||
        this.form.supplierName === null ||
        !this.form.supplierName
      ) {
        commonUtil.showTipInfo("Please enter supplierName", "", "warning");
        return;
      }
      let yearIsEmpty = false;
      let priceIsEmpty = false;
      this.form.detailList.forEach(function (item, index) {
        if (item.year === "" || item.year === null || !item.year) {
          yearIsEmpty = true;
          return;
        }
        if (item.price === "" || item.price === null || !item.price) {
          priceIsEmpty = true;
          return;
        } else {
          item.price = item.price.replace(/,/g, "");
        }
      });
      if (yearIsEmpty) {
        commonUtil.showTipInfo("Please enter year", "", "warning");
        return;
      }
      if (priceIsEmpty) {
        commonUtil.showTipInfo("Please enter price", "", "warning");
        return;
      }
      AddOrUpdateEBom_YESPricings(this.form, (response) => {
        if (response && response.code == 200) {
          this.form.id = response.result;
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.operateSuccessfully"),
            "",
            "success"
          );
          this.$route.meta.showTag = false;
          this.$router.back(-1);
        } else {
          commonUtil.showTipInfo(
            "NexteerPart, Status, ManufacturingPart data duplication",
            "",
            "warning"
          );
        }
      });
    },
    // 新增
    add() {
      if (this.form.id == "" || this.form.id == null || !this.form.id) {
        commonUtil.showTipInfo(
          this.$t("pcbas.pcba.pleseEnterMain"),
          "",
          "warning"
        );
        return;
      }
      this.$refs.addDia.form.yesPricingId = this.form.id;
      this.$refs.addDia.visible = true;
    },
    // 编辑
    editDetail(item) {
      if (this.form.id == "" || this.form.id == null || !this.form.id) {
        commonUtil.showTipInfo(
          this.$t("pcbas.pcba.pleseEnterMain"),
          "",
          "warning"
        );
        return;
      }
      this.$refs.addDia.form = {
        yesPricingId: item.yesPricingId,
        id: item.id,
        year: item.year,
        price: item.price,
      };
      this.$refs.addDia.visible = true;
    },
    // 删除
    deleteDetail(item) {
      let ids = [];
      ids.push(item.id);
      DetailDeleteEBom_YESPricings(ids, (response) => {
        if (response && response.code == 200) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.operateSuccessfully"),
            "",
            "success"
          );
          this.getDetail();
        }
      });
    },
    // 获取填写的年份价格
    submitPrice(form) {
      AddOrUpdateDetailEBom_YESPricings(form, (response) => {
        if (response && response.code == 200) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.operateSuccessfully"),
            "",
            "success"
          );
          this.$refs.addDia.close();
          this.getDetail();
        }
      });
    },
    Goback() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped lang="scss">
.crumbs-box {
  position: fixed;
  top: 70px;
  left: 220px;
  padding-left: 20px;
  width: 100%;
  display: flex;
  background-color: #f2f2f2;
  z-index: 999;
  // border-bottom: 1px solid #e4e7ed;
  align-items: center;
  height: 30px;
  font-size: 16px;
  color: #3e4954;
  .crumbs-box-item {
    display: flex;
    align-items: center;
  }
  .el-icon {
    margin: 0 10px;
  }
  .parent {
    color: #2f4cdd;
    cursor: pointer;
  }
  .line {
    margin: 0 15px;
    width: 2px;
    height: 15px;
    background: #3e4954;
  }
}
.add-cla-top {
  padding: 40px 20px 0 20px;
  min-height: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid #ebedf0;
  background: #fff;
}
.btn-cla {
  width: 100%;
  height: 50px;
  position: relative;
}
.operate-btn {
  border-radius: 5px;
  background-color: var(--themeColor);
  color: #fff;
  text-align: center;
  padding: 7px 10px;
  display: inline-block;
  min-width: 80px;
  cursor: pointer;
}
.submit-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
}
.add-cla-contain {
  padding: 20px;
  box-sizing: border-box;
  background: #fff;
}
.add-table {
  width: 50%;
  font-size: 14px;
  margin-top: 20px;
  border-collapse: collapse;
}
.add-table td {
  border: 1px solid #8c8c8c;
  padding: 5px 10px;
  text-align: center;
}
.btn-text {
  color: var(--themeColor);
  font-weight: bold;
  cursor: pointer;
}
.color-F59A23 {
  color: #f59a23;
}
</style>