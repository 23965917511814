<template>
  <div class="main">
    <div class="main-top_handle">
      <div class="title">{{ $t("homePage.home.Individualtemplates") }}</div>
      <div class="main-handle flex">
        <!-- 状态 -->
        <div class="main-handle-item">
          {{ $t("templateLists.templateList.enable") }}
          <el-select
            size="small"
            clearable
            v-model="searchForm.Enable"
            :placeholder="$t('templateLists.templateList.enable')"
          >
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="$t(item.label)"
              :value="item.value"
            />
          </el-select>
        </div>
        <div class="main-handle-item">
          {{ $t("templateLists.templateList.region") }}
          <el-select
            size="small"
            clearable
            v-model="searchForm.RegionId"
            :placeholder="$t('templateLists.templateList.region')"
          >
            <el-option
              v-for="item in regionOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </div>
        <!-- 日期选择 -->
        <div class="main-handle-item" style="margin-top: 15px">
          <div class="select-box">
            <div class="select-btn">
              <el-icon class="Calendar"><Calendar /></el-icon>
              <span :style="{ color: searchForm.date ? '#606266' : '' }">{{
                searchForm.date
                  ? searchForm.date[0] + " - " + searchForm.date[1]
                  : $t("tcmdatalists.tcmdatalist.pleaseSlect")
              }}</span>
              <el-icon v-show="!searchForm.date">
                <ArrowDownBold />
              </el-icon>
              <el-icon :size="18" v-show="searchForm.date">
                <Close />
              </el-icon>
            </div>
            <el-config-provider
              :locale="$t('homePage.home.locale') == 'locale' ? locale : ''"
            >
              <el-date-picker
                class="date-picker"
                style="
                  width: 200px;
                  height: 30px;
                  position: absolute;
                  top: 0;
                  left: 0;
                  opacity: 0;
                "
                v-model="searchForm.date"
                type="daterange"
                range-separator="To"
                :start-placeholder="
                  $t('unifiedPrompts.unifiedPrompt.startDate')
                "
                :end-placeholder="$t('unifiedPrompts.unifiedPrompt.endDate')"
                size="mini"
                value-format="YYYY-MM-DD"
                @change="changeDate"
              />
            </el-config-provider>
          </div>
        </div>

        <div class="main-handle-item" style="margin-top: 15px">
          <input
            type="text"
            v-model="searchForm.Keyword"
            :placeholder="$t('unifiedPrompts.unifiedPrompt.keyword')"
          />
        </div>
        <div class="search-btn" @click="search" style="margin-top: 15px">
          {{ $t("tcmlists.tcmlist.search") }}
        </div>
      </div>
    </div>

    <div class="handle-btn flex">
      <div
        v-if="BtnLimits.Add"
        class="handle-btn-item"
        style="margin-right: 10px"
        @click="addTemplate"
      >
        {{ $t("unifiedPrompts.unifiedPrompt.new") }}
      </div>
      <div
        v-if="BtnLimits.Delete"
        class="handle-btn-item"
        style="margin-right: 10px"
        @click="bathOperate('delete')"
      >
        {{ $t("unifiedPrompts.unifiedPrompt.batchDelete") }}
      </div>
    </div>
    <div class="regional_table">
      <el-table
        border
        stripe
        :empty-text="$t('tcmlists.tcmlist.noData')"
        :data="tableData"
        :height="TableHeight"
        style="width: 100%"
        @row-click="RowClick"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>

        <!-- <el-table-column
          prop="number"
          :label="$t('templateLists.templateList.templateNo')"
          show-overflow-tooltip
          align="center"
        /> -->
        <el-table-column
          prop="name"
          :label="$t('templateLists.templateList.templateName')"
          show-overflow-tooltip
          align="center"
        />
        <el-table-column
          prop="regionName"
          :label="$t('templateLists.templateList.region')"
          width="100"
          show-overflow-tooltip
          align="center"
        />
        <!-- <el-table-column prop="version" :label="$t('templateLists.templateList.version')" width="100" show-overflow-tooltip align="center" /> -->
        <el-table-column
          prop="commodityName"
          :label="$t('tcmdatalists.tcmdatalist.commodity')"
          show-overflow-tooltip
          align="center"
        />
        <el-table-column
          prop="comment"
          :label="$t('templateLists.templateList.comments')"
          show-overflow-tooltip
          align="center"
        />
        <el-table-column
          prop="creator"
          :label="$t('templateLists.templateList.creator')"
          width="100"
          show-overflow-tooltip
          align="center"
        />
        <el-table-column
          prop="date"
          :label="$t('templateLists.templateList.date')"
          show-overflow-tooltip
          align="center"
        />
        <el-table-column
          prop="enable"
          :label="$t('templateLists.templateList.enable')"
          width="80"
          show-overflow-tooltip
          align="center"
        >
          <template #default="scope">
            <el-icon
              :size="16"
              color="#40D4A8"
              v-show="scope.row.isEnable == '1'"
            >
              <SuccessFilled />
            </el-icon>
            <el-icon
              :size="16"
              color="#F56C6C"
              v-show="scope.row.isEnable == '2'"
            >
              <CircleCloseFilled />
            </el-icon>
          </template>
        </el-table-column>
        <el-table-column
          prop="status"
          width="80"
          fixed="right"
          class-name="lastTd"
          :label="$t('templateLists.templateList.operate')"
          align="center"
        >
          <template #default="scope">
            <el-popover placement="bottom" trigger="hover">
              <template #reference>
                <div style="text-align: center">
                  <el-icon><MoreFilled /></el-icon>
                </div>
              </template>
              <div class="action-box">
                <div
                  v-if="BtnLimits.Edit"
                  class="action-btn"
                  @click="editTemplate(scope.row)"
                >
                  <el-icon><Edit /></el-icon>
                  <span>{{ $t("unifiedPrompts.unifiedPrompt.edit") }}</span>
                </div>
                <div
                  v-if="BtnLimits.Delete"
                  class="action-btn"
                  @click="deleteTemplate(scope.row.id)"
                >
                  <el-icon><Delete /></el-icon>
                  <span>{{ $t("unifiedPrompts.unifiedPrompt.delete") }}</span>
                </div>
                <div
                  v-if="BtnLimits.Detail"
                  class="action-btn"
                  @click="previewTemplate(scope.row)"
                >
                  <el-icon><View /></el-icon>
                  <span>{{ $t("unifiedPrompts.unifiedPrompt.preview") }}</span>
                </div>
              </div>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <div>
        {{ $t("homePage.home.Showing") }} {{ pageSize }}
        {{ $t("homePage.home.From") }} {{ total }}
        {{ $t("homePage.home.Data") }}
        <el-popover placement="top" :width="70" trigger="click">
          <template #reference>
            <el-icon
              style="margin-left: 5px; font-size: 17px; vertical-align: middle"
              ><Operation
            /></el-icon>
          </template>
          <el-input
            v-model="searchForm.limit"
            @input="(val) => (searchForm.limit = val.replace(/[^\d]/g, ''))"
            :min="1"
            style="width: 130px"
            @change="handleSizeChange"
          >
            <template #suffix> {{ $t("ccls.ccl.barPage") }}</template>
          </el-input>
        </el-popover>
      </div>
      <el-pagination
        layout="prev, pager, next"
        background
        :total="total"
        :page-size="pageSize"
        :current-page="searchForm.page"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import {
  GetOrgs,
  GetTemplateApplys,
  deleteTemplateApplys,
  startTemplateApplys,
  stopTemplateApplys,
  getRegions,
} from "../../assets/js/requestApi.js";
import commonUtil from "../../assets/js/commonUtil.js";
import "../../assets/css/custom-el-tree.css";
import zhCn from "element-plus/dist/locale/zh-cn.mjs";

export default {
  name: "MyTemplateList",
  data() {
    return {
      locale: zhCn,
      TableHeight: 0,
      timers: "",
      searchForm: {
        date: "",
        Enable: 0,
        StartDate: "",
        EndDate: "",
        status: "",
        Keyword: "",
        CommodityId: "",
        currencyId: "",
        regionId: "",
        isDraft: 1,
        VersionType: 1,
        page: 1,
        limit: 10,
      },
      // 总页数
      total: 0,
      pageSize: 10,
      // status选项
      statusOptions: [
        {
          value: 0,
          label: "other.other.All",
        },
        {
          value: 1,
          label: "Enable",
          label: "other.other.Enable",
        },
        {
          value: 2,
          label: "Disable",
          label: "other.other.Disable",
        },
      ],
      filterText: "",
      // 商品分类数据
      treedata: [],
      defaultProps: {
        children: "children",
        label: "Name",
      },
      // region选项
      regionOptions: [],
      // 表格数据
      tableData: [],
      // 已选择的商品分类名称
      CommodityName: "",
      // 已勾选数据
      chooseData: [],
      // 是否为历史版本
      historyVal: false,
      // commodity选项
      CommodityOptions: [],
      defaultProps: {
        children: "children",
        label: "Name",
      },
      BtnLimits: {},
      timer: "",
    };
  },
  watch: {
    filterText(val) {
      this.$refs.treeRef.filter(val);
    },
    historyVal(val) {
      if (val) {
        this.searchForm.page = 1;
        this.searchForm.VersionType = 2;
        this.search();
      } else {
        this.searchForm.page = 1;
        this.searchForm.VersionType = 1;
        this.search();
      }
    },
  },
  mounted() {
    this.autoHeight();
    this.getlist();
    this.getTreeData();
    // this.getRegion();
    this.regionOptions = JSON.parse(sessionStorage.getItem("Region"));
    this.getUnits();
    // this.getUserCommodity();
  },
  beforeUnmount() {
    clearTimeout(this.timer);
  },
  methods: {
    RowClick(row) {
      if (this.BtnLimits.Detail) {
        this.previewTemplate(row);
      }
    },
    autoHeight() {
      // 初始化的时候，设置高度
      this.setHeight();
      // 改变浏览器窗口大小的时候，设置高度
      window.addEventListener("resize", () => {
        this.setHeight();
      });
    },
    setHeight() {
      this.$nextTick(() => {
        this.TableHeight = window.innerHeight - 235; // 这里的X就是顶部栏的高度
      });
    },
    getUnits() {
      this.timer = setInterval(this.gettime, 1000);
    },
    gettime() {
      this.BtnLimits = JSON.parse(sessionStorage.getItem("Btn"));
    },
    getlist() {
      this.searchForm = {
        date: "",
        Enable: 0,
        StartDate: "",
        EndDate: "",
        status: "",
        Keyword: "",
        regionId: "",
        CommodityId: "",
        currencyId: "",
        VersionType: 1,
        isDraft: 1,
        page: 1,
        limit: 10,
      };
      this.search();
    },
    // 查询
    search() {
      GetTemplateApplys(this.searchForm, (response) => {
        if (response && response.code == 200) {
          this.tableData = response.data;
          this.total = response.count;
        }
      });
    },
    // 分页
    handleCurrentChange(val) {
      this.searchForm.page = val;
      this.search();
    },
    // 分页数量修改
    handleSizeChange() {
      this.pageSize = parseInt(this.searchForm.limit);
      if (this.searchForm.limit === "") {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.setPageNum"),
          "",
          "warning"
        );

        return;
      }
      if (this.searchForm.limit == 0) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.pageThanZero"),
          "",
          "warning"
        );

        return;
      }
      this.searchForm.page = 1;
      this.search();
    },
    // 新增
    addTemplate() {
      // if (this.searchForm.CommodityId == '') {
      // 	commonUtil.showTipInfo('请选择商品类别！', '', 'warning')
      // 	return;
      // }
      this.$router.push({
        path: "/addTemplate",
        name: "AddTemplate",
        query: {
          type: "add",
          CommodityId: this.searchForm.CommodityId,
          CommodityName: this.CommodityName,
          template: "myTemplate",
          crumbs: [
            "homePage.home.Individualtemplates",
            "unifiedPrompts.unifiedPrompt.new",
          ],
        },
      });
    },
    // 编辑
    editTemplate(row) {
      this.$router.push({
        path: "/addTemplate",
        name: "AddTemplate",
        query: {
          type: "edit",
          CommodityName: row.commodityName,
          id: row.id,
          template: "myTemplate",
          crumbs: [
            "homePage.home.Individualtemplates",
            "unifiedPrompts.unifiedPrompt.edit",
          ],
        },
      });
    },
    // 获取区域列表选项
    getRegion() {
      getRegions(
        {
          Keyword: "",
          isEnable: 1,
          page: 1,
          limit: 9999,
          key: "",
        },
        (response) => {
          if (response && response.code == 200) {
            this.regionOptions = response.data;
          }
        }
      );
    },
    // 获取组织树数据
    getTreeData() {
      let _this = this;
      GetOrgs(
        {
          type: 1,
          versionType: this.searchForm.VersionType,
          TypeCost: 0,
          isAll: 0,
        },
        (response) => {
          if (response && response.Code == 200) {
            let data = response.Result;
            data.forEach(function (val, key) {
              val.disabled = val.isEnable == 1 ? false : true;
            });
            this.treedata = _this.transferList(response.Result);
          }
        }
      );
    },
    // 获取用户对应的商品分类选项
    getUserCommodity() {
      let _this = this;
      GetOrgs(
        {
          type: 0,
          versionType: 0,
          TypeCost: 0,
          isAll: 0,
        },
        (response) => {
          if (response && response.Code == 200) {
            this.CommodityOptions = commonUtil.transferList(response.Result);
          }
        }
      );
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.Name.indexOf(value) !== -1;
    },
    // 树点击事件
    handleNodeClick(data) {
      this.searchForm.CommodityId = "";
      this.CommodityName = "";
      if (data.disabled) {
        commonUtil.showTipInfo("当前商品分类已禁用！", "", "warning");
        return;
      }
      this.searchForm.CommodityId = data.Id;
      this.CommodityName = data.Name;
      this.search();
    },
    // 转化树数据
    transferList(data) {
      const res = [];
      data.forEach((item) => {
        const parent = data.find((node) => node.Id === item.ParentId);
        if (parent) {
          parent.children = parent.children || [];
          parent.children.push(item);
        } else {
          // * 根节点
          res.push(item);
        }
      });
      return res;
    },
    // 切换时间
    changeDate(val) {
      if (val != null) {
        this.searchForm.StartDate = val[0];
        this.searchForm.EndDate = val[1];
      } else {
        this.searchForm.StartDate = "";
        this.searchForm.EndDate = "";
      }
    },
    // 查看详情
    previewTemplate(row) {
      this.$router.push({
        path: "/previewTemplate",
        name: "PreviewTemplate",
        query: {
          type: "detail",
          CommodityName: row.commodityName,
          id: row.id,
        },
      });
    },
    // 删除
    deleteTemplate(id) {
      let ids = [];
      ids.push(id);
      this.deleteTem(ids);
    },
    // 调用删除接口
    deleteTem(ids) {
      deleteTemplateApplys(ids, (response) => {
        if (response && response.code == 200) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.deleteSuccessfully"),
            "",
            "success"
          );
          this.search();
        }
      });
    },
    // 表格多选
    handleSelectionChange(val) {
      this.chooseData = val;
    },
    // 批量操作
    bathOperate(type) {
      if (this.chooseData.length == 0) {
        commonUtil.showTipInfo(
          this.$t("calculators.calculator.selectManipulate"),
          "",
          "warning"
        );
        return;
      }
      let ids = [];
      this.chooseData.forEach(function (val, key) {
        ids.push(val.id);
      });
      if (type == "delete") {
        this.deleteTem(ids);
      } else if (type == "start") {
        // 启用
        startTemplateApplys(ids, (response) => {
          commonUtil.showTipInfo(
            this.$t("ccls.ccl.enableSuccess"),
            "",
            "success"
          );
          this.search();
        });
      } else if (type == "stop") {
        // 禁用
        stopTemplateApplys(ids, (response) => {
          commonUtil.showTipInfo(
            this.$t("ccls.ccl.disableSuccess"),
            "",
            "success"
          );
          this.search();
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.tcm-list {
  height: 100%;
}
.tcm-search {
  width: calc(100% - var(--asidewidth));
  background-color: var(--searchBg);
  padding: 20px;
  box-sizing: border-box;
  position: fixed;
  z-index: 888;
}
.tcm-search td {
  /* width: 20%; */
  padding: 0 10px;
}
.tcm-hold {
  height: 132px;
}
.operate-btn {
  border-radius: 5px;
  background-color: var(--themeColor);
  color: #fff;
  text-align: center;
  padding: 7px 10px;
  display: inline-block;
  min-width: 80px;
  cursor: pointer;
}
.operate-btnx {
  border-radius: 5px;
  background-color: var(--themeColor);
  color: #fff;
  font-size: 12px;
  text-align: center;
  padding: 2px 5px;
  min-width: 60px;
  cursor: pointer;
}
.color-ff0000 {
  color: #ff0000;
}
.color-F59A23 {
  color: #f59a23;
}
.color-0000FF {
  color: #0000ff;
}
.color-D9001B {
  color: #d9001b;
}
.page-cla {
  padding: 20px;
  display: flex;
  justify-content: flex-end;
}
.align-right {
  text-align: right;
}
.tcm-table {
  height: calc(100% - 90px);
  padding: 0;
  box-sizing: border-box;
}
.el-row {
  height: 100%;
}
.flex-around {
  display: flex;
  justify-content: space-around;
}
.disabled {
  cursor: not-allowed;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  width: 100%;
}
.my-badge {
  color: #fff;
  background: var(--themeColor);
  padding: 3px 8px;
  font-size: 12px;
  line-height: 12px;
  border-radius: 20px;
  text-align: center;
}
.my-text {
  width: calc(100% - 60px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
}

.main {
  width: 100%;
  height: 100%;
  background: url("../../assets/img/car-table-bg.png") no-repeat center;
  background-size: contain;
}

.main-top_handle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
}
.tree-cla {
  background: #fff;
  .tree-cla-search {
    padding: 10px;
  }
  input {
    display: block;
    width: calc(100% - 10px);
    height: 40px;
    border-radius: 10px;
    background: #f5f7ff;
  }
}
.main-handle {
  align-items: center;
}
.main-handle-item {
  margin-right: 10px;
  flex: 1;
}
.main-handle input,
.main-handle .el-select .el-input {
  width: 100%;
  border-radius: 10px;
  font-size: 14px;
}
.main-handle input::placeholder {
  color: #3e4954;
  text-align: center;
  font-size: 14px;
}

.handle-btn {
  align-items: center;
  height: 40px;
  .handle-btn-item {
    padding: 0 20px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    background: #b2bde7;
    color: #fff;
    border-radius: 25px;
    cursor: pointer;
    margin-left: 10px;
  }
}
// 日期选择器
.select-box {
  position: relative;
  .select-btn {
    width: 200px;
    height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 10px;
    background: #fff;
    cursor: pointer;
    .Calendar,
    .Switch {
      font-size: 18px;
      color: #2f4cdd;
    }
  }
}
.search-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 30px;
  background: #445581;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
</style>