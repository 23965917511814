<template>
  <el-dialog v-model="visible" width="80%" destroy-on-close :show-close="false">
    <template #header="{ close, titleId, titleClass }">
      <div class="my-header">
        <div class="size-16">
          {{ $t("changePriceLists.changePriceList.selectPriceReference") }}
        </div>
        <div style="cursor: pointer" @click="close">
          <el-icon :size="20"><Close /></el-icon>
        </div>
      </div>
    </template>
    <div class="tcm-table">
      <el-row>
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
          <!-- <el-tab-pane
            :label="$t('changePriceLists.changePriceList.selectCell')"
            name="first"
          ></el-tab-pane> -->
          <el-tab-pane
            :label="$t('unifiedPrompts.unifiedPrompt.selectTCM')"
            name="second"
          ></el-tab-pane>
          <el-tab-pane
            :label="$t('unifiedPrompts.unifiedPrompt.selectccl')"
            name="third"
          ></el-tab-pane>
          <el-tab-pane
            :label="$t('unifiedPrompts.unifiedPrompt.selectpcba')"
            name="fourth"
          ></el-tab-pane>
        </el-tabs>
      </el-row>
      <div v-show="activeName == 'second'">
        <table style="margin-bottom: 10px">
          <tr>
            <td style="width: 290px">
              {{ $t("changePriceLists.changePriceList.commodity") }}
              <el-tree-select
                clearable
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                v-model="tcmSearchForm.CommodityId"
                :data="CommodityOptions"
                :props="defaultProps"
                check-strictly
                :render-after-expand="false"
                :default-expand-all="true"
              />
            </td>
            <td style="width: 260px">
              <el-input v-model="tcmSearchForm.Keyword" />
            </td>
            <td style="width: 260px">
              <el-button
                style="
                  background-color: var(--themeColor);
                  color: var(--menuTextActive);
                  margin-right: 16px;
                "
                @click="searchTcm"
                >{{ $t("userlists.userlist.search") }}</el-button
              >
            </td>
          </tr>
        </table>
        <div class="regional_table">
          <el-table
            :empty-text="$t('tcmlists.tcmlist.noData')"
            :data="tcmTableData"
            border
            stripe
            style="width: 100%"
            height="400"
          >
            <el-table-column prop="select" width="110" align="center">
              <template #default="scope">
                <el-button
                  style="background: #b2bde7; color: var(--menuTextActive)"
                  @click="select(scope.row.id, 'tcm')"
                  >{{
                    $t("changePriceLists.changePriceList.select")
                  }}</el-button
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="commodityName"
              :label="$t('tcmdatalists.tcmdatalist.commodity')"
              width="110"
              align="center"
            />
            <el-table-column
              prop="tcmRequest"
              :label="$t('tcmdatalists.tcmdatalist.tcmRequester')"
              align="center"
            />
            <el-table-column
              prop="currencyName"
              :label="$t('changePriceLists.changePriceList.currency')"
              show-overflow-tooltip
              align="center"
            />
            <el-table-column
              prop="partDescription"
              :label="$t('tcmdatalists.tcmdatalist.partDescription')"
              align="center"
            />
            <el-table-column
              prop="partNumber"
              :label="$t('tcmdatalists.tcmdatalist.partNumber')"
              align="center"
            />
            <el-table-column
              prop="date"
              :label="$t('tcmdatalists.tcmdatalist.startDate')"
              sortable
              align="center"
            />
            <el-table-column
              prop="doDate"
              :label="$t('tcmdatalists.tcmdatalist.dueDate')"
              sortable
              align="center"
            />
            <el-table-column
              prop="version"
              :label="$t('templateLists.templateList.version')"
              width="120"
              align="center"
            />
          </el-table>
        </div>
        <div class="pagination">
          <p></p>
          <el-pagination
            background
            layout="prev, pager, next"
            :total="tcmTotal"
            :current-page="tcmSearchForm.page"
            @current-change="tcmHandleCurrentChange"
          />
        </div>
      </div>

      <div v-show="activeName == 'third'">
        <div class="regional_table">
          <el-table
            :empty-text="$t('tcmlists.tcmlist.noData')"
            :data="cclTableData"
            border
            stripe
            height="400"
            style="width: 100%"
          >
            <el-table-column prop="" label="" width="110" align="center">
              <template #default="scope">
                <el-button
                  style="
                    background-color: #b2bde7;
                    color: var(--menuTextActive);
                  "
                  @click="select(scope.row, 'ccl')"
                  >{{
                    $t("changePriceLists.changePriceList.select")
                  }}</el-button
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="cclSize_Code"
              :label="$t('ccls.ccl.code')"
              align="center"
              show-overflow-tooltip
            />
            <el-table-column
              prop="nexteerPart"
              :label="$t('ccls.ccl.nexteerPart')"
              align="center"
              show-overflow-tooltip
            />
            <el-table-column
              prop="name"
              :label="$t('ccls.ccl.name')"
              align="center"
              show-overflow-tooltip
            />
            <el-table-column
              prop="description"
              :label="$t('ccls.ccl.desc')"
              align="center"
              show-overflow-tooltip
            />
            <el-table-column
              prop="revisionID"
              :label="$t('ccls.ccl.revision')"
              align="center"
              show-overflow-tooltip
            />
            <el-table-column
              prop="project"
              :label="$t('ccls.ccl.project')"
              align="center"
              show-overflow-tooltip
            />
            <el-table-column
              prop="regionName"
              :label="$t('changePriceLists.changePriceList.region')"
              align="center"
              show-overflow-tooltip
            />
            <el-table-column
              prop="annualVolume"
              :label="$t('ccls.ccl.annualVolumn')"
              align="center"
            />
          </el-table>
        </div>

        <div class="pagination">
          <p></p>
          <el-pagination
            background
            layout="prev, pager, next"
            :total="cclTotal"
            :current-page="cclSearchForm.page"
            @current-change="cclHandleCurrentChange"
          />
        </div>
      </div>
      <div v-show="activeName == 'fourth'">
        <div class="regional_table">
          <el-table
            :empty-text="$t('tcmlists.tcmlist.noData')"
            :data="pcbaTableData"
            border
            stripe
            height="400"
            style="width: 100%"
          >
            <el-table-column prop="" label="" width="110" align="center">
              <template #default="scope">
                <el-button
                  style="
                    background-color: #b2bde7;
                    color: var(--menuTextActive);
                  "
                  @click="select(scope.row, 'pcba')"
                  >{{
                    $t("changePriceLists.changePriceList.select")
                  }}</el-button
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="eBOMPN"
              label="eBOM PN"
              width="110"
              align="center"
            />
            <el-table-column
              prop="name"
              :label="$t('ccls.ccl.name')"
              align="center"
            />
            <el-table-column
              prop="description"
              :label="$t('ccls.ccl.desc')"
              align="center"
            />
            <el-table-column
              prop="version"
              :label="$t('ccls.ccl.revision')"
              align="center"
            />
            <el-table-column
              prop="project"
              :label="$t('ccls.ccl.project')"
              align="center"
            />
            <el-table-column
              prop="regionName"
              :label="$t('changePriceLists.changePriceList.region')"
              align="center"
            />
            <el-table-column
              prop="annualVolume"
              :label="$t('ccls.ccl.annualVolumn')"
              align="center"
            />
            <el-table-column
              prop="extCurrencyName"
              label="Ext. Currency1"
              align="center"
            >
              <template #default="scope">
                <div class="color-F59A23">{{ scope.row.extCurrencyName }}</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="extTotalPrice"
              label="Total Ext.Price1"
              align="center"
            >
              <template #default="scope">
                <div class="color-F59A23">{{ scope.row.extTotalPrice }}</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="extCurrencyName2"
              label="Ext. Currency2"
              align="center"
            >
              <template #default="scope">
                <div class="color-F59A23">{{ scope.row.extCurrencyName2 }}</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="extTotalPrice2"
              label="Total Ext.Price2"
              align="center"
            >
              <template #default="scope">
                <div class="color-F59A23">{{ scope.row.extTotalPrice2 }}</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="date"
              :label="$t('changePriceLists.changePriceList.date')"
              align="center"
            >
              <template #default="scope">
                <div class="color-F59A23">{{ scope.row.date }}</div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination">
          <p></p>
          <el-pagination
            background
            layout="prev, pager, next"
            :total="pcbaTotal"
            :current-page="pcbaSearchForm.page"
            @current-change="pcbaHandleCurrentChange"
          />
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {
  GetTemplatePurchases,
  getRegions,
  getApplicationList,
  loadCCLSizes,
  EBomsLoad,
  GetOrgs,
} from "../../assets/js/requestApi.js";
import commonUtil from "../../assets/js/commonUtil.js";
export default {
  props: ["CommodityId", "operateType", "CurrencyOptions"],
  emits: ["getChoosePurId", "getChooseTcm", "getChooseccl", "getChoosePcba"],
  data() {
    return {
      visible: false,
      cellSearchForm: {
        date: "",
        Enable: 1,
        StartDate: "",
        EndDate: "",
        status: "",
        Keyword: "",
        CommodityId: "",
        currencyId: "",
        VersionType: 1,
        RegionId: "",
        CommodityId: "",
        page: 1,
        limit: 10,
      },
      tcmSearchForm: {
        CommodityId: "",
        currencyId: "",
        date: "",
        StartDate: "",
        EndDate: "",
        status: "6",
        Keyword: "",
        priceType: 0,
        PageType: 3,
        isDraft: 0,
        page: 1,
        limit: 10,
        key: "",
        versionType: 1,
        isDisable: 0,
      },
      cclSearchForm: {
        page: 1, //当前页
        limit: 10, //每页个数
      },
      pcbaSearchForm: {
        description: "",
        page: 1,
        limit: 10,
        key: "",
      },
      CommodityOptions: [],
      defaultProps: {
        children: "children",
        label: "Name",
      },
      regionOptions: [],
      // 总页数
      cellTotal: 0,
      tcmTotal: 0,
      cclTotal: 0,
      pcbaTotal: 0,
      // 表格数据
      cellTableData: [],
      tcmTableData: [],
      cclTableData: [],
      pcbaTableData: [],
      // 已勾选数据
      cellChooseData: [],
      tcmChooseData: [],
      cclChooseData: [],
      pcbaChooseData: [],
      activeName: "second",
    };
  },
  mounted() {
    this.getRegion();
    this.getCommdity();
  },
  methods: {
    close() {
      this.visible = false;
      this.chooseData = [];
    },
    toConfirm() {
      if (this.chooseData.length == 0) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.pleaseChooseCell"),
          "",
          "warning"
        );
        return;
      }
      if (this.operateType == "choosePrice" && this.chooseData.length > 1) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.onlyOneToChoose"),
          "",
          "warning"
        );
        return;
      }
      let ids = [];
      this.chooseData.forEach(function (val, index) {
        ids.push(val.id);
      });
      this.$emit("getChoosePurId", ids);
      // this.$emit('getChoosePurId', this.chooseData.id)
      this.chooseData = [];
    },
    select(id, type) {
      if (type == "tcm") {
        this.$emit("getChooseTcm", [id]);
      } else if (type == "ccl") {
        this.$emit("getChooseccl", id);
      } else if (type == "pcba") {
        this.$emit("getChoosePcba", id);
      }
    },
    // 获取表格数据
    getcellList() {
      this.cellSearchForm = {
        date: "",
        Enable: 1,
        StartDate: "",
        EndDate: "",
        status: "",
        Keyword: "",
        CommodityId: "", //this.CommodityId 去掉了这个初始值，默认显示全部吧
        currencyId: "",
        VersionType: 1,
        RegionId: "",
        page: 1,
        limit: 10,
      };
      this.searchCell();
    },
    getTcmList() {
      this.tcmSearchForm = {
        CommodityId: "", //this.CommodityId 去掉了这个初始值，默认显示全部吧
        currencyId: "",
        date: "",
        StartDate: "",
        EndDate: "",
        status: "6",
        Keyword: "",
        priceType: 0,
        PageType: 3,
        isDraft: 0,
        page: 1,
        limit: 10,
        key: "",
        versionType: 1,
        isDisable: 0,
      };
      this.searchTcm();
    },
    getCclList() {
      this.cclSearchForm = {
        page: 1, //当前页
        limit: 10, //每页个数
      };
      this.searchCcl();
    },
    getPcbaList() {
      this.pcbaSearchForm = {
        description: "",
        page: 1,
        limit: 10,
        key: "",
      };
      this.searchPcba();
    },
    // 查询
    searchCell() {
      GetTemplatePurchases(this.cellSearchForm, (response) => {
        if (response && response.code == 200) {
          this.cellTableData = response.data;
          this.cellTotal = response.count;
        } else {
          commonUtil.showTipInfo(this.$t("userlists.userlist.inqueryTimeout"));
        }
      });
    },
    searchTcm() {
      getApplicationList(this.tcmSearchForm, (response) => {
        if (response && response.code == 200) {
          this.tcmTableData = response.data;
          this.tcmTotal = response.count;
        }
      });
    },
    searchCcl() {
      loadCCLSizes(this.cclSearchForm, (response) => {
        if (response && response.code == 200) {
          this.cclTableData = response.data;
          this.cclTotal = response.count;
        } else {
          commonUtil.showTipInfo(this.$t("userlists.userlist.inqueryTimeout"));
        }
      });
    },
    searchPcba() {
      EBomsLoad(this.pcbaSearchForm, (response) => {
        if (response && response.code == 200) {
          this.pcbaTableData = response.data;
          this.pcbaTotal = response.count;
        }
      });
    },
    // 获取商品分类数据
    getCommdity() {
      let _this = this;
      GetOrgs(
        {
          type: 0,
          versionType: 0,
          TypeCost: 0,
          isAll: 1,
        },
        (response) => {
          if (response && response.Code == 200) {
            _this.CommodityOptions = commonUtil.transferList(response.Result);
          }
        }
      );
    },
    // 分页
    handleCurrentChange(val) {
      this.searchForm.page = val;
      this.search();
    },
    cellHandleCurrentChange(val) {
      this.cellSearchForm.page = val;
      this.searchCell();
    },
    tcmHandleCurrentChange(val) {
      this.tcmSearchForm.page = val;
      this.searchTcm();
    },
    cclHandleCurrentChange(val) {
      this.cclSearchForm.page = val;
      this.searchCcl();
    },
    pcbaHandleCurrentChange(val) {
      this.pcbaSearchForm.page = val;
      this.searchPcba();
    },
    // 表格单选选
    handleSelectionChange(val) {
      // this.chooseData = val[0]
      // if (val.length > 1) {
      // 	this.$refs.rawTable.clearSelection();
      // 	this.$refs.rawTable.toggleRowSelection(val.pop());
      // }
      this.chooseData = val;
    },
    // 表格全选
    selectAll() {
      this.$refs.rawTable.clearSelection();
      this.chooseData = [];
    },
    // 获取区域列表选项
    getRegion() {
      getRegions(
        {
          Keyword: "",
          isEnable: 1,
          page: 1,
          limit: 9999,
          key: "",
        },
        (response) => {
          if (response && response.code == 200) {
            this.regionOptions = response.data;
          }
        }
      );
    },
    // 切换tab页
    handleClick(tab, event) {
      // this.$refs.priceDialog.cellSearchForm.page = 1;
      // this.$refs.priceDialog.getcellList();
      // this.$refs.priceDialog.tcmSearchForm.page = 1;
      // this.$refs.priceDialog.getTcmList();
      // this.$refs.priceDialog.cclSearchForm.page = 1;
      // this.$refs.priceDialog.getCclList();
    },
  },
};
</script>

<style scoped>
:deep(.el-dialog__header) {
  margin-right: 0;
}
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.size-16 {
  font-size: 16px;
}
.pagination {
  width: 100%;
}
/* .page-cla {
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: flex-end;
} */
.align-right {
  text-align: right;
}
.tcm-table {
  /* height: calc(100% - 90px); */
  padding: 0 20px;
  box-sizing: border-box;
}
.tree-cla {
  padding: 10px;
  box-sizing: border-box;
  height: 100%;
  border: 1px solid #ebeef5;
}
.el-row {
  height: 100%;
}
.flex-around {
  display: flex;
  justify-content: space-around;
}
</style>