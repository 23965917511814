<template>
  <el-dialog
    v-model="visible"
    width="80%"
    top="10vh"
    destroy-on-close
    :show-close="false"
  >
    <template #header="{ close, titleId, titleClass }">
      <div class="my-header">
        <div class="size-16">
          {{
            type == "detail" ? $t("ccls.ccl.detail") : $t("ccls.ccl.chooseCal")
          }}
        </div>
        <div @click="close">
          <el-icon :size="20"><Close /></el-icon>
        </div>
      </div>
    </template>
    <div class="tcm-table">
      <el-row>
        <el-tabs v-model="activeName" type="card" v-show="type != 'detail'">
          <el-tab-pane
            :label="$t('ccls.ccl.chooseCalculator')"
            name="first"
          ></el-tab-pane>
          <el-tab-pane
            :label="$t('ccls.ccl.plastic')"
            name="second"
          ></el-tab-pane>
          <el-tab-pane
            :label="$t('ccls.ccl.Stamping')"
            name="third"
          ></el-tab-pane>
          <!-- <el-tab-pane :label="$t('unifiedPrompts.unifiedPrompt.selectccl')" name="third"></el-tab-pane> -->
        </el-tabs>
      </el-row>
      <div class="max-cla" v-show="activeName == 'first'">
        <el-row :gutter="10">
          <el-col :span="4">
            <div class="tree-cla">
              <el-input
                v-model="filterText"
                :placeholder="$t('unifiedPrompts.unifiedPrompt.keyword')"
                style="margin-bottom: 10px"
              />
              <el-tree
                ref="treeRef"
                class="filter-tree"
                :data="treedata"
                :props="defaultProps"
                default-expand-all
                :filter-node-method="filterNode"
                :highlight-current="true"
                @node-click="handleNodeClick"
              />
            </div>
          </el-col>
          <el-col :span="20">
            <div class="regional_table">
              <el-table
                stripe
                :data="tableData"
                height="400"
                border
                style="width: 100%"
                highlight-current-row
                @row-click="rowClick"
                :empty-text="$t('tcmlists.tcmlist.noData')"
              >
                <el-table-column
                  prop="process_Calculator_Code"
                  :label="$t('calculators.calculator.calculatorCode')"
                  align="center"
                />
                <el-table-column
                  prop="process_Calculator_Name"
                  :label="$t('calculators.calculator.calculatorName')"
                  align="center"
                />
                <el-table-column
                  :label="$t('calculators.calculator.version')"
                  prop="version"
                  align="center"
                />
                <el-table-column
                  prop="comments"
                  :label="$t('calculators.calculator.comments')"
                  align="center"
                />
                <el-table-column
                  prop="enable"
                  :label="$t('ccls.ccl.enable')"
                  align="center"
                >
                  <template #default="scope">
                    <el-icon
                      :size="16"
                      color="#40D4A8"
                      v-show="scope.row.enable == '1'"
                    >
                      <SuccessFilled />
                    </el-icon>
                    <el-icon
                      :size="16"
                      color="#F56C6C"
                      v-show="scope.row.enable == '2'"
                    >
                      <CircleCloseFilled />
                    </el-icon>
                  </template>
                </el-table-column>
              </el-table>
            </div>

            <div class="pagination">
              <!-- <p>{{ total }} data</p> -->
              <el-pagination
                background
                layout="prev, pager, next"
                :total="total"
                :current-page="searchForm.page"
                @current-change="handleCurrentChange"
              />
            </div>
          </el-col>
        </el-row>
      </div>

      <div class="max-cla" v-show="activeName == 'second'">
        <el-row v-show="type != 'detail'">
          <el-radio v-model="activeRadio" label="tonnage">{{
            $t("other.other.Tonnage")
          }}</el-radio>
          <el-radio v-model="activeRadio" label="partWeight">
            {{ $t("other.other.PartWeight") }}
          </el-radio>
          <el-radio v-model="activeRadio" label="cycleTime">
            {{ $t("other.other.CycleTime") }}
          </el-radio>
        </el-row>
        <div>
          <Tonnage
            ref="tonnageCla"
            :type="type"
            v-show="activeRadio == 'tonnage'"
          ></Tonnage>
          <PartWeight
            ref="partWeightCla"
            :type="type"
            v-show="activeRadio == 'partWeight'"
          ></PartWeight>
          <CycleTime
            ref="cycleTimeCla"
            :type="type"
            v-show="activeRadio == 'cycleTime'"
          ></CycleTime>
        </div>
      </div>
      <div class="max-cla" v-show="activeName == 'third'">
        <el-row v-show="type != 'detail'">
          <el-radio v-model="activeRadioStamping" label="bracket">
            {{ $t("other.other.BracketWeight") }}
          </el-radio>
          <el-radio v-model="activeRadioStamping" label="tonnage">{{
            $t("homePage.home.TonnageCalculator")
          }}</el-radio>
        </el-row>
        <div>
          <BracketWeight
            ref="bracketCla"
            :type="type"
            v-show="activeRadioStamping == 'bracket'"
          ></BracketWeight>
          <!-- <AllTonnageStamping ref="tonnageStamping" v-show="activeRadioStamping == 'tonnage'" @chooseTonnageStampingRow="chooseTonnageStampingRow"></AllTonnageStamping> -->
          <TonnageCalculatorCompute
            ref="TonnageCalculatorCompute"
            :status="type"
            :applyId="applyId"
            v-show="activeRadioStamping == 'tonnage'"
          ></TonnageCalculatorCompute>
        </div>
      </div>
    </div>
    <template #footer>
      <span
        class="dialog-footer"
        v-show="
          (activeName == 'second' || activeName == 'third') && type != 'detail'
        "
      >
        <el-button
          style="
            background-color: var(--themeColor);
            color: var(--menuTextActive);
            margin-right: 16px;
          "
          @click="submit"
          >{{ $t("ccls.ccl.confirm") }}</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {
  getCalculatorList,
  GetOrgs,
  CycleTimeAddHistory,
  StampingAddHistory,
} from "../../assets/js/requestApi.js";
import Tonnage from "../plasticManagement/tonnage.vue";
import PartWeight from "../plasticManagement/partWeight.vue";
import CycleTime from "../plasticManagement/cycleTime.vue";
import BracketWeight from "../bracketWeight/bracketWeight.vue";
// import AllTonnageStamping from '../TonnageCalculator/allTonnageDia.vue'
import TonnageCalculatorCompute from "../TonnageCalculator/TonnageCalculatorCompute.vue";
export default {
  props: ["applyId", "type"],
  emits: ["chooseRow", "submitPlastic"],
  components: {
    Tonnage,
    PartWeight,
    CycleTime,
    BracketWeight,
    // AllTonnageStamping,
    TonnageCalculatorCompute,
  },
  data() {
    return {
      visible: false,
      // 已选商品类别
      CommodityName: "",
      filterText: "",
      treedata: [],
      defaultProps: {
        children: "children",
        label: "Name",
      },
      // 表格数据
      tableData: [],
      total: 0,
      activeName: "first",
      activeRadio: "tonnage",
      activeRadioStamping: "bracket",
      operateName: "",
    };
  },
  watch: {
    filterText(val) {
      this.$refs.treeRef.filter(val);
    },
  },
  methods: {
    close() {
      this.visible = false;
    },
    getlist(type) {
      let _this = this;
      this.searchForm = {
        CommodityId: "",
        Enable: 1,
        Keyword: "",
        page: 1,
        limit: 10,
        key: "",
      };
      this.search();
      this.$nextTick(function () {
        if (type != 1) {
          _this.$refs.cycleTimeCla.getInit();
          _this.$refs.TonnageCalculatorCompute.initList("");
        }
      });
    },
    // 查询
    search() {
      getCalculatorList(this.searchForm, (response) => {
        if (response && response.code == 200) {
          this.tableData = response.data;
          this.total = response.count;
        }
      });
    },
    // 分页
    handleCurrentChange(val) {
      this.searchForm.page = val;
      this.search();
    },
    // 获取组织树数据
    getTreeData() {
      let _this = this;
      GetOrgs(
        {
          type: 0,
          versionType: 0,
          TypeCost: 0,
          isAll: 0,
        },
        (response) => {
          if (response && response.Code == 200) {
            this.treedata = _this.transferList(response.Result);
          }
        }
      );
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    // 树点击事件
    handleNodeClick(data) {
      this.searchForm.CommodityId = data.Id;
      this.CommodityName = data.Name;
      this.search();
    },
    // 转化树数据
    transferList(data) {
      const res = [];
      data.forEach((item) => {
        const parent = data.find((node) => node.Id === item.ParentId);
        if (parent) {
          parent.children = parent.children || [];
          parent.children.push(item);
        } else {
          // * 根节点
          res.push(item);
        }
      });
      return res;
    },
    // 回显历史数据
    setData(form) {
      let _this = this;
      this.$nextTick(function () {
        if (form.activeName == "second") {
          _this.activeName = form.activeName;
          _this.activeRadio = form.activeRadio;
          if (form.activeRadio === "tonnage") {
            _this.$refs.tonnageCla.form = form;
          }
          if (form.activeRadio === "partWeight") {
            _this.$refs.partWeightCla.form = form;
          }
          if (form.activeRadio === "cycleTime") {
            _this.$refs.cycleTimeCla.form = form;
            _this.$refs.cycleTimeCla.getHistoryList();
          }
          if (form.activeRadio != "cycleTime") {
            _this.$refs.cycleTimeCla.getInit();
          }
        }
        if (form.activeName == "third") {
          _this.activeName = form.activeName;
          _this.activeRadioStamping = form.activeRadio;
          if (form.activeRadio == "bracket") {
            _this.$refs.bracketCla.form = form;
          }
          if (form.activeRadio == "tonnage") {
            _this.$refs.TonnageCalculatorCompute.type = form.type;
            _this.$refs.TonnageCalculatorCompute.calculatedTonnage =
              form.calculatedTonnage;
            _this.$refs.TonnageCalculatorCompute.total_CalculatedTonnage =
              form.total_CalculatedTonnage;
            _this.$refs.TonnageCalculatorCompute.initList(form.id);
          }
          if (form.activeRadio != "tonnage") {
            _this.$refs.TonnageCalculatorCompute.initList("");
          }
        }
      });
    },
    // 选择某一行
    rowClick(row) {
      this.$emit("chooseRow", row);
    },
    // 获取stamping选择的行数据
    chooseTonnageStampingRow(row) {
      this.$emit("submitPlastic", row.recommendedTonnage);
    },
    submit() {
      let _this = this;
      if (this.activeName == "second") {
        if (this.activeRadio === "tonnage") {
          let tonnageForm = JSON.parse(
            JSON.stringify(this.$refs.tonnageCla.form)
          );
          tonnageForm.operateName = this.operateName;
          tonnageForm.activeName = this.activeName;
          tonnageForm.activeRadio = this.activeRadio;
          this.$emit(
            "submitPlastic",
            this.$refs.tonnageCla.form.machine,
            tonnageForm
          );
        }
        if (this.activeRadio === "partWeight") {
          let partWeightForm = JSON.parse(
            JSON.stringify(this.$refs.partWeightCla.form)
          );
          partWeightForm.operateName = this.operateName;
          partWeightForm.activeName = this.activeName;
          partWeightForm.activeRadio = this.activeRadio;
          this.$emit(
            "submitPlastic",
            this.$refs.partWeightCla.form.grossWeight,
            partWeightForm
          );
        }
        if (this.activeRadio === "cycleTime") {
          CycleTimeAddHistory(
            {
              mainId: _this.$refs.cycleTimeCla.form.id
                ? _this.$refs.cycleTimeCla.form.id
                : "",
            },
            (response) => {
              if (response && response.code == 200) {
                let cycleTimeForm = JSON.parse(
                  JSON.stringify(_this.$refs.cycleTimeCla.form)
                );
                cycleTimeForm.operateName = _this.operateName;
                cycleTimeForm.activeName = _this.activeName;
                cycleTimeForm.activeRadio = _this.activeRadio;
                cycleTimeForm.id = response.result;
                _this.$emit(
                  "submitPlastic",
                  _this.$refs.cycleTimeCla.form.total,
                  cycleTimeForm
                );
              }
            }
          );
        }
      }
      if (this.activeName == "third") {
        if (this.activeRadioStamping == "bracket") {
          let bracketForm = JSON.parse(
            JSON.stringify(this.$refs.bracketCla.form)
          );
          bracketForm.operateName = this.operateName;
          bracketForm.activeName = this.activeName;
          bracketForm.activeRadio = this.activeRadioStamping;
          this.$emit(
            "submitPlastic",
            this.$refs.bracketCla.form.yeild,
            bracketForm
          );
        }
        if (this.activeRadioStamping == "tonnage") {
          StampingAddHistory(
            {
              mainId: this.$refs.TonnageCalculatorCompute.id
                ? this.$refs.TonnageCalculatorCompute.id
                : "",
            },
            (response) => {
              if (response && response.code == 200) {
                let stampingTonnageForm = {
                  activeName: this.activeName,
                  activeRadio: this.activeRadioStamping,
                  operateName: this.operateName,
                  id: response.result,
                  type: this.$refs.TonnageCalculatorCompute.type,
                  calculatedTonnage:
                    this.$refs.TonnageCalculatorCompute.calculatedTonnage,
                  total_CalculatedTonnage:
                    this.$refs.TonnageCalculatorCompute.total_CalculatedTonnage,
                };
                this.$emit(
                  "submitPlastic",
                  this.$refs.TonnageCalculatorCompute.calculatedTonnage,
                  stampingTonnageForm
                );
              }
            }
          );
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
:deep(.el-dialog__header) {
  margin-right: 0;
}
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.size-16 {
  font-size: 16px;
}
.page-cla {
  padding: 20px;
  display: flex;
  justify-content: flex-end;
}
.align-right {
  text-align: right;
}
.tcm-table {
  height: calc(100% - 90px);
  padding: 0 20px;
  box-sizing: border-box;
}
.tree-cla {
  padding: 10px;
  box-sizing: border-box;
  height: 100%;
  border: 1px solid #ebeef5;
}
.el-row {
  height: 100%;
}
.flex-around {
  display: flex;
  justify-content: space-around;
}
.max-cla {
  max-height: 550px;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>