<template>
  <div class="main-box">
    <div class="main-top">
      <div class="main-top-left">
        <img class="logo-box" src="../../assets/img/logos.png" />
        <div class="Parting-line"></div>
        <div class="logo-title">A leader in intuitive motion control</div>
      </div>
      <div class="main-top-right">
        <span
          :class="LanguageFlag == 'zh' ? 'active' : ''"
          @click="changeLanguage('zh')"
          >中文</span
        >
        /
        <span
          :class="LanguageFlag == 'en' ? 'active' : ''"
          @click="changeLanguage('en')"
          >English</span
        >
      </div>
    </div>
    <div class="main-container">
      <div class="main-container-Mask">
        <div class="login-box">
          <!-- <!== 内部人员登录 ==> -->
          <div class="login-box-form">
            <div class="login-box-title">{{ $t("logins.login.login") }}</div>
            <p>
              {{ $t("logins.login.AccessApp") }}
            </p>
            <div class="input-item">
              <div class="img-box">
                <img src="../../assets/img/user.png" alt="" />
              </div>
              <input
                v-model.trim="form.username"
                type="text"
                :placeholder="$t('logins.login.name')"
              />
            </div>
            <div class="input-item">
              <div class="img-box">
                <img
                  style="width: 35px; height: 35px; margin-left: -2px"
                  src="../../assets/img/pwd.png"
                  alt=""
                />
              </div>
              <input
                v-model.trim="form.password"
                type="password"
                :placeholder="$t('logins.login.password')"
              />
            </div>
            <div style="text-align: center">
              <div class="Forgotpwd" @click="ForgotBtn">
                <span>{{ $t("logins.login.Forgot") }}</span>
                <div>{{ $t("logins.login.password") }}</div>
              </div>
            </div>
          </div>
          <div class="login-btn" @click="handleLogin">
            {{ $t("logins.login.login") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  login,
  ForgetPassword,
  JudgeAccountIsSupplier,
  ChangeLoginPassword,
  GetUserSettingMenu,
} from "../../assets/js/requestApi.js";
import commonUtil from "../../assets/js/commonUtil.js";
import "../../assets/css/custom-el-input-login.css";
import { useI18n } from "vue-i18n";
import { onMounted, getCurrentInstance, ref } from "vue";
export default {
  name: "Login",
  directives: {
    focus: {
      inserted(el) {
        el.querySelector("input").focus();
      },
    },
  },
  setup() {
    // 切换中英文翻译
    const { proxy } = getCurrentInstance();
    const LanguageFlag = ref();
    onMounted(() => {
      proxy.value = localStorage.getItem("translate")
        ? JSON.parse(localStorage.getItem("translate"))
        : "en";
      proxy.$i18n.locale = proxy.value;
      LanguageFlag.value = proxy.value;
    });
    function changeLanguage(type) {
      proxy.$i18n.locale = type;
      LanguageFlag.value = type;
      localStorage.setItem("translate", JSON.stringify(type));
    }
    const { t } = useI18n();
    return { changeLanguage, LanguageFlag };
  },
  data() {
    return {
      nodeEnv: process.env.NODE_ENV,
      title: "NEXTEER TCM DIGITAL SYSTEM",
      // 内部人员登录
      form: {
        // id:'',
        username: "",
        password: "",
      },
      rules: {
        username: [{ required: true, trigger: "blur" }],
        password: [{ required: true, trigger: "blur" }],
      },
      // 供应商登录
      formSupplier: {
        username: "",
        password: "",
      },
      rulesSupplier: {
        username: [{ required: true, trigger: "blur" }],
        password: [{ required: true, trigger: "blur" }],
      },
      loading: false,
      passwordType: "password",
      redirect: undefined,
      // 是否为二期登录
      isSupplier: false,
      // elementsList: [],
    };
  },
  watch: {
    $route: {
      handler(route) {
        this.redirect = (route.query && route.query.redirect) || "/";
      },
      immediate: true,
    },
  },
  created() {
    document.body.style.overflow = "hidden";
    this.$i18n.locale = localStorage.getItem("translate")
      ? JSON.parse(localStorage.getItem("translate"))
      : "en";
    if (JSON.parse(localStorage.getItem("translate")) == "zh") {
      this.rules = {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      };
    } else {
      this.rules = {
        username: [
          { required: true, message: "Username is required", trigger: "blur" },
        ],
        password: [
          { required: true, message: "Password is required", trigger: "blur" },
        ],
      };
    }

    // if (this.$route.query.id) {
    //   login(
    //     {
    //       account: "MailAccout",
    //       password: "MailAccout",
    //     },
    //     (response) => {
    //       if (response && response.code == 200) {
    //         sessionStorage.setItem("token", response.token);
    //         this.$router.push({
    //           path: "/applicationDetail",
    //           name: "ApplicationDetail",
    //           query: {
    //             type: "detail",
    //             id: this.$route.query.id,
    //           },
    //         });
    //       }
    //     }
    //   );
    // }
    // 判断是否为二期
    if (this.$route.query.phaseII) {
      sessionStorage.setItem("phaseII", 2);
    } else {
      sessionStorage.setItem("phaseII", 1);
    }
  },
  beforeDestroy() {
    document.body.style.overflow = "auto";
  },
  mounted() {},

  methods: {
    // 内部登录
    handleLogin() {
      if (this.form.username == "") {
        commonUtil.showTipInfo(
          this.$t("logins.login.nameverification"),
          "",
          "warning"
        );
      } else if (this.form.password == "") {
        commonUtil.showTipInfo(
          this.$t("logins.login.passwordverification"),
          "",
          "warning"
        );
      } else {
        this.nextLogin();
      }
    },
    nextLogin() {
      login(
        {
          account: this.form.username,
          password: this.form.password,
        },
        (response) => {
          if (response && response.code == 200) {
            sessionStorage.setItem("token", response.token);
            // 获取用户的Commodity
            let list = [];
            if (response.commoditys && response.commoditys.length) {
              response.commoditys.forEach((item, index) => {
                list.push({
                  Id: item.id,
                  Name: item.name,
                  ParentId: item.parentId,
                  ParentName: item.parentName,
                });
              });
              list = commonUtil.transferList(list);
            }
            sessionStorage.setItem("Commodity", JSON.stringify(list));
            // 获取用户的Region
            let regionsList = [];
            if (response.regions && response.regions.length) {
              response.regions.forEach((item, index) => {
                regionsList.push({
                  id: item.id,
                  name: item.name,
                });
              });
            }

            sessionStorage.setItem("Region", JSON.stringify(regionsList));
            // 获取权限列表
            // this.GetManagementSetting();

            commonUtil.showTipInfo(
              this.$t("logins.login.loginsuccess"),
              "",
              "success"
            );
            this.$router.push("/homePage");
          }
        },
        (errorResponse) => {
          commonUtil.showTipInfo(
            this.$t("logins.login.loginfailed"),
            "",
            "error"
          );
        }
      );
    },
    // 忘记密码
    forgetPwd() {
      if (this.formSupplier.username == "") {
        commonUtil.showTipInfo(
          this.$t("logins.login.nameverification"),
          "",
          "warning"
        );
        return;
      }
      if (this.formSupplier.username == "System") {
        commonUtil.showTipInfo(
          this.$t("logins.login.nopremission"),
          "",
          "error"
        );
      } else {
        JudgeAccountIsSupplier(
          {
            account: this.formSupplier.username,
          },
          (response) => {
            if (response && response.code == 200) {
              if (response.result === 1) {
                ForgetPassword(
                  {
                    account: this.formSupplier.username,
                  },
                  (response) => {
                    if (response && response.code == 200) {
                      commonUtil.showTipInfo(
                        this.$t("logins.login.forgetPwdSuccess"),
                        "",
                        "success"
                      );
                    } else {
                      commonUtil.showTipInfo(
                        this.$t("logins.login.forgetPwdFailed"),
                        "",
                        "error"
                      );
                    }
                  }
                );
              } else {
                commonUtil.showTipInfo(
                  this.$t("logins.login.forgetPwdFailed"),
                  "",
                  "error"
                );
              }
            }
          }
        );
      }
    },
    ForgotBtn() {
      if (!this.form.username) {
        commonUtil.showTipInfo(
          this.$t("logins.login.nameverification"),
          "",
          "warning"
        );
      }
      ChangeLoginPassword(
        {
          account: this.form.username,
        },
        (response) => {
          if (response && response.code == 200) {
            commonUtil.showTipInfo(
              this.$t("logins.login.changepasswordMsg"),
              "",
              "success"
            );
          }
        }
      );
    },
    // GetManagementSetting() {
    //   GetUserSettingMenu({}, (response) => {
    //     if (response && response.code == 200) {
    //       console.log(response.result);
    //       let menus = response.result.menus;
    //       menus.forEach((item, index) => {
    //         item.subPages = item.pages;
    //       });
    //       this.GetOnshowCheck(menus);
    //       console.log(this.elementsList, "this.elementsList");
    //     }
    //   });
    // },
    // GetOnshowCheck(data) {
    //   if (data) {
    //     data.forEach((item) => {
    //       if (item.elements && !item.subPages) {
    //         let elements = item.elements.filter((itm, idx) => {
    //           itm.isCheck == true;
    //         });
    //         let obj = {
    //           name: item.name,
    //           id: item.id,
    //           elements: elements,
    //         };
    //         this.elementsList.push(obj);
    //         this.GetOnshowCheck(item.subPages);
    //       } else {
    //         this.GetOnshowCheck(item.subPages);
    //       }
    //     });
    //   }
    // },
  },
};
</script>


<style lang="scss" scoped>
.main-box {
  width: 100%;
  height: 100%;
}
.main-top {
  padding: 0 40px 0;
  display: flex;
  justify-content: space-between;
  width: 100wh;
  height: 100px;
  background: #fff;
}
.main-top-left {
  display: flex;
  align-items: center;
}
.logo-box {
  width: 200px;
  height: 60px;
}
.Parting-line {
  margin: 0 20px 0 28px;
  width: 3px;
  height: 60px;
  background: #8e1818;
}
.logo-title {
  font-weight: 700;
  font-size: 27px;
  color: #cc2121;
  white-space: nowrap;
}
.main-top-right {
  display: flex;
  align-items: center;
  color: #969ba0;
  font-weight: 500;
  white-space: nowrap;
  cursor: pointer;
  span {
    margin: 0 10px;
    font-size: 27px;
  }
  .active {
    color: #002257;
  }
}
.main-container {
  width: 100%;
  height: calc(100% - 100px);
  background: url("../../assets/img/bg.png") no-repeat;
  background-size: 100% 100%;
}
.main-container-Mask {
  position: relative;
  width: 100%;
  height: calc(100% - 100px);
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
}
.login-box {
  position: absolute;
  bottom: 20%;
  right: 10%;
  display: flex;
  flex-direction: column;
  width: 400px;
  min-height: 400px;
  background-color: rgba(255, 255, 255, 0.7) !important;
  border-radius: 20px;
  overflow: hidden;
  /* transform: translateX(-50%); */
}
.login-box-form {
  flex: 1;
  padding: 40px;
}
input {
  padding-left: 12px;
  flex: 1;
  height: 40px;
  font-size: 20px;
  color: #001940;
}
input::placeholder {
  font-size: 18px;
  color: #5a5e62;
}
.login-box-title {
  font-size: 35px;
  font-weight: 600;
  text-align: center;
  color: #002257;
}
.login-box p {
  color: #5a5e62;
  text-align: center;
  font-size: 13px;
  padding-top: 10px;
}
.login-btn {
  width: 100%;
  height: 60px;
  line-height: 60px;
  text-align: center;
  color: #fff;
  background: #6480ae;
  font-size: 16px;
  cursor: pointer;
}
.input-item {
  display: flex;
  margin-top: 20px;
  .img-box {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
  }
  img {
    margin-right: 10px;
    width: 30px;
    height: 30px;
  }
}
.Forgotpwd {
  display: inline-block;
  padding-top: 20px;
  color: #008bff;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
}
</style>
