<template>
  <div class="main">
    <div class="crumbs-box">
      <div class="crumbs-box-item parent" @click="Goback">
        {{ $t("homePage.home.Back") }}
      </div>
      <div class="line"></div>
      <div class="crumbs-box-item" v-for="(item, index) in crumbs">
        {{ item }}
        <el-icon v-show="index != crumbs.length - 1"><ArrowRight /></el-icon>
      </div>
    </div>

    <div class="main-top_handle">
      <div class="title">
        <!-- Process Calculator - Process Stamping - Tonnage Calculator -Compute
        Detail -->
      </div>
      <div class="main-handle flex">
        <div class="search-btn" @click="addGrade" v-if="this.btnAdd > 0">
          {{ $t("unifiedPrompts.unifiedPrompt.new") }}
        </div>
        <div class="main-handle-item" style="margin-left: 10px">
          <el-select
            clearable
            filterable
            v-model="type"
            placeholder=""
            @change="changeType"
            style="width: 214px"
          >
            <el-option
              v-for="item in typeOptions"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </div>
      </div>
    </div>

    <!-- 
    <div class="handle-btn flex">
      <div class="handle-btn-item" style="margin-right: 10px" @click="addData">
        {{ $t("pcbas.pcba.new") }}
      </div>
      <div class="handle-btn-item" style="margin-right: 10px" @click="editData">
        {{ $t("pcbas.pcba.edit") }}
      </div>
      <div
        class="handle-btn-item"
        style="margin-right: 10px"
        @click="deleteData"
      >
        {{ $t("pcbas.pcba.delete") }}
      </div>
    </div> -->
    <div class="regional_table" style="margin-top: 10px">
      <el-table
        border
        stripe
        :empty-text="$t('tcmlists.tcmlist.noData')"
        :data="tableData"
        :height="TableHeight"
        style="width: 100%"
        ref="roleTable"
      >
        <el-table-column
          prop="gradeFerrousName"
          :label="$t('homePage.home.Grade')"
          align="center"
        />
        <el-table-column
          prop="operation_DetailsName"
          :label="$t('homePage.home.OperationDetails')"
          align="center"
          show-overflow-tooltip
        />
        <el-table-column
          prop="section_Profile"
          :label="$t('homePage.home.SectionProfile')"
          align="center"
        />
        <el-table-column
          prop="length"
          :label="$t('homePage.home.Length')"
          align="center"
        />
        <el-table-column
          prop="width"
          :label="$t('homePage.home.Width')"
          align="center"
        />
        <el-table-column prop="circle_OD" label="OD" align="center" />
        <el-table-column prop="circle_ID" label="ID" align="center" />
        <el-table-column
          prop="thk"
          :label="$t('homePage.home.Thickness')"
          align="center"
        />
        <el-table-column
          prop="total_Length_Of_Cut_bend"
          :label="$t('homePage.home.TotalLength_Cut_bend_Form')"
          align="center"
        />
        <el-table-column
          prop="calculated"
          :label="$t('homePage.home.Calculated')"
          align="center"
        />
        <el-table-column
          prop="manual"
          :label="$t('homePage.home.Manual')"
          align="center"
        />
        <el-table-column
          prop="type_Of_Strength"
          :label="$t('homePage.home.TypeStrength')"
          align="center"
        />
        <el-table-column
          prop="stripping_Force"
          :label="$t('homePage.home.StrippingForce')"
          align="center"
        />
        <el-table-column prop="fos" label="FOS" align="center" />
        <el-table-column
          prop="calculated_Tonnage"
          :label="$t('homePage.home.CalculatedTonnage')"
          align="center"
        />

        <el-table-column
          width="80"
          :label="$t('templateLists.templateList.operate')"
          align="center"
          fixed="right"
          class-name="lastTd"
        >
          <template #default="scope">
            <el-popover placement="bottom" trigger="hover">
              <template #reference>
                <div style="text-align: center">
                  <el-icon><MoreFilled /></el-icon>
                </div>
              </template>
              <div class="action-box">
                <div class="action-btn" @click="edit(scope.row, scope.$index)">
                  <el-icon><Edit /></el-icon>
                  <span> {{ $t("unifiedPrompts.unifiedPrompt.edit") }}</span>
                </div>
              </div>
              <div class="action-box">
                <div class="action-btn" @click="del(scope.row)">
                  <el-icon><Delete /></el-icon>
                  <span> {{ $t("unifiedPrompts.unifiedPrompt.delete") }}</span>
                </div>
              </div>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <div>
        {{ $t("homePage.home.Showing") }} {{ pageSize }}
        {{ $t("homePage.home.From") }} {{ total }}
        {{ $t("homePage.home.Data") }}

        <el-popover placement="top" :width="70" trigger="click">
          <template #reference>
            <el-icon
              style="margin-left: 5px; font-size: 17px; vertical-align: middle"
              ><Operation
            /></el-icon>
          </template>
          <el-input
            v-model="searchForm.limit"
            @input="(val) => (searchForm.limit = val.replace(/[^\d]/g, ''))"
            :min="1"
            style="width: 130px"
            @change="handleSizeChange"
          >
            <template #suffix> {{ $t("ccls.ccl.barPage") }}</template>
          </el-input>
        </el-popover>
      </div>
      <el-pagination
        layout="prev, pager, next"
        background
        :total="total"
        :page-size="pageSize"
        :current-page="searchForm.page"
        @current-change="handleCurrentChange"
      />
    </div>
    <div class="total-text">
      <el-row :gutter="10">
        <el-col :span="3" class="align-right">
          {{ $t("homePage.home.TotalTonnage") }}：</el-col
        >
        <el-col :span="3">
          <el-input
            v-model="total_CalculatedTonnage"
            disabled
            placeholder=""
            style="width: 100%"
          />
        </el-col>
        <el-col :span="3" class="pl-10">T</el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="3" class="align-right">
          {{ $t("homePage.home.RecommendedTonnage") }}：</el-col
        >
        <el-col :span="3">
          <el-input
            v-model="calculatedTonnage"
            disabled
            placeholder=""
            style="width: 100%"
          />
        </el-col>
        <el-col :span="3" class="pl-10">T</el-col>
        <el-col :span="3">
          <div class="operate-btn mgr-10" @click="computed">
            {{ $t("homePage.home.Compute") }}
          </div>
        </el-col>
      </el-row>
    </div>
    <AddTonnageCalculator
      ref="addGrade"
      @handleSubmit="addSubmit"
    ></AddTonnageCalculator>
  </div>
  <!-- <div class="tcm-list">
    <div class="tcm-search">
      <el-row>
        <el-col :span="24">
          <div>
            <div
              class="operate-btn mgr-10"
              @click="addGrade"
              v-if="this.btnAdd > 0"
            >
              {{ $t("unifiedPrompts.unifiedPrompt.new") }}
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="padding-20">
      <el-select
        clearable
        filterable
        v-model="type"
        placeholder=""
        @change="changeType"
        style="width: 214px"
      >
        <el-option
          v-for="item in typeOptions"
          :key="item"
          :label="item"
          :value="item"
        />
      </el-select>
    </div>
    <div class="tcm-table">
      <el-table
        :empty-text="$t('tcmlists.tcmlist.noData')"
        ref="roleTable"
        :data="tableData"
        border
        style="width: 100%"
      >
        <el-table-column
          :label="$t('ccls.ccl.operate')"
          align="center"
          width="200"
        >
          <template #default="scope">
            <div class="flex-around">
              <div class="operate-btnx" @click="edit(scope.row, scope.$index)">
                {{ $t("unifiedPrompts.unifiedPrompt.edit") }}
              </div>
              <div class="operate-btnx" @click="del(scope.row)">
                {{ $t("unifiedPrompts.unifiedPrompt.delete") }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="gradeFerrousName" label="Grade" align="center" />
        <el-table-column
          prop="operation_DetailsName"
          label="Operation Details"
          align="center"
          show-overflow-tooltip
        />
        <el-table-column
          prop="section_Profile"
          label="Section Profile"
          align="center"
        />
        <el-table-column prop="length" label="Length" align="center" />
        <el-table-column prop="width" label="Width" align="center" />
        <el-table-column prop="circle_OD" label="OD" align="center" />
        <el-table-column prop="circle_ID" label="ID" align="center" />
        <el-table-column prop="thk" label="Thickness" align="center" />
        <el-table-column
          prop="total_Length_Of_Cut_bend"
          label="Total Length of Cut / bend / Form"
          align="center"
        />
        <el-table-column prop="calculated" label="Calculated" align="center" />
        <el-table-column prop="manual" label="Manual" align="center" />
        <el-table-column
          prop="type_Of_Strength"
          label="Type of Strength"
          align="center"
        />
        <el-table-column
          prop="stripping_Force"
          label="Stripping Force"
          align="center"
        />
        <el-table-column prop="fos" label="FOS" align="center" />
        <el-table-column
          prop="calculated_Tonnage"
          label="Calculated Tonnage"
          align="center"
        />
        <!== <el-table-column prop="total_CalculatedTonnage" label="Recommended Tonnage" align="center" /> ==>
      </el-table>
    </div>
    <div class="flex-between">
      <div class="total-text"></div>
      <div class="page-cla">
        <div>
          <el-input
            v-model="searchForm.limit"
            @input="(val) => (searchForm.limit = val.replace(/[^\d]/g, ''))"
            min="1"
            style="width: 130px"
            @change="handleSizeChange"
          >
            <template #suffix>
              {{ $t("ccls.ccl.barPage") }}
            </template>
          </el-input>
        </div>
        <el-pagination
          layout="prev, pager, next"
          :total="total"
          :page-size="pageSize"
          :current-page="searchForm.page"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <div class="total-text">
      <el-row :gutter="10">
        <el-col :span="3" class="align-right"> Total Tonnage：</el-col>
        <el-col :span="3">
          <el-input
            v-model="total_CalculatedTonnage"
            disabled
            placeholder=""
            style="width: 100%"
          />
        </el-col>
        <el-col :span="3" class="pl-10">T</el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="3" class="align-right"> Recommended Tonnage：</el-col>
        <el-col :span="3">
          <el-input
            v-model="calculatedTonnage"
            disabled
            placeholder=""
            style="width: 100%"
          />
        </el-col>
        <el-col :span="3" class="pl-10">T</el-col>
        <el-col :span="3">
          <div class="operate-btn mgr-10" @click="computed">Compute</div>
        </el-col>
      </el-row>
    </div>
    <AddTonnageCalculator
      ref="addGrade"
      @handleSubmit="addSubmit"
    ></AddTonnageCalculator>
    <!== <EditRole ref="editRloe" v-on:handleSubmit="editSubmit"></EditRole> ==>
  </div> -->
</template>

<script>
import AddTonnageCalculator from "./addTonnageCalculator.vue";
import commonUtil from "../../assets/js/commonUtil.js";
import {
  LoadStamping,
  GetTonnageCost,
  GetRecommendedTonnageCost,
  AddOrUpdateStamping,
  DeleteStamping,
  GetComputeMainResultRes,
  UpdateTypeStamping,
  GetStampingDetail,
} from "@/assets/js/requestApi.js";
export default {
  components: {
    AddTonnageCalculator,
  },
  data() {
    return {
      crumbs: [
        this.$t("homePage.home.ProcessCalculator"),
        this.$t("homePage.home.ProcessStamping"),
        this.$t("homePage.home.TonnageCalculator"),
        this.$t("ccls.ccl.detail"),
      ],
      TableHeight: 0,
      id: "",
      searchForm: {
        mainId: "",
        keyword: "",
        key: "",
        page: 1, //当前页
        limit: 10, //每页个数
      },
      tableData: [],
      total: 0,
      pageSize: 10,
      // 已勾选的数据
      chooseData: [],
      // 类型选择项
      type: "Max",
      typeOptions: ["Max", "Avg", "Min"],
      power: [],
      btnAdd: "",
      btnDel: "",
      btnEdit: "",
      timer: "",
      // 总值
      total_CalculatedTonnage: 0,
      // 吨数值
      calculatedTonnage: 0,
      // 数据操作状态
      operateType: "",
      // 数据操作下标
      operateIndex: "",
    };
  },
  watch: {
    "$i18n.locale"() {
      this.crumbs = [
        this.$t("homePage.home.ProcessCalculator"),
        this.$t("homePage.home.ProcessStamping"),
        this.$t("homePage.home.TonnageCalculator"),
        this.$t("ccls.ccl.detail"),
      ];
    },
  },
  mounted() {
    this.autoHeight();
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      // this.type = this.$route.query.calculatedType
      this.searchForm.mainId = this.$route.query.id;
      // this.total_CalculatedTonnage = this.$route.query.totalTonnage
      // this.calculatedTonnage = this.$route.query.recommendTonnage
      this.getDetail();
      this.initList();
    }
    // this.getTreeData();
    this.timer = setTimeout(this.gettime, 1000);
  },
  methods: {
    autoHeight() {
      // 初始化的时候，设置高度
      this.setHeight();
      // 改变浏览器窗口大小的时候，设置高度
      window.addEventListener("resize", () => {
        this.setHeight();
      });
    },
    setHeight() {
      this.$nextTick(() => {
        this.TableHeight = window.innerHeight - 335; // 这里的X就是顶部栏的高度
      });
    },
    gettime() {
      this.power = JSON.parse(localStorage.getItem("power"));
      this.power.forEach((e) => {
        if (e.name == "添加" || e.name == "Add") {
          this.btnAdd = 1;
        }
        if (e.name == "删除" || e.name == "Delete") {
          this.btnDel = 1;
        }
        if (e.name == "编辑" || e.name == "Edit") {
          this.btnEdit = 1;
        }
      });
      clearTimeout(this.timer);
    },
    // 获取主表详细信息
    getDetail() {
      GetStampingDetail(
        {
          id: this.id,
        },
        (response) => {
          if (response && response.code == 200) {
            this.type = response.result.type;
            this.total_CalculatedTonnage = response.result.tonnage;
            this.calculatedTonnage = response.result.recommendedTonnage;
          }
        }
      );
    },
    // 渲染列表数据
    initList() {
      this.searchForm.page = 1;
      this.searchForm.limit = 10;
      this.search();
    },
    // 新增
    addGrade() {
      this.operateType = "add";
      this.$refs.addGrade.form.mainId = this.id;
      this.$refs.addGrade.form.type = this.type;
      // this.$refs.addGrade.init();
      this.$refs.addGrade.visible = true;
    },
    addSubmit(form) {
      AddOrUpdateStamping(form, (response) => {
        if (response && response.code == 200) {
          this.initList();
          // if (this.operateType == "add") {
          //     this.tableData.push({
          //         grade: form.grade,
          //         gradeId: form.gradeId,
          //         operation_DetailsId: form.operation_DetailsId,
          //         operation_Details: form.operation_Details,
          //         section_Profile: form.section_Profile,
          //         length: form.length,
          //         width: form.width,
          //         circle_OD: form.circle_OD,
          //         circle_ID: form.circle_ID,
          //         calculatedMax: form.calculatedMax,
          //         thk: form.thk,
          //         calculated: response.data[0].calculated,
          //         calculatedTonnage:
          //             response.data[0].calculatedTonnage,
          //         manual: response.data[0].manual,
          //         total_CalculatedTonnage:
          //             response.data[0].total_CalculatedTonnage,
          //         total_Length_Cut_Bend:
          //             response.data[0].total_Length_Cut_Bend,
          //         typeOfStrength: response.data[0].typeOfStrength,
          //         strippingForce: "25%",
          //         fos: "15%",
          //     });
          // } else if (this.operateType == "edit") {
          //     this.tableData[this.operateIndex] = {
          //         grade: form.grade,
          //         gradeId: form.gradeId,
          //         operation_DetailsId: form.operation_DetailsId,
          //         operation_Details: form.operation_Details,
          //         section_Profile: form.section_Profile,
          //         length: form.length,
          //         width: form.width,
          //         circle_OD: form.circle_OD,
          //         circle_ID: form.circle_ID,
          //         calculatedMax: form.calculatedMax,
          //         thk: form.thk,
          //         calculated: response.data[0].calculated,
          //         calculatedTonnage:
          //             response.data[0].calculatedTonnage,
          //         manual: response.data[0].manual,
          //         total_CalculatedTonnage:
          //             response.data[0].total_CalculatedTonnage,
          //         total_Length_Cut_Bend:
          //             response.data[0].total_Length_Cut_Bend,
          //         typeOfStrength: response.data[0].typeOfStrength,
          //         strippingForce: "25%",
          //         fos: "15%",
          //     };
          // }
        }
      });
    },
    // 查询
    search() {
      LoadStamping(this.searchForm, (response) => {
        if (response && response.code == 200) {
          this.tableData = response.data;
          this.total = response.count;
        }
      });
    },
    // 分页
    handleCurrentChange(val) {
      this.searchForm.page = val;
      this.search();
    },
    // 分页数量修改
    handleSizeChange() {
      this.pageSize = parseInt(this.searchForm.limit);
      if (this.searchForm.limit === "") {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.setPageNum"),
          "",
          "warning"
        );

        return;
      }
      if (this.searchForm.limit == 0) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.pageThanZero"),
          "",
          "warning"
        );

        return;
      }
      this.searchForm.page = 1;
      this.search();
    },
    // 批量操作
    bathOperate(type) {
      if (this.chooseData.length == 0) {
        commonUtil.showTipInfo(
          this.$t("userlists.userlist.selecWant"),
          "",
          "warning"
        );
        return;
      }
      let ids = [];
      this.chooseData.forEach(function (val, key) {
        ids.push(val.id);
      });
      if (type == "start") {
        // 启用
        GradeFerroussEnableByIds(ids, (response) => {
          commonUtil.showTipInfo("启用成功！", "", "success");
          this.search();
        });
      } else if (type == "stop") {
        // 禁用
        GradeFerroussDisableByIds(ids, (response) => {
          commonUtil.showTipInfo("禁用成功！", "", "success");
          this.search();
        });
      } else if (type == "delete") {
        // 删除
        GradeFerroussDelete(ids, (response) => {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.deleteSuccessfully"),
            "",
            "success"
          );
          this.search();
        });
      }
    },
    // 导出
    Export() {},
    // 编辑
    edit(row, index) {
      this.operateType = "edit";
      this.operateIndex = index;
      // this.$refs.addGrade.init();
      this.$refs.addGrade.form = {
        id: row.id,
        mainId: this.id,
        gradeFerrousId: row.gradeFerrousId,
        operation_DetailsId: row.operation_DetailsId,
        section_Profile: row.section_Profile,
        length: row.length,
        width: row.width,
        circle_OD: row.circle_OD,
        circle_ID: row.circle_ID,
        thk: row.thk,
        total_Length_Of_Cut_bend: row.total_Length_Of_Cut_bend,
        calculatedMax: row.calculatedMax,
        calculated: row.calculated,
        manual: row.manual,
        type_Of_Strength: row.type_Of_Strength,
        stripping_Force: "25",
        fos: "15",
        calculated_Tonnage: row.calculated_Tonnage,
        type: this.type,
      };
      this.$refs.addGrade.visible = true;
    },
    // 删除
    del(row) {
      let ids = [];
      ids.push(row.id);
      DeleteStamping(ids, (response) => {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.deleteSuccessfully"),
          "",
          "success"
        );
        this.search();
      });
    },
    // 计算总值
    computed() {
      let _this = this;
      GetComputeMainResultRes(
        {
          id: this.id,
        },
        (response) => {
          if (response && response.code == 200) {
            if (response.result) {
              this.total_CalculatedTonnage = response.result.totalTonnage;
              this.calculatedTonnage = response.result.recommendedTonnage;
            }
          }
        }
      );
    },
    // 修改类型
    changeType() {
      UpdateTypeStamping(
        {
          mainId: this.id,
          type: this.type,
        },
        (response) => {
          if (response && response.code == 200) {
            commonUtil.showTipInfo(
              this.$t("other.other.ChangeSuccess"),
              "",
              "success"
            );
            this.search();
          }
        }
      );
    },
    Goback() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  padding-top: 20px;
  width: 100%;
  height: 100%;
  background: url("../../assets/img/car-table-bg.png") no-repeat center;
  background-size: contain;
}

.main-top_handle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
}
.tree-cla {
  background: #fff;
  .tree-cla-search {
    padding: 10px;
  }
  input {
    display: block;
    width: calc(100% - 10px);
    height: 40px;
    border-radius: 10px;
    background: #f5f7ff;
  }
}
.main-handle {
  align-items: center;
}
.main-handle-item {
  margin-right: 10px;
  flex: 1;
  display: flex;
  align-items: center;
  span {
    margin-right: 10px;
    white-space: nowrap;
  }
}
.main-handle input,
.main-handle .el-select .el-input {
  width: 100%;
  border-radius: 10px;
  font-size: 14px;
}
.main-handle input::placeholder {
  color: #3e4954;
  text-align: center;
  font-size: 14px;
}

.handle-btn {
  align-items: center;
  height: 40px;
  .handle-btn-item {
    padding: 0 20px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    background: #b2bde7;
    color: #fff;
    border-radius: 25px;
    cursor: pointer;
    margin-left: 10px;
  }
}
// 日期选择器
.select-box {
  position: relative;
  .select-btn {
    width: 200px;
    height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 10px;
    background: #fff;
    cursor: pointer;
    .Calendar,
    .Switch {
      font-size: 18px;
      color: #2f4cdd;
    }
  }
}
.search-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 30px;
  background: #445581;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.crumbs-box {
  position: fixed;
  top: 70px;
  left: 220px;
  padding-left: 20px;
  width: 100%;
  display: flex;
  background-color: #f2f2f2;
  z-index: 999;
  // border-bottom: 1px solid #e4e7ed;
  align-items: center;
  height: 30px;
  font-size: 16px;
  color: #3e4954;
  .crumbs-box-item {
    display: flex;
    align-items: center;
  }
  .el-icon {
    margin: 0 10px;
  }
  .parent {
    color: #2f4cdd;
    cursor: pointer;
  }
  .line {
    margin: 0 15px;
    width: 2px;
    height: 15px;
    background: #3e4954;
  }
}
</style>