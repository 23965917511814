<template>
  <el-dialog v-model="visible" width="500px" :show-close="false" @close="close">
    <template #header="{ close, titleId, titleClass }">
      <div class="my-header">
        <div class="size-16">
          {{ form.id === "" ? $t("pcbas.pcba.new") : $t("pcbas.pcba.edit") }}
        </div>
        <div style="cursor: pointer" @click="close">
          <el-icon :size="20"><Close /></el-icon>
        </div>
      </div>
    </template>
    <div class="add-cla-top">
      <el-row>
        <el-col :span="24">
          <el-form :inline="true" :model="form" label-width="150px">
            <el-row>
              <el-form-item
                :label="$t('changePriceLists.changePriceList.partNumber')"
                class="custom-el-form-rules"
              >
                <el-input
                  v-model="form.partNumber"
                  @input="(v) => (form.partNumber = v.replace(/[^\d.]/g, ''))"
                  placeholder=""
                  style="width: 214px"
                />
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item
                :label="$t('changePriceLists.changePriceList.description')"
              >
                <el-input
                  v-model="form.description"
                  placeholder=""
                  style="width: 214px"
                />
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item :label="$t('ccls.ccl.revision')">
                <el-input
                  v-model="form.revision"
                  placeholder=""
                  style="width: 214px"
                />
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item :label="$t('ccls.ccl.supplier')">
                <el-input
                  v-model="form.supplier"
                  placeholder=""
                  style="width: 214px"
                />
              </el-form-item>
            </el-row>
          </el-form>
        </el-col>
      </el-row>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close">{{ $t("pcbas.pcba.cancle") }}</el-button>
        <el-button
          style="
            background-color: var(--themeColor);
            color: var(--menuTextActive);
            margin-right: 16px;
          "
          @click="confirm"
          >{{ $t("pcbas.pcba.confirm") }}</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import commonUtil from "../../../assets/js/commonUtil.js";
export default {
  emits: ["confirmInfo"],
  data() {
    return {
      visible: false,
      form: {
        id: "",
        partNumber: "",
        description: "",
        revision: "",
        supplier: "",
      },
    };
  },
  methods: {
    close() {
      this.visible = false;
      this.form = {
        id: "",
        partNumber: "",
        description: "",
        revision: "",
        supplier: "",
      };
    },
    confirm() {
      if (this.form.partNumber == "") {
        commonUtil.showTipInfo(this.$t("ccls.ccl.EnterPartNum"), "", "warning");
        return;
      }
      this.$emit("confirmInfo", this.form);
      // this.close()
    },
  },
};
</script>

<style scoped>
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.size-16 {
  font-size: 16px;
}
.padding-9 {
  padding: 9px;
}
.el-row {
  margin-bottom: 10px;
}
.color-red {
  color: red;
}
.align-right {
  text-align: right;
}
</style>