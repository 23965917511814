<template>
  <div style="height: 100%">
    <div class="crumbs-box">
      <div class="crumbs-box-item parent" @click="GobackPage">
        {{ $t("homePage.home.Back") }}
      </div>
      <div class="line"></div>
      <div class="crumbs-box-item" v-for="(item, index) in crumbs">
        {{ $t(item) }}
        <el-icon v-show="index != crumbs.length - 1"><ArrowRight /></el-icon>
      </div>
    </div>
    <div class="main">
      <TcmPur
        ref="purForm"
        type="template"
        :CommodityOptions="CommodityOptions"
        :CommodityId="CommodityId"
        :CommodityName="CommodityName"
        :regionOptions="regionOptions"
        :CurrencyOptions="currencyOptions"
        :operateType="operateType"
        @goBack="goBack"
        @confirmData="confirmData"
      ></TcmPur>
    </div>
  </div>
</template>

<script>
import TcmPur from "../tcmTemplateManagement/tcmPur.vue";
import {
  GetOrgs,
  AddOrUpdateTemplatePurchases,
  getTemplatePurchasesDetail,
  getRegions,
  loadCurrency,
} from "../../assets/js/requestApi.js";
import commonUtil from "../../assets/js/commonUtil.js";
export default {
  components: {
    TcmPur,
  },
  watch: {
    operateType: {
      immediate: true,
      handler(nval) {
        switch (nval) {
          case "add":
            this.crumbs = [
              "homePage.home.Components",
              "unifiedPrompts.unifiedPrompt.new",
            ];
            break;
          case "edit":
            this.crumbs = [
              "homePage.home.Components",
              "unifiedPrompts.unifiedPrompt.edit",
            ];
            break;
          case "detail":
            this.crumbs = [
              "homePage.home.Components",
              "unifiedPrompts.unifiedPrompt.preview",
            ];
            break;
        }
      },
    },
  },
  data() {
    return {
      CommodityId: "",
      CommodityName: "",
      operateType: "add",
      CommodityOptions: [],
      // 区域选项
      regionOptions: [],
      // 货币选项
      currencyOptions: [],
      crumbs: [],
    };
  },
  mounted() {
    this.$route.meta.showTag = true;
    this.operateType = this.$route.query.type;
    if (this.operateType == "add") {
      this.CommodityId = this.$route.query.CommodityId;
      this.$refs.purForm.formData[0].commodityId = this.CommodityId;
    } else {
      let id = this.$route.query.id;
      this.getDetail(id);
    }
    this.CommodityName = this.$route.query.CommodityName;
    // this.getCommdity();
    // this.getRegion();
    this.regionOptions = JSON.parse(sessionStorage.getItem("Region"));
    this.CommodityOptions = JSON.parse(sessionStorage.getItem("Commodity"));

    this.getCurrencyOptions();
  },
  methods: {
    // 返回
    goBack() {
      this.$router.back(-1);
    },
    GobackPage() {
      this.$router.go(-1);
    },
    // 提交
    confirmData(val) {
      if (val[0].name === "") {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.pleaseName"),
          "",
          "warning"
        );
        return;
      }
      if (val[0].commodityId === "") {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.commodityRequired"),
          "",
          "warning"
        );
        return;
      }
      if (val[0].regionId === "") {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.regionRquired"),
          "",
          "warning"
        );
        return;
      }
      if (val[0].currencyId === "") {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.currencyRequired"),
          "",
          "warning"
        );
        return;
      }
      if (val[0].processName === "") {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.pleaseProcess"),
          "",
          "warning"
        );
        return;
      }
      // if (val[0].quantity === "") {
      //     commonUtil.showTipInfo(
      //         this.$t("changePriceLists.changePriceList.pleaseAssembly"),
      //         "",
      //         "warning"
      //     );
      //     return;
      // }
      if (val[0].unit === "") {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.pleaseMeasure"),
          "",
          "warning"
        );
        return;
      }
      if (val[0].unitCost === "") {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.pleaseUoM"),
          "",
          "warning"
        );
        return;
      }
      val[0].id = "";
      // val[0].commodityId = this.CommodityId
      AddOrUpdateTemplatePurchases(val[0], (response) => {
        if (response && response.code == 200) {
          if (this.operateType == "add") {
            commonUtil.showTipInfo(
              this.$t("unifiedPrompts.unifiedPrompt.addedSuccess"),
              "",
              "success"
            );
          } else {
            commonUtil.showTipInfo(
              this.$t("unifiedPrompts.unifiedPrompt.editSuccess"),
              "",
              "success"
            );
          }
          this.$route.meta.showTag = false;
          this.$router.back(-1);
        }
      });
    },
    // 获取详情
    getDetail(id) {
      getTemplatePurchasesDetail(
        {
          id: id,
        },
        (response) => {
          if (response && response.code == 200) {
            this.CommodityId = response.result[0].commodityId;
            this.$refs.purForm.formData[0] = response.result[0];
            if (this.operateType == "edit") {
              this.$refs.purForm.formData[0].version =
                parseFloat(response.result[0].version) + 1;
            }
          }
        }
      );
    },
    // 获取商品分类数据
    getCommdity() {
      let _this = this;
      GetOrgs(
        {
          type: 0,
          versionType: 0,
          TypeCost: 0,
          isAll: 0,
        },
        (response) => {
          if (response && response.Code == 200) {
            this.CommodityOptions = commonUtil.transferList(response.Result);
          }
        }
      );
    },
    // 获取区域列表选项
    getRegion() {
      getRegions(
        {
          Keyword: "",
          isEnable: 1,
          page: 1,
          limit: 9999,
          key: "",
        },
        (response) => {
          if (response && response.code == 200) {
            this.regionOptions = response.data;
          }
        }
      );
    },
    // 查询货币
    getCurrencyOptions() {
      loadCurrency(
        {
          IsOrderNumber: 0,
          Keyword: "",
          page: 1, //当前页
          limit: 99999, //每页个数
        },
        (response) => {
          if (response && response.code == 200) {
            this.currencyOptions = response.data;
          }
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  padding-top: 20px;
  width: 100%;
  min-height: 100%;
  // background: url("../../assets/img/car-table-bg.png") no-repeat center;
  // background-size: contain;
  // background-color: #fff;

  background: url("../../assets/img/car-table-bg.png") no-repeat right;
  background-position: right 4% bottom 0;
  background-size: 650px 600px;
  border-radius: 20px;
  background-color: #fff;
}
.crumbs-box {
  position: fixed;
  top: 70px;
  left: 220px;
  padding-left: 20px;
  width: 100%;
  display: flex;
  background-color: #f2f2f2;
  z-index: 999;
  // border-bottom: 1px solid #e4e7ed;
  align-items: center;
  height: 30px;
  font-size: 16px;
  color: #3e4954;
  .crumbs-box-item {
    display: flex;
    align-items: center;
  }
  .el-icon {
    margin: 0 10px;
  }
  .parent {
    color: #2f4cdd;
    cursor: pointer;
  }
  .line {
    margin: 0 15px;
    width: 2px;
    height: 15px;
    background: #3e4954;
  }
}
.handle-box {
  padding-bottom: 10px;
  display: flex;
  justify-content: flex-end;
}
.submit-btn {
  margin-right: 10px;
  padding: 0 30px;
  height: 30px;
  line-height: 30px;
  background: #445581;
  color: #fff;
  text-align: center;
  border-radius: 30px;
  cursor: pointer;
  font-weight: 600;
}
</style>