const info = {
    // 中文
    ZH: {
        processCalculator: '工艺计算器',
        calculatorCode: '工艺计算器编号',
        calculatorName: '工艺计算器名称',
        version: '版本',
        comments: '备注',
        enable: '激活状态',
        operate: '操作',
        Bdisabling: '分批取消',
        batchEnable: '分批激活',
        selectManipulate: '选择要操作的数据!',

    },
    // 英文
    EN: {
        processCalculator: 'Process Calculator',
        calculatorCode: 'Process Calculator Code',
        calculatorName: 'Process Calculator Name',
        version: 'Version',
        comments: 'Comments',
        enable: 'Status',
        operate: 'Operate',
        Bdisabling: 'Batch Disabling',
        batchEnable: 'Batch Enable',
        selectManipulate: 'Select the data that you want to manipulate！',
    }
}
export default info