import {
    ElLoading,
    ElMessage,
    ElMessageBox
} from 'element-plus';
import '../css/custom-message-box.css';
import '../css/custom-el-message.css';
import '../css/custom-loading-icon.css';

// import {
//     downloadFile
// } from './requestApi.js'
//loading 对象
let loadingInstance = null;
//设置消息框只能出现一个
let messageInstance = null;
let mainMessage = function DoneMessage(options) {
    //如果弹窗已存在先关闭
    if (messageInstance) {
        messageInstance.close();
    }
    messageInstance = ElMessage(options);
}
let arr = ['success', 'warning', 'info', 'error'];
arr.forEach(function (type) {
    mainMessage[type] = function (options) {
        if (typeof options === 'string') {
            options = {
                message: options
            };
        }
        options.type = type;
        return mainMessage(options);
    };
});
let commonUtil = {
    //当前正在加载请求
    loadingRequestArray: [],
    /**
     *判断字符是否为空
     *
     * @param {*} checkStr 待验证字符
     */
    isEmpty: function (checkStr) {
        if (checkStr == undefined || checkStr == null) {
            return true;
        } else {
            checkStr = String(checkStr);
            if (checkStr.trim() == "") {
                return true;
            }
        }
        return false;
    },
    /**
     * 获取时间
     * @param {*} showDate 是否显示日期
     * @param {*} showTime 是否显示时间
     * @param {*} interval 时间与当前时间间隔 默认0
     * @param {*} dateLinkChar 日期连接字符 默认-
     * @param {*} timeLinkChar 时间连接字符 默认:
     */
    getTime: function (showDate, showTime, interval, dateLinkChar, timeLinkChar) {
        let time = new Date();
        let timeStr = "";
        if (!isNaN(interval)) {
            time.setDate(time.getDate() + interval);
        }
        if (!this.isEmpty(showDate) && showDate == true) {
            if (this.isEmpty(dateLinkChar)) {
                dateLinkChar = "-";
            }
            timeStr = time.getFullYear() + dateLinkChar;
            if (time.getMonth() >= 9) {
                timeStr += (time.getMonth() + 1) + dateLinkChar;
            } else {
                timeStr += "0" + (time.getMonth() + 1) + dateLinkChar;
            }
            if (time.getDate() > 9) {
                timeStr += time.getDate();
            } else {
                timeStr += "0" + time.getDate();
            }
        }
        if (!this.isEmpty(showTime) && showTime == true) {
            if (!this.isEmpty(showDate) && showDate == true) {
                timeStr += " ";
            }
            if (this.isEmpty(timeLinkChar)) {
                timeLinkChar = ":";
            }
            if (time.getHours() > 9) {
                timeStr += time.getHours() + timeLinkChar;
            } else {
                timeStr += "0" + time.getHours() + timeLinkChar;
            }
            if (time.getMinutes() > 9) {
                timeStr += time.getMinutes() + timeLinkChar;
            } else {
                timeStr += "0" + time.getMinutes() + timeLinkChar;
            }
            if (time.getSeconds() > 9) {
                timeStr += time.getSeconds();
            } else {
                timeStr += "0" + time.getSeconds();
            }
        }
        return timeStr;
    },
    /**
     * 展示提示信息
     * @param {*} tipMsg  提示信息
     * @param {*} title  标题信息
     * @param {*} tipType 提示类型
     * @param {*} winType 窗口类型 1：message框 2：弹出框 默认为message框 3 alert弹框
     * @param {*} conformCallBack 确认回调
     * @param {*} cancelCallBack 取消回调
     * @param {*} closeCallBack 关闭回调
     */
    showTipInfo: function (tipMsg, title, tipType, winType, conformCallBack, cancelCallBack, closeCallBack) {
        if (winType == 2) {
            let iconClass = "custom-message-box-icon-info";
            let customClass = "custom-message-box custom-message-box-confirm";
            if (tipType == "confirm") {
                iconClass = "custom-message-box-icon-confirm";
            } else if (tipType == "warning") {
                iconClass = "custom-message-box-icon-warning";
            } else if (tipType == "error") {
                iconClass = "custom-message-box-icon-error";
            } else if (tipType == "success") {
                iconClass = "custom-message-box-icon-success";
            }
            let tipMessage = "<table><tr><td class='" + iconClass + "'></td><td>" + tipMsg + "</td></tr></table>";
            let option = {
                title: title || "提示",
                closeOnClickModal: false,
                dangerouslyUseHTMLString: true,
                customClass: customClass,
                cancelButtonClass: 'custom-message-button-cancel',
                // action 的值为 'confirm', 'cancel'或 'close', instance 为 MessageBox 实例， 可以通过它访问实例上的属性和方法
                callback: function (action, instance) {
                    if (action == "confirm") {
                        if (typeof (conformCallBack) == "function") {
                            conformCallBack();
                        }
                    } else if (action == "cancel") {
                        if (typeof (cancelCallBack) == "function") {
                            cancelCallBack();
                        }
                    } else if (action == "close") {
                        if (typeof (closeCallBack) == "function") {
                            closeCallBack();
                        }
                    }
                }
            };
            ElMessageBox.confirm(tipMessage, option)
        } else if (winType == 4) {
            alert(tipMsg);
        } else {
            let options = {
                message: tipMsg,
                type: tipType || "info",
                dangerouslyUseHTMLString: true,
                duration: 10000,
                center: true,
                // customClass: "custom-el-message",
                onClose: function () {
                    if (typeof (closeCallBack) == "function") {
                        closeCallBack();
                    }
                }
            };
            mainMessage(options)
        }
    },
    /**
     * 展示loading页面
     * @param {*} showFlag 是否显示标志
     */
    showLoading: function (showFlag) {
        if (showFlag == true) {
            loadingInstance = ElLoading.service({
                lock: true,
                text: '',
                background: 'rgba(0, 0, 0, 0.5)',
            });
        } else {
            if (loadingInstance != null) {
                //请求加载完
                if (this.loadingRequestArray.length == 0) {
                    window.setTimeout(function () {
                        loadingInstance.close();
                    }, 500);
                }
            }
        }
    },
    /**
     * 根据文件url下载文件
     * @param {*} url 
     * @param {*} name 
     */
    downloadFileByUrl(url, name) {
        const link = document.createElement('a');
        // url转成blob地址
        fetch(url).then(res => res.blob()).then(blob => {
            link.href = URL.createObjectURL(blob)
            // 下载文件的名称及文件类型后缀
            link.download = name;
            document.body.appendChild(link)
            link.click()
            //在资源下载完成后 清除 占用的缓存资源
            window.URL.revokeObjectURL(link.href);
            document.body.removeChild(link);
        });
        // const iframe = document.createElement("iframe");
        // iframe.setAttribute("hidden","hidden");
        // document.body.appendChild(iframe);
        // iframe.onload = () => {
        //     if(iframe){
        //         iframe.setAttribute('src','about:blank');
        //     }
        // };
        // iframe.setAttribute("src",url);
    },
    /**
     * 根据文件流下载文件
     * @param {*} blobData  文件流
     * @param {*} fileName  下载文件名
     */
    downloadFileByBlob(blobData, fileName) {
        // ie 使用
        if (window.navigator.msSaveBlob) {
            // eslint-disable-next-line no-useless-catch
            try {
                // eslint-disable-next-line no-inner-declarations
                var blobObject = new Blob([blobData]);
                window.navigator.msSaveBlob(blobObject, fileName);
            } catch (e) {
                throw e;
            }
        } else {
            // eslint-disable-next-line no-useless-catch
            try {
                // 其他浏览器 下载方式
                // eslint-disable-next-line no-inner-declarations
                var reader = new FileReader();
                reader.readAsDataURL(blobData);
                reader.onload = function (e) {
                    // 转换完成，创建一个a标签用于下载
                    var a = document.createElement('a');
                    a.download = fileName;
                    a.href = e.target.result;
                    // 兼容触发click
                    var evt = document.createEvent("MouseEvents");
                    evt.initEvent("click", true, true);
                    a.dispatchEvent(evt);
                }
            } catch (e) {
                throw e;
            }
        }
    },
    /**
     * 根据文件流下载文件
     * @param {*} blobData  文件流
     * @param {*} fileName  下载文件名
     */
    downloadFileByArryBuffer(blobData, fileName, fileType) {
        // 构造一个blob对象来处理数据，并设置文件类型
        var blob = ''
        if (fileType == 'txt') {
            blob = new Blob([blobData], { type: 'text/plain' })
        } else if (fileType == 'doc') {
            blob = new Blob([blobData], { type: 'application/msword' })
        } else if (fileType == 'pdf') {
            blob = new Blob([blobData], { type: 'application/pdf' })
        } else if (fileType == 'ppt') {
            blob = new Blob([blobData], { type: 'application/vnd.ms-powerpoint' })
        } else if (fileType == 'xlsm') {
            blob = new Blob([blobData], { type: 'application/vnd.ms-excel.sheet.macroEnabled.12' })
        } else if (fileType == 'xlsx') {
            blob = new Blob([blobData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        } else {
            blob = new Blob([blobData], { type: 'application/vnd.ms-excel' })
        }

        if (window.navigator.msSaveOrOpenBlob) { //兼容IE10
            navigator.msSaveBlob(blob, fileName)
        } else {
            const href = URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
            const a = document.createElement('a') //创建a标签
            a.style.display = 'none'
            a.href = href // 指定下载链接
            a.download = fileName //指定下载文件名
            a.click() //触发下载
            URL.revokeObjectURL(a.href) // 释放URL对象
        }
    },
    /**
     * 根据url下载图片
     * @param {*} url  url
     * @param {*} fileName  下载图片名
     */
    downloadPicByUrl(url, fileName) {
        let image = new Image()
        image.setAttribute('crossOrigin', 'anonymous')
        image.src = url
        image.onload = () => {
            let canvas = document.createElement('canvas')
            canvas.width = image.width
            canvas.height = image.height
            let ctx = canvas.getContext('2d')
            ctx.drawImage(image, 0, 0, image.width, image.height)
            canvas.toBlob((blob) => {
                let url = URL.createObjectURL(blob)
                // download(url,name)
                let eleLink = document.createElement('a')
                eleLink.download = fileName
                eleLink.href = url
                eleLink.click()
                eleLink.remove()
                // 用完释放URL对象
                URL.revokeObjectURL(url)
            })
        }
    },
    /**
     * 根据file下载图片
     * @param {*} url  url
     * @param {*} name  下载图片名
     */
    downloadPicByFile(file, name) {
        var baseData;
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            baseData = reader.result;
            //base64-->blob
            var byteString;
            if (baseData.split(',')[0].indexOf('base64') >= 0) {
                byteString = atob(baseData.split(',')[1]); //base64 解码
            } else {
                byteString = unescape(baseData.split(',')[1]);
            }
            var mimeString = baseData.split(',')[0].split(':')[1].split(';')[0]; //mime类型 -- image/png
            var ia = new Uint8Array(byteString.length); //创建视图
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            var blob = new Blob([ia], { type: 'image/png' });
            blob.lastModifiedDate = new Date();
            const objectUrl = URL.createObjectURL(blob)
            // 创建一个a链接
            var a = document.createElement('a')
            // 将a链接标签插入到body末
            document.body.appendChild(a)
            // 赋值a链接地址
            a.href = objectUrl
            // 设置文件名
            a.download = name || this.$moment().format('YYYYMMDDHHMMSS') + '.xlsx'
            // 触发a链接点击事件
            a.click()
            // 释放createObjectURL创建的对象URL
            window.URL.revokeObjectURL(objectUrl)
            // 移出a链接标签
            document.body.removeChild(a)
        };

    },
    /**
     * 列表数据转为树形结构
     * @param {*} data  列表数据
     */
    transferList(data) {
        const res = [];
        data.forEach((item) => {
            const parent = data.find((node) => node.Id === item.ParentId);
            if (parent) {
                parent.children = parent.children || [];
                item.value = item.Id
                parent.children.push(item);
            } else {
                // * 根节点
                item.value = item.Id
                res.push(item);
            }
        });
        return res;
    },
    /**
     * 金额转换千分位
     * @param {*} n 金额
     */
    format_with_regex(n) {
        if (n !== '' && n !== null) {
            n = n.toString().replace(/,/g, "")
            var b = parseInt(n).toString()
            var len = b.length
            if (len <= 3) {
                return b
            }
            var r = len % 3
            return r > 0
                ? b.slice(0, r) + ',' + b.slice(r, len).match(/\d{3}/g).join(',')
                : b.slice(r, len).match(/\d{3}/g).join(',')
        }

    }
}
export default commonUtil