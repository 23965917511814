const info = {
    // 中文
    ZH: {
        //GEN
        totalPrice: '总价(币种)',
        date: '日期',
        document: '文件',
        generalInformation: '基础信息',
        quotationType: '请求类型',
        programName: '项目名称',
        partDescription: '零件描述',
        ecrNo: 'ECR序号',
        partNumber: '零件号',
        commodity: '商品分类',
        drawingsRevision: '图纸版本号',
        currency: '币种',
        reportCurrency: '汇报币种',
        convertCurrency: '原始币种',
        exchangeRate: '汇率',
        region: '地区',
        supplierName: '供应商名称',
        materialDescription: '材料名称',
        peakVolume: '峰值年用量',
        lifetimeVolume: '生命周期总量',
        pegPoint: '价格基准点',
        comments: '备注',
        comment: '备注',
        year: '年份',
        rejected: '拒绝',
        approved: '批准',
        cancle: '取消',
        cancel_A: '取消',
        rejecit: '拒绝',
        submit: '提交',
        type: '类型',
        operator: '操作人',
        approver: '审批人',
        cc: '抄送人',

        volume: '量',
        attachments: '附件',
        dueDate: '截止日期',
        urgency: '紧急程度',
        productionBatch: '生产批量',
        annualProduction: '年生产频次',
        vendor: '供应商',
        //RM
        rawMaterial: '原材料',
        totalCost: '总净重原材料成本',
        materialDescription: '物料描述',
        netWeight: '原材料净重',
        grossWeight: '原材料毛重',
        unitMeasure: '计量单位',
        materialMeasure: '物料成本/计量单位',
        scrapPrice: '废料转售价/单位',
        country: '国家',
        materialCost: '原料成本',
        //PUR
        processDescription: '项目、材料或工艺描述',
        consumption: '每件装配用量',
        costUoM: '成本/单价',
        subTotalCost: '总成本',
        priceReference: '价格参考',
        purchasedComponents: '采购件/委外过程',
        purchaseCost: '采购件组件总价',
        // PRO
        processing: "工序",
        total: '直接人工成本总计',
        totalBurden: '间接费用成本总计',
        totalSetCost: '生产准备成本',
        totalManufacturing: '制造成本总计',
        annualVolume: '年用量',
        setUpTime: '准备时间(分钟)',
        setUpPersons: '准备人员数',
        piecesPerLot: '批次量',
        setUpCosts: '单件准备成本',
        operationDescription: '操作描述',
        operation: '操作',
        descriptionSize: '设备描述/型号',
        piecesHour: '件数/每小时',
        piecesPer: '每周期件数',
        pleasePieceCycle: '请输入每周期件数',
        manufacturingEfficiency: '生产效率',
        standardHour: '标准时间',
        directRate: '直接人工费率',
        setUpRate: '劳动工资率',
        setUpRates: '请输入劳动工资率',
        directOperators: '直接人工数',
        directAssembly: '操作人工成本/',
        burdenVariable: '变动机台费率/每小时',
        burdenRate: '固定费率/每小时',
        burdenDepreciation: '折旧费率/每小时',
        totalBurdenRate: '总设备费率/每小时',
        burdenAssembly: '设备费用成本/件',
        processScrap: '工序报废率',
        //P&L
        dutiesCustoms: '包装，物流，摊销，关税和海关',
        calculationMethod: '计算方式',
        freightCost: '运费',
        wareCost: '仓储成本',
        packagingCost: '包装成本',
        taxesFees: '海关、关税、税费',
        addedDescription: '另加说明(再加工，永久加工)',
        totalAddedCost: '另加成本(再加工成本，永久加工成本)',
        totalPiecesAdded: '另加总件数(再加工件数，永久加工件数)',
        addedCost: '另加成本',
        // OH
        sgaProfitScrap: '管销费&利润，报废',
        sga: '管销费%',
        sgaRequired: '请输入管销费%',
        sgaCost: '管销费成本',
        profitMaterial: '材料利润%',
        profitComponents: '增值利润%',
        profitValue: '采购件利润%',
        totalProfit: '总利润率',
        totalprofitCost: '总利润成本',
        materialScrap: '材料/PC 报废率%',
        sgas: '行销管理',
        profit: '总利润',
        // SUM
        costSummary: '成本汇总',
        rawMaterialCost: '原材料成本',
        setUpCost: '生产材料成本',
        purchasedCost: '采购件成本',
        packagingFreightCost: '包装/运费成本',
        directLaborCost: '直接人工成本',
        amortizationFees: '另加成本+关税，海关，税费',
        burdenCost: '间接费用成本',
        amortizationCost: '另加成本',
        sgaAndProfit: '管销费 &利润',
        processScrapCost: '报废成本',
        totalSellPrice: '总售价(币种)',
        totalSellPriceBefore: '总售价(币种)(改价前)',
        totalSellCurrency: '总售价(汇报币种)',
        totalSellCurrencyBefore: '总售价(汇报币种)(改价前)',
        beforeChangePrice: '改价前',
        setUp: '设置',
        selectCell: '选择组件',
        selectPriceReference: '选择参考价格',

        //tcm新增提示信息
        requestRequired: '请选择请求类型!',
        pleaseName: '请选择单元名称!',
        commodityRequired: '请选择商品分类!',
        regionIdRequired: '请选择地区!',
        commentsRequired: '请输入备注',
        pleaseMinutes: '请输入准备时间(分钟)!',
        pleasePersons: '请输入准备人员数!',
        pleaseDescriptions: '请输入操作描述!',
        partRequired: '请输入零件描述!',
        currencyRequired: '请选择货币!',
        reportRequired: '请选择汇报币种！',
        regionRquired: '请选择地区!',
        SupplierRquired: '请输入供应商名称!',
        exRquired: '请输入汇率！',
        projectRequired: '请输入项目名称!',
        partNumberRequired: '请输入零件号!',
        ecrNoRequired: '请输入ECR序号!',
        //模板新增提示
        templateRequired: '请输入模板名称',
        materialRequired: '请输入物料描述',
        NetMWeightRequired: '请输入原材料净重',
        GrossMWeightRequired: '请输入原材料毛重',
        unitRequired: '请选择计量单位',
        UnitMaterialRequired: '请输入物料成本/计量单位',
        materialRequireds: '请输入废料转售价格',
        setRequired: '请输入准备时间(分钟)',
        ofMissing: '请输入准备人员数',
        pleaseDescription: '请输入工艺操作说明',
        pleaseSize: '请输入设备描述及型号',
        pleaseHour: '请输入小时产出数',
        pleaseCycle: '请输入每周期的产出数',
        pleaseEfficiency: '请输入生产效率',
        pleaseRate: '请输入直接人工费率',
        pleaseOperators: '请输入直接人工数',
        pleaseVariable: '请输入每小时变动机台费率',
        pleaseScrap: '请输入工序报废率',
        pleaseProcess: '请输入项目、材料或工序说明',
        pleaseAssembly: '请输入每组装配用量',
        pleaseMeasure: '请选择计量单位',
        pleaseUoM: '请输入成本/单价!',
        partRevision: '图纸版本号',
        totalTooling: '总摊销成本',
        totalAmortized: '平摊总件数',
        amortizationDescription: '摊销描述',
        reportForm: '报告表',
        exportPdf: '导出PDF',
        copy: '复制',
        upload: '上传',
        email: '邮箱',
        name: '姓名',
        emails: '邮箱',
        names: '姓名',
        start: '开始',
        saveTemplata: '保存模板',
        saveDraft: '保存草稿',
        selectTemplate: '选择模板',
        selectOwerTemplate: '选择个人模板',
        selectCompleted: '选择已完成的CBD',
        fromTemplate: '选择模板',
        materialInput: '新建单元',
        purchasedInput: '新增单元',
        procesInput: '过程输入',
        search: '搜索',
        select: '选择',
        templateCode: '模板代码',
        templateName: '模板名称',
        newCell: '新建工序单元',
        newMateria: '新建原材料单元',
        versionName: '请输入工艺计算器名称',
        pleaseNames: '请输入输出值名称',
        pleaseFormula: '请输入公式!',
        pleaseData: '请输入数据!',
        pleaseescription: '请输入物料描述',
        pleaseCostMeasure: "请输入物料成本/计量单位",
        pleaseMeasures: '请输入废料转售价/单位!',
        calculationMethodOption: 'calculationMethodOption',
        pleaseChooseCell: '请选择需要添加的组件！',
        pleaseChooseData: '请选择需要添加的数据！',
        onlyOneToChoose: '仅可选择一条价格参考！',
        pleaseTrueEmail: '请输入正确的邮箱!',
        description: '描述',
        approve: '批准',
        OverwriteTheOriginalData: '保存覆盖原CBD',
        SaveNewParts: '保存为新零件',
        Tips: '提示',
        ApplicationTips: '系统已存在录入零件，请查看详情是否引用',
        View: '查看',
        Refuse: '拒绝',
        alreadyExists: '已存在',
        recordFounded: '条记录',
        downloadFile: '下载文件',
        ExternalImport: '外部导入',
        SelectHistoryMailbox: '选择历史邮箱',
        SelectEmail: '选择邮箱',
        onlyOnePartNumber: '一个partNumber只能选择一个版本,以下partnumber选择多个版本。请重新选择!',
        total: '总价',
        Scrap: '报废',
        Packaging: '包装',
        Absorbed: '另加',
        Save: '保存',
        UploadFiles: '上传文件',
    },
    // 英文
    EN: {
        calculationMethodOption: 'calculationMethodOptions',
        pleaseescription: 'Please enter Material Description',
        pleaseCostMeasure: "Please enter Material Cost / Unit of Measure！",
        pleaseMeasures: 'Please enter Material Scrap Resale Value / Unit of Measure',
        versionName: 'Please enter a process calculator name',
        pleaseNames: 'Please enter the output value name',
        pleaseFormula: 'Please enter the formula!',
        pleaseData: 'Please enter data!',
        newMateria: 'New Raw Material Cell',
        newCell: 'New Processing Cell',
        templateCode: 'Template Code',
        templateName: 'Template Name',
        select: 'Select',
        search: 'Search',
        procesInput: 'process input',
        emails: 'email',
        names: 'name',
        reportForm: 'Report form',
        exportPdf: 'Export PDF',
        //GEN
        totalPrice: 'Total Sell Price(Currency)',
        date: 'Date',
        document: 'Document',
        generalInformation: 'General Information',
        quotationType: 'Request Type',
        programName: 'Program Name',
        partDescription: 'Part Description',
        ecrNo: 'ECR No.',
        partNumber: 'Part Number',
        partRevision: 'Drawings Revision',
        commodity: 'Commodity',
        drawingsRevision: 'Drawings Revision',
        currency: 'Currency',
        reportCurrency: 'Report Currency',
        convertCurrency: 'From Currency',
        fromTemplate: 'Select Template',
        purchasedInput: 'Add Cell',
        materialInput: 'Add Cell',
        exchangeRate: 'Exchange Rate',
        region: 'Region',
        supplierName: 'Supplier Name',
        rejected: 'Rejected',
        approved: 'Approved',
        cancle: 'Cancel',
        cancel_A: 'CANCEL',
        submit: 'Submit',
        type: 'Type',
        operator: 'operator',
        approver: 'approver',
        cc: 'cc',
        saveTemplata: 'SAVE TEMPLATE',
        saveDraft: 'SAVE DRAFT',
        rejecit: 'REJECT',
        materialDescription: 'Material Description',
        peakVolume: 'Peak Volume',
        lifetimeVolume: 'Lifetime Volume',
        pegPoint: 'Peg Point',
        comments: 'Comments',
        comment: 'Comment',
        year: 'Year',
        volume: 'Volume',
        copy: 'Copy',
        attachments: 'Attachments',
        dueDate: 'Due date',
        upload: 'Upload',
        urgency: 'Urgency',
        email: 'E-mail',
        name: 'Name',
        productionBatch: 'Lot Run Size',
        annualProduction: '# of Production Runs Per Year',
        vendor: 'Supply source',
        start: 'Start',
        //RM
        rawMaterial: 'Raw Material',
        totalCost: 'Total Net Raw Material Cost',
        materialDescription: 'Material Description',
        netWeight: 'Net Material Weight',
        grossWeight: 'Gross Material Weight',
        unitMeasure: 'Unit of Measure',
        materialMeasure: 'Material Cost / Unit of Measure',
        scrapPrice: 'Material Scrap Resale Value / Unit of Measure',
        country: 'Country',
        materialCost: 'Material Cost',
        setUp: 'Set Up # 1',
        selectTemplate: 'Select Template',
        selectOwerTemplate: 'Select Personal Template',
        selectCompleted: 'Select Completed CBD',
        //PUR
        processDescription: 'Item, Material or Process Description',
        consumption: 'Quantity Per Assembly',
        costUoM: 'Cost / UoM',
        subTotalCost: 'Sub-Total Cost',
        priceReference: 'Price reference',
        //计量单位同上
        purchasedComponents: 'Purchased Components & Outside Processing',
        purchaseCost: 'Total Purchase Components Cost',
        // PRO
        processing: "Processing",
        total: 'TOTAL Direct Labor Cost',
        totalBurden: 'TOTAL Burden Cost',
        totalSetCost: 'TOTAL Set Up Cost',
        totalManufacturing: 'TOTAL Manufacturing Cost',
        annualVolume: 'Annual Volume',
        setUpTime: 'Set Up Time (Minutes)',
        setUpPersons: '# of Set Up Persons',
        piecesPerLot: 'Pieces per Lot',
        setUpCosts: 'Set Up Costs Per Piece',
        operation: 'Operation',
        operationDescription: 'Operation Description',
        descriptionSize: 'Equipment Description & Size',
        //用量同上
        piecesHour: 'Pieces/Hour',
        piecesPer: 'Pieces Per Cycle',
        pleasePieceCycle: 'Pieces Per Cycle Required',
        manufacturingEfficiency: 'Manufacturing Efficiency %',
        standardHour: 'Standard Hour',
        directRate: 'Direct Labor Wage Rate',
        setUpRate: 'Set Up Labor Wage Rate',
        setUpRates: 'Please enter Set Up Labor Wage Rate！',
        directOperators: 'No. of Direct Operators',
        directAssembly: 'Direct Labor Cost /Assembly',
        burdenVariable: 'Burden Variable Rate / hr',
        burdenRate: 'Burden Fixed Rate / hr',
        burdenDepreciation: 'Burden Depreciation Rate / hr',
        totalBurdenRate: 'Total Burden Rate / hr',
        burdenAssembly: 'Burden Cost / Assembly',
        //总成本 同上
        processScrap: 'Process Scrap %',
        //P&L
        dutiesCustoms: 'Packaging, Logistics, Amortization, Duties & Customs',
        calculationMethod: 'Calculation method',
        freightCost: 'Freight Cost 2',
        wareCost: 'Ware Housing Cost',
        packagingCost: 'Packaging Cost',
        taxesFees: 'Customs, Duties, Taxes & Fees',
        amortizationDescription: 'Amortization Description (Resinking, Perpectual tooling)',
        addedDescription: 'Absorbed Description (Resinking, Perpectual tooling)',
        totalAddedCost: 'Total Absorbed Cost(Resinking Cost,Perpectual Tooling)',
        totalTooling: 'Total Amortizations Cost(Resinking Cost,Perpectual Tooling)',
        totalAmortized: 'Total Pieces Amortized(Pieces per Resink, Pieces per perpectual tool)',
        totalPiecesAdded: 'Total Pieces Absorbed(Pieces per Resink, Pieces per perpectual tool)',
        addedCost: 'Absorbed  Cost',
        // OH
        sgaProfitScrap: 'SGA & Profit, Scrap',
        sga: 'SG&A %',
        sgaCost: 'SG&A Cost',
        profitMaterial: 'PROFIT on Material %',
        profitComponents: 'PROFIT on Value Add %',
        profitValue: 'PROFIT on Purchased Components%',
        totalProfit: 'Total Profit%',
        totalprofitCost: 'Total Profit Cost',
        materialScrap: 'Material/PC Scrap %',
        sgas: 'SGA',
        profit: 'PROFIT',
        sgaRequired: 'SG&A % required',
        // SUM
        costSummary: 'COST SUMMARY',
        rawMaterialCost: 'Raw Material Cost',
        setUpCost: 'Set Up Cost',
        purchasedCost: 'Purchased Components Cost',
        packagingFreightCost: 'Packaging & Freight Cost',
        directLaborCost: 'Direct Labor Cost',
        amortizationFees: 'Absorbed Cost + Customs, Duties, Taxes & Fees',
        burdenCost: 'Burden Cost',
        amortizationCost: 'Absorbed Cost',
        sgaAndProfit: 'SG&A and Profit',
        processScrapCost: 'Process Scrap Cost',
        totalSellPrice: 'Total Sell Price(Currency)',
        totalSellPriceBefore: 'Total Sell Price(Currency)(Before)',
        totalSellCurrency: 'Total Sell Price ( Report Currency)',
        totalSellCurrencyBefore: 'Total Sell Price ( Report Currency)(Before)',
        beforeChangePrice: 'Before Change Price',
        //附件同上
        setUp: 'Set Up',
        selectCell: 'Select Cell',
        selectPriceReference: 'Select Reference Price',

        //tcm新增提示信息
        requestRequired: 'Request Type required',
        pleaseName: 'Please choose  cell name',
        commodityRequired: 'Commodity required',
        regionIdRequired: 'Region Required',
        commentsRequired: 'Please enter comment',
        pleaseMinutes: 'Please enter Set Up Time (Minutes)！',
        pleasePersons: 'Please enter # of Set Up Persons！',
        pleaseDescriptions: 'Please enter Operation Description！',
        partRequired: 'Part description required',
        currencyRequired: 'Currency required',
        reportRequired: 'Report currency required',
        regionRquired: 'Region required',
        SupplierRquired: 'Supplier name required',
        exRquired: 'Ex rate required',
        projectRequired: 'Project required',
        partNumberRequired: 'Part number required',
        ecrNoRequired: 'ECR NO required',
        //模板新增提示
        templateRequired: 'template name required',
        materialRequired: 'Material Description required',
        NetMWeightRequired: 'Net Material Weight required',
        GrossMWeightRequired: 'Gross Material Weight required',
        unitRequired: 'Unit of Measure required',
        UnitMaterialRequired: 'Unit of Material price required',
        materialRequireds: 'Material Scrap Resale price required',
        setRequired: 'Set Up Time required',
        ofMissing: '# of Set Up Persons missing',
        pleaseDescription: 'Please enter Operation Description',
        pleaseSize: 'Please enter Equipment Description & Size',
        pleaseHour: 'Please enter Pieces/Hour！',
        pleaseCycle: 'Please enter Pieces Per Cycle！',
        pleaseEfficiency: 'Please enter Manufacturing Efficiency',
        pleaseRate: 'Please enter Direct Labor Wage Rate',
        pleaseOperators: 'Please enter No. of Direct Operators',
        pleaseVariable: 'Please enter Burden Variable Rate / hr',
        pleaseScrap: 'Please enter Process Scrap %',
        pleaseProcess: 'Please enter Item, Material or Process Description',
        pleaseAssembly: 'Please enter Quantity Per Assembly',
        pleaseMeasure: 'Please choose Unit of Measure',
        pleaseUoM: 'Please enter Cost / UoM',
        pleaseChooseCell: 'Please select the component you want to add!',
        pleaseChooseData: 'Please select the data you want to add!',
        onlyOneToChoose: 'Only a price reference can be selected!',
        pleaseTrueEmail: 'Please enter the correct email address!',
        description: 'Description',
        approve: 'APPROVE',
        OverwriteTheOriginalData: 'OverWrite',
        SaveNewParts: 'Save as new',
        Tips: 'Tips',
        ApplicationTips: 'The system already has input parts, please check whether the details are referenced',
        View: 'View',
        Refuse: 'Refuse',
        alreadyExists: 'already exists,',
        recordFounded: 'record founded',
        downloadFile: 'Download File',
        ExternalImport: 'External Import',
        SelectHistoryMailbox: 'Select History Mailbox',
        SelectEmail: 'Select Email',
        onlyOnePartNumber: 'Only one version can be selected for a partNumber. Multiple versions can be selected for the following partNumbers. Please reselect!',
        total: 'Total',
        Scrap: 'Scrap',
        Packaging: 'Packaging',
        Absorbed: 'Absorbed',
        Save: 'Save',
        UploadFiles: 'Upload Files',
    }
}
export default info