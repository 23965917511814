<template>
  <el-dialog v-model="visible" width="40%" :show-close="false">
    <template #header="{ close, titleId, titleClass }">
      <div class="my-header">
        <div class="size-16">
          {{ $t("tcmlists.tcmlist.chooseHistoryEmaol") }}
        </div>
        <div @click="close">
          <el-icon :size="20"><Close /></el-icon>
        </div>
      </div>
    </template>
    <div class="tcm-table">
      <div class="search-box">
        <input
          type="text"
          v-model="searchForm.Keyword"
          :placeholder="$t('unifiedPrompts.unifiedPrompt.keyword')"
        />
        <div class="search-btn" style="margin-right: 10px" @click="search">
          {{ $t("userlists.userlist.search") }}
        </div>
      </div>
      <div class="regional_table">
        <el-table
          :empty-text="$t('tcmlists.tcmlist.noData')"
          ref="rawTableHistory"
          :data="tableData"
          border
          stripe
          height="400"
          :row-key="
            (row) => {
              return row.id;
            }
          "
          @selection-change="handleSelectionChange"
          @select-all="selectAll"
        >
          <el-table-column
            type="selection"
            width="55"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="email"
            :label="$t('changePriceLists.changePriceList.email')"
            show-overflow-tooltip
            align="center"
          />
          <el-table-column
            prop="name"
            :label="$t('changePriceLists.changePriceList.name')"
            show-overflow-tooltip
            align="center"
          />
          <el-table-column
            prop="enable"
            :label="$t('ccls.ccl.operate')"
            show-overflow-tooltip
            width="110"
            align="center"
          >
            <template #default="scope">
              <img
                src="../../assets/img/tcm/cuowu.png"
                style="width: 20px; height: 20px; cursor: pointer"
                @click="deleteEmail(scope.row.id)"
              />
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination">
        <div></div>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :page-size="pageSize"
          :current-page="searchForm.page"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button
          style="
            background-color: var(--themeColor);
            color: var(--menuTextActive);
            margin-right: 16px;
          "
          @click="toConfirm"
          >{{ $t("userlists.userlist.confirm") }}</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { LoadMailLine, DeleteMailHistory } from "../../assets/js/requestApi.js";
import commonUtil from "../../assets/js/commonUtil.js";

export default {
  emits: ["getChooseData"],
  data() {
    return {
      isMultiChose: true, //默认可多选
      visible: false,
      searchForm: {
        Keyword: "",
        page: 1,
        limit: 10,
      },
      // 总页数
      total: 0,
      // 表格数据
      tableData: [],
      // 已勾选数据
      chooseData: [],
    };
  },
  methods: {
    close() {
      this.visible = false;
      this.chooseData = [];
    },
    toConfirm() {
      if (this.chooseData.length == 0) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.pleaseChooseData"),
          "",
          "warning"
        );
        return;
      }
      let data = [];
      this.chooseData.forEach(function (val, index) {
        data.push({
          id: val.userId,
          email: val.email,
          name: val.name,
          type: 1,
        });
      });
      this.$emit("getChooseData", data);
      this.close();
    },
    // 获取表格数据
    getlist() {
      this.searchForm = {
        Keyword: "",
        page: 1,
        limit: 10,
      };
      this.search();
    },
    // 查询
    search() {
      LoadMailLine(this.searchForm, (response) => {
        if (response && response.code == 200) {
          this.tableData = response.data;
          this.total = response.count;
        } else {
          commonUtil.showTipInfo(this.$t("userlists.userlist.inqueryTimeout"));
        }
      });
    },
    // 分页
    handleCurrentChange(val) {
      this.searchForm.page = val;
      this.search();
    },
    // 表格单选选
    handleSelectionChange(val) {
      // this.chooseData = val
      if (val.length > 1) {
        //单选且选中的数量大于1时
        this.$refs.rawTableHistory.clearSelection();
        this.$refs.rawTableHistory.toggleRowSelection(val.pop());
      }
      this.chooseData = val;
    },
    // 表格全选
    selectAll() {
      // this.chooseData = this.tableData;
    },
    // 删除邮箱
    deleteEmail(id) {
      let ids = [];
      ids.push(id);
      DeleteMailHistory(ids, (response) => {
        if (response && response.code == 200) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.deleteSuccessfully"),
            "",
            "success"
          );
          this.search();
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
:deep(.el-dialog__header) {
  margin-right: 0;
}
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.size-16 {
  font-size: 16px;
}
.search-box {
  display: flex;
  input {
    width: 50%;
    background: #f4f5f9;
    border-radius: 5px;
    font-size: 13px;
  }
  input::placeholder {
    color: #445581;
  }
  .search-btn {
    margin-left: 15px;
    width: 100px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background: #445581;
    color: #fff;
    border-radius: 5px;
  }
}
.regional_table {
  margin: 10px 0;
}
</style>