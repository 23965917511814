const info = {
    // 中文
    ZH: {
        commodity: '商品分类',
        date: '日期',
        status: '任务状态',
        search: '搜索',
        commodity: '商品分类',
        workflowId: '工作流编号',
        tcmRequester: 'TCM申请人',
        partDescription: '零件描述',
        partNumber: '零件号',
        Price: '价格',
        startDate: '开始时间',
        dueDate: '完成时间',
        priority: '优先级',
        quotationType: '请求类型',
        tcmOwner: 'TCM处理人',
        workStatus: '任务状态',

        notStart: '未开始',
        inProcess: '进行中',
        rejected: '驳回',
        approved: '通过',
        finished: '已完成',
        unfinished: '未完成',
        toApproved: '待审批',
        underApproved: '审批中',
        documentNO: '文件号',
        pleaseSlect: '请选择',
        high: '高',
        middle: '中',
        low: '低',
        urgencyOptionss: 'urgencyOptionss',
        unchangedPrice: '未改价',
        changedprice: '已改价',
        regionName: '区域',
        All: '全部'

    },
    // 英文
    EN: {
        urgencyOptionss: 'urgencyOptions',
        commodity: 'commodity',
        date: 'Date',
        status: 'Workflow Status',
        search: 'Search',
        commodity: 'Commodity',
        workflowId: 'Workflow ID',
        tcmRequester: 'TCM Requester',
        partDescription: 'Part Description',
        partNumber: 'Part Number',
        Price: 'Price',
        startDate: 'Start Date',
        dueDate: 'Complete date',
        priority: 'Priority',
        quotationType: 'Request Type',
        tcmOwner: 'TCM Owner',
        workStatus: 'Workflow Status',

        notStart: 'Not started',
        inProcess: 'In process',
        rejected: 'Rejected',
        approved: 'Approved',
        finished: 'Finished',
        unfinished: 'Unfinished',
        toApproved: 'To be approved',
        underApproved: 'Under approved',
        documentNO: 'Document NO',
        pleaseSlect: 'Please select',
        high: 'High',
        middle: 'Middle',
        low: 'Low',
        unchangedPrice: 'Unchanged Price',
        changedprice: 'Changed price',
        regionName: 'Region Name',
        All: 'All'
    }
}
export default info