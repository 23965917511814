<template>
  <div style="padding: 15px">
    <div class="Total-MaterialCost" v-show="type != 'template'">
      <div class="MaterialCost">
        <p>{{ $t("changePriceLists.changePriceList.total") }}</p>
        <input v-model="laborCost_PRO" disabled type="text" />
      </div>
      <div class="MaterialCost">
        <p>{{ $t("changePriceLists.changePriceList.totalSetCost") }}</p>
        <input v-model="setUpCost_PRO" disabled type="text" />
      </div>
      <div class="MaterialCost">
        <p>{{ $t("changePriceLists.changePriceList.totalBurden") }}</p>
        <input v-model="burdenCost_PRO" disabled type="text" />
      </div>
      <div class="MaterialCost">
        <p>{{ $t("changePriceLists.changePriceList.totalManufacturing") }}</p>
        <input v-model="manufacturingCost_PRO" disabled type="text" />
      </div>
    </div>

    <div class="flex" style="padding: 20px">
      <div class="table-box" id="ProId">
        <table class="add-table">
          <tr>
            <td></td>
            <td
              v-for="(item, index) in formData"
              :key="index"
              style="border-bottom: 1px solid #ccc"
            >
              <div
                class="flex"
                style="
                  position: relative;
                  width: 100%;
                  height: 100%;
                  justify-content: space-evenly;
                  align-items: center;
                "
              >
                <span class="Serial_number">#{{ index + 1 }}</span>
                <img
                  v-show="type != 'template'"
                  src="../../assets/img/tcm/zuojiantou.png"
                  style="width: 23px; height: 23px"
                  @click="reorder('left', index)"
                />
                <img
                  v-show="type != 'template'"
                  src="../../assets/img/tcm/youjiantou.png"
                  style="width: 23px; height: 23px"
                  @click="reorder('right', index)"
                />
                <el-icon
                  v-show="type != 'template' && operateType != 'detail'"
                  :size="18"
                  @click="copyMoudle(index)"
                  ><CopyDocument
                /></el-icon>
                <el-icon
                  v-show="type != 'template' && operateType != 'detail'"
                  :size="18"
                  @click="deleteMoudle(index)"
                  ><DeleteFilled
                /></el-icon>

                <div
                  class="submit-btn"
                  v-show="type == 'template' && operateType != 'detail'"
                  @click="cancle"
                >
                  {{ $t("userlists.userlist.cancel") }}
                </div>
                <div
                  class="submit-btn"
                  v-show="type == 'template' && operateType != 'detail'"
                  @click="confirm"
                >
                  {{ $t("userlists.userlist.confirm") }}
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <span class="color-red">*</span>

              {{ $t("changePriceLists.changePriceList.operationDescription") }}
            </td>
            <td v-for="(item, index) in formData" :key="index">
              <el-input
                v-show="type == 'template'"
                v-model="item.processName"
                :disabled="operateType == 'detail'"
              />
              <el-input
                v-show="type != 'template'"
                v-model="item.name"
                :disabled="operateType == 'detail'"
              />
            </td>
          </tr>
          <tr>
            <td>
              <span class="color-red">*</span
              >{{ $t("changePriceLists.changePriceList.currency") }}
            </td>
            <td v-for="(item, index) in formData" :key="index">
              <el-select
                style="width: 100%"
                clearable
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                filterable
                v-model="item.currencyId"
                placeholder=""
                :disabled="operateType == 'detail' || type != 'template'"
              >
                <el-option
                  v-for="item in CurrencyOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </td>
          </tr>
          <tr v-show="type != 'template'">
            <td>
              <span class="color-red">*</span>
              {{ $t("changePriceLists.changePriceList.convertCurrency") }}
            </td>
            <td v-for="(item, index) in formData" :key="index">
              <el-select
                style="width: 100%"
                clearable
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                filterable
                v-model="item.convertCurrencyId"
                placeholder=""
                disabled
              >
                <el-option
                  v-for="item in CurrencyOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </td>
          </tr>
          <tr v-show="type != 'template'">
            <td>
              <span class="color-red">*</span>

              {{ $t("changePriceLists.changePriceList.exchangeRate") }}
            </td>
            <td v-for="(item, index) in formData" :key="index">
              <el-input
                v-model="item.converRate"
                :disabled="operateType == 'detail'"
                @input="
                  transferFormat($event, item, 'converRate'), toComputeAll(item)
                "
                @change="toComputeAll(item)"
                @blur="toComputeAll(item)"
              />
            </td>
          </tr>
          <!--  -->
          <!-- <template v-show="type == 'template'"> -->
          <tr v-show="operateType != 'add' && type == 'template'">
            <td>
              <span class="color-red">*</span>
              {{ $t("templateLists.templateList.cellNo") }}
            </td>
            <td v-for="(item, index) in formData" :key="index">
              <el-input v-model="item.number" disabled />
            </td>
          </tr>
          <tr v-show="type == 'template'">
            <td>
              <span class="color-red">*</span>
              {{ $t("templateLists.templateList.cellName") }}
            </td>
            <td v-for="(item, index) in formData" :key="index">
              <el-input
                v-model="item.name"
                :disabled="operateType == 'detail'"
              />
            </td>
          </tr>
          <tr v-show="type == 'template'">
            <td>
              <span class="color-red">*</span>
              {{ $t("changePriceLists.changePriceList.commodity") }}
            </td>
            <td v-for="(item, index) in formData" :key="index">
              <el-tree-select
                clearable
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                v-model="item.commodityId"
                append-to-body
                @change="changeCommodityId($event, index)"
                :disabled="operateType == 'detail'"
                :data="CommodityOptions"
                :props="defaultProps"
                check-strictly
                :render-after-expand="false"
                :default-expand-all="true"
              >
                <template #default="{ data: { Name } }">
                  <el-tooltip :content="Name" placement="top" effect="light">
                    {{ Name }}
                  </el-tooltip>
                </template>
              </el-tree-select>
            </td>
          </tr>
          <tr v-show="type == 'template'">
            <td>
              <span class="color-red">*</span>
              {{ $t("templateLists.templateList.region") }}
            </td>
            <td v-for="(item, index) in formData" :key="index">
              <el-select
                clearable
                v-model="item.regionId"
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                :disabled="operateType == 'detail'"
              >
                <el-option
                  v-for="item in regionOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
                <!-- <el-option
                  v-if="
                    !regionOptions.some((list) => list.id === item.regionId) &&
                    item.regionId != ''
                  "
                  :label="item.regionName"
                  :value="item.regionId"
                /> -->
              </el-select>
            </td>
          </tr>
          <tr v-show="operateType != 'add' && type == 'template'">
            <td>{{ $t("templateLists.templateList.version") }}</td>
            <td v-for="(item, index) in formData" :key="index">
              <el-input v-model="item.version" disabled />
            </td>
          </tr>
          <tr v-show="type == 'template'">
            <td>{{ $t("templateLists.templateList.comments") }}</td>
            <td v-for="(item, index) in formData" :key="index">
              <el-input
                v-model="item.comment"
                :disabled="operateType == 'detail'"
              />
            </td>
          </tr>
          <!-- </template> -->
          <!-- --------- -->

          <tr>
            <td>{{ $t("changePriceLists.changePriceList.annualVolume") }}</td>
            <td v-for="(item, index) in formData" :key="index">
              <el-input
                v-model="item.annualVolume"
                :disabled="operateType == 'detail' || type != 'alloperate'"
              />
            </td>
          </tr>
          <tr>
            <td>
              <span class="color-red">*</span>
              {{ $t("changePriceLists.changePriceList.setUpTime") }}
            </td>
            <td v-for="(item, index) in formData" :key="index">
              <el-input
                v-model="item.setTime"
                :disabled="operateType == 'detail'"
                @input="
                  transferFormat($event, item, 'setTime'), toComputeAll(item)
                "
                @change="toComputeAll(item)"
                @blur="toComputeAll(item)"
              />
            </td>
          </tr>
          <tr>
            <td>
              <span class="color-red">*</span>

              {{ $t("changePriceLists.changePriceList.setUpPersons") }}
            </td>
            <td v-for="(item, index) in formData" :key="index">
              <el-input
                v-model="item.setPerson"
                :disabled="operateType == 'detail'"
                @input="
                  transferFormat($event, item, 'setPerson'), toComputeAll(item)
                "
                @change="toComputeAll(item)"
                @blur="toComputeAll(item)"
              />
            </td>
          </tr>

          <tr>
            <td>{{ $t("changePriceLists.changePriceList.piecesPerLot") }}</td>
            <td v-for="(item, index) in formData" :key="index">
              <el-input
                v-model="item.pieceLot"
                :disabled="operateType == 'detail' || type != 'alloperate'"
                @input="
                  transferFormat($event, item, 'pieceLot'), toComputeAll(item)
                "
                @change="toComputeAll(item)"
                @blur="toComputeAll(item)"
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t("changePriceLists.changePriceList.setUpCosts") }}</td>
            <td v-for="(item, index) in formData" :key="index">
              <el-input v-model="item.pieceCost" disabled />
            </td>
          </tr>

          <tr>
            <td>
              <span class="color-red">*</span>

              {{ $t("changePriceLists.changePriceList.descriptionSize") }}
            </td>
            <td v-for="(item, index) in formData" :key="index">
              <div class="flex" style="align-items: center; width: 100%">
                <el-input
                  v-model="item.equSize"
                  :disabled="operateType == 'detail'"
                  @input="transferFormat($event, item, 'equSize')"
                />
                <!-- v-show="type == 'alloperate' && operateType != 'detail'" -->
                <img
                  src="../../assets/img/tcm/daikuanjisuanqi.png"
                  style="width: 25px; height: 25px; cursor: pointer"
                  @click="openCal(index, 'equSize')"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <span class="color-red" v-show="type != 'template'">*</span>
              {{ $t("changePriceLists.changePriceList.consumption") }}
            </td>
            <td v-for="(item, index) in formData" :key="index">
              <el-input
                v-model="item.quantity"
                disabled
                @input="
                  transferFormat($event, item, 'quantity'), toComputeAll(item)
                "
                @change="toComputeAll(item)"
                @blur="toComputeAll(item)"
              />
            </td>
          </tr>
          <tr>
            <td>
              <span class="color-red" v-show="type != 'template'">*</span>
              {{ $t("changePriceLists.changePriceList.piecesHour") }}
            </td>
            <td v-for="(item, index) in formData" :key="index">
              <div class="flex" style="align-items: center; width: 100%">
                <el-input
                  v-model="item.pieceHour"
                  :disabled="operateType == 'detail'"
                  @input="
                    transferFormat($event, item, 'pieceHour'),
                      toComputeAll(item)
                  "
                  @change="toComputeAll(item)"
                  @blur="BlurPieceHour($event, item)"
                />
                <!-- onblur="if(!value || value <= 0) value = 1" -->

                <!-- v-show="type == 'alloperate' && operateType != 'detail'" -->
                <img
                  src="../../assets/img/tcm/daikuanjisuanqi.png"
                  style="width: 25px; height: 25px; cursor: pointer"
                  @click="openCal(index, 'pieceHour')"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <span class="color-red" v-show="type != 'template'">*</span>
              {{ $t("changePriceLists.changePriceList.piecesPer") }}
            </td>
            <td v-for="(item, index) in formData" :key="index">
              <el-input
                v-model="item.pieceCycle"
                :disabled="operateType == 'detail'"
                @input="transferFormat($event, item, 'pieceCycle')"
              />
            </td>
          </tr>
          <tr>
            <td>
              <span class="color-red">*</span>

              {{
                $t("changePriceLists.changePriceList.manufacturingEfficiency")
              }}
            </td>
            <td v-for="(item, index) in formData" :key="index">
              <el-input
                v-model="item.efficiencyPercent"
                :disabled="operateType == 'detail'"
                @input="
                  transferFormat($event, item, 'efficiencyPercent'),
                    toComputeAll(item)
                "
                @change="toComputeAll(item)"
                @blur="toComputeAll(item)"
              >
                <template #suffix>
                  <el-icon class="el-input__icon">%</el-icon>
                </template>
              </el-input>
            </td>
          </tr>
          <tr>
            <td>{{ $t("changePriceLists.changePriceList.standardHour") }}</td>
            <td v-for="(item, index) in formData" :key="index">
              <el-input v-model="item.standardHour" disabled />
            </td>
          </tr>
          <tr>
            <td>
              <span class="color-red">*</span>

              {{ $t("changePriceLists.changePriceList.directRate") }}
            </td>
            <td v-for="(item, index) in formData" :key="index">
              <el-input
                v-model="item.directRate"
                :disabled="operateType == 'detail'"
                @input="
                  transferFormat($event, item, 'directRate'), toComputeAll(item)
                "
                @change="toComputeAll(item)"
                @blur="toComputeAll(item)"
              />
            </td>
          </tr>
          <tr>
            <td>
              <span class="color-red">*</span>

              {{ $t("changePriceLists.changePriceList.setUpRate") }}
            </td>
            <td v-for="(item, index) in formData" :key="index">
              <el-input
                v-model="item.setUpRate"
                :disabled="operateType == 'detail'"
                @input="
                  transferFormat($event, item, 'setUpRate'), toComputeAll(item)
                "
                @change="toComputeAll(item)"
                @blur="toComputeAll(item)"
              />
            </td>
          </tr>
          <tr>
            <td>
              <span class="color-red">*</span>

              {{ $t("changePriceLists.changePriceList.directOperators") }}
            </td>
            <td v-for="(item, index) in formData" :key="index">
              <el-input
                v-model="item.directOperateQty"
                :disabled="operateType == 'detail'"
                @input="
                  transferFormat($event, item, 'directOperateQty'),
                    toComputeAll(item)
                "
                @change="toComputeAll(item)"
                @blur="toComputeAll(item)"
              />
            </td>
          </tr>
          <tr>
            <td>
              {{ $t("changePriceLists.changePriceList.directAssembly") }}
            </td>
            <td v-for="(item, index) in formData" :key="index">
              <el-input v-model="item.assemblyDirectCost" disabled />
            </td>
          </tr>
          <tr>
            <td>
              <span class="color-red" v-show="type != 'template'">*</span>

              {{ $t("changePriceLists.changePriceList.burdenVariable") }}
            </td>
            <td v-for="(item, index) in formData" :key="index">
              <el-input
                v-model="item.variableRate"
                :disabled="operateType == 'detail'"
                @input="
                  transferFormat($event, item, 'variableRate'),
                    toComputeAll(item)
                "
                @change="toComputeAll(item)"
                @blur="toComputeAll(item)"
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t("changePriceLists.changePriceList.burdenRate") }}</td>
            <td v-for="(item, index) in formData" :key="index">
              <el-input
                v-model="item.fixedRate"
                :disabled="operateType == 'detail'"
                @input="
                  transferFormat($event, item, 'fixedRate'), toComputeAll(item)
                "
                @change="toComputeAll(item)"
                @blur="toComputeAll(item)"
              />
            </td>
          </tr>
          <tr>
            <td>
              {{ $t("changePriceLists.changePriceList.burdenDepreciation") }}
            </td>
            <td v-for="(item, index) in formData" :key="index">
              <el-input
                v-model="item.depreciationRate"
                :disabled="operateType == 'detail'"
                @input="
                  transferFormat($event, item, 'depreciationRate'),
                    toComputeAll(item)
                "
                @change="toComputeAll(item)"
                @blur="toComputeAll(item)"
              />
            </td>
          </tr>
          <tr>
            <td>
              {{ $t("changePriceLists.changePriceList.totalBurdenRate") }}
            </td>
            <td v-for="(item, index) in formData" :key="index">
              <el-input v-model="item.totalBurdenRate" disabled />
            </td>
          </tr>
          <tr>
            <td>
              {{ $t("changePriceLists.changePriceList.burdenAssembly") }}
            </td>
            <td v-for="(item, index) in formData" :key="index">
              <el-input v-model="item.assemblyBurdenCost" disabled />
            </td>
          </tr>
          <tr>
            <td>{{ $t("changePriceLists.changePriceList.subTotalCost") }}</td>
            <td v-for="(item, index) in formData" :key="index">
              <el-input v-model="item.totalCost" disabled />
            </td>
          </tr>
          <tr>
            <td>
              <span class="color-red" v-show="type != 'template'">*</span>

              {{ $t("changePriceLists.changePriceList.processScrap") }}
            </td>
            <td v-for="(item, index) in formData" :key="index">
              <el-input
                v-model="item.scrapPercent"
                :disabled="operateType == 'detail'"
                @input="
                  transferFormat($event, item, 'scrapPercent'),
                    toComputeAll(item)
                "
                @change="toComputeAll(item)"
                @blur="toComputeAll(item)"
              >
                <template #suffix>
                  <el-icon class="el-input__icon">%</el-icon>
                </template>
              </el-input>
            </td>
          </tr>

          <tr>
            <td>{{ $t("changePriceLists.changePriceList.vendor") }}</td>
            <td v-for="(item, index) in formData" :key="index">
              <el-input
                v-model="item.vendor"
                :disabled="operateType == 'detail'"
              />
            </td>
          </tr>
        </table>
      </div>
      <div
        class="add-column"
        v-show="type != 'template' && operateType != 'detail'"
      >
        <div @click="addMoudle">
          {{ $t("changePriceLists.changePriceList.fromTemplate") }}
        </div>
        <div @click="addEmptyMoudle" style="border-top: 1px solid #ccc">
          {{ $t("changePriceLists.changePriceList.materialInput") }}
        </div>
      </div>
    </div>

    <CalculatorList
      ref="calList"
      :applyId="applyId"
      type="edit"
      @chooseRow="chooseRow"
      @submitPlastic="submitPlastic"
    ></CalculatorList>
    <PreviewDia
      ref="preDia"
      type="template"
      :formulaPre="outform.formulaPre"
      :previewResultx="previewResultx"
      :fileListUrl="fileListUrl"
      :intermediateBondList="intermediateBondListPreview"
      :valueList="valueList"
      @tocompute="tocompute"
      @toConfirm="toConfirm"
    ></PreviewDia>
    <ProDia
      ref="proDialog"
      :CommodityId="CommodityId"
      :CurrencyOptions="CurrencyOptions"
      @getChooseProId="getChooseProId"
    ></ProDia>
    <AddRate ref="rateDialog" @rateSubmit="rateSubmit"></AddRate>
  </div>
</template>
<script>
import {
  GetCalculatorDetail,
  InputParameterAnalysis,
  getTemplateProcesssDetail,
  getMultiRate,
} from "../../assets/js/requestApi.js";
import commonUtil from "../../assets/js/commonUtil.js";
import CalculatorList from "../processCalculatorManagement/calculatorListDia.vue";
import PreviewDia from "../processCalculatorManagement/previewDia.vue";
import ProDia from "../processingManagement/proDia.vue";
import AddRate from "./addRate.vue";
export default {
  props: [
    "type",
    "operateType",
    "CommodityId",
    "CommodityName",
    "CommodityOptions",
    "regionOptions",
    "CurrencyOptions",
    "currencyId",
    "applyId",
    "width",
  ],
  emits: ["goBack", "confirmData"],
  components: {
    CalculatorList,
    PreviewDia,
    ProDia,
    AddRate,
  },
  data() {
    return {
      cycleLst: [],
      laborCost_PRO: "",
      setUpCost_PRO: "",
      burdenCost_PRO: "",
      manufacturingCost_PRO: "",
      formData: [
        {
          id: "",
          number: "",
          name: "",
          version: "",
          vendor: "",
          comment: "",
          isEnable: 1,
          annualVolume: "",
          setTime: "",
          setPerson: "",
          pieceLot: "",
          pieceCost: "",
          processName: "",
          equSize: "",
          quantity: "1",
          pieceHour: 1,
          pieceCycle: "",
          efficiencyPercent: "",
          standardHour: "",
          directRate: "",
          setUpRate: "",
          directOperateQty: "",
          assemblyDirectCost: "",
          variableRate: "0",
          fixedRate: "0",
          depreciationRate: "0",
          totalBurdenRate: "",
          assemblyBurdenCost: "",
          totalCost: "",
          scrapPercent: "0",
          commodityId: "",
          regionId: "",
          currencyId: "",
          convertCurrencyId: "",
          converRate: "",
          cLines: [],
          calculateData: "",
          descShow: false,
          setShow: false,
          opeShow: true,
        },
      ],
      // 商品分类显示字段
      defaultProps: {
        children: "children",
        label: "Name",
      },
      // 工艺计算器详细数据
      form: {
        id: "",
        process_Calculator_Code: "",
        name: "",
        commodityId: "",
        commodityName: "",
        version: "",
        comments: "",
      },
      // 上传的文件
      fileListx: [],
      fileListUrl: [],
      // 表格内容
      tableData: [],
      outform: {
        name: "",
        unit: "",
        formula: "",
        formulaVal: "",
        formulaPre: "",
      },
      // 符号
      symbol: ["+", "-", "*", "/", "sum", "if", "(", ")"],
      // 计算结果
      previewResultx: 0,
      // 打开计算器输入框类型
      calType: "input",
      // 中间键列表
      intermediateBondList: [],
      // 计算后中间键列表
      intermediateBondListPreview: [],
      // 数值列表
      valueList: [],
      // 当前操作数据下标
      operateIndex: "",
      // 当前操作数据字段名
      operateName: "",
      // 当前选择的工艺计算器id
      operateCalId: "",
      // 编辑时删除的组件id
      processDelIds: [],
      // Sub-Total Cost/Burden Cost / Assembly乘积之和
      sbSum: 0,
      // 选择的组件税率转化数组
      addCellExchangeList: [],
      // 选择的组件数据
      addCellList: [],
      // 计算调用参数
      computeNum: 0,
      // 操作时申请单中修改的值
      annualVolumeNew: "",
      pieceLotNew: "",
    };
  },
  watch: {
    computeNum: {
      handler(nval, oval) {
        if (nval == this.intermediateBondListPreview.length) {
          this.$refs.preDia.visible = true;
        }
      },
    },
  },
  methods: {
    // 取消
    cancle() {
      this.$emit("goBack");
    },
    // 提交
    confirm() {
      this.formData[0].convertCurrencyId = this.formData[0].currencyId;
      this.formData[0].converRate = "1";
      this.$emit("confirmData", this.formData);
    },
    // 打开计算器
    // openCal(index, type) {
    //     if (this.operateType == "detail") {
    //         return;
    //     }
    //     this.operateIndex = index;
    //     this.operateName = type;
    //     this.$refs.calList.getlist();
    //     this.$refs.calList.getTreeData();
    //     this.$refs.calList.visible = true;
    // },
    openCal(index, name) {
      let _this = this;
      if (this.operateType == "detail") {
        return;
      }
      this.operateIndex = index;
      this.operateName = name;
      this.$refs.calList.operateName = name;
      let form = this.formData[index].calculateData
        ? JSON.parse(this.formData[index].calculateData)
        : [];
      this.$refs.calList.getTreeData();
      this.$refs.calList.visible = true;
      if (form.length > 0) {
        this.$refs.calList.getlist(1);
        form.forEach(function (val, index) {
          if (val.operateName == name) {
            _this.$refs.calList.setData(val);
          }
        });
      } else {
        this.$refs.calList.getlist(2);
        this.$refs.calList.activeName = "first";
        this.$refs.calList.activeRadio = "tonnage";
      }
    },
    // 获取选择的工艺计算器
    chooseRow(row) {
      this.operateCalId = row.id;
      this.getDetail(row.id);
    },
    // 获取选择的plastic的值
    submitPlastic(value, form) {
      this.$refs.calList.visible = false;
      this.formData[this.operateIndex][this.operateName] = value;
      if (value !== "") {
        if (this.formData[this.operateIndex].calculateData === "") {
          let calculateData = [];
          calculateData.push(form);
          this.formData[this.operateIndex].calculateData =
            JSON.stringify(calculateData);
        } else {
          let calculateData = JSON.parse(
            this.formData[this.operateIndex].calculateData
          );
          let newCalculateData = [];
          // 是否包含当前数据
          let isContain = false;
          let containIndex = "";
          calculateData.forEach(function (val, index) {
            newCalculateData.push(val);
            if (val.operateName == form.operateName) {
              isContain = true;
              containIndex = index;
            }
          });
          if (isContain) {
            newCalculateData[containIndex] = form;
          } else {
            newCalculateData.push(form);
          }
          this.formData[this.operateIndex].calculateData =
            JSON.stringify(newCalculateData);
        }
      }

      this.toComputeAll(this.formData[this.operateIndex]);
    },
    // 根据id获取选择的工艺计算器详情
    getDetail(id) {
      let _this = this;
      this.computeNum = 0;
      GetCalculatorDetail('"' + id + '"', (response) => {
        if (response && response.code == 200) {
          this.form = {
            id: response.data[0].id,
            process_Calculator_Code: response.data[0].process_Calculator_Code,
            name: response.data[0].process_Calculator_Name,
            commodityId: response.data[0].commodityId,
            commodityName: response.data[0].commodList[0].name,
            version: response.data[0].version,
            comments: response.data[0].comments,
          };
          _this.intermediateBondList = [];
          _this.valueList = [];
          this.tableData = response.data[0].edit_List;
          this.tableData.forEach(function (val, key) {
            val.formulaVal = val.formulaPre
              ? val.formulaPre.replace(/\|/g, "")
              : "";
            if (val.formula != "") {
              _this.intermediateBondList.push(val);
            } else {
              _this.valueList.push(val);
            }
          });
          this.intermediateBondListPreview = JSON.parse(
            JSON.stringify(this.intermediateBondList)
          );
          this.outform = {
            name: response.data[0].outputName,
            unit: response.data[0].outputUnit,
            formula: response.data[0].outputFormulaPre.replace(/\|/g, ""),
            formulaVal: response.data[0].outputFormula,
            formulaPre: response.data[0].outputFormulaPre,
          };
          this.fileListx = response.data[0].pictureList;
          // 计算默认值
          let list = [];
          this.valueList.forEach(function (val, key) {
            list.push({
              paramValue: val.thedefaultvalue,
              paramentName: val.parameterName,
            });
          });
          this.intermediateBondList.forEach(function (val, key) {
            list.push({
              paramValue: val.formulaPre,
              paramentName: val.parameterName,
            });
          });

          this.intermediateBondListPreview.forEach(function (val, key) {
            // val.formulaPre
            let data = {
              outputFormula: val.formulaPre,
              inputParameterList: list,
            };
            InputParameterAnalysis(data, (response) => {
              _this.computeNum++;
              if (response && response.code == 200) {
                val.thedefaultvalue = response.data;
              }
            });
          });
          this.previewResultx = 0;
          if (this.intermediateBondListPreview.length == 0) {
            this.$refs.preDia.visible = true;
          }
          // let data = {
          //     outputFormula: this.outform.formulaPre,
          //     inputParameterList: list,
          // };
          // this.$refs.preDia.visible = true;
          // this.tocompute(data)
        }
      });
    },
    // 计算结果
    tocompute(val) {
      InputParameterAnalysis(val, (response) => {
        if (response && response.code == 200) {
          this.previewResultx = response.data;
          this.$refs.preDia.visible = true;
        }
      });
    },
    // 获取最后计算的值
    toConfirm(val) {
      this.$refs.calList.visible = false;
      this.formData[this.operateIndex][this.operateName] = val;
      let row = {};
      if (this.operateName == "equSize") {
        row = {
          fieldName: "equSize",
          calculatorTableId: this.operateCalId,
        };
      } else if (this.operateName == "pieceHour") {
        row = {
          fieldName: "pieceHour",
          calculatorTableId: this.operateCalId,
        };
      }
      if (this.formData[this.operateIndex].cLines.indexOf(row) > -1) {
        let index = this.formData[this.operateIndex].cLines.indexOf(row);
        this.formData[this.operateIndex].cLines[index] = row;
      } else {
        this.formData[this.operateIndex].cLines.push(row);
      }
      this.toComputeAll(this.formData[this.operateIndex]);
    },
    // 添加组件
    addMoudle() {
      if (
        this.CommodityId == "" ||
        this.CommodityId == null ||
        !this.CommodityId
      ) {
        commonUtil.showTipInfo(
          this.$t("templateLists.templateList.commodityRequired"),
          "",
          "warning"
        );
        return;
      }
      if (
        this.currencyId == "" ||
        this.currencyId == null ||
        !this.currencyId
      ) {
        commonUtil.showTipInfo(
          this.$t("templateLists.templateList.currencyRequired"),
          "",
          "warning"
        );
        return;
      }
      this.$refs.proDialog.searchForm.page = 1;
      this.$refs.proDialog.getlist();
      this.$refs.proDialog.visible = true;
    },
    // 添加空组件
    addEmptyMoudle() {
      this.formData.push({
        id: "",
        number: "",
        name: "",
        version: "",
        vendor: "",
        comment: "",
        isEnable: 1,
        annualVolume: this.annualVolumeNew === "" ? "" : this.annualVolumeNew,
        setTime: "",
        setPerson: "",
        pieceLot: this.pieceLotNew === "" ? "" : this.pieceLotNew,
        pieceCost: "",
        processName: "",
        equSize: "",
        quantity: "1",
        pieceHour: 1,
        pieceCycle: "",
        efficiencyPercent: "",
        standardHour: "",
        directRate: "",
        setUpRate: "",
        directOperateQty: "",
        assemblyDirectCost: "",
        variableRate: "0",
        fixedRate: "0",
        depreciationRate: "0",
        totalBurdenRate: "",
        assemblyBurdenCost: "",
        totalCost: "",
        scrapPercent: "0",
        commodityId: "",
        regionId: "",
        currencyId: this.currencyId ? this.currencyId : "",
        convertCurrencyId: this.currencyId ? this.currencyId : "",
        converRate: this.currencyId ? "1" : "",
        exchangeRate: this.currencyId ? "1" : "",
        cLines: [],
        calculateData: "",
        descShow: false,
        setShow: false,
        opeShow: true,
      });
      this.$nextTick(() => {
        let outDiv = document.getElementById("ProId");
        const scrollLeft = this.formData.length * 221 + 201;
        outDiv.scrollTo(scrollLeft, 0);
      });
    },
    // 获取添加组件的详情信息
    getChooseProId(id) {
      let _this = this;
      getTemplateProcesssDetail(
        {
          id: id,
        },
        (response) => {
          if (response && response.code == 200) {
            this.getMultiRates(response.result);
            // this.CommodityId = response.result.commodityId
            // response.result.forEach(function (resVal, resIndex) {
            //     let cLines = [];
            //     resVal.cLines.forEach(function (val, index) {
            //         cLines.push({
            //             fieldName: val.paramName,
            //             calculatorTableId: val.calculator_TableId,
            //         });
            //     });
            //     let moudle = resVal;
            //     moudle.id = "";
            //     moudle.cLines = cLines;
            //     moudle.name = moudle.processName;
            //     if (_this.type == "alloperate") {
            //         moudle.annualVolume =
            //             _this.$parent.$refs.gen.genList[0].peakVolume;
            //         moudle.pieceLot =
            //             _this.$parent.$refs.gen.form.lotSize;
            //     }
            //     _this.formData.push(moudle);
            //     _this.toComputeAll(moudle);
            // });
            // this.$refs.proDialog.visible = false;
          }
        }
      );
    },
    // 获取选择组件税率对应关系
    getMultiRates(arr) {
      let _this = this;
      let multiRow = [];
      arr.forEach(function (resVal, resIndex) {
        let cLines = [];
        resVal.cLines.forEach(function (val, index) {
          cLines.push({
            fieldName: val.paramName,
            calculatorTableId: val.calculator_TableId,
          });
        });
        let moudle = resVal;
        // moudle.id = "";
        moudle.cLines = cLines;
        moudle.name = moudle.processName;
        if (_this.type == "alloperate") {
          moudle.annualVolume = _this.$parent.$refs.gen.genList[0].peakVolume;
          moudle.pieceLot = _this.$parent.$refs.gen.form.lotSize;
        }
        moudle.currencyId = _this.currencyId;
        moudle.currId = moudle.convertCurrencyId;
        moudle.currCNYId = _this.currencyId;
        moudle.exchangeRate = "";
        moudle.calculateData = "";
        moudle.descShow = false;
        moudle.setShow = false;
        moudle.opeShow = true;
        multiRow.push(moudle);
      });
      _this.addCellList = multiRow;
      this.$refs.proDialog.visible = false;
      getMultiRate(
        {
          genList: multiRow,
        },
        (response) => {
          if (response && response.code == 200) {
            _this.addCellExchangeList = [];
            let untaxedNum = 0;
            _this.addCellExchangeList = response.data[0].exchangeList;
            _this.addCellExchangeList.forEach(function (excVal, excIndex) {
              if (excVal.exchangeRate === "") {
                excVal.hasRate = false;
                untaxedNum++;
              } else {
                excVal.hasRate = true;
              }
            });
            // 如果其中有无税率的情况则弹窗输入
            if (untaxedNum > 0) {
              _this.$refs.rateDialog.addCellExchangeList =
                _this.addCellExchangeList;
              _this.$refs.rateDialog.visible = true;
            } else {
              // 如果税率都有则转换
              _this.$refs.rateDialog.visible = false;
              _this.addCellExchangeList.forEach(function (rateVal, rateIndex) {
                _this.addCellList.forEach(function (cellVal, cellIndex) {
                  if (rateVal.id == cellVal.id) {
                    cellVal.exchangeRate = rateVal.exchangeRate;
                    cellVal.converRate = rateVal.exchangeRate;
                    cellVal.variableRate =
                      parseFloat(
                        cellVal.variableRate === "" ||
                          cellVal.variableRate === null ||
                          !cellVal.variableRate
                          ? "0"
                          : cellVal.variableRate
                      ) *
                      (parseFloat(cellVal.exchangeRate) === 0
                        ? 1
                        : parseFloat(cellVal.exchangeRate));
                    cellVal.fixedRate =
                      parseFloat(
                        cellVal.fixedRate === "" ||
                          cellVal.fixedRate === null ||
                          !cellVal.fixedRate
                          ? "0"
                          : cellVal.fixedRate
                      ) *
                      (parseFloat(cellVal.exchangeRate) === 0
                        ? 1
                        : parseFloat(cellVal.exchangeRate));
                    cellVal.depreciationRate =
                      parseFloat(
                        cellVal.depreciationRate === "" ||
                          cellVal.depreciationRate === null ||
                          !cellVal.depreciationRate
                          ? "0"
                          : cellVal.depreciationRate
                      ) *
                      (parseFloat(cellVal.exchangeRate) === 0
                        ? 1
                        : parseFloat(cellVal.exchangeRate));
                  }
                });
              });
              _this.addCellTransfer();
            }
          }
        }
      );
    },
    // 填写完税率后获取并且转化数据
    rateSubmit(rateList) {
      let _this = this;
      rateList.forEach(function (rateVal, rateIndex) {
        _this.addCellList.forEach(function (cellVal, cellIndex) {
          if (rateVal.id == cellVal.id) {
            cellVal.exchangeRate = rateVal.exchangeRate;
            cellVal.converRate = rateVal.exchangeRate;
            cellVal.variableRate =
              parseFloat(
                cellVal.variableRate === "" ||
                  cellVal.variableRate === null ||
                  !cellVal.variableRate
                  ? "0"
                  : cellVal.variableRate
              ) *
              (parseFloat(cellVal.exchangeRate) === 0
                ? 1
                : parseFloat(cellVal.exchangeRate));
            cellVal.fixedRate =
              parseFloat(
                cellVal.fixedRate === "" ||
                  cellVal.fixedRate === null ||
                  !cellVal.fixedRate
                  ? "0"
                  : cellVal.fixedRate
              ) *
              (parseFloat(cellVal.exchangeRate) === 0
                ? 1
                : parseFloat(cellVal.exchangeRate));
            cellVal.depreciationRate =
              parseFloat(
                cellVal.depreciationRate === "" ||
                  cellVal.depreciationRate === null ||
                  !cellVal.depreciationRate
                  ? "0"
                  : cellVal.depreciationRate
              ) *
              (parseFloat(cellVal.exchangeRate) === 0
                ? 1
                : parseFloat(cellVal.exchangeRate));
          }
        });
      });
      _this.$refs.rateDialog.visible = false;
      this.addCellTransfer();
    },
    // 添加转化税率后的组件并重新计算
    addCellTransfer() {
      let _this = this;
      _this.addCellList.forEach(function (resVal, resIndex) {
        resVal.id = "";
        _this.formData.push(resVal);
      });
      this.formData.forEach(function (val, index) {
        _this.toComputeAll(val);
      });
      // this.toComputeByrate(_this.formData)
    },
    // 税率转换后计算各个值
    toComputeByrate(arr) {
      let _this = this;
      _this.laborCost_PRO = 0;
      _this.setUpCost_PRO = 0;
      _this.burdenCost_PRO = 0;
      _this.manufacturingCost_PRO = 0;
      _this.sbSum = 0;
      arr.forEach(function (val, index) {
        _this.laborCost_PRO += parseFloat(
          val.assemblyDirectCost === "" ||
            val.assemblyDirectCost === null ||
            !val.assemblyDirectCost
            ? 0
            : val.assemblyDirectCost
        );
        _this.burdenCost_PRO += parseFloat(
          val.assemblyBurdenCost === "" ||
            val.assemblyBurdenCost === null ||
            !val.assemblyBurdenCost
            ? 0
            : val.assemblyBurdenCost
        );
        _this.setUpCost_PRO += parseFloat(
          val.pieceCost === "" || val.pieceCost === null || !val.pieceCost
            ? 0
            : val.pieceCost
        );
        if (_this.type != "template") {
          _this.manufacturingCost_PRO =
            parseFloat(_this.laborCost_PRO === "" ? 0 : _this.laborCost_PRO) +
            parseFloat(_this.burdenCost_PRO) +
            parseFloat(_this.setUpCost_PRO) +
            parseFloat(
              _this.$parent.$refs.rwTcm.materialCost_RM === ""
                ? 0
                : _this.$parent.$refs.rwTcm.materialCost_RM
            ) +
            parseFloat(
              _this.$parent.$refs.purTcm.purchaseCost_PUR === ""
                ? 0
                : _this.$parent.$refs.purTcm.purchaseCost_PUR
            );
          _this.sbSum +=
            parseFloat(val.scrapPercent == "" ? 0 : val.scrapPercent / 100) *
            parseFloat(val.totalCost == "" ? 0 : val.totalCost);
        }
        val.pieceCost =
          val.pieceCost == "" || !val.pieceCost
            ? ""
            : parseFloat(val.pieceCost).toFixed(12);
        val.standardHour =
          val.standardHour == "" || !val.standardHour
            ? ""
            : parseFloat(val.standardHour).toFixed(12);
        val.assemblyDirectCost =
          val.assemblyDirectCost === "" ||
          !val.assemblyDirectCost ||
          val.assemblyDirectCost === null
            ? ""
            : parseFloat(val.assemblyDirectCost).toFixed(12);
        val.totalCost =
          val.totalCost == "" || !val.totalCost || val.totalCost === null
            ? ""
            : parseFloat(val.totalCost).toFixed(12);
        val.totalBurdenRate =
          val.totalBurdenRate == "" ||
          !val.totalBurdenRate ||
          val.totalBurdenRate === null
            ? ""
            : parseFloat(val.totalBurdenRate).toFixed(12);
        val.assemblyBurdenCost =
          val.assemblyBurdenCost == "" ||
          !val.assemblyBurdenCost ||
          val.assemblyBurdenCost === null
            ? ""
            : parseFloat(val.assemblyBurdenCost).toFixed(12);
      });
      _this.laborCost_PRO = _this.laborCost_PRO.toFixed(12);
      _this.burdenCost_PRO = _this.burdenCost_PRO.toFixed(12);
      _this.setUpCost_PRO = _this.setUpCost_PRO.toFixed(12);
      _this.manufacturingCost_PRO = _this.manufacturingCost_PRO.toFixed(12);
      _this.sbSum = _this.sbSum.toFixed(12);
      if (_this.type != "template") {
        _this.$parent.$refs.ohTcm.tocompute();
      }
    },
    // 删除组件
    deleteMoudle(index) {
      if (this.processDelIds.indexOf(this.formData[index].id) > -1) {
        this.processDelIds.splice(
          this.processDelIds.indexOf(this.formData[index].id),
          1
        );
      } else {
        this.processDelIds.push(this.formData[index].id);
      }
      this.formData.splice(index, 1);
      if (this.formData.length > 0) {
        this.toComputeAll(this.formData[0]);
      } else {
        this.laborCost_PRO = 0;
        this.setUpCost_PRO = 0;
        this.burdenCost_PRO = 0;
        this.manufacturingCost_PRO = 0;
        if (this.type != "template") {
          this.$parent.$refs.ohTcm.tocompute();
        }
      }
    },
    // 复制组件
    copyMoudle(index) {
      let data = JSON.parse(JSON.stringify(this.formData[index]));
      data.id = "";
      data.descShow = false;
      data.setShow = false;
      data.opeShow = true;
      this.formData.push(data);
      if (this.formData.length > 0) {
        this.toComputeAll(this.formData[0]);
      }
      this.$nextTick(() => {
        let outDiv = document.getElementById("ProId");
        const scrollLeft = this.formData.length * 221 + 201;
        outDiv.scrollTo(scrollLeft, 0);
      });
    },

    // 计算
    toComputeAll(row) {
      let _this = this;
      if (row) {
        // 计算Set Up Costs Per Piece
        // if (
        //     row.setTime !== "" &&
        //     row.pieceLot !== "" &&
        //     row.quantity !== "" &&
        //     row.setPerson !== "" &&
        //     row.setUpRate !== "" &&
        //     row.variableRate !== "" &&
        //     row.fixedRate !== "" &&
        //     row.depreciationRate !== ""
        // ) {
        if (
          row.setTime === 0 ||
          row.setTime === "" ||
          row.setTime === null ||
          row.pieceLot === "" ||
          row.pieceLot === null ||
          !row.pieceLot
        ) {
          row.pieceCost = 0;
        } else if (row.setTime >= 0) {
          row.pieceCost = (
            (parseFloat(row.setTime) /
              60 /
              parseFloat(
                row.pieceLot == 0 ||
                  !row.pieceLot ||
                  row.pieceLot === "" ||
                  row.pieceLot === null
                  ? 1
                  : row.pieceLot
              ) /
              parseFloat(
                row.quantity == 0 ||
                  !row.quantity ||
                  row.quantity === "" ||
                  row.quantity === null
                  ? 1
                  : row.quantity
              )) *
            (parseFloat(
              (row.setPerson === "" || row.setPerson === null || !row.setPerson
                ? 1
                : row.setPerson) *
                (row.setUpRate === "" ||
                row.setUpRate === null ||
                !row.setUpRate
                  ? 1
                  : row.setUpRate)
            ) +
              parseFloat(
                row.variableRate === "" ||
                  row.variableRate === null ||
                  !row.variableRate
                  ? 0
                  : row.variableRate
              ) +
              parseFloat(
                row.fixedRate === null || row.fixedRate === "" || !row.fixedRate
                  ? 0
                  : row.fixedRate
              ) +
              parseFloat(
                row.depreciationRate === null ||
                  row.depreciationRate === "" ||
                  !row.depreciationRate
                  ? 0
                  : row.depreciationRate
              ))
          ).toString();
        }
        // }
        if (
          row.pieceHour !== "" &&
          row.pieceHour !== 0 &&
          row.efficiencyPercent !== "" &&
          row.pieceHour !== null &&
          row.efficiencyPercent !== null
        ) {
          row.standardHour =
            1 /
            parseFloat(row.pieceHour == 0 ? 1 : row.pieceHour) /
            parseFloat(
              row.efficiencyPercent == 0 ? 1 : row.efficiencyPercent / 100
            );
        }
        if (
          row.standardHour === "" ||
          row.standardHour === null ||
          row.directRate === "" ||
          row.directRate === null
        ) {
          row.assemblyDirectCost = 0;
        } else {
          row.assemblyDirectCost =
            parseFloat(row.standardHour) *
            parseFloat(row.directRate) *
            parseFloat(
              row.directOperateQty === "" ||
                row.directOperateQty === null ||
                !row.directOperateQty
                ? 0
                : row.directOperateQty
            );
        }
        row.assemblyBurdenCost = (
          (parseFloat(
            row.variableRate === "" || row.variableRate === null
              ? 0
              : row.variableRate
          ) +
            parseFloat(
              row.fixedRate === "" || row.fixedRate === null ? 0 : row.fixedRate
            ) +
            parseFloat(
              row.depreciationRate === "" || row.depreciationRate === null
                ? 0
                : row.depreciationRate
            )) *
          parseFloat(
            row.standardHour === "" || row.standardHour === null
              ? 0
              : row.standardHour
          )
        ).toString();
        row.totalCost = (
          parseFloat(
            row.assemblyDirectCost === "" || row.assemblyDirectCost === null
              ? 0
              : row.assemblyDirectCost
          ) +
          parseFloat(
            row.assemblyBurdenCost === "" || row.assemblyBurdenCost === null
              ? 0
              : row.assemblyBurdenCost
          )
        ).toString();
        // }
        if (
          row.variableRate != "" &&
          row.fixedRate != "" &&
          row.depreciationRate != ""
        ) {
          row.totalBurdenRate = (
            parseFloat(row.variableRate === null ? 0 : row.variableRate) +
            parseFloat(row.fixedRate === null ? 0 : row.fixedRate) +
            parseFloat(row.depreciationRate === null ? 0 : row.depreciationRate)
          ).toString();
        }
        if (row.pieceHour == 0) {
          row.totalBurdenRate = "0";
          row.assemblyBurdenCost = "0";
          row.totalCost = "0";
        }
      }

      // assemblyDirectCost
      _this.laborCost_PRO = 0;
      _this.setUpCost_PRO = 0;
      _this.burdenCost_PRO = 0;
      _this.manufacturingCost_PRO = 0;
      _this.sbSum = 0;
      this.formData.forEach(function (val, index) {
        _this.laborCost_PRO += parseFloat(
          val.assemblyDirectCost === "" ||
            val.assemblyDirectCost === null ||
            !val.assemblyDirectCost
            ? 0
            : val.assemblyDirectCost
        );
        _this.burdenCost_PRO += parseFloat(
          val.assemblyBurdenCost === "" ||
            val.assemblyBurdenCost === null ||
            !val.assemblyBurdenCost
            ? 0
            : val.assemblyBurdenCost
        );
        _this.setUpCost_PRO += parseFloat(
          val.pieceCost == "" || !val.pieceCost || val.pieceCost === null
            ? 0
            : val.pieceCost
        );
        if (_this.type != "template") {
          _this.manufacturingCost_PRO =
            parseFloat(_this.laborCost_PRO === "" ? 0 : _this.laborCost_PRO) +
            parseFloat(_this.burdenCost_PRO) +
            parseFloat(_this.setUpCost_PRO) +
            parseFloat(
              _this.$parent.$refs.rwTcm.materialCost_RM === ""
                ? 0
                : _this.$parent.$refs.rwTcm.materialCost_RM
            ) +
            parseFloat(
              _this.$parent.$refs.purTcm.purchaseCost_PUR === ""
                ? 0
                : _this.$parent.$refs.purTcm.purchaseCost_PUR
            );
          _this.sbSum +=
            parseFloat(val.scrapPercent == "" ? 0 : val.scrapPercent / 100) *
            parseFloat(val.totalCost == "" ? 0 : val.totalCost);
        }
        val.pieceCost =
          val.pieceCost == "" || !val.pieceCost
            ? ""
            : parseFloat(val.pieceCost).toFixed(12);
        val.standardHour =
          val.standardHour == "" || !val.standardHour
            ? ""
            : parseFloat(val.standardHour).toFixed(12);
        val.assemblyDirectCost =
          val.assemblyDirectCost === "" ||
          !val.assemblyDirectCost ||
          val.assemblyDirectCost === null
            ? ""
            : parseFloat(val.assemblyDirectCost).toFixed(12);
        val.totalCost =
          val.totalCost == "" || !val.totalCost || val.totalCost === null
            ? ""
            : parseFloat(val.totalCost).toFixed(12);
        val.totalBurdenRate =
          val.totalBurdenRate == "" || !val.totalBurdenRate
            ? ""
            : parseFloat(val.totalBurdenRate).toFixed(12);
        val.assemblyBurdenCost =
          val.assemblyBurdenCost == "" || !val.assemblyBurdenCost
            ? ""
            : parseFloat(val.assemblyBurdenCost).toFixed(12);
      });
      _this.laborCost_PRO = _this.laborCost_PRO.toFixed(12);
      _this.burdenCost_PRO = _this.burdenCost_PRO.toFixed(12);
      _this.setUpCost_PRO = _this.setUpCost_PRO.toFixed(12);
      _this.manufacturingCost_PRO = _this.manufacturingCost_PRO.toFixed(12);
      if (_this.$parent.$refs.sumTcm) {
        _this.$parent.$refs.sumTcm.form.manufacturingCost_PRO =
          _this.manufacturingCost_PRO;
      }
      _this.sbSum = _this.sbSum.toFixed(12);
      if (_this.type != "template") {
        _this.$parent.$refs.ohTcm.tocompute();
      }
    },
    // 修改分类
    changeCommodityId(val, index) {
      this.formData[index].commodityId = val;
    },
    // 切换顺序
    reorder(arrow, index) {
      if (index == 0 && arrow == "left") {
        commonUtil.showTipInfo(
          this.$t("templateLists.templateList.firstPlace"),
          "",
          "warning"
        );
        return;
      }
      if (index == this.formData.length - 1 && arrow == "right") {
        commonUtil.showTipInfo(
          this.$t("templateLists.templateList.endPlace"),
          "",
          "warning"
        );
        return;
      }
      if (arrow == "left") {
        [this.formData[index - 1], this.formData[index]] = [
          this.formData[index],
          this.formData[index - 1],
        ];
        this.$forceUpdate();
      } else if (arrow == "right") {
        [this.formData[index + 1], this.formData[index]] = [
          this.formData[index],
          this.formData[index + 1],
        ];
        this.$forceUpdate();
      }
    },
    // 限制
    transferFormat(val, item, name) {
      if (name != "equSize") {
        item[name] = val.replace(/^\D*(\d*(?:\.\d{0,12})?).*$/g, "$1");

        if (name === "converRate" && item[name] !== "") {
          if (this.type != "template" && this.currencyId === "") {
            commonUtil.showTipInfo(
              this.$t("templateLists.templateList.currencyRequired"),
              "",
              "warning"
            );
            return;
          }
          let exchangeList = item.exchangeRate.split(".");
          let converRateList = item[name].split(".");
          item.variableRate =
            (parseFloat(
              item.variableRate === "" ||
                item.variableRate === null ||
                !item.variableRate
                ? "0"
                : item.variableRate
            ) /
              parseFloat(
                item.exchangeRate === 0 ||
                  item.exchangeRate === "0" ||
                  (exchangeList.length == 2 && exchangeList[1] == "")
                  ? 1
                  : item.exchangeRate
              )) *
            (parseFloat(item[name]) === 0 ||
            (converRateList.length == 2 && converRateList[1] == "")
              ? 1
              : parseFloat(item[name]));
          item.fixedRate =
            (parseFloat(
              item.fixedRate === "" ||
                item.fixedRate === null ||
                !item.fixedRate
                ? "0"
                : item.fixedRate
            ) /
              parseFloat(
                item.exchangeRate === 0 ||
                  item.exchangeRate === "0" ||
                  (exchangeList.length == 2 && exchangeList[1] == "")
                  ? 1
                  : item.exchangeRate
              )) *
            (parseFloat(item[name]) === 0 ||
            (converRateList.length == 2 && converRateList[1] == "")
              ? 1
              : parseFloat(item[name]));
          item.depreciationRate =
            (parseFloat(
              item.depreciationRate === "" ||
                item.depreciationRate === null ||
                !item.depreciationRate
                ? "0"
                : item.depreciationRate
            ) /
              parseFloat(
                item.exchangeRate === 0 ||
                  item.exchangeRate === "0" ||
                  (exchangeList.length == 2 && exchangeList[1] == "")
                  ? 1
                  : item.exchangeRate
              )) *
            (parseFloat(item[name]) === 0 ||
            (converRateList.length == 2 && converRateList[1] == "")
              ? 1
              : parseFloat(item[name]));
          item.exchangeRate = item[name];
        }
      }
      if (name === "equSize" || name === "pieceHour") {
        if (item.calculateData !== "") {
          let delIndex = "";
          let calculateData = JSON.parse(item.calculateData);
          calculateData.forEach(function (calValue, calIndex) {
            if (calValue.operateName == name) {
              delIndex = calIndex;
            }
          });
          if (delIndex !== "") {
            calculateData.splice(delIndex, 1);
            item.calculateData = JSON.stringify(calculateData);
          }
        }
      }
    },
    // 展开收缩
    hideCell(index, operateType, cell) {
      if (cell == "desc") {
        this.formData[index].descShow = operateType == "hide" ? false : true;
      }
      if (cell == "set") {
        this.formData[index].setShow = operateType == "hide" ? false : true;
      }
      if (cell == "ope") {
        this.formData[index].opeShow = operateType == "hide" ? false : true;
      }
    },
    BlurPieceHour(e, item) {
      if (!e.target.value || e.target.value <= 0) {
        e.target.value = 1;
        item.pieceHour = 1;
      }
      this.toComputeAll(item);
    },
  },
};
</script>

<style scoped lang="scss">
.bottom-box {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.handle-btn {
  color: #fff;
  width: 200px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #2f4cdd;
  border-radius: 10px;
  cursor: pointer;
}
.Raw-box {
  display: flex;
  flex-wrap: wrap;
}
.Raw-card {
  margin-right: 15px;
  margin-top: 15px;
  padding: 10px 35px 0px 20px;
  width: 30%;
  border-radius: 10px;
  color: #363b64;
  background: #f2f3f5;
  .Raw-top {
    padding-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    font-size: 15px;
    .Raw-title {
      display: flex;
      align-items: center;
    }
    .el-icon {
      font-size: 18px;
      font-weight: 600;
      color: #a098ae;
    }
  }
  .Raw-edit-btn {
    margin-bottom: -20px;
    text-align: right;
    font-size: 20px;
  }
  .Raw-card-main {
    padding: 10px 20px 10px;
    width: 100%;
    height: 600px;
    overflow-y: scroll;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.8) !important;
    p {
      padding: 10px 0 10px 4px;
      font-weight: 600;
      color: #363b64;
    }
    input {
      width: 80%;
      background: #f6f6f6;
      border: 1px solid #718ebf;
      border-radius: 10px;
    }
  }
}
.Raw-card-item {
  .el-icon {
    margin-left: 10px;
    font-size: 18px;
    font-weight: 600;
    color: #a098ae;
  }
  img {
    display: inline-block;
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
}
.Total-MaterialCost {
  display: flex;
  justify-content: flex-end;
  font-size: 15px;
  color: #363b64;
  font-weight: 600;
  .MaterialCost {
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  input {
    width: 150px;
    background: #f6f6f6;
    border: 1px solid #718ebf;
    border-radius: 10px;
  }
}

.tcm-form-solid {
  position: relative;
  height: 600px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  border: 1px dashed #c0c4cc;
}
.add-cla-template {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.form-type-title {
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  background: #fff;
  line-height: 35px;
  border-radius: 5px;
  padding: 0 10px;
  margin: 10px 0;
  position: relative;
  border: 1px solid #ccc;
}
.form-type-title-img {
  width: 25px;
  position: absolute;
  right: 10px;
  top: 5px;
}

.table-cla {
  position: relative;
  display: flex;
  margin-top: 10px;
}
.add-table {
  border-collapse: collapse;
}
.add-table td {
  // border: 1px solid #dcdfe6;
  padding: 5px 10px;
  text-align: center;
}
.btn-text {
  color: var(--themeColor);
  cursor: pointer;
  font-size: 12px;
}
:deep .el-input__wrapper {
  background: transparent;
}

.add-column {
  width: 100px;
  height: 75px;
  line-height: 37px;
  border: 1px solid #dcdfe6;
  border-left: none;
  text-align: center;
}

.add-table {
  max-width: 100%;
  border-collapse: collapse;
}
.add-table td {
  border-right: 1px solid #dcdfe6;
  padding: 0px 5px;
  text-align: center;
}
.btn-text {
  color: var(--themeColor);
  cursor: pointer;
  font-size: 12px;
}
:deep .el-input__wrapper {
  background: transparent;
}

.table-box {
  overflow-x: scroll;
}
table {
  border: 1px solid #ccc;
  border-right: none;
  flex-shrink: 0;
}
table tr {
  display: flex;
  text-align: center;
}
table td {
  display: flex;
  align-items: center;
  width: 210px;
  height: 30px;
  word-wrap: break-word;
  word-break: break-all;
}

table td:nth-child(1) {
  width: 190px;
  font-size: 13px !important;
  text-align: center;
}

.add-column {
  flex-shrink: 0;
  width: 100px;
  height: 75px;
  line-height: 37px;
  border: 1px solid #dcdfe6;
  border-left: none;
  text-align: center;
  cursor: pointer;
}

table tr > td:first-child,
table tr > td:first-child {
  position: sticky;
  left: 0;
  z-index: 88;
  background-color: #f5f7fa;
  font-size: 15px;
  text-align: left;
}

.submit-btn {
  margin-left: 5px;
  padding: 0 10px;
  height: 20px;
  line-height: 20px;
  background: #b2bde7;
  color: #fff;
  text-align: center;
  border-radius: 20px;
  cursor: pointer;
}
.Serial_number {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 17px;
  font-weight: 600;
  color: #445581;
}
.el-input,
/deep/ .el-select .el-input__inner {
  height: 25px !important;
}
</style>