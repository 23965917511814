<template>
  <el-dialog v-model="visible" width="500px" :show-close="false" @close="close">
    <template #header="{ close, titleId, titleClass }">
      <div class="my-header">
        <div class="size-16">{{ $t("other.other.AddGrade") }}</div>
        <div style="cursor: pointer" @click="close">
          <el-icon :size="20"><Close /></el-icon>
        </div>
      </div>
    </template>
    <div>
      <el-form ref="formList" :model="form" :rules="rules" label-width="140px">
        <el-form-item :label="$t('other.other.Process')" prop="process">
          <el-input v-model="form.process" style="width: 214px" />
        </el-form-item>
        <el-form-item
          :label="$t('other.other.StrengthDetails')"
          prop="strength_Details"
        >
          <el-input v-model="form.strength_Details" style="width: 214px" />
        </el-form-item>
        <el-form-item :label="$t('other.other.Comment')">
          <el-input v-model="form.comment" style="width: 214px" />
        </el-form-item>
        <el-form-item :label="$t('ccls.ccl.enable')">
          <el-switch
            v-model="form.isEnable"
            active-value="1"
            inactive-value="2"
          />
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close">{{ $t("ccls.ccl.cancel") }}</el-button>
        <el-button
          style="
            background-color: var(--themeColor);
            color: var(--menuTextActive);
            margin-right: 16px;
          "
          @click="handleSubmit"
          >{{ $t("ccls.ccl.confirm") }}</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import commonUtil from "../../assets/js/commonUtil.js";

export default {
  emits: ["handleSubmit"],
  data() {
    return {
      visible: false,
      form: {
        id: "",
        process: "",
        strength_Details: "",
        comment: "",
        isEnable: 1,
      },
      rules: {
        process: [
          {
            required: true,
            message: this.$t("other.other.EnterProcess"),
            trigger: "blur",
          },
        ],
        strength_Details: [
          {
            required: true,
            message: this.$t("other.other.EnterStrength"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    "$i18n.locale"() {
      this.ChangeLan();
    },
  },
  methods: {
    ChangeLan() {
      this.rules = {
        process: [
          {
            required: true,
            message: this.$t("other.other.EnterProcess"),
            trigger: "blur",
          },
        ],
        strength_Details: [
          {
            required: true,
            message: this.$t("other.other.EnterStrength"),
            trigger: "blur",
          },
        ],
      };
    },
    handleSubmit() {
      // 给出添加弹框
      if (this.form.process == "") {
        commonUtil.showTipInfo("Please enter Process！", "", "warning");
        return;
      }
      if (this.form.strength_Details == "") {
        commonUtil.showTipInfo(
          "Please enter Strength Details！",
          "",
          "warning"
        );
        return;
      }
      this.$emit("handleSubmit", this.form);
      this.close();
    },
    close() {
      this.visible = false;
      this.form = {
        id: "",
        process: "",
        strength_Details: "",
        comment: "",
        isEnable: 1,
      };
    },
  },
};
</script>

<style scoped>
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.size-16 {
  font-size: 16px;
}
</style>