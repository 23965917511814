<template>
  <el-dialog v-model="visible" width="600px" :show-close="false" @close="close">
    <template #header="{ close, titleId, titleClass }">
      <div class="my-header">
        <div class="size-16">{{ $t("ccls.ccl.editParameter") }}</div>
        <div style="cursor: pointer" @click="close">
          <el-icon :size="20" style="color: var(--themeColor)">
            <CircleCloseFilled />
          </el-icon>
        </div>
      </div>
    </template>
    <div>
      <el-form :model="inputform" label-width="145px" :rules="rules">
        <el-form-item
          :label="$t('ccls.ccl.editParameter')"
          prop="parameterName"
        >
          <el-input v-model="inputform.parameterName" style="width: 214px" />
        </el-form-item>
        <el-form-item :label="$t('ccls.ccl.theValue')" prop="thedefaultvalue">
          <el-input
            v-model="inputform.thedefaultvalue"
            @input="
              (v) => (inputform.thedefaultvalue = v.replace(/[^\d.]/g, ''))
            "
            style="width: 214px"
          />
        </el-form-item>
        <el-form-item :label="$t('ccls.ccl.unit')">
          <el-input v-model="inputform.unit" style="width: 214px" />
        </el-form-item>
        <el-form-item :label="$t('ccls.ccl.formula')">
          <div @click="showCalculator">
            <el-input
              v-model="inputform.formulaVal"
              type="textarea"
              resize="none"
              :rows="4"
              :readonly="true"
              style="width: 400px"
            />
          </div>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close">{{ $t("ccls.ccl.cancel") }}</el-button>
        <el-button
          style="
            background-color: var(--themeColor);
            color: var(--menuTextActive);
            margin-right: 16px;
          "
          @click="submit"
          >{{ $t("ccls.ccl.confirm") }}</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import commonUtil from "../../assets/js/commonUtil.js";
export default {
  emits: ["showCalDia", "submitForm"],
  props: ["tableData"],
  data() {
    return {
      timer: "",
      visible: false,
      // 新增或编辑
      type: "add",
      // 编辑时原名称
      relName: "",
      // 表单数据
      inputform: {
        parameterName: "",
        thedefaultvalue: "",
        unit: "",
        formula: "",
        formulaVal: "",
        formulaPre: "",
      },
      // 非空校验
      rules: {},
    };
  },
  watch: {
    "$i18n.locale"() {
      this.changeLan();
    },
  },
  created() {
    this.changeLan();
  },
  methods: {
    changeLan() {
      this.rules = {
        parameterName: [
          {
            required: true,
            message: this.$t("ccls.ccl.pleaseNamse"),
            trigger: "blur",
          },
        ],
        thedefaultvalue: [
          {
            required: true,
            message: this.$t("ccls.ccl.pleaseValue"),
            trigger: "blur",
          },
        ],
      };
    },
    close() {
      this.visible = false;
      this.$parent.editIntermediateBondList = JSON.parse(
        JSON.stringify(this.$parent.intermediateBondList)
      );
      this.$parent.editValueList = JSON.parse(
        JSON.stringify(this.$parent.valueList)
      );
      this.inputform = {
        parameterName: "",
        thedefaultvalue: "",
        unit: "",
        formula: "",
        formulaVal: "",
        formulaPre: "",
      };
    },
    // 显示计算器弹窗
    showCalculator() {
      this.$emit("showCalDia", "true");
    },
    // 提交
    submit() {
      let _this = this;
      if (this.inputform.parameterName == "") {
        commonUtil.showTipInfo(this.$t("ccls.ccl.pleaseNamse"), "", "warning");
        return;
      }
      if (this.inputform.thedefaultvalue == "") {
        commonUtil.showTipInfo(this.$t("ccls.ccl.pleaseValue"), "", "warning");
        return;
      }
      let state = false;
      this.tableData.forEach(function (val, key) {
        if (val.parameterName == _this.inputform.parameterName) {
          state = true;
        }
      });
      if (
        (this.type == "add" && state) ||
        (this.type == "edit" &&
          state &&
          this.relName != "" &&
          this.relName != this.inputform.parameterName)
      ) {
        commonUtil.showTipInfo(this.$t("ccls.ccl.theRepeated"), "", "warning");
        return;
      }
      console.log(this.inputform);
      this.$emit("submitForm", this.inputform, this.type);
      this.close();
    },
  },
};
</script>

<style scoped lang="scss">
:deep(.el-dialog__header) {
  margin-right: 0;
}
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.size-16 {
  font-size: 16px;
}
</style>