<template>
  <el-dialog v-model="visible" width="30%" :show-close="false" @close="close">
    <template #header="{ close }">
      <div class="my-header">
        <div class="size-16">{{ $t("ccls.ccl.newModule") }}</div>
        <div style="cursor: pointer" @click="close">
          <el-icon :size="20">
            <Close />
          </el-icon>
        </div>
      </div>
    </template>
    <div>
      <el-form :model="form" :rules="rules" ref="form" label-width="180px">
        <el-form-item :label="$t('ccls.ccl.menuName')" prop="name">
          <el-input v-model="form.name" />
        </el-form-item>
        <el-form-item :label="$t('ccls.ccl.englishName')" prop="nameEn">
          <el-input v-model="form.nameEn" />
        </el-form-item>
        <!-- <el-form-item :label="$t('ccls.ccl.nameSpain')" prop="nameSpain">
					<el-input v-model="form.nameSpain" style="width: 240px" />
				</el-form-item>
				<el-form-item :label="$t('ccls.ccl.namePoland')" prop="namePoland">
					<el-input v-model="form.namePoland" style="width: 240px" />
				</el-form-item> -->
        <el-form-item :label="$t('ccls.ccl.parentMenu')" prop="parentName">
          <el-input
            @click="showMoudleDia"
            style="width: 100%"
            v-model="form.parentName"
          />
        </el-form-item>
        <el-form-item :label="$t('ccls.ccl.route')" prop="url">
          <el-input v-model="form.url" />
        </el-form-item>
        <el-form-item :label="$t('ccls.ccl.moduleId')" prop="Code">
          <el-input v-model="form.Code" />
        </el-form-item>
        <el-form-item :label="$t('ccls.ccl.sort')" prop="sortNo">
          <el-input v-model="form.sortNo" />
        </el-form-item>
        <el-form-item :label="$t('ccls.ccl.disable')">
          <el-switch
            v-model="form.status"
            :active-value="1"
            :inactive-value="0"
          />
        </el-form-item>
        <!-- <el-form-item  :label="$t('ccls.ccl.isPhaseII')">
					<el-switch v-model="form.isPhaseII" :active-value="1" :inactive-value="0" />
				</el-form-item> -->
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close">{{ $t("ccls.ccl.cancel") }}</el-button>
        <el-button
          style="
            background-color: var(--themeColor);
            color: var(--menuTextActive);
            margin-right: 16px;
          "
          @click="addSubmit"
          >{{ $t("ccls.ccl.confirm") }}</el-button
        >
      </span>
    </template>
    <Treemodule
      ref="TreemoduleDialog"
      @handleSubmit="DepartmentTree"
    ></Treemodule>
  </el-dialog>
</template>

<script>
import Treemodule from "./treemodule.vue";
import commonUtil from "../../assets/js/commonUtil.js";
export default {
  components: {
    Treemodule,
  },
  data() {
    return {
      visible: false,
      form: {
        timer: "",
        name: "",
        nameEn: "",
        parentId: "",
        parentName: "",
        url: "",
        Code: "",
        sortNo: "",
        isPhaseII: 0,
        nameSpain: "",
        namePoland: "",
      },
      rules: {},
    };
  },
  created() {
    // this.getUnits();
    this.ChangeLan();
  },
  watch: {
    "$i18n.locale"(val) {
      this.ChangeLan(val);
    },
  },
  methods: {
    // getUnits() {
    //   this.timer = setInterval(this.valChange, 1000);
    // },
    ChangeLan() {
      this.rules = {
        name: [
          {
            required: true,
            message: this.$t("unifiedPrompts.unifiedPrompt.pleaseMenuName"),
            trigger: "blur",
          },
        ],
        url: [
          {
            required: true,
            message: this.$t("unifiedPrompts.unifiedPrompt.pleaseMoudlePath"),
            trigger: "blur",
          },
        ],
        Code: [
          {
            required: true,
            message: this.$t("unifiedPrompts.unifiedPrompt.pleaseMoudleCode"),
            trigger: "blur",
          },
        ],
      };
    },
    close() {
      this.$refs.form.resetFields();
      this.visible = false;
    },
    // 显示选择部门弹窗
    showMoudleDia() {
      this.$refs.TreemoduleDialog.getTree();
      this.$refs.TreemoduleDialog.visible = true;
    },
    addSubmit() {
      let _this = this;
      this.$refs.form.validate(function (e) {
        if (e) {
          _this.form.sortNo =
            _this.form.sortNo === "" ||
            _this.form.sortNo === null ||
            !_this.form.sortNo
              ? 0
              : parseFloat(_this.form.sortNo);
          // _this.form.isPhaseII = sessionStorage.getItem('phaseII') == 2 ? 1 : 0
          _this.$emit("handleSubmit", _this.form);
          _this.visible = false;
          _this.$refs.form.resetFields();
        } else if (_this.form.name == "") {
          commonUtil.showTipInfo(
            this.$t("ccls.ccl.pleaseModuleName"),
            "",
            "warning"
          );
          retrun;
        } else if (_this.form.url == "") {
          commonUtil.showTipInfo(this.$t("ccls.ccl.pleasePath"), "", "warning");
          retrun;
        }
      });
    },
    DepartmentTree(form) {
      this.form.parentId = form.id;
      this.form.parentName = form.name;
      this.$refs.TreemoduleDialog.visible = true;
    },
  },
};
</script>

<style scoped>
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.size-16 {
  font-size: 16px;
}
</style>
