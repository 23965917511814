<template>
  <el-dialog v-model="visible" width="50%" :show-close="false">
    <template #header="{ close }">
      <div class="my-header">
        <div class="size-16">
          {{ $t("userlists.userlist.role") }}
        </div>
        <div style="cursor: pointer" @click="close">
          <el-icon :size="20"><Close /></el-icon>
        </div>
      </div>
    </template>
    <div class="tcm-table">
      <el-table
        border
        stripe
        :empty-text="$t('tcmlists.tcmlist.noData')"
        ref="roleTable"
        :data="Tabledata"
        style="width: 100%"
        :row-key="
          (row) => {
            return row.id;
          }
        "
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column
          prop="name"
          :label="$t('userlists.userlist.roleName')"
          align="center"
          width="150"
        />
        <el-table-column
          :label="$t('userlists.userlist.allocatedModules')"
          align="center"
          show-overflow-tooltip
        >
          <template #default="scope">
            {{ scope.row.modulesNameEn }}
          </template>
        </el-table-column>
        <el-table-column
          prop="status"
          :label="$t('userlists.userlist.enable')"
          align="center"
          width="100"
        >
          <template #default="scope">
            <el-icon
              :size="16"
              color="#40D4A8"
              v-show="scope.row.status == '0'"
            >
              <SuccessFilled />
            </el-icon>
            <el-icon
              :size="16"
              color="#F56C6C"
              v-show="scope.row.status == '1'"
            >
              <CircleCloseFilled />
            </el-icon>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close">{{ $t("ccls.ccl.cancel") }}</el-button>
        <el-button
          style="
            background-color: var(--themeColor);
            color: var(--menuTextActive);
            margin-right: 16px;
          "
          @click="handleSubmit"
          >{{ $t("ccls.ccl.confirm") }}</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>
  
  <script>
import { roleList } from "@/assets/js/requestApi.js";
export default {
  props: [],
  data() {
    return {
      visible: false,
      Tabledata: [],
      ChooseData: [],
    };
  },

  methods: {
    close() {
      this.visible = false;
    },
    GetData(valData) {
      roleList(
        {
          IsPhaseII: 0,
          page: 1,
          limit: 99999,
        },
        (response) => {
          if (response && response.code == 200) {
            this.Tabledata = response.data;
            if (this.Tabledata.length && valData.length) {
              this.Tabledata.forEach((itm) => {
                valData.forEach((item) => {
                  if (itm.id == item) {
                    console.log(itm, "itm");
                    this.$nextTick(() => {
                      this.$refs.roleTable.toggleRowSelection(itm);
                    });
                  }
                });
              });
            }
          }
        }
      );
    },
    handleSelectionChange(val) {
      let arr = [];
      if (val.length) {
        val.forEach((item) => {
          arr.push({
            id: item.id,
            name: item.name,
          });
        });
      }
      this.ChooseData = arr;
    },
    // 提交按钮
    handleSubmit() {
      this.$emit("handleSubmit", this.ChooseData);
      this.visible = false;
    },
  },
};
</script>
<style scoped>
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.size-16 {
  font-size: 16px;
}
</style>